import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'

import { onCategorySuggest, onBrandSuggest } from '../../../../../actions/niq-widget-actions'
import { useEditValue } from './useEditValue'

import { DESCRIPTION_EDITABLE_ATTRIBUTE } from '../../table-constants'
import { handleOnChange } from './utils'

import AutocompleteMUI from 'mui/components/AutocompleteMUI'

/**
 * Component to edit a value in the description table
 *
 * @param {Object} props - The props for the component
 * @param {function} props.onUpdate - The function to call when the value is updated
 * @param {DESCRIPTION_EDITABLE_ATTRIBUTE} props.attribute - The attribute to edit
 * @param {Object} props.row - The row to edit
 * @param {string} props.defaultValue - The default value for the input
 */
const InEditValue = ({ onUpdate, attribute, row, defaultValue }) => {
  const dispatch = useDispatch()

  const [value, setValue] = useState(defaultValue)
  const [isOpen, setIsOpen] = useState(false)

  const dictionaryKey = row.dictionary_key
  const { suggestions, stageValues, updateStageValue, isLoading } = useEditValue(attribute, dictionaryKey)

  /**
   * Callback for handling changes in the input field.
   *
   * @param {Array<Object>} newValues - The new values selected by the user.
   */
  const onChange = useCallback(
    newValue => {
      handleOnChange({
        newValue,
        attribute,
        stageValues,
        row,
        defaultValue,
        updateStageValue,
        onUpdate
      })
    },
    [updateStageValue, stageValues, row, attribute, defaultValue, onUpdate]
  )

  const onInputChange = useCallback(
    val => {
      if (attribute === DESCRIPTION_EDITABLE_ATTRIBUTE.BRAND) {
        const actualCategoryFullPath = stageValues?.category?.name ?? row.category_full_path
        dispatch(onBrandSuggest(val, actualCategoryFullPath))
      } else if (attribute === DESCRIPTION_EDITABLE_ATTRIBUTE.CATEGORY) {
        dispatch(onCategorySuggest(val))
      }
      setIsOpen(true)
      setValue(val)
    },
    [dispatch, attribute, stageValues, row]
  )

  const onCancel = useCallback(
    event => {
      event.preventDefault()
      onUpdate(null)
    },
    [onUpdate]
  )

  return (
    <AutocompleteMUI
      value={value}
      suggestions={suggestions}
      isLoading={isLoading}
      isOpen={isOpen}
      onChange={onChange}
      onInputChange={onInputChange}
      onCancel={onCancel}
      placeholder={(attribute === DESCRIPTION_EDITABLE_ATTRIBUTE.BRAND ? 'Brand' : 'Category') + ' name'}
    />
  )
}

InEditValue.propTypes = {
  onUpdate: PropTypes.func.isRequired,
  attribute: PropTypes.string.isRequired,
  row: PropTypes.shape({
    dictionary_key: PropTypes.string.isRequired,
    category_id: PropTypes.number,
    category_full_path: PropTypes.string,
    brand_id: PropTypes.number,
    brand_name: PropTypes.string
  }).isRequired,
  defaultValue: PropTypes.string
}

export default InEditValue
