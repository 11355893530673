import React from 'react'
import PropTypes from 'prop-types'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  Input,
  Alert,
  Stack,
  DialogContentText
} from '@mui/material'
import joi from 'joi'
import { useForm, Controller } from 'react-hook-form'
import { joiResolver } from '@hookform/resolvers/joi'
import { useUpdateMaxIdMutation, useGetMaxIdQuery } from './cmeDictionaryAPI'
import LoadingOverlay from 'mui/components/LoadingOverlay/LoadingOverlay'

import InputWithWideLabel from 'mui/components/Inputs/InputWithWideLabel'

const UpdateMaxIdModal = ({ onClose }) => {
  const validationSchema = joi.object({
    max_id: joi
      .number()
      .integer()
      .min(0)
  })

  const { isLoading, isError: errorFetching, data: maxIdDetails } = useGetMaxIdQuery()

  const [updateMaxId, { isError, error, isLoading: isUpdating }] = useUpdateMaxIdMutation()

  const defaultValues = {
    max_id: maxIdDetails?.extract_dt_max_value_id ?? 0
  }

  const {
    control,
    handleSubmit,
    formState: { isDirty, isValid }
  } = useForm({
    mode: 'all',
    defaultValues,
    resolver: joiResolver(validationSchema)
  })

  const onSubmit = async data => {
    const { max_id } = data
    try {
      const result = await updateMaxId({
        max_id
      }).unwrap()
      if (result.success) {
        onClose()
      }
    } catch (error) {
      // do nothing as the mutation will include the required details
    }
  }

  return (
    <Dialog open fullWidth maxWidth="sm" onClose={onClose}>
      <LoadingOverlay open={isLoading || isUpdating} />
      <DialogTitle sx={{ fontWeight: 500, fontSize: '1.5rem' }}>Update Max Uniq ID for test and staging</DialogTitle>
      <DialogContent>
        <DialogContentText id="dialog-description">
          Rows with uniq_id less than the value you provide will be deleted when the purge dictionary history worker
          runs. The backend assumes that those rows are already written to Snowflake. This is only for development and
          staging as we don&apos;t have the sliceds.cds.descriptionary table for staging
        </DialogContentText>
        {!isLoading && !errorFetching && (
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={1} direction="column">
              <InputWithWideLabel label="Max ID">
                <Controller name="max_id" control={control} render={({ field }) => <Input {...field} />} />
              </InputWithWideLabel>
              <DialogActions sx={{ justifyContent: 'flex-end' }}>
                <Button onClick={onClose}>Close</Button>

                <Button className="ml-2" type="submit" disabled={!isDirty || (isDirty && !isValid)}>
                  Update
                </Button>
              </DialogActions>
            </Stack>
          </form>
        )}
      </DialogContent>

      {isError && (
        <Alert sx={{ ml: 4, mb: 2 }} severity="warning">
          {error.data}
        </Alert>
      )}
    </Dialog>
  )
}

UpdateMaxIdModal.propTypes = {
  onClose: PropTypes.func
}

export default UpdateMaxIdModal
