import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import BrandSynonymRuleModal from './Modals/BrandSynonymRuleModal'
import CommonRuleActionsCell from './../Common/CommonRuleActionsCell'

import { BrandSynonymRulesActions } from './../actions'

class BrandSynonymActionsCell extends Component {
  deleteFunction = id => {
    this.props.saveRuleAction({ id, isActive: false })
  }

  activateFunction = id => {
    this.props.saveRuleAction({ id, isActive: true })
  }

  getEditModal = () => {
    const { id, brandId, brandFullPath, synonym } = this.props.row
    const { saveRuleAction } = this.props
    const synonyms = [{ synonym }]

    return (
      <BrandSynonymRuleModal
        value={{
          id,
          brandId,
          brandFullPath,
          synonyms
        }}
        onOkHandler={saveRuleAction}
      />
    )
  }

  render() {
    const { id, isActive, brandIsActive, ruleIsActive } = this.props.row
    let activationTooltip = ''
    let isActivationBlocked = false

    if (isActive) {
      activationTooltip = 'Delete rule'
    } else if (!brandIsActive) {
      activationTooltip = "Can't reactivate because related Brand is inactivate"
      isActivationBlocked = true
    } else if (!ruleIsActive && brandIsActive) {
      activationTooltip = 'Re-Activate'
    }

    return (
      <CommonRuleActionsCell
        editModaTitle="Edit Brand Synonym Rule (BSR)"
        editModalContent={this.getEditModal()}
        id={id}
        isActive={isActive}
        isActivationBlocked={isActivationBlocked}
        activationTooltip={activationTooltip}
        deleteFunction={this.deleteFunction}
        activateFunction={this.activateFunction}
      />
    )
  }
}

BrandSynonymActionsCell.propTypes = {
  saveRuleAction: PropTypes.func.isRequired,
  row: PropTypes.object.isRequired
}

const mapDispatchToProps = {
  saveRuleAction: BrandSynonymRulesActions.save
}

export default connect(null, mapDispatchToProps)(BrandSynonymActionsCell)
