import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Typeahead } from 'react-bootstrap-typeahead'
import { Glyphicon } from 'react-bootstrap'

class CreateTypeaheadWrapper extends Component {
  static propTypes = {
    selectedValue: PropTypes.string,
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
    onInputChange: PropTypes.func,
    suggestions: PropTypes.array,
    renderMenuItemChildren: PropTypes.func,
    maxResults: PropTypes.number,
    class: PropTypes.string,
    mode: PropTypes.string,
    currentObj: PropTypes.object,
    type: PropTypes.string
  }

  getPlaceholder = () => {
    const { selectedValue } = this.props
    return selectedValue && this.props.mode === 'search' ? selectedValue : this.props.placeholder
  }

  renderMenuItemChildren = option => {
    if (!option.id) {
      return (
        <span className={'create_Typeahead_create'}>
          <span className={'sq_active_icon'}>{this.props.type === 'group' ? 'Create Group ' : 'Create Query '}</span>
          {option.label}
        </span>
      )
    }
    return <span style={{ fontWeight: 'bold' }}>{option.label}</span>
  }

  getSuggestion = () => {
    let showCreateOption = true
    const { currentObj } = this.props
    if (!currentObj.inputValue) {
      showCreateOption = false
    }
    let suggestions = currentObj.list
      .filter(obj => !obj.isDisable)
      .map(obj => {
        if (showCreateOption && obj.name === currentObj.inputValue) {
          showCreateOption = false
        }
        return { id: obj.id, label: obj.name }
      })
    if (showCreateOption) {
      const createSuggestion = [{ label: currentObj.inputValue, type: 'create' }]
      suggestions = suggestions.concat(createSuggestion)
    }
    return suggestions || []
  }

  render() {
    return (
      <div>
        <Typeahead
          className={this.props.class}
          style={{ display: 'inline-block' }}
          placeholder={this.getPlaceholder()}
          onChange={this.props.onChange}
          selected={[]}
          onInputChange={this.props.onInputChange}
          options={this.getSuggestion()}
          renderMenuItemChildren={this.renderMenuItemChildren}
          maxResults={this.props.maxResults}
          paginationText="Show More Results"
          paginate
        />
        <span className="create_Typeahead_Wrapper_icon">
          <Glyphicon glyph="search" />
        </span>
      </div>
    )
  }
}

export default CreateTypeaheadWrapper
