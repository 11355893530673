import React from 'react'
import { Button, Glyphicon } from 'react-bootstrap'
import { useDispatch } from 'react-redux'

import { addCustomModal } from 'actions/modal-actions'
import CategoryTestEditGlobalModal from './Modals/EditGlobal/CategoryTestEditGlobalModal'

const CategoryTestsGlobalConfButton = () => {
  const dispatch = useDispatch()

  const openGlobalModal = () => {
    dispatch(
      addCustomModal({
        modalType: 'successModal',
        id: 'edit-modal',
        title: `Edit Global Test Configuration`,
        size: 'large',
        content: <CategoryTestEditGlobalModal />
      })
    )
  }

  return (
    <div className="rightFormButton">
      <Button bsStyle="default" onClick={openGlobalModal}>
        <Glyphicon glyph="cog" />
        Global Thresholds
      </Button>
    </div>
  )
}

export default CategoryTestsGlobalConfButton
