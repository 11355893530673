import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import Loader from '../Loader'
import DocumentTitle from 'react-document-title'
import { BootstrapTable } from 'react-bootstrap-table'
import { Alert, Button, Glyphicon, ButtonGroup, ButtonToolbar } from 'react-bootstrap'

import buildColumns from './feedDeliveryColumns'

import { appName } from '../../constants/constants'

class FeedDeliveriesPage extends Component {
  constructor(props) {
    super(props)

    this.pageSize = this.props.pageSize
    this.pageNumber = this.props.pageNumber
  }

  fetchFeedDeliveries = (payload = {}) => {
    const { pageNumber = this.pageNumber, pageSize = this.pageSize } = payload

    this.props.fetchFeedDeliveries({
      page: pageNumber,
      count: pageSize
    })
  }

  onPageChange = pageNumber => {
    this.pageNumber = pageNumber
    this.fetchFeedDeliveries({ pageNumber })
  }

  onSizePerPageChange = pageSize => {
    this.pageNumber = 1
    this.pageSize = pageSize

    this.fetchFeedDeliveries({
      pageSize,
      pageNumber: 1
    })
  }

  getTableOptions = () => {
    return {
      sortOrder: 'desc',
      sortName: 'deliveryDate',
      sizePerPageList: [25, 50, 100],
      page: this.pageNumber,
      sizePerPage: this.pageSize,
      onPageChange: this.onPageChange,
      onSearchChange: this.onSearchChange,
      onSizePerPageList: this.onSizePerPageChange
    }
  }

  render() {
    const { feedDeliveries, error, loading } = this.props

    return (
      <React.Fragment>
        <DocumentTitle title={this.props.documentTitle ? `${appName} | ${this.props.documentTitle}` : appName}>
          <div className="feed-deliveries-page">
            <ButtonToolbar className="feed-deliveries-toolbar">
              <ButtonGroup className="pull-right">
                <Button onClick={this.fetchFeedDeliveries}>
                  Reload <Glyphicon glyph="refresh" />
                </Button>
              </ButtonGroup>
            </ButtonToolbar>

            {loading && <Loader loading overlap />}
            <BootstrapTable
              remote
              hover
              striped
              bordered
              condensed
              responsive
              pagination
              data={feedDeliveries}
              className="delivery-logs-table"
              ref={el => {
                this.table = el
              }}
              options={this.getTableOptions()}
              fetchInfo={{ dataTotalSize: 10000 }}
            >
              {buildColumns()}
            </BootstrapTable>

            {error && <Alert bsStyle="danger">{error}</Alert>}
          </div>
        </DocumentTitle>
      </React.Fragment>
    )
  }
}

FeedDeliveriesPage.defaultProps = {
  pageSize: 25,
  pageNumber: 1,
  documentTitle: 'Feed Deliveries'
}

FeedDeliveriesPage.propTypes = {
  error: PropTypes.string,
  loading: PropTypes.bool,
  pageSize: PropTypes.number,
  pageNumber: PropTypes.number,
  documentTitle: PropTypes.string,
  fetchFeedDeliveries: PropTypes.func,
  feedDeliveries: PropTypes.arrayOf(PropTypes.object)
}

const mapStateToProps = ({ feeds: { feedDeliveries } }) => ({
  error: feedDeliveries.error,
  loading: feedDeliveries.loading
})

export default connect(mapStateToProps, null)(FeedDeliveriesPage)
