import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Glyphicon, OverlayTrigger, Tooltip } from 'react-bootstrap'

import { addCustomModal } from 'actions/modal-actions'
import CategoryGuidelineEditModal from './../Modals/CategoryGuidelineEditModal'

class CategoryGuidelineActionsCell extends Component {
  openModal = ({ title, readOnly }) => {
    const { id, categoryFullPath, include, exclude, notes } = this.props.row
    const { addCustomModal } = this.props
    addCustomModal({
      modalType: 'successModal',
      id: 'category-guideline-modal',
      title,
      size: 'large',
      content: (
        <CategoryGuidelineEditModal
          categoryId={id}
          categoryFullPath={categoryFullPath}
          include={include}
          exclude={exclude}
          notes={notes}
          readOnly={readOnly}
        />
      )
    })
  }

  editModal = () => {
    this.openModal({ title: 'Edit Category Guideline', readOnly: false })
  }

  viewModal = () => {
    this.openModal({ title: 'View Category Guideline', readOnly: true })
  }

  render() {
    const { hasEditAccess } = this.props
    return (
      <div className="sb-table-action-cell">
        {hasEditAccess && (
          <OverlayTrigger
            placement="left"
            overlay={<Tooltip id="category-guideline-edit-tooltip">Edit category guideline</Tooltip>}
          >
            <div onClick={this.editModal} title={`Edit category guideline`}>
              <Glyphicon glyph="edit" />
            </div>
          </OverlayTrigger>
        )}

        <OverlayTrigger
          placement="left"
          overlay={<Tooltip id="category-guideline-view-tooltip">View category guideline</Tooltip>}
        >
          <div onClick={this.viewModal}>
            <Glyphicon glyph="eye-open" />
          </div>
        </OverlayTrigger>
      </div>
    )
  }
}

CategoryGuidelineActionsCell.propTypes = {
  addCustomModal: PropTypes.func.isRequired,
  hasEditAccess: PropTypes.bool.isRequired,
  row: PropTypes.object.isRequired
}

const mapDispatchToProps = {
  addCustomModal
}

export default connect(null, mapDispatchToProps)(CategoryGuidelineActionsCell)
