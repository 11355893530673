import { cloneDeep } from 'lodash'

/**
 * These are helper functions for converting chart data to show different variants
 */

// IN-1234
// the option {stacking: 'percent'} in Highcharts will be summarized series in yAxis
// and calculate the percentage base on this sum.
// But if we need to get the percentage of one series based on another,
// we should subtract it before sending data in the graph
const percentBetweenSeries = (contextSeries, querySeries) => {
  const newContextSeries = contextSeries.map((contextPoint, index) => {
    const queryPoint = querySeries[index]
    const newContextPoint = contextPoint - queryPoint
    return newContextPoint
  })

  return newContextSeries
}

// This is when we should have in "red" area the opposite value of the query
// this is the same as PERCENT_TYPE.fromContextInvert in the server side
const invertQuery = (contextSeries, querySeries) => {
  const newQuerySeries = querySeries.map((queryPoint, index) => {
    const contextPoint = contextSeries[index]
    const newQuery = contextPoint - queryPoint
    return newQuery
  })

  return newQuerySeries
}

const convertYAxisIfNeed = ({ yAxis, isInvertQuery, isPercentBetweenSeries }) => {
  const yAxisSerieses = cloneDeep(yAxis)

  if (isInvertQuery) {
    const querySeries = yAxisSerieses[0].data
    const contextSeries = yAxisSerieses[1].data

    const newQuerySeries = invertQuery(contextSeries, querySeries)
    yAxisSerieses[0].data = newQuerySeries
  }

  if (isPercentBetweenSeries) {
    const querySeries = yAxisSerieses[0].data
    const contextSeries = yAxisSerieses[1].data

    const newContextSeries = percentBetweenSeries(contextSeries, querySeries)
    yAxisSerieses[1].data = newContextSeries
  }

  return yAxisSerieses
}

export default convertYAxisIfNeed

export { invertQuery, percentBetweenSeries }
