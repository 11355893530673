/*
 * Get Submit button name base on mode
 */
export const getSubmitButtonName = mode => {
  return (
    (mode.isRuleCreate && 'Create') ||
    (mode.isRuleEdit && 'Edit') ||
    (mode.isRequestCreate && 'Request') ||
    (mode.isRequestApprove && 'Approve') ||
    'Ok'
  )
}

/**
 * Get UI parameters of Rules/Requests Modal's form
 */
export const getModalUISettings = ({
  prevRuleId,
  isAllFieldsFilled,
  fieldsNames,
  isRuleActiveFunc,
  mode,
  isFormChanged,
  isLoading,
  rule,
  errorMessage,
  hasDuplicateInGroup,
  hasDuplicateWithParent
}) => {
  const initSettings = {
    ruleInfoMessage: '',
    // possible values of bsStyle of <Alert /> component:
    // null, 'success', 'info', 'warning', 'danger'
    ruleInfoStyle: null,
    okButtonName: getSubmitButtonName(mode),
    isOkButtonDisabled: false,
    isFieldsDisabled: false
  }

  /** ******** Check rule loading and fields ****/
  if (isLoading) {
    return {
      ...initSettings,
      ruleInfoMessage: 'Check a Rule ...',
      isOkButtonDisabled: true
    }
  } else if (!isAllFieldsFilled) {
    return {
      ...initSettings,
      ruleInfoMessage: `Select ${fieldsNames}`,
      isOkButtonDisabled: true
    }
  } else if (hasDuplicateInGroup) {
    return {
      ...initSettings,
      ruleInfoMessage: 'Item already exists, choose the unique one',
      ruleInfoStyle: 'danger',
      isOkButtonDisabled: true
    }
  } else if (hasDuplicateWithParent) {
    return {
      ...initSettings,
      ruleInfoMessage: 'Item has a duplicate with the parent element',
      ruleInfoStyle: 'danger',
      isOkButtonDisabled: true
    }
  } else if (errorMessage) {
    return {
      ...initSettings,
      ruleInfoMessage: errorMessage,
      ruleInfoStyle: 'danger',
      isOkButtonDisabled: true
    }
  }
  /** ******** Check Rule  ****/
  let ruleCheckSettings = {}
  if (mode.isRuleCreate) {
    ruleCheckSettings = checkRuleWhenCreate({ rule, fieldsNames, isRuleActiveFunc })
  } else if (mode.isRuleEdit) {
    ruleCheckSettings = checkRuleWhenEdit({ rule, fieldsNames, prevRuleId })
  } else if (mode.isRequestCreate) {
    ruleCheckSettings = checkRuleWhenCreateRequest({ rule, fieldsNames })
  } else if (mode.isRequestApprove) {
    ruleCheckSettings = checkRuleWhenApprove({ rule, fieldsNames })
  }

  const settings = {
    ...initSettings,
    ...ruleCheckSettings
  }

  /** ******** Check disable of Ok button  ****/
  const isEditModeAndFormNotChanged = mode.isRuleEdit && !isFormChanged
  settings.isOkButtonDisabled = isEditModeAndFormNotChanged || settings.isOkButtonDisabled

  return settings
}

//
// Private methods
//

const checkRuleWhenCreate = ({ rule, fieldsNames, isRuleActiveFunc }) => {
  const settings = {}
  if (rule) {
    settings.isFieldsDisabled = true

    let ruleInfoMessage = `Rule (ID:${rule.id}) already exists. `
    if (isRuleActiveFunc(rule)) {
      ruleInfoMessage += "It's ACTIVE. You can't change it!"
      settings.ruleInfoStyle = 'danger'
      settings.isOkButtonDisabled = true
    }

    if (!rule.isActive) {
      ruleInfoMessage += "It's INACTIVE. You can re-active it."
      settings.ruleInfoStyle = 'warning'
      settings.okButtonName = 'Activate'
    }

    settings.ruleInfoMessage = ruleInfoMessage
  } else {
    settings.ruleInfoMessage = `Rule for selected ${fieldsNames} doesn't exist. It will be created.`
  }

  return settings
}

const checkRuleWhenEdit = ({ rule, fieldsNames, prevRuleId }) => {
  const settings = {}

  if (rule) {
    const isEditSelectedRule = rule.id === prevRuleId
    if (isEditSelectedRule) {
      settings.ruleInfoMessage = `You edit Rule (ID:${rule.id})`
    } else {
      settings.ruleInfoMessage = `Rule (ID:${rule.id}) with selected ${fieldsNames} already exists. You can't change it!`
      settings.ruleInfoStyle = 'danger'
      settings.isFieldsDisabled = true
      settings.isOkButtonDisabled = true
    }
  } else {
    settings.ruleInfoMessage = `There isn't a Rule for selected ${fieldsNames}. Current Rule will be updated.`
  }

  return settings
}

const checkRuleWhenCreateRequest = ({ rule, fieldsNames }) => {
  const settings = {}
  if (rule) {
    // TODO its for CBR. Check in other modals
    // disable isMatchAnywhere if in Create Request mode there is selected existing rule
    settings.isFieldsDisabled = true
    let ruleInfoMessage = `Rule (ID:${rule.id}) already exists. `
    settings.ruleInfoStyle = 'danger'
    settings.isFieldsDisabled = true
    settings.isOkButtonDisabled = true

    if (rule.isActive) {
      ruleInfoMessage += "It's ACTIVE."
    } else {
      // TODO can I reactivate if for example related categoryId is disabled???
      ruleInfoMessage += "It's INACTIVE. You can re-active it."
      settings.ruleInfoStyle = 'warning'
      settings.okButtonName = 'Request Re-activation'
      settings.isOkButtonDisabled = false
    }

    settings.ruleInfoMessage = ruleInfoMessage
  } else {
    settings.ruleInfoMessage = `Rule for selected ${fieldsNames} doesn't exist. Request will be created.`
  }

  return settings
}

const checkRuleWhenApprove = ({ rule, fieldsNames }) => {
  const settings = {}

  if (rule) {
    settings.ruleInfoMessage = `Rule (ID:${rule.id}) with selected ${fieldsNames} already exists!`
    settings.ruleInfoStyle = 'danger'
    settings.isFieldsDisabled = true
    settings.isOkButtonDisabled = true

    if (rule.isActive) {
      settings.ruleInfoMessage += " It's ACTIVE."
    } else {
      settings.ruleInfoMessage += " It's INACTIVE. You can re-active it."
      settings.ruleInfoStyle = 'warning'
      settings.okButtonName = 'Approve Re-activation'
      settings.isOkButtonDisabled = false
    }
  } else {
    settings.ruleInfoMessage = `There isn't a Rule for selected ${fieldsNames}. You can Create it.`
  }

  return settings
}
