import React, { useState, useEffect } from 'react'
import EmbeddedRenderer from '../EmbeddedRenderer'
import WithErrorBoundaryWrapper from 'components/WithErrorBoundaryWrapper/WithErrorBoundaryWrapper'
import { HEADER_HEIGHT, FOOTER_HEIGHT } from '../../constants/constants'
import './supraQCMonitoringPage.scss'

const SHOVING_INFO_TIME = 7000
const MONITORING_PAGE_HOST = 'https://mlstreamlit.prod.slicetest.com/Supra_Monitoring/'

const SupraPage = () => {
  const [showInfo, setShowInfo] = useState(true)

  const timer = setTimeout(() => {
    setShowInfo(false)
  }, SHOVING_INFO_TIME)

  useEffect(() => {
    return () => clearTimeout(timer)
  })

  return (
    <>
      {showInfo && (
        <div className="monitoring-info">
          <p>To view this page, you need to enable Slice VPN</p>
        </div>
      )}
      <EmbeddedRenderer
        sourceName={'supra-qc-monitoring'}
        url={MONITORING_PAGE_HOST}
        iframeHeight="100%"
        fullscreen
        styles={{
          height: `calc(100vh - ${HEADER_HEIGHT + FOOTER_HEIGHT}px)`,
          display: 'block'
        }}
      />
    </>
  )
}

export default WithErrorBoundaryWrapper(SupraPage, '"Supra Monitoring" page')
