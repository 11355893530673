import React from 'react'
import PropTypes from 'prop-types'

import ETLStatTotals from './ETLStatsTotals'
import ETLStatsFilters from './ETLStatsFilters'

import './index.scss'

const ETLStatsToolbar = props => {
  const { taskCount, fetchETLStats, failedTaskCount, pendingTaskCount, completedTaskCount } = props

  return (
    <div className="etl-stats-toolbar">
      <ETLStatTotals
        taskCount={taskCount}
        failedTaskCount={failedTaskCount}
        pendingTaskCount={pendingTaskCount}
        completedTaskCount={completedTaskCount}
      />

      <ETLStatsFilters fetchETLStats={fetchETLStats} />
    </div>
  )
}

ETLStatsToolbar.propTypes = {
  taskCount: PropTypes.number,
  fetchETLStats: PropTypes.func,
  failedTaskCount: PropTypes.number,
  pendingTaskCount: PropTypes.number,
  completedTaskCount: PropTypes.number
}

export default ETLStatsToolbar
