import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import { Table, Glyphicon } from 'react-bootstrap'

import './deliverableTable.scss'

export default class DeliverableTable extends Component {
  render() {
    const { deliverables, totalCount, showCategory } = this.props
    return (
      <div className="deliverables-categories-table">
        <Table responsive striped hover condensed>
          <thead>
            <tr>
              <th className="text-center">Deliverable ID</th>
              <th className="text-left">Deliverable Name</th>
              <th className="text-center">Has future Delivery</th>
              {showCategory && (
                <>
                  <th className="text-center">Category ID</th>
                  <th>Category Path</th>
                </>
              )}
            </tr>
          </thead>
          <tbody>
            {deliverables.map(d => (
              <tr key={d.id}>
                <td className="text-center">{d.Deliverable.id}</td>
                <td className="text-left">{d.Deliverable.name}</td>
                <td className="text-center">
                  {d.Deliverable.nextDelivery && moment(d.Deliverable.nextDelivery) > moment() ? (
                    <Glyphicon glyph="ok-circle" title={moment(d.Deliverable.nextDelivery).format('MM-DD-YYYY')} />
                  ) : (
                    <Glyphicon
                      glyph="minus-sign"
                      title={
                        d.Deliverable.nextDelivery ? moment(d.Deliverable.nextDelivery).format('MM-DD-YYYY') : 'Empty'
                      }
                    />
                  )}
                </td>
                {showCategory && (
                  <>
                    <td className="text-center">{d.Category.id}</td>
                    <td className="deliverables-categories-table-category-path">{d.Category.full_path}</td>
                  </>
                )}
              </tr>
            ))}
          </tbody>
        </Table>
        {totalCount > deliverables.length && (
          <h5 className="deliverables-categories-table-info text-left">
            ... show first {deliverables.length} relations from {totalCount}
          </h5>
        )}
      </div>
    )
  }
}

DeliverableTable.defaultProps = {
  totalCount: 0,
  deliverables: [],
  showCategory: true
}

DeliverableTable.propTypes = {
  totalCount: PropTypes.number,
  deliverables: PropTypes.arrayOf(PropTypes.arrayOf),
  showCategory: PropTypes.bool
}
