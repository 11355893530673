import React, { useEffect, useState } from 'react'
import { Button, Stack, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import EditIcon from '@mui/icons-material/Edit'
import DoneAllIcon from '@mui/icons-material/DoneAll'
import RemoveDoneIcon from '@mui/icons-material/RemoveDone'

import SupraTaxonomyEditEntryModal from 'mui/pages/SupraTaxonomyPage/SupraTaxonomyEditEntryModal'
import { useLazyGetSupraTaxonomyRowByIdQuery } from 'mui/pages/SupraTaxonomyPage/supraTaxonomyAPI'
import { STATUS, STEP } from '../../orchestratorConstants'

const WhitelistedStep = ({ selectedDomain, active, activateNextStep }) => {
  const [isWndShow, setWndShow] = useState(false)
  const [isWhitelisted, setIsWhitelisted] = useState(selectedDomain?.nonreceipt_whitelisted)
  const [status, setStatus] = useState(STATUS.NONE)

  useEffect(() => {
    setIsWhitelisted(selectedDomain?.nonreceipt_whitelisted)
    setStatus(STATUS.NONE)
  }, [selectedDomain])

  const handleEditClick = () => {
    setWndShow(true)
  }

  const updateStatus = () => {
    setStatus(STATUS.PENDING)
  }

  const [trigger, result] = useLazyGetSupraTaxonomyRowByIdQuery()

  const handleStatusClick = () => {
    trigger({ domainId: selectedDomain?.domain_id })
  }

  useEffect(() => {
    if (result?.data?.domain?.active) {
      setStatus(STATUS.DONE)
      setIsWhitelisted(true)
      activateNextStep(STEP.BRONZE_TO_SILVER)
    }
  }, [result])

  return (
    <>
      {isWndShow && (
        <SupraTaxonomyEditEntryModal
          selectedRow={selectedDomain || {}}
          setWndShow={setWndShow}
          updateStatus={updateStatus}
        />
      )}
      <Stack
        direction="row"
        sx={{
          alignItems: 'flex-end',
          my: 1
        }}
      >
        <Typography sx={{ width: 400 }} variant="h5">
          1. Whitelisted
        </Typography>
        <Stack
          direction="row"
          sx={{
            alignItems: 'flex-end',
            width: 64
          }}
        >
          {isWhitelisted ? <DoneAllIcon fontSize="large" /> : <RemoveDoneIcon fontSize="large" />}
        </Stack>
        <Button sx={{ ml: 2 }} variant="outlined" onClick={handleEditClick} disabled={!active} startIcon={<EditIcon />}>
          Edit
        </Button>
        <Button sx={{ ml: 2 }} variant="outlined" onClick={handleStatusClick} disabled={!active}>
          Status
        </Button>
        <Typography sx={{ ml: 4 }} variant="h5">
          {status}
        </Typography>
      </Stack>
    </>
  )
}

WhitelistedStep.propTypes = {
  selectedDomain: PropTypes.any,
  active: PropTypes.bool,
  activateNextStep: PropTypes.func
}

export default WhitelistedStep
