import React from 'react'
import PropTypes from 'prop-types'
import { Glyphicon, OverlayTrigger, Tooltip } from 'react-bootstrap'

import { VALIDATION_BRAND_MESSAGE, VALIDATION_CATEGORY_MESSAGE } from './helper'

const TaxonomyNameTooltipInfo = ({ type }) => {
  const message = type === 'Category' ? VALIDATION_CATEGORY_MESSAGE : VALIDATION_BRAND_MESSAGE
  return (
    <OverlayTrigger rootClose overlay={<Tooltip id="taxonomy-validation-restrictions">{message}</Tooltip>}>
      <Glyphicon glyph="info-sign" style={{ marginLeft: '5px' }} />
    </OverlayTrigger>
  )
}

TaxonomyNameTooltipInfo.propTypes = {
  type: PropTypes.string
}

export default TaxonomyNameTooltipInfo
