// handleOnChange.js

import { DESCRIPTION_EDITABLE_ATTRIBUTE } from '../../table-constants'

/**
 * Handles the change event for updating the values in the description table.
 *
 * @param {Object} params - The parameters for the function.
 * @param {{id: number, value: string}} params.newValue - The new values selected by the user.
 * @param {string} params.attribute - The attribute that is being edited (e.g., brand, category).
 * @param {Object} params.stageValues - The current staged values for brand and category.
 * @param {Object} params.row - The current row data.
 * @param {string} params.defaultValue - The default value for the input field.
 * @param {function} params.updateStageValue - The function to call for updating the stage values.
 * @param {function} params.onUpdate - The callback function to call when the value is updated.
 */
export const handleOnChange = ({ newValue, attribute, stageValues, row, defaultValue, updateStageValue, onUpdate }) => {
  // Update the stage value with the selected new value
  updateStageValue(attribute, newValue.id, newValue.value)
  onUpdate(defaultValue)

  // Auto update related Brand/Category if another is changed
  const IS_BRAND_CHANGED = !!stageValues?.brand
  const IS_CATEGORY_CHANGED = !!stageValues?.category

  // If category is changed and the brand hasn't been changed, update the brand automatically
  if (attribute === DESCRIPTION_EDITABLE_ATTRIBUTE.CATEGORY && !IS_BRAND_CHANGED) {
    const id = row.brand_id
    const value = row.brand_name
    if (value !== undefined) {
      updateStageValue(DESCRIPTION_EDITABLE_ATTRIBUTE.BRAND, id, value)
    }
  }
  // If brand is changed and the category hasn't been changed, update the category automatically
  else if (attribute === DESCRIPTION_EDITABLE_ATTRIBUTE.BRAND && !IS_CATEGORY_CHANGED) {
    const id = row.category_id
    const value = row.category_full_path
    if (value !== undefined) {
      updateStageValue(DESCRIPTION_EDITABLE_ATTRIBUTE.CATEGORY, id, value)
    }
  }
}
