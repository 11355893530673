import { cloneDeep } from 'lodash'

const FIELDS_WITH_JSON_CONFIGS = ['firehoseConfig', 'monthlyConfig', 'weeklyConfig', 'dailyConfig']

export const convertConfigsStrToJSON = feedModule => {
  const module = cloneDeep(feedModule)

  for (const key in module) {
    if (FIELDS_WITH_JSON_CONFIGS.includes(key)) {
      const config = module[key]
      try {
        module[key] = config ? JSON.parse(config) : null
      } catch (e) {
        module[key] = {
          ERROR: 'Original config is not a valid JSON. Please fix it!',
          ORIGINAL_CONFIG: config
        }
      }
    }
  }
  return module
}

export const convertConfigsJsonToStr = feedModule => {
  const module = cloneDeep(feedModule)

  for (const key in module) {
    if (FIELDS_WITH_JSON_CONFIGS.includes(key)) {
      module[key] = module[key] ? JSON.stringify(module[key]) : null
    }
  }
  return module
}

export const generateIdBaseOnName = name => {
  let id = name

  // trim
  id = id.trim()
  // to lower case
  id = id.toLowerCase()
  // change ' ' to '-'
  id = id.replace(/\s/g, '-')
  // allow only leters, numbers and '-'
  id = id.replace(/[^\w|-]/g, '')

  return id
}
