import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import constants from '../../../constants/constants'
import { Form, FormGroup, FormControl, Table, Tooltip, OverlayTrigger, Glyphicon, Button } from 'react-bootstrap'
import { onStatFilterChanged } from '../../../actions/search-actions'
import { onChangeWidgetEnabledStatus, updateStatsData } from '../../../actions/widget-actions'
import LargeNumValue from './descriptionTableCells/LargeNumValue'
import { simpleNumberFormatter } from '../../../utils/formatters'
import './data-summary.scss'
import cx from 'classnames'
import Loader from '../../../components/Loader'

const displayOptions = [
  {
    key: 'revenue',
    value: 'Revenue'
  },
  {
    key: 'count',
    value: 'Item Count'
  }
]
const CONSTANTS = {
  TOTAL_CONTEXT: 'Total Context',
  TOTAL_QUERY: 'Total Query',
  COUNT: 'count',
  REVENUE: 'revenue'
}
const {
  CONTEXT_REVENUE,
  CONTEXT_OTHER_REVENUE,
  CONTEXT_DESCRIPTION_COUNT,
  QUERIED_REVENUE,
  QUERIED_OTHER_REVENUE,
  QUERIED_DESCRIPTION_COUNT,
  STATS
} = constants.aggregations

class DataSummary extends Component {
  onFieldChange(ele) {
    this.props.onStatFilterChanged(ele.target.value)
  }

  renderStatValue = (type, refType, refName, hideTooltip = true) => {
    const { stats } = this.props
    const { selectedFilter } = stats
    /** If value missing render NA */
    if (!stats[type]) {
      return 'NA'
    }
    const currentValue = stats[type][selectedFilter]
    const totalValue = stats[refType] ? stats[refType][selectedFilter] : null
    const numberValue = (
      <LargeNumValue
        value={parseFloat(currentValue.toFixed(2))}
        prefix={selectedFilter === CONSTANTS.REVENUE ? '$' : ''}
        hideTooltip={hideTooltip}
      />
    )
    /** If totalValue missing, ignore percentage values and render numberValue as it is */
    if (!totalValue) {
      return numberValue
    }

    const percentValue = parseFloat((currentValue / totalValue) * 100).toFixed(2)
    const tooltip = () => (
      <Tooltip id="stat-widget-value">
        {`${percentValue}% of ${refName}`}
        <span style={{ display: 'block' }}>
          {`${selectedFilter === CONSTANTS.REVENUE ? '$' : ''}${simpleNumberFormatter(currentValue.toFixed(2))}`}
        </span>
      </Tooltip>
    )
    return (
      <OverlayTrigger placement="top" overlay={tooltip()}>
        <span>
          {numberValue}
          {refType && <strong>{`  (${percentValue}%)`}</strong>}
        </span>
      </OverlayTrigger>
    )
  }

  renderOptions = data => {
    return data.map(opt => {
      return (
        <option key={opt.key} value={opt.key}>
          {opt.value}
        </option>
      )
    })
  }

  render() {
    const { stats, width } = this.props
    const isLoading = () => {
      return (
        (stats[CONTEXT_REVENUE] && stats[CONTEXT_REVENUE].loading) ||
        (stats[CONTEXT_OTHER_REVENUE] && stats[CONTEXT_OTHER_REVENUE].loading) ||
        (stats[CONTEXT_DESCRIPTION_COUNT] && stats[CONTEXT_DESCRIPTION_COUNT].loading) ||
        (stats[QUERIED_REVENUE] && stats[QUERIED_REVENUE].loading) ||
        (stats[QUERIED_OTHER_REVENUE] && stats[QUERIED_OTHER_REVENUE].loading) ||
        (stats[QUERIED_DESCRIPTION_COUNT] && stats[QUERIED_DESCRIPTION_COUNT].loading)
      )
    }
    return (
      <div className={`data-summary-widget ${width > 405 ? '' : 'small'}`}>
        {/* Header Section starts  */}
        <div className="data-summary-header">
          <div className="pull-left title">Data Stats</div>
          <div
            onClick={() => this.props.onChangeWidgetEnabledStatus(STATS)}
            className={cx(
              'pull-left search-stats-status search-qc-statusButton',
              this.props.widgetEnabledStatus[STATS] ? 'active' : 'inactive'
            )}
          >
            <span className={'statusMarker'} />
          </div>
          <div className="pull-right option">
            <Form inline>
              <FormGroup controlId="field-selection">
                <FormControl
                  className="pull-right"
                  componentClass="select"
                  placeholder="select"
                  defaultValue={stats.selectedFilter}
                  onChange={this.onFieldChange.bind(this)}
                >
                  {this.renderOptions(displayOptions)}
                </FormControl>
              </FormGroup>
            </Form>
          </div>
          <div className="pull-right widget-enable-status">
            <Button
              bsSize="xsmall"
              bsStyle="default"
              className="action-button refresh"
              onClick={() => this.props.updateStatsData(STATS)}
            >
              <Glyphicon glyph="refresh" />
            </Button>
          </div>
        </div>
        {/* Header Section ends  */}
        {/* Show loading symbol on top of stats widget if any of the service is still in progress */}
        {isLoading() && (
          <div className="data-stats-loader">
            <Loader overlap height={70} />
          </div>
        )}
        {/* Table stats */}
        <Table responsive striped bordered>
          <thead>
            <tr>
              <th />
              <th>Total</th>
              <th>Brand “Other”</th>
              {stats.selectedFilter === CONSTANTS.COUNT && <th>Description Count</th>}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Context</td>
              <td>{this.renderStatValue(CONTEXT_REVENUE, null, null, false)}</td>
              <td>{this.renderStatValue(CONTEXT_OTHER_REVENUE, CONTEXT_REVENUE, CONSTANTS.TOTAL_CONTEXT)}</td>
              {stats.selectedFilter === CONSTANTS.COUNT && (
                <td>{this.renderStatValue(CONTEXT_DESCRIPTION_COUNT, null, null, false)}</td>
              )}
            </tr>
            <tr>
              <td>Query</td>
              <td>{this.renderStatValue(QUERIED_REVENUE, CONTEXT_REVENUE, CONSTANTS.TOTAL_CONTEXT)}</td>
              <td>{this.renderStatValue(QUERIED_OTHER_REVENUE, QUERIED_REVENUE, CONSTANTS.TOTAL_QUERY)}</td>
              {stats.selectedFilter === CONSTANTS.COUNT && (
                <td>{this.renderStatValue(QUERIED_DESCRIPTION_COUNT, null, null, false)}</td>
              )}
            </tr>
          </tbody>
        </Table>
        {/* Table ends */}
      </div>
    )
  }
}

DataSummary.propTypes = {
  onStatFilterChanged: PropTypes.func,
  selectedStatFilter: PropTypes.string,
  stats: PropTypes.object,
  width: PropTypes.number,
  onChangeWidgetEnabledStatus: PropTypes.func,
  widgetEnabledStatus: PropTypes.object,
  updateStatsData: PropTypes.func
}

function mapStateToProps(state) {
  return {
    stats: state.searchEdit.stats,
    widgetEnabledStatus: state.search.widgetEnabledStatus
  }
}

export default connect(mapStateToProps, { onStatFilterChanged, onChangeWidgetEnabledStatus, updateStatsData })(
  DataSummary
)
