import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

const BooleandHighlightedText = ({ isPass, useHighlight = true, children }) => {
  let content = children

  if (!children) {
    content = isPass ? 'PASSED' : 'FAILED'
  }

  return (
    <span
      className={cx({
        'category-test-result-tooltip-pass': useHighlight && isPass,
        'category-test-result-tooltip-failed': useHighlight && isPass === false
      })}
    >
      {content}
    </span>
  )
}

BooleandHighlightedText.propTypes = {
  isPass: PropTypes.bool,
  useHighlight: PropTypes.bool,
  children: PropTypes.element
}

export default BooleandHighlightedText
