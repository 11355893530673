import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { MerchantCategoryRulesActions } from '../../actions'

export default function useFormMerchantCategoryFilds(values, updateValue, mode) {
  const dispatch = useDispatch()

  const checkRule = (merchantId, categoryId) => {
    if (merchantId && categoryId) {
      dispatch(MerchantCategoryRulesActions.fetchByMerchantAndCategory({ merchantId, categoryId }))
    }
  }

  useEffect(() => {
    const { merchantId, categoryId } = values
    if (merchantId && categoryId && !mode.isRequestView) {
      checkRule(merchantId, categoryId)
    }
    // Not necessary to check dependencies
    // We run it only once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleMerchantChange = merchant => {
    const merchantId = merchant?.id
    const merchantName = merchant?.merchantName

    updateValue({ merchantId, merchantName })

    if (merchantId) {
      checkRule(merchantId, values.categoryId)
    }
  }

  const handleCategoryChange = category => {
    const categoryId = category?.id
    const categoryFullPath = category?.fullPath
    updateValue({ categoryId, categoryFullPath })

    if (categoryId) {
      checkRule(values.merchantId, categoryId)
    }
  }

  return [handleMerchantChange, handleCategoryChange]
}
