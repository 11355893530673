import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Glyphicon, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { confirmModal, addCustomModal } from './../../../actions/modal-actions'

class CommonRuleActionsCell extends Component {
  deleteModal = id => {
    const { confirmModal } = this.props

    confirmModal(
      <div>
        <span>
          {`Are you sure you want to delete rule `}
          <strong>
            <em>{`ID: ${id}`}</em>
          </strong>
          ?
        </span>
      </div>,
      'Confirm Delete',
      { okButton: `Remove` },
      () => this.props.deleteFunction(id)
    )
  }

  activateModal = id => {
    const { confirmModal } = this.props

    confirmModal(
      <div>
        <span>
          {`Are you sure you want to re-activate rule `}
          <strong>
            <em>{`ID: ${id}`}</em>
          </strong>
          ?
        </span>
      </div>,
      'Confirm Re-Activate',
      { okButton: `Re-Activate rule` },
      () => this.props.activateFunction(id)
    )
  }

  editModal = () => {
    const { addCustomModal, editModalContent, editModaTitle } = this.props

    addCustomModal({
      modalType: 'successModal',
      id: 'create-new-rule-modal',
      title: editModaTitle,
      size: 'large',
      content: editModalContent
    })
  }

  render() {
    const { activationTooltip, isActive, isActivationBlocked, id } = this.props

    let activationGlyph = ''
    let activationMethod = () => {}

    if (isActive) {
      activationGlyph = 'trash'
      activationMethod = this.deleteModal.bind(this, id)
    } else {
      if (isActivationBlocked) {
        activationGlyph = 'ban-circle'
        activationMethod = () => {}
      } else {
        activationGlyph = 'repeat'
        activationMethod = this.activateModal.bind(this, id)
      }
    }

    return (
      <div className="sb-table-action-cell">
        <OverlayTrigger placement="top" overlay={<Tooltip id="rule-edit-tooltip">Edit rule</Tooltip>}>
          <div onClick={this.editModal} title={`Edit rule`}>
            <Glyphicon glyph="edit" />
          </div>
        </OverlayTrigger>

        <OverlayTrigger placement="left" overlay={<Tooltip id="rule-activation-tooltip">{activationTooltip}</Tooltip>}>
          <div onClick={activationMethod}>
            <Glyphicon glyph={activationGlyph} />
          </div>
        </OverlayTrigger>
      </div>
    )
  }
}

CommonRuleActionsCell.propTypes = {
  editModaTitle: PropTypes.string.isRequired,
  editModalContent: PropTypes.element.isRequired,

  activationTooltip: PropTypes.string,
  isActive: PropTypes.bool,
  isActivationBlocked: PropTypes.bool,
  id: PropTypes.number,
  activateFunction: PropTypes.func.isRequired,
  deleteFunction: PropTypes.func.isRequired,

  addCustomModal: PropTypes.func.isRequired,
  confirmModal: PropTypes.func.isRequired
}

const mapDispatchToProps = {
  addCustomModal,
  confirmModal
}

export default connect(null, mapDispatchToProps)(CommonRuleActionsCell)
