import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { removeStagedEdit } from '../../../../actions/widget-actions'
import Staged from './EditValues/Staged'
import Processing from './EditValues/Processing'
import Processed from './EditValues/Processed'
import Unchanged from './EditValues/Unchanged'

class EditableDescriptionValue extends Component {
  static propTypes = {
    cell: PropTypes.string,
    dictKey: PropTypes.string,
    trackedValue: PropTypes.object,
    stagedValue: PropTypes.object,
    attribute: PropTypes.string,
    inDict: PropTypes.bool,
    removeStagedEdit: PropTypes.func,
    updatedBy: PropTypes.string
  }

  renderStagedValues = () => {
    return (
      <Staged
        stagedValue={this.props.stagedValue}
        dictKey={this.props.dictKey}
        attribute={this.props.attribute}
        removeStagedEdit={this.props.removeStagedEdit}
      />
    )
  }

  renderProcessingValues = () => {
    return (
      <Processing
        trackedValue={this.props.trackedValue}
        attribute={this.props.attribute}
        cell={this.props.cell}
        inDict={this.props.inDict}
      />
    )
  }

  renderProcessedValues = () => {
    return (
      <Processed
        trackedValue={this.props.trackedValue}
        attribute={this.props.attribute}
        cell={this.props.cell}
        inDict={this.props.inDict}
      />
    )
  }

  renderUnchangedValues = () => {
    return <Unchanged cell={this.props.cell} inDict={this.props.inDict} updatedBy={this.props.updatedBy} />
  }

  render() {
    if (this.props.stagedValue) {
      return this.renderStagedValues()
    }
    if (this.props.trackedValue && this.props.trackedValue.status !== 'DONE') {
      return this.renderProcessingValues()
    } else if (this.props.trackedValue) {
      return this.renderProcessedValues()
    } else {
      return this.renderUnchangedValues()
    }
  }
}

function mapStateToProps(state, ownProps) {
  let trackedValue = null
  let stagedValue = null
  if (state.searchEdit.description.trackedEdits && state.searchEdit.description.trackedEdits[ownProps.dictKey]) {
    trackedValue = state.searchEdit.description.trackedEdits[ownProps.dictKey][ownProps.attribute]
  }
  if (state.searchEdit.description.stagedEdits && state.searchEdit.description.stagedEdits[ownProps.dictKey]) {
    stagedValue = state.searchEdit.description.stagedEdits[ownProps.dictKey][ownProps.attribute]
  }
  return {
    trackedValue,
    stagedValue
  }
}

export default connect(mapStateToProps, {
  removeStagedEdit
})(EditableDescriptionValue)
