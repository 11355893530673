import { useState } from 'react'
import cuid from 'cuid'

const useActiveElementLoading = (loading, activeID) => {
  // generate unique element id
  const [id] = useState(cuid())

  // is Current element requests suggestions?
  const isActive = id === activeID

  // is Current element should show loading
  const isLoading = isActive && loading

  return [id, isLoading]
}

export default useActiveElementLoading
