import React, { useEffect, useState } from 'react'
import DocumentTitle from 'react-document-title'
import { Button, Col, Form, FormGroup, FormControl } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import {
  fetchLatestRetrainerDagRun,
  fetchLastSuccessfulRetrainedModel,
  triggerRetrainerDAG,
  triggerDeploymentDAG,
  fetchLatestDeploymentDagRun,
  fetchCurrentProductionModel,
  fetchRecentModels
} from './actions'
import InfoTooltip from 'components/Tooltips/InfoTooltip'
import WithErrorBoundaryWrapper from 'components/WithErrorBoundaryWrapper/WithErrorBoundaryWrapper'
import EmbeddedRenderer from '../EmbeddedRenderer'
import Loader from '../Loader'
import { HEADER_HEIGHT, FOOTER_HEIGHT, CME_RETRAINER_IFRAME_URL } from 'constants/constants'
import './retrainer.scss'
import UserService from 'services/user-service'

const tooltipsMap = {
  'current-production-model': 'Current production model ID from CME production API',
  'last-successful-retrained-model':
    'This field will either contain the last successful retrained model from Snowflake table if the last run of the retraining DAG was successful. Or it will contain the DAG run ID of the DAG from Airflow if the latest run is still running. If the last run failed for the retrainer DAG, it will simply mention the same',
  'latest-retrainer-dag-run-execution-date':
    'Execution date of the latest DAG run of the CME retrainer DAG from Airflow',
  'latest-retrainer-dag-run-status': 'Status of the latest DAG run of the CME retrainer DAG from Airflow',
  'trigger-retrainer-dag-run': 'Trigger CME retrainer DAG run',
  'recent-models-from-snowflake':
    'This dropdown lists the 5 most recent models from Snowflake table. You need to select the model you wish to pass as a parameter to the CME Deployment DAG',
  'latest-deployment-dag-run-start-date': 'Execution date of the latest CME deployment DAG run from Airflow',
  'latest-deployment-dag-run-status': 'Status date of the latest CME deployment DAG run from Airflow',
  'trigger-deployment-dag-run': 'Trigger CME deployment DAG run using the model ID selected from the dropdown above'
}

const RetrainerPage = () => {
  const dispatch = useDispatch()
  const {
    latestRetrainerDagRun,
    lastSuccessfulRetrainedModel,
    currentProductionModel,
    latestDeploymentDagRun,
    recentModels
  } = useSelector(state => state.cmeRetrainer)

  const { user: sessionUser } = useSelector(state => state.session)

  const isAdmin = UserService.isAdmin(sessionUser)

  const [selectedModel, setSelectedModel] = useState('')

  useEffect(() => {
    dispatch(fetchLatestRetrainerDagRun())
    dispatch(fetchLastSuccessfulRetrainedModel())
    dispatch(fetchLatestDeploymentDagRun())
    dispatch(fetchCurrentProductionModel())
    dispatch(fetchRecentModels())
  }, [dispatch])

  useEffect(() => {
    setSelectedModel(recentModels[0])
  }, [recentModels])

  const triggerRetrainerDAGRun = () => {
    dispatch(triggerRetrainerDAG())
  }

  const triggerDeploymentDAGRun = () => {
    dispatch(triggerDeploymentDAG(selectedModel))
  }

  const isPageReady = selectedModel?.id

  const handleSelectedModelChange = event => {
    const model = recentModels.find(model => model.full_model_version === event.target.value)
    setSelectedModel(model)
  }

  const retrainerModelIdText = () => {
    if (lastSuccessfulRetrainedModel?.model_version && latestRetrainerDagRun?.state === 'success') {
      return `Model ID: ${lastSuccessfulRetrainedModel?.model_version}`
    } else {
      if (latestRetrainerDagRun?.state === 'failed') {
        return 'Last retrainer DAG run failed'
      } else if (latestRetrainerDagRun?.state === 'running') {
        return `Running: ${latestRetrainerDagRun.dag_run_id}`
      }
      return latestRetrainerDagRun.dag_run_id
    }
  }

  const getModelName = model => {
    const suffix = `${model.full_model_version} ${model.creation_dag_id}`
    return model.name ? `(${model.name}) ${suffix}` : suffix
  }

  return (
    <DocumentTitle title="CME Retrainer">
      <div className="container-fluid mt-2">
        <div className="text-center">
          <h2>
            <u>CME Retrainer</u>
          </h2>
        </div>
        <div className="text-center mt-5">
          <h5>
            <span>
              <strong>Current Model ID: {currentProductionModel?.model_version}</strong>
              <InfoTooltip text={tooltipsMap['current-production-model']} trigger={['click', 'hover']} />
            </span>
            <span className="ml-3">{''}</span>
          </h5>
        </div>
        {isPageReady ? (
          <div className="mt-5 d-flex justify-content-around">
            <div className="container-fluid retrainer-container">
              <div className="text-center">
                <h4>
                  <strong>Retrain</strong>
                </h4>
              </div>
              <div>
                <Form horizontal className="mt-3">
                  <FormGroup>
                    <Col className="mt-3" sm={4}>
                      Last Successful Model
                      <InfoTooltip text={tooltipsMap['last-successful-retrained-model']} trigger={['click', 'hover']} />
                    </Col>

                    <Col sm={8}>
                      <FormControl bsClass="form-control" disabled type="text" value={retrainerModelIdText()} />
                    </Col>
                  </FormGroup>

                  <FormGroup>
                    <Col className="mt-3" sm={4}>
                      Last Retraining Date
                      <InfoTooltip
                        text={tooltipsMap['latest-retrainer-dag-run-execution-date']}
                        trigger={['click', 'hover']}
                      />
                    </Col>
                    <Col sm={8}>
                      <FormControl
                        bsClass="form-control"
                        type="text"
                        value={latestRetrainerDagRun.execution_date}
                        disabled
                      />
                    </Col>
                  </FormGroup>

                  <FormGroup>
                    <Col className="mt-3" sm={4}>
                      Last Retraining Status
                      <InfoTooltip text={tooltipsMap['latest-retrainer-dag-run-status']} trigger={['click', 'hover']} />
                    </Col>
                    <Col sm={8}>
                      <FormControl bsClass="form-control" type="text" value={latestRetrainerDagRun.state} disabled />
                    </Col>
                  </FormGroup>

                  {isAdmin && (
                    <Button bsStyle="primary" type="button" onClick={() => triggerRetrainerDAGRun()}>
                      Retrain
                      <InfoTooltip text={tooltipsMap['trigger-retrainer-dag-run']} trigger={['click', 'hover']} />
                    </Button>
                  )}
                </Form>
              </div>
            </div>

            {selectedModel?.full_model_version ? (
              <div className="container-fluid retrainer-container">
                <div className="text-center">
                  <h4>
                    <strong>Deploy</strong>
                  </h4>
                </div>
                <div>
                  <Form horizontal className="mt-3">
                    <FormGroup>
                      <Col className="mt-3" sm={4}>
                        Recent Models
                        <InfoTooltip text={tooltipsMap['recent-models-from-snowflake']} trigger={['click', 'hover']} />
                      </Col>

                      <Col sm={8}>
                        <FormControl
                          componentClass="select"
                          bsClass="form-control"
                          type="text"
                          placeholder="Select Model ID"
                          defaultValue={selectedModel.full_model_version}
                          onChange={handleSelectedModelChange}
                        >
                          <option key="default" value={null} disabled>
                            Select Model ID
                          </option>

                          {recentModels.map(model => (
                            <option key={model.full_model_version} value={model.full_model_version}>
                              {getModelName(model)}
                            </option>
                          ))}
                        </FormControl>
                      </Col>
                    </FormGroup>

                    <FormGroup>
                      <Col className="mt-3" sm={4}>
                        Last Deployment Date
                        <InfoTooltip
                          text={tooltipsMap['latest-deployment-dag-run-start-date']}
                          trigger={['click', 'hover']}
                        />
                      </Col>
                      <Col sm={8}>
                        <FormControl
                          bsClass="form-control"
                          type="text"
                          value={latestDeploymentDagRun.start_date}
                          disabled
                        />
                      </Col>
                    </FormGroup>

                    <FormGroup>
                      <Col className="mt-3" sm={4}>
                        Last Deployment Status
                        <InfoTooltip
                          text={tooltipsMap['latest-deployment-dag-run-status']}
                          trigger={['click', 'hover']}
                        />
                      </Col>
                      <Col sm={8}>
                        <FormControl bsClass="form-control" type="text" value={latestDeploymentDagRun.state} disabled />
                      </Col>
                    </FormGroup>

                    {isAdmin && (
                      <Button bsStyle="primary" type="button" onClick={() => triggerDeploymentDAGRun()}>
                        Deploy
                        <InfoTooltip text={tooltipsMap['trigger-deployment-dag-run']} trigger={['click', 'hover']} />
                      </Button>
                    )}
                  </Form>
                </div>
              </div>
            ) : (
              <Loader />
            )}
          </div>
        ) : (
          <Loader />
        )}

        <div className="mt-2">
          <EmbeddedRenderer
            sourceName={'supra-qc-monitoring'}
            url={CME_RETRAINER_IFRAME_URL}
            iframeHeight="100%"
            fullscreen
            styles={{
              height: `calc(100vh - ${HEADER_HEIGHT + FOOTER_HEIGHT}px)`,
              display: 'block'
            }}
          />
        </div>
      </div>
    </DocumentTitle>
  )
}

export default WithErrorBoundaryWrapper(RetrainerPage, '"CME Retrainer" page')
