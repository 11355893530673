import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Form, Button } from 'react-bootstrap'
import { default as SubStep } from './DeliverySubStep'

const subStepsConfig = {
  createDelTable: {
    showOutput: false,
    texts: {
      COMPLETED: 'Delivery table created',
      FAILED: 'Delivery table creation failed',
      PENDING: 'Delivery table not yet created',
      PROCESSING: 'Creating Delivery table...'
    }
  },
  populateDelTable: {
    showOutput: false,
    texts: {
      COMPLETED: 'Delivery table populated',
      FAILED: 'Delivery table population failed',
      PENDING: 'Delivery table not yet populated',
      PROCESSING: 'Populating Delivery table...'
    }
  },
  updateDelTable: {
    showOutput: false,
    texts: {
      COMPLETED: 'Data update scripts ran successfully',
      FAILED: 'Data update scripts failed',
      PENDING: 'Data update scripts have not been run',
      PROCESSING: 'Data update scripts are running...'
    }
  },
  createQaTable: {
    showOutput: true,
    texts: {
      COMPLETED: 'QA Table created',
      FAILED: 'QA Table creation failed',
      PENDING: 'QA Table creation pending',
      PROCESSING: 'Creating QA Table...'
    }
  },
  grantQaAccess: {
    showOutput: false,
    texts: {
      COMPLETED: 'Access granted to QA Table',
      FAILED: 'Grant access failed',
      PENDING: 'Grant access pending',
      PROCESSING: 'Granting access...'
    }
  }
}

const substeps = ['createDelTable', 'populateDelTable', 'updateDelTable', 'createQaTable', 'grantQaAccess']

const getLabel = (substep, state) => {
  return subStepsConfig[substep].texts[state]
}
export default class DataCustomization extends Component {
  renderActions = () => {
    const { qcRejected, startDataCustomization, editable } = this.props
    if (!editable) {
      return null
    }
    return (
      <div className="sb-form-footer">
        <div className="form-action-buttons">
          {qcRejected ? (
            <Button className="btn_custom_secondary btn-small" onClick={() => startDataCustomization(true)}>
              Rerun
            </Button>
          ) : null}
        </div>
      </div>
    )
  }

  render() {
    const { tasks, substepDetails, onToggleDetails, retryDeliveryStep, editable } = this.props
    return (
      <Form className="sb-form data-customization-panel">
        {substeps.map(substep => {
          const { status, output: details } = tasks[substep]
          const label = getLabel(substep, status)
          const showOutput = subStepsConfig[substep].showOutput
          return (
            <SubStep
              key={substep}
              step="dataCustomization"
              substep={substep}
              label={label}
              showDetails={substepDetails.selectedSubstep === substep && substepDetails.show}
              status={status}
              details={status === 'FAILED' || (status === 'COMPLETED' && showOutput) ? details : null}
              onToggleDetails={onToggleDetails}
              onRetry={retryDeliveryStep}
              hideRetry={!editable}
            />
          )
        })}
        {this.renderActions()}
      </Form>
    )
  }
}

DataCustomization.propTypes = {
  qcRejected: PropTypes.bool,
  tasks: PropTypes.object,
  substepDetails: PropTypes.object,
  startDataCustomization: PropTypes.func,
  onToggleDetails: PropTypes.func,
  retryDeliveryStep: PropTypes.func,
  editable: PropTypes.bool
}
