import React from 'react'
import { Controller } from 'react-hook-form'
import { Checkbox } from 'react-bootstrap'
import PropTypes from 'prop-types'
import InputField from 'components/FormInputComponents/InputField'

const UseBronzeInput = ({ control, errors, info }) => {
  return (
    <InputField
      label="Use Bronze"
      info={info}
      className="text-start"
      labelSize={4}
      validationMessage={errors.useBronze?.message}
    >
      <Controller
        name="useBronze"
        control={control}
        render={({ field }) => {
          return (
            <>
              <Checkbox {...field} checked={field.value} />
              {field.value && (
                <p className="text-danger">Are you sure you want to use Bronze Layer, this may slow performance?</p>
              )}
            </>
          )
        }}
      />
    </InputField>
  )
}

UseBronzeInput.propTypes = {
  control: PropTypes.any,
  info: PropTypes.string,
  errors: PropTypes.object
}

export default UseBronzeInput
