import React from 'react'
import PropTypes from 'prop-types'
import { ButtonToolbar, Button, Glyphicon } from 'react-bootstrap'

import './data-table-toolbar.scss'

const DataTableToolbar = props => {
  return <ButtonToolbar className="data-table-toolbar">{props.children}</ButtonToolbar>
}

DataTableToolbar.propTypes = {
  children: PropTypes.node
}

const TableToolbarButtons = ({ downloadHandler, refreshHandler, clearFiltersHandler }) => {
  return (
    <>
      {downloadHandler && (
        <Button bsStyle="default" onClick={() => downloadHandler()}>
          <Glyphicon glyph="download-alt" />
          Download CSV
        </Button>
      )}

      <Button bsStyle="default" onClick={() => refreshHandler()}>
        <Glyphicon glyph="refresh" />
        Refresh
      </Button>
      <Button bsStyle="default" onClick={() => clearFiltersHandler()}>
        <Glyphicon glyph="erase" />
        Clear Filters
      </Button>
    </>
  )
}

TableToolbarButtons.propTypes = {
  downloadHandler: PropTypes.func,
  refreshHandler: PropTypes.func.isRequired,
  clearFiltersHandler: PropTypes.func.isRequired
}

export { TableToolbarButtons }

export default DataTableToolbar
