import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import { find } from 'lodash'
import '../../../styles/widget.scss'
import '../../../styles/descriptionTable.scss'

class EditReviewTable extends Component {
  static propTypes = {
    data: PropTypes.arrayOf(PropTypes.object),
    stagedEdits: PropTypes.object
  }

  getData() {
    const allEdits = this.props.stagedEdits
    const tableData = []
    let sequence = 1
    let noOfChanges = 0
    const data = {}
    Object.keys(allEdits).forEach(dictionarKey => {
      let tableRow = {}
      const attributeObj = allEdits[dictionarKey]
      const value = find(this.props.data, d => d.dictionary_key === dictionarKey)
      if (!value || !attributeObj) {
        return
      }
      tableRow = {
        id: sequence,
        description: value.description,
        merchant: value.merchant_name,
        indictBrand: value.in_dict_brand,
        indictCategory: value.in_dict_category_fp,
        brand: value.brand_name,
        category: value.category_full_path
      }

      Object.keys(attributeObj).forEach(attribute => {
        noOfChanges++
        tableRow[`new${attribute}`] = attributeObj[attribute].name
      })
      tableData.push(tableRow)
      sequence++
    })
    data.tabledata = tableData
    data.noOfChanges = noOfChanges
    return data
  }

  getOptions = () => {
    return {
      sizePerPageList: [
        {
          text: '25',
          value: 25
        },
        {
          text: '50',
          value: 50
        },
        {
          text: '100',
          value: 100
        }
      ],
      sizePerPage: 25
    }
  }

  render() {
    const data = this.getData()
    let msg = ''
    const brandFormatter = (cell, row) => {
      return (
        <div>
          <span className={row.indictBrand ? 'in-dict' : 'not-in-dict'}>{row.brand}</span>
          {row.newbrand && <span className="in-staging">{` (${row.newbrand})`}</span>}
        </div>
      )
    }
    const categoryFormatter = (cell, row) => {
      return (
        <div>
          <span className={row.indictCategory ? 'in-dict' : 'not-in-dict'}>{row.category}</span>
          {row.newcategory && <span className="in-staging">{` (${row.newcategory})`}</span>}
        </div>
      )
    }
    if (!data || !data.tabledata || !data.tabledata.length) {
      msg = 'No records to save'
    } else {
      msg = `Are you sure you want to save ${data.noOfChanges} changes made to ${data.tabledata.length} descriptions?`
    }
    return (
      <div>
        <div className="editReviewDescription">{msg}</div>
        <div className="editReviewTable">
          <BootstrapTable data={data.tabledata} striped pagination condensed options={this.getOptions()}>
            <TableHeaderColumn dataField="id" isKey dataAlign="right" width={'50'}>
              #
            </TableHeaderColumn>
            <TableHeaderColumn dataField="description" dataSort>
              Description
            </TableHeaderColumn>
            <TableHeaderColumn dataField="merchant" dataSort>
              Merchant
            </TableHeaderColumn>
            <TableHeaderColumn dataField="brand" dataAlign="center" dataFormat={brandFormatter} dataSort>
              Brand
            </TableHeaderColumn>
            <TableHeaderColumn dataField="category" dataAlign="center" dataFormat={categoryFormatter} dataSort>
              Category
            </TableHeaderColumn>
          </BootstrapTable>
        </div>
      </div>
    )
  }
}

export default EditReviewTable
