import React from 'react'
import Box from '@mui/material/Box'
import PropTypes from 'prop-types'
import RestartAltRoundedIcon from '@mui/icons-material/RestartAltRounded'
import { useGetSupraOrchestratorItemQuery } from './tableDAGAPI'
import { calculateDurationFromMilliseconds } from 'utils/formatters'

const DurationCell = ({ row }) => {
  const { isLoading, data: orchestratorItem } = useGetSupraOrchestratorItemQuery(row.run_id)
  const duration = calculateDurationFromMilliseconds(orchestratorItem?.execution_duration)

  return <Box sx={{ p: 1 }}>{isLoading ? <RestartAltRoundedIcon /> : <span>{duration}</span>}</Box>
}

DurationCell.propTypes = {
  row: PropTypes.object
}

export default DurationCell
