import commonRtkApi from 'commonRtkApi.js'

const categoryBrandPathAPI = commonRtkApi.injectEndpoints({
  endpoints: builder => ({
    categoryBrandPath: builder.query({
      query: categoryId => {
        const params = {
          page: 1,
          pageSize: 1,
          filters: { id: { value: categoryId } },
          filterType: 'table'
        }

        return { url: `/rops/rules/category-brand-path`, method: 'GET', params }
      }
    })
  })
})

export const { useCategoryBrandPathQuery } = categoryBrandPathAPI
