import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Glyphicon, Button, OverlayTrigger } from 'react-bootstrap'

class Actionitem extends Component {
  static propTypes = {
    tooltip: PropTypes.func,
    class: PropTypes.string,
    iconType: PropTypes.string,
    onClick: PropTypes.func,
    tooltipValue: PropTypes.string
  }

  render() {
    return (
      <OverlayTrigger placement="top" overlay={this.props.tooltip(this.props.tooltipValue)}>
        <Button
          bsStyle={'link'}
          className={this.props.class}
          onClick={e => {
            e.preventDefault()
            this.props.onClick()
          }}
        >
          <Glyphicon glyph={this.props.iconType} />
        </Button>
      </OverlayTrigger>
    )
  }
}

export default Actionitem
