import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import BooleandHighlightedText from './../BooleandHighlightedText'
import ValueWithMark from './../ValueWithMark'
import { getMaxPassedViolation } from './../utils'

const formatDate = date =>
  moment(date)
    .utc()
    .format('MMM YYYY')

const FailedItems = ({ items, isActive, visibleCount = 3 }) => {
  const rows = []

  const visibleItems = items.slice(0, visibleCount)

  for (const item of visibleItems) {
    const date = formatDate(item.monthsInfo[0].date)
    const violation = item.monthsInfo[0].violation

    rows.push(
      <tr key={item.name}>
        <td className="text-capitalize">&ldquo;{item.name}&rdquo;</td>
        <td>
          <BooleandHighlightedText isPass={false} useHighlight={isActive}>
            <ValueWithMark mark="%" nullValue="no data">
              {violation}
            </ValueWithMark>
          </BooleandHighlightedText>
        </td>
        <td>
          {date} {item.more ? <i> +{item.more} more</i> : ''}
        </td>
      </tr>
    )
  }

  return rows.length > 0 ? (
    <div className="text-start mb-2">
      <table>
        <tbody>{rows}</tbody>
      </table>
    </div>
  ) : null
}

FailedItems.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({ name: PropTypes.string, monthsInfo: PropTypes.array, more: PropTypes.number })
  ),
  visibleCount: PropTypes.number,
  isActive: PropTypes.bool
}

const TrendViolationInfo = ({
  isActive,
  result,
  threshold,
  sensitivity,
  rank,
  resultMark,
  itemName,
  isOverallTrend = false
}) => {
  // ----------------- helpers ----------
  const getItemInfo = (name, failedMonths) => {
    const SHOW_MONTHS = 1
    const monthsSortedByViolation = failedMonths.sort((a, b) => b.violation - a.violation)

    const viewMonths = monthsSortedByViolation.slice(0, SHOW_MONTHS)
    return {
      name,
      monthsInfo: monthsSortedByViolation.slice(0, SHOW_MONTHS),
      more: failedMonths.length - viewMonths.length,
      maxViolation: failedMonths[0].violation
    }
  }

  const getFailedItems = result => {
    const failedItems = []

    for (const name in result) {
      const itemResult = result[name]

      const failedMonths = itemResult.filter(r => !r.isPass)

      if (failedMonths.length > 0) {
        failedItems.push(getItemInfo(name, failedMonths))
      }
    }

    // (merchants, brend etc) trends with the highest violation should be first
    const sortedFailedItems = failedItems.sort((a, b) => b.maxViolation - a.maxViolation)

    return sortedFailedItems
  }

  // ----------------- ^^^ helpers ^^^ ----------

  const maxPassedViolation = result ? getMaxPassedViolation(result) : null

  const failedItems = getFailedItems(result)
  const failedItemsCount = failedItems.length

  const isPassed = failedItemsCount === 0
  return (
    <>
      <div>
        <span>Violation </span>
        {result ? (
          isPassed ? (
            <BooleandHighlightedText isPass useHighlight={isActive}>
              <ValueWithMark mark={resultMark}>{maxPassedViolation}</ValueWithMark>
            </BooleandHighlightedText>
          ) : (
            <>
              {isOverallTrend ? (
                <>
                  <BooleandHighlightedText isPass={false} useHighlight={isActive}>
                    <ValueWithMark mark="%" nullValue="no data">
                      {failedItems[0]?.monthsInfo[0].violation}
                    </ValueWithMark>
                  </BooleandHighlightedText>{' '}
                  {formatDate(failedItems[0]?.monthsInfo[0].date)}{' '}
                  {failedItems[0].more ? <i> +{failedItems[0].more} more</i> : ''}
                </>
              ) : (
                <>
                  <span>in </span>
                  <BooleandHighlightedText isPass={false} useHighlight={isActive}>
                    <>
                      {failedItemsCount} {itemName}
                      {failedItemsCount > 1 ? 's' : ''}
                    </>
                  </BooleandHighlightedText>
                  <FailedItems items={failedItems} isActive={isActive} />
                </>
              )}
            </>
          )
        ) : (
          'null'
        )}
      </div>
      <div>
        <span className="pr-3">
          Threshold: <ValueWithMark mark={resultMark}>{threshold}</ValueWithMark>
        </span>
        <span className="pr-3">
          Sensitivity: <ValueWithMark mark={resultMark}>{sensitivity}</ValueWithMark>
        </span>
        {!isOverallTrend && (
          <span>
            Rank: <ValueWithMark>{rank}</ValueWithMark>
          </span>
        )}
      </div>
    </>
  )
}

TrendViolationInfo.displayName = 'TooltipViolation'

TrendViolationInfo.propTypes = {
  itemName: PropTypes.string,
  result: PropTypes.object,
  isActive: PropTypes.bool,
  threshold: PropTypes.number,
  sensitivity: PropTypes.number,
  rank: PropTypes.number,
  resultMark: PropTypes.string,
  isOverallTrend: PropTypes.bool
}

export default TrendViolationInfo
