import React from 'react'
import { Glyphicon, OverlayTrigger, Tooltip } from 'react-bootstrap'
import cx from 'classnames'

const LimitStagingDeliveryWarning = () => {
  const environment = process.env.REACT_APP_DEPLOY_ENV.toLowerCase()
  const isStaging = environment === 'staging'
  const isDevelopment = environment === 'development'

  const isShow = isStaging || isDevelopment
  if (!isShow) {
    return null
  }
  return (
    <OverlayTrigger
      placement="top"
      overlay={
        <Tooltip id="">
          In the <i>{environment}</i> environment, we{' '}
          <strong>disable scheduling (run automatically) feeds with &quot;startDate&quot; older than 1 month</strong>.
          This is to protect the system from unnecessary queries to production resources (Redshift and Snowflake).
        </Tooltip>
      }
    >
      <Glyphicon glyph="warning-sign" className={cx('FeedEditPage__delivery_icon_warn')} />
    </OverlayTrigger>
  )
}

export default LimitStagingDeliveryWarning
