import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import CategoryBrandRuleModal from './Modals/CategoryBrandRuleModal'
import CommonRuleActionsCell from './../Common/CommonRuleActionsCell'

import { CategoryBrandRulesActions } from './../actions'

class CategoryBrandActionsCell extends Component {
  deleteFunction = id => {
    this.props.saveRuleAction({ id, isActive: false })
  }

  activateFunction = id => {
    this.props.saveRuleAction({ id, isActive: true })
  }

  getEditModal = () => {
    const { id, categoryId, categoryFullPath, brandId, brandFullPath, isMatchAnywhere } = this.props.row
    const { saveRuleAction } = this.props
    const brand = { brandId, brandFullPath, isMatchAnywhere }

    return (
      <CategoryBrandRuleModal
        value={{
          id,
          categoryId,
          categoryFullPath,
          brands: [brand]
        }}
        onOkHandler={saveRuleAction}
      />
    )
  }

  render() {
    const { id, isActive, brandIsActive, categoryIsActive, ruleIsActive } = this.props.row
    let activationTooltip = ''
    let isActivationBlocked = false

    if (isActive) {
      activationTooltip = 'Delete rule'
    } else if (!brandIsActive) {
      activationTooltip = "Can't reactivate because related Brand is inactive"
      isActivationBlocked = true
    } else if (!categoryIsActive) {
      activationTooltip = "Can't reactivate because related Category is inactive"
      isActivationBlocked = true
    } else if (!ruleIsActive && brandIsActive && categoryIsActive) {
      activationTooltip = 'Re-Activate'
    }

    return (
      <CommonRuleActionsCell
        editModaTitle="Edit Category Brand Rule (CBR)"
        editModalContent={this.getEditModal()}
        id={id}
        isActive={isActive}
        isActivationBlocked={isActivationBlocked}
        activationTooltip={activationTooltip}
        deleteFunction={this.deleteFunction}
        activateFunction={this.activateFunction}
      />
    )
  }
}

CategoryBrandActionsCell.propTypes = {
  saveRuleAction: PropTypes.func.isRequired,
  row: PropTypes.object.isRequired
}

const mapDispatchToProps = {
  saveRuleAction: CategoryBrandRulesActions.save
}

export default connect(null, mapDispatchToProps)(CategoryBrandActionsCell)
