import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Form, Button } from 'react-bootstrap'
import RVTable from '../../../Table/ReactVirtualizedTable'
import { fetchCategoriesCleanupStatus, createCategoryCleanupJob } from '../../../../actions/delivery-actions'
import moment from 'moment'
import cx from 'classnames'
import { difference } from 'lodash'

import { DELIVERY_JOB_STATUS } from '../../../../constants/deliveryJobsConstants'

const HEADER_HEIGHT = 42
const ROW_HEIGHT = 50
const MAX_TABLE_HEIGHT = 480
const MIN_BATCH_SIZE = 25
const DATE_TIME_FORMAT = 'MM/DD/YYYY hh:mm A'

class DataCleaning extends Component {
  componentDidMount() {
    this.fetchCategoriesCleanupStatus()
  }

  componentDidUpdate(prevProps) {
    const { categoryIds, deliveryDate } = this.props
    if (
      categoryIds.length &&
      (deliveryDate !== prevProps.deliveryDate || // delivery deadline edited
        difference(categoryIds, prevProps.categoryIds).length) // category edited
    ) {
      this.fetchCategoriesCleanupStatus()
    }
  }

  fetchCategoriesCleanupStatus = () => {
    const { categoryIds, fetchCategoriesCleanupStatus, deliveryDate } = this.props
    if (deliveryDate && categoryIds.length) {
      fetchCategoriesCleanupStatus({
        categoryIds,
        deliveryDate
      })
    }
  }

  renderActions = data => {
    const cleanupComplete = data.filter(item => item.status !== DELIVERY_JOB_STATUS.PUBLISHED).length === 0
    const { categoryCleanupStatus, proceedToDataCustomization, editable } = this.props
    if (!editable) {
      return null
    }
    return (
      <div className="sb-form-footer">
        <div className="form-action-buttons">
          {categoryCleanupStatus === 'PENDING' &&
            (cleanupComplete ? (
              <Button className="btn_custom_secondary btn-small" onClick={() => proceedToDataCustomization(false)}>
                Start Next Step
              </Button>
            ) : (
              <Button className="btn_custom_secondary btn-small" onClick={() => proceedToDataCustomization(true)}>
                Skip Current Step
              </Button>
            ))}
        </div>
      </div>
    )
  }

  calcTableHeight = noOfRows => {
    const actualHeight = HEADER_HEIGHT + noOfRows * ROW_HEIGHT
    return Math.min(actualHeight, MAX_TABLE_HEIGHT)
  }

  renderDeliveryStatus = job => {
    const { status = '', categoryId, isDelayed } = job || {}
    const { editable } = this.props
    return (
      <div className={cx('table-cell category-job-status', status.toLowerCase())}>
        {status === DELIVERY_JOB_STATUS.UNSCHEDULED && editable ? (
          <span
            className="btn_custom_secondary action-button"
            onClick={() => this.createCategoryCleanupJob(categoryId)}
          >
            Request Cleanup
          </span>
        ) : (
          `${status}`
        )}
        {isDelayed && <div className="delayed">(DELAYED)</div>}
      </div>
    )
  }

  createCategoryCleanupJob = categoryId => {
    const { deliveryDate, deliveryId, createCategoryCleanupJob } = this.props

    createCategoryCleanupJob({
      categoryId,
      deliveryDate,
      deliveryId
    })
  }

  getColumns = () => {
    const columns = []
    columns.push(
      {
        key: 'categoryId',
        label: 'ID',
        width: 40,
        flexGrow: 0,
        flexShrink: 0
      },
      {
        key: 'fullPath',
        label: 'CATEGORY',
        className: 'category-full-path-cell'
      },
      {
        key: 'deliveryDate',
        label: 'DEADLINE',
        width: 150,
        className: 'cat-job-cell',
        headerStyle: { textAlign: 'center' },
        flexGrow: 0,
        flexShrink: 0
      },
      {
        key: 'dateDelivered',
        label: 'DATE DELIVERED',
        width: 150,
        className: 'cat-job-cell',
        headerStyle: { textAlign: 'center' },
        flexGrow: 0,
        flexShrink: 0
      },
      {
        key: 'status',
        label: 'STATUS',
        width: 150,
        className: 'cat-job-cell',
        headerStyle: { textAlign: 'center' },
        flexGrow: 0,
        flexShrink: 0,
        cellRenderer: this.renderDeliveryStatus
      }
    )
    return columns
  }

  formatDateTime = date => {
    return date ? moment(date).format(DATE_TIME_FORMAT) : ''
  }

  getTableData = () => {
    const { categoryIds, cleanupJobs } = this.props
    return categoryIds.map(categoryId => {
      const { category: { fullPath = '' } = {}, deliveryDate, dateDelivered = '', status, isDelayed } =
        cleanupJobs[categoryId] || {}
      return {
        categoryId,
        fullPath: fullPath || '--',
        deliveryDate: this.formatDateTime(deliveryDate) || '--',
        dateDelivered: this.formatDateTime(dateDelivered) || '--',
        status: status || '--',
        isDelayed
      }
    })
  }

  render() {
    const data = this.getTableData() || []
    return (
      <Form className="sb-form category-cleanup-jobs">
        {
          <RVTable
            columns={this.getColumns()}
            data={data}
            fetchData={this.fetchData}
            minimumBatchSize={MIN_BATCH_SIZE}
            searchPlaceHolder="Search category name"
            rowHeight={ROW_HEIGHT}
            headerHeight={HEADER_HEIGHT}
            height={this.calcTableHeight(data.length)}
            fetchCount={MIN_BATCH_SIZE}
          />
        }
        {data.length === 0 && <div className="no-category-data-msg">{'NO DATA FOUND'}</div>}
        {this.renderActions(data)}
      </Form>
    )
  }
}

DataCleaning.propTypes = {
  proceedToDataCustomization: PropTypes.func,
  categoryIds: PropTypes.array,
  data: PropTypes.array,
  fetchCategoriesCleanupStatus: PropTypes.func,
  cleanupJobs: PropTypes.object,
  deliveryDate: PropTypes.string,
  createCategoryCleanupJob: PropTypes.func,
  deliveryId: PropTypes.number,
  categoryCleanupStatus: PropTypes.string,
  editable: PropTypes.bool
}

function mapStateToProps({
  deliveryConfig: { deliverableData: { categories = [] } = {} },
  delivery: { cleanupJobs = {}, currentDelivery: { deliveryDate, id: deliveryId } = {} }
}) {
  const categoryIds = categories.map(({ deliveryCategoryId }) => deliveryCategoryId)
  return {
    categoryIds,
    cleanupJobs,
    deliveryDate,
    deliveryId
  }
}

export default connect(mapStateToProps, {
  fetchCategoriesCleanupStatus,
  createCategoryCleanupJob
})(DataCleaning)
