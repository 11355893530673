import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { cloneDeep } from 'lodash'

import Loader from '../../Loader'
import { DataTableFiltered, BooleanCell } from './../../DataTable'

import CategoryBrandRuleModal from './Modals/CategoryBrandRuleModal'
import CommonRulesToolbar from './../Common/CommonRulesToolbar'
import CategoryBrandActionsCell from './CategoryBrandActionsCell'

import { CategoryBrandRulesActions } from './../actions'

import COMMON_COLUMNS from './../Common/commonColumns'
import { STORE_PATH } from './../constants'

class RulesCategoryBrand extends Component {
  constructor(props) {
    super(props)

    this.columns = this.getColumns(cloneDeep(COMMON_COLUMNS))
  }

  getColumns(commonColumns) {
    const columns = [
      commonColumns.id,
      commonColumns.categoryId,
      commonColumns.categoryFullPath,
      commonColumns.categoryIsLeaf,
      commonColumns.brandId,
      commonColumns.brandFullPath,
      commonColumns.updatedAt,
      commonColumns.updatedByName,
      {
        field: 'isMatchAnywhere',

        dataAlign: 'center',
        headerAlign: 'center',
        width: '80px',

        label: 'Match',
        title: 'Match Anywhere',
        dataSort: true,
        dataFormat: (cell, row) => BooleanCell({ isActive: cell }),
        filter: {
          type: 'SelectFilter',
          options: {
            1: 'True',
            0: 'False'
          }
        }
      },
      commonColumns.isActive,
      {
        ...commonColumns.actionsColumn,
        dataFormat: (cell, row) => <CategoryBrandActionsCell row={row} />
      }
    ]

    return columns
  }

  render() {
    const {
      loading,
      pageSize,
      hasEditAccess,
      totalCount,
      page,
      rows,
      sortBy,
      sortOrder,
      filters,
      fetchRulesAction,
      downloadRulesAction,
      clearFilters,
      saveRuleAction
    } = this.props

    return (
      <React.Fragment>
        <CommonRulesToolbar
          hasEditAccess={hasEditAccess}
          toolbarType="Category Brand"
          createRuleModalContet={<CategoryBrandRuleModal onOkHandler={saveRuleAction} />}
          downloadRulesAction={downloadRulesAction}
          refreshHandler={fetchRulesAction}
          clearFilters={clearFilters}
        />

        {loading && <Loader loading overlap />}

        <DataTableFiltered
          data={rows}
          columns={this.columns}
          page={page}
          pageSize={pageSize}
          totalCount={totalCount}
          sortBy={sortBy}
          sortOrder={sortOrder}
          filters={filters}
          tableClassName="rules-table"
          fetchRowsAction={fetchRulesAction}
        />
      </React.Fragment>
    )
  }
}

RulesCategoryBrand.defaultProps = {
  rows: [],
  totalCount: 0
}

RulesCategoryBrand.propTypes = {
  page: PropTypes.number,
  pageSize: PropTypes.number,
  sortBy: PropTypes.string,
  sortOrder: PropTypes.string,
  filters: PropTypes.object,
  loading: PropTypes.bool.isRequired,
  hasEditAccess: PropTypes.bool.isRequired,
  totalCount: PropTypes.number.isRequired,
  rows: PropTypes.arrayOf(PropTypes.object).isRequired,
  fetchRulesAction: PropTypes.func.isRequired,
  downloadRulesAction: PropTypes.func.isRequired,
  clearFilters: PropTypes.func.isRequired,
  saveRuleAction: PropTypes.func.isRequired
}

const mapStateToProps = ({ rules: { [STORE_PATH.categoryBrand]: categoryBrand } }) => ({
  page: categoryBrand.page,
  sortBy: categoryBrand.sortBy,
  loading: categoryBrand.loading,
  rows: categoryBrand.data.rows,
  pageSize: categoryBrand.pageSize,
  sortOrder: categoryBrand.sortOrder,
  totalCount: categoryBrand.data.count,
  filters: categoryBrand.filters
})

const mapDispatchToProps = {
  fetchRulesAction: CategoryBrandRulesActions.fetch,
  downloadRulesAction: CategoryBrandRulesActions.download,
  clearFilters: CategoryBrandRulesActions.clearFilters,
  saveRuleAction: CategoryBrandRulesActions.save
}

export default connect(mapStateToProps, mapDispatchToProps)(RulesCategoryBrand)
