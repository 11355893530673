import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import DocumentTitle from 'react-document-title'

import { Row, Col } from 'react-bootstrap'
import Loader from '../../Loader'
import DataTable from './../../DataTable'
import buildDeliverableColumns from '../DeliverablesPage/deliverable-columns'
import { createDeliveriesLinkUrl } from '../../../utils/delivery-center'

import {
  fetchDeliverables,
  updateDeliverableStatus,
  refreshDeliveriesProgress,
  deleteDeliverable,
  downloadDeliverables
} from '../../../actions/dm-actions'
import { confirmModal } from '../../../actions/modal-actions'
import { appName } from '../../../constants/constants'
import ReactInterval from 'react-interval'
import UserService from '../../../services/user-service'

const PROGRESS_REFRESH_INTERVAL = 5000

class DeliverablesPage extends Component {
  componentDidMount() {
    this.fetchDeliverables({ showInactive: this.props.showInactive })
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.selectedClientId !== this.props.selectedClientId ||
      prevProps.selectedContractId !== this.props.selectedContractId
    ) {
      this.fetchDeliverables({ page: 1, showInactive: this.props.showInactive })
    }
  }

  confirmDeleteForm = deliverable => {
    const { isPortalAdmin, confirmModal, deleteDeliverable } = this.props
    if (isPortalAdmin) {
      confirmModal(
        `Are you sure you want to delete Deliverable - "${deliverable.name}" ? It will also delete all the Deliveries under it. This action cannot be undone.`,
        `Delete "${deliverable.name}"`,
        { okButton: 'Delete' },
        () => deleteDeliverable(deliverable.id)
      )
    } else {
      confirmModal(
        `You do not have permission to delete Deliverable - "${deliverable.name}". Please contact portal admin to delete it.`,
        `Oops!`,
        { okButton: null }
      )
    }
  }

  onEdit = deliverable => {
    this.props.history.push(createDeliveriesLinkUrl(deliverable, 'config'))
  }

  onToggleStatus = deliverable => {
    this.props.updateDeliverableStatus({
      id: deliverable.id,
      isActive: !deliverable.isActive
    })
  }

  routeToNewDeliverableForm = () => {
    this.props.history.push(createDeliveriesLinkUrl())
  }

  onSortChange = (sortBy, sortOrder) => {
    this.fetchDeliverables({
      sortBy,
      sortOrder,
      page: 1,
      showInactive: this.props.showInactive
    })
  }

  onPageChange = page => {
    this.fetchDeliverables({
      page,
      showInactive: this.props.showInactive
    })
  }

  trClassName = row => {
    return row.isActive === false ? 'inactive' : ''
  }

  fetchDeliverables = payload => {
    const {
      sortBy = this.props.sortBy,
      sortOrder = this.props.sortOrder,
      page = this.props.page,
      pageSize = this.props.pageSize,
      showInactive = this.props.showInactive
    } = payload || {}
    this.props.fetchDeliverables({
      sortBy,
      sortOrder,
      page,
      pageSize,
      showInactive
    })
  }

  getDeliveryProgress = currentDeliveryId => {
    return this.props.deliveriesProgress[currentDeliveryId] || null
  }

  refreshDeliveriesProgress = () => {
    const { data, refreshDeliveriesProgress } = this.props
    const currentDeliveryIds = data.map(({ currentDeliveryId }) => currentDeliveryId).filter(deliveryId => deliveryId)
    if (currentDeliveryIds.length) {
      return refreshDeliveriesProgress(currentDeliveryIds)
    }
  }

  handleDownload = clickEvent => {
    clickEvent.preventDefault()
    this.props.downloadDeliverables()
  }

  render() {
    const {
      page = 1,
      data,
      totalDeliverableCount = 0,
      pageSize,
      sortBy,
      sortOrder,
      loading,
      documentTitle
    } = this.props
    const maxPages = Math.ceil(totalDeliverableCount / pageSize) || 1
    const columns = buildDeliverableColumns({
      onEdit: this.onEdit,
      onToggleStatus: this.onToggleStatus,
      getDeliveryProgress: this.getDeliveryProgress,
      onDelete: this.confirmDeleteForm
    })
    return (
      <DocumentTitle title={documentTitle ? `${appName} | ${documentTitle}` : appName}>
        <div>
          {loading && <Loader overlap />}

          <Row>
            <Col sm={12} className="action-row">
              <button
                onClick={this.handleDownload}
                className="btn_custom_secondary download-csv-button-custom pull-right"
              >
                Download CSV
              </button>

              <button className={'btn_custom pull-right'} type="button" onClick={this.routeToNewDeliverableForm}>
                {`Create New Deliverable`}
              </button>
            </Col>
          </Row>

          <DataTable
            ref={el => {
              this.dataTable = el
            }}
            data={data}
            trClassName={this.trClassName}
            tableClassName="deliverables-table"
            columns={columns}
            page={page}
            maxPages={maxPages}
            onPageChange={this.onPageChange}
            sortName={sortBy}
            sortOrder={sortOrder}
            onSortChange={this.onSortChange}
          />
          <ReactInterval timeout={PROGRESS_REFRESH_INTERVAL} enabled callback={this.refreshDeliveriesProgress} />
        </div>
      </DocumentTitle>
    )
  }
}

DeliverablesPage.propTypes = {
  history: PropTypes.object,
  totalDeliverableCount: PropTypes.number,
  selectedClientId: PropTypes.number,
  selectedContractId: PropTypes.number,
  selectedDeliverableId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  page: PropTypes.number,
  pageSize: PropTypes.number,
  sortBy: PropTypes.string,
  sortOrder: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool,
  fetchDeliverables: PropTypes.func,
  updateDeliverableStatus: PropTypes.func,
  documentTitle: PropTypes.string,
  deliveriesProgress: PropTypes.object,
  refreshDeliveriesProgress: PropTypes.func,
  confirmModal: PropTypes.func,
  deleteDeliverable: PropTypes.func,
  isPortalAdmin: PropTypes.bool,
  showInactive: PropTypes.bool,
  downloadDeliverables: PropTypes.func
}

DeliverablesPage.defaultProps = {
  documentTitle: 'Delivery Manager - Deliverables'
}

function mapStateToProps({
  dm: {
    deliverables,
    totalDeliverableCount,
    selectedClient: { id: selectedClientId },
    selectedContract: { id: selectedContractId },
    selectedDeliverable: { id: selectedDeliverableId },
    showInactive
  },
  session: { user } = {}
}) {
  return {
    totalDeliverableCount,
    page: deliverables.page,
    pageSize: deliverables.pageSize,
    sortBy: deliverables.sortBy,
    sortOrder: deliverables.sortOrder,
    data: deliverables.data,
    loading: deliverables.loading,
    deliveriesProgress: deliverables.deliveriesProgress,
    selectedClientId,
    selectedContractId,
    selectedDeliverableId,
    isPortalAdmin: user ? UserService.isPortalAdmin(user) : false,
    showInactive
  }
}
export default connect(mapStateToProps, {
  fetchDeliverables,
  updateDeliverableStatus,
  refreshDeliveriesProgress,
  confirmModal,
  deleteDeliverable,
  downloadDeliverables
})(DeliverablesPage)
