import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import ScriptEditor from './script-editor'
import { Form, Button } from 'react-bootstrap'
import Loader from '../../../../Loader'

import {
  loadScriptsData,
  updateScriptsForm,
  resetScriptsForm,
  saveScriptsForm,
  fetchScriptTemplate
} from '../../../../../actions/delivery-config-actions'

import { confirmModal } from '../../../../../actions/modal-actions'

import './index.scss'

class ScriptsConfig extends Component {
  componentDidMount() {
    this.props.fetchScriptTemplate()
  }

  componentDidUpdate(prevProps) {
    const { loadScriptsData, deliverableId, active, updateScript } = this.props
    if (active && !prevProps.active && deliverableId && deliverableId !== 'new' && updateScript === undefined) {
      loadScriptsData(deliverableId)
    }
  }

  handleScriptChange = value => {
    const { updateScriptsForm } = this.props
    updateScriptsForm({ updateScript: value, dirty: true })
  }

  hasValidChangesToSave = () => {
    const { dirty, updateScript } = this.props
    return dirty && updateScript
  }

  saveHandler = () => {
    const { onComplete, onNext, isLastPendingStep } = this.props
    let callback = onComplete
    if (isLastPendingStep) {
      callback = () => {
        onComplete()
        onNext()
      }
    }
    this.saveScriptsFormForDeliverable(callback)
  }

  saveScriptsFormForDeliverable = callback => {
    const { saveScriptsForm, updateScript, deliverableId } = this.props
    const payload = {
      deliverableId,
      updateScript
    }
    saveScriptsForm(payload, callback)
  }

  renderActions = () => {
    const { resetScriptsForm, editable, dirty, isLastPendingStep } = this.props
    return (
      <div className="sb-form-footer">
        <div className="form-action-buttons">
          {editable && (
            <React.Fragment>
              <Button className="btn-cancel btn-small" disabled={!dirty} onClick={resetScriptsForm}>
                Cancel
              </Button>
              <Button
                disabled={!this.hasValidChangesToSave()}
                className="btn_custom_secondary btn-small"
                onClick={this.saveHandler}
              >
                {isLastPendingStep ? 'Complete' : 'Save'}
              </Button>
            </React.Fragment>
          )}
        </div>
      </div>
    )
  }

  render() {
    const { editable, loading, scriptTemplate, updateScript, confirmModal } = this.props

    return (
      <div className="scripts-config-form">
        {loading && <Loader overlap />}
        <Form className="sb-form">
          <ScriptEditor
            required
            label="Update Script"
            readOnly={!editable}
            script={updateScript}
            confirmModal={confirmModal}
            defaultScript={scriptTemplate}
            onChange={this.handleScriptChange}
          />
          {this.renderActions()}
        </Form>
      </div>
    )
  }
}

ScriptsConfig.propTypes = {
  active: PropTypes.bool,
  loading: PropTypes.bool,
  deliverableId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  editable: PropTypes.bool,
  dirty: PropTypes.bool,
  updateScript: PropTypes.string,
  loadScriptsData: PropTypes.func,
  isLastPendingStep: PropTypes.bool,
  updateScriptsForm: PropTypes.func,
  resetScriptsForm: PropTypes.func,
  saveScriptsForm: PropTypes.func,
  onComplete: PropTypes.func,
  onNext: PropTypes.func,
  fetchScriptTemplate: PropTypes.func,
  scriptTemplate: PropTypes.string,
  confirmModal: PropTypes.func
}

function mapStateToProps({ dm: { selectedDeliverable: { id: deliverableId } = {} }, deliveryConfig: { scriptsForm } }) {
  return {
    ...scriptsForm,
    deliverableId
  }
}

export default connect(mapStateToProps, {
  loadScriptsData,
  updateScriptsForm,
  resetScriptsForm,
  saveScriptsForm,
  fetchScriptTemplate,
  confirmModal
})(ScriptsConfig)
