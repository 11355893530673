/**
 * Attributes that we can edit in NIQ Description table
 * @typedef {Object} DESCRIPTION_EDITABLE_ATTRIBUTE
 * @property {string} BRAND - BRAND
 * @property {string} CATEGORY - category
 */
export const DESCRIPTION_EDITABLE_ATTRIBUTE = {
  BRAND: 'brand',
  CATEGORY: 'category'
}
