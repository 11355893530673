import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Tooltip, OverlayTrigger } from 'react-bootstrap'
import { connect } from 'react-redux'

import { retryTask, cancelTask, deleteTask, doAgainTask } from '../../actions/task-actions'

import UserService from '../../services/user-service'

import config from './../../config'

class JobInteractions extends Component {
  static tooltipFromInteraction = {
    cancel: 'The job will be cancelled',
    retry: 'The job will be rescheduled as a new entry of the same batch',
    delete: "Delete only removes the entry from the job list. It doesn't rollback the job",
    doagain: 'The job will be copied with a pending status and empty output'
  }

  constructor(args) {
    super(args)
    if (this.props.isPortalAdmin || config.allowJobRetryOnSuccess === true) {
      this.availableInteractions.success.push('doagain')
    }
  }

  availableInteractions = {
    pending: ['cancel'],
    processing: ['cancel'],
    retried: ['delete'],
    paused: [],
    success: ['delete'],
    cancelled: ['retry', 'delete'],
    failed: ['retry', 'delete']
  }

  dispatchFromInteraction(interaction) {
    switch (interaction) {
      case 'cancel':
        return () => this.props.cancelTask(this.props.job)
      case 'retry':
        return () => this.props.retryTask(this.props.job)
      case 'delete':
        return () => this.props.deleteTask(this.props.job)
      case 'doagain':
        return () => this.props.doAgainTask(this.props.job)
      default:
    }
  }

  render() {
    const { job } = this.props

    return (
      <div>
        {this.availableInteractions[job.status].map(availableInteraction => (
          <OverlayTrigger
            key={`key-job-action-${availableInteraction}`}
            placement="bottom"
            overlay={
              <Tooltip id={`tooltip-${availableInteraction}`}>
                {JobInteractions.tooltipFromInteraction[availableInteraction]}
              </Tooltip>
            }
          >
            <span
              className="btn_link"
              style={{ marginRight: '10px' }}
              onClick={this.dispatchFromInteraction(availableInteraction)}
            >
              {availableInteraction}
            </span>
          </OverlayTrigger>
        ))}
      </div>
    )
  }
}

JobInteractions.propTypes = {
  job: PropTypes.object,
  cancelTask: PropTypes.func,
  deleteTask: PropTypes.func,
  retryTask: PropTypes.func,
  doAgainTask: PropTypes.func,
  isPortalAdmin: PropTypes.bool
}

const mapStateToProps = ({ session }) => ({
  isPortalAdmin: session.user ? UserService.isPortalAdmin(session.user) : false
})

const mapDispatchToProps = {
  retryTask,
  cancelTask,
  deleteTask,
  doAgainTask
}

export default connect(mapStateToProps, mapDispatchToProps)(JobInteractions)
