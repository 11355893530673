import React, { useEffect, useState } from 'react'
import { Button, Stack, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import { useLazyRunRecrawlingQuery, useLazyGetRecrawlingRunStatusQuery } from './recrawlingAPI'
import { STATUS } from '../../orchestratorConstants'

const API_STATUS = {
  PENDING: 'pending',
  RUNNING: 'running',
  SUCCESS: 'success'
}

const RecrawlingStep = ({ selectedDomain, active, sessionId }) => {
  const [triggerRun, resultRunStatus, lastPromiseInfo] = useLazyRunRecrawlingQuery()
  const [triggerStatus, resultStatus] = useLazyGetRecrawlingRunStatusQuery()

  const [status, setStatus] = useState(STATUS.NONE)

  useEffect(() => {
    setStatus(STATUS.NONE)
  }, [selectedDomain])

  useEffect(() => {
    const statusMap = {
      [API_STATUS.PENDING]: STATUS.PENDING,
      [API_STATUS.RUNNING]: STATUS.RUNNING,
      [API_STATUS.SUCCESS]: STATUS.DONE
    }
    const apiState = resultStatus?.data?.state
    setStatus(statusMap[apiState] || STATUS.NONE)
  }, [resultStatus])

  const onRunRecrawling = () => {
    triggerRun({ domain: selectedDomain?.domain, sessionId })
    setStatus(STATUS.PENDING)
  }

  const onGetStatus = () => {
    triggerStatus({ dagRunId: resultRunStatus?.data?.dag_run_id, sessionId })
  }

  const handleSkipClick = () => {}

  return (
    <Stack
      direction="row"
      sx={{
        alignItems: 'flex-end',
        my: 1
      }}
    >
      <Typography sx={{ width: 400 }} variant="h5">
        3. Recrawling
      </Typography>
      <Button
        sx={{ ml: 2 }}
        variant="outlined"
        onClick={onRunRecrawling}
        disabled={!active || lastPromiseInfo?.lastArg?.domain === selectedDomain?.domain}
      >
        Run
      </Button>
      <Button sx={{ ml: 2 }} variant="outlined" onClick={onGetStatus} disabled={!active}>
        Status
      </Button>
      <Button sx={{ ml: 2 }} variant="outlined" onClick={handleSkipClick} disabled>
        Skip
      </Button>
      <Typography sx={{ ml: 4 }} variant="h5">
        {status}
      </Typography>
    </Stack>
  )
}

RecrawlingStep.propTypes = {
  selectedDomain: PropTypes.any,
  active: PropTypes.bool,
  sessionId: PropTypes.string
}

export default RecrawlingStep
