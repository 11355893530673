import { useEffect, useState } from 'react'
import { DEBOUNCE_ONCHANGE_UI_ACTION_TIMEOUT } from 'constants/constants'

/**
 * Debounce hook which guarantees that some value changes only after the specified delay
 */
const useDebounce = (value, delay = DEBOUNCE_ONCHANGE_UI_ACTION_TIMEOUT) => {
  const [debouncedValue, setDebouncedValue] = useState(value)

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value)
    }, delay)

    return () => {
      clearTimeout(handler)
    }
  }, [value, delay])

  return debouncedValue
}

export default useDebounce
