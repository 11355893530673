import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button, Glyphicon } from 'react-bootstrap'

class ZoomResetButton extends Component {
  static propTypes = {
    onClick: PropTypes.func
  }

  render() {
    return (
      <div className="pull-left reset-zoom-button">
        <Button onClick={this.props.onClick}>
          <Glyphicon glyph="resize-horizontal" />
        </Button>
      </div>
    )
  }
}

export default ZoomResetButton
