import React, { useState } from 'react'
import { LinearProgress } from '@mui/material'
import { DataGridPremium, GridActionsCellItem } from '@mui/x-data-grid-premium'
import { GridToolbar } from '@mui/x-data-grid-pro'
import EditIcon from '@mui/icons-material/Edit'

import { useGetSupraTaxonomyRowsQuery } from './supraTaxonomyAPI'
import supraTaxonomyTableModel from './supraTaxonomyTableModel'
import { TABLE_MUI_ROWS_OPTION, sortModes } from '../../../constants/constants'
import WrapperWithLoader from 'mui/components/WrapperWithLoader/WrapperWithLoader'
import useMUITableState from 'mui/hooks/useMUITableState'
import SupraTaxonomyEditEntryModal from './SupraTaxonomyEditEntryModal'
import WithErrorBoundaryWrapper from 'components/WithErrorBoundaryWrapper/WithErrorBoundaryWrapper'

const SupraTaxonomyPage = () => {
  const initialSortModel = [{ field: 'domain_id', sort: sortModes.DESCENDING }]

  const {
    paginationModel,
    setPaginationModel,
    sortModel,
    setSortModel,
    filterModel,
    setFilterModel
  } = useMUITableState(initialSortModel)

  const [isWndShow, setWndShow] = useState(false)
  const [selectedRow, setSelectedRow] = useState({})

  const { isLoading, isFetching, data: supraTaxonomy } = useGetSupraTaxonomyRowsQuery({
    paginationModel,
    sortModel,
    filterModel
  })

  const rows = supraTaxonomy?.rows || []
  const initialState = {
    pagination: { paginationModel },
    sorting: { sortModel }
  }

  const handleEditClick = id => () => {
    const selectedRow = rows.find(row => row.domain_id === id)
    setSelectedRow(selectedRow)
    setWndShow(true)
  }

  const getActions = ({ id, row }) => {
    return [
      <GridActionsCellItem
        key="edit-icon"
        icon={<EditIcon />}
        label="Edit"
        className="textPrimary"
        onClick={handleEditClick(id)}
        color="inherit"
      />
    ]
  }

  return (
    <div className="m-2">
      {isWndShow && <SupraTaxonomyEditEntryModal selectedRow={selectedRow} setWndShow={setWndShow} />}
      <WrapperWithLoader isLoading={isLoading}>
        <DataGridPremium
          loading={isLoading || isFetching}
          getRowId={row => row.domain_id}
          paginationMode="server"
          sortingMode="server"
          filterMode="server"
          initialState={initialState}
          rows={rows}
          rowCount={supraTaxonomy?.total || 0}
          columns={supraTaxonomyTableModel({ getActions })}
          slots={{
            toolbar: GridToolbar,
            loadingOverlay: LinearProgress
          }}
          disableRowSelectionOnClick
          pageSizeOptions={TABLE_MUI_ROWS_OPTION}
          onPaginationModelChange={setPaginationModel}
          pagination
          onFilterModelChange={setFilterModel}
          onSortModelChange={setSortModel}
        />
      </WrapperWithLoader>
    </div>
  )
}

export default WithErrorBoundaryWrapper(SupraTaxonomyPage, '"Supra Taxonomy" page')
