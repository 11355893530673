import React from 'react'
import PropTypes from 'prop-types'
import { Glyphicon } from 'react-bootstrap'

import convertToNumberIfPossible from './utils'
import JsonEditField from './JsonEditField'

import './jsonEditField.scss'

const Property = ({ key, value, level, maxLevel, requiredKeys = [], onValueChange, onRemoveHandler }) => {
  const onValueChangeHandlert = e => {
    let value = e.target.value

    value = convertToNumberIfPossible(value)

    onValueChange({ [key]: value })
  }

  const onValueObjChangeHandler = newValue => {
    onValueChange({ [key]: newValue })
  }

  const onPropertyRemoveHandler = () => {
    onRemoveHandler(key)
  }

  const onAddSubPropertyHandler = newValue => {
    onValueChange({ [key]: {} })
  }

  const getValueElement = (key, value) => {
    if (typeof value === 'object') {
      return <JsonEditField level={level + 1} name={key} value={value} onChange={onValueObjChangeHandler} />
    }
    return (
      <>
        <input value={value} onChange={onValueChangeHandlert} />
        {level < maxLevel && (
          <Glyphicon
            className="cursor-pointer"
            title="Add sub properties"
            onClick={onAddSubPropertyHandler}
            glyph={'plus-sign'}
          />
        )}
      </>
    )
  }

  return (
    <div className="json-edit-field-property" key={key}>
      <span className="json-edit-field-property-key">
        {key}
        {': '}
      </span>
      {getValueElement(key, value)}
      {requiredKeys.includes(key) ? (
        <Glyphicon className="cursor-not-allowed disabled" title="You can't remove required property" glyph={'minus'} />
      ) : (
        <Glyphicon
          className="cursor-pointer"
          title="Remove property"
          onClick={onPropertyRemoveHandler}
          glyph={'minus'}
        />
      )}
    </div>
  )
}

Property.defaultProps = {
  level: 0
}

Property.propTypes = {
  key: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  onValueChange: PropTypes.func.isRequired,
  onRemoveHandler: PropTypes.func.isRequired,
  level: PropTypes.number,
  maxLevel: PropTypes.number,
  requiredKeys: PropTypes.arrayOf(PropTypes.string)
}

export default Property
