import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Tooltip, OverlayTrigger } from 'react-bootstrap'
import { numberFormatter, simpleNumberFormatter } from '../../../../utils/formatters'

class LargeNumValue extends Component {
  static propTypes = {
    value: PropTypes.number,
    prefix: PropTypes.string,
    hideTooltip: PropTypes.bool
  }

  render() {
    const prefix = this.props.prefix || ''
    const displayValue = <span>{`${prefix}${numberFormatter(this.props.value)}`}</span>
    if (this.props.hideTooltip) {
      return displayValue
    }
    const tooltip = value => <Tooltip id="large-num-value">{`${prefix}${simpleNumberFormatter(value)}`}</Tooltip>
    return (
      <OverlayTrigger placement="top" overlay={tooltip(this.props.value)}>
        {displayValue}
      </OverlayTrigger>
    )
  }
}

export default LargeNumValue
