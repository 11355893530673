import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import DateRangeFilter from './../content/DateRangeFilter'
import './date-range-table-filter.scss'

import { DATE_ONLY_API_FORMAT } from './constants'

export default class DateRangeTableFilter extends Component {
  state = {
    startDate: this.props.startDate,
    endDate: this.props.endDate
  }

  convertDateToDateOnly(date, defaultValue) {
    return date ? moment(date).format(DATE_ONLY_API_FORMAT) : defaultValue
  }

  applyFilter = filterObj => {
    this.setState({ startDate: moment(filterObj.from).toDate(), endDate: moment(filterObj.to).toDate() })
    this.props.filterHandler({
      from: filterObj.from,
      to: filterObj.to
    })
  }

  filter = range => {
    const [from, to] = range
    this.setState({ startDate: from, endDate: to })
    this.props.filterHandler({
      from: this.convertDateToDateOnly(from, null),
      to: this.convertDateToDateOnly(to, null)
    })
  }

  cleanFiltered = arg => {
    this.setState({ startDate: null, endDate: null })
    this.props.filterHandler()
  }

  render() {
    const { rangeStart, rangeEnd, label, availableRangeInfo } = this.props
    const { startDate, endDate } = this.state
    return (
      <div>
        <DateRangeFilter
          label={label}
          rangeStart={rangeStart}
          rangeEnd={rangeEnd}
          startDate={startDate}
          endDate={endDate}
          handleDateSelection={this.filter}
          className="date-range-table-filter"
          availableRangeInfo={availableRangeInfo}
        />
      </div>
    )
  }
}

DateRangeTableFilter.propTypes = {
  rangeStart: PropTypes.instanceOf(Date),
  rangeEnd: PropTypes.instanceOf(Date),
  startDate: PropTypes.instanceOf(Date),
  endDate: PropTypes.instanceOf(Date),
  filterHandler: PropTypes.func.isRequired,
  label: PropTypes.string,
  availableRangeInfo: PropTypes.string
}

DateRangeTableFilter.defaultProps = {
  label: 'Date Selection'
}
