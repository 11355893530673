import React from 'react'

import Metric, { useMetric } from './Metric/Metric'
import AreaChart from './Charts/AreaChart'

import { METRIC_GRAPTH } from './constants'

const ExcludeWidget = () => {
  const { data, loading, subTitle, queryType, queryId, warningMessage, refreshGraph } = useMetric(METRIC_GRAPTH.EXCLUDE)

  return (
    <Metric loading={loading} warningMessage={warningMessage}>
      <Metric.Header title="Revenue To Be Excluded" disabled={!queryId} refreshGraph={refreshGraph} />
      <Metric.Query key={queryId} queryType={queryType} />
      <AreaChart
        data={data}
        subTitle={subTitle}
        yAxisTitle="Revenue Within Category (%)"
        threshold={5}
        isPercentBetweenSeries
      />
    </Metric>
  )
}

export default React.memo(ExcludeWidget)
