export const fields = [
  { name: 'description', label: 'Description' },
  { name: 'category', label: 'Category' },
  { name: 'brand', label: 'Brand' },
  { name: 'merchant', label: 'Merchant' },
  { name: 'quantity', label: 'Quantity' },
  { name: 'price', label: 'Price' }
]

const descriptionOperators = [
  { name: 'exact', label: 'is' },
  { name: 'notMatches', label: 'is not' },
  { name: 'startsWith', label: 'starts with' },
  { name: 'notStartsWith', label: 'not starts with' },
  { name: 'endsWith', label: 'ends with' },
  { name: 'notEndsWith', label: 'not ends with' },
  { name: 'wildcard', label: 'wildcard' },
  { name: 'notWildcard', label: 'not wildcard' }
]

const bcmOperators = [
  { name: 'matches', label: 'is' },
  { name: 'notMatches', label: 'is not' }
]

const numericOperators = [
  { name: 'lt', label: '<' },
  { name: 'gt', label: '>' },
  { name: 'lte', label: '<=' },
  { name: 'gte', label: '>=' },
  { name: 'eq', label: '=' },
  { name: 'neq', label: '!=' }
]

export const combinators = [
  { name: 'and', label: 'ALL OF ' },
  { name: 'or', label: 'ANY OF ' }
]
const fieldOperators = {
  description: descriptionOperators,
  category: bcmOperators,
  merchant: bcmOperators,
  brand: bcmOperators,
  quantity: numericOperators,
  price: numericOperators
}

export const controlClassnames = {
  queryBuilder: 'delivery-query-builder portal-query-builder', // Root <div> element
  combinators: 'qb-combinators btn-md', // <select> control for combinators
  removeGroup: 'ab-remove-group btn-link btn-xs', // <button> to remove a RuleGroup
  fields: 'qb-fields btn-xs', // <select> control for fields
  operators: 'qb-operators btn-xs', // <select> control for operators
  value: 'qb-value btn-xs', // <input> for the field value
  removeRule: 'qb-remove-rule btn-link btn-xs', // <button> to remove a Rule
  disableToggle: 'qb-disable-toggle btn-link btn-xs',
  emptyQueryTree: 'qb-empty-query-tree btn-link btn-xs', // <button> to clear query tree
  repeatRule: 'qb-repeat-rule btn-link btn-xs'
}

export const getOperators = field => fieldOperators[field]
