import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { fetchMerchantSuggestionsAction } from './actions'
import Suggestion from './Suggestion'

class MerchantSuggestion extends Component {
  render() {
    const {
      selectedId,
      selectedLabel,
      additionalSearchParams,
      merchantSuggestions,
      fetchMerchantSuggestionsAction,
      changeCallback,
      readOnly
    } = this.props

    return (
      <Suggestion
        labelKey={'merchantName'}
        selectedId={selectedId}
        selectedLabel={selectedLabel}
        searchSuggestions={merchantSuggestions}
        fetchAction={fetchMerchantSuggestionsAction}
        changeCallback={changeCallback}
        additionalSearchParams={additionalSearchParams}
        placeholder={`Search for a Merchant`}
        readOnly={readOnly}
        isActive
      />
    )
  }
}

MerchantSuggestion.propTypes = {
  selectedId: PropTypes.number,
  selectedLabel: PropTypes.string,
  additionalSearchParams: PropTypes.object,
  changeCallback: PropTypes.func,

  merchantSuggestions: PropTypes.array,
  fetchMerchantSuggestionsAction: PropTypes.func.isRequired,

  readOnly: PropTypes.bool
}

MerchantSuggestion.defaultProps = {
  selectedId: null,
  selectedLabel: '',
  additionalSearchParams: null,
  merchantSuggestions: [],
  changeCallback: () => {},
  readOnly: false
}

const mapStateToProps = ({ suggestions }) => ({
  merchantSuggestions: suggestions.merchants
})

const mapDispatchToProps = {
  fetchMerchantSuggestionsAction
}

export default connect(mapStateToProps, mapDispatchToProps)(MerchantSuggestion)
