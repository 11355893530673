import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { NavLink } from 'react-router-dom'
import { Badge } from 'react-bootstrap'
import { simpleNumberFormatter } from '../../utils/formatters'

import { createContractsLinkUrl, createDeliverablesLinkUrl, createDeliveriesLinkUrl } from '../../utils/delivery-center'

class Tabs extends Component {
  renderTabButton(title = '', badgeCount) {
    return (
      <React.Fragment>
        {title} {badgeCount != null && <Badge>{simpleNumberFormatter(badgeCount)}</Badge>}
      </React.Fragment>
    )
  }

  renderMainPageTabs() {
    const {
      totalClientCount,
      totalContractCount,
      totalDeliverableCount,
      selectedPage = '',
      selectedClient,
      selectedContract
    } = this.props
    const { id: clientId } = selectedClient
    const { id: contractId } = selectedContract
    return (
      <React.Fragment>
        {!clientId && (
          <li role="presentation">
            <NavLink
              className="tab"
              to="/admin/dm/clients"
              activeclassname="active"
              isActive={() => selectedPage === 'clients'}
            >
              {this.renderTabButton('Clients', totalClientCount)}
            </NavLink>
          </li>
        )}
        {!contractId && (
          <li role="presentation">
            <NavLink
              className="tab"
              to={createContractsLinkUrl(selectedClient)}
              activeclassname="active"
              isActive={() => selectedPage === 'contracts'}
            >
              {this.renderTabButton('Contracts', totalContractCount)}
            </NavLink>
          </li>
        )}
        <li role="presentation">
          <NavLink
            className="tab"
            to={createDeliverablesLinkUrl(selectedClient, selectedContract)}
            activeclassname="active"
            isActive={() => selectedPage === 'deliverables'}
          >
            {this.renderTabButton('Deliverables', totalDeliverableCount)}
          </NavLink>
        </li>
      </React.Fragment>
    )
  }

  renderDeliveryPageTabs() {
    const { selectedPage = '', selectedDeliverable, pastDeliveryCount = 0, deliverableConfigured } = this.props
    const { id: deliverableId } = selectedDeliverable
    return (
      <React.Fragment>
        <li role="presentation">
          <NavLink
            className="tab"
            to={createDeliveriesLinkUrl(selectedDeliverable, 'config')}
            activeclassname="active"
            isActive={() => selectedPage === 'delivery-config'}
          >
            {this.renderTabButton('Configuration')}
          </NavLink>
        </li>
        {deliverableId && (selectedPage === 'delivery-current' || (deliverableId !== 'new' && deliverableConfigured)) && (
          <li role="presentation">
            <NavLink
              className="tab"
              to={createDeliveriesLinkUrl(selectedDeliverable, 'current')}
              activeclassname="active"
              isActive={() => selectedPage === 'delivery-current'}
            >
              {this.renderTabButton('Current Delivery')}
            </NavLink>
          </li>
        )}
        {deliverableId && (selectedPage === 'delivery-past' || (deliverableId !== 'new' && deliverableConfigured)) && (
          <li role="presentation">
            <NavLink
              className="tab"
              to={createDeliveriesLinkUrl(selectedDeliverable, 'past')}
              activeclassname="active"
              isActive={() => selectedPage === 'delivery-past'}
            >
              {this.renderTabButton('Past Deliveries', pastDeliveryCount)}
            </NavLink>
          </li>
        )}
      </React.Fragment>
    )
  }

  render() {
    return this.props.isDeliveryPage ? this.renderDeliveryPageTabs() : this.renderMainPageTabs()
  }
}

Tabs.propTypes = {
  totalClientCount: PropTypes.number,
  totalContractCount: PropTypes.number,
  totalDeliverableCount: PropTypes.number,
  pastDeliveryCount: PropTypes.number,
  selectedPage: PropTypes.string,
  isDeliveryPage: PropTypes.bool,
  deliverableConfigured: PropTypes.bool,
  selectedClient: PropTypes.object,
  selectedContract: PropTypes.object,
  selectedDeliverable: PropTypes.object
}

export default Tabs
