import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Tooltip, OverlayTrigger, Glyphicon, Button } from 'react-bootstrap'
import SearchTypeahead from '../../../SearchTypeahead'
import { stageDictEdit, onBrandSuggest, onCategorySuggest } from '../../../../actions/widget-actions'

class InEditValue extends Component {
  static propTypes = {
    onUpdate: PropTypes.func,
    attribute: PropTypes.string,
    row: PropTypes.object,
    suggestions: PropTypes.arrayOf(PropTypes.object),
    onCategorySuggest: PropTypes.func,
    onBrandSuggest: PropTypes.func,
    stageDictEdit: PropTypes.func,
    defaultValue: PropTypes.string
  }

  onChange = newValues => {
    if (newValues && newValues.length) {
      const newValue = newValues[0]
      this.props.stageDictEdit(this.props.row.dictionary_key, this.props.attribute, newValue.id, newValue.value)
      this.props.onUpdate(this.props.defaultValue)
    }
  }

  onInputChange = val => {
    if (this.props.attribute === 'brand') {
      this.props.onBrandSuggest(val)
    } else if (this.props.attribute === 'category') {
      this.props.onCategorySuggest(val)
    }
  }

  onCancel = event => {
    event.preventDefault()
    this.props.onUpdate(null)
  }

  render() {
    const tooltip = name => <Tooltip id="in-edit-value-clear">{name}</Tooltip>
    return (
      <div>
        <SearchTypeahead
          selectedValues={[{ value: this.props.defaultValue }]}
          align="right"
          placeholder={`Enter ${this.props.attribute}`}
          suggestions={this.props.suggestions}
          onInputChange={this.onInputChange}
          onChange={this.onChange}
          clearOnSelect={false}
          disabled={false}
        />
        <OverlayTrigger delayShow={1000} placement="top" overlay={tooltip('Cancel')}>
          <Button className="cell-clear-button" bsStyle="link" onClick={this.onCancel.bind(this)}>
            <Glyphicon glyph="remove" />
          </Button>
        </OverlayTrigger>
      </div>
    )
  }
}

function mapStateToProps(state, ownProps) {
  return {
    suggestions: state.searchEdit.description.edits[`${ownProps.attribute}Suggestions`]
  }
}

export default connect(mapStateToProps, {
  stageDictEdit,
  onBrandSuggest,
  onCategorySuggest
})(InEditValue)
