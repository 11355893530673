import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import constants from '../../constants/niq-constants'
import 'react-bootstrap-table/dist/react-bootstrap-table.min.css'
import '../../../../styles/descriptionTable.scss'
import LargeNumValue from './DescriptionTable/descriptionTableCells/LargeNumValue'
import Loader from 'components/Loader'

class SearchTable extends Component {
  static propTypes = {
    aggType: PropTypes.string,
    nameField: PropTypes.string,
    onTableSort: PropTypes.func,
    filter: PropTypes.string,
    onApplyTempFilter: PropTypes.func,
    onRemoveTempFilter: PropTypes.func,
    data: PropTypes.array,
    loading: PropTypes.bool,
    sortOrder: PropTypes.string,
    sortBy: PropTypes.string,
    type: PropTypes.string,
    height: PropTypes.number,
    contextStats: PropTypes.object,
    csvFileName: PropTypes.string
  }

  constructor(props) {
    super(props)
    this.state = {
      filterable: false
    }
  }

  toggleFilter(e, row) {
    e.preventDefault()
    const value = row[this.props.nameField]
    if (this.props.filter === value) {
      this.props.onRemoveTempFilter(this.props.aggType, value)
    } else {
      this.props.onApplyTempFilter(this.props.aggType, value)
    }
  }

  getPercentage = (currentValue, totalValue) => parseFloat((currentValue / totalValue) * 100).toFixed(2)

  buildColumns() {
    const getColumn = column => {
      const { contextStats } = this.props
      switch (column) {
        case 'sequence':
          return {
            label: '#',
            field: column,
            filter: {},
            width: '18',
            dataAlign: 'center',
            sortFunc: false
          }
        case 'count':
          return {
            label: 'Count',
            field: column,
            filter: {
              type: 'NumberFilter'
            },
            dataAlign: 'left',
            dataFormat: (cell, row) => {
              return <LargeNumValue value={parseFloat(cell)} />
            },
            width: '50',
            sortFunc: true
          }
        case 'revenue':
          return {
            label: 'Rev.',
            field: column,
            filter: {
              type: 'NumberFilter'
            },
            dataAlign: 'left',
            width: '50',
            dataFormat: (cell, row) => {
              return contextStats && contextStats.revenue ? (
                `${this.getPercentage(cell, contextStats.revenue)} %`
              ) : (
                <LargeNumValue value={parseFloat(cell)} prefix="$" />
              )
            },
            sortFunc: true
          }
        case 'sales':
          return {
            label: 'Sales.',
            field: column,
            filter: {
              type: 'NumberFilter'
            },
            dataAlign: 'left',
            width: '50',
            dataFormat: (cell, row) => {
              return <LargeNumValue value={parseFloat(cell)} prefix="$" />
            },
            sortFunc: true
          }
        default:
          return {
            label: constants.fields[column].label,
            field: column,
            filter: {
              type: 'TextFilter'
            },
            width: '60',
            sortFunc: true,
            dataFormat: (cell, row) => {
              return (
                <span className="unselectable temp-filter-select" onDoubleClick={e => this.toggleFilter(e, row)}>
                  {cell || <div>&nbsp;</div>}
                </span>
              )
            }
          }
      }
    }

    const fields = ['sequence', this.props.nameField, 'count', 'sales']
    const columns = fields.map(field => {
      return getColumn(field)
    })

    const headerColumns = columns.map((col, index) => {
      return (
        <TableHeaderColumn
          dataField={col.field}
          isKey={col.field === 'sequence'}
          key={`col-${index}`}
          dataSort={col.sortFunc}
          dataAlign={col.dataAlign}
          dataFormat={col.dataFormat}
          ref={col.field}
          width={col.width}
        >
          {col.label}
        </TableHeaderColumn>
      )
    })
    return headerColumns
  }

  createField(fieldName, sortType, sortOrder, size, siblingAggs) {
    const field = {}
    field.fieldName = fieldName
    field.orderType = sortType
    field.sort = sortOrder
    field.size = size || 10
    field.siblingAggs = siblingAggs
    return field
  }

  // Sorts data if this.props.data contains at least 1 row
  onSortChange = (sortName, sortOrder) => {
    /* @todo TODO: Remove below line and If there is no data,
      global sortable flag should be set thus disabling sort on columns
    */
    if (!this.props.data.length || this.props.data.length === 0) {
      return
    }
    let sortType = '_term'
    switch (sortName) {
      case 'count': {
        sortType = '_count'
        break
      }
      case 'sales': {
        sortType = 'sales'
        break
      }
      case 'revenue': {
        sortType = 'revenue'
        break
      }
      default: {
        break
      }
    }
    this.props.onTableSort(this.props.aggType, sortType, sortOrder)
  }

  trClassFormat = (rowData, rIndex) => {
    return this.props.filter
      ? rowData[this.props.nameField] === this.props.filter
        ? 'selected-filter'
        : 'unselected-filter'
      : ''
  }

  getSortBy = () => {
    let sortType = ''
    const sortBy = this.props.sortBy
    switch (sortBy) {
      case '_count': {
        sortType = 'count'
        break
      }
      case 'sales': {
        sortType = 'sales'
        break
      }
      case '_term': {
        sortType = this.props.type
        break
      }
      case 'revenue': {
        sortType = 'revenue'
        break
      }
      default: {
        sortType = 'count'
        break
      }
    }
    return sortType
  }

  render() {
    const columns = this.buildColumns()
    const options = {
      onSortChange: this.onSortChange,
      defaultSortName: this.getSortBy(), // default sort column name
      defaultSortOrder: this.props.sortOrder || 'desc', // default sort order
      noDataText: <div className="no-data-msg">{this.props.loading ? <Loader /> : 'No results'}</div>
    }
    let sequence = 1
    return (
      <div>
        {this.props.loading ? (
          <Loader />
        ) : (
          <div className={`widget-table-container`}>
            <BootstrapTable
              ref={el => {
                this.table = el
              }}
              data={this.props.data.map(r => ({ ...r, sequence: sequence++ }))}
              height={this.props.height}
              trClassName={this.trClassFormat}
              remote
              options={options}
              striped
              hover
              bordered
              condensed
              responsive
              csvFileName={this.props.csvFileName}
            >
              {columns}
            </BootstrapTable>
          </div>
        )}
      </div>
    )
  }
}

export default SearchTable
