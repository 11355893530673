import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import DocumentTitle from 'react-document-title'

import { Tab, Tabs } from 'react-bootstrap'

import Unauthorized from '../Unauthorized'

import UserService from '../../services/user-service'

import { appName, permissions } from '../../constants/constants'

import RulesCategoryBrand from './CategoryBrand'
import RulesMerchantCategory from './MerchantCategory'
import RulesMerchantBrand from './MerchantBrand'
import RulesMerchantCategoryRegex from './MerchantCategoryRegex'
import RulesMerchantBrandRegex from './MerchantBrandRegex'
import RulesBrandSynonym from './BrandSynonym'
import RulesCategoryBrandPath from './CategoryBrandPath'
import DeployRulesTab from './DeployRulesTab'
import HelpRulesTab from './HelpRulesTab'
import WithErrorBoundaryWrapper from 'components/WithErrorBoundaryWrapper/WithErrorBoundaryWrapper'

import './rules.scss'

const RULES = {
  MERCHANT_CATEGORY: 'Merchant Category',
  MERCHANT_CATEGORY_REGEX: 'Merchant Category Regex',
  MERCHANT_BRAND: 'Merchant Brand',
  MERCHANT_BRAND_REGEX: 'Merchant Brand Regex',
  CATEGORY_BRAND: 'Category Brand',
  CATEGORY_BRAND_PATH: 'Category Brand Path',
  BRAND_SYNONYMS: 'Brand Synonyms'
}

class RulesPage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      selectedTab: RULES.CATEGORY_BRAND
    }
  }

  handleTabSelect = tabKey => this.setState({ selectedTab: tabKey })

  render() {
    const { selectedTab } = this.state
    const { hasTaxonomyAccess, hasEditAccess } = this.props

    if (!hasTaxonomyAccess) return <Unauthorized />

    return (
      <DocumentTitle title={selectedTab ? `${appName} | ${selectedTab}` : appName}>
        <div className="rules-page">
          <Tabs id="rules-tabs" className="portal-tabs" activeKey={selectedTab} onSelect={this.handleTabSelect}>
            <Tab mountOnEnter title={RULES.CATEGORY_BRAND} eventKey={RULES.CATEGORY_BRAND}>
              <RulesCategoryBrand hasEditAccess={hasEditAccess} />
            </Tab>
            <Tab mountOnEnter title={RULES.MERCHANT_CATEGORY} eventKey={RULES.MERCHANT_CATEGORY}>
              <RulesMerchantCategory hasEditAccess={hasEditAccess} />
            </Tab>
            <Tab mountOnEnter title={RULES.MERCHANT_BRAND} eventKey={RULES.MERCHANT_BRAND}>
              <RulesMerchantBrand hasEditAccess={hasEditAccess} />
            </Tab>
            <Tab mountOnEnter title={RULES.MERCHANT_CATEGORY_REGEX} eventKey={RULES.MERCHANT_CATEGORY_REGEX}>
              <RulesMerchantCategoryRegex hasEditAccess={hasEditAccess} />
            </Tab>
            <Tab mountOnEnter title={RULES.MERCHANT_BRAND_REGEX} eventKey={RULES.MERCHANT_BRAND_REGEX}>
              <RulesMerchantBrandRegex hasEditAccess={hasEditAccess} />
            </Tab>
            <Tab mountOnEnter title={RULES.CATEGORY_BRAND_PATH} eventKey={RULES.CATEGORY_BRAND_PATH}>
              <RulesCategoryBrandPath hasEditAccess={hasEditAccess} />
            </Tab>
            <Tab mountOnEnter title={RULES.BRAND_SYNONYMS} eventKey={RULES.BRAND_SYNONYMS}>
              <RulesBrandSynonym hasEditAccess={hasEditAccess} />
            </Tab>
            <Tab mountOnEnter title="Deploy Rules" eventKey="Deploy Rules">
              <DeployRulesTab hasEditAccess={hasEditAccess} />
            </Tab>
            <Tab mountOnEnter title="Help" eventKey="Help Rules">
              <HelpRulesTab />
            </Tab>
          </Tabs>
        </div>
      </DocumentTitle>
    )
  }
}

RulesPage.propTypes = {
  hasEditAccess: PropTypes.bool.isRequired,
  hasTaxonomyAccess: PropTypes.bool.isRequired
}

const mapStateToProps = ({ session: { user } }) => ({
  hasTaxonomyAccess: user && user.permissions ? UserService.hasPermission(user, permissions.ropsTaxonomyView) : false,
  hasEditAccess: user && user.permissions ? UserService.hasPermission(user, permissions.ropsTaxonomyEdit) : false
})

export default WithErrorBoundaryWrapper(connect(mapStateToProps, null)(RulesPage), '"CME Rules" page')
