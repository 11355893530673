import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import MerchantsInput from './../Filter/merchants-input'

export default class InStore extends Component {
  static propTypes = {
    feed: PropTypes.object.isRequired
  }

  render() {
    return (
      <div>
        <Field name="merchants" component={MerchantsInput} />
      </div>
    )
  }
}
