import React from 'react'

import { Glyphicon } from 'react-bootstrap'
import moment from 'moment'
import uaParser from 'ua-parser-js'
import CopyToClipboard from 'react-copy-to-clipboard'
import { AuthlogEvents as EVENTS } from '../../constants/constants'

export function renderCreatedAt(cell, row) {
  const { rawContext: { 'xc-tzo': tzo } = {} } = row
  return (
    <div>
      <div>{moment(cell).calendar()}</div>
      <div
        className="date-time"
        title={moment(cell)
          .utcOffset(-Number(tzo))
          .format('hh:mm a, DD MMM (Z)')}
      >
        {moment(cell).format('hh:mm a, DD MMM (Z)')}
      </div>
    </div>
  )
}

export function renderUpdatedAt(cell) {
  return (
    <div>
      <div title={cell}>{moment(cell).calendar()}</div>
    </div>
  )
}

export function renderUA(cell, row) {
  const { rawContext: { 'user-agent': ua } = {} } = row
  const uaData = uaParser(ua) || {}
  const { browser = {}, os = {} } = uaData
  return (
    <CopyToClipboard text={JSON.stringify(uaData, null, 4)}>
      <div title={`Click to Copy\n\n${JSON.stringify(uaData, null, 4)}`}>
        <div>
          {browser.name} {browser.version}
        </div>
        <div>
          {os.name} {os.version}
        </div>
      </div>
    </CopyToClipboard>
  )
}

export function renderUser(cell, row) {
  const { email } = row
  let { user } = row
  user = user || { email }
  return (
    <div>
      <div className="user-name">{user.fullname || ''}</div>
      <div className="user-email">{user.email}</div>
    </div>
  )
}

export function renderEvent(cell, row) {
  const { event, description, additionalData, initiator, user } = row
  const { strategy } = additionalData || {}
  let initiatedBy = null
  if (initiator) {
    if (!user || user.id !== initiator.id) {
      initiatedBy = initiator.email
    }
  }
  return (
    <div>
      <div className="auth-event">{event}</div>
      {strategy && <div className="auth-strategy">{strategy}</div>}
      {description && <div className="auth-event-desc">{description}</div>}
      {initiatedBy && <div className="auth-event-initiator">by {initiatedBy}</div>}
    </div>
  )
}

export function renderIP(cell, row) {
  const ip = extractIp(row)
  const { ipLocationMap = {}, resolveLocations } = this.props
  const locData = ipLocationMap[ip]
  if (locData === undefined) {
    return (
      <div className="ip-details-block" title="click to get location info" onClick={() => resolveLocations([ip])}>
        <div className="ip-address">
          {ip}
          {'  '}
          <Glyphicon glyph={'map-marker'} />
        </div>
      </div>
    )
  } else {
    const {
      city,
      region_code: regionCode,
      country_code: countryCode,
      country_name: countryName,
      organisation,
      flag,
      threat = {},
      error
    } = locData || {}
    if (error) {
      return <div className="ip-error">{error}</div>
    }
    const activeThreats = Object.keys(threat).filter(tKey => threat[tKey])
    return (
      <CopyToClipboard text={JSON.stringify(locData || { ip }, null, 4)}>
        <div title={'Click to Copy'} className="ip-details-block">
          <div className="ip-address">{ip}</div>
          {organisation && <div className="ip-organisation">{organisation}</div>}
          <div>
            {city && <span className="ip-city">{city}, </span>}
            {regionCode && <span className="ip-region">{regionCode}</span>}
          </div>
          <div>
            {flag && <img className="ip-flag" src={flag} width={30} alt={countryCode} />}
            {countryName && <span className="ip-country">{countryName}</span>}
          </div>
          {activeThreats.length > 0 && <span className="ip-threat">{activeThreats.join(', ')}</span>}
        </div>
      </CopyToClipboard>
    )
  }
}

export function extractIp(row) {
  const {
    rawContext: { 'x-forwarded-for': xFwdIp },
    ip
  } = row
  return ip || xFwdIp
}

export function trClassFormat(row) {
  switch (row.event) {
    case EVENTS.LOGIN_FAILURE:
    case EVENTS.ONBOARD_TOKEN_FAILURE:
    case EVENTS.RESET_PASSWORD_TOKEN_FAILURE:
      return 'danger'
    case EVENTS.ONBOARD_TOKEN_CREATED:
    case EVENTS.ONBOARD_TOKEN_VERIFIED:
    case EVENTS.RESET_PASSWORD_TOKEN_CREATED:
      return 'warning'
    case EVENTS.LOGIN_SUCCESS:
      return 'success'
    case EVENTS.PASSWORD_CHANGED:
    default:
      return 'info'
  }
}
