import React, { useState } from 'react'
import DocumentTitle from 'react-document-title'
import { Button } from 'react-bootstrap'
import { useDispatch } from 'react-redux'

import CMEDictionary from './CMEDictionary'
import CMEDictionaryAddEntryModal from './CMEDictionaryAddEntryModal'
import CMEDictionaryUpdateMaxIdModal from './CMEDictionaryUpdateMaxIdModal'
import { openAddDictionaryEntryModal } from './slices'
import WithErrorBoundaryWrapper from 'components/WithErrorBoundaryWrapper/WithErrorBoundaryWrapper'

const isProduction = process.env.REACT_APP_DEPLOY_ENV.toLowerCase() === 'production'

const CMEDictionaryPage = () => {
  const dispatch = useDispatch()
  const openCreateModal = () => {
    dispatch(openAddDictionaryEntryModal())
  }

  const [open, setOpen] = useState(false)

  return (
    <DocumentTitle title="CME Dictionary">
      <div className="cme-dictionary-page">
        <CMEDictionaryAddEntryModal />
        {open && <CMEDictionaryUpdateMaxIdModal onClose={() => setOpen(false)} />}
        <div className="cme-dictionary-toolbar">
          <div className="cme-dictionary-toolbar-buttons">
            <Button bsStyle="info" onClick={openCreateModal}>
              Add Dictionary Entry
            </Button>

            {!isProduction && (
              <Button bsClass="ml-2 btn btn-info" onClick={() => setOpen(true)}>
                Update Latest Dictionary Sync ID
              </Button>
            )}
          </div>
        </div>

        <CMEDictionary />
      </div>
    </DocumentTitle>
  )
}

export default WithErrorBoundaryWrapper(CMEDictionaryPage, '"CME Dictionary" page')
