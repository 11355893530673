import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { default as QueryBuilderTree } from './QueryBuilderTree'
import { OverlayTrigger, Glyphicon, Tooltip } from 'react-bootstrap'
import { default as Search } from './Search'

export default class QueryBuilderWrapper extends Component {
  constructor() {
    super()
    this.state = {
      ruleGroupsCount: 1
    }
  }

  ruleGroupsCountChange = queryTree => {
    const calculateRuleDepth = root => {
      let level = 1
      let key
      for (key in root) {
        if (!root.hasOwnProperty(key)) continue
        if (typeof root[key] === 'object') {
          level = Math.max(calculateRuleDepth(root[key]) + 1, level)
        }
      }
      return level
    }

    this.setState({ ruleGroupsCount: Math.floor(calculateRuleDepth(queryTree) / 2) })
  }

  render() {
    const tooltip = name => <Tooltip id="query-builder-tooltip">{name}</Tooltip>

    return (
      <div
        style={{ '--size': `${550 + 50 * this.state.ruleGroupsCount}px` }}
        className={`query-builder-wrapper ${this.props.isTreeVisible ? 'active' : 'inactive'}`}
      >
        <div
          onClick={this.props.toggleTreeVisibility}
          className={`show-tree-btn ${this.props.isTreeVisible ? 'open' : 'closed'}`}
        >
          <OverlayTrigger
            rootClose
            placement="right"
            overlay={this.props.isTreeVisible ? tooltip('Hide Query') : tooltip('Show Query')}
          >
            <span>
              <Glyphicon glyph="triangle-left" />
              <span className="text">QUERY</span>
              <Glyphicon glyph="triangle-right" />
            </span>
          </OverlayTrigger>
        </div>

        <div className="query-builder">
          <div className="search-filter-wrapper">
            <Search />
          </div>

          <QueryBuilderTree ruleGroupsCountChange={this.ruleGroupsCountChange} />
        </div>
      </div>
    )
  }
}

QueryBuilderWrapper.propTypes = {
  isTreeVisible: PropTypes.bool,
  toggleTreeVisibility: PropTypes.func
}
