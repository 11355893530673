// @flow
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { reduxForm, Field, FormSection } from 'redux-form'
import { debounce, keyBy, get as getProperty } from 'lodash'
import cx from 'classnames'
import axios from 'axios'
import DocumentTitle from 'react-document-title'
import { Link } from 'react-router-dom'

// modules
import Toggle from '../Toggle'
import FormSelect from '../FormSelect'
import BaseTransaction from './Module/base-transaction'
import RideShare from './Module/ride-share'
import InStore from './Module/in-store'
import Hotels from './Module/hotels'
import AmazonScalingFactor from './Module/amazon-scaling-factor'
import Airline from './Module/airline-items'
import Meal from './Module/meal-items'
import VideoGame from './Module/video-game-items'
import MerchantsDate from './Module/merchants-date-template'
import ItemAttribute from './Module/item-attribute'

// components
import sendIncrementModal from './send-increment-modal'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import SearchTypeahead from '../SearchTypeahead'
import FormDatePicker from '../FormDatePicker'
import Loader from '../Loader'
import WithErrorBoundaryWrapper from 'components/WithErrorBoundaryWrapper/WithErrorBoundaryWrapper'

// actions
import { fetchGroupSuggestions, fetchGroup } from '../../actions/group-actions'
import { updateEtlCheckpoint } from '../../actions/task-actions'
import { updateFilter } from '../../actions/filter-actions'
import { createFeed, fetchFeed, resetFeed, saveFeed, createFirehoseFeed } from '../../actions/feed-actions'

// selector
import { getEligibleGroups } from '../../selectors/group-selector'
import { getFeedForEdit } from '../../selectors/feed-selector'
import { getSelectableGroups } from '../UserEditPage/basic-infos'

import validateForm from './validate-form'

// services
import { UserService } from '../../services'

import { appName } from '../../constants/constants'

import { fetchFeedModulesAction } from './../DataFeeds/FeedModules/actions'
import { getFeedModulesForFeedPage } from './../DataFeeds/FeedModules/selectors'
import LimitStagingDeliveryWarning from './LimitStagingDeliveryWarning'
import EncryptionWarning from './EncryptionWarning'

import './index.scss'
import DataFeedTabs from '../DataFeeds/DataFeedTabs'

const getIsS3Shipping = groupS3Bucket => {
  return !!groupS3Bucket
}

class FeedEditPage extends Component {
  constructor(props) {
    super(props)

    this.onChange = this.onChange.bind(this)
    this.onInputChange = this.onInputChange.bind(this)
    this.fetchGroupsDebounce = this.fetchGroupsDebounce.bind(this)

    this.state = {
      isS3Shipping: undefined
    }
  }

  componentDidMount() {
    // Cannot edit/create a feed if not a portal admin
    if (!this.props.user || !Object.keys(this.props.user).length) {
      return
    }

    if (!UserService.isPortalAdmin(this.props.user)) {
      this.props.history.replace('/')
    }

    // Only load feed if :id != new
    if (this.props.match.params.id !== 'new') {
      this.props.fetchFeed(this.props.match.params.id)
    }

    // Upload Feed Modules info if need
    if (!this.props.feedModules || this.props.feedModules.length === 0) {
      this.props.fetchFeedModules()
    }
  }

  componentWillUnmount() {
    this.props.resetFeed()
  }

  renderStatusFeed = ({ input, isActive, text, inactiveText, disabled = false }) => {
    return (
      <Toggle
        {...input}
        isActive={isActive}
        text={text}
        inactiveText={inactiveText}
        disabled={disabled}
        onChange={value => input.onChange(value)}
      />
    )
  }

  getModuleFilters(moduleFilterName, feed) {
    switch (moduleFilterName) {
      case 'BaseTransaction':
        return <BaseTransaction feed={feed} />
      case 'RideShare':
        return <RideShare feed={feed} />
      case 'AmazonScalingFactor':
        return <AmazonScalingFactor feed={feed} />
      case 'InStore':
        return <InStore feed={feed} />
      case 'Hotels':
        return <Hotels feed={feed} />
      case 'Airline':
        return <Airline feed={feed} />
      case 'Meal':
        return <Meal feed={feed} />
      case 'VideoGame':
        return <VideoGame feed={feed} />
      case 'MerchantsDate':
        return <MerchantsDate feed={feed} />
      case 'ItemAttribute':
        return <ItemAttribute feed={feed} />
      // Merchants without filters should follow
      case null:
      case undefined:
      default:
        break
    }
  }

  onInputChange = searchTerm => {
    this.fetchGroupsDebounce(searchTerm)
  }

  fetchGroupsDebounce = debounce(searchTerm => {
    this.props.fetchGroupSuggestions(searchTerm)
  }, 200)

  onChange = selectedGroup => {
    if (selectedGroup.length !== 0) {
      this.props.change('GroupId', selectedGroup[0].value)

      // save isS3Shipping for selected group
      const isS3Shipping = getIsS3Shipping(selectedGroup[0].s3Bucket)
      this.setState({ isS3Shipping })
      if (isS3Shipping) {
        // automatically enable encryption for shipping-s3
        this.props.change('useEncryption', true)
      }
    } else {
      this.props.change('GroupId', -1)
    }
  }

  render() {
    const {
      feed,
      feedModules,
      loading,
      pristine,
      submitting,
      handleSubmit,
      selectedGroup,
      newDocumentTitle,
      editDocumentTitle,
      groupSuggestions,
      initialValues: { id = -1, groupName = '', moduleId: initialModuleId },
      match: { params }
    } = this.props

    const isS3Shipping =
      this.state.isS3Shipping === undefined ? getIsS3Shipping(feed?.groupS3Bucket) : this.state.isS3Shipping

    if (loading || !feed || !feedModules.length) {
      return <Loader loading />
    }

    const isNew = params.id === 'new'
    const isContinuousDeliveryActive = feed.isActive === undefined ? true : feed.isActive

    const submitDisabled = (id && selectedGroup) === -1

    const feedModule = feedModules.find(m => m.id === feed.moduleId)
    const initialFeedModule = feedModules.find(m => m.id === initialModuleId)

    const moduleFilters = feedModule && this.getModuleFilters(feedModule.additionalUIFilter, feed)
    return (
      <>
        {DataFeedTabs(this.props)}

        <DocumentTitle
          title={
            newDocumentTitle && editDocumentTitle
              ? `${appName} | ${isNew ? newDocumentTitle : editDocumentTitle}`
              : appName
          }
        >
          <div className="container">
            <form onSubmit={handleSubmit}>
              <span className="h3">{isNew ? 'Create Feed' : 'Edit Feed'}</span>
              <div className={'rightFormButton'}>
                {!isNew && (
                  <>
                    <OverlayTrigger
                      placement="bottom"
                      overlay={
                        <Tooltip id="resend-files-tooltip">
                          Force the system to re-send the increment files to the client from a given date
                        </Tooltip>
                      }
                    >
                      <button
                        className={'btn_custom_secondary'}
                        disabled={isNew || feed.startDate === null}
                        title="This option is available once the feed has been delivered at least once."
                        onClick={event => {
                          event.preventDefault()
                          this.props.sendIncrementModal(this.props.updateEtlCheckpoint, feed)
                        }}
                      >
                        Resend Files
                      </button>
                    </OverlayTrigger>

                    <OverlayTrigger
                      placement="bottom"
                      overlay={
                        <Tooltip id="schedule-firehose-tooltip">Schedule right now the shipping of a firehose</Tooltip>
                      }
                    >
                      <button
                        className={'btn_custom_secondary'}
                        type="button"
                        title={
                          !initialFeedModule?.hasFirehoseConfig
                            ? 'This option is available once the selected module has firehose config.'
                            : ''
                        }
                        onClick={e => this.props.createFirehoseFeed(feed.id)}
                        disabled={isNew || !initialFeedModule?.hasFirehoseConfig}
                      >
                        Send Firehose
                      </button>
                    </OverlayTrigger>
                  </>
                )}

                <button
                  className={'btn_custom_secondary'}
                  type="button"
                  title="Go back to the feed list without saving"
                  onClick={() => {
                    this.props.updateFilter('', 'feeds')
                    this.props.history.goBack()
                  }}
                >
                  Cancel
                </button>

                <button className={'btn_custom'} type="submit" disabled={submitDisabled || pristine || submitting}>
                  {isNew ? 'Create' : 'Save Changes'}
                </button>
              </div>
              <div className="h4 top-spacing">BASIC INFORMATION</div>
              <div className="feed-module-input">
                <div>
                  <FormSelect
                    isRequired
                    label="Feed Module"
                    fieldName="moduleId"
                    placeholder="Select Feed Module"
                    options={feedModules.map(module => ({ value: module.id, label: module.name }))}
                  />
                </div>
                <div className="feed-module-link">
                  <Link to={`/admin/feeds/modules/${feed.moduleId}`}> Go to module </Link>
                </div>
              </div>
              {feed.moduleId && (
                <div>
                  <div>
                    <label className="group-input required">
                      <span> Group</span>
                      <SearchTypeahead
                        clearButton
                        size={'large'}
                        justify={'left'}
                        disabled={false}
                        autoFocus={false}
                        labelKey={'label'}
                        placeholder={'Select a Group'}
                        className={cx('input')}
                        onChange={this.onChange}
                        onInputChange={this.onInputChange}
                        suggestions={getSelectableGroups(groupSuggestions)}
                        selectedValues={[{ value: id, label: groupName }]}
                      />
                      <div className="group-link">
                        <Link to={`/admin/groups/${selectedGroup}`}> Go to group </Link>
                      </div>
                    </label>
                  </div>

                  <br />
                  <div>
                    <FormDatePicker
                      label="Start Date"
                      fieldName="startDate"
                      fromDate={new Date('1/1/13')}
                      tooltip={'Override the start date of the feed.'}
                    />
                  </div>

                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id="useEncryption-delivery-tooltip">Encrypt delivery result</Tooltip>}
                  >
                    <label className="FeedEditPage__toggle_label">Encryption</label>
                  </OverlayTrigger>

                  <div className="FeedEditPage__toggle">
                    <Field
                      name="useEncryption"
                      isActive={feed.useEncryption}
                      text={'On'}
                      inactiveText={'Off'}
                      component={this.renderStatusFeed}
                      disabled={isS3Shipping}
                    />
                  </div>
                  {isS3Shipping && <EncryptionWarning />}

                  <div className="h4 top-spacing">DELIVERY SCHEDULE</div>
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id="continuous-delivery-tooltip">
                        {feed.isActive
                          ? 'Delivery will start as soon as the feed is saved'
                          : 'Increments will not be sent. Firehose must be triggered manually'}
                      </Tooltip>
                    }
                  >
                    <label className="FeedEditPage__toggle_label">Continuous Delivery</label>
                  </OverlayTrigger>

                  <div className="FeedEditPage__toggle">
                    <Field
                      name="isActive"
                      isActive={feed.isActive}
                      text={'On'}
                      inactiveText={'Off'}
                      component={this.renderStatusFeed}
                    />
                  </div>
                  {isContinuousDeliveryActive && <LimitStagingDeliveryWarning />}
                  {isContinuousDeliveryActive && (
                    <div className="top-spacing-sm">
                      <FormSelect
                        isRequired
                        label="Frequency"
                        fieldName="frequency"
                        placeholder="Select Frequency"
                        options={keyBy(feedModules, 'id')[feed.moduleId].frequencies} // <- no feed modules found for amazon scaling factor component
                      />

                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip id="initial-firehose-tooltip">
                            {'If disabled, only increments will be scheduled after the creation of this feed.'}
                          </Tooltip>
                        }
                      >
                        <label className="FeedEditPage__toggle_label">Initial Firehose</label>
                      </OverlayTrigger>

                      <div className="FeedEditPage__toggle">
                        {!feed.firstDeliveryDate ? (
                          <Field
                            name="scheduleFirehose"
                            isActive={feed.scheduleFirehose}
                            component={this.renderStatusFeed}
                            text={'On'}
                            inactiveText={'Off'}
                          />
                        ) : (
                          <span>Increments delivery already started</span>
                        )}
                      </div>
                    </div>
                  )}
                  {moduleFilters ? <div className="h4 top-spacing">FILTERS</div> : <div />}
                  <FormSection name="filters">{moduleFilters}</FormSection>
                </div>
              )}
            </form>
          </div>
        </DocumentTitle>
      </>
    )
  }
}

FeedEditPage.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  eligibleGroups: PropTypes.array,
  fetchFeed: PropTypes.func.isRequired,
  fetchFeedModules: PropTypes.func.isRequired,
  createFirehoseFeed: PropTypes.func.isRequired,
  feedModules: PropTypes.array,
  frequencies: PropTypes.array,
  feed: PropTypes.object,
  loading: PropTypes.bool.isRequired,
  resetFeed: PropTypes.func.isRequired,
  updateEtlCheckpoint: PropTypes.func,
  user: PropTypes.object,
  updateFilter: PropTypes.func,
  change: PropTypes.func,
  groupSuggestions: PropTypes.array,
  fetchGroup: PropTypes.func.isRequired,
  fetchGroupSuggestions: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
  selectedGroup: PropTypes.number.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  sendIncrementModal: PropTypes.func.isRequired,
  newDocumentTitle: PropTypes.string,
  editDocumentTitle: PropTypes.string,
  deliveredTodayCount: PropTypes.number,
  location: PropTypes.object
}

FeedEditPage.defaultProps = {
  feed: null,
  feedMmodules: [],
  frequencies: [],
  selectedGroup: -1,
  autocompleteMerchants: [],
  editDocumentTitle: 'Edit Feed',
  newDocumentTitle: 'Create Feed'
}

// Export and Connect

const form = reduxForm({
  form: 'feed-form',
  validate: validateForm,
  asyncValidate: values => {
    if (!values.filters.merchants || values.filters.merchants.length === 0) {
      return new Promise(resolve => resolve())
    }
    return (
      axios
        .get(`/api/admin/idf-dimension/merchants-by-id?ids=${values.filters.merchants.join(',')}`)
        // Redux forms expect and error object in either the "then" or "catch"
        // we need to add an additional "then" to forbid axios to return the response
        // of the call to redux-form.
        .then(() => {})
        .catch(err => {
          /* eslint-disable-next-line prefer-promise-reject-errors */
          return Promise.reject({ filters: { merchants: err.response.data } })
        })
    )
  },
  asyncBlurFields: ['filters', 'filters.l1Categories', 'filters.merchants'],
  enableReinitialize: true,
  onSubmit: (values, dispatch, props) => {
    // Do not dispatch if no group selected
    if (!values.GroupId || values.GroupId === -1) return

    const valuesToSave = {}
    valuesToSave.id = values.id
    valuesToSave.isActive = values.isActive
    valuesToSave.moduleId = values.moduleId
    valuesToSave.useEncryption = values.useEncryption ?? true
    valuesToSave.frequency = values.frequency
    valuesToSave.firstDeliveryDate = values.firstDeliveryDate || null
    valuesToSave.lastDeliveryDate = values.lastDeliveryDate || null
    valuesToSave.GroupId = values.GroupId
    valuesToSave.editorId = values.editorId
    if (valuesToSave.isActive) {
      if (valuesToSave.endDate !== null || valuesToSave.endDate !== undefined) {
        valuesToSave.endDate = null
      }
    } else {
      if (!valuesToSave.endDate) {
        valuesToSave.endDate = new Date()
      }
    }

    // Start date is overridable
    if (values.startDate) {
      valuesToSave.startDate = values.startDate
    } else {
      // If we don't want the firehoses to be sent at first, we can just
      // initialize the start date of the feed to the current day
      if (values.scheduleFirehose === false) {
        valuesToSave.startDate = new Date()
      } else if (valuesToSave.firstDeliveryDate === null) {
        // You can only reset the start date if no delivery has been done
        valuesToSave.startDate = null
      }
    }

    valuesToSave.metadata = JSON.stringify({
      filters: values.filters
    })

    if (valuesToSave.id) {
      dispatch(saveFeed(valuesToSave))
    } else {
      dispatch(createFeed(valuesToSave))
    }
  }
})(FeedEditPage)

const mapStateToProps = state => ({
  user: state.session.user,
  loading: state.session.loading,
  initialValues: getFeedForEdit(state),
  feedModules: getFeedModulesForFeedPage(state),
  groupSuggestions: getEligibleGroups(state),
  frequencies: state.frequencies && state.frequencies.data,
  autocompleteMerchants: state.session.autocompleteMerchants,
  feed: state.form['feed-form'] && state.form['feed-form'].values,
  deliveredTodayCount: state.feeds.feedDeliveries.deliveredTodayCount,
  selectedGroup: getProperty(state.form['feed-form'], 'values.GroupId', -1)
})

const mapDispatchToProps = dispatch => ({
  saveFeed: feed => saveFeed(feed),
  resetFeed: () => dispatch(resetFeed()),
  fetchFeed: id => dispatch(fetchFeed(id)),
  fetchGroup: id => dispatch(fetchGroup(id)),
  createFirehoseFeed: id => dispatch(createFirehoseFeed(id)),
  fetchGroupSuggestions: searchTerm => dispatch(fetchGroupSuggestions(searchTerm)),
  updateFilter: (searchTerm, filterId) => dispatch(updateFilter(searchTerm, filterId)),
  updateEtlCheckpoint: (taskType, param, value) => dispatch(updateEtlCheckpoint({ taskType, param, value })),
  sendIncrementModal: (updateEtlCheckpoint, feed) => dispatch(sendIncrementModal(updateEtlCheckpoint, feed)),
  fetchFeedModules: () => dispatch(fetchFeedModulesAction())
})

export default WithErrorBoundaryWrapper(
  connect(mapStateToProps, mapDispatchToProps)(form),
  '"Admin Data Feeds edit" page'
)
