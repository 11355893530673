import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import moment from 'moment'

import Loader from '../Loader'
import DocumentTitle from 'react-document-title'
import { BootstrapTable } from 'react-bootstrap-table'
import { Alert, Popover, OverlayTrigger, Glyphicon, Label } from 'react-bootstrap'

import DateRangeFilter from '../content/DateRangeFilter'
import { getDateRanges, createStaticRanges } from '../content/DateRangeFilter/ranges'

import SearchTypeahead from '../SearchTypeahead'

import buildColumns from './feedDeliveryColumns'

import { appName } from '../../constants/constants'

import { fetchFeedDeliveriesSummary } from '../../actions/feed-actions'

import { fetchGroups } from '../../actions/group-actions'

const DATE_FORMAT = 'YYYY-MM-DD'

class FeedDeliveriesSummary extends Component {
  constructor(props) {
    super(props)
    this.selectedGroups = []
    this.dateRange = {}
  }

  componentDidMount() {
    this.props.fetchFeedDeliveriesSummary({})
    // workaround for uploading all groups
    const offset = 0
    const count = 2_000 // expect that we don't have more active groups than this value
    this.props.fetchGroups(offset, count)
  }

  helpContainer = (
    <Popover id="feed-deliveries-summary-page-help">
      <p>This page contains summary statistics of feed deliveries for a given date for selected (or all) groups</p>
    </Popover>
  )

  onDateRangeChange = value => {
    const startDate = moment(value[0]).format(DATE_FORMAT)
    const endDate = moment(value[1]).format(DATE_FORMAT)
    this.dateRange.startDate = startDate
    this.dateRange.endDate = endDate
    this.props.fetchFeedDeliveriesSummary({
      startDate,
      endDate,
      groups: this.selectedGroups.map(g => g.value)
    })
  }

  fetchFeedDeliveriesSummary = () => {
    this.props.fetchFeedDeliveriesSummary({})
  }

  getTableOptions = () => {
    return {
      sizePerPage: 20,
      hideSizePerPage: true
    }
  }

  onChange = selectedGroups => {
    this.selectedGroups = [...selectedGroups]
    this.props.fetchFeedDeliveriesSummary({
      ...this.dateRange,
      groups: selectedGroups.map(g => g.value)
    })
  }

  get filteredRows() {
    const unfilteredRows = this.props.dateLevelSummary
    if (this.selectedGroups.length) {
      // return only those rows which have one of the selected groups in them
      return unfilteredRows.filter(row => {
        const hasOneOfSelectedGroups = row.groups.some(group => this.selectedGroups.find(g => g === group.groupId))
        return hasOneOfSelectedGroups
      })
    }
    return unfilteredRows
  }

  get groupsFormatted() {
    return this.props.groups.map(g => ({ value: g.id, label: g.name }))
  }

  render() {
    const { error, loading, dateLevelSummary, startDate, endDate } = this.props
    const rangeStart = moment().add(-6, 'months')
    const rangeEnd = moment()
    const ranges = getDateRanges(rangeStart, rangeEnd)
    const customDateRanges = createStaticRanges([ranges.last7d, ranges.last1m, ranges.last3m, ranges.last6m])

    return (
      <>
        <DocumentTitle title={this.props.documentTitle ? `${appName} | ${this.props.documentTitle}` : appName}>
          <div className="feed-deliveries-summary-page">
            <div className="feed-deliveries-summary-toolbar">
              <div className="feed-deliveries-summary__group-search-input">
                <Label className="feed-deliveries-summary__group-search-input__label"> Groups: </Label>
                <SearchTypeahead
                  className="feed-deliveries-summary__group-search-input__input-box"
                  label="Groups:"
                  multiple
                  justify={'left'}
                  disabled={false}
                  autoFocus={false}
                  labelKey={'label'}
                  placeholder={'Search Groups..'}
                  onChange={this.onChange}
                  onInputChange={() => {}}
                  suggestions={this.groupsFormatted}
                  selectedValues={this.selectedGroups}
                />
              </div>
              <div>
                <DateRangeFilter
                  handleDateSelection={this.onDateRangeChange}
                  className="date-range-filter"
                  rangeStart={rangeStart.format(DATE_FORMAT)}
                  rangeEnd={moment().format(DATE_FORMAT)}
                  customDateRanges={customDateRanges}
                  startDate={startDate}
                  endDate={endDate}
                />
                <OverlayTrigger placement="bottom" overlay={this.helpContainer}>
                  <Glyphicon glyph="info-sign" className="feed-deliveries-summary-page__help-icon" />
                </OverlayTrigger>
              </div>
            </div>

            {loading && <Loader loading overlap />}
            <div className="row feed-deliveries-summary___table-and-filters">
              <BootstrapTable
                hover
                striped
                bordered
                condensed
                responsive
                pagination
                data={dateLevelSummary}
                className="date-summary-table"
                ref={el => {
                  this.table = el
                }}
                options={this.getTableOptions()}
                fetchInfo={{ dataTotalSize: dateLevelSummary.length }}
              >
                {buildColumns('dateSummary')}
              </BootstrapTable>
            </div>

            {error && <Alert bsStyle="danger">{error}</Alert>}
          </div>
        </DocumentTitle>
      </>
    )
  }
}

FeedDeliveriesSummary.defaultProps = {
  documentTitle: 'Feed Deliveries Summary'
}

FeedDeliveriesSummary.propTypes = {
  error: PropTypes.string,
  loading: PropTypes.bool,
  documentTitle: PropTypes.string,
  fetchFeedDeliveriesSummary: PropTypes.func,
  fetchGroups: PropTypes.func,
  feedDeliveriesSummary: PropTypes.arrayOf(PropTypes.object),
  dateLevelSummary: PropTypes.arrayOf(PropTypes.object),
  groups: PropTypes.arrayOf(PropTypes.object),
  startDate: PropTypes.string,
  endDate: PropTypes.string
}

const mapStateToProps = ({ feeds: { feedDeliveriesSummary }, groups: { data } }) => ({
  error: feedDeliveriesSummary.error,
  startDate: feedDeliveriesSummary.startDate,
  endDate: feedDeliveriesSummary.endDate,
  loading: feedDeliveriesSummary.loading,
  dateLevelSummary: feedDeliveriesSummary.dateLevelSummary,
  groups: data
})

export default connect(mapStateToProps, { fetchFeedDeliveriesSummary, fetchGroups })(FeedDeliveriesSummary)
