import React, { Component } from 'react'
import PropTypes from 'prop-types'
import TableToolbar from './table-toolbar'
import TableChartToolbar from './table-chart-toolbar'
import SearchTable from './search-table'
import TableChart from './table-chart'
import { connect } from 'react-redux'
import Loader from 'components/Loader'
import WidgetTableOptions from './WidgetTableOptions'
import {
  onDimensionChanged,
  onViewChanged,
  onTableSort,
  onRemoveTempFilter,
  onApplyTempFilter,
  onWidgetsOptionsChanged,
  updateWidgetData,
  onChangeWidgetEnabledStatus
} from '../../actions/niq-widget-actions'
import constants, { aggregationSettings } from '../../constants/niq-constants'
import cx from 'classnames'

import { NIQ_SEARCH_EDIT_STORE_PATH, NIQ_SEARCH_STORE_PATH } from '../../reducers/niq-reducers'

class TableWidget extends Component {
  getHeightForTable = () => {
    if (this.props.height) {
      let offset = 30 // offset for toolbar
      if (this.props.showToolbar) {
        offset += 60 // offset for tableOptions
      }
      return this.props.height - offset
    }
  }

  getHeightForChart = () => {
    if (this.props.height) {
      let offset = 55 // offset for toolbar
      if (this.props.showToolbar) {
        offset += 60 // offset for tableOptions
      }
      return this.props.height - offset
    }
  }

  handleExportCSV = () => {
    if (this.tableWrapper && this.tableWrapper.table && this.tableWrapper.table.handleExportCSV) {
      this.tableWrapper.table.handleExportCSV()
    }
  }

  getExportFileName = () => {
    const date = new Date()
    return (
      `${this.props.aggType}_` +
      `${date.getFullYear()}_${date.getMonth()}_${date.getDate()}_` +
      `${date.getHours()}_${date.getMinutes()}_${date.getSeconds()}` +
      '.csv'
    )
  }

  render() {
    return (
      <div className={`table-widget`}>
        <div>
          <div className="table-widget-header">
            <div className="table-title-wrapper pull-left">
              <h5 className="pull-left table-title">
                {this.props.getTitle(this.props.data && this.props.data.length ? this.props.data.length : 0)}
              </h5>
              <div
                onClick={() => this.props.onChangeWidgetEnabledStatus(this.props.aggType)}
                className={cx(
                  'pull-left widget-enable-status search-qc-statusButton',
                  this.props.widgetEnabledStatus[this.props.aggType] ? 'active' : 'inactive'
                )}
              >
                <span className={'statusMarker'} />
              </div>
            </div>
            <TableToolbar
              selectedView={this.props.selectedView}
              onViewChanged={this.props.onViewChanged}
              aggType={this.props.aggType}
              showToolbar={this.props.showToolbar}
              onWidgetsOptionsChanged={this.props.onWidgetsOptionsChanged}
              updateWidgetData={this.props.updateWidgetData}
              onExport={this.handleExportCSV}
            />
          </div>
          {
            <WidgetTableOptions
              selectedSize={this.props.selectedSize}
              onWidgetsOptionsChanged={this.props.onWidgetsOptionsChanged}
              updateWidgetData={this.props.updateWidgetData}
              aggType={this.props.aggType}
              showToolbar={this.props.showToolbar}
            />
          }
          {this.props.selectedView === 'chart' ? (
            <div>
              <TableChartToolbar
                selectedField={this.props.selectedField}
                onDimensionChanged={this.props.onDimensionChanged}
                aggType={this.props.aggType}
              />
              {this.props.loading && <Loader />}
              <TableChart
                data={this.props.data}
                selectedField={this.props.selectedField}
                aggType={this.props.aggType}
                nameField={this.props.nameField}
                height={this.getHeightForChart()}
                width={this.props.width}
              />
            </div>
          ) : (
            <SearchTable
              ref={el => {
                this.tableWrapper = el
              }}
              data={this.props.data}
              filter={this.props.filter}
              onTableSort={this.props.onTableSort}
              aggType={this.props.aggType}
              nameField={this.props.nameField}
              onApplyTempFilter={this.props.onApplyTempFilter}
              onRemoveTempFilter={this.props.onRemoveTempFilter}
              loading={this.props.loading}
              sortBy={this.props.sortBy}
              sortOrder={this.props.sortOrder}
              selectedField={this.props.selectedField}
              contextStats={this.props.contextStats}
              height={this.getHeightForTable()}
              csvFileName={this.getExportFileName()}
            />
          )}
        </div>
      </div>
    )
  }
}

TableWidget.propTypes = {
  getTitle: PropTypes.func,
  filter: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.object),
  selectedField: PropTypes.string,
  selectedView: PropTypes.string,
  onDimensionChanged: PropTypes.func,
  onViewChanged: PropTypes.func,
  nameField: PropTypes.string,
  aggType: PropTypes.string,
  onTableSort: PropTypes.func,
  onApplyTempFilter: PropTypes.func,
  onRemoveTempFilter: PropTypes.func,
  loading: PropTypes.bool,
  sortOrder: PropTypes.string,
  sortBy: PropTypes.string,
  selectedSize: PropTypes.number,
  onWidgetsOptionsChanged: PropTypes.func,
  updateWidgetData: PropTypes.func,
  showToolbar: PropTypes.bool,
  height: PropTypes.number,
  width: PropTypes.number,
  contextStats: PropTypes.object,
  onChangeWidgetEnabledStatus: PropTypes.func,
  widgetEnabledStatus: PropTypes.object
}
function mapStateToProps(state, props) {
  const niqSearchState = state[NIQ_SEARCH_STORE_PATH]
  const niqSearchEditState = state[NIQ_SEARCH_EDIT_STORE_PATH]
  return {
    data: niqSearchEditState[props.aggType].data,
    loading: niqSearchEditState[props.aggType].loading,
    aggregation: niqSearchEditState[props.aggType].aggregation,
    selectedView: niqSearchEditState[props.aggType].selectedView,
    selectedField: niqSearchEditState[props.aggType].selectedField,
    filter: niqSearchEditState.description.tempFilters[props.aggType],
    nameField: aggregationSettings[props.aggType]?.aggregationField ?? constants.esFields[props.aggType],
    sortBy: niqSearchEditState[props.aggType].sortBy,
    sortOrder: niqSearchEditState[props.aggType].sortOrder,
    showToolbar: niqSearchEditState[props.aggType].showToolbar,
    selectedSize: niqSearchEditState[props.aggType].selectedSize,
    contextStats: niqSearchEditState.stats[constants.aggregations.CONTEXT_REVENUE],
    widgetEnabledStatus: niqSearchState.widgetEnabledStatus
  }
}

export default connect(mapStateToProps, {
  onDimensionChanged,
  onViewChanged,
  onTableSort,
  onRemoveTempFilter,
  onApplyTempFilter,
  onWidgetsOptionsChanged,
  updateWidgetData,
  onChangeWidgetEnabledStatus
})(TableWidget)
