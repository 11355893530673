import React, { Component } from 'react'
import PropTypes from 'prop-types'
import DrilldownChart from './DrilldownChart'
import {
  onSelectedTimeRangeChanged,
  onChangeDrilldownEnabledStatus,
  refreshQCGraphData
} from '../../../actions/qc-actions'
import { connect } from 'react-redux'
import { isEmpty } from 'lodash'

class DrilldownChartWidget extends Component {
  onSelectedTimeRangeChanged = (start, end) => {
    this.props.onSelectedTimeRangeChanged({ from: start, to: end })
  }

  render() {
    if (isEmpty(this.props.splitByData.revenue) && isEmpty(this.props.splitByData.last_revenue)) {
      return <div />
    }

    return (
      <div className="qctool-widget">
        <div>
          <DrilldownChart
            drillDownBy={this.props.drillDownBy}
            chartView={this.props.chartView}
            data={this.props.data}
            title={this.props.title}
            onSelectedTimeRangeChanged={this.props.onSelectedTimeRangeChanged}
            splitByData={this.props.splitByData}
            drilldownEnabledStatus={this.props.drilldownEnabledStatus}
            onChangeDrilldownEnabledStatus={this.props.onChangeDrilldownEnabledStatus}
            refreshQCGraphData={this.props.refreshQCGraphData}
          />
        </div>
      </div>
    )
  }
}

DrilldownChartWidget.propTypes = {
  chartView: PropTypes.string,
  drillDownBy: PropTypes.object,
  data: PropTypes.object,
  title: PropTypes.string,
  onSelectedTimeRangeChanged: PropTypes.func,
  splitByData: PropTypes.object,
  drilldownEnabledStatus: PropTypes.object,
  onChangeDrilldownEnabledStatus: PropTypes.func,
  refreshQCGraphData: PropTypes.func
}

function mapStateToProps(state) {
  return {
    chartView: state.searchQC.chartView,
    drillDownBy: state.searchQC.drillDownBy,
    data: state.searchQC.drillDownChart.data,
    title: '',
    splitByData: state.searchQC.splitByChart.data,
    drilldownEnabledStatus: state.searchQC.drilldownEnabledStatus
  }
}

export default connect(mapStateToProps, {
  onSelectedTimeRangeChanged,
  onChangeDrilldownEnabledStatus,
  refreshQCGraphData
})(DrilldownChartWidget)
