import React from 'react'
import PropTypes from 'prop-types'
import { uniqBy } from 'lodash'
import { Form } from 'react-bootstrap'

import InputField, { useFormValues } from 'components/FormInputComponents/InputField'

import useMode from 'components/Rules/Common/useMode'
import useFormButtons from 'components/Rules/Common/useFormButtons'
import NoteFields from 'components/Rules/Common/NoteFields'
import CommonRuleModalButtons from 'components/Rules/Common/CommonRuleModalButtons'
import CategoryBrandPathSuggestion from 'components/CategoryBrandPathSuggestion'
import { getSubmitButtonName } from '../../../Rules/Common/utils'

import ManageFieldButtons from './ManageFieldButtons'
import useNewBrandValidation from './useNewBrandValidation'
import useBrandRequestsModalButtons from './useBrandRequestsModalButtons'

import './brandRequestModal.scss'

const emptyCategoryObj = {
  id: undefined,
  fullPath: null
}

const BrandRequestModal = ({ value, onOkHandler, onRejectHandler, isReadOnly }) => {
  const { id, brand, categories, synonyms, request_notes, review_notes } = value

  const isRequest = true
  const mode = useMode(isRequest, isReadOnly, id)

  const { values, handleFieldChange, updateValue } = useFormValues({
    id: id ?? null,
    brand,
    categories,
    synonyms,
    request_notes: request_notes ?? null,
    review_notes: review_notes ?? null
  })

  const { brandHelpInfo, isBrandValid, isBrandReady } = useNewBrandValidation(values.brand)

  const {
    addCategoryFieldHandler,
    removeCategoryFieldHandler,
    handleCategoryChange,
    addSynonymFieldHandler,
    removeSynonymFieldHandler,
    handleSynonymChange
  } = useBrandRequestsModalButtons({ updateValue, emptyCategoryObj })

  // ********* modal buttons ***********//
  let isOkButtonEnabled = true
  let isRejectButtonEnabled = true

  const isCategoryExists = !!values.categories?.[0]?.id
  if (mode.isRequestCreate && (!isBrandReady || !isCategoryExists)) {
    isOkButtonEnabled = false
  }

  if (mode.isRequestApprove && !isBrandReady) {
    isOkButtonEnabled = false
  }

  if (mode.isRequestApprove && !values.review_notes) {
    isRejectButtonEnabled = false
  }

  const sentFieldsForRequests = ['brand', 'categories', 'synonyms', 'request_notes', 'review_notes']
  const { handleOkButton, handleRejectButton, handleCancelButton } = useFormButtons({
    onOkHandler,
    onRejectHandler,
    values: convertValuesForSave(values),
    sentFieldsForRequests,
    isRequest
  })
  // ********* /modal buttons ***********//

  return (
    <Form className="text-left" horizontal>
      <div className="brand-request-modal-form-body">
        <InputField
          id="brand"
          label="New Brand"
          type="input"
          placeholder="new Brand"
          value={values.brand || ''}
          onChange={handleFieldChange}
          validationState={!mode.isRequestView && !isBrandValid ? 'error' : null}
          disabled={mode.isRequestApprove || mode.isRequestView}
          help={mode.isRequestView ? null : <div className="pl-1 pt-2">{brandHelpInfo}</div>}
          required
        />

        {values.categories.map((category, i) => (
          <CategoryBrandPathSuggestion
            key={i}
            categoryId={category.id}
            categoryFullPath={category.fullPath}
            handleCategoryChange={handleCategoryChange}
            isShowUseCategory={false}
            readOnly={mode.isRequestApprove || mode.isRequestView}
            required={i === 0}
            index={i}
          />
        ))}

        {mode.isRequestCreate && (
          <ManageFieldButtons
            name={'Category'}
            addHandler={addCategoryFieldHandler}
            removeHandler={removeCategoryFieldHandler}
            fieldsCount={values.categories.length}
            minFildCount={1}
          />
        )}

        {values.synonyms.map((synonym, i) => (
          <InputField
            key={i}
            label={`Synonym #${i + 1}`}
            placeholder="Synonym"
            type="input"
            data-index={i}
            value={synonym || ''}
            onChange={handleSynonymChange}
            validationState={synonym === '' ? 'error' : null}
            disabled={mode.isRequestApprove || mode.isRequestView}
          />
        ))}
        {mode.isRequestCreate && (
          <ManageFieldButtons
            name={'Synonym'}
            addHandler={addSynonymFieldHandler}
            removeHandler={removeSynonymFieldHandler}
            fieldsCount={values.synonyms.length}
          />
        )}

        <NoteFields
          mode={mode}
          request_notes={values.request_notes}
          review_notes={values.review_notes}
          handleFieldChange={handleFieldChange}
        />
      </div>

      <CommonRuleModalButtons
        handleOkButton={handleOkButton}
        handleRejectButton={handleRejectButton}
        handleCancelButton={handleCancelButton}
        mode={mode}
        okButtonName={getSubmitButtonName(mode)}
        isOkButtonDisabled={!isOkButtonEnabled}
        isRejectButtonDisabled={!isRejectButtonEnabled}
      />
    </Form>
  )
}

BrandRequestModal.defaultProps = {
  value: {
    id: null,
    brand: null,
    categories: [emptyCategoryObj],
    synonyms: [],
    request_notes: null,
    review_notes: null
  },
  isReadOnly: false
}

BrandRequestModal.propTypes = {
  value: PropTypes.shape({
    id: PropTypes.number,
    brand: PropTypes.string,
    categories: PropTypes.arrayOf(PropTypes.object),
    synonyms: PropTypes.arrayOf(PropTypes.string),
    request_notes: PropTypes.string,
    review_notes: PropTypes.string
  }),

  onOkHandler: PropTypes.func.isRequired,
  onRejectHandler: PropTypes.func,

  isReadOnly: PropTypes.bool
}

export default BrandRequestModal

const convertValuesForSave = values => {
  // remove duplicates
  const uniqCategories = uniqBy(values.categories, 'id').filter(c => c.id)

  const synonymsForSave = uniqBy(values.synonyms) // remove duplicates
    .filter(s => s) // remove empty
    .map(s => s.trim()) // trim values

  const brandForSave = values.brand ? values.brand.trim() : values.brand
  return {
    ...values,
    brand: brandForSave,
    categories: uniqCategories,
    synonyms: synonymsForSave
  }
}
