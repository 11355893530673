import React from 'react'
import { Typography } from '@mui/material'
import PropTypes from 'prop-types'

const Caption = ({ title }) => {
  return (
    <Typography variant="h5" sx={{ pl: 2, pt: 2, pb: 1, fontWeight: 700 }}>
      {title}
    </Typography>
  )
}

Caption.propTypes = {
  title: PropTypes.string
}

export default Caption
