import React from 'react'
import PropTypes from 'prop-types'
import { Glyphicon, OverlayTrigger, Tooltip } from 'react-bootstrap'
import cx from 'classnames'

const GlobalInactiveWarning = ({ show }) => {
  return (
    <OverlayTrigger
      placement="top"
      overlay={
        <Tooltip id="category-test-modal-tooltip">
          This option was disabled globally. Turn-on global option if you need to enable it.
        </Tooltip>
      }
    >
      <Glyphicon glyph="warning-sign" className={cx('category-tests-modal-icon-warn', { hidden: !show })} />
    </OverlayTrigger>
  )
}

GlobalInactiveWarning.propTypes = {
  show: PropTypes.bool.isRequired
}

export default GlobalInactiveWarning
