import React, { Component } from 'react'
import { Modal, Button, FormGroup, FormControl, Form, Checkbox, OverlayTrigger, Tooltip, Col } from 'react-bootstrap'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import { validEmailTypes, permissions as PERMISSIONS } from '../../constants/constants'
import PropTypes from 'prop-types'
import {
  onAddToDictRegexChange,
  onAddToDictTemplateChange,
  onFromAddrRegexChange,
  onAddToDictModalClosed,
  onAddEntryToDict,
  onDictionaryLabelChange,
  onUseInTrainingChange,
  onIsDeletedChange
} from '../../actions/supra-qc-tool-actions'
import { toggleDictionaryEntryDeletedStatus } from './actions'
import { connect } from 'react-redux'
import AddToDictResultsTable from './AddToDictResultsTable'
import Loader from '../Loader'
import UserService from '../../services/user-service'
import './style.scss'

const CheckboxItem = (onClickHandler, isChecked) => {
  return <Checkbox className="text-center" onClick={onClickHandler} checked={isChecked} />
}

class AddToDictModal extends Component {
  dictLabelFormatter = (cell, row) => {
    return (
      <FormControl
        bsClass="form-control add-to-dict-form-control"
        componentClass="select"
        type="text"
        value={this.props.dictionaryLabel}
        placeholder="Enter new label"
        onChange={this.handleDictionaryLabelChange}
      >
        <option value={null}>Select Label</option>
        {validEmailTypes.map(emailType => {
          return (
            <option key={emailType} value={emailType}>
              {emailType}
            </option>
          )
        })}
      </FormControl>
    )
  }

  handleTemplateChange = event => {
    this.props.onAddToDictTemplateChange({ template: event.target.value, source: this.props.source })
  }

  handleRegexChange = event => {
    this.props.onAddToDictRegexChange({ regex: event.target.value, source: this.props.source })
  }

  handleFromAddrRegexChange = event => {
    this.props.onFromAddrRegexChange({ from_addr_regex: event.target.value, source: this.props.source })
  }

  handleDictionaryLabelChange = event => {
    this.props.onDictionaryLabelChange({ dictionaryLabel: event.target.value })
  }

  handleUseInTrainingChange = event => {
    this.props.onUseInTrainingChange({ useInTraining: event.target.checked })
  }

  handleIsDeletedChange = event => {
    this.props.onIsDeletedChange({ isDeleted: event.target.checked })
    this.props.toggleDictionaryEntryDeletedStatus({
      dictionaryId: this.props.selectedRow.id,
      deleted: event.target.checked
    })
  }

  handleClose = event => {
    this.props.onAddToDictModalClosed()
  }

  addEntryToDict = () => {
    const dictionaryEntry = {
      isRegex: this.props.isRegex,
      fromAddr: this.props.from_addr_regex ? this.props.from_addr_regex : this.props.selectedRow.from_addr,
      selectedRowId: this.props.selectedRow.id,
      domain: this.props.selectedRow.domain,
      subject: this.props.regex ? this.props.regex : this.props.selectedRow.subject,
      template: this.props.template,
      emailType: this.props.dictionaryLabel,
      useInTraining: this.props.useInTraining,
      sampleSubject: this.props.selectedRow.subject,
      existingRow: this.props.selectedRow.is_in_dictionary,
      dictionaryId: this.props.selectedRow.id
    }
    this.props.onAddEntryToDict({ dictionaryEntry })
  }

  overlayFormatter = (cell, row, placement = 'right') => {
    return (
      <OverlayTrigger placement={placement} delayShow={500} overlay={this.tooltip(cell)}>
        <span>{cell}</span>
      </OverlayTrigger>
    )
  }

  tooltip = name => <Tooltip id="add-to-dictionary-tooltip">{name}</Tooltip>

  render() {
    return (
      <Modal backdrop="static" dialogClassName="regex-utility-modal" show={this.props.show} onHide={this.handleClose}>
        <Modal.Header>
          <Modal.Title>Add to Dictionary</Modal.Title>
        </Modal.Header>

        {this.props.modalLoading ? (
          <Loader />
        ) : (
          <Modal.Body>
            <BootstrapTable data={[{ ...this.props.selectedRow, sequence: 1 }]} striped condensed bordered>
              <TableHeaderColumn dataField="sequence" hidden isKey>
                Sequence
              </TableHeaderColumn>

              <TableHeaderColumn
                dataFormat={this.overlayFormatter}
                tdStyle={{ whiteSpace: 'normal' }}
                dataField="original_template"
              >
                Original Template
              </TableHeaderColumn>

              <TableHeaderColumn
                dataFormat={this.overlayFormatter}
                tdStyle={{ whiteSpace: 'normal' }}
                dataField="subject"
              >
                Subject
              </TableHeaderColumn>

              <TableHeaderColumn
                tdStyle={{ whiteSpace: 'normal' }}
                dataFormat={this.overlayFormatter}
                placement="right"
                dataField="from_addr"
              >
                From
              </TableHeaderColumn>
              <TableHeaderColumn dataField="domain">Domain</TableHeaderColumn>
              <TableHeaderColumn dataField="type">Original Type</TableHeaderColumn>

              <TableHeaderColumn
                tdStyle={{ whiteSpace: 'normal' }}
                dataField="dictLabel"
                dataFormat={this.dictLabelFormatter}
              >
                New Type
              </TableHeaderColumn>

              {this.props.userHasSupraQCAdminPermission && (
                <TableHeaderColumn
                  tdStyle={{ whiteSpace: 'normal' }}
                  dataField="isDeleted"
                  dataFormat={() => CheckboxItem(this.handleIsDeletedChange, this.props.isDeleted)}
                >
                  Is Deleted
                </TableHeaderColumn>
              )}

              <TableHeaderColumn
                tdStyle={{ whiteSpace: 'normal' }}
                dataField="useInTraining"
                dataFormat={() => CheckboxItem(this.handleUseInTrainingChange, this.props.useInTraining)}
              >
                Use In Training
              </TableHeaderColumn>
            </BootstrapTable>

            <Form horizontal className="mt-3">
              <FormGroup>
                <Col sm={2}>Template</Col>
                <Col sm={10}>
                  <FormControl
                    bsClass="form-control add-to-dict-form-control"
                    type="text"
                    value={this.props.template}
                    placeholder="Enter regex value"
                    onChange={this.handleTemplateChange}
                  />
                  <FormControl.Feedback />
                </Col>
              </FormGroup>

              <FormGroup>
                <Col sm={2}>Regex</Col>
                <Col sm={10}>
                  <FormControl
                    bsClass="form-control add-to-dict-form-control"
                    type="text"
                    value={this.props.regex}
                    placeholder="Enter regex value"
                    disabled
                  />
                </Col>
                <FormControl.Feedback />
              </FormGroup>

              <FormGroup>
                <Col sm={2}>From Address</Col>
                <Col sm={10}>
                  <FormControl
                    bsClass="form-control add-to-dict-form-control"
                    type="text"
                    value={this.props.from_addr_regex}
                    placeholder="Enter regex value"
                    onChange={this.handleFromAddrRegexChange}
                  />
                </Col>
                <FormControl.Feedback />
              </FormGroup>
            </Form>

            <hr />
            {this.props.loading ? <Loader /> : <AddToDictResultsTable />}
          </Modal.Body>
        )}

        <Modal.Footer>
          <Button onClick={this.handleClose}>Close</Button>
          <Button onClick={this.addEntryToDict} bsStyle="primary">
            Save changes
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

function mapStateToProps(state) {
  return {
    show: state.supraQCTool.addToDictModal.show,
    selectedRow: state.supraQCTool.addToDictModal.selectedRow,
    regex: state.supraQCTool.addToDictModal.regex,
    source: state.supraQCTool.addToDictModal.source,
    from_addr_regex: state.supraQCTool.addToDictModal.from_addr_regex,
    dictionaryLabel: state.supraQCTool.addToDictModal.dictionaryLabel,
    useInTraining: state.supraQCTool.addToDictModal.useInTraining,
    isDeleted: state.supraQCTool.addToDictModal.isDeleted,
    isRegex: state.supraQCTool.addToDictModal.isRegex,
    loading: state.supraQCTool.addToDictResult.loading,
    modalLoading: state.supraQCTool.addToDictModal.loading,
    template: state.supraQCTool.addToDictModal.template,
    userHasSupraQCAdminPermission: UserService.hasPermission(state.session.user, PERMISSIONS.supraQCToolAdmin)
  }
}

AddToDictModal.propTypes = {
  show: PropTypes.bool,
  selectedRow: PropTypes.object,
  regex: PropTypes.string,
  dictionaryLabel: PropTypes.string,
  useInTraining: PropTypes.bool,
  isDeleted: PropTypes.bool,
  isRegex: PropTypes.bool,
  onAddToDictModalOpen: PropTypes.func,
  onAddToDictRegexChange: PropTypes.func,
  onAddToDictTemplateChange: PropTypes.func,
  onFromAddrRegexChange: PropTypes.func,
  onDictionaryLabelChange: PropTypes.func,
  onUseInTrainingChange: PropTypes.func,
  onIsDeletedChange: PropTypes.func,
  onIsRegexChange: PropTypes.func,
  onAddToDictModalClosed: PropTypes.func,
  onAddEntryToDict: PropTypes.func,
  loading: PropTypes.bool,
  modalLoading: PropTypes.bool,
  from_addr_regex: PropTypes.string,
  source: PropTypes.string,
  template: PropTypes.string,
  userHasSupraQCAdminPermission: PropTypes.bool,
  toggleDictionaryEntryDeletedStatus: PropTypes.func
}

export default connect(mapStateToProps, {
  onAddToDictRegexChange,
  onAddToDictTemplateChange,
  onFromAddrRegexChange,
  onDictionaryLabelChange,
  onUseInTrainingChange,
  onIsDeletedChange,
  onAddToDictModalClosed,
  onAddEntryToDict,
  toggleDictionaryEntryDeletedStatus
})(AddToDictModal)
