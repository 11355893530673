import React, { useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { isEmpty } from 'lodash'

import Metric from './Metric/Metric'
import AreaChart from './Charts/AreaChart'

import useCategoryInfo from './Metric/useCategoryInfo'
import metricsActions from './metricsActions'
import { METRIC_STORE_KEY } from './constants'
import { getMetricWarningMessage } from './utils'

const DictionaryWidget = () => {
  const dispatch = useDispatch()

  const { data, loading } = useSelector(state => state.searchEdit[METRIC_STORE_KEY.DICTIONARY_COVERAGE])
  const { label } = useCategoryInfo()

  const refreshGraph = useCallback(() => {
    dispatch(metricsActions.fetchDictionaryGraph())
  }, [dispatch])

  useEffect(() => {
    refreshGraph()
    // this dependency is necessary for updating the graph after category changing
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [label])

  const warningMessage = getMetricWarningMessage({ isNoData: isEmpty(data) })

  return (
    <Metric loading={loading} warningMessage={warningMessage}>
      <Metric.Header title="Dictionary Coverage" refreshGraph={refreshGraph} />
      <AreaChart data={data} subTitle={label} yAxisTitle="Percent" threshold={50} />
    </Metric>
  )
}

export default React.memo(DictionaryWidget)
