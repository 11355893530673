import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import DocumentTitle from 'react-document-title'

import { Row, Col } from 'react-bootstrap'
import Loader from '../../Loader'
import DataTable from './../../DataTable'
import buildContractColumns from '../ContractsPage/contract-columns'
import ContractForm from './ContractForm'

import { fetchContracts, saveContract, deleteContract, downloadContracts } from '../../../actions/dm-actions'
import { addFormModal, confirmModal } from '../../../actions/modal-actions'
import { appName } from '../../../constants/constants'
import UserService from '../../../services/user-service'

class ContractsPage extends Component {
  componentDidMount() {
    this.fetchContracts({ showInactive: this.props.showInactive })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedClientId !== this.props.selectedClientId) {
      this.fetchContracts({ page: 1, showInactive: this.props.showInactive })
    }
  }

  openContractForm = contract => {
    this.props.addFormModal({
      id: 'contract-modal',
      className: 'contract-edit-modal',
      title: contract ? 'Contract' : 'New Contract',
      content: <ContractForm contract={contract} selectedClientId={this.props.selectedClientId} />
    })
  }

  confirmDeleteForm = contract => {
    const { isPortalAdmin, confirmModal, deleteContract } = this.props
    if (isPortalAdmin) {
      confirmModal(
        `Are you sure you want to delete Contract - "${contract.name}" ? It will also delete all the Deliverables and Deliveries under it. This action cannot be undone.`,
        `Delete "${contract.name}"`,
        { okButton: 'Delete' },
        () => deleteContract(contract.id)
      )
    } else {
      confirmModal(
        `You do not have permission to delete Contract - "${contract.name}". Please contact portal admin to delete it.`,
        `Oops!`,
        { okButton: null }
      )
    }
  }

  onCreateNew = () => {
    this.openContractForm()
  }

  onToggleStatus = contract => {
    this.props.saveContract({
      id: contract.id,
      isActive: !contract.isActive
    })
  }

  onSortChange = (sortBy, sortOrder) => {
    this.fetchContracts({
      sortBy,
      sortOrder,
      page: 1,
      showInactive: this.props.showInactive
    })
  }

  onPageChange = page => {
    this.fetchContracts({
      page,
      showInactive: this.props.showInactive
    })
  }

  trClassName = row => {
    return row.isActive === false ? 'inactive' : ''
  }

  fetchContracts = payload => {
    const {
      sortBy = this.props.sortBy,
      sortOrder = this.props.sortOrder,
      page = this.props.page,
      pageSize = this.props.pageSize,
      showInactive = this.props.showInactive
    } = payload || {}
    this.props.fetchContracts({
      sortBy,
      sortOrder,
      page,
      pageSize,
      showInactive
    })
  }

  handleDownload = clickEvent => {
    clickEvent.preventDefault()
    this.props.downloadContracts()
  }

  render() {
    const { page = 1, data, totalContractCount = 0, pageSize, sortBy, sortOrder, loading, documentTitle } = this.props
    const maxPages = Math.ceil(totalContractCount / pageSize) || 1
    const columns = buildContractColumns({
      onEdit: this.openContractForm,
      onToggleStatus: this.onToggleStatus,
      onDelete: this.confirmDeleteForm
    })

    return (
      <DocumentTitle title={documentTitle ? `${appName} | ${documentTitle}` : appName}>
        <div>
          {loading && <Loader overlap />}

          <Row>
            <Col sm={12} className="action-row">
              <button
                onClick={this.handleDownload}
                className="btn_custom_secondary download-csv-button-custom  pull-right"
              >
                Download CSV
              </button>

              <button className={'btn_custom pull-right'} type="button" onClick={this.onCreateNew}>
                {`Create New Contract`}
              </button>
            </Col>
          </Row>

          <DataTable
            ref={el => {
              this.dataTable = el
            }}
            data={data}
            trClassName={this.trClassName}
            tableClassName="contracts-table"
            columns={columns}
            page={page}
            maxPages={maxPages}
            onPageChange={this.onPageChange}
            sortName={sortBy}
            sortOrder={sortOrder}
            onSortChange={this.onSortChange}
          />
        </div>
      </DocumentTitle>
    )
  }
}

ContractsPage.propTypes = {
  totalContractCount: PropTypes.number,
  selectedClientId: PropTypes.number,
  page: PropTypes.number,
  pageSize: PropTypes.number,
  sortBy: PropTypes.string,
  sortOrder: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool,
  fetchContracts: PropTypes.func,
  addFormModal: PropTypes.func,
  confirmModal: PropTypes.func,
  saveContract: PropTypes.func,
  deleteContract: PropTypes.func,
  isPortalAdmin: PropTypes.bool,
  documentTitle: PropTypes.string,
  showInactive: PropTypes.bool,
  downloadContracts: PropTypes.func
}

ContractsPage.defaultProps = {
  documentTitle: 'Delivery Manager - Contracts'
}

function mapStateToProps({
  dm: {
    contracts,
    totalContractCount,
    selectedClient: { id: selectedClientId },
    showInactive
  },
  session: { user } = {}
}) {
  return {
    totalContractCount,
    page: contracts.page,
    pageSize: contracts.pageSize,
    sortBy: contracts.sortBy,
    sortOrder: contracts.sortOrder,
    data: contracts.data,
    loading: contracts.loading,
    selectedClientId,
    isPortalAdmin: user ? UserService.isPortalAdmin(user) : false,
    showInactive
  }
}
export default connect(mapStateToProps, {
  fetchContracts,
  addFormModal,
  confirmModal,
  deleteContract,
  saveContract,
  downloadContracts
})(ContractsPage)
