import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { default as constants } from '../../constants/constants'
import { connect } from 'react-redux'
import { searchTermSuggest, changeCategoryFilter } from '../../actions/search-actions'
import ChipSelector from '../ChipSelector'

function isParent(suggestion) {
  return constants.fields[suggestion.type].canBeParent && !suggestion.isChild
}

function getCategoryFilterList(state) {
  return state.search.searchFilters.categoryFilterList || []
}

function mapStateToProps(state, ownProps) {
  const categoryFilterList = getCategoryFilterList(state)
  return {
    searchSuggestions: state.search.searchSuggestions.reduce((suggArray, suggestion) => {
      for (let i = 0; i < categoryFilterList.length; i++) {
        if (categoryFilterList[i].id === suggestion.id) {
          return suggArray
        }
      }
      suggArray.push({
        ...suggestion,
        prefixLabel: isParent(suggestion) ? constants.parentElementPrefix : ''
      })
      return suggArray
    }, []),
    categoryFilterList
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onCategorySearchTermSuggest: ({ term, filter, excludeParents, excludeInactive }) => {
      dispatch(searchTermSuggest({ term, filter, excludeParents, excludeInactive }))
    },
    onChangeCategoryFilter: filter => {
      dispatch(changeCategoryFilter(filter))
    }
  }
}

class CategoryFilter extends Component {
  static propTypes = {
    onChangeCategoryFilter: PropTypes.func,
    onCategorySearchTermSuggest: PropTypes.func,
    searchSuggestions: PropTypes.arrayOf(PropTypes.object),
    categoryFilterList: PropTypes.array
  }

  renderSearchSuggestion = option => {
    return (
      <span>
        <span>{`${option.prefixLabel ? option.prefixLabel : ''}`}</span>
        <span style={{ fontWeight: 'bold', whiteSpace: 'normal' }}>{option.value}</span>
      </span>
    )
  }

  onSearchChange = text => {
    this.props.onCategorySearchTermSuggest({
      term: text,
      filter: 'category',
      excludeParents: true,
      excludeInactive: true
    })
  }

  render() {
    return (
      <ChipSelector
        className="category-filter-comp"
        selectedValues={this.props.categoryFilterList}
        onSelectionChange={this.props.onChangeCategoryFilter}
        onSearchChange={this.onSearchChange}
        searchSuggestions={this.props.searchSuggestions}
        renderSearchSuggestion={this.renderSearchSuggestion}
        placeholder="Select Categories..."
        multiple
        showRemoveAllButton
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CategoryFilter)
