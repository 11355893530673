import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

class SelectedName extends Component {
  static propTypes = {
    onChangeMode: PropTypes.func,
    name: PropTypes.string,
    class: PropTypes.string,
    type: PropTypes.string,
    entity: PropTypes.object
  }

  getTitle = () => {
    const { entity } = this.props
    const createdAtReadable = moment(entity?.createdAt).format('YYYY-MM-DD HH:mm:ss')
    const updatedAtReadable = moment(entity?.updatedAt).format('YYYY-MM-DD HH:mm:ss')
    return `${this.props.name} \ncreatedBy: ${entity?.creator?.fullname} \ncreatedAt: ${createdAtReadable} \nupdatedBy: ${entity?.updater?.fullname} \nupdatedAt: ${updatedAtReadable}`
  }

  render() {
    return (
      <div
        className={this.props.class}
        onDoubleClick={e => {
          e.preventDefault()
          this.props.onChangeMode({ type: this.props.type, value: 'edit' })
        }}
        title={this.getTitle()}
      >
        {this.props.name}
      </div>
    )
  }
}

export default SelectedName
