import React from 'react'
import PropTypes from 'prop-types'

import TaskRuntimes from './Widgets/TaskRuntimes'
import AvgRuntimesChart from './Widgets/AvgRuntimesChart'

import './index.scss'

const ETLStatsWidgets = ({ taskRuntimes }) => {
  return (
    <div className="etl-stats-widget-container">
      <div className="etl-stats-widget-row">
        <AvgRuntimesChart taskRuntimes={taskRuntimes} />

        <TaskRuntimes taskRuntimes={taskRuntimes} />
      </div>
    </div>
  )
}

ETLStatsWidgets.propTypes = {
  tasks: PropTypes.array,
  taskRuntimes: PropTypes.object
}

export default ETLStatsWidgets
