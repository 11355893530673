import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReactHighstock from 'react-highcharts/ReactHighstock'
import moment from 'moment'
import { filter, some, forEach, map } from 'lodash'
import constants from '../../../constants/constants'
import ZoomResetButton from './ZoomResetButton'
import noData from 'highcharts/js/modules/no-data-to-display'
import Loader from '../../Loader'
import { Button, OverlayTrigger, Tooltip, Glyphicon } from 'react-bootstrap'
import cx from 'classnames'
import { simpleNumberFormatter } from '../../../utils/formatters'

noData(ReactHighstock.Highcharts)

class ChartComponent extends Component {
  constructor(props) {
    super(props)
    ReactHighstock.Highcharts.setOptions({
      lang: {
        thousandsSep: ',',
        noData: ''
      }
    })
  }

  shouldComponentUpdate(nextProps) {
    // Component will render only if below properties are different in current and next Props
    if (this.props.data !== nextProps.data) {
      return (
        this.props.chartView !== nextProps.chartView ||
        (this.props.data && this.props.data.currentSeries !== nextProps.data.currentSeries) ||
        this.props.data.lastSeries !== nextProps.data.lastSeries ||
        this.props.enabled !== nextProps.enabled
      )
    }
    return false
  }

  renderLoading() {
    if (this.props.loading) {
      return (
        <div className="chart-loader-container">
          <Loader />
        </div>
      )
    }
    return <div />
  }

  setExtremesValues(from, to) {
    if (ReactHighstock.Highcharts.charts) {
      ReactHighstock.Highcharts.charts.forEach(chart => {
        if (chart) {
          if (chart.xAxis[0].setExtremes) {
            chart.xAxis[0].setExtremes(from, to, true, true, { trigger: 'syncExtremes' })
          }
        }
      })
    }
  }

  syncExtremes = event => {
    if (event.trigger !== 'syncExtremes') {
      // Prevent feedback loop
      this.setExtremesValues(event.min, event.max)
      this.props.onSelectedTimeRangeChanged(event.min, event.max)
    }
  }

  buildChartInfo = (key, data, isCountRequired = false, isCurrentData = false) => {
    const chartInfo = []
    if (!data) {
      return chartInfo
    }
    const series = []
    const value = Object.keys(data).map(key => {
      return isCountRequired
        ? [moment.utc(data[key][0]).valueOf(), Number(data[key][1])]
        : [moment.utc(data[key][0]).valueOf(), parseFloat(Number(data[key][2]).toFixed(2))]
    })
    series.push({
      name: key,
      data: value,
      color: isCurrentData
        ? constants.chartOptions[this.props.chartView].color.current
        : constants.chartOptions[this.props.chartView].color.last,
      props: this.props
    })
    return series
  }

  buildDeltaChartInfo = (currentSeries, lastSeries, isCountRequired = false) => {
    const series = []
    forEach(currentSeries, element => {
      let lastMap = {}
      let currentMap = {}
      if (element && element.data && element.data.length) {
        currentMap = isCountRequired
          ? Object.assign(...element.data.map(elData => ({ [elData[0]]: parseFloat(Number(elData[1]).toFixed(1)) })))
          : Object.assign(...element.data.map(elData => ({ [elData[0]]: parseFloat(Number(elData[1]).toFixed(2)) })))
      }

      let lastElement = filter(lastSeries, ['name', element.name + constants.aggregationSuffix])
      some(lastSeries, lastEntry => {
        if (lastEntry.name === element.name + constants.aggregationSuffix) {
          lastElement = lastEntry
          return true
        }
      })
      if (lastElement && lastElement.data && lastElement.data.length) {
        forEach(lastElement.data, () => {
          lastMap = isCountRequired
            ? Object.assign(...lastElement.data.map(elData => ({ [elData[0]]: Number(elData[1]) })))
            : Object.assign(
                ...lastElement.data.map(elData => ({ [elData[0]]: parseFloat(Number(elData[1]).toFixed(2)) }))
              )
        })
      }
      const values = map(currentMap, (val, key) => {
        return lastMap[key]
          ? [Number(key), Number(parseFloat(val - lastMap[key]).toFixed(2))]
          : [Number(key), Number(parseFloat(val))]
      })
      series.push({
        name: element.name,
        data: values,
        color: constants.chartOptions[this.props.chartView].color,
        props: this.props
      })
    })
    return series
  }

  checkIfMarkerRequired = currentSeries => {
    let enableMarker = false
    const dataSet = map(currentSeries, 'data')
    some(dataSet, data => {
      if (data.length < 10) {
        enableMarker = true
        return true
      }
    })
    return enableMarker
  }

  buildConfig(key, currentData, lastData, isCountRequired = false) {
    let textLabel = constants.fields[key] ? constants.fields[key].label : key
    let aggName = constants.fields[key] ? constants.fields[key].label : key
    if (isCountRequired && key === constants.aggregations.REVENUE) {
      textLabel = constants.fields.item_count.label
      aggName = constants.fields.item_count.label
    }
    let currentSeries = []
    let lastSeries = []
    let series = []
    if (currentData) {
      currentSeries = this.buildChartInfo(aggName, currentData, isCountRequired, true)
    }
    if (lastData) {
      lastSeries = this.buildChartInfo(aggName + constants.aggregationSuffix, lastData, isCountRequired)
    }
    const isMarkerEnabled = this.checkIfMarkerRequired(currentSeries)

    if (this.props.chartView === 'delta') {
      series = this.buildDeltaChartInfo(currentSeries, lastSeries, isCountRequired)
    } else {
      series = [...currentSeries, ...lastSeries]
    }
    return {
      scrollbar: false,
      navigator: {
        enabled: true,
        height: 20,
        margin: 10,
        top: this.props.splitBy === 'none' ? this.props.height * 2 - 30 : this.props.height - 30
      },
      rangeSelector: {
        enabled: false
      },
      legend: {
        enabled: true,
        floating: true,
        itemDistance: 20,
        x: 10,
        y: -10,
        align: 'left',
        verticalAlign: 'top',
        layout: 'horizontal'
      },
      chart: {
        type: 'line',
        zoomType: 'x',
        margin: [20, 15, 25, 70], // [t, r, b, l],
        backgroundColor: this.props.backgroundColor,
        height: this.props.splitBy === 'none' ? this.props.height * 2 : this.props.height,
        width: this.props.width,
        animation: true
      },
      drilldown: {
        activeAxisLabelStyle: {
          color: 'red'
        }
      },
      xAxis: {
        crosshair: true,
        minRange: 2,
        events: {
          setExtremes: this.syncExtremes
        },
        type: 'datetime',
        title: {
          dateTimeLabelFormats: {
            month: '%b %e, %Y',
            week: '%e. %b',
            day: '%e. %b'
          }
        }
      },
      tooltip: {
        shared: true,
        formatter() {
          const numberOfPoints = this.points.length
          let tooltipMarkup = numberOfPoints
            ? `<span style='font-size: 10px'>${ReactHighstock.Highcharts.dateFormat(
                '%b %Y',
                this.points[0].key
              )}</span><br/>`
            : ''
          let difference_y = 0
          if (numberOfPoints > 1) {
            let differencePercent = 100
            if (this.points[1].y > 0) {
              differencePercent = ((this.points[0].y - this.points[1].y) * 100) / this.points[1].y
            }
            difference_y = `${Math.round(differencePercent * 100) / 100}%`
          }
          this.points.forEach(point => {
            let y_value_k = point.y
            const props = point.series.options.props
            if (props.splitBy || ('drillDownBy' in props && point.series.name.toLowerCase().includes('revenue'))) {
              y_value_k = `$${simpleNumberFormatter(parseFloat(y_value_k))}`
            }
            const seriesName =
              point.series.name.length > 50
                ? `...${point.series.name.substring(point.series.name.length - 45, point.series.name.length)}`
                : point.series.name
            tooltipMarkup += `<span style='color:${point.series.color}'>\u25CF</span> ${seriesName}: <b>${y_value_k}</b><br/>`
          })
          tooltipMarkup +=
            numberOfPoints > 1
              ? `<span style='color:black'>\u25CF</span><span> Difference: <b>${difference_y}</b></span>`
              : ''
          return tooltipMarkup
        }
      },
      yAxis: [
        {
          title: {
            text:
              textLabel.length > 25 ? `...${textLabel.substring(textLabel.length - 25, textLabel.length)}` : textLabel,
            x: 10,
            align: textLabel.length > 15 ? 'low' : 'middle'
          },
          opposite: false
        }
      ],
      plotOptions: {
        series: {
          allowPointSelect: true,
          animation: true,
          marker: {
            enabled: isMarkerEnabled
          }
        }
      },
      title: {
        text: this.props.drillDownBy ? this.props.drillDownBy.name || '' : '',
        floating: true,
        align: 'left',
        style: {
          color: 'black',
          opacity: 0.5,
          fontSize: '12px'
        },
        x: 40,
        y: 25
      },
      credits: {
        enabled: false
      },
      series,
      noData: this.props.loading ? '' : 'No data found'
    }
  }

  resetZoom = () => {
    this.props.onSelectedTimeRangeChanged(null, null)
  }

  renderEnableButton = () => {
    const { chartKey, isCountRequired } = this.props

    const graphKey = chartKey === 'revenue' && isCountRequired ? constants.aggregations.ITEM_COUNT : chartKey

    return (
      <div
        onClick={() => this.props.onChangeEnabledStatus(graphKey)}
        className={cx('pull-right search-qc-statusButton', this.props.enabled ? 'active' : 'inactive')}
      >
        <span className={'statusMarker'} />
      </div>
    )
  }

  renderDataRefreshButtons = () => {
    const { chartKey, isCountRequired } = this.props

    const graphKey = chartKey === 'revenue' && isCountRequired ? constants.aggregations.ITEM_COUNT : chartKey

    const tooltip = name => <Tooltip id="qc-tooltip">{name}</Tooltip>

    return (
      <div className="refresh-graph-icon">
        <OverlayTrigger delayShow={1000} placement="top" overlay={tooltip('Refresh Current')}>
          <Button
            bsSize="xsmall"
            bsStyle="default"
            className="current-refresh action-button"
            onClick={() => {
              this.props.refreshQCGraphData({ type: 'current', chartType: graphKey })
            }}
          >
            <Glyphicon glyph="refresh" />
          </Button>
        </OverlayTrigger>
        <OverlayTrigger delayShow={1000} placement="top" overlay={tooltip('Refresh Previous')}>
          <Button
            bsSize="xsmall"
            bsStyle="default"
            className="last-refresh action-button"
            onClick={() => {
              this.props.refreshQCGraphData({ type: 'previous', chartType: graphKey })
            }}
          >
            <Glyphicon glyph="refresh" />
          </Button>
        </OverlayTrigger>
      </div>
    )
  }

  render() {
    const { data, chartKey, isCountRequired } = this.props
    if (!data) {
      return <div />
    }
    return (
      <div className="stock-chart-container">
        {this.renderLoading()}
        <div>
          {this.props.showStatusButton && this.renderEnableButton()}
          {(!this.props.showStatusButton || this.props.drillDownBy) && this.renderDataRefreshButtons()}
        </div>
        <ZoomResetButton onClick={this.resetZoom} />
        <ReactHighstock config={this.buildConfig(chartKey, data.currentSeries, data.lastSeries, isCountRequired)} />
      </div>
    )
  }
}

ChartComponent.propTypes = {
  chartView: PropTypes.string,
  splitBy: PropTypes.string,
  drillDownBy: PropTypes.object,
  data: PropTypes.object,
  chartKey: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
  isCountRequired: PropTypes.bool,
  onSelectedTimeRangeChanged: PropTypes.func,
  backgroundColor: PropTypes.string,
  loading: PropTypes.bool,
  className: PropTypes.string,
  showStatusButton: PropTypes.bool,
  enabled: PropTypes.bool,
  onChangeEnabledStatus: PropTypes.func,
  refreshQCGraphData: PropTypes.func
}

ChartComponent.defaultProps = {
  height: 250,
  isCountRequired: false,
  backgroundColor: 'transparent'
}

export default ChartComponent
