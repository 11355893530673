import React from 'react'
import PropTypes from 'prop-types'

const QueryInfo = ({ groupName = null, queryName = null }) => {
  return (
    <div className="text-left">
      <div>Group: {groupName ?? <i>not set</i>}</div>
      <div>Query: {queryName ?? <i>not set</i>}</div>
    </div>
  )
}

QueryInfo.displayName = 'TooltipQuery'

QueryInfo.propTypes = {
  groupName: PropTypes.string,
  queryName: PropTypes.string
}

export default QueryInfo
