import React from 'react'
import { useSelector } from 'react-redux'

import TabNav, { useBasePath } from 'components/TabNav'
import Unauthorized from 'components/Unauthorized'
import UserService from 'services/user-service'
import { permissions } from 'constants/constants'
import CategoryGuideline from './CategoryGuideline'
import Requests from './Requests'
import WithErrorBoundaryWrapper from 'components/WithErrorBoundaryWrapper/WithErrorBoundaryWrapper'

import './qcSupport.scss'

const QCSupportPage = () => {
  const TAB_PATH = {
    REQUESTS: 'requests',
    CATEGORY_GUIDELINE: 'category-guideline'
  }
  const basePath = useBasePath(Object.values(TAB_PATH))

  const user = useSelector(state => state.session.user)
  const hasTaxonomyAccess = UserService.hasPermission(user, permissions.ropsTaxonomyView)
  const hasEditAccess = UserService.hasPermission(user, permissions.ropsTaxonomyEdit)

  if (!hasTaxonomyAccess) return <Unauthorized />

  return (
    <div className="qc-support-page">
      <TabNav>
        <TabNav.Item
          path={basePath + TAB_PATH.REQUESTS}
          label="Requests"
          component={<Requests hasEditAccess={hasEditAccess} />}
          default
        />
        <TabNav.Item
          path={basePath + TAB_PATH.CATEGORY_GUIDELINE}
          label="Category Guideline"
          component={<CategoryGuideline hasEditAccess={hasEditAccess} />}
        />
      </TabNav>
    </div>
  )
}

export default WithErrorBoundaryWrapper(QCSupportPage, '"CME QC Support" page')
