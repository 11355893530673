import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { getItemAttributesId, getItemAttributesIndexedById } from '../../../selectors/item-attribute-selector'

import Loader from '../../Loader'
import CheckBoxMultiSelect from '../../CheckBoxMultiSelect'

import { fetchItemAttributes } from '../../../actions/item-attribute-actions'

import './index.scss'

class ItemAttributesInput extends Component {
  static propTypes = {
    fetchItemAttributes: PropTypes.func,
    itemAttributesIndexedById: PropTypes.object,
    itemAttributesId: PropTypes.array,
    input: PropTypes.object,
    isLoading: PropTypes.bool
  }

  componentDidMount() {
    this.props.fetchItemAttributes()
  }

  render() {
    return (
      <div className="CategoriesInput__container ItemAttributesInput__container FormSelect__container">
        {this.props.isLoading && <Loader overlap />}

        <label>Item Attributes</label>

        <CheckBoxMultiSelect
          canBeHidden
          isImportEnable
          isHiddenByDefault
          keys={this.props.itemAttributesId}
          options={this.props.itemAttributesIndexedById}
          columnCount={2}
          valueDidChange={value => {
            this.props.input.onChange(value)
          }}
          data={this.props.input.value === '' ? {} : this.props.input.value}
          entityNames={{ singular: 'item', plural: 'items' }}
          importTitle={'Import item attributes list'}
        />
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    isLoading: state.itemAttributes.isLoading,
    itemAttributesIndexedById: getItemAttributesIndexedById(state),
    itemAttributesId: getItemAttributesId(state)
  }
}

export default connect(mapStateToProps, { fetchItemAttributes })(ItemAttributesInput)
