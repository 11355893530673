import { uniq } from 'lodash'
import { createSelector } from '@reduxjs/toolkit'

const feedModulesSelector = state => (state.feedModules && state.feedModules.data && state.feedModules.data.rows) || []

const editedFeedModuleSelector = state => (state.feedModules && state.feedModules.editedFeedModule) || {}

const feedModuleQueriesSelector = state => (state.feedModules && state.feedModules.queries) || []

const feedModuleQueriesLoadingSelector = state => state.feedModules.queryLoading

export const getFeedModulesForFeedPage = createSelector(feedModulesSelector, feedModules => {
  return feedModules.map(feedModule => {
    const { id, name, additionalUIFilter } = feedModule

    const frequencies = []

    if (feedModule.dailyConfig) {
      frequencies.push({ value: 'daily', label: 'Daily' })
    }

    if (feedModule.weeklyConfig) {
      frequencies.push({ value: 'weekly', label: 'Weekly' })
    }

    if (feedModule.monthlyConfig) {
      frequencies.push({ value: 'monthly', label: 'Monthly' })
    }

    return {
      id,
      name,
      additionalUIFilter,
      hasFirehoseConfig: !!feedModule.firehoseConfig,
      frequencies
    }
  })
})

export const getAdditionalUIFilters = createSelector(feedModulesSelector, feedModules => {
  const allAdditionalUIFilters = feedModules.map(feedModule => feedModule.additionalUIFilter)
  return uniq(allAdditionalUIFilters)
})

const getFeedModuleQueriesNames = createSelector(feedModuleQueriesSelector, feedModuleQueries => {
  return feedModuleQueries.map(query => query.name)
})

/**
 * Return hash:
 * {[configName]: [queryFileName]}
 */
const getQueryFilesNamesSelector = createSelector(editedFeedModuleSelector, editedFeedModule => {
  const configs = {}

  const checkJsonConfig = configNameField => {
    try {
      const configAsJson = JSON.parse(editedFeedModule[configNameField])
      const queryFileName = configAsJson.queryFile
      configs[configNameField] = queryFileName
    } catch (e) {
      // do nothing, it's possible
    }
  }

  checkJsonConfig('firehoseConfig')
  checkJsonConfig('monthlyConfig')
  checkJsonConfig('weeklyConfig')
  checkJsonConfig('dailyConfig')

  return configs
})

export const getMissedQueryFilesAlerts = createSelector(
  getQueryFilesNamesSelector,
  getFeedModuleQueriesNames,
  feedModuleQueriesLoadingSelector,
  (queryFilesByConfName, queryNames, queryLoading) => {
    const alerts = {}

    // not necessary check while queries aren't loaded yet
    if (queryLoading) return alerts

    for (const confName in queryFilesByConfName) {
      const queryFileName = queryFilesByConfName[confName]

      if (!queryNames.includes(queryFileName)) {
        alerts[confName] = queryFileName
      }
    }

    return alerts
  }
)

export const getMissedSqlNames = createSelector(
  getQueryFilesNamesSelector,
  getFeedModuleQueriesNames,
  (queryFilesByConfName, queryNames) => {
    const existingQueryFileNames = Object.values(queryFilesByConfName)
    return queryNames.filter(x => !existingQueryFileNames.includes(x))
  }
)
