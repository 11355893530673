import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button, Glyphicon, OverlayTrigger, Tooltip, Tabs, Tab } from 'react-bootstrap'
import { connect } from 'react-redux'

import { default as WidgetWrapper } from './widgets/WidgetWrapper'
import { default as QCWrapper } from './QCWrapper'
import { onSearchFilterChanged } from '../../actions/widget-actions'
import constants, { ES_SCORECARD, searchToolTabs } from '../../constants/constants'
import moment from 'moment'
import './resizable-widget.scss'
import OverTimeWidget from './widgets/OverTimeWidget'
import Metrics from './widgets/Metrics/Metrics'
import './search-tab.scss'
import { joinRoom, leaveRoom, DICT_EDIT_ROOM } from '../../socket'

import { toggleQCMode, toggleLayoutLock, setSelectedTab } from '../../actions/search-actions'

const {
  aggregations: { TIME }
} = constants
const { EDIT_MODE, QC_MODE, TRENDS, METRICS } = searchToolTabs

const IndexLabel = props => {
  switch (props.dataIndex) {
    case ES_SCORECARD.nonScorecard:
      return <div className="pull-right index-label index-label-nonscorecard">Non-Scorecard Data</div>
    case ES_SCORECARD.scorecard:
      return <div className="pull-right index-label index-label-scorecard">Scorecard Data</div>
    default:
      return null
  }
}

IndexLabel.propTypes = {
  dataIndex: PropTypes.string
}

class Content extends Component {
  componentDidMount() {
    joinRoom(DICT_EDIT_ROOM)
  }

  componentWillUnmount() {
    leaveRoom(DICT_EDIT_ROOM)
  }

  handleDateSelection = range => {
    this.props.onSearchFilterChanged('range', range)
  }

  render() {
    const tooltip = name => <Tooltip id="edit-layout-tooltip">{name}</Tooltip>
    return (
      <div id="search-qc-wrapper">
        <Tabs
          onSelect={this.props.setSelectedTab}
          id="search-tabs"
          className="tab-label portal-tabs"
          activeKey={this.props.isActiveTab}
        >
          <Tab eventKey={EDIT_MODE} title={EDIT_MODE} unmountOnExit>
            <OverlayTrigger
              placement="left"
              delayShow={1000}
              overlay={tooltip(this.props.isLayoutLocked ? 'Edit Layout' : 'Lock Layout')}
            >
              <>
                <IndexLabel dataIndex={this.props.dataIndex} />
                <Button
                  bsStyle="default"
                  active={!this.props.isLayoutLocked}
                  className="pull-right edit-nlayout"
                  onClick={event => {
                    event.preventDefault()
                    this.props.toggleLayoutLock()
                  }}
                >
                  <Glyphicon glyph="edit" />
                </Button>
              </>
            </OverlayTrigger>
            <div style={{ position: 'relative', zIndex: 5 }}>
              <WidgetWrapper
                isLayoutLocked={this.props.isLayoutLocked}
                handleDateSelection={this.handleDateSelection}
                rangeStart={this.props.filters.min_range}
                rangeEnd={this.props.filters.max_range}
                from={this.props.filters.from}
                to={this.props.filters.to}
                lastLoadDate={this.props.lastLoadDate}
                dataIndex={this.props.dataIndex}
              />
            </div>
          </Tab>
          <Tab eventKey={QC_MODE} title={QC_MODE}>
            <IndexLabel dataIndex={this.props.dataIndex} />
            <QCWrapper />
          </Tab>
          <Tab eventKey={METRICS} title={METRICS} unmountOnExit>
            <IndexLabel dataIndex={this.props.dataIndex} />
            <div key="metrics-widget">
              <Metrics />
            </div>
          </Tab>
          <Tab eventKey={TRENDS} title={TRENDS} unmountOnExit>
            <IndexLabel dataIndex={this.props.dataIndex} />
            <div key="overtime-widget">
              <OverTimeWidget
                height={450}
                aggType={TIME}
                handleDateSelection={this.handleDateSelection}
                rangeStart={this.props.filters.min_range}
                rangeEnd={this.props.filters.max_range}
                from={this.props.filters.from}
                to={this.props.filters.to}
              />
            </div>
          </Tab>
        </Tabs>
      </div>
    )
  }
}

Content.propTypes = {
  isLayoutLocked: PropTypes.bool,
  toggleQCMode: PropTypes.func.isRequired,
  setSelectedTab: PropTypes.func.isRequired,
  filters: PropTypes.object,
  onSearchFilterChanged: PropTypes.func.isRequired,
  toggleLayoutLock: PropTypes.func.isRequired,
  isActiveTab: PropTypes.string,
  lastLoadDate: PropTypes.object,
  dataIndex: PropTypes.string
}

function mapStateToProps(state) {
  return {
    isTreeVisible: state.search.isTreeVisible,
    isLayoutLocked: state.search.isLayoutLocked,
    isActiveTab: state.search.isActiveTab,
    filters: {
      ...state.search.searchFilters,
      min_range: state.search.searchFilters.min_range
        ? state.search.searchFilters.min_range
        : moment('1', 'X')
            .utc()
            .format(constants.dateFormat.qcTool),
      max_range: state.search.searchFilters.max_range,
      from: state.search.searchFilters.from,
      to: state.search.searchFilters.to
    },
    lastLoadDate: state.search.lastLoadDate,
    dataIndex: state.searchQC.dataIndex
  }
}

export default connect(mapStateToProps, {
  toggleQCMode,
  onSearchFilterChanged,
  toggleLayoutLock,
  setSelectedTab
})(Content)
