import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Checkmark from '../../../Icon/DoneCheckMark'
import Cross from '../../../Icon/Cross'
import { Panel, PanelGroup } from 'react-bootstrap'

import { findIndex } from 'lodash'
import cx from 'classnames'
import './index.scss'

/**
 * Component to render a StepPanel within a StepPanelGroup
 * @export
 * @class StepPanel
 * @extends {Component}
 */
export class StepPanel extends Component {
  handleOnToggle = (...args) => {
    const { onToggle, disabled } = this.props
    if (!disabled && onToggle) {
      onToggle(...args)
    }
  }

  render() {
    const {
      stepKey,
      title,
      step,
      collapsible,
      children,
      expanded,
      isComplete,
      className,
      editable,
      disabled,
      headerSuffix,
      failed
    } = this.props
    return (
      <Panel
        ref={el => {
          this.panel = el
        }}
        eventKey={stepKey}
        onToggle={this.handleOnToggle}
        className={cx('step-panel', className, {
          active: expanded,
          complete: isComplete,
          pending: isComplete === false,
          failed,
          editable,
          disabled
        })}
        expanded={expanded}
      >
        <Panel.Heading>
          <Panel.Title toggle>
            <div className="step-icon">
              {failed ? (
                <Cross />
              ) : isComplete === null ? (
                '- -'
              ) : typeof step !== 'object' && isComplete && !expanded ? (
                <Checkmark />
              ) : (
                step
              )}
            </div>
            <div className="step-panel-header">{title}</div>
          </Panel.Title>
          {headerSuffix !== undefined && <div className="header-suffix">{headerSuffix}</div>}
        </Panel.Heading>
        <Panel.Body collapsible={collapsible}>{children}</Panel.Body>
      </Panel>
    )
  }
}

StepPanel.defaultProps = {
  collapsible: true
}

StepPanel.propTypes = {
  stepKey: PropTypes.string.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  headerSuffix: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  step: PropTypes.oneOfType([PropTypes.number, PropTypes.node]),
  collapsible: PropTypes.bool,
  expanded: PropTypes.bool,
  isComplete: PropTypes.bool,
  failed: PropTypes.bool,
  editable: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.any,
  onToggle: PropTypes.func
}

/**
 * Component to render a Summary component adjacent to other StepPanels
 * @export
 * @class StepPanelSummary
 * @extends {Component}
 */
export class StepPanelSummary extends Component {
  render() {
    const { label, actions = [], className, isComplete } = this.props
    return (
      <Panel
        eventKey="summary"
        className={cx('step-panel step-panel-summary', className, {
          complete: isComplete,
          pending: !isComplete
        })}
      >
        <Panel.Heading>
          <div className="step-summary-label">{label}</div>
          {actions.length > 0 && <div className="step-summary-action-bar">{actions}</div>}
        </Panel.Heading>
      </Panel>
    )
  }
}

StepPanelSummary.propTypes = {
  label: PropTypes.string.isRequired,
  actions: PropTypes.arrayOf(PropTypes.node),
  className: PropTypes.string,
  isComplete: PropTypes.bool
}

/**
 * Componnent to render a group of StepPanels
 *
 * @export
 * @class StepPanelGroup
 * @extends {Component}
 */
export class StepPanelGroup extends Component {
  constructor(props) {
    super(props)
    this.state = {
      // initial expanded states for each child StepPanels
      expanded: { ...props.defaultExpanded }
    }
  }

  // creates an on toggle handler to update expanded state,
  // and call original onToggle handler if it exists
  onToggleFactory = (panelKey, onToggle) => () => {
    const nextValue = !this.state.expanded[panelKey]
    this.updatePanelExpandState(panelKey, nextValue)
    if (onToggle && typeof onToggle === 'function') {
      onToggle(nextValue)
    }
  }

  // method to update expand state of panelKey with the value provided
  updatePanelExpandState = (panelKey, nextExpandedValue) => {
    let expanded = {}
    const { accordian } = this.props
    const expandedFromState = this.state.expanded
    if (accordian) {
      Object.keys(expandedFromState).forEach(key => {
        expanded[key] = false
      })
      if (nextExpandedValue) {
        expanded[panelKey] = true
      }
    } else {
      expanded = {
        ...expandedFromState,
        [panelKey]: nextExpandedValue
      }
    }
    this.setState({ expanded })
  }

  openNextStep = panelKey => {
    const nextStepKey = this.findNextStep(panelKey)
    if (nextStepKey) {
      this.updatePanelExpandState(nextStepKey, true)
    } else {
      this.updatePanelExpandState(panelKey, false)
    }
  }

  findNextStep = panelKey => {
    const stepKeys = []
    React.Children.forEach(this.props.children, child => {
      if (this.isStepPanel(child)) {
        stepKeys.push(child.props.stepKey)
      }
    })
    const currIndex = findIndex(stepKeys, key => key === panelKey)
    if (currIndex !== stepKeys.length - 1) {
      return stepKeys[currIndex + 1]
    }
    return null
  }

  isStepPanel = node => node && node.type === StepPanel

  render() {
    const { id, className, children } = this.props
    const { expanded } = this.state
    return (
      <PanelGroup id={id} className={cx('step-panel-group', className)}>
        {children &&
          React.Children.map(children, child => {
            // if child is a StepPanel, clone it with additional props
            if (this.isStepPanel(child)) {
              const { stepKey, onToggle, disabled } = child.props
              return React.cloneElement(child, {
                expanded: disabled ? false : expanded[stepKey], // current expanded status
                onToggle: this.onToggleFactory(stepKey, onToggle) // overriden onToggleHandler
              })
            }
            return child
          })}
      </PanelGroup>
    )
  }
}

StepPanelGroup.defaultProps = {
  defaultExpanded: {}
}

StepPanelGroup.propTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  defaultExpanded: PropTypes.object,
  onActiveKeyChange: PropTypes.func,
  children: PropTypes.element,
  accordian: PropTypes.bool
}
