import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { Button } from 'react-bootstrap'

import TaxonomyCreateModal from '../TaxonomyCreateModal'

import { addCustomModal } from '../../../../actions/modal-actions'

import './index.scss'

class TaxonomyToolbar extends Component {
  openCreateModal = () => {
    const { toolbarType, addCustomModal, createFunction, fetchSuggestions } = this.props

    addCustomModal({
      modalType: 'successModal',
      id: 'create-new-modal',
      title: `Create new ${toolbarType}`,
      size: 'large',
      content: (
        <TaxonomyCreateModal
          toolbarType={toolbarType}
          createFunction={createFunction}
          fetchSuggestions={fetchSuggestions}
        />
      )
    })
  }

  handleDownload = () => {
    this.props.downloadFunction()
  }

  render() {
    const { toolbarType, hasEditAccess } = this.props

    return (
      <div className="taxonomy-toolbar">
        <div className="taxonomy-toolbar-buttons">
          {hasEditAccess && (
            <Button bsStyle="info" onClick={this.openCreateModal}>
              {`Create ${toolbarType}`}
            </Button>
          )}

          <Button bsStyle="default" onClick={this.handleDownload}>
            Download CSV
          </Button>
        </div>
      </div>
    )
  }
}

TaxonomyToolbar.propTypes = {
  hasEditAccess: PropTypes.bool.isRequired,
  toolbarType: PropTypes.string.isRequired,
  createFunction: PropTypes.func,
  addCustomModal: PropTypes.func.isRequired,
  fetchSuggestions: PropTypes.func,
  downloadFunction: PropTypes.func.isRequired
}

TaxonomyToolbar.defaultProps = {
  createFunction: () => {},
  fetchSuggestions: () => {}
}

export default connect(null, { addCustomModal })(TaxonomyToolbar)
