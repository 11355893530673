import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { isEmpty, find } from 'lodash'
import cx from 'classnames'

import { Row, Col, ButtonToolbar, Button, OverlayTrigger, Tooltip, Glyphicon } from 'react-bootstrap'
import SearchTypeahead from 'components/SearchTypeahead'
import { default as Export } from '../../../../export/ExportContainer'
import EditReviewModal from './EditReviewModal'
import { confirmModal } from 'actions/modal-actions'

class DescriptionToolbar extends Component {
  shouldShowActionButtons = () => {
    const { stagedEdits, data, inEdit } = this.props
    if (!inEdit && !isEmpty(stagedEdits)) {
      for (const key in stagedEdits) {
        if (stagedEdits.hasOwnProperty(key)) {
          if (find(data, { dictionary_key: key })) {
            return true
          }
        }
      }
    }
    return false
  }

  render() {
    const tooltip = name => <Tooltip id="description-toolbar-tooltip">{name}</Tooltip>
    const {
      canEdit,
      inEdit,
      onToggleDescriptionEdit,
      edits,
      onBrandSuggest,
      onBrandSelect,
      data,
      totalDescriptionsCount,
      onChangeStatus,
      tableStatus,
      onCategorySuggest,
      onCategorySelect,
      onBulkStageDictionaryEdits,
      onBulkUnstageDictionaryEdits,
      onSelectAll,
      stagedEdits,
      onSaveDictionaryEntries,
      showSearchIcon,
      toggleSearchStatus,
      refreshDescriptionData,
      onExport,
      toolbarOptionActive,
      onToggleToolbarOption
      // descriptionEditSuggestions
    } = this.props
    return (
      <div>
        <Row className="show-grid">
          <Col xs={12} md={8} className="description-toolbar-container">
            {canEdit && (
              <OverlayTrigger delayShow={1000} placement="top" overlay={tooltip('Bulk Edit')}>
                <Button
                  bsSize="xsmall"
                  bsStyle="default"
                  className="pull-left action-button"
                  active={inEdit}
                  onClick={evnt => {
                    evnt.preventDefault()
                    onToggleDescriptionEdit()
                  }}
                >
                  <Glyphicon glyph="edit" />
                </Button>
              </OverlayTrigger>
            )}
            <div className="pull-left bulk-edit-brand description-toolbar-container">
              {(inEdit && edits.rows.length > 0 && (
                <React.Fragment>
                  <h6 className="select-text" onClick={() => onSelectAll()}>
                    Select All
                  </h6>
                  <SearchTypeahead
                    placeholder="Enter Brand"
                    suggestions={edits.brandSuggestions}
                    onInputChange={onBrandSuggest}
                    clearOnSelect={false}
                    onChange={onBrandSelect}
                    disabled={false}
                    size={'small'}
                  />
                  &nbsp;
                  <SearchTypeahead
                    placeholder="Enter Category"
                    suggestions={edits.categorySuggestions}
                    onInputChange={onCategorySuggest}
                    onChange={onCategorySelect}
                    clearOnSelect={false}
                    disabled={false}
                    size={'small'}
                  />
                </React.Fragment>
              )) || (
                <>
                  {inEdit && (
                    <h6 className="select-text" onClick={() => onSelectAll()}>
                      Select All
                    </h6>
                  )}
                  <h5 className="table-title">
                    Top {data?.length || 0} of {totalDescriptionsCount} Descriptions
                  </h5>
                </>
              )}
            </div>
            {!inEdit && (
              <div
                onClick={onChangeStatus}
                className={cx(
                  'pull-left search-desc-status search-qc-statusButton',
                  tableStatus ? 'active' : 'inactive'
                )}
              >
                <span className={'statusMarker'} />
              </div>
            )}
          </Col>
          <Col xs={6} md={4}>
            {
              <ButtonToolbar bsSize="xsmall" bsClass="pull-right" className="description-toolbar-container">
                {inEdit && edits.rows && edits.rows.length > 0 && (
                  <ButtonToolbar className="description-toolbar-container">
                    <Button
                      bsSize="xsmall"
                      bsStyle="default"
                      className="description-toolbar-button"
                      onClick={evnt => {
                        evnt.preventDefault()
                        if (edits.rows.length > 0) {
                          onBulkStageDictionaryEdits()
                        }
                      }}
                    >
                      {!edits.brandName && !edits.categoryFullPath ? 'Apply Existing' : 'Apply Selected'}
                    </Button>
                    {/* The button was hided in ticket IN-1880 */}
                    {/* <Button
                      bsSize="xsmall"
                      bsStyle="success"
                      className="description-toolbar-button"
                      onClick={evnt => {
                        evnt.preventDefault()
                        if (edits.rows.length > 0) {
                          descriptionEditSuggestions()
                        }
                      }}
                    >
                      {'Brandize'}
                    </Button> */}
                  </ButtonToolbar>
                )}
                {this.shouldShowActionButtons() && !this.props.loading && (
                  <ButtonToolbar className="description-toolbar-container">
                    <OverlayTrigger delayShow={1000} placement="top" overlay={tooltip('Clear Edits')}>
                      <Button
                        bsSize="xsmall"
                        bsStyle="default"
                        className="description-toolbar-button"
                        onClick={evnt => {
                          evnt.preventDefault()
                          onBulkUnstageDictionaryEdits()
                        }}
                      >
                        Clear
                      </Button>
                    </OverlayTrigger>
                    <OverlayTrigger delayShow={1000} placement="top" overlay={tooltip('Save Edits')}>
                      <Button
                        bsSize="xsmall"
                        bsStyle="danger"
                        className="description-toolbar-button"
                        onClick={evnt => {
                          evnt.preventDefault()
                          this.props.confirmModal(
                            <EditReviewModal allRows={data} stagedEdits={stagedEdits} />,
                            'Confirm Save',
                            { okButton: 'Save' },
                            onSaveDictionaryEntries,
                            'large',
                            'edit-review'
                          )
                        }}
                      >
                        Check &amp; Save
                      </Button>
                    </OverlayTrigger>
                  </ButtonToolbar>
                )}
                {!inEdit && (
                  <React.Fragment>
                    {
                      <OverlayTrigger
                        delayShow={1000}
                        placement="top"
                        overlay={tooltip(`${showSearchIcon ? 'Hide Column Filters' : 'Show Column Filters'}`)}
                      >
                        <Button
                          bsSize="xsmall"
                          bsStyle="default"
                          className="action-button"
                          onClick={() => {
                            toggleSearchStatus()
                          }}
                        >
                          <Glyphicon glyph={'filter'} />
                        </Button>
                      </OverlayTrigger>
                    }
                    {
                      <OverlayTrigger delayShow={1000} placement="top" overlay={tooltip('Refresh')}>
                        <Button
                          bsSize="xsmall"
                          bsStyle="default"
                          className="action-button"
                          onClick={() => {
                            refreshDescriptionData()
                          }}
                        >
                          <Glyphicon glyph="refresh" />
                        </Button>
                      </OverlayTrigger>
                    }
                    <Export onExport={onExport} />
                    <OverlayTrigger delayShow={1000} placement="top" overlay={tooltip('Table Options')}>
                      <Button
                        bsSize="xsmall"
                        bsStyle="default"
                        className="action-button"
                        active={toolbarOptionActive}
                        onClick={evnt => {
                          evnt.preventDefault()
                          onToggleToolbarOption()
                        }}
                      >
                        <Glyphicon glyph="wrench" />
                      </Button>
                    </OverlayTrigger>
                  </React.Fragment>
                )}
              </ButtonToolbar>
            }
          </Col>
        </Row>
      </div>
    )
  }
}

DescriptionToolbar.propTypes = {
  loading: PropTypes.bool,
  totalDescriptionsCount: PropTypes.number,
  onBrandSuggest: PropTypes.func,
  onCategorySuggest: PropTypes.func,
  onBrandSelect: PropTypes.func,
  onCategorySelect: PropTypes.func,
  onSaveDictionaryEntries: PropTypes.func,
  onBulkStageDictionaryEdits: PropTypes.func,
  onBulkUnstageDictionaryEdits: PropTypes.func,
  onToggleDescriptionEdit: PropTypes.func,
  onExport: PropTypes.func,
  inEdit: PropTypes.bool,
  stagedEdits: PropTypes.object,
  edits: PropTypes.object,
  selectedSize: PropTypes.number,
  onUpdateAggregation: PropTypes.func,
  data: PropTypes.arrayOf(PropTypes.object),
  onToggleToolbarOption: PropTypes.func,
  toolBarConfig: PropTypes.bool,
  toolbarOptionActive: PropTypes.bool,
  canEdit: PropTypes.bool,
  tableStatus: PropTypes.bool,
  onSelectAll: PropTypes.func,
  onChangeStatus: PropTypes.func,
  refreshDescriptionData: PropTypes.func,
  toggleSearchStatus: PropTypes.func,
  showSearchIcon: PropTypes.bool,
  confirmModal: PropTypes.func
  // descriptionEditSuggestions: PropTypes.func
}

export default connect(null, { confirmModal })(DescriptionToolbar)
