import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import { useForm } from 'react-hook-form'
import joi from 'joi'
import { joiResolver } from '@hookform/resolvers/joi'

import { validEmailTypes, validSampleTypes, supraQCToolDefaults } from 'constants/constants.js'
import { closeGenerateSampleModal } from '../../slices'
import { generateNewSampleForSupraQC } from '../../actions'
import Loader from 'components/Loader'
import DomainInput from './DomainInput'
import SampleInput from './SampleInput'
import DateRangeInput from './DateRangeInput'
import TypeInput from './TypeInput'
import MaxTemplatesInput from './MaxTemplatesInput'
import MinTrafficInput from './MinTrafficInput'
import MaxQuantileInput from './MaxQuantileInput'
import FilterKeywordsInput from './FilterKeywordsInput'
import UseBronzeInput from './UseBronzeInput'
import SkipTrafficCheckInput from './SkipTrafficCheckInput'
import { formatForInputSelect } from '../../utils'

const { DATE_FORMAT, SAMPLING_QC_START_DATE } = supraQCToolDefaults
const VALIDATION_DOMAIN_REGEX = /^[A-Za-z0-9.'/-]+$/i

const GenerateNewSampleModal = () => {
  const { loading, defaultValue } = useSelector(state => state.supraQCToolV2.generateSampleModal)
  const dispatch = useDispatch()
  const validationSchema = joi.object({
    sample: joi
      .string()
      .trim()
      .required(),
    domain: joi
      .string()
      .ruleset.regex(VALIDATION_DOMAIN_REGEX)
      .rule({ message: "Allowed English letters, numbers and symbols (.'-)" })
      .trim()
      .label('Domain')
      .required(),
    dateRange: joi
      .object({
        timeStart: joi
          .date()
          .label('Date Start')
          .required(),
        timeEnd: joi
          .date()
          .label('Date End')
          .required()
      })
      .required(),
    types: joi
      .array()
      .min(1)
      .label('Type')
      .required(),
    maxTemplates: joi
      .number()
      .min(0)
      .max(100000)
      .label('Max Templates')
      .required(),
    minTraffic: joi
      .number()
      .precision(1)
      .min(0)
      .max(100)
      .label('Min Traffic')
      .required(),
    maxQuantile: joi
      .number()
      .precision(1)
      .min(0)
      .max(100)
      .label('Max Quantile')
      .required(),
    filterKeywords: joi
      .string()
      .trim()
      .allow('')
      .label('Filter Keywords'),
    useBronze: joi
      .boolean()
      .default(false)
      .label('Use Bronze'),
    skipTrafficCheck: joi
      .boolean()
      .default(false)
      .label('Skip Traffic Check')
  })

  const {
    filter_start_date,
    filter_end_date,
    filter_supra_type,
    filter_target_domain,
    max_template_cnt,
    template_traffic_min,
    template_traffic_percentile_max
  } = defaultValue
  const {
    control,
    handleSubmit,
    formState: { isDirty, errors },
    reset
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      sample: validSampleTypes[0],
      domain: filter_target_domain || '',
      dateRange: {
        timeStart: filter_start_date || SAMPLING_QC_START_DATE,
        timeEnd: filter_end_date || moment().format('YYYY-MM-DD')
      },
      types: formatForInputSelect(filter_supra_type),
      maxTemplates: max_template_cnt || 100,
      minTraffic: template_traffic_min || '0.0',
      maxQuantile: template_traffic_percentile_max || '100',
      filterKeywords: '',
      useBronze: false
    },
    resolver: joiResolver(validationSchema)
  })

  const onCloseWindow = () => {
    dispatch(closeGenerateSampleModal())
  }

  const onSubmit = data => {
    dispatch(
      generateNewSampleForSupraQC({
        sample: data.sample,
        domain: data.domain,
        maxTemplates: data.maxTemplates,
        minTraffic: data.minTraffic,
        maxQuantile: data.maxQuantile,
        filterKeywords: data.filterKeywords,
        useBronze: data.useBronze,
        skipTrafficCheck: data.skipTrafficCheck,
        // Converting data to correct format
        timeStart: moment(data.dateRange.timeStart).format(DATE_FORMAT),
        timeEnd: moment(data.dateRange.timeEnd).format(DATE_FORMAT),
        types: data.types.map(t => t.value)
      })
    )
  }

  return (
    <Modal show onHide={onCloseWindow} bsSize="lg">
      <Modal.Header>
        <Modal.Title>Generate Supra Sample</Modal.Title>
      </Modal.Header>
      {loading ? (
        <Loader />
      ) : (
        <Modal.Body>
          <form className="mt-20 d-flex flex-column" onSubmit={handleSubmit(onSubmit)}>
            {
              // NEED to keep info props here because when we use info in inputs, ups Tooltips <OverlayTrigger />, and test FAILED !!!
            }
            <SampleInput control={control} info="Select sample type from the dropdown." />
            <DomainInput control={control} errors={errors} info="Select mapped URL domain (merchant)." />
            <DateRangeInput control={control} errors={errors} info="Date range where we search emails." />
            <TypeInput
              control={control}
              errors={errors}
              options={formatForInputSelect(validEmailTypes)}
              info="Filter by selection type."
            />
            <MaxTemplatesInput
              control={control}
              errors={errors}
              info="The maximum number of templates to return in the result."
            />
            <MinTrafficInput
              control={control}
              errors={errors}
              info="Minimum percentage of traffic template represents."
            />
            <MaxQuantileInput
              control={control}
              errors={errors}
              info="Maximum traffic quantile that template represents."
            />
            <FilterKeywordsInput
              control={control}
              errors={errors}
              info="Keywords we want to filter, only templates matching the keyword rules would be left in the result"
            />

            <UseBronzeInput
              control={control}
              errors={errors}
              info="Read in data from bronze layer table (recent 2 days) + staging table of bronze-to-silver ETL job then combine with silver layer data"
            />

            <SkipTrafficCheckInput
              control={control}
              errors={errors}
              info="If checking the monitoring table (same table as in Supra Monitoring dashboard that is update daily around the finish of refining job), there is less than 100 or more than 300MM messages for the select domains, time range and supra categories, the job would stop immediately and return an error."
            />

            <div className="mt-4 d-flex justify-content-center">
              <Button className="mr-3" onClick={onCloseWindow}>
                Cancel
              </Button>
              <Button className="mr-3" onClick={() => reset()} disabled={!isDirty}>
                Reset
              </Button>
              <Button type="submit" bsStyle="primary" disabled={!isDirty && !filter_target_domain}>
                Generate Sample
              </Button>
            </div>
          </form>
        </Modal.Body>
      )}
    </Modal>
  )
}

export default GenerateNewSampleModal
