import React, { useEffect, useMemo, useState } from 'react'
import {
  Modal,
  Button,
  FormGroup,
  FormControl,
  OverlayTrigger,
  Tooltip,
  Form,
  Col,
  Tabs,
  Tab,
  HelpBlock,
  Checkbox
} from 'react-bootstrap'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import { useDispatch, useSelector } from 'react-redux'

import { closeRegexUtilityModal, addRuleToDictionary } from './../slices'
import { validEmailTypes, permissions as PERMISSIONS, supraQCToolDefaults } from 'constants/constants'

import RegexUtilityMatchingExamples from './RegexUtilityMatchingExamples'
import RegexUtilityMatchesAcrossSample from './RegexUtilityMatchesAcrossSample'
import useTemplateRegex from './useTemplateRegex'
import findRegexUtilityResults from './findRegexUtilityResults'
import Loader from 'components/Loader'
import InfoTooltipWhite from '../../Tooltips/InfoTooltipWhite'
import UserService from '../../../services/user-service'
import { countBy } from 'lodash'
import './../style.scss'

const UNALLOWABLE_TEMPLATE_REGEX = supraQCToolDefaults.UNALLOWABLE_TEMPLATE_REGEX

const overlayFormatter = (cell, row, placement = 'right') => {
  return (
    <OverlayTrigger placement={placement} delayShow={500} overlay={tooltip(cell)}>
      <span>{cell}</span>
    </OverlayTrigger>
  )
}

const tooltip = name => <Tooltip id="regex-utility-tooltip">{name}</Tooltip>

const getValidationState = regex => {
  return isValidJSRegex(regex) ? 'success' : 'error'
}

const getValidationStateForTemplate = template => {
  return isValidTemplate(template).result ? 'success' : 'error'
}

function isValidTemplate(value) {
  if (value.trim().length < 10) {
    return { result: false, error: 'Template cannot have less than 10 characters' }
  }

  const numberOfWildCards = countBy(value)['_']
  if (numberOfWildCards > 5) {
    return { result: false, error: 'Template cannot have more than 5 wildcards' }
  }

  const hasTwoConsecutiveWildcards = value.match(/_[\s]*_/)
  if (hasTwoConsecutiveWildcards) {
    return { result: false, error: 'Template cannot have two consecutive wildcards ' }
  }

  return { result: true }
}

function isValidJSRegex(value) {
  let isValid = true
  try {
    // eslint-disable-next-line no-unused-vars
    const test = new RegExp(value)
  } catch (e) {
    isValid = false
  }
  return isValid
}

const TABS = {
  MATCHING_EXAMPLES: 'MATCHING_EXAMPLES',
  NOT_MATCHING_EXAMPLES: 'NOT_MATCHING_EXAMPLES',
  MATCHING_ACROSS_SAMPLE: 'MATCHING_ACROSS_SAMPLE'
}

const RegexUtilityModal = () => {
  const dispatch = useDispatch()
  const { show, selectedRow, showAddToDictionaryLoader, matchesAcrossSample } = useSelector(
    state => state.supraQCToolV2.regexUtilityModal
  )
  const session = useSelector(state => state.session)

  const [activeTab, setActiveTab] = useState(TABS.MATCHING_EXAMPLES)
  const { template, regex, onTemplateChange } = useTemplateRegex(selectedRow.template)
  const [dictionaryLabel, setDictionaryLabel] = useState('')
  const [useInTraining, setUseInTraining] = useState(false)

  const userHasSupraQCEditPermission = useMemo(() => {
    return UserService.hasPermission(session.user, PERMISSIONS.supraQCToolEdit)
  }, [session])

  useEffect(() => {
    setDictionaryLabel(
      selectedRow?.bbx_supra_portal ? selectedRow.bbx_supra_portal.toUpperCase() : selectedRow?.bbx_supra?.toUpperCase()
    )
  }, [selectedRow])

  const closeModal = () => {
    dispatch(closeRegexUtilityModal())
    // Reset states to initial, becouse wnd hide not cloused
    setDictionaryLabel('')
    setUseInTraining(false)
    onTemplateChange(selectedRow.template)
  }

  const addToDictionary = () => {
    dispatch(
      addRuleToDictionary({
        subject: regex,
        template,
        useInTraining,
        emailType: dictionaryLabel,
        fromAddr: selectedRow.from_addr,
        domain: selectedRow.domain,
        sampleSubject: selectedRow.examples[0],
        originalTemplate: selectedRow.template
      })
    )
  }

  // eslint-disable-next-line no-unused-vars
  const isEnabledAddToDictionary = () => {
    if (selectedRow.template !== template) return true

    if (useInTraining) return true

    if (selectedRow.bbx_supra_portal) {
      if (selectedRow.bbx_supra_portal.toUpperCase() !== dictionaryLabel) {
        return true
      }
    } else {
      if (selectedRow.bbx_supra.toUpperCase() !== dictionaryLabel) {
        return true
      }
    }

    return false
  }

  const handleTemplateStringChange = event => {
    const template = event.target.value.replace(UNALLOWABLE_TEMPLATE_REGEX, '')
    onTemplateChange(template)
  }

  const handleDictionaryLabelChange = event => {
    setDictionaryLabel(event.target.value)
  }

  const handleUseInTrainingChange = event => {
    setUseInTraining(event.target.checked)
  }

  const { examplesMatch, examplesNotMatch, percentage, total } = useMemo(() => {
    return findRegexUtilityResults({
      examples: selectedRow?.examples,
      examples_msguuid: selectedRow?.examples_msguuid,
      regexString: regex
    })
  }, [selectedRow, regex])

  const dictLabelFormatter = (cell, row) => {
    return (
      <FormControl
        bsClass="form-control add-to-dict-form-control"
        componentClass="select"
        type="text"
        placeholder="Enter new label"
        defaultValue={dictionaryLabel}
        onChange={handleDictionaryLabelChange}
      >
        {validEmailTypes.map(emailType => {
          return (
            <option key={emailType} value={emailType}>
              {emailType}
            </option>
          )
        })}
      </FormControl>
    )
  }

  const useInTrainingFormatter = (cell, row) => {
    return <Checkbox className="text-center" onChange={handleUseInTrainingChange} checked={useInTraining} />
  }

  return (
    <Modal backdrop="static" show={show} onHide={closeModal} dialogClassName="regex-utility-modal">
      <Modal.Header>
        <Modal.Title>Regex Utility</Modal.Title>
      </Modal.Header>

      <Modal.Body className="scrollable-modal-body">
        <BootstrapTable data={[{ ...selectedRow, sequence: 1 }]} striped condensed bordered>
          <TableHeaderColumn dataField="sequence" hidden isKey>
            Sequence
          </TableHeaderColumn>

          <TableHeaderColumn dataFormat={overlayFormatter} tdStyle={{ whiteSpace: 'normal' }} dataField="template">
            Template
          </TableHeaderColumn>
          <TableHeaderColumn
            tdStyle={{ whiteSpace: 'normal' }}
            dataFormat={overlayFormatter}
            placement="right"
            dataField="from_addr"
          >
            From
          </TableHeaderColumn>
          <TableHeaderColumn dataField="bbx_supra">Original Type</TableHeaderColumn>

          <TableHeaderColumn tdStyle={{ whiteSpace: 'normal' }} dataField="dictLabel" dataFormat={dictLabelFormatter}>
            Defined Type
          </TableHeaderColumn>

          <TableHeaderColumn
            tdStyle={{ whiteSpace: 'normal' }}
            dataField="useInTrainingField"
            dataFormat={useInTrainingFormatter}
          >
            Use In Training
          </TableHeaderColumn>
        </BootstrapTable>

        <Form horizontal className="mt-3">
          <FormGroup validationState={getValidationStateForTemplate(template)}>
            <Col sm={2}>
              <div className="d-flex justify-content-center">
                <span>Template</span>
                <InfoTooltipWhite
                  trigger={['hover', 'focus']}
                  text="Allowed: English letters, numbers and special characters"
                />
              </div>
            </Col>
            <Col sm={10}>
              <FormControl
                bsClass="form-control add-to-dict-form-control"
                type="text"
                value={template}
                placeholder="Enter template string"
                onChange={handleTemplateStringChange}
              />
            </Col>
            <FormControl.Feedback />
            {!isValidTemplate(template).result && <HelpBlock>{isValidTemplate(template).error}</HelpBlock>}
          </FormGroup>

          <FormGroup validationState={getValidationState(regex)}>
            <Col sm={2}>Regex</Col>
            <Col sm={10}>
              <FormControl
                bsClass="form-control add-to-dict-form-control"
                type="text"
                label="Regex"
                value={regex}
                placeholder="Enter regex value"
                disabled
              />
            </Col>
            <FormControl.Feedback />
            {!isValidJSRegex(regex) && <HelpBlock>The string is not a valid JavaScript regular expression</HelpBlock>}
          </FormGroup>
        </Form>

        {isValidJSRegex(regex) && selectedRow?.examples && (
          <Tabs id="supra-qc-tabs" className="tab-label portal-tabs" onSelect={setActiveTab} activeKey={activeTab}>
            <Tab eventKey={TABS.MATCHING_EXAMPLES} title={`Matching Examples (${percentage}%)`}>
              <RegexUtilityMatchingExamples
                examples={examplesMatch}
                totalCount={total}
                subtitle="This tab shows the examples of the current template that match the generated regex. The percentage also considers only the examples of the current template"
              />
            </Tab>
            <Tab eventKey={TABS.NOT_MATCHING_EXAMPLES} title={`Not matching examples (${100 - percentage}%)`}>
              <RegexUtilityMatchingExamples
                examples={examplesNotMatch}
                totalCount={total}
                subtitle="This tab shows the examples of the current template that do not match the generated regex. The percentage also considers only the examples of the current template"
              />
            </Tab>
            <Tab
              eventKey={TABS.MATCHING_ACROSS_SAMPLE}
              title={`Matches across sample (${matchesAcrossSample?.matchPercentage}%)`}
            >
              <RegexUtilityMatchesAcrossSample regex={regex} template={template} />
            </Tab>
          </Tabs>
        )}

        <hr />
      </Modal.Body>

      <Modal.Footer>
        {showAddToDictionaryLoader ? (
          <Loader height={40} />
        ) : (
          <>
            <Button onClick={closeModal}>Close</Button>
            {userHasSupraQCEditPermission && (
              <Button bsStyle="primary" onClick={addToDictionary}>
                Add to Dictionary
              </Button>
            )}
          </>
        )}
      </Modal.Footer>
    </Modal>
  )
}

export default RegexUtilityModal
