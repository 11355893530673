import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

import { SingleDateInputField } from '../SingleDateInput'

import './index.scss'

const FormDatePicker = ({ label, isRequired, fieldName, fromDate, isEditable, tooltip }) => {
  return (
    <div className="FormDatePicker__container">
      {tooltip ? (
        <OverlayTrigger placement="left" overlay={<Tooltip id="continuous-delivery-tooltip">{tooltip}</Tooltip>}>
          <label className={`${isRequired ? 'required toggle_label' : 'toggle_label'}`}>{label}</label>
        </OverlayTrigger>
      ) : (
        <label className={`${isRequired ? 'required' : ''}`}>{label}</label>
      )}
      <Field name={fieldName} component={SingleDateInputField} minDate={fromDate} disabled={!isEditable} />
    </div>
  )
}

FormDatePicker.propTypes = {
  label: PropTypes.string.isRequired,
  isRequired: PropTypes.bool,
  fieldName: PropTypes.string.isRequired,
  fromDate: PropTypes.object,
  isEditable: PropTypes.bool,
  tooltip: PropTypes.string
}

FormDatePicker.defaultProps = {
  isRequired: false,
  isEditable: true
}

export default FormDatePicker
