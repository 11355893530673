import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { Button, Glyphicon, Badge } from 'react-bootstrap'
import { addCustomModal } from 'actions/modal-actions'

import ColumnsConfigModal from './ColumnsConfigModal'

import { useLocalStorage } from '@rehooks/local-storage'

import { getLocalStorageKey } from './utils'

const ColumnsConfigButton = ({ columns, tableId }) => {
  const dispatch = useDispatch()

  const openConfigColumnsModal = () => {
    dispatch(
      addCustomModal({
        modalType: 'successModal',
        id: 'edit-modal',
        title: `Configure columns visibility`,
        content: <ColumnsConfigModal columns={columns} tableId={tableId} />
      })
    )
  }

  const [columnsVisibility] = useLocalStorage(getLocalStorageKey(tableId), null)

  let hiddenColumns = 0
  if (columnsVisibility) {
    Object.entries(columnsVisibility).forEach(([key, val]) => {
      if (!val) hiddenColumns++
    })
  }

  return (
    <Button bsStyle="default" className="pull-right" onClick={openConfigColumnsModal}>
      <Glyphicon glyph="align-justify" className="padding-rigt-standard" />
      <span className="padding-rigt-standard">Columns</span>
      {hiddenColumns > 0 && <Badge title="Hidden Columns">{hiddenColumns}</Badge>}
    </Button>
  )
}

ColumnsConfigButton.propTypes = {
  columns: PropTypes.object.isRequired,
  tableId: PropTypes.PropTypes.string.isRequired
}

export default ColumnsConfigButton
