import React from 'react'
import PropTypes from 'prop-types'
import { OverlayTrigger, Tooltip, Badge } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'

import { simpleNumberFormatter } from '../../utils/formatters'

const renderTabButton = (title = '', badgeCount) => (
  <>
    {title} {badgeCount != null && <Badge>{simpleNumberFormatter(badgeCount)}</Badge>}
  </>
)

const DataFeedTabs = ({ deliveredTodayCount, location = {} }) => {
  const navLinkTabData = [
    {
      to: '/admin/feeds',
      key: 'feeds',
      title: 'Feeds',
      render: component => component
    },
    {
      to: '/admin/feeds/modules',
      key: 'modules',
      title: 'Feed Modules',
      render: component => component
    },
    {
      to: '/admin/feeds/deliveries',
      key: 'feed-deliveries',
      title: 'Feed Deliveries',
      badgeCount: deliveredTodayCount,
      // eslint-disable-next-line react/display-name
      render: component => (
        <OverlayTrigger
          key={'feed-deliveries'}
          rootClose
          placement="bottom"
          overlay={<Tooltip id={'checkbox_filter'}>{`${deliveredTodayCount} feed(s) were delivered today`}</Tooltip>}
          delayShow={500}
        >
          {component}
        </OverlayTrigger>
      )
    },
    {
      to: '/admin/feeds/deliveries-summary',
      key: 'feed-deliveries-summary',
      title: 'Feed Deliveries Summary',
      render: component => component
    },

    {
      to: '/admin/feeds/locks',
      key: 'feed-locks',
      title: 'Feed Group Locks',
      render: component => component
    },
    {
      to: '/admin/feeds/client-info',
      key: 'client-info',
      title: 'Client Feed Info',
      render: component => component
    },
    {
      to: '/admin/feeds/global-config',
      key: 'global-config',
      title: 'Global Feed Config',
      render: component => component
    }
  ]

  const getSelectedPage = pathname => {
    const tab = navLinkTabData.find(entry => entry.to === pathname)
    if (!tab) {
      if (pathname.startsWith('/admin/feeds/modules')) {
        return 'modules'
      } else {
        return 'feeds'
      }
    }
    return tab.key
  }

  const selectedPage = getSelectedPage(location.pathname)

  return (
    <div className="portal-tabs data-feed-tabs">
      <ul className="nav nav-tabs">
        {navLinkTabData.map(({ key, render, title, to, badgeCount }) =>
          render(
            <li role="presentation" key={key}>
              <NavLink
                key={key}
                to={to}
                className={'tab'}
                activeClassName="active"
                isActive={() => selectedPage === key}
              >
                {renderTabButton(title, badgeCount)}
              </NavLink>
            </li>
          )
        )}
      </ul>
    </div>
  )
}

DataFeedTabs.propTypes = {
  location: PropTypes.object,
  deliveredTodayCount: PropTypes.number
}

export default DataFeedTabs
