/* eslint-disable max-len */

/*!
 * deps
 */

import React from 'react'
import IconBase from './IconBase'

/**
 * CheckMark component
 */
const ChatBars = props => {
  return (
    <IconBase viewBox="0 0 30 30" {...props}>
      <path d="M17.5 23.7H13c-.3 0-.6-.3-.6-.6V5.6c0-.4.2-.6.6-.6h4.5c.3 0 .5.2.5.6v17.5c0 .3-.2.6-.5.6zm7.3 0h-4.5c-.3 0-.6-.3-.6-.6V10.2c0-.3.3-.6.6-.6h4.5c.3 0 .6.3.6.6v12.9c0 .3-.2.6-.6.6zm-14.8 0H5.5c-.3 0-.6-.3-.6-.6v-8.2c0-.3.3-.6.6-.6H10c.3 0 .6.3.6.6v8.2c0 .3-.3.6-.6.6z" />
    </IconBase>
  )
}

export default ChatBars
