const errorIsRequired = 'This field is required'

export default function validateForm(values) {
  const errors = {}

  if (values === undefined) {
    return errors
  }

  const { moduleId, GroupId, frequency } = values

  if (!moduleId || moduleId.length === 0) {
    errors.moduleId = errorIsRequired
  }
  if (!GroupId || GroupId.length === 0) {
    errors.GroupId = errorIsRequired
  }
  if (!frequency || frequency.length === 0) {
    errors.frequency = errorIsRequired
  }

  return errors
}
