const convertToNumberIfPossible = value => {
  let newValue = value

  const numberValue = parseInt(newValue, 10)
  if (!isNaN(numberValue)) {
    newValue = numberValue
  }

  return newValue
}

export default convertToNumberIfPossible
