import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import MerchantCategoryRegexRuleModal from './Modals/MerchantCategoryRegexRuleModal'
import CommonRuleActionsCell from './../Common/CommonRuleActionsCell'

import { MerchantCategoryRegexRulesActions } from './../actions'

class MerchantCategoryRegexActionsCell extends Component {
  deleteFunction = id => {
    this.props.saveRuleAction({ id, isActive: false })
  }

  activateFunction = id => {
    this.props.saveRuleAction({ id, isActive: true })
  }

  getEditModal = () => {
    const {
      id,
      merchantId,
      merchantName,
      categoryId,
      categoryFullPath,
      includeParam,
      excludeParam,
      ruleType,
      isExcludeRule
    } = this.props.row
    const { saveRuleAction } = this.props
    return (
      <MerchantCategoryRegexRuleModal
        value={{
          id,
          categoryId,
          categoryFullPath,
          merchantId,
          merchantName,
          includeParam,
          excludeParam,
          ruleType,
          isExcludeRule
        }}
        onOkHandler={saveRuleAction}
      />
    )
  }

  render() {
    const { id, isActive, merchantIsActive, categoryIsActive, ruleIsActive } = this.props.row
    let activationTooltip = ''
    let isActivationBlocked = false

    if (isActive) {
      activationTooltip = 'Delete rule'
    } else if (!merchantIsActive) {
      activationTooltip = "Can't reactivate because related Merchant is inactivate"
      isActivationBlocked = true
    } else if (!categoryIsActive) {
      activationTooltip = "Can't reactivate because related Category is inactivate"
      isActivationBlocked = true
    } else if (!ruleIsActive && merchantIsActive && categoryIsActive) {
      activationTooltip = 'Re-Activate'
    }

    return (
      <CommonRuleActionsCell
        editModaTitle="Edit Merchant Category Regex Rule (MCRR)"
        editModalContent={this.getEditModal()}
        id={id}
        isActive={isActive}
        isActivationBlocked={isActivationBlocked}
        activationTooltip={activationTooltip}
        deleteFunction={this.deleteFunction}
        activateFunction={this.activateFunction}
      />
    )
  }
}

MerchantCategoryRegexActionsCell.propTypes = {
  saveRuleAction: PropTypes.func.isRequired,
  row: PropTypes.object.isRequired
}

const mapDispatchToProps = {
  saveRuleAction: MerchantCategoryRegexRulesActions.save
}

export default connect(null, mapDispatchToProps)(MerchantCategoryRegexActionsCell)
