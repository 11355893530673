import React from 'react'
import PropTypes from 'prop-types'
import Loader from 'components/Loader/Loader'

const WrapperWithLoader = ({ isLoading, children }) => {
  return <div>{isLoading ? <Loader /> : children}</div>
}

WrapperWithLoader.propTypes = {
  isLoading: PropTypes.bool,
  children: PropTypes.element
}

export default WrapperWithLoader
