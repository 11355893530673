import { useLocalStorage } from '@rehooks/local-storage'
import { getLocalStorageKey, getColumnVisibility } from './utils'
import { pickBy } from 'lodash'

const ALWAYS_VISIBLE_COLUMNS = ['id', 'actionsColumn']

/**
  Columns Info for Configuration Modal
*/
const buildColumnsForConfiguration = (columns, filters) => {
  return columns.reduce((accum, col) => {
    return {
      ...accum,
      ...{
        [col.field]: {
          label: col.label,
          title: col.title,
          isAlwaysVisible: col.isAlwaysVisible || ALWAYS_VISIBLE_COLUMNS.includes(col.field) || false,
          isFiltered: !!filters[col.field]
        }
      }
    }
  }, {})
}

/**
 * Use columns configuration to get only visible columns
 */
const getVisibleColumns = (columns, columnsVisibility) => {
  if (columnsVisibility) {
    return columns.reduce((accum, column) => {
      if (getColumnVisibility(columnsVisibility?.[column.field])) {
        accum.push(column)
      }
      return accum
    }, [])
  }

  // don't have any info in Localstorage -> show all columns
  return columns
}

/**
 * This hook configure visibility of table columns base on storing information in LocalStorage
 */
const useColumnsConfig = (columns, tableId, filters) => {
  // build config columns
  const columnsConfig = buildColumnsForConfiguration(columns, filters)

  // get visible columns
  const [columnsVisibility] = useLocalStorage(getLocalStorageKey(tableId), null)
  const visibleColumns = getVisibleColumns(columns, columnsVisibility)

  // build Key for table
  const key = visibleColumns.map(column => column.field).join('')

  // take filters only for visiblie columns
  const visibleFilters = pickBy(filters, (value, key) => getColumnVisibility(columnsVisibility?.[key]))

  return { visibleColumns, columnsConfig, key, visibleFilters }
}

export default useColumnsConfig
