import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { Glyphicon, OverlayTrigger, Tooltip } from 'react-bootstrap'

import TaxonomyEditModal from './TaxonomyEditModal'
import CategoryDeleteModal from './../Categories/CategoryDeleteModal'

import { confirmModal, addCustomModal } from '../../../actions/modal-actions'

export class ActionColumn extends Component {
  renderActionTooltip = label => <Tooltip id="taxonomy-action">{label}</Tooltip>

  deleteModal = () => {
    const { confirmModal, rowId, rowName, tableType, isLeaf } = this.props

    confirmModal(
      <div>
        <span>
          {`Are you sure you want to delete ${tableType.toLowerCase()} `}
          <strong>
            <em>{rowName}</em>
          </strong>
          ?
        </span>
        {!isLeaf && <div>Warning: all children of this {tableType.toLowerCase()} will be also deleted!</div>}
      </div>,
      'Confirm Delete',
      { okButton: `Remove ${tableType}` },
      () => this.props.deleteFunction(rowId)
    )
  }

  deleteCategoryModal = () => {
    const { rowId, rowName, isLeaf, addCustomModal } = this.props
    addCustomModal({
      id: 'delete-taxonomy-category-modal',
      title: `Confirm Delete`,
      size: 'large',
      content: (
        <CategoryDeleteModal
          rowId={rowId}
          rowName={rowName}
          isLeaf={isLeaf}
          deleteFunction={this.props.deleteFunction}
        />
      )
    })
  }

  activateModal = () => {
    const { confirmModal, rowId, rowName, tableType, editFunction } = this.props

    confirmModal(
      <div>
        <span>
          {`Are you sure you want to re-activate ${tableType.toLowerCase()} `}
          <strong>
            <em>{rowName}</em>
          </strong>
          ?
        </span>
        <p>&nbsp;</p>
        <div>
          <em>
            <strong>Note:</strong> You also activate the parent node if it is inactive.
            <p>
              Also, any inactive associated Rules with this {tableType} will be re-activated. (However, any rules
              manually deleted by user will remain inactive.)
            </p>
          </em>
        </div>
      </div>,
      'Confirm Re-Activate',
      { okButton: `Re-Activate ${tableType}` },
      () => editFunction({ rowId, isActive: true })
    )
  }

  editModal = () => {
    const { rowId, rowName, isCPG, tableType, editFunction, addCustomModal } = this.props

    addCustomModal({
      modalType: 'successModal',
      id: 'taxonomy-edit-modal',
      title: `Edit ${tableType}`,
      content: (
        <TaxonomyEditModal
          rowId={rowId}
          rowName={rowName}
          isCPG={isCPG}
          editFunction={editFunction}
          tableType={tableType}
        />
      )
    })
  }

  render() {
    const { tableType, isActive } = this.props

    return (
      <div className="sb-table-action-cell">
        <OverlayTrigger placement="top" overlay={this.renderActionTooltip(`Edit ${tableType}`)}>
          <div onClick={this.editModal}>
            <Glyphicon glyph="edit" />
          </div>
        </OverlayTrigger>

        {isActive ? (
          <OverlayTrigger placement="left" overlay={this.renderActionTooltip(`Delete ${tableType}`)}>
            <div onClick={tableType.toLowerCase() === 'category' ? this.deleteCategoryModal : this.deleteModal}>
              <Glyphicon glyph="trash" />
            </div>
          </OverlayTrigger>
        ) : (
          <OverlayTrigger placement="left" overlay={this.renderActionTooltip(`Re-Activate ${tableType}`)}>
            <div onClick={this.activateModal}>
              <Glyphicon glyph="repeat" />
            </div>
          </OverlayTrigger>
        )}
      </div>
    )
  }
}

ActionColumn.propTypes = {
  isLeaf: PropTypes.bool.isRequired,
  isActive: PropTypes.bool.isRequired,
  rowId: PropTypes.number.isRequired,
  rowName: PropTypes.string.isRequired,
  isCPG: PropTypes.bool,
  tableType: PropTypes.string.isRequired,
  editFunction: PropTypes.func.isRequired,
  confirmModal: PropTypes.func.isRequired,
  addCustomModal: PropTypes.func.isRequired,
  deleteFunction: PropTypes.func.isRequired
}

const mapDispatchToProps = {
  confirmModal,
  addCustomModal
}

export default connect(null, mapDispatchToProps)(ActionColumn)
