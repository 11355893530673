import { useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { isEmpty } from 'lodash'

import { getGraphOptions } from './../constants'
import { METRICS_STORE_PATH } from './../metricsReducer'
import useCategoryInfo from './useCategoryInfo'
import metricsActions from './../metricsActions'
import { getMetricWarningMessage } from './../utils'

const useMetric = graphType => {
  const dispatch = useDispatch()

  const { queryType, storeKey } = getGraphOptions(graphType)

  // get chart info
  const { data, loading, isQueryCorrect } = useSelector(state => state.searchEdit[storeKey])

  // get selected group>query info
  const { groupQueryLabel, queryId, queryLabel, loading: queryLoading } = useSelector(
    state => state[METRICS_STORE_PATH][queryType]
  )

  // get selected Category Info
  const { label, categoryId } = useCategoryInfo()

  const warningMessage = getMetricWarningMessage({ queryId, isQueryCorrect, isNoData: isEmpty(data) })
  const subTitle = `${label} | ${groupQueryLabel} > ${queryLabel}`

  useEffect(() => {
    dispatch(metricsActions.fetchQuery({ queryType, categoryId }))
  }, [dispatch, queryType, categoryId])

  const refreshGraph = useCallback(() => {
    if (queryId) {
      dispatch(metricsActions.fetchGraph({ graphType, categoryId, queryId }))
    }
  }, [dispatch, graphType, queryId, categoryId])

  useEffect(() => {
    refreshGraph()
  }, [refreshGraph])

  return {
    data,
    loading: loading || queryLoading,
    subTitle,
    queryType,
    queryId,
    refreshGraph,
    warningMessage
  }
}

export default useMetric
