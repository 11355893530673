import React from 'react'

export default function Contract(props) {
  return (
    <svg
      className="contract-icon"
      width="23px"
      height="18px"
      viewBox="0 0 23 18"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <defs />
      <g id="Components" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g id="Icons" transform="translate(-155.000000, -259.000000)">
          <g id="ic-contract" transform="translate(151.000000, 253.000000)">
            <g id="Reports">
              <rect id="spacing" x={0} y={0} width={30} height={30} />
              <g id="report" transform="translate(4.000000, 6.000000)">
                <path
                  d="M4.5,0.5 L4.5,17.5 L15,17.5 C16.3807119,17.5 17.5,16.3807119 17.5,15 L17.5,0.5 L4.5,0.5 Z"
                  id="Rectangle-3"
                  stroke="currentColor"
                  fill="#FFFFFF"
                />
                <path
                  d="M10.2232092,7 C11.2395605,11.4616302 5.32312984,13.4234391 7.47495858,9.3967591 C8.01695086,8.3825383 12.858247,10.8843996 13.7100089,10.9712845"
                  id="Path-6"
                  stroke="currentColor"
                />
                <rect
                  id="Rectangle-2-Copy-3"
                  fill="currentColor"
                  transform="translate(11.000000, 3.500000) rotate(-360.000000) translate(-11.000000, -3.500000) "
                  x={7}
                  y={3}
                  width={8}
                  height={1}
                />
                <rect
                  id="Rectangle-2-Copy-4"
                  fill="currentColor"
                  transform="translate(11.000000, 5.500000) rotate(-360.000000) translate(-11.000000, -5.500000) "
                  x={7}
                  y={5}
                  width={8}
                  height={1}
                />
                <path
                  d="M2.10094356,13.5 C1.99245997,13.5541054 1.83541142,13.6621765 1.67379515,13.8358792 C1.32671366,14.2089172 1.11547155,14.7395711 1.11547155,15.4830478 C1.11547155,16.2280435 1.32790628,16.7668955 1.6775951,17.1512989 C1.83944274,17.3292137 1.9968392,17.4419437 2.10687379,17.5 L13.9671207,17.5 C13.6725899,16.9709704 13.5,16.3466994 13.5,15.6309611 C13.5,14.8492 13.7051256,14.1340193 14.0468806,13.5 L2.10094356,13.5 Z"
                  id="Rectangle-2-Copy-4"
                  stroke="currentColor"
                  fill="#FFFFFF"
                  transform="translate(7.807736, 15.500000) rotate(-360.000000) translate(-7.807736, -15.500000) "
                />
                <path
                  d="M20.4639158,3 L22.0982251,4.61834692 L15.2266784,11.4495263 C15.2266784,11.4495263 13.2937848,12.1949601 13.0474815,11.9509242 C12.8011782,11.7068882 13.5932956,9.8311794 13.5932956,9.8311794 L20.4639158,3 Z"
                  id="Shape"
                  fill="currentColor"
                  fillRule="nonzero"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
