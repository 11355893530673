import React from 'react'
import PropTypes from 'prop-types'
import { FormGroup, Glyphicon, Button } from 'react-bootstrap'

/**
 * Component for +/- buttons that can add or remove fields in a form
 */
const ManageFieldButtons = ({
  name,
  addHandler,
  removeHandler,
  fieldsCount,
  hasEmptyField = false,
  minFildCount = 0,
  maxFildCount = 20
}) => {
  return (
    <FormGroup className="text-center">
      <Button className="btn_small" onClick={addHandler} disabled={fieldsCount >= maxFildCount || hasEmptyField}>
        <Glyphicon glyph="plus" className="pr-2" />
        Add {name}
      </Button>
      <Button className="btn_small ml-4" onClick={removeHandler} disabled={fieldsCount <= minFildCount}>
        <Glyphicon glyph="minus" className="pr-2" />
        Remove {name}
      </Button>
    </FormGroup>
  )
}

ManageFieldButtons.propTypes = {
  name: PropTypes.string,
  addHandler: PropTypes.func,
  removeHandler: PropTypes.func,
  fieldsCount: PropTypes.number,
  hasEmptyField: PropTypes.bool,
  minFildCount: PropTypes.number,
  maxFildCount: PropTypes.number
}

export default ManageFieldButtons
