import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { FormControl, Well, Row, Col, DropdownButton } from 'react-bootstrap'
import CheckBox from '../CheckBox'
import Box from '../Box'
import { reduce, filter } from 'lodash'
import { ES_SCORECARD, ES_SCORECARD_KEY, SEARCH_KEY_ALL } from 'constants/constants'

const options = {
  brandFilters: [
    {
      key: SEARCH_KEY_ALL,
      value: 'All Brands'
    },
    {
      key: 'brandsInDict',
      value: 'Brands in Dictionary'
    },
    {
      key: 'brandsNotInDict',
      value: 'Brands not in Dictionary'
    },
    {
      key: 'brandsInStaging',
      value: 'Brands in Staging'
    },
    {
      key: 'brandsNotInStaging',
      value: 'Brands not in Staging'
    },
    {
      key: 'brandsEditable',
      value: 'Brands Editable'
    },
    {
      key: 'brandsNotEditable',
      value: 'Brands not Editable'
    }
  ],
  categoryFilters: [
    {
      key: SEARCH_KEY_ALL,
      value: 'All Categories'
    },
    {
      key: 'categoriesInDict',
      value: 'Categories in Dictionary'
    },
    {
      key: 'categoriesNotInDict',
      value: 'Categories not in Dictionary'
    },
    {
      key: 'categoriesInStaging',
      value: 'Categories in Staging'
    },
    {
      key: 'categoriesNotInStaging',
      value: 'Categories not in Staging'
    },
    {
      key: 'categoriesEditable',
      value: 'Categories Editable'
    },
    {
      key: 'categoriesNotEditable',
      value: 'Categories not Editable'
    }
  ],
  [ES_SCORECARD_KEY]: [
    {
      key: ES_SCORECARD.scorecard,
      value: 'Scorecard Data'
    },
    {
      key: ES_SCORECARD.nonScorecard,
      value: 'Non-Scorecard Data'
    }
  ],
  userTertile: [
    {
      key: '0',
      value: 'All Users'
    },
    {
      key: '1',
      value: 'User Group 1'
    },
    {
      key: '2',
      value: 'User Group 2'
    },
    {
      key: '3',
      value: 'User Group 3'
    }
  ]
}

class SearchDictionaryFilter extends Component {
  static propTypes = {
    brandFilters: PropTypes.object,
    categoryFilters: PropTypes.object,
    scoreCard: PropTypes.string,
    userTertile: PropTypes.string,
    onChanged: PropTypes.func,
    brandUpdateUsers: PropTypes.array,
    categoryUpdateUsers: PropTypes.array,
    selectedBrandUsers: PropTypes.array,
    selectedCategoryUsers: PropTypes.array
  }

  onFiltersChange(attr, key, value) {
    if (['brand_added_by', 'category_added_by'].includes(attr)) {
      this.props.onChanged(attr, key)
    } else if (key === SEARCH_KEY_ALL) {
      const selectedFilters = reduce(
        options[attr],
        (map, option) => {
          map[option.key] = value
          return map
        },
        {}
      )
      this.props.onChanged(attr, selectedFilters)
    } else {
      const selectedFilters = { ...this.props[attr], [key]: value }
      selectedFilters.all = filter(selectedFilters, (value, key) => !value && key !== SEARCH_KEY_ALL).length === 0
      this.props.onChanged(attr, selectedFilters)
    }
  }

  render() {
    const renderOptions = (attribute, multiselect) => {
      let optionList = []
      let selectedUserList = []
      switch (attribute) {
        case 'brand_added_by':
          optionList = this.props.brandUpdateUsers
          selectedUserList = this.props.selectedBrandUsers
          break
        case 'category_added_by':
          optionList = this.props.categoryUpdateUsers
          selectedUserList = this.props.selectedCategoryUsers
          break
        default:
          optionList = options[attribute]
      }

      if (multiselect) {
        return optionList.map(option => {
          const key = option.key ? option.key : option
          return (
            <CheckBox
              key={key}
              className={'filter'}
              valueDidChange={checked => this.onFiltersChange(attribute, key, checked)}
              checked={
                option.key
                  ? this.props[attribute][option.key] || this.props[attribute].all
                  : selectedUserList.includes(option)
              }
              label={option.value ? option.value : option}
            />
          )
        })
      }
      return optionList.map(opt => {
        return (
          <option key={opt.key} value={opt.key}>
            {opt.value}
          </option>
        )
      })
    }
    return (
      <div>
        <Well bsSize="small">
          <Row>
            <Col md={12} style={{ paddingBottom: '0.5em' }}>
              <FormControl
                componentClass="select"
                placeholder="Select Scorecard Filter"
                onChange={e => this.props.onChanged(ES_SCORECARD_KEY, e.target.value)}
                defaultValue={this.props.scoreCard}
              >
                {renderOptions(ES_SCORECARD_KEY)}
              </FormControl>
            </Col>
          </Row>

          <Row>
            <Col md={6} className={'search-filters-container'}>
              <DropdownButton key="brandFilters" id="brandFilters" title="Brand Filters">
                <div className="checkbox-filter">
                  <Box className={'search-filter block'}>
                    <>{renderOptions('brandFilters', true)}</>
                  </Box>
                </div>
              </DropdownButton>
            </Col>

            <Col md={6} className={'search-filters-container'}>
              <DropdownButton key="categoryFilters" id="categoryFilters" title="Category Filters">
                <div className="checkbox-filter">
                  <Box className={'search-filter block'}>
                    <>{renderOptions('categoryFilters', true)}</>
                  </Box>
                </div>
              </DropdownButton>
            </Col>
          </Row>

          <Row>
            <Col md={6} className={'search-filters-container'}>
              <DropdownButton key="brandUserFilters" id="brandUserFilters" title="Brand Updated By">
                <div className="checkbox-filter updated-by">
                  <Box className={'search-filter block label-capitalize'}>
                    <> {renderOptions('brand_added_by', true)}</>
                  </Box>
                </div>
              </DropdownButton>
            </Col>

            <Col md={6} className={'search-filters-container'}>
              <DropdownButton key="categoryUserFilters" id="categoryUserFilters" title="Category Updated By">
                <div className="checkbox-filter updated-by">
                  <Box className={'search-filter block label-capitalize'}>
                    <>{renderOptions('category_added_by', true)}</>
                  </Box>
                </div>
              </DropdownButton>
            </Col>
          </Row>

          <Row>
            <Col md={6} style={{ paddingBottom: '0.5em' }}>
              <FormControl
                componentClass="select"
                placeholder="Select User Filter"
                onChange={e => this.props.onChanged('userTertile', e.target.value)}
                defaultValue={this.props.userTertile}
              >
                {renderOptions('userTertile')}
              </FormControl>
            </Col>
          </Row>
        </Well>
      </div>
    )
  }
}

export default SearchDictionaryFilter
