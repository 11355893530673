import { isSameDay } from 'date-fns'
import moment from 'moment'

// See more info https://github.com/hypeserver/react-date-range/tree/master/src/components/DefinedRange how to build custom ranges

const staticRangeHandler = {
  range: {},
  isSelected(range) {
    const definedRange = this.range()
    return isSameDay(range.startDate, definedRange.startDate) && isSameDay(range.endDate, definedRange.endDate)
  }
}

export function createStaticRanges(ranges) {
  return ranges.map(range => ({ ...staticRangeHandler, ...range }))
}

/**
 * Build predefined date ranges
 *
 * @rangeStart - js Date obj || moment obj
 * @rangeEnd - js Date obj || moment obj
 */
export const getDateRanges = (rangeStart, rangeEnd) => {
  const rangeStartDate = moment(rangeStart).toDate() // in any case convert to Date obj
  const rangeEndDate = moment(rangeEnd).toDate() // in any case convert to Date obj
  return {
    empty: {
      label: 'Clear range',
      range: () => ({
        startDate: null,
        endDate: null
      })
    },
    last7d: {
      label: 'Last 7 days',
      range: () => ({
        startDate: moment(rangeEnd)
          .add(-7, 'days')
          .toDate(),
        endDate: rangeEndDate
      })
    },
    last1m: {
      label: 'Last 1 month',
      range: () => ({
        startDate: moment(rangeEnd)
          .startOf('month')
          .toDate(),
        endDate: rangeEndDate
      })
    },
    last3m: {
      label: 'Last 3 months',
      range: () => ({
        startDate: moment(rangeEnd)
          .subtract(2, 'month')
          .startOf('month')
          .toDate(),
        endDate: rangeEndDate
      })
    },
    last6m: {
      label: 'Last 6 months',
      range: () => ({
        startDate: moment(rangeEnd)
          .subtract(5, 'month')
          .startOf('month')
          .toDate(),
        endDate: rangeEndDate
      })
    },
    last12m: {
      label: 'Last 12 months',
      range: () => ({
        startDate: moment(rangeEnd)
          .subtract(11, 'month')
          .startOf('month')
          .toDate(),
        endDate: rangeEndDate
      })
    },
    last24m: {
      label: 'Last 24 months',
      range: () => ({
        startDate: moment(rangeEnd)
          .subtract(23, 'month')
          .startOf('month')
          .toDate(),
        endDate: rangeEndDate
      })
    },
    last36m: {
      label: 'Last 36 months',
      range: () => ({
        startDate: moment(rangeEnd)
          .subtract(35, 'month')
          .startOf('month')
          .toDate(),
        endDate: rangeEndDate
      })
    },
    calYearToDate: {
      label: 'Year to date',
      range: () => ({
        startDate: moment(rangeEnd)
          .startOf('year')
          .toDate(),
        endDate: rangeEndDate
      })
    },
    allTime: {
      label: 'All Time',
      range: () => ({
        startDate: rangeStartDate,
        endDate: rangeEndDate
      })
    }
  }
}

/**
 * @rangeStart - Date object
 * @rangeEnd - Date object
 */
const monthsRanges = (rangeStart, rangeEnd) => {
  const ranges = getDateRanges(rangeStart, rangeEnd)
  return createStaticRanges([
    ranges.last1m,
    ranges.last3m,
    ranges.last6m,
    ranges.last12m,
    ranges.last24m,
    ranges.last36m,
    ranges.calYearToDate,
    ranges.allTime,
    ranges.empty
  ])
}

export default monthsRanges
