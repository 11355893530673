// @flow
import localStorage from '../localStorage'

export default class LayoutHelper {
  layoutId: string
  defaultLayouts: Object
  version: number

  constructor(layoutId: string, defaultLayouts: Object = {}, version: number = 0) {
    this.layoutId = layoutId
    this.version = version
    this.defaultLayouts = defaultLayouts
  }

  getLayouts = function() {
    const storageLayout = localStorage.get(this.layoutId, true)
    if (storageLayout && storageLayout.layouts && this.version === storageLayout.version) {
      return storageLayout.layouts
    } else {
      localStorage.set(this.layoutId, { version: this.version, layouts: this.defaultLayouts }, true)
      return this.defaultLayouts
    }
  }

  setLayouts = function(layouts: Object) {
    layouts = layouts || this.defaultLayouts
    localStorage.set(this.layoutId, { version: this.version, layouts }, true)
  }

  onLayoutChange = (context, layout, layouts, a) => {
    this.setLayouts(layouts)
    context.setState({ layout, layouts })
  }
}
