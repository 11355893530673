import React from 'react'
import PropTypes from 'prop-types'

import { Alert } from 'react-bootstrap'

const MetricsWarning = ({ hasSelectedCategory, isMultipleSelection }) => {
  if (!hasSelectedCategory) {
    return (
      <Alert bsStyle="warning" className="qc-metris-warning">
        No category selected. Hit <strong>Apply</strong> button if not clicked.
      </Alert>
    )
  }

  // NOTE: only Dictionary Graph supports multiple categories.
  // But we don't need it, so disable it also
  if (isMultipleSelection) {
    return (
      <Alert bsStyle="warning" className="qc-metris-warning">
        Multiple Categories are selected. Please select only one Category.
      </Alert>
    )
  }

  return null
}

MetricsWarning.propTypes = {
  hasSelectedCategory: PropTypes.bool,
  isMultipleSelection: PropTypes.bool
}

export default MetricsWarning
