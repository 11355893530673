import React from 'react'
import { Controller } from 'react-hook-form'
import { Checkbox } from 'react-bootstrap'
import PropTypes from 'prop-types'
import InputField from 'components/FormInputComponents/InputField'

const SkipTrafficCheckInput = ({ control, errors, info }) => {
  return (
    <InputField
      label="Skip Traffic Check"
      info={info}
      className="text-start"
      labelSize={4}
      validationMessage={errors.skipTrafficCheck?.message}
    >
      <Controller
        name="skipTrafficCheck"
        control={control}
        render={({ field }) => {
          return (
            <>
              <Checkbox {...field} checked={field.value} />
              {field.value && <p className="text-danger">Are you sure you want to skip traffic check?</p>}
            </>
          )
        }}
      />
    </InputField>
  )
}

SkipTrafficCheckInput.propTypes = {
  control: PropTypes.any,
  info: PropTypes.string,
  errors: PropTypes.object
}

export default SkipTrafficCheckInput
