import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { cleanupSearchSuggestion } from 'components/NIQSearchPage/actions/niq-search-actions'

const Rule = ({ id, parentId, field, operator, value, isDisabled, schema, editable }) => {
  const {
    fields,
    controls,
    getOperators,
    getLevel,
    classNames,
    onPropChange,
    onRuleRemove,
    onRuleRepeat,
    onDisableToggle
  } = schema

  const dispatch = useDispatch()

  const onElementChanged = (property, value, condition) => {
    onPropChange(property, value, id, condition)
  }

  const onFieldChanged = value => {
    onElementChanged('field', value)
    dispatch(cleanupSearchSuggestion())
  }

  const onOperatorChanged = value => {
    onElementChanged('operator', value)
  }

  const onValueChanged = (value, condition) => {
    onElementChanged('value', value, condition)
  }

  const removeRule = event => {
    event.preventDefault()
    event.stopPropagation()

    onRuleRemove(id, parentId)
  }

  const repeatRule = event => {
    event.preventDefault()
    event.stopPropagation()

    onRuleRepeat(id, parentId)
  }

  const disableToggle = event => {
    event.preventDefault()
    event.stopPropagation()

    onDisableToggle(id)
  }

  const level = getLevel && getLevel(id)

  return (
    <div className={`rule ${classNames.rule} ${isDisabled ? 'rule-disabled' : ''}`}>
      <div className={'rule-selections-wrapper'}>
        {React.createElement(controls.fieldSelector, {
          options: fields,
          value: field,
          className: `rule-fields ${classNames.fields}`,
          handleOnChange: onFieldChanged,
          level,
          readOnly: !editable
        })}
        {React.createElement(controls.operatorSelector, {
          field,
          options: getOperators(field),
          value: operator,
          className: `rule-operators ${classNames.operators}`,
          handleOnChange: onOperatorChanged,
          level,
          readOnly: !editable
        })}
        {React.createElement(controls.valueEditor, {
          field,
          operator,
          value,
          className: `rule-value ${classNames.value}`,
          handleOnChange: onValueChanged,
          level,
          readOnly: !editable
        })}
      </div>
      {editable && (
        <div className={`rule-actions ${classNames.ruleActions}`}>
          {controls.repeatRuleAction &&
            React.createElement(controls.repeatRuleAction, {
              label: '+',
              className: `rule-repeat ${classNames.repeatRule}`,
              handleOnClick: repeatRule,
              level
            })}
          {controls.disableToggleRuleAction &&
            React.createElement(controls.disableToggleRuleAction, {
              label: isDisabled ? 'Enable' : 'Disable',
              className: `rule-disable ${classNames.disableToggle}`,
              handleOnClick: disableToggle,
              level,
              isDisabled
            })}
          {controls.removeRuleAction &&
            React.createElement(controls.removeRuleAction, {
              label: 'x',
              className: `rule-remove ${classNames.removeRule}`,
              handleOnClick: removeRule,
              level,
              isDisabled
            })}
        </div>
      )}
    </div>
  )
}

Rule.propTypes = {
  id: PropTypes.string,
  parentId: PropTypes.string,
  combinator: PropTypes.string,
  field: PropTypes.string,
  operator: PropTypes.string,
  value: PropTypes.any,
  isDisabled: PropTypes.bool,
  schema: PropTypes.object,
  label: PropTypes.string,
  editable: PropTypes.bool
}

Rule.defaultProps = {
  id: null,
  parentId: null,
  field: null,
  operator: null,
  value: null,
  isDisabled: null,
  schema: {},
  editable: true
}

export default Rule
