import React from 'react'
import PropTypes from 'prop-types'

import { Form } from 'react-bootstrap'

import { useFormValues } from 'components/FormInputComponents/InputField'
import { getSubmitButtonName } from '../../../Rules/Common/utils'

import useMode from 'components/Rules/Common/useMode'
import useFormButtons from 'components/Rules/Common/useFormButtons'
import NoteFields from 'components/Rules/Common/NoteFields'
import CommonRuleModalButtons from 'components/Rules/Common/CommonRuleModalButtons'

export default function GeneralRequestModal({ value, onOkHandler, onRejectHandler, isReadOnly }) {
  const { id, request_notes, review_notes } = value

  const isRequest = true
  const mode = useMode(isRequest, isReadOnly, id)

  const { values, handleFieldChange } = useFormValues({
    id: id ?? null,
    request_notes: request_notes ?? null,
    review_notes: review_notes ?? null
  })

  let isOkButtonEnabled = true
  let isRejectButtonEnabled = true
  if (mode.isRequestCreate && !values.request_notes) {
    isOkButtonEnabled = false
  }
  if (mode.isRequestApprove && !values.review_notes) {
    isOkButtonEnabled = false
    isRejectButtonEnabled = false
  }

  const sentFieldsForRules = []
  const sentFieldsForRequests = ['request_notes', 'review_notes']
  const { handleOkButton, handleRejectButton, handleCancelButton } = useFormButtons({
    onOkHandler,
    onRejectHandler,
    values,
    sentFieldsForRules,
    sentFieldsForRequests,
    isRequest
  })

  return (
    <Form className="text-left" horizontal>
      <NoteFields
        mode={mode}
        request_notes={values.request_notes}
        review_notes={values.review_notes}
        handleFieldChange={handleFieldChange}
        required
      />

      <CommonRuleModalButtons
        handleOkButton={handleOkButton}
        handleRejectButton={handleRejectButton}
        handleCancelButton={handleCancelButton}
        mode={mode}
        okButtonName={getSubmitButtonName(mode)}
        isOkButtonDisabled={!isOkButtonEnabled}
        isRejectButtonDisabled={!isRejectButtonEnabled}
      />
    </Form>
  )
}

GeneralRequestModal.defaultProps = {
  value: {
    id: null,
    request_notes: null,
    review_notes: null
  },
  isReadOnly: false
}

GeneralRequestModal.propTypes = {
  value: PropTypes.shape({
    id: PropTypes.number,
    request_notes: PropTypes.string,
    review_notes: PropTypes.string
  }),

  onOkHandler: PropTypes.func.isRequired,
  onRejectHandler: PropTypes.func,

  isReadOnly: PropTypes.bool
}
