import React, { Component } from 'react'
import PropTypes from 'prop-types'
import './index.scss'

// TODO @todo: Rename this component as it's just a textarea, not a modal
class FilterListModal extends Component {
  constructor(props) {
    super(props)
    this.textareaRef = null
  }

  captureTextInput = () => {
    const input = this.textAreaRef.value.replace(/;$/g, '').split(';')
    this.props.onSubmit(input)
  }

  render() {
    return (
      <div>
        <textarea
          ref={el => {
            this.textAreaRef = el
          }}
          className="FilterListModal__textarea"
          placeholder="e.g. 1;2;3;"
          onBlur={this.captureTextInput}
        />
      </div>
    )
  }
}

FilterListModal.propTypes = {
  onSubmit: PropTypes.func
}

export default FilterListModal
