import React, { Component } from 'react'
import PropTypes from 'prop-types'
import FormDatePicker from './../../FormDatePicker'
import MerchantsInput from './../Filter/merchants-input'
import { Field } from 'redux-form'

export default class RideShare extends Component {
  static propTypes = {
    feed: PropTypes.object
  }

  render() {
    return (
      <div>
        <Field name="merchants" component={MerchantsInput} />
        <FormDatePicker
          label="Min Order Date"
          fieldName="minOrderDate"
          isRequired={!this.props.feed.isActive}
          fromDate={new Date('1/1/13')}
          tooltip={'The min order date is inclusive.'}
        />
        {!this.props.feed.isActive && (
          <FormDatePicker
            label="Max Order Date"
            isRequired
            fieldName="maxOrderDate"
            fromDate={this.props.feed.minOrderDate ? new Date(this.props.feed.minOrderDate) : new Date('1/1/13')}
            tooltip={'The max order date is inclusive.'}
          />
        )}
      </div>
    )
  }
}
