import React from 'react'
import PropTypes from 'prop-types'

import ReactHighcharts from 'react-highcharts'
import { isEqual, round } from 'lodash'
import convertYAxisIfNeed from './chartConvertor'

import highchartsTheme from './../../highcharts-theme'

const NUMBERS_AFTER_DECIMAL_POINT = 1
const RED_POINT_COLOR = '#FF0000'

const AreaChart = ({
  data,
  title,
  subTitle,
  height = 400,
  width,
  yAxisTitle,
  threshold,
  isInvertQuery = false,
  isPercentBetweenSeries = false
}) => {
  data.yAxis = convertYAxisIfNeed({ yAxis: data.yAxis, isInvertQuery, isPercentBetweenSeries })

  ReactHighcharts.Highcharts.setOptions(highchartsTheme)

  const config = {
    chart: {
      type: 'area',
      height: height,
      width: height ? null : width
    },
    title: { text: title },
    subtitle: { text: subTitle },
    xAxis: {
      categories: data.xAxis,
      tickmarkPlacement: 'on',
      title: { enabled: false }
    },
    yAxis: {
      title: { text: yAxisTitle }
    },
    tooltip: {
      headerFormat: '<span style="font-size: 15px"><b>{point.key}</b></span><br/>',
      pointFormat:
        '<span style="color:{series.color}">{series.name}</span>: <b>{point.percentage:.1f}%</b> ({point.y:,.0f})<br/>',
      shared: true,
      split: false
    },
    plotOptions: {
      area: {
        stacking: 'percent',
        lineColor: '#ffffff',
        lineWidth: 1,
        marker: {
          lineWidth: 1,
          lineColor: '#ffffff'
        }
      }
    },
    credits: { enabled: false },
    series: data.yAxis
  }

  if (threshold) {
    // Mark points that % > threshold in red

    config.chart.events = {
      load: function() {
        const querySeries = this.series[0]
        const contextSeries = this.series[1]
        querySeries.points.forEach((queryPoint, index) => {
          // round like we do in category tests in server
          const roundPercentage = round(queryPoint.percentage, NUMBERS_AFTER_DECIMAL_POINT)
          if (roundPercentage > threshold) {
            // we check querySeries but mark both series it's better for UI
            const color = RED_POINT_COLOR
            queryPoint.update({ color })
            contextSeries.points[index].update({ color })
          }
        })
      }
    }
  }

  return <ReactHighcharts config={config} />
}

AreaChart.propTypes = {
  data: PropTypes.object.isRequired,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
  yAxisTitle: PropTypes.string,
  isInvertQuery: PropTypes.bool,
  isPercentBetweenSeries: PropTypes.bool,
  threshold: PropTypes.number
}

const areEqual = (prevProps, nextProps) =>
  isEqual(prevProps.data, nextProps.data) &&
  prevProps.height === nextProps.height &&
  prevProps.width === nextProps.width &&
  prevProps.loading === nextProps.loading

const AreaChartMemo = React.memo(AreaChart, areEqual)
AreaChartMemo.displayName = 'MetricChart'

export default AreaChartMemo
