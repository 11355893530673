/**
 * Get value from DOM element base on element type
 * @param{element} element - DOM element that we receive in onChange event
 * @return {Object} - object where the key is "id" of the element and the value from the corresponding element
 */
const getElementValue = element => {
  let value
  switch (element.type) {
    case 'select-one':
      value = element.value || undefined
      break
    case 'checkbox':
      value = element.checked || false
      break
    case 'number':
      value = Number(element.value)
      break
    case 'text':
    default:
      value = element.value || ''
      break
  }

  // we can get "id" from 'data-id'
  // this is necessary when we have a few InputField
  // with the same "id" on the page
  const id = element.id || element.dataset.id
  return { [id]: value }
}

export default getElementValue
