import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import cx from 'classnames'

class SelectedChip extends Component {
  static propTypes = {
    id: PropTypes.number,
    value: PropTypes.string,
    onRemoveValue: PropTypes.func,
    readOnly: PropTypes.bool
  }

  state = {
    focus: false
  }

  focus = () => {
    this.setState({ focus: true })
  }

  blur = () => {
    this.setState({ focus: false })
  }

  render() {
    const { id, value, readOnly, onRemoveValue } = this.props
    const tooltip = name => <Tooltip id={`${id}-selected-chip`}>{name}</Tooltip>
    return (
      <div
        className={cx('rbt-token rbt-token-removeable selected-chip', {
          'rbt-token-active': this.state.focus
        })}
      >
        {value}
        <button
          aria-label="Remove"
          className="close rbt-close rbt-token-remove-button"
          type="button"
          onFocus={this.focus}
          onBlur={this.blur}
          onClick={event => {
            if (readOnly) return
            onRemoveValue(id, value)
            event.preventDefault()
          }}
        >
          <OverlayTrigger
            delayShow={1000}
            placement="top"
            overlay={tooltip(readOnly ? 'Remove is disabled' : 'Remove')}
          >
            <span aria-hidden="true">{readOnly ? '' : '×'}</span>
          </OverlayTrigger>
          <span className="sr-only">Remove</span>
        </button>
      </div>
    )
  }
}

export default SelectedChip
