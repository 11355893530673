import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  initializeContractForm,
  updateContractForm,
  checkIfContractNameAvailable,
  saveContract,
  onClientSearchChange,
  onUserSearchChange
} from '../../../actions/dm-actions'
import { clearModals } from '../../../actions/modal-actions'
import { Button, ControlLabel, Glyphicon, Form } from 'react-bootstrap'
import TextInputField from '../Common/TextInputField'
import ChipSelectField from '../Common/ChipSelectField'
import Loader from '../../Loader'
import Highlighter from 'react-highlight-words'
import { debounce, pick } from 'lodash'

const FollowersLabel = (
  <span>
    Followers <Glyphicon glyph="question-sign" />
  </span>
)

class ContractForm extends Component {
  componentDidMount() {
    const { selectedClient, contract } = this.props
    this.props.initializeContractForm({
      Client: contract.Client
        ? contract.Client
        : selectedClient && selectedClient.id
        ? { ...selectedClient }
        : undefined,
      ...pick(contract, ['name', 'id', 'contractSize', 'salesOwner', 'csOwner', 'followers']),
      dirty: false
    })
  }

  getValidationDetails(key, value) {
    const payload = {}
    switch (key) {
      case 'name':
        payload.isNameValid = false
        if (value && value.trim()) payload.isNameValid = true
        break
      case 'Client':
        payload.isClientValid = false
        if (value && value.id) payload.isClientValid = true
        break
      default:
    }
    return payload
  }

  handleNameChange = event => {
    const { Client: { id: clientId } = {}, id } = this.props
    this.updateFormValue('name', event.target.value)
    if (clientId) {
      this.checkIfContractNameAvailable({
        id,
        name: event.target.value,
        clientId
      })
    }
  }

  checkIfContractNameAvailable = debounce(this.props.checkIfContractNameAvailable, 500)

  onClientSearchChange = debounce(this.props.onClientSearchChange, 500)

  onUserSearchChange = debounce(this.props.onUserSearchChange, 500)

  handleClientChange = value => {
    const { id, name } = this.props
    this.updateFormValue('Client', value)
    if (value && value.id && this.props.name) {
      this.checkIfContractNameAvailable({
        id,
        name,
        clientId: value.id
      })
    }
  }

  handleSalesOwnerChanged = value => {
    this.updateFormValue('salesOwner', value)
  }

  handleCSOwnerChanged = value => {
    this.updateFormValue('csOwner', value)
  }

  handleFollowersChanged = values => {
    this.updateFormValue('followers', values)
  }

  updateFormValue(key, value) {
    this.props.updateContractForm({
      [key]: value,
      ...this.getValidationDetails(key, value),
      dirty: true
    })
  }

  disableSaveButton() {
    const { dirty, isNameValid, isNameAvailable, isClientValid } = this.props
    return !dirty || isNameValid === false || isNameAvailable === false || isClientValid === false
  }

  saveContract = () => {
    const { salesOwner = {}, csOwner = {}, Client, saveContract } = this.props
    saveContract({
      ...pick(this.props, ['name', 'id', 'contractSize', 'followers']),
      clientId: Client.id,
      salesOwnerId: salesOwner ? salesOwner.id : null,
      csOwnerId: csOwner ? csOwner.id : null
    })
  }

  renderUserSuggestions = (option, props) => (
    <React.Fragment>
      <Highlighter
        searchWords={[props.text]}
        textToHighlight={option[props.labelKey]}
        highlightClassName="highlight-text"
      />
      <div className="subtext">{option.email}</div>
    </React.Fragment>
  )

  render() {
    const {
      name = '',
      dirty,
      isNameValid,
      isClientValid,
      id,
      isNameAvailable,
      Client,
      contractSize = '',
      salesOwner,
      csOwner,
      followers,
      loading,
      fileUploadEnabled
    } = this.props
    return (
      <Form className="sb-form">
        {loading && <Loader overlap />}
        <div>
          <TextInputField
            id="contract-name"
            label="Contract Name"
            help={
              isNameValid === false
                ? 'Contract name cannot be empty'
                : isNameAvailable === false
                ? 'Contract name already exists'
                : null
            }
            validationState={dirty && (isNameValid === false || isNameAvailable === false) ? 'error' : null}
            required
            type="text"
            value={name}
            onChange={this.handleNameChange}
            autoFocus
          />
          <ChipSelectField
            id="client"
            label="Client"
            required
            selectedValue={Client}
            onSelectionChange={this.handleClientChange}
            labelKey="name"
            onSearchChange={search => {
              this.onClientSearchChange(search)
            }}
            searchSuggestions={this.props.clientSuggestions}
            help={isClientValid === false ? 'Client cannot be empty' : null}
            validationState={dirty && isClientValid === false ? 'error' : null}
            placeholder="Select Client"
          />
          <TextInputField
            id="contractSize"
            label="Contract Size"
            type="number"
            value={contractSize}
            onChange={event => this.updateFormValue('contractSize', event.target.value)}
            prefix="$"
          />
          <ChipSelectField
            id="salesOwner"
            label="Sales Owner"
            labelKey="fullname"
            selectedValue={salesOwner}
            searchSuggestions={this.props.userSuggestions}
            onSelectionChange={this.handleSalesOwnerChanged}
            renderSearchSuggestion={this.renderUserSuggestions}
            onSearchChange={search => {
              this.onUserSearchChange('salesOwner', search)
            }}
            multiple={false}
            placeholder="Select User"
          />
          <ChipSelectField
            id="csOwner"
            label="CS Owner"
            labelKey="fullname"
            selectedValue={csOwner}
            searchSuggestions={this.props.userSuggestions}
            onSelectionChange={this.handleCSOwnerChanged}
            renderSearchSuggestion={this.renderUserSuggestions}
            onSearchChange={search => {
              this.onUserSearchChange('csOwner', search)
            }}
            placeholder="Select User"
          />
          <ChipSelectField
            id="followers"
            label={FollowersLabel}
            labelKey="fullname"
            selectedValues={followers}
            searchSuggestions={this.props.userSuggestions}
            onSelectionChange={this.handleFollowersChanged}
            multiple
            placeholder="Select Users"
            renderSearchSuggestion={this.renderUserSuggestions}
            onSearchChange={search => {
              this.onUserSearchChange('followers', search)
            }}
          />
          {fileUploadEnabled && (
            <div className="form-box">
              <ControlLabel>Upload Documents</ControlLabel>
              <div>
                <Button id="upload-file-button">
                  {' '}
                  <Glyphicon glyph="plus" />{' '}
                </Button>
              </div>
            </div>
          )}
        </div>
        <div className="modal-footer sb-form-footer">
          <div className="modal-buttons">
            <Button className="btn-cancel" onClick={this.props.clearModals}>
              Cancel
            </Button>
            <Button disabled={this.disableSaveButton()} className="btn-ok" onClick={this.saveContract}>
              {id && id !== 'new' ? 'Save' : 'Create'}
            </Button>
          </div>
        </div>
      </Form>
    )
  }
}

ContractForm.defaultProps = {
  contract: { id: 'new' }
}

ContractForm.propTypes = {
  contract: PropTypes.object,
  selectedClient: PropTypes.object,
  name: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  dirty: PropTypes.bool,
  isNameValid: PropTypes.bool,
  isNameAvailable: PropTypes.bool,
  isClientValid: PropTypes.bool,
  Client: PropTypes.object,
  contractSize: PropTypes.string,
  salesOwner: PropTypes.object,
  csOwner: PropTypes.object,
  followers: PropTypes.arrayOf(PropTypes.object),
  initializeContractForm: PropTypes.func,
  updateContractForm: PropTypes.func,
  checkIfContractNameAvailable: PropTypes.func,
  clearModals: PropTypes.func,
  saveContract: PropTypes.func,
  loading: PropTypes.bool,
  clientSuggestions: PropTypes.array,
  onClientSearchChange: PropTypes.func,
  userSuggestions: PropTypes.array,
  onUserSearchChange: PropTypes.func,
  fileUploadEnabled: PropTypes.bool
}

function mapStateToProps({ dm: { contractForm, selectedClient, clientSuggestions, userSuggestions } }) {
  return {
    ...contractForm,
    selectedClient,
    clientSuggestions,
    userSuggestions
  }
}

export default connect(mapStateToProps, {
  initializeContractForm,
  updateContractForm,
  checkIfContractNameAvailable,
  clearModals,
  saveContract,
  onClientSearchChange,
  onUserSearchChange
})(ContractForm)
