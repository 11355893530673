import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { fetchAutocompleteMerchants, fetchSelectedMerchants } from './../../../actions/merchant-actions'
import { Typeahead } from 'react-bootstrap-typeahead'
import FilterListModal from './../../Modals/FilterListModal'
import { updateSession } from './../../../actions/app-actions'
import { confirmModal } from '../../../actions/modal-actions'
import { clone } from 'lodash'
import cx from 'classnames'

class MerchantsInput extends Component {
  static propTypes = {
    fetchAutocompleteMerchants: PropTypes.func,
    fetchSelectedMerchants: PropTypes.func,
    autocompleteMerchants: PropTypes.array,
    input: PropTypes.object,
    meta: PropTypes.object,
    merchants: PropTypes.object,
    isMerchantsFilterVisible: PropTypes.bool,
    toggleContainerVisibility: PropTypes.func,
    confirmModal: PropTypes.func
  }

  constructor(props) {
    super(props)

    this.searchTypeahead = React.createRef()
  }

  componentDidMount() {
    if (this.props.input.value.length > 0) {
      this.props.fetchSelectedMerchants(this.props.input.value)
    }
  }

  handleImport = values => {
    this.props.input.onChange(values)
    this.props.input.onBlur(values)
    this.props.fetchSelectedMerchants(values)
  }

  openImportModal = event => {
    event.preventDefault()
    this.filtersDataRef = null
    this.props.confirmModal(
      <FilterListModal
        onSubmit={values => {
          this.filtersDataRef = values
        }}
      />,
      'Import merchant id list',
      { okButton: 'Import' },
      () => {
        this.handleImport(clone(this.filtersDataRef))
        this.filtersDataRef = null
      }
    )
  }

  render() {
    let toggleMessage = ''
    const length = Object.keys(this.props.input.value).length
    if (!length) {
      toggleMessage = 'no restrictions applied'
    } else if (length === 1) {
      toggleMessage = '1 merchant selected'
    } else {
      toggleMessage = `${length} merchants selected`
    }

    return (
      <div className="MerchantsInput__container FormSelect__container row">
        <label className="col-sm-2">Merchants</label>
        <div className="col-sm-10">
          <b>
            <span
              className="visibilityStyle btn_link"
              onClick={e => {
                e.preventDefault()
                this.props.toggleContainerVisibility(!this.props.isMerchantsFilterVisible)
              }}
            >
              {!this.props.isMerchantsFilterVisible ? `Show (${toggleMessage})` : `Hide (${toggleMessage})`}
            </span>
          </b>
          <br />
          <span
            className={cx('btn_link', {
              'top-spacing-sm': true,
              'inline-block': true,
              hidden: !this.props.isMerchantsFilterVisible
            })}
            onClick={this.openImportModal}
          >
            Import merchant id list
          </span>
          <div
            className={cx({
              container: true,
              hidden: !this.props.isMerchantsFilterVisible
            })}
          >
            <div className="col-sm-3">
              <div className="">
                <Typeahead
                  className="search_search"
                  ref={this.searchTypeahead}
                  placeholder={'Enter a merchant name'}
                  onChange={data => {
                    if (!data || data.length === 0) return
                    this.props.input.onChange([...this.props.input.value, data[0].id])
                    this.props.fetchSelectedMerchants([data[0].id])
                    this.searchTypeahead.current.getInstance().clear()
                  }}
                  onInputChange={text => {
                    if (!text || text.length < 2) return
                    this.props.fetchAutocompleteMerchants(text.toLowerCase())
                  }}
                  options={this.props.autocompleteMerchants}
                  labelKey={'name'}
                  disabled={false}
                  maxResults={10}
                  minLength={2}
                  paginate
                  renderMenuItemChildren={option => (
                    <div>
                      {option.name} ({option.id})
                    </div>
                  )}
                />
                {this.props.meta.error ? <span className="error">{this.props.meta.error}</span> : <span />}
              </div>
            </div>
            <div className="col-sm-3 merchant-names">
              <b className="title">Selected merchants</b>
              <div>
                {this.props.input.value &&
                  this.props.input.value.map(merchantId => {
                    const merchant = this.props.merchants[merchantId]
                    return merchant ? (
                      <div key={merchant.id}>
                        {merchant.name}{' '}
                        <span
                          className="remove-merchants btn_link"
                          onClick={() => {
                            this.props.input.onChange(
                              this.props.input.value.filter(id => Number(id) !== Number(merchant.id))
                            )
                          }}
                        >
                          x
                        </span>
                      </div>
                    ) : null
                  })}
              </div>
              <span className="clear-action btn_link" onClick={() => this.props.input.onChange([])}>
                Clear
              </span>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    autocompleteMerchants: state.session.autocompleteMerchants || [],
    merchants: state.merchants.data || {},
    isMerchantsFilterVisible: !!state.session.isMerchantsFilterVisible
  }
}

export default connect(mapStateToProps, {
  confirmModal,
  fetchAutocompleteMerchants,
  fetchSelectedMerchants,
  toggleContainerVisibility: visible => {
    return updateSession({ isMerchantsFilterVisible: visible })
  }
})(MerchantsInput)
