import React from 'react'
import PropTypes from 'prop-types'

import { Button, Glyphicon } from 'react-bootstrap'

const MetricHeader = ({ title, disabled, refreshGraph }) => {
  return (
    <div className="qc-metrics-widget-header">
      <span className="qc-metrics-widget-header-label">{title}</span>
      <Button bsStyle="default" bsSize="small" className="ml-2" onClick={refreshGraph} disabled={disabled}>
        <Glyphicon glyph="refresh" />
      </Button>
    </div>
  )
}

MetricHeader.displayName = 'MetricHeader'

MetricHeader.propTypes = {
  title: PropTypes.string,
  refreshGraph: PropTypes.func,
  disabled: PropTypes.bool
}

export default MetricHeader
