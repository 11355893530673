import React, { Component } from 'react'
import PropTypes from 'prop-types'
import MerchantsFilter from './merchants-filter-template'
import MinMaxDateFilter from './min-max-date-filter-template'

export default class Meal extends Component {
  static propTypes = {
    feed: PropTypes.object
  }

  render() {
    return (
      <div>
        <MerchantsFilter feed={this.props.feed} />
        <MinMaxDateFilter feed={this.props.feed} />
      </div>
    )
  }
}
