import React from 'react'
import PropTypes from 'prop-types'
import { Backdrop } from '@mui/material'
import Loader from 'components/Loader/Loader'

/**
 * This general component for showing loading overlay and progress icon
 */
const LoadingOverlay = ({ open }) => {
  if (!open) return null

  return (
    <Backdrop
      open={open}
      sx={{ position: 'absolute', zIndex: theme => theme.zIndex.drawer + 1, backgroundColor: 'rgba(0, 0, 0, 0.1)' }}
    >
      <Loader height={40} />
    </Backdrop>
  )
}

LoadingOverlay.propTypes = {
  open: PropTypes.bool
}

export default LoadingOverlay
