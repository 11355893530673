import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import DocumentTitle from 'react-document-title'

import { Row, Col } from 'react-bootstrap'
import Loader from '../../Loader'
import DataTable from './../../DataTable'
import buildClientColumns from './client-columns'
import ClientForm from './ClientForm'

import { fetchClients, saveClient, deleteClient, downloadClients } from '../../../actions/dm-actions'
import { addFormModal, confirmModal } from '../../../actions/modal-actions'
import { appName } from '../../../constants/constants'
import UserService from '../../../services/user-service'

class ClientsPage extends Component {
  componentDidMount() {
    this.fetchClients({ showInactive: this.props.showInactive })
  }

  openClientForm = client => {
    this.props.addFormModal({
      id: 'client-modal',
      className: 'client-edit-modal',
      title: client ? 'Client' : 'New Client',
      content: <ClientForm client={client} />
    })
  }

  confirmDeleteForm = client => {
    const { isPortalAdmin, confirmModal, deleteClient } = this.props
    if (isPortalAdmin) {
      confirmModal(
        `Are you sure you want to delete Client - "${client.name}" ? It will also delete all the Contracts, Deliverables and Deliveries under it. This action cannot be undone.`,
        `Delete "${client.name}"`,
        { okButton: 'Delete' },
        () => deleteClient(client.id)
      )
    } else {
      confirmModal(
        `You do not have permission to delete Client - "${client.name}". Please contact portal admin to delete it.`,
        `Oops!`,
        { okButton: null }
      )
    }
  }

  onCreateNew = () => {
    this.openClientForm()
  }

  onToggleStatus = client => {
    this.props.saveClient({ id: client.id, isActive: !client.isActive })
  }

  onSortChange = (sortBy, sortOrder) => {
    this.fetchClients({
      sortBy,
      sortOrder,
      page: 1,
      showInactive: this.props.showInactive
    })
  }

  onPageChange = page => {
    this.fetchClients({
      page,
      showInactive: this.props.showInactive
    })
  }

  trClassName = row => {
    return row.isActive === false ? 'inactive' : ''
  }

  fetchClients = payload => {
    const {
      sortBy = this.props.sortBy,
      sortOrder = this.props.sortOrder,
      page = this.props.page,
      pageSize = this.props.pageSize,
      showInactive = this.props.showInactive
    } = payload || {}
    this.props.fetchClients({
      sortBy,
      sortOrder,
      page,
      pageSize,
      showInactive
    })
  }

  handleDownload = clickEvent => {
    clickEvent.preventDefault()
    this.props.downloadClients()
  }

  render() {
    const { page = 1, data, totalClientCount = 0, pageSize, sortBy, sortOrder, loading, documentTitle } = this.props
    const maxPages = Math.ceil(totalClientCount / pageSize) || 1
    const columns = buildClientColumns({
      onEdit: this.openClientForm,
      onToggleStatus: this.onToggleStatus,
      onDelete: this.confirmDeleteForm
    })
    return (
      <DocumentTitle title={documentTitle ? `${appName} | ${documentTitle}` : appName}>
        <div>
          {loading && <Loader overlap />}

          <Row>
            <Col className="action-row">
              <button
                onClick={this.handleDownload}
                className="btn_custom_secondary download-csv-button-custom  pull-right"
              >
                Download CSV
              </button>

              <button className={'btn_custom pull-right'} type="button" onClick={this.onCreateNew}>
                {`Create New Client`}
              </button>
            </Col>
          </Row>

          <DataTable
            ref={el => {
              this.dataTable = el
            }}
            data={data}
            trClassName={this.trClassName}
            tableClassName="clients-table"
            columns={columns}
            page={page}
            maxPages={maxPages}
            onPageChange={this.onPageChange}
            sortName={sortBy}
            sortOrder={sortOrder}
            onSortChange={this.onSortChange}
          />
        </div>
      </DocumentTitle>
    )
  }
}

ClientsPage.propTypes = {
  totalClientCount: PropTypes.number,
  page: PropTypes.number,
  pageSize: PropTypes.number,
  sortBy: PropTypes.string,
  sortOrder: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool,
  fetchClients: PropTypes.func,
  addFormModal: PropTypes.func,
  confirmModal: PropTypes.func,
  saveClient: PropTypes.func,
  deleteClient: PropTypes.func,
  isPortalAdmin: PropTypes.bool,
  documentTitle: PropTypes.string,
  showInactive: PropTypes.bool,
  downloadClients: PropTypes.func
}

ClientsPage.defaultProps = {
  documentTitle: 'Delivery Manager - Clients'
}

function mapStateToProps({ dm: { clients, totalClientCount, showInactive }, session: { user } = {} }) {
  return {
    totalClientCount,
    page: clients.page,
    pageSize: clients.pageSize,
    sortBy: clients.sortBy,
    sortOrder: clients.sortOrder,
    loading: clients.loading,
    data: clients.data,
    isPortalAdmin: user ? UserService.isPortalAdmin(user) : false,
    showInactive
  }
}
export default connect(mapStateToProps, {
  fetchClients,
  addFormModal,
  confirmModal,
  saveClient,
  deleteClient,
  downloadClients
})(ClientsPage)
