import React from 'react'
import PropTypes from 'prop-types'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import ValidationIcon, { CELL_TYPE } from './ValidationIcon'

import './styles.scss'

/**
 * EditReviewTable Component
 * @param {Array} tableData - Array of objects containing the data to be displayed in the table
 * @param {Number} nofChanges - total number or changed rows
 * @param {Array} incorrectPairs - Object with info about incorrect pairs
 */
const EditReviewTable = ({ tableData, noOfChanges, incorrectPairs = {} }) => {
  const msg = tableData.length
    ? `Are you sure you want to save ${noOfChanges} changes made to ${tableData.length} descriptions?`
    : 'No records to save'

  const brandFormatter = (_cell, row) => (
    <div>
      <span className={row.indictBrand ? 'in-dict' : 'not-in-dict'}>{row.brand}</span>
      {row.newbrand && <span className="in-staging">{` (${row.newbrand})`}</span>}
      <ValidationIcon type={CELL_TYPE.BRAND} reason={incorrectPairs[row.id]} />
    </div>
  )

  const categoryFormatter = (_cell, row) => (
    <div>
      <span className={row.indictCategory ? 'in-dict' : 'not-in-dict'}>{row.category}</span>
      {row.newcategory && <span className="in-staging">{` (${row.newcategory})`}</span>}
      <ValidationIcon type={CELL_TYPE.CATEGORY} reason={incorrectPairs[row.id]} />
    </div>
  )

  const highlightWrongRows = row => (incorrectPairs[row.id] ? 'highlight-incorrect-row' : '')

  const getOptions = () => ({
    sizePerPageList: [
      { text: '25', value: 25 },
      { text: '50', value: 50 },
      { text: '100', value: 100 }
    ],
    sizePerPage: 25
  })

  return (
    <div>
      <div className="editReviewDescription">{msg}</div>
      <div className="editReviewTable">
        <BootstrapTable
          data={tableData}
          striped
          pagination
          condensed
          options={getOptions()}
          trClassName={highlightWrongRows}
        >
          <TableHeaderColumn dataField="id" isKey dataAlign="right" width="50">
            #
          </TableHeaderColumn>
          <TableHeaderColumn dataField="description" dataSort>
            Description
          </TableHeaderColumn>
          <TableHeaderColumn dataField="merchant" dataSort>
            Merchant
          </TableHeaderColumn>
          <TableHeaderColumn dataField="brand" dataAlign="center" dataFormat={brandFormatter} dataSort>
            Brand
          </TableHeaderColumn>
          <TableHeaderColumn dataField="category" dataAlign="center" dataFormat={categoryFormatter} dataSort>
            Category
          </TableHeaderColumn>
        </BootstrapTable>
      </div>
    </div>
  )
}

EditReviewTable.propTypes = {
  tableData: PropTypes.arrayOf(PropTypes.object).isRequired,
  noOfChanges: PropTypes.number.isRequired,
  incorrectPairs: PropTypes.object
}

export default EditReviewTable
