import React from 'react'
import PropTypes from 'prop-types'

import { FormGroup, ControlLabel, HelpBlock } from 'react-bootstrap'
import ChipSelector from '../../ChipSelector'

export default function ChipSelectField({ id, label, help, validationState, required, ...otherProps }) {
  return (
    <FormGroup controlId={id} validationState={validationState}>
      <ControlLabel className={required ? 'required' : ''}>{label}</ControlLabel>
      <ChipSelector {...otherProps} />
      {help && <HelpBlock>{help}</HelpBlock>}
    </FormGroup>
  )
}

ChipSelectField.defaultProps = {
  autoFocus: false
}

ChipSelectField.propTypes = {
  id: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  help: PropTypes.string,
  validationState: PropTypes.string,
  required: PropTypes.bool,
  autoFocus: PropTypes.bool
}
