export const fields = [
  { name: 'template', label: 'Template' },
  { name: 'from_addr', label: 'From' },
  { name: 'domain', label: 'Domain' },
  { name: 'bbx_supra', label: 'Type' },
  { name: 'source', label: 'Source' },
  { name: 'cnt_msg', label: 'Messages' },
  { name: 'percentage_msg', label: 'Traffic' },
  { name: 'cnt_mailbox', label: 'Mailboxes' },
  { name: 'percentage_msg_total_cusum', label: 'Quantile' }
]

const descriptionOperators = [
  { name: 'regex', label: 'regex' }, // for regex
  { name: 'notRegex', label: 'not regex' }, // must not match given regex
  { name: 'contains', label: 'contains' }, // for root word and associates
  { name: 'notContains', label: 'not contains' },
  { name: 'exact', label: 'is' },
  { name: 'notMatches', label: 'is not' },
  { name: 'startsWith', label: 'starts with' },
  { name: 'notStartsWith', label: 'not starts with' },
  { name: 'endsWith', label: 'ends with' },
  { name: 'notEndsWith', label: 'not ends with' }
]

const bcmOperators = [
  { name: 'matches', label: 'is' },
  { name: 'notMatches', label: 'is not' }
]

const numericOperators = [
  { name: 'lt', label: '<' },
  { name: 'gt', label: '>' },
  { name: 'lte', label: '<=' },
  { name: 'gte', label: '>=' },
  { name: 'eq', label: '=' },
  { name: 'neq', label: '!=' }
]

export const combinators = [
  { name: 'and', label: 'ALL OF ' },
  { name: 'or', label: 'ANY OF ' }
]
const fieldOperators = {
  template: descriptionOperators,
  from_addr: descriptionOperators,
  domain: descriptionOperators,
  source: bcmOperators,
  email_type: bcmOperators,
  cnt_msg: numericOperators,
  percentage_msg: numericOperators,
  percentage_msg_total_cusum: numericOperators,
  cnt_mailbox: numericOperators
}

export const controlClassnames = {
  queryBuilder: 'search-query-builder portal-query-builder', // Root <div> element
  combinators: 'qb-combinators btn-md', // <select> control for combinators
  removeGroup: 'ab-remove-group btn-link btn-xs', // <button> to remove a RuleGroup
  fields: 'qb-fields btn-xs', // <select> control for fields
  operators: 'qb-operators btn-xs', // <select> control for operators
  value: 'qb-value btn-xs', // <input> for the field value
  removeRule: 'qb-remove-rule btn-link btn-xs', // <button> to remove a Rule
  disableToggle: 'qb-disable-toggle btn-link btn-xs',
  emptyQueryTree: 'qb-empty-query-tree btn-link btn-xs', // <button> to clear query tree
  getQuerySQL: 'qb-get-query-sql btn-link btn-xs', // <button> to get Query SQL
  repeatRule: 'qb-repeat-rule btn-link btn-xs'
}

export const getOperators = field => fieldOperators[field]
