import React, { Component } from 'react'
import PropTypes from 'prop-types'
import SelectedValue from './SelectedChip'
import SearchTypeahead from '../SearchTypeahead'
import cx from 'classnames'
import './index.scss'

const NOOP = () => {}

class ChipSelector extends Component {
  onRemoveValue = (id, value) => {
    const { selectedValues, multiple, readOnly } = this.props
    if (readOnly) {
      return
    }
    let selected
    if (multiple) {
      const { labelKey } = this.props
      selected = selectedValues.filter(option => {
        return option.id !== id && option[labelKey] !== value
      })
    } else {
      selected = null
    }
    this.props.onSelectionChange(selected)
    setTimeout(() => {
      this.searchComp && this.searchComp.focus()
    }, 0)
  }

  removeAllValues = () => {
    if (this.props.readOnly) {
      return
    }
    this.props.onSelectionChange([])
  }

  onChange = values => {
    const { multiple } = this.props
    if (!multiple) {
      this.props.onSelectionChange(values.length ? values[0] : null)
      return
    }
    this.props.onSelectionChange(values)
  }

  renderRemoveAllButton = () => {
    const { multiple, showRemoveAllButton, selectedValues } = this.props
    return (
      multiple &&
      showRemoveAllButton &&
      selectedValues.length > 0 && (
        <div className="remove-all-values-wrapper">
          <span className="remove-all-values" onClick={this.removeAllValues}>
            {`REMOVE ALL (${selectedValues.length})`}
          </span>
        </div>
      )
    )
  }

  renderSingleSelectValue = () => {
    const { selectedValue, labelKey, readOnly, disabled } = this.props
    return (
      <div className="rbt-input form-control rbt-input-multi single-select-value" disabled={disabled || readOnly}>
        <div className="rbt-input-wrapper">
          <SelectedValue
            id={selectedValue.id}
            value={selectedValue[labelKey]}
            onRemoveValue={this.onRemoveValue}
            readOnly={disabled || readOnly}
          />
        </div>
      </div>
    )
  }

  renderSearchTypeAhead = () => {
    const {
      selectedValue,
      onSearchChange,
      onMenuShow,
      searchSuggestions,
      placeholder,
      renderSearchSuggestion,
      autoFocus,
      multiple,
      labelKey,
      disabled,
      readOnly,
      minLength,
      isLoading
    } = this.props
    let { selectedValues = [] } = this.props
    if (!multiple) {
      selectedValues = selectedValue ? [selectedValue] : []
    }
    return (
      <div className="chips-search-box">
        <SearchTypeahead
          onInputChange={onSearchChange}
          suggestions={searchSuggestions}
          labelKey={labelKey}
          onChange={this.onChange}
          onMenuShow={onMenuShow}
          renderSuggestions={renderSearchSuggestion}
          multiple // Since multiple=false is handled outside of SearchTypeahead
          placeholder={placeholder}
          autoFocus={autoFocus}
          ref={el => {
            this.searchComp = el
          }}
          selectedValues={selectedValues}
          disabled={disabled || readOnly}
          minLength={minLength}
          isLoading={isLoading}
        />
      </div>
    )
  }

  render() {
    const { className, selectedValue, multiple } = this.props
    return (
      <div className={cx('chips-selector', className)}>
        {this.renderRemoveAllButton()}
        {multiple || !selectedValue
          ? this.renderSearchTypeAhead() // if any more values needs to be searched
          : this.renderSingleSelectValue() // if no more values can be selected
        }
      </div>
    )
  }
}

ChipSelector.defaultProps = {
  onSelectionChange: NOOP,
  onSearchChange: NOOP,
  multiple: false,
  labelKey: 'value',
  selectedValues: [],
  placeholder: 'Search',
  autoFocus: false,
  readOnly: false
}

ChipSelector.propTypes = {
  className: PropTypes.string,
  selectedValue: PropTypes.object,
  selectedValues: PropTypes.arrayOf(PropTypes.object),
  onSelectionChange: PropTypes.func,
  onSearchChange: PropTypes.func,
  onMenuShow: PropTypes.func,
  searchSuggestions: PropTypes.arrayOf(PropTypes.object),
  renderSearchSuggestion: PropTypes.func,
  placeholder: PropTypes.string,
  autoFocus: PropTypes.bool,
  multiple: PropTypes.bool,
  labelKey: PropTypes.string,
  showRemoveAllButton: PropTypes.bool,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  minLength: PropTypes.number,
  isLoading: PropTypes.bool
}

export default ChipSelector
