import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Modal, Button } from 'react-bootstrap'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import PropTypes from 'prop-types'
import InfoTooltip from 'components/Tooltips/InfoTooltip'
import { closeMetricsModal } from '../slices'
import { metricsSupraSamplingCalc } from '../utils'
import '../style.scss'

const SamplingQCMetricsModal = ({ getCurrentData }) => {
  const dispatch = useDispatch()
  const handleClose = () => dispatch(closeMetricsModal())
  const { show } = useSelector(state => state.supraQCToolV2.metricsModal)
  const { page } = useSelector(state => state.supraQCToolV2.samplingSessionsTab)

  const currentRows = getCurrentData()
  const metricsData = metricsSupraSamplingCalc(currentRows)

  return (
    <Modal show={show} onHide={handleClose} bsClass="modal">
      <Modal.Header>
        <Modal.Title>Sampling QC Metrics</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex flex-column">
          <h5>
            Page {page}. Selected sessions {metricsData[0].count}
          </h5>
          <div className="mt-3">
            <BootstrapTable data={metricsData} striped hover condensed bordered responsive>
              <TableHeaderColumn dataField="sequence" hidden isKey>
                Sequence
              </TableHeaderColumn>
              <TableHeaderColumn key="templates_cnt" tdStyle={{ whiteSpace: 'normal' }} dataField="defined_templates">
                Defined Templates
                <InfoTooltip text="% Templates in sample defined / total templates" />
              </TableHeaderColumn>
              <TableHeaderColumn key="quantile" tdStyle={{ whiteSpace: 'normal' }} dataField="defined_templates_pct">
                Traffic Defined
                <InfoTooltip text="% Sum of all defined templates traffic message / sessions count" />
              </TableHeaderColumn>
              <TableHeaderColumn
                key="pct_traffic_100_templates"
                tdStyle={{ whiteSpace: 'normal' }}
                dataField="pct_traffic_100_templates"
              >
                100 Templates
                <InfoTooltip text="% Sum of traffic message % for the top 100 templates ordered by traffic message / sessions count" />
              </TableHeaderColumn>
              <TableHeaderColumn
                key="dictionary_conflicts"
                tdStyle={{ whiteSpace: 'normal' }}
                dataField="dictionary_conflicts"
              >
                Conflicts
                <InfoTooltip text="% Templates with dictionary conflicts / total templates" />
              </TableHeaderColumn>
            </BootstrapTable>
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button onClick={handleClose}>Close</Button>
      </Modal.Footer>
    </Modal>
  )
}

SamplingQCMetricsModal.propTypes = {
  getCurrentData: PropTypes.func
}

export default SamplingQCMetricsModal
