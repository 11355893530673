import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'

import { Button } from 'react-bootstrap'

import QuerySuggestion from 'components/Suggestion/QuerySuggestion'
import { METRICS_STORE_PATH } from './../metricsReducer'
import metricsActions from './../metricsActions'
import useCategoryInfo from './useCategoryInfo'

const MetricQuery = ({ queryType }) => {
  const dispatch = useDispatch()

  const { categoryId, shortCategoryFullPath } = useCategoryInfo()
  const selectedQuery = useSelector(state => state[METRICS_STORE_PATH][queryType])

  const [groupQueryId, setGroupQueryId] = useState(selectedQuery.groupQueryId)
  const [groupQueryLabel, setGroupQueryLabel] = useState(selectedQuery.groupQueryLabel)
  const [queryId, setQueryId] = useState(selectedQuery.queryId)
  const [queryLabel, setQueryLabel] = useState(selectedQuery.queryLabel)

  const groupQueryChangeHandler = newValue => {
    setGroupQueryId(newValue?.id ?? null)
    setGroupQueryLabel(newValue?.name ?? '')
    if (!newValue) {
      setQueryId(null)
      setQueryLabel('')
    }
  }

  const queryChangeHandler = newValue => {
    setQueryId(newValue?.id ?? null)
    setQueryLabel(newValue?.name ?? '')
  }

  const saveGraphHandler = () => {
    dispatch(
      metricsActions.saveQuery({
        categoryId,
        shortCategoryFullPath,
        groupQueryLabel,
        groupQueryId,
        queryLabel,
        queryId,
        queryType
      })
    )
  }

  const removeGraphHandler = () => {
    dispatch(
      metricsActions.removeQuery({
        categoryId,
        queryType
      })
    )
  }

  const isSaveActive = queryId && queryId !== selectedQuery.queryId
  const isRemoveActive = selectedQuery.queryId
  return (
    <div className="qc-metrics-widget-query">
      <QuerySuggestion
        groupQueryId={groupQueryId}
        groupQueryLabel={groupQueryLabel}
        queryId={queryId}
        queryLabel={queryLabel}
        changeGroupQueryCallback={groupQueryChangeHandler}
        changeQueryCallback={queryChangeHandler}
        inline
      />

      <div className="ml-2 qc-metrics-widget-query-buttons">
        <Button className="btn_custom btn_medium" disabled={!isSaveActive} onClick={saveGraphHandler}>
          Save & Graph
        </Button>

        <Button
          className="btn_custom btn_custom_bg_red btn_medium"
          disabled={!isRemoveActive}
          onClick={removeGraphHandler}
        >
          Remove Graph
        </Button>
      </div>
    </div>
  )
}

MetricQuery.propTypes = {
  groupQueryId: PropTypes.number,
  groupQueryLabel: PropTypes.string,
  queryId: PropTypes.number,
  queryLabel: PropTypes.string,
  queryType: PropTypes.string,
  categoryLabel: PropTypes.string,
  categoryId: PropTypes.number
}

const MetricQueryMemo = React.memo(MetricQuery)
MetricQueryMemo.displayName = 'MetricQuery'

export default MetricQueryMemo
