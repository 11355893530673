import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import { Form, Button } from 'react-bootstrap'
import TextareaModal from '../../../Modals/TextareaModal'

import { addCustomModal, confirmModal } from '../../../../actions/modal-actions'

import { default as SubStep } from './DeliverySubStep'

const subStepsConfig = {
  updateQcReport: {
    texts: {
      COMPLETED: 'QC report updated with table name',
      FAILED: 'QC report update failed',
      PENDING: 'QC report update pending',
      PROCESSING: 'Updating QC report...',
      SKIPPED: 'No QC Report linked'
    }
  },
  refreshQcFilters: {
    texts: {
      COMPLETED: 'QC report filters are successfully updated',
      FAILED: 'QC report filter refresh failed',
      PENDING: 'QC report filter refresh pending',
      PROCESSING: 'QC report filters are updating...',
      SKIPPED: 'QC report filter refresh - N/A'
    }
  },
  waitingForApproval: {
    texts: {
      COMPLETED: 'QC approved',
      FAILED: 'QC rejected',
      PENDING: 'Waiting for approval',
      SKIPPED: 'QC report approval - N/A',
      '': 'QC report not ready for approval'
    }
  }
}

const substeps = ['updateQcReport', 'refreshQcFilters', 'waitingForApproval']

const getLabel = (substep, state = '') => {
  return subStepsConfig[substep].texts[state]
}

class QCPanel extends Component {
  renderActions = () => {
    const { qcApprovalStatus, editable, canReviewQc } = this.props

    if (!editable || qcApprovalStatus !== 'PENDING') {
      return null
    }

    return (
      <div className="sb-form-footer">
        {this.renderViewReport()}

        <div className="form-action-buttons">
          <Button
            className="btn_custom btn-small"
            disabled={!canReviewQc}
            onClick={() => this.handleApproveReject(true)}
          >
            Approve
          </Button>

          <Button
            disabled={!canReviewQc}
            onClick={() => this.handleApproveReject(false)}
            className="btn_custom btn-small btn_custom_bg_red"
          >
            Reject
          </Button>
        </div>
      </div>
    )
  }

  renderViewReport() {
    const { qcReportId } = this.props
    return (
      <div className="place-left">
        <Link target="_blank" to={`/custom/reports/view/${qcReportId}`}>
          View QC report
        </Link>
        <Link className="link" target="_blank" to={`/admin/reports/edit/${qcReportId}`}>
          Edit QC report
        </Link>
      </div>
    )
  }

  handleApproveReject = isApproved => {
    const { addCustomModal, approveQC, confirmModal } = this.props
    const onSubmit = (notes = '') => approveQC(isApproved, notes)
    if (isApproved) {
      confirmModal(
        `Are you sure you to approve this report and push this delivery to prod?`,
        'Confirm push to prod',
        { okButton: 'Yes, I approve' },
        onSubmit
      )
    } else {
      addCustomModal({
        modalType: 'errorModal',
        id: 'reject-qc-modal',
        className: 'textarea-modal',
        title: 'Reject QC Report',
        content: (
          <TextareaModal
            onSubmit={onSubmit}
            subtitle={'Please enter reason for rejection below.'}
            submitText={'Reject Report'}
          />
        )
      })
    }
  }

  render() {
    const { qcApprovalStatus, tasks, substepDetails, onToggleDetails, retryDeliveryStep, editable, notes } = this.props

    return (
      <Form className="sb-form delivery-qc-panel">
        {qcApprovalStatus === 'REJECTED' && <div className="beta-alert">{notes}</div>}
        {substeps.map(substep => {
          const { status, output: details } = tasks[substep]
          const label = getLabel(substep, status)

          return (
            <SubStep
              key={substep}
              step="qc"
              substep={substep}
              label={label}
              hideRetry={!editable || substep === 'waitingForApproval'}
              showDetails={substepDetails.selectedSubstep === substep && substepDetails.show}
              status={qcApprovalStatus === 'SKIPPED' ? 'COMPLETED' : status}
              details={status === 'FAILED' ? details : null}
              onToggleDetails={onToggleDetails}
              onRetry={retryDeliveryStep}
            />
          )
        })}

        {this.renderActions()}
      </Form>
    )
  }
}

QCPanel.propTypes = {
  qcApprovalStatus: PropTypes.string,
  tasks: PropTypes.object,
  substepDetails: PropTypes.object,
  qcReportId: PropTypes.number,
  onToggleDetails: PropTypes.func,
  retryDeliveryStep: PropTypes.func,
  approveQC: PropTypes.func,
  editable: PropTypes.bool,
  canReviewQc: PropTypes.bool,
  addCustomModal: PropTypes.func,
  confirmModal: PropTypes.func,
  notes: PropTypes.string
}

export default connect(null, { addCustomModal, confirmModal })(QCPanel)
