import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Form, FormGroup, FormControl } from 'react-bootstrap'
import DateRangeFilter from '../DateRangeFilter'

const filters = [
  {
    key: 'merchant_name',
    value: 'Merchants'
  },
  {
    key: 'brand_name',
    value: 'Brands'
  }
]

const timeIntervals = [
  {
    key: 'month',
    value: 'Month'
  },
  {
    key: 'week',
    value: 'Week'
  },
  {
    key: 'day',
    value: 'Day'
  }
]

const fields = [
  {
    key: 'revenue',
    value: 'Revenue'
  },
  {
    key: 'count',
    value: 'Count'
  }
]

class OverTimeToolbar extends Component {
  static propTypes = {
    selectedInterval: PropTypes.string,
    selectedFilter: PropTypes.string,
    onTimeAggregationChanged: PropTypes.func,
    onTimeDimensionChanged: PropTypes.func,
    selectedField: PropTypes.string,
    aggType: PropTypes.string,
    aggregation: PropTypes.arrayOf(PropTypes.object),
    from: PropTypes.string,
    to: PropTypes.string,
    rangeStart: PropTypes.string,
    rangeEnd: PropTypes.string,
    handleDateSelection: PropTypes.func
  }

  onFilterChange(e) {
    const value = e.target.value
    const aggregation = [
      { fieldName: value },
      { fieldName: 'order_date', timeInterval: this.props.selectedInterval },
      { fieldName: 'revenue' }
    ]
    this.props.onTimeAggregationChanged(aggregation, this.props.aggType, value, this.props.selectedInterval)
  }

  onIntervalChange(e) {
    const value = e.target.value
    const aggregation = [
      { fieldName: this.props.selectedFilter },
      { fieldName: 'order_date', timeInterval: value },
      { fieldName: 'revenue' }
    ]
    this.props.onTimeAggregationChanged(aggregation, this.props.aggType, this.props.selectedFilter, value)
  }

  onFieldChange(e) {
    this.props.onTimeDimensionChanged(e.target.value, this.props.aggregation, this.props.aggType)
  }

  render() {
    const renderDropdowns = data => {
      return data.map(opt => {
        return (
          <option key={opt.key} value={opt.key}>
            {opt.value}
          </option>
        )
      })
    }

    return (
      <div className="pull-right">
        <Form inline>
          <FormGroup controlId="field-selection">
            <FormControl
              componentClass="select"
              placeholder="select"
              defaultValue={this.props.selectedField}
              onChange={this.onFieldChange.bind(this)}
            >
              {renderDropdowns(fields)}
            </FormControl>
          </FormGroup>{' '}
          <FormGroup controlId="filter-selection">
            <FormControl
              componentClass="select"
              placeholder="select"
              defaultValue={this.props.selectedFilter}
              onChange={this.onFilterChange.bind(this)}
            >
              {renderDropdowns(filters)}
            </FormControl>
          </FormGroup>{' '}
          <FormGroup controlId="interval-selection">
            <FormControl
              componentClass="select"
              placeholder="select"
              defaultValue={this.props.selectedInterval}
              onChange={this.onIntervalChange.bind(this)}
            >
              {renderDropdowns(timeIntervals)}
            </FormControl>
          </FormGroup>
          <DateRangeFilter
            className="widget-filters-daterange"
            handleDateSelection={this.props.handleDateSelection}
            rangeStart={this.props.rangeStart}
            rangeEnd={this.props.rangeEnd}
            startDate={this.props.from}
            endDate={this.props.to}
          />
        </Form>
      </div>
    )
  }
}

export default OverTimeToolbar
