import React from 'react'
import PropTypes from 'prop-types'
import { Glyphicon, OverlayTrigger, Tooltip } from 'react-bootstrap'

import { useDispatch } from 'react-redux'
import { addCustomModal, confirmModal } from 'actions/modal-actions'

import categoryTestsActions from './../categoryTestsActions'
import CategoryTestEditModal from './../Modals/Edit/CategoryTestEditModal'

const ActionsCell = ({ row }) => {
  const dispatch = useDispatch()

  const runTestsHandler = id => {
    dispatch(
      categoryTestsActions.runTests({
        id
      })
    )
  }

  const runModal = () => {
    const { id, categoryFullPath } = row

    dispatch(
      confirmModal(
        <div>
          <span>
            {`Are you sure you want to run tests for Category `}
            <strong>
              <em>{categoryFullPath}</em>
            </strong>
            &nbsp; (ID:{id}) ?
          </span>
        </div>,
        'Confirm Run',
        { okButton: `Run tests` },
        () => runTestsHandler(id)
      )
    )
  }

  const openEditModal = () => {
    const { id, categoryFullPath } = row
    dispatch(
      addCustomModal({
        modalType: 'successModal',
        id: 'edit-modal',
        title: `Edit Category Test for "${categoryFullPath}" (ID:${id})`,
        size: 'large',
        content: <CategoryTestEditModal value={row} />
      })
    )
  }

  // TODO create a component icon + tooltip
  return (
    <div className="sb-table-action-cell">
      <OverlayTrigger placement="left" overlay={<Tooltip id="run-tooltip">Run tests</Tooltip>}>
        <div onClick={runModal}>
          <Glyphicon glyph="play" />
        </div>
      </OverlayTrigger>
      <OverlayTrigger
        placement="left"
        overlay={<Tooltip id="edit-configuration-tooltip">Change configuration</Tooltip>}
      >
        <div onClick={openEditModal}>
          <Glyphicon glyph="cog" />
        </div>
      </OverlayTrigger>
    </div>
  )
}

ActionsCell.propTypes = {
  row: PropTypes.object.isRequired
}

export default ActionsCell
