import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Form } from 'react-bootstrap'
import { default as SubStep } from './DeliverySubStep'
import { Link } from 'react-router-dom'
import { last } from 'lodash'

const subStepsConfig = {
  createTable: {
    showOutput: true,
    texts: {
      COMPLETED: 'Prod Table created',
      FAILED: 'Prod Table creation failed',
      PENDING: 'Prod Table creation pending',
      PROCESSING: 'Creating Prod Table...'
    }
  },
  createExcludedView: {
    showOutput: true,
    texts: {
      COMPLETED: 'Prod View (excluded data)  created',
      FAILED: 'Prod View (excluded data) creation failed',
      PENDING: 'Prod View (excluded data) creation pending',
      PROCESSING: 'Creating Prod View (excluded data)...'
    }
  },
  createNonExcludedView: {
    showOutput: true,
    texts: {
      COMPLETED: 'Prod View (non-excluded data) created',
      FAILED: 'Prod View (non-excluded data) creation failed',
      PENDING: 'Prod View (non-excluded data) creation pending',
      PROCESSING: 'Creating Prod View (non-excluded data)...'
    }
  },
  grantAccess: {
    showOutput: false,
    texts: {
      COMPLETED: 'Access granted to Prod Table & Views',
      FAILED: 'Grant access failed',
      PENDING: 'Grant access pending',
      PROCESSING: 'Granting access...'
    }
  },
  updateReport: {
    showOutput: false,
    texts: {
      COMPLETED: 'Prod report updated with table name',
      FAILED: 'Prod report update failed',
      PENDING: 'Prod report update pending',
      PROCESSING: 'Updating Prod report...'
    }
  },
  refreshFilters: {
    showOutput: false,
    texts: {
      COMPLETED: 'Prod report filters are successfully updated',
      FAILED: 'Prod report filter refresh failed',
      PENDING: 'Prod report filter refresh pending',
      PROCESSING: 'Prod report filters are updating...'
    }
  }
}

const substeps = [
  'createTable',
  'createExcludedView',
  'createNonExcludedView',
  'grantAccess',
  'updateReport',
  'refreshFilters'
]

const getLabel = (substep, state) => {
  return subStepsConfig[substep].texts[state]
}

export default class ProdPanel extends Component {
  renderViewReport() {
    const { prodReportId } = this.props
    return (
      <div className="place-left">
        <Link target="_blank" to={`/custom/reports/view/${prodReportId}`}>
          View Prod report
        </Link>
        <Link className="link" target="_blank" to={`/admin/reports/edit/${prodReportId}`}>
          Edit Prod report
        </Link>
      </div>
    )
  }

  render() {
    const { editable, tasks, substepDetails, onToggleDetails, retryDeliveryStep } = this.props
    return (
      <Form className="sb-form delivery-prod-panel">
        {substeps.map(substep => {
          const { status, output: details } = tasks[substep]
          const label = getLabel(substep, status)
          const showOutput = subStepsConfig[substep].showOutput
          return (
            <SubStep
              key={substep}
              step="prod"
              substep={substep}
              label={label}
              showDetails={substepDetails.selectedSubstep === substep && substepDetails.show}
              status={status}
              details={status === 'FAILED' || (status === 'COMPLETED' && showOutput) ? details : null}
              onToggleDetails={onToggleDetails}
              onRetry={retryDeliveryStep}
              hideRetry={!editable}
            />
          )
        })}
        {tasks[last(substeps)].status === 'COMPLETED' ? this.renderViewReport() : null}
      </Form>
    )
  }
}

ProdPanel.propTypes = {
  tasks: PropTypes.object,
  substepDetails: PropTypes.object,
  prodReportId: PropTypes.number,
  onRefresh: PropTypes.func,
  onToggleDetails: PropTypes.func,
  retryDeliveryStep: PropTypes.func,
  editable: PropTypes.bool
}
