import React from 'react'
import PropTypes from 'prop-types'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import { Popover, OverlayTrigger, Glyphicon } from 'react-bootstrap'

const RegexUtilityMatchingExamples = ({ examples, totalCount, subtitle }) => {
  let sequence = 1

  const helpContainer = (
    <Popover id="regex-utility-matching-examples-help">
      <p>{subtitle}</p>
    </Popover>
  )

  return (
    <>
      <div>
        <h5 className="text-left">
          Templates: {examples.length}, Total Templates: {totalCount}
          <OverlayTrigger overlay={helpContainer} trigger="click" placement="right">
            <Glyphicon glyph="info-sign" className="ml-2" />
          </OverlayTrigger>
        </h5>
        <BootstrapTable
          data={examples.map(example => ({ ...example, sequence: sequence++ }))}
          striped
          hover
          condensed
          bordered
          responsive
        >
          <TableHeaderColumn dataField="sequence" hidden isKey>
            Sequence
          </TableHeaderColumn>

          <TableHeaderColumn dataField="example" dataSort>
            Subject
          </TableHeaderColumn>
          <TableHeaderColumn dataField="messageUUID" dataSort>
            Message UUID
          </TableHeaderColumn>
        </BootstrapTable>
      </div>
    </>
  )
}

RegexUtilityMatchingExamples.propTypes = {
  examples: PropTypes.arrayOf(PropTypes.object).isRequired,
  percentage: PropTypes.number,
  totalCount: PropTypes.number,
  subtitle: PropTypes.string
}

export default RegexUtilityMatchingExamples
