import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'

import Loader from 'components/Loader'
import { DataTableFiltered, DataTableToolbar, TableToolbarButtons } from 'components/DataTable'
import useColumnsConfig from 'components/DataTable/ColumnsConfig/useColumnsConfig'

import categoryTestsActions from './categoryTestsActions'
import CategoryTestsRunBatchButton from './CategoryTestsRunBatchButton'
import CategoryTestsGlobalConfButton from './CategoryTestsGlobalConfButton'

import categoryTestsColumns from './Table/columns'
import useTableProperties from './Table/useTableProperties'

import { CATEGORY_TEST_STORE_PATH } from './categoryTestsReducers'

import './categoryTests.scss'

const CategoryTests = ({ hasEditAccess }) => {
  const { page, sortBy, loading, rows, pageSize, sortOrder, totalCount, filters } = useTableProperties(
    state => state[CATEGORY_TEST_STORE_PATH]
  )

  const tableId = 'categoryTestTable'
  const { visibleColumns, columnsConfig, key, visibleFilters } = useColumnsConfig(
    categoryTestsColumns,
    tableId,
    filters
  )

  const dispatch = useDispatch()

  const fetchData = payload => dispatch(categoryTestsActions.fetch(payload))

  const clearFilters = () => dispatch(categoryTestsActions.clearFilters())

  const handleDownload = () => dispatch(categoryTestsActions.download())

  return (
    <div className="category-tests-page">
      <DataTableToolbar>
        <TableToolbarButtons
          refreshHandler={fetchData}
          clearFiltersHandler={clearFilters}
          downloadHandler={handleDownload}
        />
        {hasEditAccess && <CategoryTestsRunBatchButton />}
        <CategoryTestsGlobalConfButton />
      </DataTableToolbar>
      {loading && <Loader loading overlap />}
      <DataTableFiltered
        id={tableId}
        key={key}
        data={rows}
        columns={visibleColumns}
        columnsConfig={columnsConfig}
        page={page}
        pageSize={pageSize}
        totalCount={totalCount}
        sortBy={sortBy}
        sortOrder={sortOrder}
        filters={visibleFilters}
        tableClassName="category-tests-table"
        fetchRowsAction={fetchData}
        entityName="tests"
      />
    </div>
  )
}

CategoryTests.propTypes = {
  hasEditAccess: PropTypes.bool.isRequired
}

export default CategoryTests
