import React from 'react'
import { Controller } from 'react-hook-form'
import { FormControl, InputGroup } from 'react-bootstrap'
import PropTypes from 'prop-types'
import InputField from 'components/FormInputComponents/InputField'

const MinTrafficInput = ({ control, errors, info }) => {
  return (
    <InputField
      label="Min Traffic"
      required
      info={info}
      validationMessage={errors.minTraffic?.message}
      className="text-start"
      labelSize={4}
    >
      <Controller
        name="minTraffic"
        control={control}
        render={({ field }) => (
          <InputGroup>
            <FormControl {...field} type="number" step="0.1" />
            <InputGroup.Addon> % </InputGroup.Addon>
          </InputGroup>
        )}
      />
    </InputField>
  )
}

MinTrafficInput.propTypes = {
  control: PropTypes.any,
  errors: PropTypes.object,
  info: PropTypes.string
}

export default MinTrafficInput
