import { useEffect } from 'react'
import { emptyBrandObj } from './CategoryBrandRuleModal'

// this hook adds a new brand to the store after the rule will be checked
const useHandleBrandChange = ({ mode, currentBrand, index, newRule, prevRuleId, isLoading, handleBrandsChange }) => {
  let brand = currentBrand
  let skip = false
  // check cases when the new brand wouldn't need to add
  // eslint-disable-next-line default-case
  switch (true) {
    case mode.isRuleCreate:
    case mode.isRequestCreate:
      if (isLoading || newRule) {
        brand = emptyBrandObj
      }
      break
    case mode.isRuleEdit:
      if (isLoading || (newRule && newRule.id !== prevRuleId)) {
        skip = true
      }
      break
  }

  useEffect(() => {
    if (skip) return
    handleBrandsChange(brand, index)
  }, [brand, index, handleBrandsChange, skip])
}

export default useHandleBrandChange
