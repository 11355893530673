import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import DocumentTitle from 'react-document-title'

import { Grid, Row, Col } from 'react-bootstrap'
import { default as QueryBuilderWrapper } from './QueryBuilderWrapper'
import { default as Content } from '../content/Content'
import { loadDefaultQuery, toggleTreeVisibility, hideTree } from '../../actions/search-actions'
import '../../styles/treeView.scss'
import Unauthorized from '../Unauthorized'
import UserService from '../../services/user-service'
import { products, appName } from '../../constants/constants'
import WithErrorBoundaryWrapper from 'components/WithErrorBoundaryWrapper/WithErrorBoundaryWrapper'

class Search extends Component {
  static propTypes = {
    isTreeVisible: PropTypes.bool,
    loadDefaultQuery: PropTypes.func,
    userProducts: PropTypes.object,
    hideTree: PropTypes.func,
    toggleTreeVisibility: PropTypes.func
  }

  componentDidMount() {
    // Load initial query
    this.props.loadDefaultQuery()
  }

  isUnauthorized = key => {
    return !this.props.userProducts || !this.props.userProducts[key] || !this.props.userProducts[key].enabled
  }

  render() {
    if (this.isUnauthorized(products.adminToolsQCTool)) {
      return <Unauthorized />
    }
    return (
      <DocumentTitle title={this.props.documentTitle ? `${appName} | ${this.props.documentTitle}` : appName}>
        <Grid bsClass="search-root">
          <Row className="show-grid">
            <Col xs={12} md={12}>
              <QueryBuilderWrapper
                isTreeVisible={this.props.isTreeVisible}
                toggleTreeVisibility={this.props.toggleTreeVisibility}
              />
              <div
                onClick={this.props.hideTree}
                className={`overlay
                  ${this.props.isTreeVisible ? 'query-tree-overlay-visible' : 'query-tree-overlay-hidden'}`}
              />
              <Content />
            </Col>
          </Row>
        </Grid>
      </DocumentTitle>
    )
  }
}

Search.propTypes = {
  isTreeVisible: PropTypes.bool,
  loadDefaultQuery: PropTypes.func,
  userProducts: PropTypes.object,
  hideTree: PropTypes.func,
  toggleTreeVisibility: PropTypes.func,
  documentTitle: PropTypes.string
}

Search.defaultProps = {
  documentTitle: 'QC Tool'
}

function mapStateToProps(state) {
  return {
    isTreeVisible: state.search.isTreeVisible,
    userProducts: UserService.getProducts(state.session.user)
  }
}

export default WithErrorBoundaryWrapper(
  connect(mapStateToProps, { loadDefaultQuery, toggleTreeVisibility, hideTree })(Search),
  '"CME QC Tool" page'
)
