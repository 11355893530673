import React from 'react'

export default function DoneCheckMark(props) {
  return (
    <svg
      width="13px"
      height="10px"
      viewBox="0 0 13 10"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      {/* Generator: Sketch 51.2 (57519) - http://www.bohemiancoding.com/sketch */}
      <title>ic-check</title>
      <desc>Created with Sketch.</desc>
      <defs />
      <g id="Components" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g id="Icons" transform="translate(-188.000000, -233.000000)">
          <g id="ic-check" transform="translate(180.000000, 223.000000)">
            <g>
              <rect id="spacing" x={0} y={0} width={30} height={30} />
              <g id="check" transform="translate(8.000000, 10.000000)" fill="currentColor">
                <rect
                  id="Rectangle"
                  transform="translate(3.363961, 6.363961) rotate(-315.000000) translate(-3.363961, -6.363961) "
                  x="0.363961031"
                  y="5.36396103"
                  width={6}
                  height={2}
                />
                <rect
                  id="Rectangle"
                  transform="translate(8.449747, 4.949747) rotate(-45.000000) translate(-8.449747, -4.949747) "
                  x="2.94974747"
                  y="3.94974747"
                  width={11}
                  height={2}
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
