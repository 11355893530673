import React from 'react'
import PropTypes from 'prop-types'

import { RESET_WARNING } from './../constants'

const getNeedToShowResetWarning = (testName, valuesPrev, valuesCurrent) => {
  const isPassKey = `${testName}TestIsPass`
  const isTestHasStatus = valuesPrev[isPassKey] !== null

  // Check isActive value
  const isActiveKey = `${testName}TestIsActive`
  const isTestActive = valuesCurrent[isActiveKey]

  const isTestActivation = isTestActive && !valuesPrev[isActiveKey]

  if (isTestActivation && isTestHasStatus) {
    return true
  }

  //
  // check all other tests values
  //

  const keysAll = Object.keys(valuesCurrent)

  const testKeys = keysAll
    .filter(key => key.startsWith(testName))
    .filter(key => key !== isActiveKey && key !== isPassKey)

  for (const key of testKeys) {
    const prevValue = valuesPrev[key]
    const currentValue = valuesCurrent[key]

    const isValueChanged = currentValue !== prevValue
    if (isValueChanged && isTestActive && isTestHasStatus) {
      return true
    }
  }

  return false
}

const ResetWarning = ({ valuesPrev, valuesCurrent, testNames }) => {
  let isShowResetWarning = false
  for (const testName of testNames) {
    const isNeedWarning = getNeedToShowResetWarning(testName, valuesPrev, valuesCurrent)

    if (isNeedWarning) {
      isShowResetWarning = true
    }
  }

  return <div className="category-tests-modal-reset-container">{isShowResetWarning && RESET_WARNING.LOCAL}</div>
}

ResetWarning.propTypes = {
  testNames: PropTypes.arrayOf(PropTypes.string).isRequired,
  valuesPrev: PropTypes.object.isRequired,
  valuesCurrent: PropTypes.object.isRequired
}

export default ResetWarning
