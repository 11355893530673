/* eslint-disable id-length */
export const LAYOUT_VERSION_NUMBER = 1

export const LAYOUTS = {
  lg: [
    { i: 'description-widget', x: 0, y: 0, w: 6, h: 4, minW: 4 },
    { i: 'category-widget', x: 6, y: 0, w: 3, h: 2 },
    { i: 'brand-widget', x: 9, y: 0, w: 3, h: 2 },
    { i: 'merchant-widget', x: 6, y: 2, w: 3, h: 2 },
    { i: 'firstword-widget', x: 9, y: 2, w: 3, h: 2 },
    { i: 'slice-category-widget', x: 6, y: 4, w: 3, h: 2 },
    { i: 'slice-brand-widget', x: 9, y: 4, w: 3, h: 2 },
    { i: 'invalid-category-widget', x: 0, y: 4, w: 3, h: 2 },
    { i: 'invalid-brand-widget', x: 3, y: 4, w: 3, h: 2 },
    { i: 'omnisales-module-widget', x: 0, y: 6, w: 3, h: 2 }
    // { i: 'stats-widget', x: 0, y: 6, w: 3, h: 1 } // enable in IN-1810
  ],

  md: [
    { i: 'description-widget', x: 0, y: 0, w: 6, h: 4, minW: 4 },
    { i: 'category-widget', x: 6, y: 0, w: 3, h: 2 },
    { i: 'brand-widget', x: 6, y: 2, w: 3, h: 2 },
    { i: 'merchant-widget', x: 3, y: 4, w: 3, h: 2 },
    { i: 'firstword-widget', x: 6, y: 4, w: 3, h: 2 },
    { i: 'slice-category-widget', x: 6, y: 6, w: 3, h: 2 },
    { i: 'slice-brand-widget', x: 6, y: 8, w: 3, h: 2 },
    { i: 'invalid-category-widget', x: 10, y: 6, w: 3, h: 2 },
    { i: 'invalid-brand-widget', x: 12, y: 6, w: 3, h: 2 },
    { i: 'omnisales-module-widget', x: 6, y: 12, w: 3, h: 2 }
    // { i: 'stats-widget', x: 0, y: 4, w: 3, h: 2 } // enable in IN-1810
  ]
}

export const ROW_HEIGHT = 180
export const KEY = 'niq-search-layouts'
