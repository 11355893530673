import { useState } from 'react'
import { TABLE_MUI_ROWS_CURRENT } from 'constants/constants'

const useMUITableState = initialSortModel => {
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: TABLE_MUI_ROWS_CURRENT
  })

  const [sortModel, setSortModel] = useState(initialSortModel || [{}])
  const [filterModel, setFilterModel] = useState({
    items: []
  })

  // this is to intercept calls to the API where value is null
  // this is because if the user clicks on add new filter and has not
  // yet selected a value, it is not necessary to make the API call yet
  // once the provide the value for the field, then we can make the API call
  const setFilterModelWrapperFunction = input => {
    const itemsHasUndefinedValues = input.items.find(item => Boolean(!item.value))
    if (itemsHasUndefinedValues) return
    return setFilterModel(input)
  }

  return {
    paginationModel,
    setPaginationModel,
    sortModel,
    setSortModel,
    filterModel,
    setFilterModel: setFilterModelWrapperFunction
  }
}

export default useMUITableState
