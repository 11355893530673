import React from 'react'

export default function Refresh(props) {
  return (
    <svg
      width="16px"
      height="15px"
      viewBox="0 0 16 15"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>refresh</title>
      <defs />
      <g id="Components" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g id="Icons" transform="translate(-637.000000, -261.000000)">
          <g id="refresh" transform="translate(630.000000, 253.000000)">
            <g>
              <rect id="spacing" x={0} y={0} width={30} height={30} />
              <g
                id="icon"
                transform="translate(16.500000, 15.500000) rotate(25.000000) translate(-16.500000, -15.500000) translate(8.000000, 8.000000)"
                fill="currentColor"
              >
                <polygon
                  id="ic-arrow"
                  transform="translate(11.510581, 5.454961) rotate(45.000000) translate(-11.510581, -5.454961) "
                  points="14.2334833 5.4549614 8.78767928 1.4549614 8.78767928 9.4549614"
                />
                <path
                  d="M7,1 C3.15,1 0,4.15 0,8 C0,11.85 3.15,15 7,15 C10.2375,15 12.95,12.725 14,9.75 L12.25,9.75 C11.2,11.7625 9.275,13.25 7,13.25 C4.1125,13.25 1.75,10.8875 1.75,8 C1.75,5.1125 4.1125,2.75 7,2.75 C8.4875,2.75 9.3997953,3.55598925 10.400145,4.59993008 L11.6887243,3.28110113 C10.3746508,2.06140893 8.925,1 7,1 Z"
                  id="Shape"
                  fillRule="nonzero"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
