import React from 'react'

export default function CloseEye(props) {
  return (
    <svg
      width="22px"
      height="8px"
      viewBox="0 0 22 8"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <defs />
      <g id="Components" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g id="Icons" transform="translate(-454.000000, -236.000000)">
          <g id="close-eye" transform="translate(450.000000, 223.000000)">
            <g>
              <rect id="spacing" x={0} y={0} width={30} height={30} />
              <g id="eye" transform="translate(4.000000, 13.000000)" stroke="currentColor">
                <path
                  d="M0.717409931,0.838415474 C7.65285539,6.19501474 12.5212594,7.00706602 21.1749004,0.838415474"
                  id="Path-2"
                />
                <path d="M1.74455091,5.51525912 L3.88619353,3.29264098" id="Line-5" strokeLinecap="square" />
                <path d="M7.5,7.5 L8,4.93072622" id="Line" strokeLinecap="square" />
                <path d="M14.2452542,7.70246861 L13.3462479,5.02418183" id="Line-2" strokeLinecap="square" />
                <path d="M19.3446797,6.16973861 L17.1565995,3.47577915" id="Line-3" strokeLinecap="square" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
