import { useSelector } from 'react-redux'

const useTableProperties = getTableState => {
  const tableProperties = useSelector(getTableState)

  const properties = {
    page: tableProperties.page,
    sortBy: tableProperties.sortBy,
    loading: tableProperties.loading,
    rows: tableProperties.data.rows,
    pageSize: tableProperties.pageSize,
    sortOrder: tableProperties.sortOrder,
    totalCount: tableProperties.data.count,
    filters: tableProperties.filters
  }
  return properties
}

export default useTableProperties
