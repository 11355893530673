import commonRtkApi from 'commonRtkApi.js'

const categoryBrandRuleAPI = commonRtkApi.injectEndpoints({
  endpoints: builder => ({
    categoryBrandRule: builder.query({
      query: ({ categoryId, brandId }) => {
        const params = {
          page: 1,
          sortBy: 'id',
          pageSize: 1,
          sortOrder: 'asc',
          filters: { categoryId: { value: categoryId }, brandId: { value: brandId } },
          filterType: 'table'
        }

        return { url: `/rops/rules/category-brand`, method: 'GET', params }
      }
    })
  })
})

export const { useCategoryBrandRuleQuery } = categoryBrandRuleAPI
