import { useEffect, useContext, useMemo } from 'react'

import { ModalContext } from 'components/Modals/ModalContext'
import { useCheckCBRMutation } from './rules.api'
import { groupByBrandAndCategory, getIncorrectPairsAsObject } from './utils'

/**
 * This hook is used to check the Category-Brand pairs (rules)
 * @param {Array} changedRows - all changed rows (that have changed brand, category or both)
 * @returns {Object} - information about the checkCBR mutation
 */
const useCheckCBR = changedRows => {
  const [checkCBR, { data, error, isLoading }] = useCheckCBRMutation()

  const { setOkButtonDisabled } = useContext(ModalContext)

  useEffect(() => {
    // Disable the OK button until the data is loaded
    setOkButtonDisabled(true)

    // Check CBR when open the modal
    const newCBRs = changedRows.map(row => ({
      id: row.id,
      brand: row.newbrand || row.brand || '',
      category: row.newcategory || row.category || ''
    }))

    const uniqueCBRs = groupByBrandAndCategory(newCBRs)
    checkCBR(uniqueCBRs)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Enable the OK button when the data is loaded
  useEffect(() => {
    if (data?.incorrectPairs?.length > 0 || isLoading || error) {
      setOkButtonDisabled(true)
    } else {
      setOkButtonDisabled(false)
    }
  }, [data, setOkButtonDisabled, isLoading, error])

  /**
   * @type {Object.<string, string>}
   * @property {String} id - id of the row
   * @property {String} reason - reason why the pair is incorrect
   */
  const incorrectPairs = useMemo(() => getIncorrectPairsAsObject(data?.incorrectPairs), [data])

  return {
    isLoading,
    error,
    incorrectPairs
  }
}

export default useCheckCBR
