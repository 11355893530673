import React from 'react'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { Tooltip, OverlayTrigger } from 'react-bootstrap'

import { TableHeaderColumn } from 'react-bootstrap-table'
import { generateRandomHexColor } from '../../utils/util-color'

const renderDate = cell => (
  <div>
    <div>{moment(cell).calendar()}</div>
    <div className="date-time" title={moment(cell).format('DD MMM, hh:mm a (Z)')}>
      {moment(cell).format('ddd MMM DD, hh:mm a (Z)')}
    </div>
  </div>
)

const renderShortDate = cell => (
  <div>
    <div className="date-time" title={moment(cell).format('LL')}>
      {moment(cell).format('LL')}
    </div>
  </div>
)

const renderGroups = (cell, row) => (
  <div>{row.allGroups ? 'All' : cell.map((group, index) => <div key={index}>{group.groupName}</div>)}</div>
)

const DELIVERY_LOGS_FIELDS = [
  'id',
  'moduleId',
  'deliveryDate',
  'taskId',
  'groupName',
  'rowCount',
  'size',
  'md5Hash',
  'folderPath',
  'fileName'
]

const DATE_SUMMARY_COLUMNS = [
  { field: 'groups', label: 'Groups', isKey: false, dataFormat: renderGroups, filterFormatted: true },
  { field: 'deliveryDt', label: 'Delivery Date', isKey: true, dataFormat: renderShortDate },
  { field: 'beginningTime', label: 'Beginning Time', isKey: false, dataFormat: renderDate },
  { field: 'endingTime', label: 'Ending Time', isKey: false, dataFormat: renderDate },
  { field: 'totalRowCount', label: '# total rows', isKey: false },
  { field: 'totalFilesSent', label: '# files sent', isKey: false }
]

const tableToColumnsMap = {
  feedDeliveries: DELIVERY_LOGS_FIELDS,
  dateSummary: DATE_SUMMARY_COLUMNS
}

const moduleColorMapping = {}

const renderModule = (cell, row) => {
  const { FeedId } = row

  if (moduleColorMapping[FeedId] === undefined) {
    moduleColorMapping[FeedId] = generateRandomHexColor()
  }

  return (
    <Link to={`/admin/feeds/edit/${FeedId}`}>
      <span style={{ color: moduleColorMapping[FeedId] }}>{cell}</span>
    </Link>
  )
}

const renderGroup = (cell, row) => {
  const { GroupId } = row
  return (
    <Link to={`/admin/groups/${GroupId}`}>
      <span>{cell}</span>
    </Link>
  )
}

const getTooltip = (id, cell) => (
  <Tooltip id={`tooltip-${id}-${cell}`} placement="top">
    {cell}
  </Tooltip>
)

const renderWithToolTip = (cell, row) => {
  const { id } = row
  return (
    <OverlayTrigger placement="top" overlay={getTooltip(id, cell)}>
      <span>{cell}</span>
    </OverlayTrigger>
  )
}

const buildColumns = (tableName = 'feedDeliveries') => {
  const getColumn = column => {
    if (column.constructor === Object) {
      return {
        ...column,
        hasSort: false,
        dataAlign: 'left',
        editable: false,
        width: '100px'
      }
    }

    const defaultColumnConfig = {
      label: column,
      field: column,
      hasSort: false,
      dataAlign: 'left',
      editable: false,
      width: '100px'
    }
    let config = {}

    switch (column) {
      case 'id':
        config = {
          label: 'ID',
          width: '50px',
          dataAlign: 'center'
        }
        break

      case 'taskId':
        config = {
          label: 'Task ID',
          width: '150px'
        }
        break

      case 'moduleId':
        config = {
          width: '150px',
          label: 'Module ID',
          dataFormat: renderModule
        }
        break

      case 'deliveryDate':
        config = {
          label: 'Delivery Date',
          width: '150px',
          dataFormat: renderDate
        }
        break

      case 'groupName':
        config = {
          label: 'Group',
          width: '100px',
          dataFormat: renderGroup
        }
        break

      case 'rowCount':
        config = {
          label: 'Row Count',
          width: '50px',
          dataFormat: renderWithToolTip
        }
        break

      case 'size':
        config = {
          label: 'Size',
          width: '50px',
          dataFormat: renderWithToolTip
        }
        break

      case 'md5Hash':
        config = {
          label: 'MD5 Hash',
          width: '150px',
          dataFormat: renderWithToolTip
        }
        break

      case 'folderPath':
        config = {
          label: 'Folder Path',
          width: '150px',
          dataFormat: renderWithToolTip
        }
        break

      case 'fileName':
        config = {
          label: 'Filename',
          width: '150px',
          dataFormat: renderWithToolTip
        }
        break

      default:
        break
    }
    return {
      ...defaultColumnConfig,
      ...config
    }
  }
  const fields = tableToColumnsMap[tableName]
  const columns = fields.map(field => getColumn(field))
  const headerColumns = columns.map((col, index) => {
    return (
      <TableHeaderColumn
        ref={col.field}
        hidden={col.hidden}
        width={col.width}
        key={`col-${index}`}
        dataField={col.field}
        dataSort={col.hasSort}
        sortFunc={col.sortFunc}
        editable={col.editable}
        dataAlign={col.dataAlign}
        isKey={col.field === 'id' || col.isKey}
        dataFormat={col.dataFormat}
        columnClassName={col.columnClassName}
        editColumnClassName={col.editColumnClassName}
      >
        {col.label}
      </TableHeaderColumn>
    )
  })
  return headerColumns
}

export default buildColumns
