/* eslint-disable max-len */

/*!
 * deps
 */

import React from 'react'
import IconBase from './IconBase'

/**
 * Search component
 */
const Table = props => {
  return (
    <IconBase viewBox="0 0 30 30" {...props}>
      <g>
        <path fill="none" d="M0 0h30v30H0z" />
        <path d="M24.4 8.4v13.1c0 .3-.3.6-.6.7H6.4c-.1 0-.2 0-.3-.1-.3-.2-.4-.4-.4-.7V8.3c-.1-.3.3-.6.7-.6h17.2c.4 0 .7.3.8.6v.1zm-1.7 5.4h-11v6.9h11v-6.9zm0-1.7V9.3c0-.1 0-.1-.1-.1h-11v2.9h11.1zM7.3 20.7h2.8v-6.9H7.3v6.9zm0-8.6h2.8V9.3H7.3v2.8z" />
      </g>
    </IconBase>
  )
}

export default Table
