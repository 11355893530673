function findRegexUtilityResults({ examples = [], examples_msguuid, regexString }) {
  if (!regexString) return { examples: [], percentage: 0 }
  const examplesWithMessageUUIDs = []
  examples.forEach((example, index) => {
    examplesWithMessageUUIDs.push({ example, messageUUID: examples_msguuid[index] })
  })

  const total = examplesWithMessageUUIDs.length
  const regex = new RegExp(regexString)

  const examplesMatch = examplesWithMessageUUIDs.filter(exampleWithMessageUUID => {
    return regex.test(exampleWithMessageUUID.example.toLowerCase())
  })
  const examplesNotMatch = examplesWithMessageUUIDs.filter(exampleWithMessageUUID => {
    return !regex.test(exampleWithMessageUUID.example.toLowerCase())
  })
  const numberOfMatches = examplesMatch.length
  const percentage = (numberOfMatches * 100) / total
  return { examplesMatch, examplesNotMatch, percentage, total }
}

export default findRegexUtilityResults
