import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { FormGroup, FormControl, ControlLabel, InputGroup } from 'react-bootstrap'
import cx from 'classnames'
import { ordinalSuffixFor } from '../../../../utils/formatters'
import './index.scss'

class NumberInputField extends Component {
  onChange = event => {
    const { min, max, onChange } = this.props
    const value = event.target.value
    if (value === '') {
      onChange(null)
      return
    }
    let valid = true
    if (min !== undefined && min > value) {
      valid = false
    } else if (max !== undefined && max < value) {
      valid = false
    }
    if (valid) {
      onChange(Number(value))
    }
  }

  render() {
    const { value, preLabel, postLabel, className, ordinalSuffix, readOnly } = this.props
    return (
      <FormGroup
        className={cx('number-input-box', className, {
          'with-suffix': ordinalSuffix
        })}
      >
        {preLabel && <ControlLabel> {preLabel} </ControlLabel>}
        <FormControl type="number" value={value} onChange={this.onChange} readOnly={readOnly} />
        {ordinalSuffix && <InputGroup.Addon>{ordinalSuffixFor(value)}</InputGroup.Addon>}
        {postLabel && <ControlLabel> {postLabel} </ControlLabel>}
      </FormGroup>
    )
  }
}

NumberInputField.propTypes = {
  className: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  min: PropTypes.number,
  max: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  preLabel: PropTypes.string,
  postLabel: PropTypes.string,
  ordinalSuffix: PropTypes.bool,
  readOnly: PropTypes.bool
}

export default NumberInputField
