import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { Button, Form, Checkbox, Glyphicon } from 'react-bootstrap'
import { useForm, Controller } from 'react-hook-form'
import { useLocalStorage } from '@rehooks/local-storage'

import { clearModals } from 'actions/modal-actions'
import InputField from 'components/FormInputComponents/InputField'

import { getLocalStorageKey, getColumnVisibility } from './utils'
import './columns-config.scss'

const buildColumnsVisability = (columns, localStorageColumns) => {
  const columnsLocalStorageFormat = {}
  Object.entries(columns).forEach(([key, val]) => {
    columnsLocalStorageFormat[key] = getColumnVisibility(localStorageColumns?.[key])
  })
  return columnsLocalStorageFormat
}

const ColumnsConfigModal = React.memo(function ColumnsConfigModalComponent({ columns, tableId }) {
  const dispatch = useDispatch()

  const handleRejectButton = () => dispatch(clearModals())

  const onSubmit = data => {
    dispatch(clearModals())
    setLocalStorageColumns(data)
  }

  const [localStorageColumns, setLocalStorageColumns] = useLocalStorage(getLocalStorageKey(tableId), null)

  const defaultValues = buildColumnsVisability(columns, localStorageColumns)

  const {
    handleSubmit,
    control,
    formState: { isDirty },
    reset
  } = useForm({ defaultValues })

  const buildForm = () => {
    const items = []
    Object.entries(columns).forEach(([key, value]) => {
      items.push(
        <InputField key={key} label={value.label} info={value.title} labelSize={4}>
          <Controller
            name={key}
            control={control}
            render={({ field: { onChange, value: val } }) => (
              <Checkbox type="checkbox" checked={val} disabled={value.isAlwaysVisible} onChange={onChange}>
                {value.isAlwaysVisible ? (
                  <Glyphicon
                    glyph="pushpin"
                    className="text-color-grey"
                    title="This is a mandatory column. You can't hide it."
                  />
                ) : (
                  value.isFiltered && (
                    <Glyphicon
                      glyph="filter"
                      className={val === false && 'text-color-red'}
                      title=" Table is filtered by this column. The filter will be removed if you hide the column."
                    />
                  )
                )}
              </Checkbox>
            )}
          />
        </InputField>
      )
    })
    return items
  }
  return (
    <Form className="text-left" horizontal onSubmit={handleSubmit(onSubmit)}>
      <div className="columns-config-modal-form-body">{buildForm()}</div>
      <div className="modal-body-footer">
        <Button onClick={() => reset()} disabled={!isDirty}>
          Reset
        </Button>
        <Button onClick={handleRejectButton}>Cancel</Button>
        <Button className="btn_custom" disabled={!isDirty} type="submit">
          Save
        </Button>
      </div>
    </Form>
  )
})

ColumnsConfigModal.propTypes = {
  columns: PropTypes.object.isRequired,
  tableId: PropTypes.PropTypes.string.isRequired
}

export default ColumnsConfigModal
