import React from 'react'
import PropTypes from 'prop-types'
import joi from 'joi'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Checkbox,
  Input,
  Alert,
  Stack
} from '@mui/material'
import { useForm, Controller } from 'react-hook-form'
import { joiResolver } from '@hookform/resolvers/joi'

import { useEditSupraTaxonomyMutation } from './supraTaxonomyAPI'
import InputWithWideLabel from 'mui/components/Inputs/InputWithWideLabel'
import LoadingOverlay from 'mui/components/LoadingOverlay/LoadingOverlay'

const SupraTaxonomyEditEntryModal = ({ setWndShow, selectedRow, updateStatus }) => {
  const [editEntry, { isError, error, isLoading }] = useEditSupraTaxonomyMutation()

  const closeModal = () => {
    setWndShow(false)
  }

  const validationSchema = joi.object({
    domain_id: joi
      .number()
      .integer()
      .min(0),
    domain: joi
      .string()
      .trim()
      .required(),
    nonreceipt_whitelisted: joi
      .boolean()
      .default(false)
      .label('Is whitelisted')
  })

  const {
    control,
    handleSubmit,
    formState: { isDirty, isValid, errors },
    reset
  } = useForm({
    mode: 'all',
    defaultValues: {
      domain_id: selectedRow.domain_id,
      domain: selectedRow.domain,
      nonreceipt_whitelisted: selectedRow.nonreceipt_whitelisted
    },
    resolver: joiResolver(validationSchema)
  })

  const onSubmit = async data => {
    const { domain_id, domain, nonreceipt_whitelisted } = data
    const result = await editEntry({
      domain_id,
      domain,
      nonreceipt_whitelisted
    }).unwrap()
    if (result.success) {
      setWndShow(false)
      // eslint-disable-next-line no-unused-expressions
      updateStatus?.()
    }
  }

  return (
    <Dialog open fullWidth maxWidth="sm">
      <LoadingOverlay open={isLoading} />
      <DialogTitle>Edit Supra Taxonomy Entry</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={1} direction="column">
            <InputWithWideLabel label="Domain">
              <Typography variant="h6" sx={{ paddingRight: '8px' }}>
                {selectedRow.domain}
              </Typography>
            </InputWithWideLabel>
            <InputWithWideLabel label="ID" isHide>
              <Controller name="domain_id" control={control} render={({ field }) => <Input {...field} />} />
            </InputWithWideLabel>
            <InputWithWideLabel
              label="Is whitelisted"
              info="You can whitelist the domain"
              validationMessage={errors.nonreceipt_whitelisted?.message}
            >
              <Controller
                name="nonreceipt_whitelisted"
                control={control}
                render={({ field: { value, onChange } }) => {
                  return <Checkbox onChange={onChange} checked={value} />
                }}
              />
            </InputWithWideLabel>

            {isError && (
              <Alert sx={{ ml: 4, mb: 2 }} severity="warning">
                {error.data}
              </Alert>
            )}

            <DialogActions sx={{ justifyContent: 'flex-end' }}>
              <Button onClick={closeModal}>Close</Button>

              <Button sx={{ ml: 2 }} onClick={() => reset()} disabled={!isDirty}>
                Reset
              </Button>

              <Button sx={{ ml: 2 }} type="submit" disabled={!isDirty || (isDirty && !isValid)}>
                Update
              </Button>
            </DialogActions>
          </Stack>
        </form>
      </DialogContent>
    </Dialog>
  )
}

SupraTaxonomyEditEntryModal.propTypes = {
  isWndShow: PropTypes.bool,
  setWndShow: PropTypes.func,
  selectedRow: PropTypes.object,
  updateStatus: PropTypes.func
}

export default SupraTaxonomyEditEntryModal
