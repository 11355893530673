import React, { Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import { Button, Glyphicon, OverlayTrigger, Tooltip } from 'react-bootstrap'
import PropTypes from 'prop-types'
import { TASK_STATUS, supraQCToolDefaults, permissions as PERMISSIONS } from 'constants/constants'
import UserService from 'services/user-service'
import {
  onDictionaryTablePageChange,
  onDictionaryTableRefresh,
  onAddToDictModalOpen
} from 'actions/supra-qc-tool-actions.js'
import { toggleDictionaryEntryDeletedStatus } from './actions'
import { openAddToDictionaryModal } from './slices'
import Loader from '../Loader'
import './style.scss'
import { confirmModal } from 'actions/modal-actions'
import { orchestrate } from 'actions/task-actions'
import { getStatusMeta } from './utils'

const DATE_FORMAT = 'MM-DD-YYYY hh:mm:ss A'
const DATE_SHORT_FORMAT = 'MMM DD'

const { DICTIONARY_TABLE_SIZE_PER_PAGE } = supraQCToolDefaults
const initialState = {
  filterIdVal: '',
  filterOriginalTemplateVal: '',
  filterModdedTemplateVal: '',
  filterSubjectVal: '',
  filterFromAddrVal: '',
  filterDomainVal: '',
  filterEmailTypeVal: '',
  filterUseInTrainingVal: '',
  filterIsDeletedVal: '',
  filterIsRegexVal: '',
  filterSampleSubjectVal: '',
  filterUpdateTimeVal: ''
}

class DictionaryTab extends Component {
  constructor() {
    super()
    this.state = {
      ...initialState
    }
  }
  componentDidMount() {
    const { total, onDictionaryTableRefresh } = this.props
    if (total === 0) {
      onDictionaryTableRefresh()
    }
  }

  tooltip = name => <Tooltip id="dictionary-toolbar-tooltip">{name}</Tooltip>

  handleShow = row => {
    this.props.onAddToDictModalOpen({ selectedRow: row })
  }

  enableDisableDictionaryEntry = row => {
    this.props.toggleDictionaryEntryDeletedStatus({ dictionaryId: row.id, deleted: !row.is_deleted })
  }

  buttonFormatter = (cell, row) => {
    return (
      <>
        <Button bsStyle="primary" bsSize="xsmall" onClick={() => this.handleShow(row)}>
          Update
        </Button>
        {this.props.userHasSupraQCAdminPermission && (
          <Button
            className="ml-2"
            bsStyle={row.is_deleted ? 'success' : 'danger'}
            bsSize="xsmall"
            onClick={() => this.enableDisableDictionaryEntry(row)}
          >
            {row.is_deleted ? 'Enable' : 'Disable'}
          </Button>
        )}
      </>
    )
  }

  getTooltip(task) {
    return (
      <Tooltip id="last-dictionary-sync-tooltip">
        {task && (
          <>
            <p>Last task info</p>
            <ul className="text-left">
              <li>Id: {task.id}</li>
              <li>Status: {task.status}</li>
              <li>UserId: {task.userId}</li>
              <li>UserName: {task.user?.fullname}</li>
              <li>Create: {moment(task.createdAt).format(DATE_FORMAT)}</li>
              <li>Start: {task.startedAt && moment(task.startedAt).format(DATE_FORMAT)}</li>
              <li>End: {task.endedAt && moment(task.endedAt).format(DATE_FORMAT)}</li>
            </ul>
          </>
        )}
      </Tooltip>
    )
  }

  createCustomToolBar = () => {
    const { lastDictionarySync } = this.props
    const status = lastDictionarySync && lastDictionarySync.status
    const { color, text } = getStatusMeta(status)
    return (
      <div className="result-table-toolbar d-flex align-items-center">
        <div className="mr-2">
          <Button bsStyle="default" bsSize="sm" onClick={this.props.onDictionaryTableRefresh}>
            <Glyphicon glyph="refresh" />
            <span className="pl-2">Dictionary</span>
          </Button>
        </div>
        <Button bsStyle="default" bsSize="sm" onClick={() => this.setState(initialState)}>
          <Glyphicon glyph="erase" />
          <span className="pl-2">Clear Filters</span>
        </Button>

        {this.props.userHasSupraQCEditPermission && (
          <Button bsStyle="default" className="ml-2" bsSize="sm" onClick={() => this.props.openAddToDictionaryModal()}>
            <Glyphicon glyph="plus" />
            <span className="pl-2">Add to Dictionary</span>
          </Button>
        )}

        <Button
          bsStyle="default"
          bsSize="sm"
          className="ml-2"
          disabled={[TASK_STATUS.PENDING, TASK_STATUS.PROCESSING].includes(status)}
          onClick={event => {
            event.preventDefault()
            this.openDictionarySyncConfirmationModal()
          }}
        >
          <Glyphicon glyph="cloud-upload" />
          <span className="pl-2">Publish</span>
        </Button>

        <div className="ml-2">
          <OverlayTrigger delayShow={100} placement="bottom" overlay={this.getTooltip(lastDictionarySync)}>
            <span>
              Last run:{' '}
              <span className={`text-color-${color}`}>
                <strong>{text}</strong>
              </span>{' '}
              {lastDictionarySync && moment(lastDictionarySync.createdAt).format(DATE_SHORT_FORMAT)}
            </span>
          </OverlayTrigger>
        </div>
      </div>
    )
  }

  openDictionarySyncConfirmationModal() {
    return this.props.confirmModal(
      <span>{`Are you sure you want to publish dictionary changes to Snowflake?`}</span>,
      'Confirm Orchestration',
      { okButton: 'Yes, Orchestrate' },
      () => this.props.orchestrate('supra-qc-dictionary-sync', {})
    )
  }

  handlePageChange = (page, sizePerPage) => {
    this.props.onDictionaryTablePageChange({ page, sizePerPage: DICTIONARY_TABLE_SIZE_PER_PAGE })
  }

  renderShowsTotal = (start, to) => {
    return (
      <p>
        Showing {start} to {to || 0} of {this.props.total}
      </p>
    )
  }

  overlayFormatter = (cell, row, placement = 'right') => {
    return (
      <OverlayTrigger placement={placement} delayShow={500} overlay={this.tooltip(cell)}>
        <span>{cell}</span>
      </OverlayTrigger>
    )
  }
  onFilterChange = event => {
    const {
      id,
      original_template,
      modded_template,
      subject,
      from_addr,
      domain,
      type,
      use_in_training,
      is_deleted,
      is_regex,
      sample_subject,
      update_time
    } = event
    this.setState({
      ...this.state,
      filterIdVal: id?.value,
      filterOriginalTemplateVal: original_template?.value,
      filterModdedTemplateVal: modded_template?.value,
      filterSubjectVal: subject?.value,
      filterFromAddrVal: from_addr?.value,
      filterDomainVal: domain?.value,
      filterEmailTypeVal: type?.value,
      filterUseInTrainingVal: use_in_training?.value,
      filterIsDeletedVal: is_deleted?.value,
      filterIsRegexVal: is_regex?.value,
      filterSampleSubjectVal: sample_subject?.value,
      filterUpdateTimeVal: update_time?.value
    })
  }

  render() {
    let sequence = 1
    const options = {
      sizePerPage: DICTIONARY_TABLE_SIZE_PER_PAGE,
      paginationShowsTotal: this.renderShowsTotal,
      hideSizePerPage: true,
      toolBar: this.createCustomToolBar,
      onFilterChange: this.onFilterChange
    }

    const idFilter = { type: 'TextFilter', defaultValue: this.state.filterIdVal }
    const originalTemplateFilter = { type: 'TextFilter', defaultValue: this.state.filterOriginalTemplateVal }
    const moddedTemplateFilter = { type: 'TextFilter', defaultValue: this.state.filterModdedTemplateVal }
    const subjectFilter = { type: 'TextFilter', defaultValue: this.state.filterSubjectVal }
    const fromAddrFilter = { type: 'TextFilter', defaultValue: this.state.filterFromAddrVal }
    const domainFilter = { type: 'TextFilter', defaultValue: this.state.filterDomainVal }
    const emailTypeFilter = { type: 'TextFilter', defaultValue: this.state.filterEmailTypeVal }
    const useInTrainingFilter = { type: 'TextFilter', defaultValue: this.state.filterUseInTrainingVal }
    const isDeletedFilter = { type: 'TextFilter', defaultValue: this.state.filterIsDeletedVal }
    const isRegexFilter = { type: 'TextFilter', defaultValue: this.state.filterIsRegexVal }
    const sampleSubjectFilter = { type: 'TextFilter', defaultValue: this.state.filterSampleSubjectVal }
    const updateTimeFilter = { type: 'TextFilter', defaultValue: this.state.filterUpdateTimeVal }

    return this.props.loading ? (
      <Loader />
    ) : (
      <div key="dictionary-table">
        <BootstrapTable
          data={this.props.rows.map(row => ({ ...row, sequence: sequence++ }))}
          pagination
          options={options}
          striped
          hover
          condensed
          bordered
          responsive
        >
          <TableHeaderColumn dataField="sequence" hidden isKey>
            Sequence
          </TableHeaderColumn>
          <TableHeaderColumn dataField="id" filter={idFilter} width="60px" dataSort>
            ID
          </TableHeaderColumn>

          <TableHeaderColumn
            tdStyle={{ whiteSpace: 'nowrap' }}
            dataField="original_template"
            filter={originalTemplateFilter}
            dataFormat={this.overlayFormatter}
            dataSort
          >
            Orig Template
          </TableHeaderColumn>

          <TableHeaderColumn
            dataField="modded_template"
            filter={moddedTemplateFilter}
            dataFormat={this.overlayFormatter}
            dataSort
          >
            Modded Template
          </TableHeaderColumn>

          <TableHeaderColumn dataField="subject" filter={subjectFilter} dataFormat={this.overlayFormatter} dataSort>
            Subject
          </TableHeaderColumn>
          <TableHeaderColumn dataField="from_addr" filter={fromAddrFilter} dataFormat={this.overlayFormatter} dataSort>
            From
          </TableHeaderColumn>
          <TableHeaderColumn
            tdStyle={{ whiteSpace: 'nowrap' }}
            dataSort
            filter={domainFilter}
            dataField="domain"
            dataFormat={this.overlayFormatter}
          >
            Domain
          </TableHeaderColumn>
          <TableHeaderColumn filter={emailTypeFilter} dataSort dataField="type">
            Type
          </TableHeaderColumn>
          <TableHeaderColumn filter={useInTrainingFilter} dataSort dataField="use_in_training">
            UseInTraining
          </TableHeaderColumn>
          <TableHeaderColumn filter={isDeletedFilter} dataSort dataField="is_deleted" width="70px">
            Deleted
          </TableHeaderColumn>
          <TableHeaderColumn filter={isRegexFilter} dataSort dataField="is_regex" width="70px">
            Regex
          </TableHeaderColumn>
          <TableHeaderColumn
            tdStyle={{ whiteSpace: 'nowrap' }}
            dataSort
            filter={sampleSubjectFilter}
            dataField="sample_subject"
            dataFormat={(row, cell) => this.overlayFormatter(row, cell, 'left')}
          >
            SampleSubject
          </TableHeaderColumn>
          <TableHeaderColumn
            filter={updateTimeFilter}
            tdStyle={{ whiteSpace: 'nowrap' }}
            dataSort
            dataField="update_time"
            dataFormat={(row, cell) => this.overlayFormatter(row, cell, 'left')}
          >
            UpdateTime
          </TableHeaderColumn>

          {this.props.userHasSupraQCEditPermission && (
            <TableHeaderColumn width="10%" dataField="button" dataFormat={this.buttonFormatter}>
              Dictionary
            </TableHeaderColumn>
          )}
        </BootstrapTable>
      </div>
    )
  }
}

DictionaryTab.propTypes = {
  rows: PropTypes.array,
  lastDictionarySync: PropTypes.object,
  total: PropTypes.number,
  page: PropTypes.number,
  loading: PropTypes.bool,
  onDictionaryTablePageChange: PropTypes.func,
  onDictionaryTableRefresh: PropTypes.func,
  onAddToDictModalOpen: PropTypes.func,
  userHasSupraQCEditPermission: PropTypes.bool,
  userHasSupraQCAdminPermission: PropTypes.bool,
  toggleDictionaryEntryDeletedStatus: PropTypes.func,
  confirmModal: PropTypes.func,
  orchestrate: PropTypes.func,
  openAddToDictionaryModal: PropTypes.func
}

function mapStateToProps(state) {
  return {
    rows: state.supraQCTool.dictionaryTable.rows || [],
    lastDictionarySync: state.supraQCTool.dictionaryTable.lastDictionarySync || {},
    total: state.supraQCTool.dictionaryTable.total || 0,
    loading: state.supraQCTool.dictionaryTable.loading,
    page: state.supraQCTool.dictionaryTable.page || 1,
    userHasSupraQCEditPermission: UserService.hasPermission(state.session.user, PERMISSIONS.supraQCToolEdit),
    userHasSupraQCAdminPermission: UserService.hasPermission(state.session.user, PERMISSIONS.supraQCToolAdmin)
  }
}

export default connect(mapStateToProps, {
  onDictionaryTablePageChange,
  onDictionaryTableRefresh,
  onAddToDictModalOpen,
  confirmModal,
  orchestrate,
  toggleDictionaryEntryDeletedStatus,
  openAddToDictionaryModal
})(DictionaryTab)
