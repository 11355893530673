import React from 'react'
import PropTypes from 'prop-types'
import { Glyphicon, Alert } from 'react-bootstrap'

import InputField, { useFormValues } from 'components/FormInputComponents/InputField'
import useHandleSynonymChange from './useHandleSynonymChange'
import { getModalUISettings } from 'components/Rules/Common/utils'
import useSynonymValidation from './useSynonymValidation'
import duplicateCheck from 'utils/duplicateCheck'

const SynonymWithCheck = ({
  synonym,
  synonyms,
  index,
  brandId,
  brandFullPath,
  mode,
  prevRuleId,
  handleSynonymChange
}) => {
  const { values: currentSynonym, isFormChanged, handleFieldChange } = useFormValues(synonym)

  const { synonymHelpInfo, isSynonymValid, error, rule, isRuleActiveFunc, isLoading } = useSynonymValidation({
    synonym: currentSynonym.synonym,
    brandId
  })

  useHandleSynonymChange({
    mode,
    currentSynonym,
    index,
    rule,
    isLoading,
    handleSynonymChange,
    prevRuleId,
    isSynonymValid
  })

  // need to check synonym duplicates in the form & duplicate with Brand
  const { hasDuplicateInGroup, hasDuplicateWithParent } = duplicateCheck({
    parent: brandFullPath,
    child: synonym.synonym,
    arrOfChilds: synonyms.map(el => el.synonym)
  })

  const fieldsNames = 'Brand and Synonym'
  const isFieldsFilled = brandId && currentSynonym.synonym

  const { ruleInfoMessage, ruleInfoStyle } = getModalUISettings({
    id: brandId,
    isAllFieldsFilled: isFieldsFilled,
    fieldsNames,
    isRuleActiveFunc,
    mode,
    rule,
    error,
    isLoading,
    hasDuplicateInGroup: hasDuplicateInGroup(),
    hasDuplicateWithParent: hasDuplicateWithParent()
  })

  return (
    <div>
      <InputField
        id="synonym"
        label="Synonym"
        type="input"
        required
        value={currentSynonym.synonym}
        validationState={isFormChanged && !isSynonymValid ? 'error' : null}
        disabled={mode.isRequestApprove || mode.isRequestView}
        onChange={handleFieldChange}
        help={mode.isRequestView || !isFormChanged ? null : <div className="pl-1 pt-2">{synonymHelpInfo}</div>}
      />
      {!mode.isRequestView && (
        <Alert bsStyle={ruleInfoStyle}>
          <Glyphicon className="padding-rigt-standard" glyph="info-sign" />
          {ruleInfoMessage}
        </Alert>
      )}
    </div>
  )
}

SynonymWithCheck.propTypes = {
  synonym: PropTypes.object,
  synonyms: PropTypes.array,
  index: PropTypes.number,
  brandId: PropTypes.number,
  brandFullPath: PropTypes.string,
  mode: PropTypes.object,
  prevRuleId: PropTypes.number,
  handleSynonymChange: PropTypes.func
}

export default SynonymWithCheck
