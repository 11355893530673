import React from 'react'
import PropTypes from 'prop-types'

import BooleandHighlightedText from './../BooleandHighlightedText'
import ValueWithMark from './../ValueWithMark'

const ViolationInfo = ({ isPass, isActive, result, resultMark, threshold, showViolation = true }) => {
  return (
    <>
      {showViolation && (
        <div>
          <span>Violation: </span>
          <BooleandHighlightedText isPass={isPass} useHighlight={isActive}>
            <ValueWithMark mark={resultMark}>{result}</ValueWithMark>
          </BooleandHighlightedText>
        </div>
      )}
      <div>
        <span>Threshold: </span>
        <ValueWithMark mark={resultMark}>{threshold}</ValueWithMark>
      </div>
    </>
  )
}

ViolationInfo.displayName = 'TooltipViolation'

ViolationInfo.propTypes = {
  result: PropTypes.node,
  resultMark: PropTypes.string,
  threshold: PropTypes.number,
  isPass: PropTypes.bool,
  showViolation: PropTypes.bool,
  isActive: PropTypes.bool
}

export default ViolationInfo
