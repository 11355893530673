import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import DocumentTitle from 'react-document-title'

import Unauthorized from '../Unauthorized'
import UserService from '../../services/user-service'
import { appName, permissions } from '../../constants/constants'

import CMEInspector from './CMEInspector'
import WithErrorBoundaryWrapper from 'components/WithErrorBoundaryWrapper/WithErrorBoundaryWrapper'

class CMEInspectorPage extends Component {
  render() {
    const { hasTaxonomyAccess } = this.props

    if (!hasTaxonomyAccess) return <Unauthorized />

    return (
      <DocumentTitle title={`${appName} | CME Inspector`}>
        <CMEInspector />
      </DocumentTitle>
    )
  }
}

CMEInspectorPage.propTypes = {
  hasTaxonomyAccess: PropTypes.bool.isRequired
}

const mapStateToProps = ({ session: { user } }) => ({
  hasTaxonomyAccess: user && user.permissions ? UserService.hasPermission(user, permissions.ropsTaxonomyView) : false
})

export default WithErrorBoundaryWrapper(connect(mapStateToProps, null)(CMEInspectorPage), '"CME Inspector" page')
