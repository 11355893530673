import React from 'react'
import PropTypes from 'prop-types'
import { Glyphicon, OverlayTrigger, Tooltip } from 'react-bootstrap'

import { BooleanCell } from 'components/DataTable'
import BooleandHighlightedText from './BooleandHighlightedText'

const TestPassRow = ({ children, isPass, isActive }) => {
  const testName = children
  return (
    <tr>
      <td className="text-start category-test-cell-pass-tooltip-name">
        <span>{testName}</span>{' '}
      </td>
      <td className="pl-2">
        {isActive ? (
          isPass === null ? (
            '—'
          ) : (
            <BooleandHighlightedText isPass={isPass} />
          )
        ) : (
          <Glyphicon glyph="ban-circle" />
        )}
      </td>
    </tr>
  )
}

TestPassRow.propTypes = {
  isPass: PropTypes.bool,
  isActive: PropTypes.bool,
  children: PropTypes.node
}

const PassCell = ({ isTestPass, row }) => {
  return (
    <>
      <OverlayTrigger
        placement="top"
        overlay={
          <Tooltip id="category-test-cell-pass-tooltip">
            <table>
              <tbody>
                <TestPassRow isPass={row.dateTestIsPass} isActive={row.dateTestIsActive}>
                  Date test
                </TestPassRow>
                <TestPassRow isPass={row.dictionaryTestIsPass} isActive={row.dictionaryTestIsActive}>
                  Dictionary test
                </TestPassRow>
                <TestPassRow isPass={row.includeTestIsPass} isActive={row.includeTestIsActive}>
                  Include test
                </TestPassRow>
                <TestPassRow isPass={row.excludeTestIsPass} isActive={row.excludeTestIsActive}>
                  Exclude test
                </TestPassRow>
                <TestPassRow isPass={row.misbrandTestIsPass} isActive={row.misbrandTestIsActive}>
                  Misbrand test
                </TestPassRow>
                <TestPassRow isPass={row.coverageTestIsPass} isActive={row.coverageTestIsActive}>
                  Coverage test
                </TestPassRow>
                <TestPassRow isPass={row.newBrandTestIsPass} isActive={row.newBrandTestIsActive}>
                  New Brand test
                </TestPassRow>
                <TestPassRow isPass={row.newBrandMonthTestIsPass} isActive={row.newBrandMonthTestIsActive}>
                  New Brand 3 Months test
                </TestPassRow>
                <TestPassRow isPass={row.merchantTrendTestIsPass} isActive={row.merchantTrendTestIsActive}>
                  Merchant Trend test
                </TestPassRow>
                <TestPassRow isPass={row.brandTrendTestIsPass} isActive={row.brandTrendTestIsActive}>
                  Brand Trend test
                </TestPassRow>
                <TestPassRow isPass={row.overallTrendTestIsPass} isActive={row.overallTrendTestIsActive}>
                  Overall Trend test
                </TestPassRow>
              </tbody>
            </table>
          </Tooltip>
        }
      >
        <span>{isTestPass !== null && <BooleanCell isActive={isTestPass} isAllowNull />}</span>
      </OverlayTrigger>
    </>
  )
}

PassCell.propTypes = {
  isTestPass: PropTypes.bool.isRequired,
  row: PropTypes.object
}

export default PassCell
