import React from 'react'

export default function MoreActions(props) {
  return (
    <svg
      width="18px"
      height="18px"
      viewBox="0 0 18 4"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      {/* Generator: Sketch 51.2 (57519) - http://www.bohemiancoding.com/sketch */}
      <desc>Created with Sketch.</desc>
      <defs />
      <g id="Components" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g id="Icons" transform="translate(-336.000000, -206.000000)">
          <g id="ic-more" transform="translate(330.000000, 193.000000)">
            <g id="Close-circle">
              <rect id="spacing" x={0} y={0} width={30} height={30} />
              <g id="Group" transform="translate(6.000000, 13.000000)" fill="currentColor">
                <circle id="Combined-Shape" cx={2} cy={2} r={2} />
                <circle id="Combined-Shape" cx={9} cy={2} r={2} />
                <circle id="Combined-Shape" cx={16} cy={2} r={2} />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
