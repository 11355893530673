import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { MerchantBrandRulesActions } from '../../actions'

export default function useFormMerchantBrandFilds(values, updateValue, mode) {
  const dispatch = useDispatch()

  const checkRule = (merchantId, brandId) => {
    if (merchantId && brandId) {
      dispatch(MerchantBrandRulesActions.fetchByMerchantAndBrand({ merchantId, brandId }))
    }
  }

  useEffect(() => {
    const { merchantId, brandId } = values
    if (merchantId && brandId && !mode.isRequestView) {
      checkRule(merchantId, brandId)
    }
    // Not necessary to check dependencies
    // We run it only once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleMerchantChange = merchant => {
    const merchantId = merchant?.id
    const merchantName = merchant?.merchantName

    updateValue({ merchantId, merchantName })

    if (merchantId) {
      checkRule(merchantId, values.brandId)
    }
  }

  const handleBrandChange = brand => {
    const brandId = brand?.id
    const brandFullPath = brand?.fullPath
    updateValue({ brandId, brandFullPath })

    if (brandId) {
      checkRule(values.merchantId, brandId)
    }
  }

  return [handleMerchantChange, handleBrandChange]
}
