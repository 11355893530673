import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { clearModals } from 'actions/modal-actions'
import CategoryGuidelineModalLayout from './CategoryGuidelineModalLayout'

import CategoryGuidelineActions from './../actions'

class CategoryGuidelineEditModal extends Component {
  state = {
    categoryId: this.props.categoryId,
    categoryFullPath: this.props.categoryFullPath,
    include: this.props.include,
    exclude: this.props.exclude,
    notes: this.props.notes
  }

  handleOkButton = () => {
    const { categoryId, include, exclude, notes } = this.state
    const { saveAction, clearModals } = this.props
    saveAction({
      categoryId: categoryId,
      include: include?.trim(),
      exclude: exclude?.trim(),
      notes: notes?.trim()
    })
    clearModals()
  }

  handleFieldChange = newValue => {
    this.setState(newValue)
  }

  render() {
    const { categoryId, categoryFullPath, include, exclude, notes } = this.state
    const { clearModals, readOnly } = this.props

    return (
      <CategoryGuidelineModalLayout
        categoryId={categoryId}
        categoryFullPath={categoryFullPath}
        include={include}
        exclude={exclude}
        notes={notes}
        handleFieldChange={this.handleFieldChange}
        onCancelHandler={clearModals}
        onOkHandler={this.handleOkButton}
        readOnly={readOnly}
      />
    )
  }
}

CategoryGuidelineEditModal.defaultProps = {}

CategoryGuidelineEditModal.propTypes = {
  categoryId: PropTypes.number,
  categoryFullPath: PropTypes.string,
  include: PropTypes.string,
  exclude: PropTypes.string,
  notes: PropTypes.string,

  readOnly: PropTypes.bool,

  clearModals: PropTypes.func.isRequired,
  saveAction: PropTypes.func.isRequired
}

const mapDispatchToProps = {
  clearModals,
  saveAction: CategoryGuidelineActions.save
}

export default connect(null, mapDispatchToProps)(CategoryGuidelineEditModal)
