import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { OverlayTrigger } from 'react-bootstrap'

class EditValue extends Component {
  static propTypes = {
    getTooltip: PropTypes.func,
    children: PropTypes.element,
    className: PropTypes.string,
    name: PropTypes.string
  }

  render() {
    return (
      <OverlayTrigger placement="top" delayShow={1000} overlay={this.props.getTooltip()}>
        <span className={this.props.className}>{this.props.children}</span>
      </OverlayTrigger>
    )
  }
}

export default EditValue
