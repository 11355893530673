import React, { Component } from 'react'
import PropTypes from 'prop-types'
import FormDatePicker from './../../FormDatePicker'

export default class MinMaxDateFilter extends Component {
  static propTypes = {
    feed: PropTypes.object.isRequired,
    fromDate: PropTypes.instanceOf(Date)
  }

  static defaultProps = {
    fromDate: new Date('1/1/13')
  }

  render() {
    return (
      <div>
        <FormDatePicker
          label="Min Order Date"
          fieldName="minOrderDate"
          isRequired={!this.props.feed.isActive}
          fromDate={this.props.fromDate}
          tooltip={'The min order date is inclusive.'}
        />
        {!this.props.feed.isActive && (
          <FormDatePicker
            label="Max Order Date"
            isRequired
            fieldName="maxOrderDate"
            fromDate={
              this.props.feed.filters.minOrderDate
                ? new Date(this.props.feed.filters.minOrderDate)
                : this.props.fromDate
            }
            tooltip={'The max order date is inclusive.'}
          />
        )}
      </div>
    )
  }
}
