// ************************* Trend tests **********************/

const passFilter = i => i.isPass
const failFilter = i => !i.isPass
/**
 * Go through all months and get the max violation
 * @param {object} result - information about merchants with months series
 * @param {function} filter - filter function for getting passed or failed items
 * @return {number} Max violation for selected filter
 */
const getMaxViolation = (result, filter) => {
  return Object.values(result)
    .flat()
    .filter(filter)
    .sort((a, b) => b.violation - a.violation)[0]?.violation
}

const getMaxFailedViolation = result => getMaxViolation(result, failFilter)
const getMaxPassedViolation = result => getMaxViolation(result, passFilter)

export { getMaxFailedViolation, getMaxPassedViolation }

// ************************* /Trend tests **********************/
