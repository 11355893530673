import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'react-bootstrap'
import { connect } from 'react-redux'
import { searchTermSuggest, getSearchMetaData } from '../../actions/search-actions'
import { onSearchFilterChanged } from '../../actions/widget-actions'
import { ES_SCORECARD_KEY, default as constants } from '../../constants/constants'
import '../../styles/querybuilder.scss'
import SearchFilterContainer from './SearchFilterContainer'

/* REACT REDUX CONNECT */
function mapStateToProps(state, ownProps) {
  return {
    tree: state.search.tree,
    seq: state.search.seq,
    selectedId: state.search.selectedId,
    searchType: state.search.searchType,
    searchValue: state.search.searchValue,
    searchSuggestions: state.search.searchSuggestions.reduce(
      (suggArray, suggestion) => {
        suggArray.push({
          ...suggestion,
          label: `${constants.fields[suggestion.type].label}${
            constants.fields[suggestion.type].canBeParent && !suggestion.isChild ? ' (Parent)' : ''
          }`
        })
        return suggArray
      },
      [
        {
          type: 'description',
          value: state.search.searchValue,
          label: constants.fields.description.label
        }
      ]
    ),
    filters: state.search.searchFilters,
    brandUpdateUsers: state.search.brandUpdateUsers,
    categoryUpdateUsers: state.search.categoryUpdateUsers
  }
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    onSearchTermSuggest: term => {
      dispatch(searchTermSuggest(term))
    },
    onSearchFilterChanged: (key, value) => {
      dispatch(onSearchFilterChanged(key, value))
    },
    getSearchMetaData: () => {
      dispatch(getSearchMetaData())
    }
  }
}

/* Search Component */
class Search extends Component {
  static propTypes = {
    searchType: PropTypes.string,
    searchValue: PropTypes.string,
    searchSuggestions: PropTypes.arrayOf(PropTypes.object),
    seq: PropTypes.number,
    tree: PropTypes.object,
    selectedId: PropTypes.string,
    onSearchTermSuggest: PropTypes.func.isRequired,
    onSearchFilterChanged: PropTypes.func.isRequired,
    filters: PropTypes.object,
    getSearchMetaData: PropTypes.func,
    brandUpdateUsers: PropTypes.array,
    categoryUpdateUsers: PropTypes.array
  }

  componentDidMount() {
    this.props.getSearchMetaData()
  }

  onSearchTermSuggest(text) {
    this.props.onSearchTermSuggest(text)
  }

  render() {
    return (
      <div>
        <div>
          <div>
            <Row className="show-grid">
              <Col xs={12} md={12}>
                <SearchFilterContainer
                  brandFilters={this.props.filters.brandFilters}
                  categoryFilters={this.props.filters.categoryFilters}
                  userTertile={this.props.filters.userTertile}
                  scoreCard={this.props.filters[ES_SCORECARD_KEY]}
                  onSearchFilterChanged={this.props.onSearchFilterChanged}
                  brandUpdateUsers={this.props.brandUpdateUsers}
                  categoryUpdateUsers={this.props.categoryUpdateUsers}
                  selectedBrandUsers={this.props.filters.selectedBrandUsers}
                  selectedCategoryUsers={this.props.filters.selectedCategoryUsers}
                />
              </Col>
            </Row>
            <br />
          </div>
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Search)
