import React from 'react'
import { TableHeaderColumn } from 'react-bootstrap-table'
import {
  caretRender,
  buildContractNameRenderer,
  renderNextDelivery,
  renderDate,
  renderActiveStatus,
  renderContractClient
} from '../Common/table-utils'

const buildContractColumns = ({ onEdit, onToggleStatus, onDelete }) => {
  const getColumn = column => {
    const defaultColumnConfig = {
      label: column,
      field: column,
      dataSort: true,
      dataAlign: 'center',
      headerAlign: 'center',
      width: '100px',
      caretRender
    }

    let config = {}
    switch (column) {
      case 'id':
        config = {
          label: '#',
          hidden: true
        }
        break
      case 'name':
        config = {
          label: 'Contract',
          className: 'freeze-left contract-cell',
          columnClassName: 'freeze-left contract-cell',
          dataFormat: buildContractNameRenderer({
            onEdit,
            onToggleStatus,
            onDelete
          }),
          dataAlign: 'left',
          width: '300px'
        }
        break
      case 'Client.name':
        config = {
          label: 'Client',
          width: '130px',
          dataFormat: renderContractClient
        }
        break
      case 'deliverableCount':
        config = {
          label: 'Deliverables',
          width: '120px'
        }
        break
      case 'isActive':
        config = {
          label: 'Status',
          dataFormat: (cell, row) => renderActiveStatus(cell, row, 'contract')
        }
        break
      case 'nextDelivery':
        config = {
          label: 'Next Delivery',
          width: '130px',
          dataFormat: renderNextDelivery,
          dataSort: false
        }
        break
      case 'lastDeliveredDate':
        config = {
          label: 'Last Delivered',
          width: '120px',
          dataFormat: renderDate,
          dataSort: false
        }
        break
      case 'updatedAt':
        config = {
          label: 'Updated',
          width: '120px',
          dataFormat: renderDate
        }
        break
      case 'createdAt':
        config = {
          label: 'Created',
          width: '120px',
          dataFormat: renderDate
        }
        break
      case 'csOwner.fullname':
        config = {
          label: 'CS Owner',
          width: '120px',
          dataFormat: (cell, row) => {
            return row.csOwner ? row.csOwner.fullname : '- -'
          }
        }
        break
      default:
        break
    }
    return {
      ...defaultColumnConfig,
      ...config
    }
  }
  const fields = [
    'id',
    'name',
    'deliverableCount',
    'Client.name',
    'isActive',
    'nextDelivery',
    'lastDeliveredDate',
    'csOwner.fullname'
  ]
  const columns = fields.map(field => getColumn(field))
  const headerColumns = columns.map((col, index) => {
    const { field, label, ...otherProps } = col
    return (
      <TableHeaderColumn dataField={field} ref={field} isKey={field === 'id'} key={`col-${index}`} {...otherProps}>
        {label}
      </TableHeaderColumn>
    )
  })
  return headerColumns
}

export default buildContractColumns
