import React, { Component } from 'react'
import PropTypes from 'prop-types'
import QueryBuilder from '../../../../QueryBuilder'
import {
  findRule,
  areMultipleDescriptionsSelected,
  isParentValueSelected,
  controlElements
} from '../../../../../utils/portal-query-builder'
import { controlClassnames, fields, getOperators, combinators } from './query-config'

import '../../../../../utils/portal-query-builder/index.scss'

export default class QueryBuilderTree extends Component {
  render() {
    const onQueryChange = (query, event, ...actionArgs) => {
      if (!this.props.editable) {
        return
      }
      if (areMultipleDescriptionsSelected(event, actionArgs)) {
        // when event == 'onPropChange'
        // actionArgs == [propName, propValue, ruleId]
        const ruleId = actionArgs[2]
        const rule = findRule(ruleId, query)
        this.props.createMultipleRules(rule.value.action, ruleId, rule.value.value)
      } else if (isParentValueSelected(event, actionArgs)) {
        // when event == 'onPropChange'
        // actionArgs == [propName, propValue, ruleId]
        const ruleId = actionArgs[2]
        const rule = findRule(ruleId, query)
        this.props.fetchTypeChildren(rule.field, rule.value, rule.id)
      } else {
        this.props.handleQueryTreeChanged(query, event, actionArgs)
      }
    }
    return (
      <div className="full-height">
        <QueryBuilder
          fields={fields}
          combinators={combinators}
          getOperators={getOperators}
          query={this.props.queryTree}
          onQueryChange={onQueryChange}
          controlClassnames={controlClassnames}
          controlElements={controlElements}
          onClearQueryTree={this.props.clearQueryTree}
          editable={this.props.editable}
          showQuerySQLModal={this.props.showQuerySQLModal}
        />
      </div>
    )
  }
}

QueryBuilderTree.propTypes = {
  queryTree: PropTypes.object,
  handleQueryTreeChanged: PropTypes.func,
  fetchTypeChildren: PropTypes.func,
  clearQueryTree: PropTypes.func,
  createMultipleRules: PropTypes.func,
  editable: PropTypes.bool,
  showQuerySQLModal: PropTypes.func
}
