import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import CategoryConfig from './CategoryConfig'
import IncExcQuery from './IncExcQuery'
import BrandTracker from './BrandTracker'
import { Tabs, Tab, Button, HelpBlock } from 'react-bootstrap'
import { trim } from 'lodash'
import {
  fetchCategoryConfig,
  saveCategoryConfig,
  resetCategoryForm
} from '../../../../../actions/delivery-config-actions'
import './index.scss'

const CATEGORY_CONFIG_TAB = 'CATEGORY_CONFIG_TAB'
const INCLUDE_EXCLUDE_CONFIG_TAB = 'INCLUDE_EXCLUDE_CONFIG_TAB'
const BRAND_TRACKER_CONFIG_TAB = 'BRAND_TRACKER_CONFIG_TAB'

class BCIConfig extends Component {
  constructor(props) {
    super(props)

    this.state = {
      selectedTab: CATEGORY_CONFIG_TAB
    }
  }

  componentDidMount() {
    const { fetchCategoryConfig, deliverableId } = this.props
    if (deliverableId && deliverableId !== 'new') {
      fetchCategoryConfig(deliverableId)
    }
  }

  handleTabSelect = key => {
    this.setState({ selectedTab: key })
  }

  getTabs = () => {
    const { selectedTab } = this.state
    return (
      <Tabs id={'bci-config-tabs'} className="portal-tabs" activeKey={selectedTab} onSelect={this.handleTabSelect}>
        <Tab title="Category Config" eventKey={CATEGORY_CONFIG_TAB}>
          <CategoryConfig editable={this.props.editable} />
        </Tab>
        <Tab mountOnEnter title="Brand Trackers" eventKey={BRAND_TRACKER_CONFIG_TAB}>
          <BrandTracker editable={this.props.editable} />
        </Tab>
        <Tab mountOnEnter title="Include-Exclude Query" eventKey={INCLUDE_EXCLUDE_CONFIG_TAB}>
          <IncExcQuery editable={this.props.editable} />
        </Tab>
      </Tabs>
    )
  }

  areBrandTrackersValidToSave = brandTrackerForm => {
    const { brandTrackers, error } = brandTrackerForm
    if (!brandTrackers.length) return false
    const results = brandTrackers.map(brandTracker => {
      if (!brandTracker.label || !brandTracker.queries.length) return false
      return brandTracker.queries.every(btq => {
        return btq.brandId && !btq.isBrandInvalid
      })
    })
    return results.every(item => item) && !error.invalidBTIds.length && !error.invalidBTQIds.length
  }

  areCategoriesValidToSave = (selectedCategoryIds, updatedCategoryLabel) => {
    return selectedCategoryIds.length > 0 && trim(updatedCategoryLabel).length
  }

  hasValidChangesToSave = () => {
    const { dirty, selectedCategoryIds = [], updatedCategoryLabel = '', incExcForm, brandTrackerForm } = this.props

    const catsValid = this.areCategoriesValidToSave(selectedCategoryIds, updatedCategoryLabel)
    const btsValid = this.areBrandTrackersValidToSave(brandTrackerForm)

    // If any category related variables are present but are not valid, return false
    if ((updatedCategoryLabel || selectedCategoryIds.length) && !catsValid) {
      return false
    }

    // If any brand tracker related variables are present but are not valid, return false
    if (brandTrackerForm.brandTrackers.length && !btsValid) {
      return false
    }

    // return true only when the forms are dirty and there are valid changes to save
    return (brandTrackerForm.dirty || incExcForm.dirty || dirty) && (catsValid || btsValid)
  }

  saveHandler = () => {
    const { onComplete } = this.props
    this.saveCategoryDetails(onComplete) // Chnage Name from Category To BCI Details
  }

  saveAndNextHandler = () => {
    const { onComplete, onNext } = this.props
    if (this.hasValidChangesToSave()) {
      const callback = () => {
        onComplete()
        onNext()
      }
      this.saveCategoryDetails(callback) // Chnage Name from Category To BCI Details
    } else {
      onNext()
    }
  }

  prepareIncExcQueryPayload = () => {
    const {
      incExcForm: { queryTree, dirty, action }
    } = this.props
    if (!dirty) return {}
    return {
      inclExclQuery: {
        action,
        query: queryTree
      }
    }
  }

  prepareBrandTrackerQueryPayload = () => {
    const {
      brandTrackerForm: { dirty, brandTrackers = [] }
    } = this.props
    if (!dirty || !brandTrackers.length) return {}
    return {
      brandTrackers
    }
  }

  // Chnage Name from Category To BCI Details
  saveCategoryDetails = callback => {
    const { deliverableId, selectedCategoryIds, saveCategoryConfig, updatedCategoryLabel } = this.props
    let payload = {
      deliverableId,
      selectedCategoryIds,
      categoryLabel: updatedCategoryLabel
    }
    const incExcQueryPayload = this.prepareIncExcQueryPayload()
    payload = { ...payload, ...incExcQueryPayload }
    const brandTrackerQueryPayload = this.prepareBrandTrackerQueryPayload()
    payload = { ...payload, ...brandTrackerQueryPayload }
    // Chnage Name from Category To BCI Details
    saveCategoryConfig(payload, callback)
  }

  checkIfFormIsDirty = () => {
    const { dirty, incExcForm, brandTrackerForm } = this.props
    return dirty || incExcForm.dirty || brandTrackerForm.dirty
  }

  renderActions = () => {
    // Chnage resetCategoryForm name
    const { editable, resetCategoryForm } = this.props
    return (
      <div className="sb-form-footer">
        <div className="form-action-buttons">
          {editable && (
            <React.Fragment>
              <Button
                className="btn-cancel btn-small"
                disabled={!this.checkIfFormIsDirty()}
                onClick={resetCategoryForm}
              >
                Cancel
              </Button>
              <Button
                disabled={!this.hasValidChangesToSave()}
                className="btn_custom_secondary btn-small"
                onClick={this.saveHandler}
              >
                {'Save'}
              </Button>
            </React.Fragment>
          )}
          <Button className="btn_custom btn-small" onClick={this.saveAndNextHandler}>
            Next
          </Button>
        </div>
      </div>
    )
  }

  render() {
    return (
      <div className="sb-form">
        <HelpBlock>* Atleast one of Categories or Brand Trackers need to be configured.</HelpBlock>
        {this.getTabs()}
        {this.renderActions()}
      </div>
    )
  }
}

BCIConfig.propTypes = {
  editable: PropTypes.bool,
  dirty: PropTypes.bool,
  resetCategoryForm: PropTypes.func,
  onComplete: PropTypes.func,
  onNext: PropTypes.func,
  deliverableId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  fetchCategoryConfig: PropTypes.func,
  saveCategoryConfig: PropTypes.func,
  selectedCategoryIds: PropTypes.array,
  loading: PropTypes.bool,
  incExcForm: PropTypes.object,
  categoryLabel: PropTypes.string,
  updatedCategoryLabel: PropTypes.string,
  brandTrackerForm: PropTypes.object
}

function mapStateToProps({
  dm: { selectedDeliverable: { id: deliverableId } = {} },
  deliveryConfig: { categoryForm, incExcForm, brandTrackerForm }
}) {
  return {
    deliverableId,
    selectedCategoryIds: categoryForm.selectedCategoryIds,
    loading: categoryForm.loading,
    dirty: categoryForm.dirty,
    incExcForm,
    brandTrackerForm,
    categoryLabel: categoryForm.categoryLabel,
    updatedCategoryLabel: categoryForm.updatedCategoryLabel
  }
}

export default connect(mapStateToProps, {
  resetCategoryForm,
  fetchCategoryConfig,
  saveCategoryConfig
})(BCIConfig)
