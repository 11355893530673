import React from 'react'
import { Controller } from 'react-hook-form'
import PropTypes from 'prop-types'
import moment from 'moment'
import { supraQCToolDefaults } from 'constants/constants.js'
import InputField from 'components/FormInputComponents/InputField'
import DateRangeFilter from 'components/content/DateRangeFilter'

const { SAMPLING_QC_START_DATE } = supraQCToolDefaults
const DateRangeInput = ({ control, errors, info }) => {
  return (
    <InputField
      label="Date Range"
      required
      info={info}
      className="text-start"
      validationMessage={errors.dateRange?.timeEnd?.message || errors.dateRange?.timeStart?.message}
      labelSize={4}
    >
      <div className="d-flex">
        <Controller
          name="dateRange"
          control={control}
          render={({ field: { onChange, value } }) => (
            <DateRangeFilter
              className="ml-0"
              handleDateSelection={selection => {
                const [timeStart, timeEnd] = selection
                onChange({ timeStart, timeEnd })
              }}
              startDate={value.timeStart}
              endDate={value.timeEnd}
              rangeStart={SAMPLING_QC_START_DATE}
              rangeEnd={moment().format('YYYY-MM-DD')}
            />
          )}
        />
      </div>
    </InputField>
  )
}

DateRangeInput.propTypes = {
  control: PropTypes.object.isRequired,
  info: PropTypes.string,
  errors: PropTypes.object
}

export default DateRangeInput
