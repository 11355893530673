import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { debounce } from 'lodash'
import QueryBuilderTree from './QueryBuilderTree'
import {
  createBrandTracker,
  addBTQueryTree,
  onBrandSearchChange,
  handleBrandChange,
  handleBTLabelChange,
  updateBTQQueryTree,
  updateBTBQQueryTree,
  emptyBTQQueryTree,
  emptyBTBQQueryTree,
  removeBTQuery,
  removeBT,
  getBTInsertSQL,
  getBTUpdateSQL,
  revertAllBTChanges,
  fetchBTQTypeChildren,
  fetchBTBQTypeChildren,
  createBTBQMultipleRules,
  createBTQMultipleRules,
  createBTMultipleRules,
  fetchBTTypeChildren,
  updateBTQueryTree,
  emptyBTQueryTree
} from '../../../../../actions/delivery-config-actions'
import Loader from '../../../../Loader'
import TextInputField from '../../../Common/TextInputField'
import ChipSelectField from '../../../Common/ChipSelectField'
import { Button, Glyphicon, ControlLabel, FormGroup } from 'react-bootstrap'
import { getQuerySQL } from '../../../../../actions/search-actions'
import { createAlert } from '../../../../../actions/app-actions'
import { addCustomModal } from '../../../../../actions/modal-actions'
import CopyToClipboardModal from '../../../../../components/Modals/CopyToClipboardModal'

class BrandTracker extends Component {
  componentDidUpdate(prevProps) {
    if (prevProps.querySQLLoading === true && this.props.querySQLLoading === false && this.props.querySQL) {
      this.openCopyModal(this.props.querySQL)
    }
    if (prevProps.modalSQLLoading === true && this.props.modalSQLLoading === false && this.props.modalSQL) {
      this.openCopyModal(this.props.modalSQL)
    }
  }

  openCopyModal = sql => {
    this.props.addCustomModal({
      modalType: 'successModal',
      id: 'copy-to-clipboard-modal',
      className: 'textarea-modal',
      title: 'Query SQL',
      content: (
        <CopyToClipboardModal
          note="Note: Rules with contains, not contains, contains (stem) and not contains (stem) have been evaluated as if they are wildcard rules."
          text={sql}
          onCopy={this.onCopy}
        />
      )
    })
  }

  onBrandSearchChange = debounce(this.props.onBrandSearchChange, 500)
  filterBrandSuggestions = (brandSuggestions, btIndex) => {
    const { brandTrackers } = this.props
    const brandIds = brandTrackers[btIndex].queries
      .filter(queryObj => !queryObj.action || queryObj.action !== 'delete')
      .map(queryObj => queryObj.brandId)
    return brandSuggestions.filter(brand => {
      return !brandIds.includes(brand.id)
    })
  }

  renderBTQueries = (queries, btIndex) => {
    const {
      brandSuggestions,
      handleBrandChange,
      associatedBrandMap,
      updateBTQQueryTree,
      updateBTBQQueryTree,
      emptyBTQQueryTree,
      emptyBTBQQueryTree,
      removeBTQuery,
      dirty,
      fetchBTQTypeChildren,
      fetchBTBQTypeChildren,
      createBTQMultipleRules,
      createBTBQMultipleRules,
      editable
    } = this.props
    const btQueryList = []
    queries.forEach(({ brandQuery, query, brandId, action, isBrandInvalid, id: btqId }, index) => {
      if (action === 'delete') return
      let selectedBrandObj = null
      if (brandId) {
        selectedBrandObj = { id: brandId, value: associatedBrandMap[brandId].brandName }
      }
      btQueryList.push(
        <div className={'bt-query-wrapper'} key={index}>
          {editable && (
            <div className="bt-actions">
              <button
                type="button"
                className={'btn_custom_bg_red btn_custom btn_small pull-right'}
                onClick={() => removeBTQuery(btIndex, index, btqId)}
              >
                Remove Brand Query
              </button>
            </div>
          )}
          <div className="brand-query">
            <ChipSelectField
              id={`brandId-${index}`}
              label="Items Branded as"
              required
              selectedValue={selectedBrandObj}
              onSelectionChange={brand => handleBrandChange(brand, btIndex, index, btqId)}
              labelKey="value"
              onSearchChange={search => {
                this.onBrandSearchChange(search, btIndex, index)
              }}
              searchSuggestions={this.filterBrandSuggestions(brandSuggestions, btIndex)}
              help={isBrandInvalid ? 'Brand cannot be empty' : null}
              validationState={dirty && isBrandInvalid ? 'error' : null}
              placeholder="Select Brand"
              readOnly={!editable}
            />
            <span className="query-combinator">OR</span>
            <br />
            <FormGroup>
              <ControlLabel>{'Non Branded Items Matching'}</ControlLabel>
            </FormGroup>
            <QueryBuilderTree
              key={index}
              createMultipleRules={(event, ruleId, values) =>
                createBTBQMultipleRules({
                  event,
                  ruleId,
                  values,
                  btIndex,
                  index
                })
              }
              fetchTypeChildren={(type, value, id) =>
                fetchBTBQTypeChildren({
                  type,
                  value,
                  id,
                  btIndex,
                  index
                })
              }
              handleQueryTreeChanged={(brandQuery, event) => updateBTBQQueryTree(brandQuery, event, btIndex, index)}
              clearQueryTree={() => emptyBTBQQueryTree(btIndex, index)}
              queryTree={brandQuery}
              editable={editable}
              showQuerySQLModal={() => this.showQuerySQLModal(brandQuery)}
            />
          </div>
          <br />
          <span className="query-combinator">AND</span>
          <br />
          <FormGroup>
            <ControlLabel>{'Brand Specific Includes and Excludes'}</ControlLabel>
          </FormGroup>
          <QueryBuilderTree
            key={index}
            createMultipleRules={(event, ruleId, values) =>
              createBTQMultipleRules({
                event,
                ruleId,
                values,
                btIndex,
                index
              })
            }
            fetchTypeChildren={(type, value, id) =>
              fetchBTQTypeChildren({
                type,
                value,
                id,
                btIndex,
                index
              })
            }
            handleQueryTreeChanged={(query, event) => updateBTQQueryTree(query, event, btIndex, index)}
            clearQueryTree={() => emptyBTQQueryTree(btIndex, index)}
            queryTree={query}
            editable={editable}
            showQuerySQLModal={() => this.showQuerySQLModal(query)}
          />
        </div>
      )
    })
    return btQueryList
  }

  renderBrandTrackers = () => {
    const {
      brandTrackers,
      addBTQueryTree,
      removeBT,
      dirty,
      getBTInsertSQL,
      getBTUpdateSQL,
      editable,
      createBTMultipleRules,
      fetchBTTypeChildren,
      updateBTQueryTree,
      emptyBTQueryTree
    } = this.props
    const brandTrackerList = []
    brandTrackers.forEach(({ action, id, isLabelPresent, label, query, queries }, index) => {
      if (action === 'delete') return
      brandTrackerList.push(
        <div className={'brand-tracker'} key={id}>
          {editable && (
            <div className={'bt-actions'}>
              <button
                type="button"
                className={'btn_custom_bg_red btn_custom btn_medium pull-right'}
                onClick={() => removeBT(index, id)}
              >
                Remove Brand Tracker
              </button>
              <button type="button" className={'btn_custom btn_medium pull-right'} onClick={() => getBTInsertSQL(id)}>
                Get Insert SQL
              </button>
              <button type="button" className={'btn_custom btn_medium pull-right'} onClick={() => getBTUpdateSQL(id)}>
                Get Update SQL
              </button>
            </div>
          )}
          <TextInputField
            id="bt-label-name"
            label="Brand Tracker Label"
            help={
              isLabelPresent
                ? 'Brand tracker label is already present'
                : typeof label === 'string' && !label.length
                ? 'Brand tracker label cannot be empty'
                : null
            }
            validationState={dirty && (isLabelPresent || (typeof label === 'string' && !label.length)) ? 'error' : null}
            required
            type="text"
            value={label || ''}
            onChange={event => this.props.handleBTLabelChange(event.target.value, index, id)}
            autoFocus
            readOnly={!editable}
          />
          <FormGroup>
            <ControlLabel>{'Tracker Specific Includes and Excludes'}</ControlLabel>
          </FormGroup>
          <QueryBuilderTree
            key={index}
            createMultipleRules={(event, ruleId, values) =>
              createBTMultipleRules({
                event,
                ruleId,
                values,
                btIndex: index
              })
            }
            fetchTypeChildren={(type, value, id) =>
              fetchBTTypeChildren({
                type,
                value,
                id,
                btIndex: index
              })
            }
            handleQueryTreeChanged={(query, event) => updateBTQueryTree(query, event, index)}
            clearQueryTree={() => emptyBTQueryTree(index)}
            queryTree={query}
            editable={editable}
            showQuerySQLModal={() => this.showQuerySQLModal(query)}
          />
          <br />
          <FormGroup>
            <ControlLabel>{'Brands'}</ControlLabel>
          </FormGroup>
          {this.renderBTQueries(queries, index)}
          {editable && (
            <Button bsStyle="link" className={'btn_link btn-small bt-new-query'} onClick={() => addBTQueryTree(index)}>
              <Glyphicon glyph="plus" />
              {` New Brand Query`}
            </Button>
          )}
        </div>
      )
    })
    return brandTrackerList
  }

  onCopy = (text, result) => {
    const type = result ? 'info' : 'danger'
    const title = result ? 'SQL copied to clipboard' : 'There was an error trying to copy the SQL'
    this.props.createAlert(type, '', title)
  }

  showQuerySQLModal = async query => {
    await this.props.getQuerySQL(query)
  }

  render() {
    const { dirty, createBrandTracker, revertAllBTChanges, editable, loading } = this.props
    return (
      <React.Fragment>
        {loading && <Loader overlap />}
        {editable && (
          <div className="bt-actions">
            <button type="button" className={'btn_custom btn_medium pull-right'} onClick={createBrandTracker}>
              Add New Brand Tracker
            </button>
            {dirty && (
              <button
                type="button"
                className={'btn_custom_secondary btn_medium pull-right'}
                disabled={!dirty}
                onClick={revertAllBTChanges}
              >
                Revert All Changes
              </button>
            )}
          </div>
        )}
        {this.renderBrandTrackers()}
      </React.Fragment>
    )
  }
}

BrandTracker.propTypes = {
  dirty: PropTypes.bool,
  createBrandTracker: PropTypes.func,
  brandTrackers: PropTypes.array,
  addBTQueryTree: PropTypes.func,
  onBrandSearchChange: PropTypes.func,
  brandSuggestions: PropTypes.array,
  handleBrandChange: PropTypes.func,
  handleBTLabelChange: PropTypes.func,
  associatedBrandMap: PropTypes.object,
  updateBTQQueryTree: PropTypes.func,
  updateBTBQQueryTree: PropTypes.func,
  emptyBTQQueryTree: PropTypes.func,
  emptyBTBQQueryTree: PropTypes.func,
  createBTMultipleRules: PropTypes.func,
  fetchBTTypeChildren: PropTypes.func,
  updateBTQueryTree: PropTypes.func,
  emptyBTQueryTree: PropTypes.func,
  removeBTQuery: PropTypes.func,
  removeBT: PropTypes.func,
  getBTInsertSQL: PropTypes.func,
  getBTUpdateSQL: PropTypes.func,
  revertAllBTChanges: PropTypes.func,
  fetchBTQTypeChildren: PropTypes.func,
  fetchBTBQTypeChildren: PropTypes.func,
  createBTQMultipleRules: PropTypes.func,
  createBTBQMultipleRules: PropTypes.func,
  addCustomModal: PropTypes.func,
  getQuerySQL: PropTypes.func,
  createAlert: PropTypes.func,
  editable: PropTypes.bool,
  loading: PropTypes.bool,
  querySQL: PropTypes.string,
  querySQLLoading: PropTypes.bool,
  modalSQL: PropTypes.string,
  modalSQLLoading: PropTypes.bool
}

function mapStateToProps({
  search: { querySQL, querySQLLoading },
  deliveryConfig: {
    brandTrackerForm: { brandTrackers, brandSuggestions, associatedBrandMap, dirty, loading },
    modalSQL,
    modalSQLLoading
  }
}) {
  return {
    querySQL,
    querySQLLoading,
    modalSQL,
    modalSQLLoading,
    brandTrackers,
    brandSuggestions,
    associatedBrandMap,
    dirty,
    loading
  }
}

export default connect(mapStateToProps, {
  createBrandTracker,
  addBTQueryTree,
  onBrandSearchChange,
  handleBrandChange,
  handleBTLabelChange,
  updateBTQQueryTree,
  updateBTBQQueryTree,
  emptyBTQQueryTree,
  emptyBTBQQueryTree,
  removeBTQuery,
  removeBT,
  getBTInsertSQL,
  getBTUpdateSQL,
  revertAllBTChanges,
  fetchBTQTypeChildren,
  fetchBTBQTypeChildren,
  createBTQMultipleRules,
  createBTBQMultipleRules,
  createBTMultipleRules,
  fetchBTTypeChildren,
  updateBTQueryTree,
  emptyBTQueryTree,
  getQuerySQL,
  createAlert,
  addCustomModal
})(BrandTracker)
