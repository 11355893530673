import React from 'react'
import PropTypes from 'prop-types'
import { OverlayTrigger, Popover, Button } from 'react-bootstrap'

const Param = ({ children }) => {
  return <strong>${'{' + children + '}'}</strong>
}

Param.propTypes = {
  children: PropTypes.node
}

const QueryHelp = () => {
  const helpContainer = (
    <Popover id="feed-modules-sql-binding-paramenters-help">
      <p>You can use the following bindings. It will be replaced automatically before running the SQL query.</p>
      <p>
        <span className="h4">
          The format is: <Param>param</Param>
        </span>{' '}
        <br />
        Deprecated format (Redshift only) is:{' '}
        <s>
          <strong>:param</strong>
        </s>
      </p>
      <p>
        <span className="h5">Group value:</span>
      </p>
      <ul>
        <li>
          <Param>obfuscationTypeId</Param> - take from Group.ObfuscationTypeId
        </li>
      </ul>
      <p>
        <span className="h5">Frequency settings:</span>
      </p>
      <ul>
        <li>
          <Param>tableName</Param> - specified table name
        </li>
        <li>
          <Param>extractId</Param> - Id of specified extractTypeId
        </li>
      </ul>
      <p>
        <span className="h5">Global Feed configuration:</span>
      </p>
      <ul>
        <li>
          <Param>skipPartnerIdExcludes</Param> (plain)
        </li>
        <li>
          <Param>partnerIds</Param> (list)
        </li>
        <li>
          <Param>skipMailboxProviderIdExcludes</Param> (plain)
        </li>
        <li>
          <Param>mailboxProviderIds</Param> (list)
        </li>
        <li>
          <Param>skipPartnerMailboxProviderIdExcludes</Param> (plain)
        </li>
        <li>
          <Param>partnerMailboxProviderExclusionSQL</Param> (list)
        </li>
      </ul>
      <p>
        <span className="h5">Additional UI Filter plain:</span>
      </p>
      <ul>
        <li>
          <Param>sourceId</Param>
        </li>
        <li>
          <Param>skipCategoryIds</Param>
        </li>
        <li>
          <Param>skipAttributeIds</Param>
        </li>
        <li>
          <Param>skipMinOrderDate</Param>
        </li>
        <li>
          <Param>minOrderDate</Param>
        </li>
        <li>
          <Param>skipMaxOrderDate</Param>
        </li>
        <li>
          <Param>maxOrderDate</Param>
        </li>
        <li>
          <Param>skipMailboxProviderIdExcludes</Param>
        </li>
        <li>
          <Param>skipPartnerMailboxProviderIdExcludes</Param>
        </li>
      </ul>

      <p>
        <span className="h5">Additional UI Filter list:</span>
      </p>
      <ul>
        <li>
          <Param>merchantIds</Param>
        </li>
        <li>
          <Param>categoryIds</Param>
        </li>
        <li>
          <Param>attributeIds</Param>
        </li>
      </ul>
    </Popover>
  )

  return (
    <OverlayTrigger trigger="click" placement="left" overlay={helpContainer}>
      <Button className="btn-link">What bindings can I use?</Button>
    </OverlayTrigger>
  )
}

export default QueryHelp
