import React from 'react'
import PropTypes from 'prop-types'

import { Glyphicon, ButtonToolbar, ToggleButtonGroup, ToggleButton } from 'react-bootstrap'
import InputField from 'components/FormInputComponents/InputField'

const ConfigurationSelector = ({ isUseLocalConfig, onChange }) => {
  return (
    <>
      <InputField
        label={<span className="h4">Use configuration</span>}
        info="Choose the used configuration for this category. Global overrides all local changes. Local will not be touched by any global changes."
      >
        <ButtonToolbar>
          <ToggleButtonGroup
            type="radio"
            name="categoryFilter"
            defaultValue={isUseLocalConfig}
            onChange={value => onChange({ isUseLocalConfig: value })}
          >
            <ToggleButton value={false}>
              <Glyphicon glyph="globe" className="mr-2" />
              Global
            </ToggleButton>
            <ToggleButton value>
              <Glyphicon glyph="home" className="mr-2" />
              Local
            </ToggleButton>
          </ToggleButtonGroup>
        </ButtonToolbar>
      </InputField>

      <div className="category-tests-modal-section h4">
        {isUseLocalConfig ? (
          <span>
            Category <b>Local</b> test configuration:
          </span>
        ) : (
          <span>
            Category <b>inherits Global</b> test configuration. You can change only not-global fields:
          </span>
        )}
      </div>
    </>
  )
}

ConfigurationSelector.propTypes = {
  isUseLocalConfig: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired
}

export default ConfigurationSelector
