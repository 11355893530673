import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import Loader from '../../Loader'
import Unauthorized from '../../Unauthorized'
import DocumentTitle from 'react-document-title'
import { BootstrapTable } from 'react-bootstrap-table'
import { Alert, Button, Glyphicon, ButtonGroup, ButtonToolbar } from 'react-bootstrap'

import UserService from '../../../services/user-service'
import { fetchDeliveryLogs, updateDeliveryLogsTable } from '../../../actions/dm-actions'

import { buildColumns, trClassFormat } from './deliveryLogsColumns'
import WithErrorBoundaryWrapper from 'components/WithErrorBoundaryWrapper/WithErrorBoundaryWrapper'
import { appName, products as PRODUCTS } from '../../../constants/constants'
import './index.scss'

class DeliveryLogsPage extends Component {
  componentDidMount() {
    if (this.props.hasDeliveryManagerAccess) {
      this.fetchDeliveryLogs()
    }
  }

  fetchDeliveryLogs = (payload = {}) => {
    const { pageSize = this.props.pageSize, pageNumber = this.props.pageNumber } = payload

    this.props.fetchDeliveryLogs({
      page: pageNumber,
      count: pageSize
    })
  }

  onPageChange = pageNumber => {
    this.props.updateDeliveryLogsTable({ pageNumber })
    this.fetchDeliveryLogs({ pageNumber })
  }

  onSizePerPageChange = pageSize => {
    this.props.updateDeliveryLogsTable({
      pageSize,
      pageNumber: 1
    })
    this.fetchDeliveryLogs({
      pageSize,
      pageNumber: 1
    })
  }

  getTableOptions = () => {
    return {
      sortOrder: 'desc',
      sortName: 'createdAt',
      sizePerPageList: [25, 50, 100],
      page: this.props.pageNumber,
      onPageChange: this.onPageChange,
      sizePerPage: this.props.pageSize,
      onSearchChange: this.onSearchChange,
      onSizePerPageList: this.onSizePerPageChange
    }
  }

  render() {
    const { error, loading, deliveryLogs, documentTitle, hasDeliveryManagerAccess } = this.props

    if (!hasDeliveryManagerAccess) return <Unauthorized />

    return (
      <DocumentTitle title={documentTitle ? `${appName} | ${documentTitle}` : appName}>
        <React.Fragment>
          {loading && <Loader loading overlap />}

          <div className="delivery-logs-page">
            <ButtonToolbar>
              <ButtonGroup className="pull-right">
                <Button onClick={this.fetchDeliveryLogs}>
                  Reload <Glyphicon glyph="refresh" />
                </Button>
              </ButtonGroup>
            </ButtonToolbar>

            <BootstrapTable
              remote
              hover
              striped
              bordered
              condensed
              responsive
              pagination
              data={deliveryLogs}
              trClassName={trClassFormat}
              className="delivery-logs-table"
              ref={el => {
                this.table = el
              }}
              options={this.getTableOptions()}
              fetchInfo={{ dataTotalSize: 10000 }}
            >
              {buildColumns()}
            </BootstrapTable>

            {error && <Alert bsStyle="danger">{error}</Alert>}
          </div>
        </React.Fragment>
      </DocumentTitle>
    )
  }
}

DeliveryLogsPage.propTypes = {
  error: PropTypes.string,
  loading: PropTypes.bool,
  pageSize: PropTypes.number,
  pageNumber: PropTypes.number,
  deliveryLogs: PropTypes.array,
  documentTitle: PropTypes.string,
  fetchDeliveryLogs: PropTypes.func,
  updateDeliveryLogsTable: PropTypes.func,
  hasDeliveryManagerAccess: PropTypes.bool
}

DeliveryLogsPage.defaultProps = {
  documentTitle: 'Delivery Logs'
}

const mapStateToProps = ({
  session: { user },
  dm: {
    deliveryLogs: { loading, error, data, pageSize, pageNumber }
  }
}) => {
  const isPortalAdmin = user ? UserService.isPortalAdmin(user) : false
  const hasProductAccess =
    user && user.products ? UserService.isProductAuthorized(user.products, PRODUCTS.adminToolsDeliveryManager) : false

  return {
    error,
    loading,
    pageSize,
    pageNumber,
    deliveryLogs: data,
    hasDeliveryManagerAccess: isPortalAdmin || hasProductAccess
  }
}

export default WithErrorBoundaryWrapper(
  connect(mapStateToProps, {
    fetchDeliveryLogs,
    updateDeliveryLogsTable
  })(DeliveryLogsPage),
  '"Admin Delivery Activity" page'
)
