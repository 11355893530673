import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Tabs, Tab } from 'react-bootstrap'
import { setSelectedTab } from '../../actions/supra-qc-tool-actions.js'
import { supraQCToolTabs } from '../../constants/constants'
import DictionaryTab from './DictionaryTab'
import SamplingSessions from './SamplingSessions'
import SamplingQC from './SamplingQC'

const { DICTIONARY, SAMPLING_SESSIONS, SAMPLING_QC } = supraQCToolTabs

class SupraQCToolContent extends Component {
  render() {
    return (
      <Tabs
        id="supra-qc-tabs"
        className="tab-label portal-tabs mt-4"
        onSelect={this.props.setSelectedTab}
        activeKey={this.props.isActiveTab}
      >
        <Tab eventKey={DICTIONARY} title={DICTIONARY} unmountOnExit>
          <DictionaryTab />
        </Tab>
        <Tab eventKey={SAMPLING_SESSIONS} title={SAMPLING_SESSIONS} unmountOnExit>
          <SamplingSessions />
        </Tab>
        <Tab eventKey={SAMPLING_QC} title={SAMPLING_QC} unmountOnExit>
          <SamplingQC />
        </Tab>
      </Tabs>
    )
  }
}

SupraQCToolContent.propTypes = {
  setSelectedTab: PropTypes.func,
  isActiveTab: PropTypes.string
}

function mapStateToProps(state) {
  return {
    isActiveTab: state.supraQCTool.isActiveTab
  }
}

export default connect(mapStateToProps, {
  setSelectedTab
})(SupraQCToolContent)
