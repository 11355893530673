import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Button, Glyphicon } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import moment from 'moment'

import { DeployStatsActions } from './actions'
import { confirmModal } from 'actions/modal-actions'
import { orchestrate } from 'actions/task-actions'
import { JOB_TYPES } from 'components/JobListPage/job-list'

const DeployRulesTab = ({ stagingStat, productionStat, confirmModal, orchestrate, fetchRulesDeployStats }) => {
  useEffect(() => {
    fetchRulesDeployStats()
  })

  const getStatusIcon = isSucces => {
    return isSucces ? (
      <Glyphicon style={{ color: 'green' }} glyph="ok" title="Last deploy is successful" />
    ) : (
      <Glyphicon style={{ color: 'red' }} glyph="remove" title="Last deploy was failed " />
    )
  }

  const showDate = date => {
    return date ? moment(date).format('YYYY-MM-DD HH:mm:SS') : 'never'
  }

  const startDeploy = (environment, jobType) => {
    confirmModal(
      <span>
        Are you sure you want to deploy rules on
        <strong>
          <em>{` ${environment} `}</em>
        </strong>
      </span>,
      'Confirm Deploy Rules',
      { okButton: `Yes, Deploy on ${environment}` },
      () => orchestrate(jobType)
    )
  }

  const deployStaging = () => {
    startDeploy('Staging', JOB_TYPES.RULES_DEPLOY_STAGING)
  }

  const deployProduction = () => {
    startDeploy('Production', JOB_TYPES.RULES_DEPLOY_PRODUCTION)
  }

  const isProduction = process.env.REACT_APP_DEPLOY_ENV.toLowerCase() === 'production'

  return (
    <div className="rules-deploy">
      <div>
        <h2>
          Staging CME deploy <span>{getStatusIcon(stagingStat.isLastDeploySucces)}</span>
        </h2>
        {!stagingStat.isLastDeploySucces && (
          <p title={stagingStat.lastFailedDeploy}>
            Last deploy: <strong>{showDate(stagingStat.lastFailedDeploy)}</strong>
          </p>
        )}
        <p title={stagingStat.lastSuccessfulDeploy}>
          Last successful deploy: <strong>{showDate(stagingStat.lastSuccessfulDeploy)}</strong>
        </p>
        <p>
          <Button onClick={deployStaging}>
            <Glyphicon glyph="cloud-upload" />
            Deploy rules into CME Staging
          </Button>
        </p>
        <p>
          <Link to={`/admin/jobs/?searchField=type&&searchValue=${JOB_TYPES.RULES_DEPLOY_STAGING}`}>
            See deploy staging tasks in Job monitoring
          </Link>
        </p>
      </div>
      {isProduction && (
        <div>
          <h2>
            Production CME deploy <span>{getStatusIcon(productionStat.isLastDeploySucces)}</span>
          </h2>
          {!productionStat.isLastDeploySucces && (
            <p title={productionStat.lastFailedDeploy}>
              Last deploy: <strong>{showDate(productionStat.lastFailedDeploy)}</strong>
            </p>
          )}
          <p title={productionStat.lastSuccessfulDeploy}>
            Last successful deploy: <strong>{showDate(productionStat.lastSuccessfulDeploy)}</strong>
          </p>
          <p>
            <Button onClick={deployProduction}>
              <Glyphicon glyph="cloud-upload" />
              Deploy rules into CME Production
            </Button>
          </p>
          <p>
            <Link to={`/admin/jobs/?searchField=type&&searchValue=${JOB_TYPES.RULES_DEPLOY_PRODUCTION}`}>
              See deploy production tasks in Job monitoring
            </Link>
          </p>
        </div>
      )}

      <div className="deploy-refresh">
        <Button onClick={fetchRulesDeployStats}>
          <Glyphicon glyph="refresh" />
          Refresh deploy information
        </Button>
      </div>
    </div>
  )
}

DeployRulesTab.propTypes = {
  stagingStat: PropTypes.object,
  productionStat: PropTypes.object,
  confirmModal: PropTypes.func,
  orchestrate: PropTypes.func,
  fetchRulesDeployStats: PropTypes.func.isRequired
}

const mapStateToProps = ({ rules: { deployStats } }) => ({
  stagingStat: deployStats.staging,
  productionStat: deployStats.production
})

function mapDispatchToProps(dispatch) {
  return {
    confirmModal: (content, title, okButton, callback) => dispatch(confirmModal(content, title, okButton, callback)),
    orchestrate: (jobType, metdata) => dispatch(orchestrate(jobType, metdata)),
    fetchRulesDeployStats: () => dispatch(DeployStatsActions.fetch())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DeployRulesTab)
