import ValueEditor from './niq-ValueEditor'
import DropdownSelector from './DropdownSelector'
import RemoveButton from './RemoveButton'
import DisableToggleButton from './DisableToggleButton'
import AddGroupButton from './AddGroupButton'
import AddRuleButton from './AddRuleButton'
import MinimizeToggleButton from './MinimizeToggleButton'
import EmptyQueryTreeButton from './EmptyQueryTreeButton'
import GetQuerySQLButton from './GetQuerySQLButton'
import RepeatRuleButton from './RepeatRuleButton'

const controls = {
  valueEditor: ValueEditor,
  combinatorSelector: DropdownSelector,
  fieldSelector: DropdownSelector,
  operatorSelector: DropdownSelector,
  removeGroupAction: RemoveButton,
  removeRuleAction: RemoveButton,
  addGroupAction: AddGroupButton,
  addRuleAction: AddRuleButton,
  disableToggleRuleGroupAction: DisableToggleButton,
  disableToggleRuleAction: DisableToggleButton,
  minimizeToggleAction: MinimizeToggleButton,
  emptyQueryTree: EmptyQueryTreeButton,
  getQuerySQL: GetQuerySQLButton,
  repeatRuleAction: RepeatRuleButton,
  repeatRuleGroupAction: RepeatRuleButton
}

export default controls
