import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Form, Panel, FormControl, FormGroup, ControlLabel, DropdownButton, MenuItem, Button } from 'react-bootstrap'
import moment from 'moment'
import CheckBox from 'components/CheckBox'

class DescriptionTableOptions extends Component {
  renderSortOptions() {
    const { sortOptions, sortBy } = this.props
    return Object.keys(sortOptions).map(key => (
      <MenuItem eventKey={key} key={key} active={sortBy === key}>
        {sortOptions[key]}
      </MenuItem>
    ))
  }

  getColumnSelector() {
    const { columnList, handleSelectChange, visibleColumns } = this.props
    return columnList.map((field, index) => (
      <div key={index} className="column-selector">
        <CheckBox
          key={field.id}
          style={{ width: 20 }}
          className={'item'}
          valueDidChange={() => handleSelectChange(field.value)}
          checked={visibleColumns ? visibleColumns.indexOf(field.value) > -1 : false}
          label={field.label}
        />
      </div>
    ))
  }

  render() {
    const {
      showToolbar,
      selectedSize,
      onDescriptionSelectionChanged,
      refreshDescriptionData,
      sortOptions,
      sortBy
    } = this.props
    return showToolbar ? (
      <Panel defaultExpanded>
        <Panel.Collapse>
          <Panel.Body>
            <div className="description-table-option">
              <Form inline>
                <FormGroup>
                  <ControlLabel>
                    {' '}
                    <h5>Select Top</h5>
                  </ControlLabel>
                  &nbsp;&nbsp;&nbsp;
                  <FormControl
                    value={selectedSize}
                    className="select-size"
                    type="number"
                    placeholder="200"
                    onChange={event => {
                      onDescriptionSelectionChanged('selectedSize', parseInt(event.target.value, 10))
                    }}
                    onKeyPress={event => {
                      if (event.charCode === 13) {
                        event.preventDefault()
                        event.stopPropagation()
                        refreshDescriptionData()
                      }
                    }}
                  />
                  &nbsp;&nbsp;&nbsp;
                  <ControlLabel>
                    <h5>values by</h5>
                  </ControlLabel>
                  &nbsp;&nbsp;&nbsp;
                  <DropdownButton
                    bsSize="small"
                    className="select-sort-by"
                    id="QCDescSortBy"
                    title={sortOptions[sortBy]}
                    onSelect={value => {
                      onDescriptionSelectionChanged('sortBy', value)
                    }}
                  >
                    {this.renderSortOptions()}
                  </DropdownButton>
                  <ControlLabel>
                    <h5>
                      &nbsp;&nbsp;from&nbsp;
                      <strong>{moment(this.props.from).format('MMM-DD-YYYY')}</strong>
                      &nbsp;to&nbsp;
                      <strong>{moment(this.props.to).format('MMM-DD-YYYY')}</strong>
                      &nbsp;&nbsp;
                    </h5>
                  </ControlLabel>
                  <Button className="refresh-widget-btn" bsStyle="info" onClick={() => refreshDescriptionData()}>
                    Refresh
                  </Button>
                </FormGroup>
              </Form>
            </div>
            <hr />
            {this.getColumnSelector()}
          </Panel.Body>
        </Panel.Collapse>
      </Panel>
    ) : null
  }
}

DescriptionTableOptions.propTypes = {
  selectedSize: PropTypes.number,
  sortBy: PropTypes.string,
  onDescriptionSelectionChanged: PropTypes.func,
  refreshDescriptionData: PropTypes.func,
  sortOptions: PropTypes.object,
  from: PropTypes.string,
  to: PropTypes.string,
  columnList: PropTypes.array,
  visibleColumns: PropTypes.array,
  handleSelectChange: PropTypes.func,
  showToolbar: PropTypes.bool
}

DescriptionTableOptions.defaultProps = {
  sortOptions: {
    sales: 'Sales'
  }
}

export default DescriptionTableOptions
