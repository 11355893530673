import { connect } from 'react-redux'
import { switchToggleFeed, saveFeed, fetchFeeds, fetchFeedsSearchEntityChanged } from '../../actions/feed-actions'
import { updateFilter } from '../../actions/filter-actions'

import FeedListView from './index.view'

import { getFeeds, getFeedsCount } from '../../selectors/feed-selector'
import { getGroupsById } from '../../selectors/group-selector'
import UserService from '../../services/user-service'

function mapStateToProps(state, props) {
  return {
    search: state.filter.feeds,
    selectedFeeds: state.jobListText.selectedJobs,
    feeds: getFeeds(state),
    groups: getGroupsById(state),
    remoteRowCount: getFeedsCount(state),
    userProducts: UserService.getProducts(state.session.user),
    query: props.location.query,
    searchEntity: state.feeds.searchEntity
  }
}

function mapDispatchToProps(dispatch) {
  return {
    updateFilter: text => dispatch(updateFilter(text, 'feeds')),
    switchToggleFeed: feed => dispatch(switchToggleFeed(feed)),
    saveFeed: (data, callback) => dispatch(saveFeed({ ...data, callback })),
    fetchFeeds: (offset, limit, search) => dispatch(fetchFeeds(offset, limit, search)),
    onSearchEntityChanged: searchEntity => dispatch(fetchFeedsSearchEntityChanged(searchEntity))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FeedListView)
