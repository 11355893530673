import React from 'react'
import PropTypes from 'prop-types'
import { useLocation, Redirect } from 'react-router-dom'
import DocumentTitle from 'react-document-title'
import cx from 'classnames'

import { appName } from 'constants/constants'
import TabNavItem from './TabNavItem'
import useBasePath from './useBasePath'

/**
 * Implement Tabs which use URL for storing tab state
 */
const TabNav = ({ children }) => {
  const location = useLocation()

  const defaultPath = getDefaultPathIfNeed(children, location.pathname)
  if (defaultPath) {
    return <Redirect to={defaultPath} />
  }

  let activeComponent = null
  let activeTabName = null

  return (
    <div className="portal-tabs">
      <ul role="tablist" className="nav nav-tabs">
        {React.Children.map(children, child => {
          if (React.isValidElement(child)) {
            let isActive = false
            if (location.pathname === child.props.path) {
              activeComponent = child.props.component
              activeTabName = child.props.name || child.props.label
              isActive = true
            }
            return (
              <li key={child.name} role="presentation" className={cx({ active: isActive })}>
                {child}
              </li>
            )
          }
          // eslint-disable-next-line no-console
          console.warn('The child element should be a valid React element')
        })}
      </ul>
      <DocumentTitle title={activeTabName ? `${appName} | ${activeTabName}` : appName} />
      <div className="tab-content">{activeComponent}</div>
    </div>
  )
}

const getDefaultPathIfNeed = (children, currentPath) => {
  const locations = []
  let defaultPath
  React.Children.forEach(children, child => {
    locations.push(child.props?.path)
    if (child.props?.default) {
      defaultPath = child.props.path
    }
  })

  // take the first tab as default if not set
  defaultPath = defaultPath || locations[0]

  if (!locations.includes(currentPath)) {
    return defaultPath
  }

  return null
}

TabNav.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired
}

TabNav.Item = TabNavItem

export { useBasePath }
export default TabNav
