import React from 'react'
import { useDispatch } from 'react-redux'
import { Button, Glyphicon } from 'react-bootstrap'
import { Link } from 'react-router-dom'

import { confirmModal } from 'actions/modal-actions'
import { orchestrate } from 'actions/task-actions'
import { JOB_TYPES } from 'components/JobListPage/job-list'
import { DELIVERY_JOB_STATUS } from 'constants/deliveryJobsConstants'

const CategoryTestsRunBatchButton = () => {
  const dispatch = useDispatch()

  const startJob = () => {
    dispatch(orchestrate(JOB_TYPES.CATEGORY_TESTS_RUN_BATCH))
  }

  const openConfirmModal = () => {
    dispatch(
      confirmModal(
        <div>
          <p>
            Run all Category Tests that have an active Delivery Job in progress (with status as &quot;
            {DELIVERY_JOB_STATUS.SCHEDULED.toUpperCase()}&quot; or &quot;{DELIVERY_JOB_STATUS.PROCESSING.toUpperCase()}
            &quot;).
          </p>
          <p>
            Note: To check run status see <i>{JOB_TYPES.CATEGORY_TESTS_RUN_BATCH}</i> task in Job Monitoring.
          </p>
          <p>
            <strong>
              <em>Are you sure you want to run testing process?</em>
            </strong>
          </p>
        </div>,
        'Confirm run Category Tests batch',
        { okButton: `Run Tests` },
        () => startJob()
      )
    )
  }

  return (
    <div className="rightFormButton category-test-run-all">
      <Button
        bsStyle="default"
        onClick={openConfirmModal}
        title="Run all Category Tests that have an active Delivery Job in progress"
      >
        <Glyphicon glyph="forward" />
        Run All Tests
      </Button>
      <Link className="pl-2" to={`/admin/jobs/?searchField=type&&searchValue=${JOB_TYPES.CATEGORY_TESTS_RUN_BATCH}`}>
        See all tasks
      </Link>
    </div>
  )
}

export default CategoryTestsRunBatchButton
