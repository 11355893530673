import React from 'react'
import PropTypes from 'prop-types'

import BooleandHighlightedText from './../BooleandHighlightedText'

const InfoBlock = ({ children }) => <div className="category-test-result-tooltip-section">{children}</div>
InfoBlock.propTypes = {
  children: PropTypes.node
}

const ResultTooltip = ({ name, isPass, isActive, children }) => {
  const getChildByDisplayName = (children, displayName) =>
    React.Children.toArray(children).find(child => child.type.displayName === displayName) || null

  const violation = getChildByDisplayName(children, 'TooltipViolation')
  const query = getChildByDisplayName(children, 'TooltipQuery')

  return (
    <>
      <InfoBlock>
        {name}
        {isPass !== null && (
          <>
            <span>: </span>
            <BooleandHighlightedText isPass={isPass} useHighlight={isActive} />
          </>
        )}
      </InfoBlock>
      <InfoBlock>{violation}</InfoBlock>
      <InfoBlock>
        Test is{' '}
        <BooleandHighlightedText isPass={isActive} useHighlight={!isActive}>
          <span>{isActive ? 'Active' : 'INACTIVE'}</span>
        </BooleandHighlightedText>
      </InfoBlock>
      {query && <InfoBlock>{query}</InfoBlock>}
    </>
  )
}

ResultTooltip.propTypes = {
  name: PropTypes.string.isRequired,
  isPass: PropTypes.bool,
  isActive: PropTypes.bool,
  notes: PropTypes.node,
  error: PropTypes.string,
  children: PropTypes.node
}

export default ResultTooltip
