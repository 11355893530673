import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'

import { createContractsLinkUrl, createDeliverablesLinkUrl } from '../../utils/delivery-center'

import ContractIcon from '../Icon/Contract'
import ClientIcon from '../Icon/FolderClose'
import DeliverableIcon from '../Icon/DeliverableIcon'
import Separator from '../Icon/BreadCrumbSeparator'

class BreadCrumbs extends PureComponent {
  render() {
    const { selectedClient = {}, selectedContract = {}, selectedDeliverable = {} } = this.props
    const { id: clientId, name: clientName } = selectedClient
    const { id: contractId, name: contractName } = selectedContract
    const { id: deliverableId, name: deliverableName } = selectedDeliverable
    return (
      <ul className="breadcrumb">
        <li>
          <NavLink to="/admin/dm/clients">Home</NavLink>
        </li>
        {clientId && (
          <React.Fragment>
            <Separator />
            <li className="bc-client">
              {contractId ? (
                <NavLink to={createContractsLinkUrl(selectedClient)}>
                  <ClientIcon />
                  {clientName}
                </NavLink>
              ) : deliverableId ? (
                <NavLink to={createDeliverablesLinkUrl(selectedClient)}>
                  <ClientIcon />
                  {clientName}
                </NavLink>
              ) : (
                <span>
                  <ClientIcon />
                  {clientName}
                </span>
              )}
            </li>
          </React.Fragment>
        )}
        {contractId && (
          <React.Fragment>
            <Separator />
            <li className="bc-contract">
              {deliverableId ? (
                <NavLink to={createDeliverablesLinkUrl(selectedClient, selectedContract)}>
                  <ContractIcon />
                  {contractName}
                </NavLink>
              ) : (
                <span>
                  <ContractIcon />
                  {contractName}
                </span>
              )}
            </li>
          </React.Fragment>
        )}
        {deliverableId && (
          <React.Fragment>
            <Separator />
            <li className="bc-deliverable">
              <span>
                <DeliverableIcon />
                {deliverableName}
              </span>
            </li>
          </React.Fragment>
        )}
      </ul>
    )
  }
}

BreadCrumbs.propTypes = {
  selectedClient: PropTypes.object,
  selectedContract: PropTypes.object,
  selectedDeliverable: PropTypes.object
}

export default BreadCrumbs
