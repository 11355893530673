import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import Loader from 'components/Loader'
import DataTable from 'components/DataTable'
import TaxonomyToolbar from '../Common/TaxonomyToolbar'
import { confirmModal } from 'actions/modal-actions'

import {
  editCategory,
  createCategory,
  deleteCategory,
  fetchCategories,
  downloadCategories,
  updateCategoriesTable,
  fetchCategorySuggestions
} from 'actions/taxonomy-actions'

import buildTaxonomyColumns from '../Common/columns'

export class Categories extends Component {
  componentDidMount() {
    this.fetchCategories()
  }

  fetchCategories = payload => {
    const {
      sortBy = this.props.sortBy,
      sortOrder = this.props.sortOrder,
      page = this.props.page,
      pageSize = this.props.pageSize,
      filters = this.props.filters
    } = payload || {}

    this.props.updateCategoriesTable({
      page,
      sortBy,
      pageSize,
      sortOrder,
      filters
    })

    this.props.fetchCategories({
      page,
      sortBy,
      pageSize,
      sortOrder,
      filters
    })
  }

  onSortChange = (sortBy, sortOrder) => {
    this.fetchCategories({
      sortBy,
      sortOrder,
      page: 1
    })
  }

  onFilterChange = filtersObj => {
    const filters = {}
    for (const [columnName, filterValue] of Object.entries(filtersObj)) {
      filters[columnName] = { value: filterValue.value }
    }

    this.fetchCategories({
      filters,
      page: 1
    })
  }

  onPageChange = page => {
    this.fetchCategories({ page })
  }

  render() {
    const {
      loading,
      pageSize,
      editCategory,
      hasEditAccess,
      deleteCategory,
      createCategory,
      totalCategoryCount,
      downloadCategories,
      fetchCategorySuggestions
    } = this.props

    const maxPages = Math.ceil(totalCategoryCount / pageSize) || 1

    const columns = buildTaxonomyColumns({
      hasEditAccess,
      tableType: 'category',
      editFunction: editCategory,
      deleteFunction: deleteCategory
    })

    /* Escape backslashes from presentation */
    const data = this.props.categories.map(category => {
      category.name = category.name?.replace(/\\/g, '')
      category.full_path = category.full_path?.replace(/\\/g, '')
      return category
    })

    return (
      <React.Fragment>
        <TaxonomyToolbar
          toolbarType="Category"
          hasEditAccess={hasEditAccess}
          createFunction={createCategory}
          downloadFunction={downloadCategories}
          fetchSuggestions={fetchCategorySuggestions}
        />

        {loading && <Loader loading overlap />}

        <DataTable
          columns={columns}
          keyBoardNav={false}
          maxPages={maxPages}
          page={this.props.page}
          data={data}
          sortName={this.props.sortBy}
          trClassName={this.trClassName}
          onPageChange={this.onPageChange}
          sortOrder={this.props.sortOrder}
          onSortChange={this.onSortChange}
          totalCount={totalCategoryCount}
          onFilterChange={this.onFilterChange}
          tableClassName="categories-table sb-table-compact"
          ref={el => {
            this.dataTable = el
          }}
        />
      </React.Fragment>
    )
  }
}

Categories.defaultProps = {
  categories: [],
  totalCategoryCount: 0
}

Categories.propTypes = {
  page: PropTypes.number,
  sortBy: PropTypes.string,
  pageSize: PropTypes.number,
  sortOrder: PropTypes.string,
  filters: PropTypes.object,
  loading: PropTypes.bool.isRequired,
  confirmModal: PropTypes.func.isRequired,
  editCategory: PropTypes.func.isRequired,
  hasEditAccess: PropTypes.bool.isRequired,
  createCategory: PropTypes.func.isRequired,
  deleteCategory: PropTypes.func.isRequired,
  fetchCategories: PropTypes.func.isRequired,
  downloadCategories: PropTypes.func.isRequired,
  totalCategoryCount: PropTypes.number.isRequired,
  updateCategoriesTable: PropTypes.func.isRequired,
  fetchCategorySuggestions: PropTypes.func.isRequired,
  categories: PropTypes.arrayOf(PropTypes.object).isRequired
}

const mapStateToProps = ({ taxonomy: { categories } }) => ({
  page: categories.page,
  sortBy: categories.sortBy,
  loading: categories.loading,
  pageSize: categories.pageSize,
  sortOrder: categories.sortOrder,
  categories: categories.data.rows,
  totalCategoryCount: categories.data.count,
  filters: categories.filters
})

const mapDispatchToProps = {
  confirmModal,
  editCategory,
  createCategory,
  deleteCategory,
  fetchCategories,
  downloadCategories,
  updateCategoriesTable,
  fetchCategorySuggestions
}

export default connect(mapStateToProps, mapDispatchToProps)(Categories)
