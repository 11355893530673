import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'

import { Button, Glyphicon } from 'react-bootstrap'

import { addCustomModal } from 'actions/modal-actions'

import { DataTableToolbar, TableToolbarButtons } from 'components/DataTable'

const CommonRulesToolbar = ({
  toolbarType,
  createRuleModalContet,
  refreshHandler,
  hasEditAccess,
  downloadRulesAction,
  clearFilters
}) => {
  const dispatch = useDispatch()

  const openCreateModal = () => {
    dispatch(
      addCustomModal({
        modalType: 'successModal',
        id: 'edit-rule-modal',
        title: `Create new ${toolbarType} rule`,
        size: 'large',
        content: createRuleModalContet
      })
    )
  }

  return (
    <DataTableToolbar className="rules-toolbar">
      {hasEditAccess && createRuleModalContet && (
        <Button bsStyle="info" onClick={openCreateModal}>
          <Glyphicon glyph="plus" />
          {`Create ${toolbarType}`}
        </Button>
      )}

      <TableToolbarButtons
        downloadHandler={downloadRulesAction}
        refreshHandler={refreshHandler}
        clearFiltersHandler={clearFilters}
      />
    </DataTableToolbar>
  )
}

CommonRulesToolbar.propTypes = {
  hasEditAccess: PropTypes.bool.isRequired,
  toolbarType: PropTypes.string.isRequired,
  createRuleModalContet: PropTypes.element,
  downloadRulesAction: PropTypes.func.isRequired,
  refreshHandler: PropTypes.func.isRequired,
  clearFilters: PropTypes.func.isRequired
}

export default CommonRulesToolbar
