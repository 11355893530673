import React from 'react'

import { DATA_TABLE } from 'components/DataTable/constants'
import { BooleanCell } from 'components/DataTable'
import { TableHeaderColumn } from 'react-bootstrap-table'
import MerchantActionColumn from './MerchantActionColumn'

const buildMerchantColumns = ({ tableType }) => {
  const tableId = `id`
  const tableName = 'name'

  const columns = [
    {
      field: 'id',
      label: 'ID',
      width: '75px',
      dataAlign: 'center',
      headerAlign: 'center',
      dataSort: true,
      filterType: 'id',
      filter: {
        type: 'TextFilter',
        delay: DATA_TABLE.FILTER_DELAY
      }
    },
    {
      field: 'name',
      label: 'Name',
      dataAlign: 'left',
      dataSort: true,
      width: 'auto',
      headerAlign: 'center',
      filterType: 'name',
      filter: {
        type: 'TextFilter',
        delay: DATA_TABLE.FILTER_DELAY
      }
    },

    {
      field: 'nameDisplay',
      label: 'Display Name',
      dataAlign: 'left',
      dataSort: true,
      headerAlign: 'center',
      width: 'auto',
      filter: {
        type: 'TextFilter',
        delay: DATA_TABLE.FILTER_DELAY
      }
    },
    {
      field: 'siteUrl',
      label: 'Site URL',
      dataAlign: 'left',
      dataSort: true,
      headerAlign: 'center',
      width: 'auto',
      filter: {
        type: 'TextFilter',
        delay: DATA_TABLE.FILTER_DELAY
      }
    },
    {
      field: 'isActive',
      label: 'Active',
      dataAlign: 'center',
      headerAlign: 'center',
      dataSort: true,
      width: '80px',
      dataFormat: (cell, row) => BooleanCell({ isActive: cell }),
      filter: {
        type: 'SelectFilter',
        options: {
          0: 'Inactive',
          1: 'Active'
        }
      }
    },
    {
      field: 'mrqCsq',
      label: 'MRQ CSQ',
      dataSort: true,
      width: '100px',
      dataAlign: 'center',
      headerAlign: 'center',
      filter: {
        type: 'TextFilter',
        delay: DATA_TABLE.FILTER_DELAY
      }
    },
    {
      field: 'isInfoprodQualified',
      label: 'Is Infoprod Qualified',
      dataSort: true,
      dataAlign: 'center',
      headerAlign: 'center',
      width: '80px',
      dataFormat: (cell, row) => BooleanCell({ isActive: cell }),
      filter: {
        type: 'SelectFilter',
        options: {
          1: 'True',
          0: 'False'
        }
      }
    },
    {
      field: 'isClientQualified',
      label: 'Is Client Qualified',
      dataSort: true,
      dataAlign: 'center',
      headerAlign: 'center',
      width: '80px',
      dataFormat: (cell, row) => BooleanCell({ isActive: cell }),
      filter: {
        type: 'SelectFilter',
        options: {
          1: 'True',
          0: 'False'
        }
      }
    },
    {
      field: 'addDate',
      label: 'Add date',
      dataSort: true,
      dataAlign: 'center',
      headerAlign: 'center',
      width: '160px',
      filter: {
        type: 'DateFilter',
        delay: DATA_TABLE.FILTER_DELAY
      }
    },
    {
      field: 'action_column',
      label: 'Actions',
      dataAlign: 'center',
      headerAlign: 'center',
      width: '100px',
      // eslint-disable-next-line react/display-name
      dataFormat: (cell, row) => {
        const id = row[tableId]
        if (id > 0) {
          return <MerchantActionColumn id={id} name={row[tableName]} />
        }
        return null
      }
    }
  ]

  const headerColumns = columns.map((col, index) => {
    const { field, label, ...otherProps } = col
    return (
      <TableHeaderColumn
        {...otherProps}
        ref={field}
        dataField={field}
        isKey={field === tableId}
        key={`${tableType}-col-${index}`}
      >
        {label}
      </TableHeaderColumn>
    )
  })
  return headerColumns
}

export default buildMerchantColumns
