import React from 'react'
import PropTypes from 'prop-types'

import InputField from './../../FormInputComponents/InputField'

/**
 * Add notes fields in Rule modals for Requests mode
 */
const NoteFields = React.memo(function NotesFieldsComponent({
  mode,
  request_notes,
  review_notes,
  handleFieldChange,
  required
}) {
  return (
    <>
      {(mode.isRequestApprove || mode.isRequestView || mode.isRequestCreate) && (
        <InputField
          id="request_notes"
          label="Requestor Notes"
          type="textarea"
          componentClass="textarea"
          rows={5}
          value={request_notes || ''}
          onChange={handleFieldChange}
          disabled={mode.isRequestView || mode.isRequestApprove}
          required={required}
        />
      )}

      {(mode.isRequestApprove || mode.isRequestView) && (
        <InputField
          id="review_notes"
          label="Reviewer Notes"
          type="textarea"
          componentClass="textarea"
          rows={5}
          value={review_notes || ''}
          onChange={handleFieldChange}
          disabled={mode.isRequestView}
          required={required}
        />
      )}
    </>
  )
})

NoteFields.defaultProps = {
  request_notes: null,
  review_notes: null,
  required: false
}

NoteFields.propTypes = {
  mode: PropTypes.object.isRequired,
  request_notes: PropTypes.string,
  review_notes: PropTypes.string,
  required: PropTypes.bool,

  handleFieldChange: PropTypes.func.isRequired
}

export default NoteFields
