import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Glyphicon } from 'react-bootstrap'

import convertToNumberIfPossible from './utils'

class NewProperty extends Component {
  state = {
    isShowAddNew: this.props.isShowAddNew || false,
    newKey: '',
    newValue: ''
  }

  onNewPropertyKeyChange = e => {
    const newKey = e.target.value
    this.setState({ newKey })
  }

  onNewPropertyValueChange = e => {
    let newValue = e.target.value
    newValue = convertToNumberIfPossible(newValue)
    this.setState({ newValue })
  }

  onSaveNewProperty = e => {
    const { newKey, newValue } = this.state
    const { onSave } = this.props

    this.setState({ isShowAddNew: false, newKey: '', newValue: '' })
    onSave({ [newKey]: newValue })
  }

  onAddNewPropertyHandler = () => {
    this.setState({ isShowAddNew: true })
  }

  render() {
    const { isShowAddNew, newKey, newValue } = this.state
    return (
      <div className="json-edit-field-new-property">
        {isShowAddNew ? (
          <div>
            <input value={newKey} placeholder="key" onChange={this.onNewPropertyKeyChange} />
            {' : '}
            <input value={newValue} placeholder="value" onChange={this.onNewPropertyValueChange} />
            <Glyphicon
              className="cursor-pointer"
              title="Save new property"
              onClick={this.onSaveNewProperty}
              glyph={'ok-sign'}
            />
          </div>
        ) : (
          <Glyphicon
            className="cursor-pointer"
            title="Add new property"
            onClick={this.onAddNewPropertyHandler}
            glyph={'plus'}
          />
        )}
      </div>
    )
  }
}

NewProperty.propTypes = {
  onSave: PropTypes.func.isRequired,
  level: PropTypes.number.isRequired,
  isShowAddNew: PropTypes.bool
}

export default NewProperty
