import { useMemo } from 'react'

/**
 * Get mode object
 */
const useMode = (isRequest, isReadOnly, rowId) => {
  const getMode = (isRequest, isReadOnly, rowId) => ({
    isRuleCreate: Boolean(!isReadOnly && !isRequest && !rowId),
    isRuleEdit: Boolean(!isReadOnly && !isRequest && rowId),
    isRequestCreate: Boolean(!isReadOnly && isRequest && !rowId),
    isRequestApprove: Boolean(!isReadOnly && isRequest && rowId),
    isRequestView: isReadOnly
  })

  const mode = useMemo(() => getMode(isRequest, isReadOnly, rowId), [isRequest, isReadOnly, rowId])
  return mode
}

export default useMode
