import React from 'react'
import PropTypes from 'prop-types'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

import ResultTooltip from './Tooltip/ResultTooltip'
import ViolationInfo from './Tooltip/ViolationInfo'
import QueryInfo from './Tooltip/QueryInfo'
import ResultCellValue from './ResultCellValue'
import ErrorSign from './ErrorSign'

// Cases:
// 1. Active and pass -> show violation value in green
// 2. Active and failed -> show violation value in red
// 3. InActive and have result -> inactive is red and result in usual color
// 4. InActive and don't have result -> inactive is red and result is 'null'
const ResultCell = ({
  name,
  result,
  resultMark = '%',
  threshold,
  isPass,
  isActive,
  query = null,
  error = null,
  showViolation = true
}) => {
  return (
    <div>
      <OverlayTrigger
        placement="top"
        overlay={
          <Tooltip id="category-test-result-tooltip">
            <ResultTooltip name={name} isPass={isPass} isActive={isActive}>
              <ViolationInfo
                isPass={isPass}
                isActive={isActive}
                result={result}
                resultMark={resultMark}
                threshold={threshold}
                showViolation={showViolation}
              />
              {query && <QueryInfo groupName={query.groupName} queryName={query.queryName} />}
            </ResultTooltip>
          </Tooltip>
        }
      >
        <span>
          <ResultCellValue isActive={isActive} mark={resultMark} isPass={isPass}>
            {result}
          </ResultCellValue>
        </span>
      </OverlayTrigger>
      <ErrorSign error={error} />
    </div>
  )
}

ResultCell.propTypes = {
  name: PropTypes.string.isRequired,
  result: PropTypes.oneOfType([PropTypes.number, PropTypes.node]),
  resultMark: PropTypes.string,
  threshold: PropTypes.number,
  isPass: PropTypes.bool,
  showViolation: PropTypes.bool,
  isActive: PropTypes.bool,
  query: PropTypes.object,
  error: PropTypes.string
}

export default ResultCell
