import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { getL1CategoriesId, getL1CategoriesIndexedById } from '../../../selectors/category-selector'
import CheckBoxMultiSelect from '../../CheckBoxMultiSelect'
import { fetchL1Categories } from '../../../actions/category-actions'

import './index.scss'

const CategoriesInput = ({ categoriesId, categoriesIndexedById, input, fetchL1Categories }) => {
  useEffect(() => {
    fetchL1Categories()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="CategoriesInput__container FormSelect__container">
      <label>L1 Categories</label>
      <CheckBoxMultiSelect
        keys={categoriesId}
        options={categoriesIndexedById}
        columnCount={2}
        valueDidChange={value => input.onChange(value)}
        data={input.value}
        canBeHidden
        isHiddenByDefault
        isImportEnable
        entityNames={{ singular: 'category', plural: 'categories' }}
        importTitle={'Import L1 category id list'}
      />
    </div>
  )
}

const mapStateToProps = state => {
  return {
    categoriesIndexedById: getL1CategoriesIndexedById(state),
    categoriesId: getL1CategoriesId(state)
  }
}

CategoriesInput.propTypes = {
  fetchL1Categories: PropTypes.func,
  categoriesIndexedById: PropTypes.object,
  categoriesId: PropTypes.array,
  input: PropTypes.object
}

export default connect(mapStateToProps, { fetchL1Categories })(CategoriesInput)
