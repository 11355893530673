import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import Loader from '../Loader'
import ETLStatsToolbar from './ETLStatsToolbar'
import ETLStatsWidgets from './ETLStatsWidgets'

import { fetchETLStats, fetchETLStatsTimeframe } from '../../actions/etl-stats-actions'

import './index.scss'

class ETLStats extends Component {
  componentDidMount() {
    this.props.fetchETLStatsTimeframe()
  }

  render() {
    const { loading, taskCount, failedTaskCount, taskRuntimes, pendingTaskCount, completedTaskCount } = this.props

    return (
      <div className="etl-stats-container">
        <ETLStatsToolbar
          taskCount={taskCount}
          failedTaskCount={failedTaskCount}
          pendingTaskCount={pendingTaskCount}
          completedTaskCount={completedTaskCount}
          fetchETLStats={this.props.fetchETLStats}
        />

        {loading && <Loader overlap />}

        <ETLStatsWidgets taskRuntimes={taskRuntimes} />
      </div>
    )
  }
}

ETLStats.propTypes = {
  tasks: PropTypes.array,
  loading: PropTypes.bool,
  taskCount: PropTypes.number,
  fetchETLStats: PropTypes.func,
  filterOptions: PropTypes.array,
  failedTaskCount: PropTypes.number,
  taskRuntimes: PropTypes.object,
  pendingTaskCount: PropTypes.number,
  completedTaskCount: PropTypes.number,
  fetchETLStatsTimeframe: PropTypes.func
}

const mapStateToProps = ({
  etlStats: { data, count, error, loading, failedTaskCount, pendingTaskCount, taskRuntimes }
}) => ({
  error,
  loading,
  failedTaskCount,
  pendingTaskCount,
  taskRuntimes,
  tasks: data,
  taskCount: count,
  completedTaskCount: count - (pendingTaskCount + failedTaskCount)
})

export default connect(mapStateToProps, {
  fetchETLStats,
  fetchETLStatsTimeframe
})(ETLStats)
