import React from 'react'
import joi from 'joi'

import { useBrandSuggestionsQuery } from './brandAPI'
import useDebounce from 'utils/debounce/useDebounce'
import { VALIDATION_BRAND_REGEX, VALIDATION_BRAND_MESSAGE } from 'components/Taxonomy/Common/helper'

const validateBrand = value => {
  const schema = joi
    .string()
    .trim()
    .required()
    .ruleset.regex(VALIDATION_BRAND_REGEX)
    .rule({ message: VALIDATION_BRAND_MESSAGE })
    .label('Brand')

  const { error, value: validatedValue } = schema.validate(value)

  return { validationMessage: error?.message, validatedValue }
}

const useNewBrandValidation = brand => {
  const brandDebounced = useDebounce(brand)

  const { validationMessage, validatedValue: newBrand } = validateBrand(brandDebounced)

  // use suggestion API for getting close brands
  const { data: suggestion, error, isLoading } = useBrandSuggestionsQuery(newBrand, {
    skip: !newBrand || validationMessage,
    refetchOnMountOrArgChange: 10
  })

  // The main idea is that the suggestion API will return the equal value (i.e. a duplicate)
  // in the first element of the result
  const firstSuggestionFullPath = suggestion?.results?.[0]?.fullPath
  const firstSuggestionId = suggestion?.results?.[0]?.id
  const isBrandDuplication =
    firstSuggestionFullPath && newBrand?.toLowerCase() === firstSuggestionFullPath.toLowerCase()

  let isBrandValid = false
  if ((!validationMessage && !isBrandDuplication && !error) || newBrand === null) {
    isBrandValid = true
  }

  // message
  let brandHelpInfo
  if (error) {
    brandHelpInfo = <span className="text-color-red">Server Error during validation: ${error?.data?.error}</span>
  } else if (isLoading) {
    brandHelpInfo = <i>Loading brands ...</i>
  } else if (!newBrand) {
    brandHelpInfo = <i>enter a new Brand ...</i>
  } else if (validationMessage) {
    brandHelpInfo = <span className="text-color-red">{validationMessage}</span>
  } else if (isBrandDuplication) {
    brandHelpInfo = (
      <span className="text-color-red">
        Brand with this name already exists ID:{firstSuggestionId} &quot;{firstSuggestionFullPath}&quot;
      </span>
    )
  } else if (suggestion?.results) {
    if (suggestion.results.length > 0) {
      brandHelpInfo = `Close Brands: ${suggestion.results.map(b => `"${b.fullPath}"`).join(', ')}`
    } else {
      brandHelpInfo = `No close Brands.`
    }
  }

  // in validation we allow "null" it means the first empty value
  // but we shouldn't activate "OK" button if brand name is empty
  let isBrandReady = false
  if (!!brandDebounced && !isBrandDuplication && !isLoading && !error) {
    isBrandReady = true
  }

  return { brandHelpInfo, isBrandValid, isBrandReady }
}

export default useNewBrandValidation
