import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import Loader from 'components/Loader'
import DataTable from 'components/DataTable'
import TaxonomyToolbar from '../Common/TaxonomyToolbar'

import { confirmModal } from 'actions/modal-actions'
import {
  editBrand,
  fetchBrands,
  createBrand,
  deleteBrand,
  downloadBrands,
  updateBrandsTable,
  fetchBrandSuggestions
} from 'actions/taxonomy-actions'

import buildTaxonomyColumns from '../Common/columns'

class Brands extends Component {
  componentDidMount() {
    this.fetchBrands()
  }

  fetchBrands = payload => {
    const {
      page = this.props.page,
      sortBy = this.props.sortBy,
      pageSize = this.props.pageSize,
      sortOrder = this.props.sortOrder,
      filters = this.props.filters
    } = payload || {}

    this.props.updateBrandsTable({
      page,
      sortBy,
      pageSize,
      sortOrder,
      filters
    })

    this.props.fetchBrands({
      page,
      sortBy,
      pageSize,
      sortOrder,
      filters
    })
  }

  onSortChange = (sortBy, sortOrder) => {
    this.fetchBrands({
      sortBy,
      sortOrder,
      page: 1
    })
  }

  onFilterChange = filtersObj => {
    const filters = {}
    for (const [columnName, filterValue] of Object.entries(filtersObj)) {
      filters[columnName] = { value: filterValue.value }
    }

    this.fetchBrands({
      filters,
      page: 1
    })
  }

  onPageChange = page => {
    this.fetchBrands({ page })
  }

  render() {
    const {
      loading,
      pageSize,
      editBrand,
      createBrand,
      deleteBrand,
      hasEditAccess,
      downloadBrands,
      totalBrandCount,
      fetchBrandSuggestions
    } = this.props

    const maxPages = Math.ceil(totalBrandCount / pageSize) || 1

    const columns = buildTaxonomyColumns({
      hasEditAccess,
      tableType: 'brand',
      editFunction: editBrand,
      deleteFunction: deleteBrand
    })

    return (
      <React.Fragment>
        <TaxonomyToolbar
          toolbarType="Brand"
          createFunction={createBrand}
          hasEditAccess={hasEditAccess}
          downloadFunction={downloadBrands}
          fetchSuggestions={fetchBrandSuggestions}
        />

        {loading && <Loader loading overlap />}

        <DataTable
          columns={columns}
          keyBoardNav={false}
          maxPages={maxPages}
          page={this.props.page}
          data={this.props.brands}
          sortName={this.props.sortBy}
          tableClassName="brands-table sb-table-compact"
          trClassName={this.trClassName}
          onPageChange={this.onPageChange}
          totalCount={totalBrandCount}
          sortOrder={this.props.sortOrder}
          onSortChange={this.onSortChange}
          onFilterChange={this.onFilterChange}
          ref={el => {
            this.dataTable = el
          }}
        />
      </React.Fragment>
    )
  }
}

Brands.defaultProps = {
  brands: [],
  totalBrandCount: 0
}

Brands.propTypes = {
  page: PropTypes.number,
  pageSize: PropTypes.number,
  sortBy: PropTypes.string,
  sortOrder: PropTypes.string,
  filters: PropTypes.object,
  loading: PropTypes.bool.isRequired,
  editBrand: PropTypes.func.isRequired,
  createBrand: PropTypes.func.isRequired,
  fetchBrands: PropTypes.func.isRequired,
  deleteBrand: PropTypes.func.isRequired,
  confirmModal: PropTypes.func.isRequired,
  hasEditAccess: PropTypes.bool.isRequired,
  downloadBrands: PropTypes.func.isRequired,
  updateBrandsTable: PropTypes.func.isRequired,
  totalBrandCount: PropTypes.number.isRequired,
  fetchBrandSuggestions: PropTypes.func.isRequired,
  brands: PropTypes.arrayOf(PropTypes.object).isRequired
}

const mapStateToProps = ({ taxonomy: { brands } }) => ({
  page: brands.page,
  sortBy: brands.sortBy,
  loading: brands.loading,
  brands: brands.data.rows,
  pageSize: brands.pageSize,
  sortOrder: brands.sortOrder,
  totalBrandCount: brands.data.count,
  filters: brands.filters
})

const mapDispatchToProps = {
  editBrand,
  createBrand,
  fetchBrands,
  deleteBrand,
  confirmModal,
  downloadBrands,
  updateBrandsTable,
  fetchBrandSuggestions
}

export default connect(mapStateToProps, mapDispatchToProps)(Brands)
