import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import DocumentTitle from 'react-document-title'

import { Grid, Row, Col } from 'react-bootstrap'
import { default as QueryBuilderWrapper } from './QueryBuilderWrapper'

import SupraQCToolContent from './SupraQCToolContent'
import AddToDictModal from './AddToDictModal'
import AddToDictModalNew from './Modals/AddToDictModalNew'
import WithErrorBoundaryWrapper from 'components/WithErrorBoundaryWrapper/WithErrorBoundaryWrapper'

import '../../styles/treeView.scss'
import Unauthorized from '../Unauthorized'
import UserService from '../../services/user-service'
import { products, appName } from '../../constants/constants'
import './style.scss'

class SupraQCTool extends Component {
  static propTypes = {
    isTreeVisible: PropTypes.bool,
    userProducts: PropTypes.object,
    hideTree: PropTypes.func,
    toggleTreeVisibility: PropTypes.func
  }

  state = {
    isTreeVisible: false
  }

  hideTree = () => {
    this.setState({
      isTreeVisible: false
    })
  }

  toggleTreeVisibility = () => {
    this.setState(prevState => {
      return {
        isTreeVisible: !prevState.isTreeVisible
      }
    })
  }

  isUnauthorized = key => {
    return !this.props.userProducts || !this.props.userProducts[key] || !this.props.userProducts[key].enabled
  }

  render() {
    if (this.isUnauthorized(products.adminToolsSupraQCTool)) {
      return <Unauthorized />
    }
    return (
      <DocumentTitle title={this.props.documentTitle ? `${appName} | ${this.props.documentTitle}` : appName}>
        <Grid bsClass="search-root">
          <Row className="show-grid">
            <Col xs={12} md={12}>
              <QueryBuilderWrapper
                isTreeVisible={this.state.isTreeVisible}
                toggleTreeVisibility={this.toggleTreeVisibility}
              />
              <div
                onClick={this.props.hideTree}
                className={`overlay
                  ${this.state.isTreeVisible ? 'query-tree-overlay-visible' : 'query-tree-overlay-hidden'}`}
              />
              <div className="text-center">
                <AddToDictModal />
                <AddToDictModalNew />
                <SupraQCToolContent />
              </div>
            </Col>
          </Row>
        </Grid>
      </DocumentTitle>
    )
  }
}

SupraQCTool.propTypes = {
  userProducts: PropTypes.object,
  documentTitle: PropTypes.string
}

SupraQCTool.defaultProps = {
  documentTitle: 'Supra QC Tool'
}

function mapStateToProps(state) {
  return {
    userProducts: UserService.getProducts(state.session.user)
  }
}

export default WithErrorBoundaryWrapper(connect(mapStateToProps, {})(SupraQCTool), '"Supra QC Tool" page')
