import React from 'react'
import PropTypes from 'prop-types'
import { Glyphicon, OverlayTrigger, Tooltip } from 'react-bootstrap'

import { useDispatch } from 'react-redux'
import { addCustomModal, confirmModal } from 'actions/modal-actions'
import CategoryBrandRuleModal from 'components/Rules/CategoryBrand/Modals/CategoryBrandRuleModal'
import MerchantBrandRuleModal from 'components/Rules/MerchantBrand/Modals/MerchantBrandRuleModal'
import MerchantCategoryRuleModal from 'components/Rules/MerchantCategory/Modals/MerchantCategoryRuleModal'
import BrandSynonymRuleModal from 'components/Rules/BrandSynonym/Modals/BrandSynonymRuleModal'
import MerchantCategoryRegexRuleModal from 'components/Rules/MerchantCategoryRegex/Modals/MerchantCategoryRegexRuleModal'
import MerchantBrandRegexRuleModal from 'components/Rules/MerchantBrandRegex/Modals/MerchantBrandRegexRuleModal'
import GeneralRequestModal from './../GeneralRequestModal'
import BrandRequestModal from './../BrandRequestModal'

import { REQUESTS_ACTIONS, REQUESTS_STATUS, REQUESTS_TYPE } from './../constants'
import RequestsActions from './../actions'

export default function RequestActionsCell({ hasEditAccess, row }) {
  const dispatch = useDispatch()

  const deleteHandler = id => {
    dispatch(
      RequestsActions.save({
        action: REQUESTS_ACTIONS.DELETE,
        id
      })
    )
  }

  const deleteModal = () => {
    const { id } = row

    dispatch(
      confirmModal(
        <div>
          <span>
            {`Are you sure you want to delete Request `}
            <strong>
              <em>ID:{id}</em>
            </strong>
            ?
          </span>
        </div>,
        'Confirm Delete',
        { okButton: `Remove request` },
        () => deleteHandler(id)
      )
    )
  }

  const approveRequest = type => payload => {
    // TODO refactor this
    if (type === REQUESTS_TYPE.GENERAL || type === REQUESTS_TYPE.GUIDELINE) {
      const { id, review_notes, request_notes, ...rulePayload } = payload
      dispatch(
        RequestsActions.save({
          action: REQUESTS_ACTIONS.APPROVE,
          id,
          review_notes,
          type,
          payload: {
            // TODO tmp quick solution, restore notes in payload
            ...rulePayload,
            review_notes,
            request_notes
          }
        })
      )
    } else {
      const { id, review_notes, request_notes, ...rulePayload } = payload
      dispatch(
        RequestsActions.save({
          action: REQUESTS_ACTIONS.APPROVE,
          id,
          review_notes,
          type,
          payload: rulePayload
        })
      )
    }
  }

  // TODO refactor two rejects. Merge in one?
  const rejectRuleRequest = (id, payload) => {
    const { review_notes } = payload
    dispatch(
      RequestsActions.save({
        action: REQUESTS_ACTIONS.REJECT,
        id,
        review_notes
      })
    )
  }
  const rejectGeneralRequest = (id, payload) => {
    const { review_notes } = payload
    dispatch(
      RequestsActions.save({
        action: REQUESTS_ACTIONS.REJECT,
        id,
        review_notes,
        payload
      })
    )
  }

  const getRuleModalByType = ({ id, request_notes, review_notes, payload, type, isReadOnly = false }) => {
    if (type === REQUESTS_TYPE.CBR) {
      const brands = payload.brands || [
        {
          brandId: payload.brandId,
          brandFullPath: payload.brandFullPath,
          isMatchAnywhere: payload.isMatchAnywhere
        }
      ]
      return (
        <CategoryBrandRuleModal
          value={{
            id,
            categoryId: payload.categoryId,
            categoryFullPath: payload.categoryFullPath,
            brands,
            request_notes: request_notes,
            review_notes: review_notes
          }}
          onOkHandler={approveRequest(REQUESTS_TYPE.CBR)}
          onRejectHandler={rejectRuleRequest}
          isRequest
          isReadOnly={isReadOnly}
        />
      )
    }
    if (type === REQUESTS_TYPE.MBR) {
      return (
        <MerchantBrandRuleModal
          value={{
            id,
            merchantId: payload.merchantId,
            merchantName: payload.merchantName,
            brandId: payload.brandId,
            brandFullPath: payload.brandFullPath,
            request_notes,
            review_notes
          }}
          onOkHandler={approveRequest(REQUESTS_TYPE.MBR)}
          onRejectHandler={rejectRuleRequest}
          isRequest
          isReadOnly={isReadOnly}
        />
      )
    }
    if (type === REQUESTS_TYPE.MCR) {
      return (
        <MerchantCategoryRuleModal
          value={{
            id,
            merchantId: payload.merchantId,
            merchantName: payload.merchantName,
            categoryId: payload.categoryId,
            categoryFullPath: payload.categoryFullPath,
            request_notes,
            review_notes
          }}
          onOkHandler={approveRequest(REQUESTS_TYPE.MCR)}
          onRejectHandler={rejectRuleRequest}
          isRequest
          isReadOnly={isReadOnly}
        />
      )
    }
    if (type === REQUESTS_TYPE.BSR) {
      const synonyms = payload.synonyms || [
        {
          synonym: payload.synonym
        }
      ]
      return (
        <BrandSynonymRuleModal
          value={{
            id,
            brandId: payload.brandId,
            brandFullPath: payload.brandFullPath,
            synonyms,
            request_notes,
            review_notes
          }}
          onOkHandler={approveRequest(REQUESTS_TYPE.BSR)}
          onRejectHandler={rejectRuleRequest}
          isRequest
          isReadOnly={isReadOnly}
        />
      )
    }
    if (type === REQUESTS_TYPE.MCRR) {
      return (
        <MerchantCategoryRegexRuleModal
          value={{
            id,
            merchantId: payload.merchantId,
            merchantName: payload.merchantName,
            categoryId: payload.categoryId,
            categoryFullPath: payload.categoryFullPath,

            includeParam: payload.includeParam,
            excludeParam: payload.excludeParam,
            ruleType: payload.ruleType,
            isExcludeRule: payload.isExcludeRule,

            request_notes,
            review_notes
          }}
          onOkHandler={approveRequest(REQUESTS_TYPE.MCRR)}
          onRejectHandler={rejectRuleRequest}
          isRequest
          isReadOnly={isReadOnly}
        />
      )
    }
    if (type === REQUESTS_TYPE.MBRR) {
      return (
        <MerchantBrandRegexRuleModal
          value={{
            id,
            merchantId: payload.merchantId,
            merchantName: payload.merchantName,
            brandId: payload.brandId,
            brandFullPath: payload.brandFullPath,

            includeParam: payload.includeParam,
            excludeParam: payload.excludeParam,
            ruleType: payload.ruleType,
            isExcludeRule: payload.isExcludeRule,

            request_notes,
            review_notes
          }}
          onOkHandler={approveRequest(REQUESTS_TYPE.MBRR)}
          onRejectHandler={rejectRuleRequest}
          isRequest
          isReadOnly={isReadOnly}
        />
      )
    }
    if (type === REQUESTS_TYPE.BRAND) {
      return (
        <BrandRequestModal
          value={{
            id,
            brand: payload.brand,
            categories: payload.categories,
            synonyms: payload.synonyms,

            request_notes,
            review_notes
          }}
          onOkHandler={approveRequest(REQUESTS_TYPE.BRAND)}
          onRejectHandler={rejectRuleRequest}
          isReadOnly={isReadOnly}
        />
      )
    }
    if (type === REQUESTS_TYPE.GENERAL) {
      return (
        <GeneralRequestModal
          value={{
            id,
            request_notes,
            review_notes
          }}
          onOkHandler={approveRequest(REQUESTS_TYPE.GENERAL)}
          onRejectHandler={rejectGeneralRequest}
          isReadOnly={isReadOnly}
        />
      )
    }
    if (type === REQUESTS_TYPE.GUIDELINE) {
      return (
        <GeneralRequestModal
          value={{
            id,
            request_notes,
            review_notes
          }}
          onOkHandler={approveRequest(REQUESTS_TYPE.GUIDELINE)}
          onRejectHandler={rejectGeneralRequest}
          isReadOnly={isReadOnly}
        />
      )
    }
  }

  const viewModal = () => {
    const { id, request_notes, review_notes, payload, type } = row
    dispatch(
      addCustomModal({
        modalType: 'successModal',
        id: 'requests-view-modal',
        title: `View Request ${type.toUpperCase()}`,
        size: 'large',
        content: getRuleModalByType({ id, request_notes, review_notes, payload, type, isReadOnly: true })
      })
    )
  }

  const openApproveModal = () => {
    const { id, request_notes, review_notes, payload, type } = row
    dispatch(
      addCustomModal({
        modalType: 'successModal',
        id: 'requests-approve-modal',
        title: `Review Request ${type.toUpperCase()}`,
        size: 'large',
        content: getRuleModalByType({ id, request_notes, review_notes, payload, type })
      })
    )
  }

  return (
    <div className="sb-table-action-cell">
      {row.status === REQUESTS_STATUS.PENDING && (
        <>
          {hasEditAccess && (
            <OverlayTrigger placement="left" overlay={<Tooltip id="request-approve-tooltip">Review request</Tooltip>}>
              <div onClick={openApproveModal}>
                <Glyphicon glyph="check" />
              </div>
            </OverlayTrigger>
          )}
          <OverlayTrigger placement="left" overlay={<Tooltip id="request-delete-tooltip">Delete request</Tooltip>}>
            <div onClick={deleteModal}>
              <Glyphicon glyph="trash" />
            </div>
          </OverlayTrigger>
        </>
      )}
      <OverlayTrigger placement="left" overlay={<Tooltip id="requests-view-tooltip">View Request</Tooltip>}>
        <div onClick={viewModal}>
          <Glyphicon glyph="eye-open" />
        </div>
      </OverlayTrigger>
    </div>
  )
}

RequestActionsCell.propTypes = {
  hasEditAccess: PropTypes.bool.isRequired,
  row: PropTypes.object.isRequired
}
