import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { fetchClientFeedInfo } from '../../actions/feed-actions'

class ClientFeedInfo extends Component {
  componentDidMount() {
    this.props.fetchClientFeedInfo()
  }

  render() {
    return (
      <div>
        <h3>Below is a list of all active feeds along with their frequency and delivery methods</h3>
        <h5>Format: Client/Group Name - Feed Module ID (Feed id) - Frequency - Delivery Method</h5>
        <ol className="client-feed-list">
          {this.props.data.map((item, index) => (
            <li key={index}>
              {`${item.clientName} - ${item.feedName} (id: ${item.feedId}) - ${item.frequency} - ${
                item.deliveryMethod
              }${item.fileName ? ` - ${item.fileName}` : ''}`}
            </li>
          ))}
        </ol>
      </div>
    )
  }
}

ClientFeedInfo.propTypes = {
  fetchClientFeedInfo: PropTypes.func,
  error: PropTypes.string,
  loading: PropTypes.bool,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      clientName: PropTypes.string,
      groupName: PropTypes.string,
      deliveryMethod: PropTypes.string,
      frequency: PropTypes.string,
      feedName: PropTypes.string,
      feedId: PropTypes.number,
      fileName: PropTypes.string
    })
  )
}

ClientFeedInfo.defaultProps = {
  data: []
}

const mapStateToProps = ({
  feeds: {
    clientFeedInfo: { data, error, loading }
  }
}) => ({
  data,
  error,
  loading
})

export default connect(mapStateToProps, { fetchClientFeedInfo })(ClientFeedInfo)
