import React from 'react'
import PropTypes from 'prop-types'
import { Glyphicon } from 'react-bootstrap'

export default function DescriptionTitleValue({ descriptionValue, merchantName }) {
  return (
    <div className="description-table-label">
      <span>{descriptionValue}</span>
      <div className="description-table-label-actions" style={{ cursor: 'pointer' }}>
        <a
          href={`http://www.google.com/search?q=${encodeURIComponent(
            `${descriptionValue} + ${merchantName}`
          )}&tbm=isch`}
          target="_blank"
          rel="noopener noreferrer"
          className="action-icon"
        >
          <Glyphicon glyph={'new-window'} />
        </a>

        <span
          onClick={elem => {
            window.open(
              `http://www.google.com/search?q=${encodeURIComponent(descriptionValue + merchantName)}&tbm=isch`,
              'Google search',
              `resizable,scrollbars,status,height=600,width=600`
            )
          }}
        >
          <Glyphicon glyph={'camera'} className="action-icon" />
        </span>
      </div>
    </div>
  )
}

DescriptionTitleValue.propTypes = {
  descriptionValue: PropTypes.string,
  merchantName: PropTypes.string
}
