import commonRtkApi from 'commonRtkApi.js'
import { sortModes } from 'constants/constants'

const cmeDictionaryAPI = commonRtkApi.injectEndpoints({
  tagTypes: ['Dictionary'],
  endpoints: builder => ({
    getEntries: builder.query({
      query: ({ paginationModel, sortModel, filterModel }) => {
        const sortModelEntry = sortModel[0]
        let sortBy, sortDesc
        const filters = filterModel.items
        if (sortModelEntry) {
          sortBy = sortModelEntry.field
          sortDesc = sortModelEntry.sort === sortModes.DESCENDING
        }
        const data = {
          ...paginationModel,
          filters,
          sortBy,
          sortDesc
        }
        return { url: `/cme-dictionary`, method: 'POST', data }
      },
      providesTags: ['Dictionary']
    }),
    editEntry: builder.mutation({
      query: ({
        uniq_id,
        description,
        merchant_id,
        category_id,
        category_added_by,
        category_add_time,
        category_id_changed,
        category_full_path,
        brand_id,
        brand_added_by,
        brand_add_time,
        brand_id_changed,
        brand_full_path,
        train,
        is_deleted
      }) => {
        const data = {
          description,
          merchant_id,
          category_id,
          category_added_by,
          category_add_time,
          category_id_changed,
          category_full_path,
          brand_id,
          brand_added_by,
          brand_add_time,
          brand_id_changed,
          brand_full_path,
          train,
          is_deleted
        }
        return { url: `/cme-dictionary/edit`, method: 'PUT', data }
      },
      invalidatesTags: ['Dictionary']
    }),
    addEntry: builder.mutation({
      query: dictionaryEntry => {
        const data = {
          ...dictionaryEntry,
          category_id: dictionaryEntry.categoryId,
          brand_id: dictionaryEntry.brandId,
          merchant_id: dictionaryEntry.merchantId
        }
        return { url: `/cme-dictionary/create`, method: 'POST', data }
      },
      invalidatesTags: ['Dictionary']
    }),
    getMaxId: builder.query({
      query: () => {
        return { url: `/cme-dictionary/latest-extract`, method: 'GET' }
      },
      providesTags: ['Dictionary']
    }),
    updateMaxId: builder.mutation({
      query: ({ max_id }) => {
        const data = {
          max_uniq_id: max_id
        }
        return { url: `/cme-dictionary/latest-extract`, method: 'PUT', data }
      },
      invalidatesTags: ['Dictionary']
    })
  })
})

export const {
  useGetEntriesQuery,
  useEditEntryMutation,
  useAddEntryMutation,
  useUpdateMaxIdMutation,
  useGetMaxIdQuery
} = cmeDictionaryAPI
