import { useEffect } from 'react'

/*
 * This hook adds a new synonym to the store after the rule will be checked
 */
const useHandleSynonymChange = ({
  mode,
  currentSynonym,
  index,
  rule,
  prevRuleId,
  isLoading,
  handleSynonymChange,
  isSynonymValid
}) => {
  let reset = false
  // check cases when the new synonym wouldn't need to add
  switch (true) {
    case mode.isRuleCreate:
    case mode.isRequestCreate:
      if (isLoading || rule) {
        reset = true
      }
      break
    case mode.isRuleEdit:
      if (isLoading || (rule && rule.id !== prevRuleId)) {
        reset = true
      }
      break
    default:
      break
  }

  useEffect(() => {
    if (!isSynonymValid || reset) {
      handleSynonymChange({ synonym: '' }, index)
    } else {
      handleSynonymChange(currentSynonym, index)
    }
  }, [index, currentSynonym, handleSynonymChange, reset, isSynonymValid])
}

export default useHandleSynonymChange
