import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { isEqual } from 'lodash'
import { Alert, Glyphicon } from 'react-bootstrap'

class RawJson extends Component {
  state = {
    isCorrect: true,
    internalValueStr: '',
    value: this.props.value
  }

  static getDerivedStateFromProps(props, state) {
    // set value from props in any case
    // it will overwrite internal state
    if (!isEqual(props.value, state.value)) {
      return {
        ...state,
        isCorrect: true,
        value: props.value
      }
    }

    return null
  }

  revertChanges = () => {
    this.setState({ isCorrect: true })
  }

  onChangeHandler = e => {
    const internalValueStr = e.target.value

    this.setState({ internalValueStr })

    try {
      const newValue = internalValueStr ? JSON.parse(internalValueStr) : null
      this.setState({ isCorrect: true })
      this.props.onChange(newValue)
    } catch (e) {
      this.setState({ isCorrect: false })
    }
  }

  render() {
    const { value } = this.props

    const { isCorrect, internalValueStr } = this.state

    let visibleValue
    if (isCorrect) {
      visibleValue = value ? JSON.stringify(value, undefined, 4) : ''
    } else {
      // Show internal value if this is invalid JSON
      visibleValue = internalValueStr
    }

    return (
      <div>
        <textarea rows={8} className="json-edit-field-textarea" onChange={this.onChangeHandler} value={visibleValue} />

        {!isCorrect && (
          <Alert bsStyle="danger">
            <Glyphicon glyph="remove-sign" className="feed-module-query-alert-icon" />
            {`JSON isn't valid. It wasn't saved. Please fix it or you can `}
            <strong className="cursor-pointer" onClick={this.revertChanges}>
              REVERT
            </strong>
            {' to the latest correct version.'}
          </Alert>
        )}
      </div>
    )
  }
}

RawJson.propTypes = {
  value: PropTypes.object,
  onChange: PropTypes.func.isRequired
}

export default RawJson
