import React from 'react'
import { useSelector } from 'react-redux'

import TabNav, { useBasePath } from 'components/TabNav'
import Unauthorized from 'components/Unauthorized'
import UserService from 'services/user-service'
import { permissions } from 'constants/constants'

import DeliveryJobs from './DeliveryJobs/DeliveryJobs'
import CategoryTests from './CategoryTests/CategoryTests'
import WithErrorBoundaryWrapper from 'components/WithErrorBoundaryWrapper/WithErrorBoundaryWrapper'

const DeliveryJobsPage = () => {
  const TAB_PATH = {
    JOBS: 'jobs',
    TESTS: 'tests'
  }
  const basePath = useBasePath(Object.values(TAB_PATH))

  const user = useSelector(state => state.session.user)
  const hasTaxonomyAccess = UserService.hasPermission(user, permissions.ropsTaxonomyView)
  const hasEditAccess = UserService.hasPermission(user, permissions.ropsTaxonomyEdit)

  if (!hasTaxonomyAccess) return <Unauthorized />

  return (
    <div>
      <TabNav>
        <TabNav.Item
          path={basePath + TAB_PATH.JOBS}
          label="Delivery Jobs"
          component={<DeliveryJobs hasEditAccess={hasEditAccess} />}
          default
        />
        <TabNav.Item
          path={basePath + TAB_PATH.TESTS}
          label="Category Tests"
          component={<CategoryTests hasEditAccess={hasEditAccess} />}
        />
      </TabNav>
    </div>
  )
}

export default WithErrorBoundaryWrapper(DeliveryJobsPage, '"CME Delivery Jobs" page')
