import React from 'react'
import PropTypes from 'prop-types'
import { Glyphicon, Alert } from 'react-bootstrap'

import CategorySuggestion from 'components/Suggestion/CategorySuggestion'
import InputField, { useFormValues } from 'components/FormInputComponents/InputField'

import useCategoryBrandPathMessage from './useCategoryBrandPathMessage'
import useHandleCategoryChange from './useHandleCategoryChange'

import { useCategoryBrandPathQuery } from './categoryBrandPathAPI'

/**
 * Component for selecting existing Category (use CategorySuggestion)
 * and possibility to choose related "Category Brand Path" or selected Category
 */
const CategoryBrandPathSuggestion = ({
  categoryId = null,
  categoryFullPath = null,
  handleCategoryChange,
  readOnly = false,
  isShowUseCategory = true,
  required = true,
  index = 0
}) => {
  const { values, isFormChanged, handleFieldChange, updateValue } = useFormValues({
    categoryId: categoryId ?? null,
    categoryFullPath: categoryFullPath ?? null,
    isUseCategory: readOnly
  })

  const { data, error, isLoading: ruleCBPLoading } = useCategoryBrandPathQuery(values.categoryId, {
    skip: !values.categoryId
  })

  const ruleCBP = data?.rows?.[0]
  const { categoryBrandPathMessage, categoryBrandPathStyle, categoryBrandPathInfo } = useCategoryBrandPathMessage(
    values.categoryId,
    ruleCBP,
    ruleCBPLoading,
    error
  )

  useHandleCategoryChange({ values, ruleCBP, index, isFormChanged, handleCategoryChange })

  const onCategoryChange = category => {
    const categoryId = category?.id
    const categoryFullPath = category?.fullPath
    updateValue({ categoryId, categoryFullPath })
  }

  return (
    <>
      <InputField
        className="mb-2"
        validationState={values.categoryId === undefined ? 'error' : null}
        required={required}
        label={'Category'}
      >
        <div>
          <div>
            <CategorySuggestion
              selectedId={values.categoryId}
              selectedLabel={values.categoryFullPath}
              changeCallback={onCategoryChange}
              readOnly={readOnly}
            />
          </div>
        </div>
        {values.isUseCategory && categoryBrandPathMessage && (
          <Alert bsStyle={categoryBrandPathStyle} className="my-2">
            <Glyphicon className="padding-rigt-standard" glyph="info-sign" />
            {categoryBrandPathMessage}
          </Alert>
        )}
        {isShowUseCategory && (
          <div className="pl-1 pt-3 checkbox">
            <label>
              <input
                id="isUseCategory"
                name="isUseCategory"
                type="checkbox"
                checked={values.isUseCategory}
                disabled={readOnly}
                onChange={handleFieldChange}
                title="Use Current Brand Path"
              />
              <span>Create rule at Category Level</span>
            </label>
          </div>
        )}
      </InputField>
      <InputField
        validationState={values.categoryId === undefined ? 'error' : null}
        required={!readOnly && required}
        label={'Category Brand Path'}
      >
        {categoryBrandPathInfo}
      </InputField>
    </>
  )
}

CategoryBrandPathSuggestion.propTypes = {
  categoryId: PropTypes.number,
  categoryFullPath: PropTypes.string,

  handleCategoryChange: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
  isShowUseCategory: PropTypes.bool,
  required: PropTypes.bool,
  index: PropTypes.number
}

export default CategoryBrandPathSuggestion
