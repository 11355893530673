import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import DocumentTitle from 'react-document-title'

import { Button, ButtonGroup, ButtonToolbar, Alert, Glyphicon } from 'react-bootstrap'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'

import { fetchEntries, resolveLocations } from '../../actions/authlog-actions'

import { uniq } from 'lodash'
import {
  renderIP,
  renderUA,
  renderCreatedAt,
  renderUpdatedAt,
  renderEvent,
  extractIp,
  trClassFormat,
  renderUser
} from './columnHelper'
import WithErrorBoundaryWrapper from 'components/WithErrorBoundaryWrapper/WithErrorBoundaryWrapper'
import { appName } from '../../constants/constants'

import './index.scss'

class AuthLogsPage extends Component {
  componentDidMount() {
    this.fetchEntries({ pageNumber: 1 })
  }

  onPageChange = pageNumber => {
    this.fetchEntries({ pageNumber })
  }

  onSizePerPageChange = sizePerPage => {
    this.fetchEntries({
      sizePerPage,
      pageNumber: 1
    })
  }

  fetchEntries = ({
    pageNumber = this.props.pageNumber,
    sizePerPage = this.props.sizePerPage,
    searchField = this.props.searchField,
    searchValue = this.props.searchValue
  }) => {
    this.props.fetchEntries({
      pageNumber,
      sizePerPage,
      searchField,
      searchValue
    })
  }

  getOptions = () => {
    return {
      sizePerPage: this.props.sizePerPage,
      onPageChange: this.onPageChange,
      sizePerPageList: [20, 50, 100],
      page: this.props.pageNumber,
      onSizePerPageList: this.onSizePerPageChange,
      sortName: 'createdBy',
      sortOrder: 'desc',
      onSearchChange: this.onSearchChange
    }
  }

  buildColumns = () => {
    const getColumn = column => {
      const defaultColumnConfig = {
        label: column,
        field: column,
        hasSort: false,
        dataAlign: 'left',
        editable: false
      }
      let config = {}
      switch (column) {
        case 'id':
          config = {
            width: '50px',
            dataAlign: 'center'
          }
          break
        case 'event':
          config = {
            dataFormat: renderEvent
          }
          break
        case 'createdAt':
          config = {
            columnClassName: 'createdAt-cell',
            dataFormat: renderCreatedAt
          }
          break
        case 'updatedAt':
          config = {
            dataFormat: renderUpdatedAt
          }
          break
        case 'device':
          config = {
            label: `${column} 💻`,
            dataFormat: renderUA
          }
          break
        case 'ip':
          config = {
            label: 'I.P. Address 🏠',
            dataFormat: renderIP.bind(this)
          }
          break
        case 'user':
          config = {
            dataFormat: renderUser
          }
          break
        default:
      }
      return {
        ...defaultColumnConfig,
        ...config
      }
    }
    const fields = ['id', 'event', 'user', 'createdAt', 'ip', 'device', 'updatedAt']
    const columns = fields.map(field => getColumn(field))
    const headerColumns = columns.map((col, index) => {
      return (
        <TableHeaderColumn
          dataField={col.field}
          isKey={col.field === 'id'}
          key={`col-${index}`}
          dataSort={col.hasSort}
          dataAlign={col.dataAlign}
          dataFormat={col.dataFormat}
          sortFunc={col.sortFunc}
          editColumnClassName={col.editColumnClassName}
          columnClassName={col.columnClassName}
          editable={col.editable}
          ref={col.field}
          width={col.width}
        >
          {col.label}
        </TableHeaderColumn>
      )
    })
    return headerColumns
  }

  resolveIpLocations = () => {
    const { data = [], resolveLocations, ipLocationMap } = this.props
    const ipList = uniq(data.map(extractIp).map(ipString => ipString.split(',').shift())).filter(
      ip => !ipLocationMap[ip]
    )
    resolveLocations(ipList)
  }

  render() {
    const { data = [], error, resolvingLocations, documentTitle } = this.props
    return (
      <DocumentTitle title={documentTitle ? `${appName} | ${documentTitle}` : appName}>
        <div className="container authlogs-page">
          {error && <Alert bsStyle="danger">{error}</Alert>}
          <ButtonToolbar>
            <ButtonGroup className="pull-right">
              <Button disabled={resolvingLocations} onClick={this.resolveIpLocations}>
                Resolve IP locations <Glyphicon glyph="map-marker" />
              </Button>
              <Button onClick={this.fetchEntries}>
                Reload <Glyphicon glyph="refresh" />
              </Button>
            </ButtonGroup>
          </ButtonToolbar>
          <BootstrapTable
            ref={el => {
              this.table = el
            }}
            data={data}
            pagination
            options={this.getOptions()}
            trClassName={trClassFormat}
            striped
            hover
            condensed
            bordered
            responsive
            className="authlogs-table"
            remote
            fetchInfo={{ dataTotalSize: 10000 }}
          >
            {this.buildColumns()}
          </BootstrapTable>
        </div>
      </DocumentTitle>
    )
  }
}

function mapStateToProps({ authlogs }) {
  return { ...authlogs }
}

AuthLogsPage.propTypes = {
  data: PropTypes.array,
  error: PropTypes.string,
  count: PropTypes.number,
  pageNumber: PropTypes.number,
  sizePerPage: PropTypes.number,
  searchField: PropTypes.string,
  searchValue: PropTypes.string,
  resolvingLocations: PropTypes.bool,
  ipLocationMap: PropTypes.object,
  fetchEntries: PropTypes.func,
  resolveLocations: PropTypes.func,
  documentTitle: PropTypes.string
}

AuthLogsPage.defaultProps = {
  documentTitle: 'Login Activity'
}

export default WithErrorBoundaryWrapper(
  connect(mapStateToProps, {
    fetchEntries,
    resolveLocations
  })(AuthLogsPage),
  '"Admin Login Activity" page'
)
