import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'react-bootstrap'
import { connect } from 'react-redux'
import { searchTermSuggest, getSearchMetaData } from './actions/niq-search-actions'
import { onSearchFilterChanged } from './actions/niq-widget-actions'
import constants from './constants/niq-constants'
import '../../styles/querybuilder.scss'
import './niq-querybuilder.scss'
import SearchFilterContainer from './SearchFilterContainer'
import { NIQ_SEARCH_STORE_PATH } from './reducers/niq-reducers'
import { ES_SCORECARD_KEY } from 'constants/constants'

/* REACT REDUX CONNECT */
function mapStateToProps(state, ownProps) {
  const niqSearchState = state[NIQ_SEARCH_STORE_PATH]
  return {
    tree: niqSearchState.tree,
    seq: niqSearchState.seq,
    selectedId: niqSearchState.selectedId,
    searchType: niqSearchState.searchType,
    searchValue: niqSearchState.searchValue,
    searchSuggestions: niqSearchState.searchSuggestions.reduce(
      (suggArray, suggestion) => {
        let label
        if (suggestion.type) {
          label = `${constants.fields[suggestion.type].label}${
            constants.fields[suggestion.type].canBeParent && !suggestion.isChild ? ' (Parent)' : ''
          }`
        }

        suggArray.push({
          ...suggestion,
          label
        })
        return suggArray
      },
      [
        {
          type: 'description',
          value: niqSearchState.searchValue,
          label: constants.fields.description.label
        }
      ]
    ),
    filters: niqSearchState.searchFilters,
    brandUpdateUsers: niqSearchState.brandUpdateUsers,
    categoryUpdateUsers: niqSearchState.categoryUpdateUsers
  }
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    onSearchTermSuggest: term => {
      dispatch(searchTermSuggest(term))
    },
    onSearchFilterChanged: (key, value) => {
      dispatch(onSearchFilterChanged(key, value))
    },
    getSearchMetaData: () => {
      dispatch(getSearchMetaData())
    }
  }
}

/* Search Component */
class Search extends Component {
  static propTypes = {
    searchType: PropTypes.string,
    searchValue: PropTypes.string,
    searchSuggestions: PropTypes.arrayOf(PropTypes.object),
    seq: PropTypes.number,
    tree: PropTypes.object,
    selectedId: PropTypes.string,
    onSearchTermSuggest: PropTypes.func.isRequired,
    onSearchFilterChanged: PropTypes.func.isRequired,
    filters: PropTypes.object,
    getSearchMetaData: PropTypes.func,
    brandUpdateUsers: PropTypes.array,
    categoryUpdateUsers: PropTypes.array
  }

  componentDidMount() {
    this.props.getSearchMetaData()
  }

  onSearchTermSuggest(text) {
    this.props.onSearchTermSuggest(text)
  }

  render() {
    return (
      <div>
        <div>
          <div>
            <Row className="show-grid">
              <Col xs={12} md={12}>
                <SearchFilterContainer
                  brandFilters={this.props.filters.brandFilters}
                  categoryFilters={this.props.filters.categoryFilters}
                  userTertile={this.props.filters.userTertile}
                  scoreCard={this.props.filters[ES_SCORECARD_KEY]}
                  onSearchFilterChanged={this.props.onSearchFilterChanged}
                  brandUpdateUsers={this.props.brandUpdateUsers}
                  categoryUpdateUsers={this.props.categoryUpdateUsers}
                  selectedBrandUsers={this.props.filters.selectedBrandUsers}
                  selectedCategoryUsers={this.props.filters.selectedCategoryUsers}
                />
              </Col>
            </Row>
            <br />
          </div>
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Search)
