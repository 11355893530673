import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import BCIConfig from './DataSelection'

import { StepPanelGroup, StepPanel, StepPanelSummary } from '../../Common/StepPanel'
import BasicConfig from './BasicConfig'
import ReportsConfig from './ReportsConfig'
import ScriptsConfig from './ScriptsConfig'

import { updateFormState, cancelEditing, scheduleNextDelivery } from '../../../../actions/delivery-config-actions'
import { successModal } from '../../../../actions/modal-actions'
import { deliverableConfigSteps as steps } from '../../../../utils/delivery-center'
import UserService from '../../../../services/user-service'
import { permissions as PERMISSIONS } from '../../../../constants/constants'
import { omit, get } from 'lodash'
import moment from 'moment'

const { BASIC_INFO, DATA_SELECTION, REPORTS, SCRIPTS } = steps

class DeliverableConfigPage extends Component {
  static propTypes = {}

  componentDidUpdate(prevProps) {
    const { deliverableId } = this.props
    const { deliverableId: prevDeliverableId } = prevProps
    // data/form initializations
    if (
      prevDeliverableId !== deliverableId && // deliverableId has upadted in URL
      prevDeliverableId !== 'new' // previous deliverableId was not new (i.e we haven't just saved a new deliverable config)
    ) {
      if (deliverableId === 'new') {
        // open basic info panel
        this.panelGroup.updatePanelExpandState(BASIC_INFO, true)
      } else {
        // enssure basic info panel is closed
        this.panelGroup.updatePanelExpandState(BASIC_INFO, false)
      }
    }
  }

  /**
   * Create on complete handler given STEP
   */
  onCompleteForStep = STEP => {
    const isLastStep = this.isLastPendingStep(STEP)
    return () => {
      this.markStepComplete(STEP, isLastStep)
    }
  }

  onConfigurationComplete = () => {
    const { successModal, scheduleNextDelivery, deliverableId } = this.props
    const callback = () => {
      const message = 'Good job! Now you can go to "Current Delivery" tab to monitor the delivery process.'
      successModal(message, null, null, 'Configuration Completed')
    }
    scheduleNextDelivery({ deliverableId }, callback)
  }

  /**
   * onComplete handelers for step.
   * @param {string} stepKey key for the step
   * @param {boolean} wasLastStep it was the last pending step that completed
   */
  markStepComplete = (stepKey, wasLastStep) => {
    const { stepsCompleted: currStepsCompleted, updateFormState, nextDelivery, deliverableConfigured } = this.props
    // find updated stepsCompleted
    const newStepsCompleted = {
      ...currStepsCompleted,
      [stepKey]: true
    }
    let editable = true

    // if all steps are just completed, make editable false & inform user
    if (wasLastStep || (deliverableConfigured && !nextDelivery)) {
      this.onConfigurationComplete()
      editable = false
    }

    updateFormState({
      stepsCompleted: newStepsCompleted,
      editable
    })
  }

  /**
   * Next button handler for nextStepKey.
   * It updates activeStep to the next step and opens next Step panel
   */
  onNextStepClick = nextStepKey => {
    let { activeStep } = this.props
    nextStepKey = nextStepKey || this.panelGroup.findNextStep(activeStep)
    if (nextStepKey) {
      // open next step panel, it will close all others
      this.panelGroup.updatePanelExpandState(nextStepKey, true)
    } else if (activeStep) {
      // close current step panel
      this.panelGroup.updatePanelExpandState(activeStep, false)
    }
    activeStep = nextStepKey
    this.props.updateFormState({
      activeStep
    })
  }

  /**
   * Checks if the STEP is last pending step to be completed
   */
  isLastPendingStep = STEP => {
    const { stepsCompleted } = this.props
    const values = Object.values(stepsCompleted)
    if (stepsCompleted[STEP] || values.indexOf(null) > -1) return false
    return Object.values(omit(stepsCompleted, [STEP])).filter(item => !item).length === 0
  }

  enableEdit = () => {
    this.props.updateFormState({ editable: true })
  }

  handleOnToggle = (stepKey, value) => {
    this.props.updateFormState({ activeStep: value ? stepKey : null })
  }

  /**
   * Create onToggle handler for STEP
   */
  onToggle = STEP => value => {
    this.handleOnToggle(STEP, value)
  }

  editButton = () => (
    <button
      className="btn_custom btn_small"
      key="edit"
      onClick={this.enableEdit}
      disabled={!this.props.userHasEditPermissions}
    >
      Edit
    </button>
  )

  lockButton = () => (
    <button className="btn_custom btn_small" key="cancel-edit" onClick={this.props.cancelEditing}>
      Done editing
    </button>
  )

  basicDetailsPanel() {
    const { stepsCompleted, editable } = this.props
    return (
      <StepPanel
        stepKey={BASIC_INFO}
        step={1}
        title="Basic Info"
        isComplete={stepsCompleted[BASIC_INFO]}
        editable={editable}
        onToggle={this.onToggle(BASIC_INFO)}
      >
        <BasicConfig
          editable={editable && stepsCompleted[BASIC_INFO] !== null}
          onComplete={this.onCompleteForStep(BASIC_INFO)}
          onNext={this.onNextStepClick}
          isLastPendingStep={this.isLastPendingStep(BASIC_INFO)}
        />
      </StepPanel>
    )
  }

  categoryPanel() {
    const { stepsCompleted, categoryCount, editable } = this.props
    return (
      <StepPanel
        stepKey={DATA_SELECTION}
        step={2}
        title={stepsCompleted[DATA_SELECTION] ? `${categoryCount} Categories` : 'Select Categories'}
        isComplete={stepsCompleted[DATA_SELECTION]}
        editable={editable}
        onToggle={this.onToggle(DATA_SELECTION)}
        disabled={!stepsCompleted[BASIC_INFO]}
      >
        <BCIConfig
          editable={editable && stepsCompleted[BASIC_INFO] && stepsCompleted[DATA_SELECTION] !== null}
          onComplete={this.onCompleteForStep(DATA_SELECTION)}
          onNext={this.onNextStepClick}
        />
      </StepPanel>
    )
  }

  reportsPanel() {
    const { stepsCompleted, editable } = this.props
    return (
      <StepPanel
        stepKey={REPORTS}
        step={3}
        title="Linked Reports"
        isComplete={stepsCompleted[REPORTS]}
        editable={editable}
        onToggle={this.onToggle(REPORTS)}
        disabled={!stepsCompleted[BASIC_INFO]}
      >
        <ReportsConfig
          editable={editable && stepsCompleted[BASIC_INFO] && stepsCompleted[REPORTS] !== null}
          onComplete={this.onCompleteForStep(REPORTS)}
          onNext={this.onNextStepClick}
        />
      </StepPanel>
    )
  }

  scriptsPanel() {
    const { stepsCompleted, activeStep, editable } = this.props
    return (
      <StepPanel
        stepKey={SCRIPTS}
        step={4}
        title="Scripts"
        isComplete={stepsCompleted[SCRIPTS]}
        editable={editable}
        onToggle={this.onToggle(SCRIPTS)}
        disabled={!stepsCompleted[BASIC_INFO]}
      >
        <ScriptsConfig
          active={activeStep === SCRIPTS}
          editable={editable && stepsCompleted[BASIC_INFO] && stepsCompleted[SCRIPTS] !== null}
          onComplete={this.onCompleteForStep(SCRIPTS)}
          onNext={this.onNextStepClick}
          isLastPendingStep={this.isLastPendingStep(SCRIPTS)}
        />
      </StepPanel>
    )
  }

  summaryPanel() {
    const { editable, deliverableConfigured, lastUpdated } = this.props
    return (
      <StepPanelSummary
        isComplete={deliverableConfigured}
        label={
          deliverableConfigured === null
            ? 'Loading...'
            : deliverableConfigured
            ? `Last edited on ${moment(lastUpdated).format('MM/DD/YYYY hh:mm A')}`
            : 'Configuration Pending'
        }
        actions={
          !deliverableConfigured
            ? []
            : editable
            ? deliverableConfigured
              ? [this.lockButton()]
              : []
            : [this.editButton()]
        }
      />
    )
  }

  render() {
    const { deliverableId, activeStep } = this.props
    if (!deliverableId) {
      return null
    }
    const defaultExpanded = {}
    if (activeStep) {
      defaultExpanded[activeStep] = true
    }

    return (
      <StepPanelGroup
        ref={el => {
          this.panelGroup = el
        }}
        id="deliverable-config"
        accordian
        defaultExpanded={defaultExpanded}
      >
        {this.basicDetailsPanel()}
        {this.categoryPanel()}
        {this.reportsPanel()}
        {this.scriptsPanel()}
        {this.summaryPanel()}
      </StepPanelGroup>
    )
  }
}

DeliverableConfigPage.propTypes = {
  deliverableId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  stepsCompleted: PropTypes.object,
  /**
   * null: Status of certain steps unknown
   * false: There is atleast 1 pending step
   * true: All steps are completed
   */
  deliverableConfigured: PropTypes.bool,
  editable: PropTypes.bool,
  lastUpdated: PropTypes.string,
  activeStep: PropTypes.string,
  categoryCount: PropTypes.number,
  initializeNew: PropTypes.func,
  initializeExisting: PropTypes.func,
  updateFormState: PropTypes.func,
  cancelEditing: PropTypes.func,
  successModal: PropTypes.func,
  scheduleNextDelivery: PropTypes.func,
  nextDelivery: PropTypes.string,
  userHasEditPermissions: PropTypes.bool
}

/**
 * Returns whether current form should be editable or not
 * @param {boolean} deliverableConfigured
 * @param {boolean} editableFromState
 */
function isFormEditable(deliverableConfigured, editableFromState) {
  let editable = false
  if (deliverableConfigured === false) {
    editable = true
  } else if (deliverableConfigured === true) {
    editable = editableFromState
  }
  return editable
}

function mapStateToProps(
  {
    dm: { selectedDeliverable: { id: deliverableId } = {} },
    session: { user },
    deliveryConfig: { deliverableData, stepsCompleted, editable, lastUpdated, activeStep }
  },
  { deliverableConfigured }
) {
  return {
    deliverableId,
    stepsCompleted,
    lastUpdated,
    activeStep,
    categoryCount: get(deliverableData, 'categories', []).filter(d => d.Category.is_leaf && d.Category.is_active)
      .length,
    nextDelivery: get(deliverableData, 'nextDelivery'),
    editable: isFormEditable(deliverableConfigured, editable),
    userHasEditPermissions: UserService.hasPermission(user, PERMISSIONS.sandboxDeliverableConfigEdit)
  }
}

export default connect(mapStateToProps, {
  updateFormState,
  cancelEditing,
  successModal,
  scheduleNextDelivery
})(DeliverableConfigPage)
