import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReactHighcharts from 'react-highcharts'
// import ReactHighstock from 'react-highcharts/ReactHighstock'
import highchartsTheme from './highcharts-theme'
import moment from 'moment'

const labels = {
  revenue: 'Revenue (Dollars)',
  count: 'Count'
}

class OverTimeHighChart extends Component {
  static propTypes = {
    data: PropTypes.object,
    title: PropTypes.string,
    selectedField: PropTypes.string,
    selectedFilter: PropTypes.string,
    aggregation: PropTypes.arrayOf(PropTypes.object),
    height: PropTypes.number,
    width: PropTypes.number
  }

  static defaultProps = {
    height: 250
  }

  constructor(props) {
    super(props)
    ReactHighcharts.Highcharts.setOptions(highchartsTheme)
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      this.props.data !== nextProps.data ||
      this.props.height !== nextProps.height ||
      this.props.width !== nextProps.width
    )
  }

  buildConfig(props) {
    const series = Object.keys(props.data).map(key => {
      const data = props.data[key]
      return {
        name: key,
        data:
          props.selectedField === 'count'
            ? data.map(v => [moment.utc(v[0]).valueOf(), parseFloat(Number(v[1]).toFixed(2))])
            : data.map(v => [moment.utc(v[0]).valueOf(), parseFloat(Number(v[2]).toFixed(2))])
      }
    })
    return {
      chart: {
        type: 'line',
        height: this.props.height,
        width: this.props.height ? null : this.props.width // if height is defined let width be auto
      },
      xAxis: {
        type: 'datetime',
        title: {
          dateTimeLabelFormats: {
            month: '%b %e, %Y',
            week: '%e. %b',
            day: '%e. %b'
          },
          text: 'Time'
        }
      },
      yAxis: {
        title: {
          text: labels[props.selectedField]
        }
      },
      plotOptions: {
        series: {
          allowPointSelect: true,
          animation: false,
          marker: {
            enabled: false
          }
        }
      },
      title: {
        text: props.title
      },
      credits: {
        enabled: false
      },
      series
    }
  }

  render() {
    return <ReactHighcharts config={this.buildConfig(this.props)} />
  }
}

export default OverTimeHighChart
