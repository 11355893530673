import React, { useState } from 'react'
import PropTypes from 'prop-types'

import AccordionItem from './AccordionItem'
import './accordion.scss'

/**
 * This is a custom accordion component
 * The main idea is a default state when all items are visible
 * The item header is on the left side. You can collapse/expand by clicking on this header.
 * When we expand one item, we minimize but don't close at all other items
 */
const Accordion = ({ children, totalHeight, collapsedHeight }) => {
  const [selectedPosition, setSelectedPosition] = useState(null)

  const onToggle = position => {
    if (position === selectedPosition) {
      // no selected items - all items will have average height
      setSelectedPosition(null)
    } else {
      // select one item
      setSelectedPosition(position)
    }
  }

  const getItemHeight = position => {
    const childrenCount = React.Children.count(children)
    const averageHeight = Math.round(totalHeight / childrenCount) // default view - all elements have the same height
    const expandedHeight = Math.round(totalHeight - collapsedHeight * (childrenCount - 1)) // expand one item

    let height
    let isCollapsed = false
    let isExpanded = false
    if (selectedPosition === null) {
      height = averageHeight
    } else if (selectedPosition === position) {
      height = expandedHeight
      isExpanded = true
    } else {
      height = collapsedHeight
      isCollapsed = true
    }

    return { height, isCollapsed, isExpanded }
  }

  const childrenWithProps = React.Children.map(children, (child, i) => {
    // Checking isValidElement is the safe way
    if (React.isValidElement(child)) {
      const { height, isCollapsed, isExpanded } = getItemHeight(i)
      const newElement = React.cloneElement(child, {
        handleClick: onToggle,
        position: i,
        height,
        isCollapsed,
        isExpanded
      })
      return newElement
    }
    return child
  })

  return (
    <div className="portal-accordion" style={{ height: `${totalHeight}px` }}>
      {childrenWithProps}
    </div>
  )
}

Accordion.propTypes = {
  totalHeight: PropTypes.number,
  collapsedHeight: PropTypes.number,
  children: PropTypes.node.isRequired
}

Accordion.defaultProps = {
  totalHeight: 600,
  collapsedHeight: 60
}

Accordion.Item = AccordionItem

export default Accordion
