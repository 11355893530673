export const JOB_TYPES = {
  RULES_DEPLOY_STAGING: 'rules-deploy-staging',
  RULES_DEPLOY_PRODUCTION: 'rules-deploy-production',
  PUBLISH_DELIVERY: 'delivery-jobs-publish',
  CATEGORY_TESTS_RUN_BATCH: 'category-tests-run-batch',
  CATEGORY_TESTS_ARCHIVE: 'category-tests-archive',

  // NIQ
  NIQ_ALL_LOAD: 'niq-all-load',
  NIQ_TAXONOMY_BRAND_LOAD: 'niq-taxonomy-brand-load',
  NIQ_TAXONOMY_CATEGORY_LOAD: 'niq-taxonomy-category-load',
  NIQ_TAXONOMY_CBR_LOAD: 'niq-taxonomy-cbr-load',

  // Others
  DEACTIVATE_INACTIVE_USERS: 'deactivate-inactive-users'
}

export const JOB_GROUPS = {
  PORTAL: 'Portal',
  SEARCH: 'Search / QC tool',
  SUPRA_QC: 'Supra QC Tool',
  SANDBOX: 'Sandbox / Deliveries',
  DELIVERY_JOBS: 'Delivery Jobs',
  TAXONOMY: 'Taxonomy',
  IDF: 'IDF',
  NIQ: 'NIQ',
  OTHERS: 'Others'
}

const JOB_LIST = [
  {
    key: 'snowflake-unload-scorecard',
    label: 'Refresh Scorecard Data',
    group: JOB_GROUPS.SEARCH,
    tooltip: 'Load Scorecard data from Snowflake to S3'
  },
  {
    key: 'snowflake-unload-nonscorecard',
    label: 'Refresh Non-Scorecard Data',
    group: JOB_GROUPS.SEARCH,
    tooltip: 'Load Non-Scorecard data from Snowflake to S3'
  },
  {
    key: 'snowflake-load-scorecard',
    label: 'Scorecard Data - Only Load',
    group: JOB_GROUPS.SEARCH,
    tooltip: 'Load Scorecard data from S3 to ElasticSearch'
  },
  {
    key: 'snowflake-load-nonscorecard',
    label: 'Non-Scorecard Data - Only Load',
    group: JOB_GROUPS.SEARCH,
    tooltip: 'Load Non-Scorecard data from S3 to ElasticSearch'
  },
  {
    key: 'cme-dictionary-history-purge',
    label: 'Purge Dictionary History',
    group: JOB_GROUPS.SEARCH
  },
  {
    key: 'supra-qc-load-sampling-result',
    label: 'Reload sampling results',
    group: JOB_GROUPS.SUPRA_QC
  },
  {
    key: 'supra-qc-dictionary-check',
    label: 'Supra QC Dictionary Check',
    group: JOB_GROUPS.SUPRA_QC
  },
  {
    key: 'supra-qc-dictionary-load',
    label: 'Supra QC Dictionary Load (Snowflake to Postgres)',
    group: JOB_GROUPS.SUPRA_QC
  },
  {
    key: 'supra-qc-dictionary-sync',
    label: 'Supra QC Dictionary Sync (Postgres to Snowflake)',
    group: JOB_GROUPS.SUPRA_QC
  },
  {
    key: 'idf-email',
    label: 'Idf Email (Admin)',
    group: JOB_GROUPS.IDF
  },
  {
    key: 'idf-check-group-lock',
    label: 'IDF Check locked Groups',
    group: JOB_GROUPS.IDF,
    tooltip: 'Check Groups and send emails about long-time locked Groups'
  },
  {
    key: 'test',
    label: 'ETL System Test',
    tooltip: 'Simple worker for smoke testing of ETL'
  },
  {
    key: 'cleanup',
    label: 'Cleanup',
    tooltip: 'Run a set of cleanup scripts to tidy up the system'
  },
  {
    key: 'filter-values-load',
    label: 'Filter Values Load'
  },
  {
    key: JOB_TYPES.DEACTIVATE_INACTIVE_USERS,
    label: 'Deactivate Inactive Users',
    group: JOB_GROUPS.OTHERS,
    tooltip:
      'Deactivate users who have not logged in for more than six months. They can access the App only after resetting the password.'
  },
  {
    key: 'delivery-orchestrator',
    label: 'Deliverable customizations (data ready to be published)',
    group: JOB_GROUPS.SANDBOX
  },
  {
    key: 'delivery-scheduler',
    label: 'Start deliveries (deliverables that have been configured)',
    group: JOB_GROUPS.SANDBOX
  },
  {
    key: 'create-delivery-category-jobs',
    label: 'Schedule cleanups (deliverables with data that are ready to be cleaned)',
    group: JOB_GROUPS.SANDBOX
  },
  {
    key: 'taxonomy-merchant-load',
    label: 'Merchants Load',
    group: JOB_GROUPS.TAXONOMY,
    tooltip: "Load merchants and merchant's quality from snowflake"
  },
  {
    key: JOB_TYPES.RULES_DEPLOY_STAGING,
    label: 'Rules Staging Deploy',
    group: JOB_GROUPS.TAXONOMY,
    tooltip: 'Deploy rules in staging CME DB'
  },
  {
    key: JOB_TYPES.PUBLISH_DELIVERY,
    label: 'Publish Delivery Jobs',
    group: JOB_GROUPS.DELIVERY_JOBS,
    tooltip: 'Publis Delivery Jobs which are in the Ready status'
  },
  {
    key: 'delivery-jobs-disable-outdated',
    label: 'Disable outdated Delivery Jobs',
    group: JOB_GROUPS.DELIVERY_JOBS,
    tooltip: 'Disable outdated(less than current months) Delivery Jobs which are not in the Published status'
  },
  {
    key: JOB_TYPES.CATEGORY_TESTS_RUN_BATCH,
    label: 'Run Category Tests with Delivery Jobs in progress',
    group: JOB_GROUPS.DELIVERY_JOBS,
    tooltip: 'Run Category Test with Delivery Jobs that are in processing.'
  },
  {
    key: JOB_TYPES.CATEGORY_TESTS_ARCHIVE,
    label: 'Archive Category Tests',
    group: JOB_GROUPS.DELIVERY_JOBS,
    tooltip: 'Archive Category Test results'
  },

  // NIQ
  {
    key: JOB_TYPES.NIQ_ALL_LOAD,
    label: 'Load all NIQ Data',
    group: JOB_GROUPS.NIQ,
    tooltip:
      'This job checks for new NIQ data and executes all necessary data loading tasks to ensure successful data uploads.'
  },
  {
    key: JOB_TYPES.NIQ_TAXONOMY_BRAND_LOAD,
    label: 'Load NIQ Brands',
    group: JOB_GROUPS.NIQ,
    tooltip: 'Load NIQ Taxonomy Brands from Snowflake'
  },
  {
    key: JOB_TYPES.NIQ_TAXONOMY_CATEGORY_LOAD,
    label: 'Load NIQ Categories',
    group: JOB_GROUPS.NIQ,
    tooltip: 'Load NIQ Taxonomy Categories from Snowflake'
  },
  {
    key: JOB_TYPES.NIQ_TAXONOMY_CBR_LOAD,
    label: 'Load NIQ CBR',
    group: JOB_GROUPS.NIQ,
    tooltip: 'Load NIQ Rules Category-Brand from Snowflake'
  },
  {
    key: 'snowflake-unload-niq-scorecard',
    label: 'Refresh NIQ Scorecard Data',
    group: JOB_GROUPS.NIQ,
    tooltip: 'Load NIQ data from Snowflake to S3'
  },
  {
    key: 'snowflake-load-niq-scorecard',
    label: 'NIQ Scorecard Data - Only Load',
    group: JOB_GROUPS.NIQ,
    tooltip: 'Load NIQ data from S3 to ElasticSearch'
  }
]

const isProduction = process.env.REACT_APP_DEPLOY_ENV.toLowerCase() === 'production'
if (isProduction) {
  JOB_LIST.unshift({
    key: 'snowflake-publish-scorecard',
    label: 'Publish Scorecard Data',
    group: JOB_GROUPS.SEARCH,
    tooltip: 'Prepare scorecard data in Snowflake for uploading into QC tool'
  })

  JOB_LIST.push({
    key: JOB_TYPES.RULES_DEPLOY_PRODUCTION,
    label: 'Rules Production Deploy',
    group: JOB_GROUPS.TAXONOMY,
    tooltip: 'Deploy rules in production CME DB'
  })
}

export { JOB_LIST }
