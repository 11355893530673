import React, { Component } from 'react'
import PropTypes from 'prop-types'

import ReactHighcharts from 'react-highcharts'
import { Button } from 'react-bootstrap'

import highchartsTheme from '../../../content/widgets/highcharts-theme'

ReactHighcharts.Highcharts.setOptions(highchartsTheme)

export default class AvgRuntimesChart extends Component {
  constructor(props) {
    super(props)

    this.state = {
      minActive: false,
      maxActive: false
    }
  }

  getChartConfig = () => {
    const { taskRuntimes } = this.props
    const { maxActive, minActive } = this.state

    const taskTypes = Object.keys(taskRuntimes)
    const avgRuntimes = []
    const minRuntimes = []
    const maxRuntimes = []

    taskTypes.forEach(taskType => {
      const task = taskRuntimes[taskType]

      avgRuntimes.push(task.average)
      minRuntimes.push(task.min)
      maxRuntimes.push(task.max)
    })

    const series = [
      {
        name: 'Average Runtime',
        data: avgRuntimes,
        type: 'column'
      }
    ]

    if (minActive) {
      series.push({
        name: 'Min Runtime',
        data: minRuntimes,
        type: 'line'
      })
    }

    if (maxActive) {
      series.push({
        name: 'Max Runtime',
        data: maxRuntimes,
        type: 'line'
      })
    }

    return {
      series,
      chart: { type: 'column' },
      title: { text: '' },
      xAxis: {
        title: { text: 'Task Type' },
        categories: taskTypes
      },
      yAxis: {
        title: { text: 'Runtime (minutes)' }
      },
      plotOptions: {
        series: {
          allowPointSelect: true,
          animation: true
        }
      },
      lang: {
        noData: 'No tasks found for selected timeframe'
      },
      noData: {
        style: {
          fontWeight: 'bold',
          fontSize: '15px',
          color: '#303030'
        }
      },
      credits: { enabled: false },
      legend: { enabled: false }
    }
  }

  handleButtonClick = buttonEvent => {
    const button = buttonEvent.target.id

    this.setState({
      [button]: !this.state[button]
    })
  }

  getChartButtons = () => {
    const { minActive, maxActive } = this.state

    return (
      <div className="etl-stats-chart-buttons">
        <Button id="minActive" active={minActive} onClick={this.handleButtonClick}>
          Min
        </Button>

        <Button id="maxActive" active={maxActive} onClick={this.handleButtonClick}>
          Max
        </Button>
      </div>
    )
  }

  render() {
    return (
      <div className="etl-stats-widget etl-stats-chart-widget">
        <h3 id="avg-runtimes-header">Average Runtimes</h3>

        <ReactHighcharts config={this.getChartConfig()} />

        {this.getChartButtons()}
      </div>
    )
  }
}

AvgRuntimesChart.propTypes = {
  taskRuntimes: PropTypes.object
}
