import React, { Component } from 'react'
import { OverlayTrigger, Popover, Button } from 'react-bootstrap'

class ConfigHelp extends Component {
  helpContainer = (
    <Popover id="feed-modules-config-properties-help">
      <p>
        - Extract Type ID (<strong>extractTypeId</strong>) - This comes from the Data Warehouse table and every
        feed-frequency has an extract type. For example: Weekly is 1, Daily is 2.
      </p>
      <p>
        - Table Name (<strong>tableName</strong>) - Usually, every feed has a source table that is referred to using
        `tableName` in their respective query. But this field is optional if there is no mention of the keyword
        `tableName` in the query.
      </p>
      <p>
        - Query File (<strong>queryFile</strong>) - This is a name of SQL query.
      </p>
      <p>
        - Legacy Prefix - (<strong>legacyPrefix</strong>) - A file prefix naming convention used for Groups with legacy{' '}
        <em>&quot;File naming convention&quot;</em>. This is mostly legacy and not used.
      </p>
      <p>
        - Feed Prefix - (<strong>feedPrefix</strong>) - A file prefix naming convention used for that specific feed.
      </p>
      <p>
        <strike>
          - Post Query - (<strong>postQuery</strong>) - SQL query which will be run in the end of data processing.
        </strike>{' '}
        <span>Deprecated. Only Redshift supported</span>
      </p>
      <p>
        - Schedule - (<strong>schedule</strong>) - Set frequency for Non Extract Based feeds.This is an object which
        options:
      </p>
      <ul>
        <li>
          <strong>frequency</strong> - available options <em>&quot;day&quot;</em>, <em>&quot;month&quot;</em>,{' '}
          <em>&quot;weekly&quot;</em>
        </li>
        <li>
          <strong>dayOfWeek</strong> - number of day <em>[1-7]</em>. For example: &quot;3&quot; is Wednesday
        </li>
        <li>
          <strong>hourMark</strong> - number of hour <em>[0-23]</em> For example: &quot;13&quot; is 1PM
        </li>
      </ul>
    </Popover>
  )

  render() {
    return (
      <OverlayTrigger trigger="click" placement="left" overlay={this.helpContainer}>
        <Button className="btn-link">What properties can I use?</Button>
      </OverlayTrigger>
    )
  }
}

export default ConfigHelp
