import React from 'react'
import { Controller } from 'react-hook-form'
import Select from 'react-select'
import PropTypes from 'prop-types'
import InputField from 'components/FormInputComponents/InputField'

const TypeInput = ({ control, errors, options, info }) => {
  return (
    <InputField
      label="Type"
      required
      info={info}
      validationMessage={errors.types?.message}
      className="text-start"
      labelSize={4}
    >
      <Controller
        name="types"
        control={control}
        render={({ field }) => (
          <Select
            {...field}
            isMulti
            options={options}
            className={`basic-multi-select text-start ${errors.types ? 'validation-error' : ''}`}
            classNamePrefix="select"
          />
        )}
      />
    </InputField>
  )
}

TypeInput.propTypes = {
  control: PropTypes.any,
  errors: PropTypes.object,
  info: PropTypes.string,
  options: PropTypes.array
}

export default TypeInput
