/**
 * Format number with commas to make it more readable
 * @param {number} value - The number to format
 * @returns {string} - The formatted number
 */
function formatNumber(value) {
  const numberValue = Number(value)

  if (isNaN(numberValue)) {
    // If it's not a valid number, return the original value
    return value
  }

  return numberValue.toLocaleString()
}

/**
 * Format all numbers in an object with commas to make them more readable
 * Note: this function does not process nested objects
 * @param {Object} obj - The object to format
 * @returns {Object} - The formatted object with numbers formatted
 */
export const prettifyNumberValues = obj => {
  const formattedObj = {}

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key]

      // Only process if the value is a number or a string
      if (typeof value === 'number' || typeof value === 'string') {
        formattedObj[key] = formatNumber(value)
      } else {
        // For other types, keep the value as is
        formattedObj[key] = value
      }
    }
  }

  return formattedObj
}
