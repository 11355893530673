import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import cx from 'classnames'

import { fetchGroupQuerySuggestionsAction, fetchQuerySuggestionsAction } from './actions'
import Suggestion from './Suggestion'
import useActiveElementLoading from './useActiveElementLoading'

import { clearQuerySuggestions } from './slices'

import './suggestion.scss'

const DEFULT_QUERY_SEARCH_LIMIT = 20

const QuerySuggestion = ({
  groupQueryId,
  groupQueryLabel,
  queryId,
  queryLabel,
  changeGroupQueryCallback,
  changeQueryCallback,
  readOnly,
  inline
}) => {
  const dispatch = useDispatch()
  const {
    groupQueries: { data: groupQuerySuggestions, loading: loadingGroup, id: activeGroupID },
    queries: { data: querySuggestions, loading: loadingQuery, id: activeQueryID }
  } = useSelector(state => state.suggestions)
  const [groupID, isGroupLoading] = useActiveElementLoading(loadingGroup, activeGroupID)
  const [queryID, isQueryLoading] = useActiveElementLoading(loadingQuery, activeQueryID)

  const isGroupSelected = !!groupQueryId

  const fetchGroupQuerySuggestionsCallback = searchParams => {
    dispatch(fetchGroupQuerySuggestionsAction({ id: groupID, ...searchParams }))
  }

  const fetchQuerySuggestionsCallback = searchParams => {
    dispatch(fetchQuerySuggestionsAction({ groupId: groupQueryId, id: queryID, ...searchParams }))
  }

  const onQueryMenuShow = () => {
    dispatch(clearQuerySuggestions())
    dispatch(fetchQuerySuggestionsAction({ groupId: groupQueryId, limit: DEFULT_QUERY_SEARCH_LIMIT, id: queryID }))
  }

  return (
    <div className={cx('suggestion-query', { 'suggestion-query-inline': inline })}>
      <div className="suggestion-query-field">
        <div className="suggestion-query-label">Group:</div>
        <div className="suggestion-query-input">
          <Suggestion
            labelKey={'name'}
            selectedId={groupQueryId}
            selectedLabel={groupQueryLabel}
            searchSuggestions={groupQuerySuggestions}
            fetchAction={fetchGroupQuerySuggestionsCallback}
            changeCallback={changeGroupQueryCallback}
            placeholder={`Search for a Group`}
            readOnly={readOnly}
            isLoading={isGroupLoading}
          />
        </div>
      </div>
      <div className="suggestion-query-field">
        <div className="suggestion-query-label">Query:</div>
        <div className="suggestion-query-input">
          <Suggestion
            labelKey={'name'}
            selectedId={queryId}
            selectedLabel={queryLabel}
            searchSuggestions={querySuggestions}
            fetchAction={fetchQuerySuggestionsCallback}
            changeCallback={changeQueryCallback}
            placeholder={`Search for a Query`}
            readOnly={readOnly || !isGroupSelected}
            minLength={0}
            onMenuShow={onQueryMenuShow}
            isLoading={isQueryLoading}
          />
        </div>
      </div>
    </div>
  )
}

QuerySuggestion.propTypes = {
  groupQueryId: PropTypes.number,
  groupQueryLabel: PropTypes.string,

  queryId: PropTypes.number,
  queryLabel: PropTypes.string,

  changeGroupQueryCallback: PropTypes.func,
  changeQueryCallback: PropTypes.func,

  groupQuerySuggestions: PropTypes.array,
  querySuggestions: PropTypes.array,

  readOnly: PropTypes.bool,
  inline: PropTypes.bool
}

QuerySuggestion.defaultProps = {
  readOnly: false,
  inline: false
}

export default QuerySuggestion
