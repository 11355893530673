import { useCallback } from 'react'
import { createNextState } from '@reduxjs/toolkit'
import { emptySynonymObj } from './BrandSynonymRuleModal'

export default function useFormBrandSynonymFilds(updateValue) {
  const handleBrandChange = useCallback(
    brand => {
      const brandId = brand?.id
      const brandFullPath = brand?.fullPath
      const synonyms = [emptySynonymObj]
      updateValue(values => ({ ...values, brandId, brandFullPath, synonyms }))
    },
    [updateValue]
  )

  const handleSynonymChange = useCallback(
    (synonym, index) => {
      updateValue(values => {
        return {
          synonyms: createNextState(values.synonyms, draftState => {
            draftState[index] = synonym
          })
        }
      })
    },
    [updateValue]
  )

  return { handleBrandChange, handleSynonymChange }
}
