import { useCallback } from 'react'
import { createNextState } from '@reduxjs/toolkit'

/**
 * Handlers for add/remove fields and Category and Synonym changes
 */
const useBrandRequestsModalButtons = ({ updateValue, emptyCategoryObj }) => {
  // immutable updates
  const addCategoryFieldHandler = () =>
    updateValue(values => ({ categories: [...values.categories, emptyCategoryObj] }))
  const removeCategoryFieldHandler = () => updateValue(values => ({ categories: values.categories.slice(0, -1) }))

  const handleCategoryChange = useCallback(
    newValue => {
      updateValue(values => {
        return {
          categories: createNextState(values.categories, draftState => {
            draftState[newValue.index] = { id: newValue.id, fullPath: newValue.fullPath }
          })
        }
      })
    },
    [updateValue]
  )

  const addSynonymFieldHandler = () => updateValue(values => ({ synonyms: [...values.synonyms, null] }))
  const removeSynonymFieldHandler = () => updateValue(values => ({ synonyms: values.synonyms.slice(0, -1) }))

  const handleSynonymChange = useCallback(
    typeEvent => {
      const element = typeEvent.target
      const value = element.value
      const index = Number(element.dataset.index)

      updateValue(values => {
        return {
          synonyms: createNextState(values.synonyms, draftState => {
            draftState[index] = value
          })
        }
      })
    },
    [updateValue]
  )

  return {
    addCategoryFieldHandler,
    removeCategoryFieldHandler,
    handleCategoryChange,
    addSynonymFieldHandler,
    removeSynonymFieldHandler,
    handleSynonymChange
  }
}

export default useBrandRequestsModalButtons
