import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Form, Panel, FormControl, FormGroup, ControlLabel, Button } from 'react-bootstrap'

class WidgetTableOptions extends Component {
  render() {
    return this.props.showToolbar ? (
      <Panel defaultExpanded className="widget-table-options">
        <Panel.Collapse>
          <Panel.Body>
            <Form inline>
              <FormGroup>
                <ControlLabel>
                  <h5>Select</h5>
                </ControlLabel>
                &nbsp;&nbsp;&nbsp;
                <FormControl
                  value={this.props.selectedSize}
                  className={'select-size'}
                  type="number"
                  placeholder="10"
                  onChange={event => {
                    this.props.onWidgetsOptionsChanged(
                      this.props.aggType,
                      'selectedSize',
                      parseInt(event.target.value, 10)
                    )
                  }}
                  onKeyPress={event => {
                    // @todo TODO: Think of a better way to handle this
                    // Fire on return/enter key
                    if (event.charCode === 13) {
                      event.preventDefault()
                      event.stopPropagation()
                      this.props.updateWidgetData(this.props.aggType)
                    }
                  }}
                />
                &nbsp;&nbsp;&nbsp;
                <Button
                  className={'refresh-widget-btn'}
                  bsStyle="info"
                  onClick={() => this.props.updateWidgetData(this.props.aggType)}
                >
                  Refresh
                </Button>
              </FormGroup>
            </Form>
          </Panel.Body>
        </Panel.Collapse>
      </Panel>
    ) : null
  }
}

WidgetTableOptions.propTypes = {
  selectedSize: PropTypes.number,
  onWidgetsOptionsChanged: PropTypes.func,
  updateWidgetData: PropTypes.func,
  aggType: PropTypes.string,
  showToolbar: PropTypes.bool
}

export default WidgetTableOptions
