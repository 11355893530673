import React from 'react'

import { DateCell, TextCell, BooleanCell } from 'components/DataTable'
import { DATA_TABLE } from 'components/DataTable/constants'
import CategoryGuidelineBooleanCell from './CategoryGuidelineBooleanCell'
import CategoryGuidelineActionsCell from './CategoryGuidelineActionsCell'

export default function getColumns(hasEditAccess) {
  return [
    {
      field: 'id',

      dataAlign: 'center',
      headerAlign: 'center',
      width: '75px',

      label: 'Cat ID',
      title: 'Category ID',
      dataSort: true,
      filter: {
        type: 'TextFilter',
        delay: DATA_TABLE.FILTER_DELAY
      }
    },
    {
      field: 'categoryFullPath',

      dataAlign: 'left',
      headerAlign: 'center',
      width: '280px',

      label: 'Category full path',
      dataSort: true,
      filter: {
        type: 'TextFilter',
        delay: DATA_TABLE.FILTER_DELAY
      }
    },
    {
      field: 'isActive',

      dataAlign: 'center',
      headerAlign: 'center',
      width: '80px',

      label: 'Active',
      title: 'Is Category active and a leaf',
      dataSort: true,
      dataFormat: (cell, row) =>
        CategoryGuidelineBooleanCell({ isActive: cell, isCategoryActive: row.isCategoryActive, isLeaf: row.isLeaf }),
      filter: {
        type: 'SelectFilter',
        options: {
          1: 'True',
          0: 'False'
        }
      }
    },
    {
      field: 'include',

      dataAlign: 'left',
      headerAlign: 'center',
      width: 'auto',

      label: 'Include',
      dataSort: true,
      dataFormat: (cell, row) => TextCell({ text: cell }),
      filter: {
        type: 'TextFilter',
        delay: DATA_TABLE.FILTER_DELAY
      }
    },
    {
      field: 'exclude',

      dataAlign: 'left',
      headerAlign: 'center',
      width: 'auto',

      label: 'Exclude',
      dataSort: true,
      dataFormat: (cell, row) => TextCell({ text: cell }),
      filter: {
        type: 'TextFilter',
        delay: DATA_TABLE.FILTER_DELAY
      }
    },
    {
      field: 'notes',

      dataAlign: 'left',
      headerAlign: 'center',
      width: 'auto',

      label: 'Notes',
      dataSort: true,
      dataFormat: (cell, row) => TextCell({ text: cell }),
      filter: {
        type: 'TextFilter',
        delay: DATA_TABLE.FILTER_DELAY
      }
    },
    {
      field: 'isEdited',

      dataAlign: 'center',
      headerAlign: 'center',
      width: '80px',

      label: 'Edited',
      title: 'Edited if it has value in all or one of these fields: "Include", "Exclude" or "Notes"',
      dataSort: true,
      dataFormat: (cell, row) => BooleanCell({ isActive: cell }),
      filter: {
        type: 'SelectFilter',
        options: {
          1: 'True',
          0: 'False'
        }
      }
    },
    {
      field: 'updatedByName',

      dataAlign: 'center',
      headerAlign: 'center',
      width: '130px',

      label: 'Updated By',
      dataSort: true,
      filter: {
        type: 'TextFilter',
        delay: DATA_TABLE.FILTER_DELAY
      }
    },
    {
      field: 'updatedAt',

      dataAlign: 'center',
      headerAlign: 'center',
      width: '160px',

      label: 'Updated At',
      dataSort: true,
      dataFormat: (cell, row) => DateCell({ date: cell }),
      filter: {
        type: 'DateFilter',
        dateOnly: true,
        delay: DATA_TABLE.FILTER_DELAY
      }
    },
    {
      field: 'actionsColumn',
      label: 'Actions',
      dataAlign: 'center',
      headerAlign: 'center',
      width: '70px',
      dataFormat: function getActionCell(cell, row) {
        return <CategoryGuidelineActionsCell row={row} hasEditAccess={hasEditAccess} />
      }
    }
  ]
}
