import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import QueryBuilder from '../QueryBuilder'
import {
  findRule,
  areMultipleDescriptionsSelected,
  isParentValueSelected,
  controlElements
} from '../../utils/portal-query-builder'
import { createAlert } from '../../actions/app-actions'
import { addCustomModal } from '../../actions/modal-actions'
import CopyToClipboardModal from '../../components/Modals/CopyToClipboardModal'
import { ES_SCORECARD_KEY, permissions as PERMISSIONS } from '../../constants/constants'
import { controlClassnames, fields, getOperators, combinators } from './config'
import {
  handleQueryTreeChanged,
  fetchTypeChildren,
  applyQuery,
  clearQueryTree,
  getQuerySQL,
  createMultipleRules
} from '../../actions/search-actions'
import QueryHeader from './QueryHeader'
import UserService from '../../services/user-service'
import '../../utils/portal-query-builder/index.scss'
import './style.scss'

class QueryBuilderTree extends Component {
  componentDidUpdate(prevProps) {
    if (prevProps.querySQLLoading === true && this.props.querySQLLoading === false && this.props.querySQL) {
      this.openCopyModal(this.props.querySQL)
    }
  }

  openCopyModal = sql => {
    this.props.addCustomModal({
      modalType: 'successModal',
      id: 'copy-to-clipboard-modal',
      className: 'textarea-modal',
      title: 'Query SQL',
      content: (
        <CopyToClipboardModal
          note="Note: Rules with contains, not contains, contains (stem) and not contains (stem) have been evaluated as if they are wildcard rules."
          text={sql}
          onCopy={this.onCopy}
        />
      )
    })
  }

  applyAndSave = () => {
    if (this.queryHeader) {
      this.queryHeader.saveSearchQuery()
      this.props.applyQuery({ dataIndex: this.props.filters[ES_SCORECARD_KEY] })
    }
  }

  onCopy = (text, result) => {
    const type = result ? 'info' : 'danger'
    const title = result ? 'SQL copied to clipboard' : 'There was an error trying to copy the SQL'
    this.props.createAlert(type, '', title)
  }

  showQuerySQLModal = async query => {
    await this.props.getQuerySQL(query)
  }

  render() {
    const onQueryChange = (query, event, ...actionArgs) => {
      if (areMultipleDescriptionsSelected(event, actionArgs)) {
        // when event == 'onPropChange'
        // actionArgs == [propName, propValue, ruleId]
        const ruleId = actionArgs[2]
        const rule = findRule(ruleId, query)
        this.props.createMultipleRules(rule.value.action, ruleId, rule.value.value)
      } else if (isParentValueSelected(event, actionArgs)) {
        // when event == 'onPropChange'
        // actionArgs == [propName, propValue, ruleId]
        const ruleId = actionArgs[2]
        const rule = findRule(ruleId, query)
        this.props.fetchTypeChildren(rule.field, rule.value, rule.id)
      } else {
        this.props.handleQueryTreeChanged(query, event, actionArgs)
      }
    }
    const {
      isQueryInteractionPermitted,
      isQueryHeaderEdited,
      queryTree,
      ruleGroupsCountChange,
      clearQueryTree
    } = this.props
    return (
      <>
        {isQueryInteractionPermitted && (
          <QueryHeader
            ref={ref => {
              if (ref) {
                this.queryHeader = ref
              }
            }}
            ruleGroupsCountChange={ruleGroupsCountChange}
          />
        )}
        <QueryBuilder
          fields={fields}
          combinators={combinators}
          getOperators={getOperators}
          query={queryTree}
          onQueryChange={onQueryChange}
          controlClassnames={controlClassnames}
          controlElements={controlElements}
          onClearQueryTree={clearQueryTree}
          showQuerySQLModal={() => this.showQuerySQLModal(queryTree)}
          ruleGroupsCountChange={ruleGroupsCountChange}
        />
        <div className="query-apply-container">
          <span
            className="btn_custom pull-right"
            onClick={() => {
              this.props.applyQuery({ dataIndex: this.props.filters[ES_SCORECARD_KEY] })
            }}
          >
            Apply
          </span>
          {isQueryHeaderEdited && isQueryInteractionPermitted && (
            <span className="btn_custom pull-right btn-apply-save btn_custom_bg_red" onClick={this.applyAndSave}>
              Save & Apply
            </span>
          )}
        </div>
      </>
    )
  }
}

QueryBuilderTree.propTypes = {
  queryTree: PropTypes.object,
  handleQueryTreeChanged: PropTypes.func,
  fetchTypeChildren: PropTypes.func,
  applyQuery: PropTypes.func,
  isQueryHeaderEdited: PropTypes.bool,
  user: PropTypes.object,
  clearQueryTree: PropTypes.func,
  getQuerySQL: PropTypes.func,
  isQueryInteractionPermitted: PropTypes.bool,
  createMultipleRules: PropTypes.func,
  createAlert: PropTypes.func,
  querySQL: PropTypes.string,
  addCustomModal: PropTypes.func,
  querySQLLoading: PropTypes.bool,
  ruleGroupsCountChange: PropTypes.func,
  filters: PropTypes.object
}

function mapStateToProps(state) {
  return {
    queryTree: state.search.queryTree,
    querySQL: state.search.querySQL,
    querySQLLoading: state.search.querySQLLoading,
    isQueryHeaderEdited: state.search.isQueryHeaderEdited,
    isQueryInteractionPermitted: state.session.user
      ? UserService.hasPermission(state.session.user, PERMISSIONS.adminToolsQCToolQueryInteraction)
      : false,
    filters: state.search.searchFilters
  }
}

export default connect(mapStateToProps, {
  handleQueryTreeChanged,
  fetchTypeChildren,
  applyQuery,
  clearQueryTree,
  getQuerySQL,
  createMultipleRules,
  createAlert,
  addCustomModal
})(QueryBuilderTree)
