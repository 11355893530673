import React from 'react'
import { Controller } from 'react-hook-form'
import { FormControl } from 'react-bootstrap'
import PropTypes from 'prop-types'
import InputField from 'components/FormInputComponents/InputField'

const DomainInput = ({ control, errors, info }) => {
  return (
    <InputField
      label="Domain"
      required
      info={info}
      className="text-start"
      labelSize={4}
      validationMessage={errors.domain?.message}
    >
      <Controller required name="domain" control={control} render={({ field }) => <FormControl {...field} />} />
    </InputField>
  )
}

DomainInput.propTypes = {
  control: PropTypes.any,
  info: PropTypes.string,
  errors: PropTypes.object
}

export default DomainInput
