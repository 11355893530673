import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import MerchantBrandRuleModal from './Modals/MerchantBrandRuleModal'
import CommonRuleActionsCell from './../Common/CommonRuleActionsCell'

import { MerchantBrandRulesActions } from './../actions'

class MerchantBrandActionsCell extends Component {
  deleteFunction = id => {
    this.props.saveRuleAction({ id, isActive: false })
  }

  activateFunction = id => {
    this.props.saveRuleAction({ id, isActive: true })
  }

  getEditModal = () => {
    const { id, merchantId, merchantName, brandId, brandFullPath } = this.props.row
    const { saveRuleAction } = this.props
    return (
      <MerchantBrandRuleModal
        value={{
          id,
          brandId,
          brandFullPath,
          merchantId,
          merchantName
        }}
        onOkHandler={saveRuleAction}
      />
    )
  }

  render() {
    const { id, isActive, merchantIsActive, brandIsActive, ruleIsActive } = this.props.row
    let activationTooltip = ''
    let isActivationBlocked = false

    if (isActive) {
      activationTooltip = 'Delete rule'
    } else if (!merchantIsActive) {
      activationTooltip = "Can't reactivate because related Merchant is inactivate"
      isActivationBlocked = true
    } else if (!brandIsActive) {
      activationTooltip = "Can't reactivate because related Brand is inactivate"
      isActivationBlocked = true
    } else if (!ruleIsActive && merchantIsActive && brandIsActive) {
      activationTooltip = 'Re-Activate'
    }

    return (
      <CommonRuleActionsCell
        editModaTitle="Edit Merchant Brand Rule (MCR)"
        editModalContent={this.getEditModal()}
        id={id}
        isActive={isActive}
        isActivationBlocked={isActivationBlocked}
        activationTooltip={activationTooltip}
        deleteFunction={this.deleteFunction}
        activateFunction={this.activateFunction}
      />
    )
  }
}

MerchantBrandActionsCell.propTypes = {
  saveRuleAction: PropTypes.func.isRequired,
  row: PropTypes.object.isRequired
}

const mapDispatchToProps = {
  saveRuleAction: MerchantBrandRulesActions.save
}

export default connect(null, mapDispatchToProps)(MerchantBrandActionsCell)
