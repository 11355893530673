import React from 'react'
import PropTypes from 'prop-types'
import { Glyphicon, Dropdown, MenuItem } from 'react-bootstrap'
import { useDispatch } from 'react-redux'

import { addCustomModal } from 'actions/modal-actions'
import { DataTableToolbar, TableToolbarButtons } from 'components/DataTable'

import CategoryBrandRuleModal from 'components/Rules/CategoryBrand/Modals/CategoryBrandRuleModal'
import MerchantBrandRuleModal from 'components/Rules/MerchantBrand/Modals/MerchantBrandRuleModal'
import MerchantCategoryRuleModal from 'components/Rules/MerchantCategory/Modals/MerchantCategoryRuleModal'
import BrandSynonymRuleModal from 'components/Rules/BrandSynonym/Modals/BrandSynonymRuleModal'
import MerchantCategoryRegexRuleModal from 'components/Rules/MerchantCategoryRegex/Modals/MerchantCategoryRegexRuleModal'
import MerchantBrandRegexRuleModal from 'components/Rules/MerchantBrandRegex/Modals/MerchantBrandRegexRuleModal'
import GeneralRequestModal from './../GeneralRequestModal'
import BrandRequestModal from './../BrandRequestModal'
import requestsActions from './../actions'

import { REQUESTS_STATUS, REQUESTS_TYPE } from './../constants'

const RequestsToolbar = ({ refreshHandler }) => {
  const dispatch = useDispatch()

  const clearFilters = () => {
    dispatch(requestsActions.clearFilters())
  }

  const createRuleRequest = type => payload => {
    const { request_notes, review_notes, ...rulePayload } = payload
    dispatch(
      requestsActions.save({
        type,
        status: REQUESTS_STATUS.PENDING,
        request_notes,
        payload: rulePayload
      })
    )
  }

  const createGeneralRequest = type => payload => {
    const { request_notes } = payload
    dispatch(
      requestsActions.save({
        type,
        status: REQUESTS_STATUS.PENDING,
        request_notes,
        payload
      })
    )
  }

  const getCreateRequestModal = type => {
    const mapRequestToModal = {
      [REQUESTS_TYPE.CBR]: <CategoryBrandRuleModal onOkHandler={createRuleRequest(REQUESTS_TYPE.CBR)} isRequest />,
      [REQUESTS_TYPE.MBR]: <MerchantBrandRuleModal onOkHandler={createRuleRequest(REQUESTS_TYPE.MBR)} isRequest />,
      [REQUESTS_TYPE.MCR]: <MerchantCategoryRuleModal onOkHandler={createRuleRequest(REQUESTS_TYPE.MCR)} isRequest />,
      [REQUESTS_TYPE.BSR]: <BrandSynonymRuleModal onOkHandler={createRuleRequest(REQUESTS_TYPE.BSR)} isRequest />,
      [REQUESTS_TYPE.MCRR]: (
        <MerchantCategoryRegexRuleModal onOkHandler={createRuleRequest(REQUESTS_TYPE.MCRR)} isRequest />
      ),
      [REQUESTS_TYPE.MBRR]: (
        <MerchantBrandRegexRuleModal onOkHandler={createRuleRequest(REQUESTS_TYPE.MBRR)} isRequest />
      ),
      [REQUESTS_TYPE.BRAND]: <BrandRequestModal onOkHandler={createGeneralRequest(REQUESTS_TYPE.BRAND)} />,
      [REQUESTS_TYPE.GENERAL]: <GeneralRequestModal onOkHandler={createGeneralRequest(REQUESTS_TYPE.GENERAL)} />,
      [REQUESTS_TYPE.GUIDELINE]: <GeneralRequestModal onOkHandler={createGeneralRequest(REQUESTS_TYPE.GUIDELINE)} />
    }

    return mapRequestToModal[type] ?? null
  }

  const addHandler = (name, type) => {
    dispatch(
      addCustomModal({
        modalType: 'successModal',
        id: 'request-create',
        title: `Request new ${name}`,
        size: 'large',
        content: getCreateRequestModal(type)
      })
    )
  }

  return (
    <DataTableToolbar>
      <Dropdown id="request-create" bsStyle="default" title="Create new Request">
        <Dropdown.Toggle>
          <Glyphicon glyph="plus" />
          Create new Request
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <MenuItem
            eventKey="1"
            title="Category Brand Rule"
            onSelect={() => addHandler('Category Brand Rule', REQUESTS_TYPE.CBR)}
          >
            CBR
          </MenuItem>
          <MenuItem eventKey="2" title="Merchant Brand Rule" onSelect={() => addHandler('MBR', REQUESTS_TYPE.MBR)}>
            MBR
          </MenuItem>
          <MenuItem eventKey="3" title="Merchant Category Rule" onSelect={() => addHandler('MCR', REQUESTS_TYPE.MCR)}>
            MCR
          </MenuItem>
          <MenuItem eventKey="4" title="Brand Synonym Rule" onSelect={() => addHandler('BSR', REQUESTS_TYPE.BSR)}>
            BSR
          </MenuItem>
          <MenuItem
            eventKey="5"
            title="Merchant Category Regex Rule"
            onSelect={() => addHandler('MCRR', REQUESTS_TYPE.MCRR)}
          >
            MCRR
          </MenuItem>
          <MenuItem
            eventKey="6"
            title="Merchant Brand Regex Rule"
            onSelect={() => addHandler('MBRR', REQUESTS_TYPE.MBRR)}
          >
            MBRR
          </MenuItem>
          <MenuItem
            eventKey="7"
            title="Brand Requests"
            onSelect={() => addHandler('Brand Requests', REQUESTS_TYPE.BRAND)}
          >
            Brand
          </MenuItem>
          <MenuItem
            eventKey="8"
            title="General Requests"
            onSelect={() => addHandler('General Requests', REQUESTS_TYPE.GENERAL)}
          >
            General
          </MenuItem>
          <MenuItem
            eventKey="9"
            title="Guideline Requests"
            onSelect={() => addHandler('Guideline Requests', REQUESTS_TYPE.GUIDELINE)}
          >
            Guideline
          </MenuItem>
        </Dropdown.Menu>
      </Dropdown>

      <TableToolbarButtons refreshHandler={refreshHandler} clearFiltersHandler={clearFilters} />
    </DataTableToolbar>
  )
}

RequestsToolbar.propTypes = {
  refreshHandler: PropTypes.func.isRequired
}

export default RequestsToolbar
