import React from 'react'

export default function Deliverable(props) {
  return (
    <svg
      className="deliverable-icon"
      width="17px"
      height="19px"
      viewBox="0 0 17 19"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <defs />
      <g id="Components" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g id="Icons" transform="translate(-96.000000, -258.000000)">
          <g id="ic-reports" transform="translate(90.000000, 253.000000)">
            <g id="Reports">
              <rect id="spacing" x={0} y={0} width={30} height={30} />
              <g id="report" transform="translate(6.000000, 5.000000)" fill="currentColor">
                <path
                  d="M1,1 L1,15 L13,15 L13,1 L1,1 Z M0,0 L14,0 L14,16 L0,16 L0,0 Z"
                  id="Rectangle"
                  fillRule="nonzero"
                />
                <rect
                  id="Rectangle-2"
                  transform="translate(4.000000, 8.000000) rotate(-90.000000) translate(-4.000000, -8.000000) "
                  x={3}
                  y={7}
                  width={2}
                  height={2}
                />
                <rect
                  id="Rectangle-2-Copy"
                  transform="translate(7.000000, 7.000000) rotate(-90.000000) translate(-7.000000, -7.000000) "
                  x={5}
                  y={6}
                  width={4}
                  height={2}
                />
                <rect
                  id="Rectangle-2-Copy-3"
                  transform="translate(7.000000, 10.500000) rotate(-360.000000) translate(-7.000000, -10.500000) "
                  x={3}
                  y={10}
                  width={8}
                  height={1}
                />
                <rect
                  id="Rectangle-2-Copy-4"
                  transform="translate(7.000000, 12.500000) rotate(-360.000000) translate(-7.000000, -12.500000) "
                  x={3}
                  y={12}
                  width={8}
                  height={1}
                />
                <rect
                  id="Rectangle-2-Copy-2"
                  transform="translate(10.000000, 6.000000) rotate(-90.000000) translate(-10.000000, -6.000000) "
                  x={7}
                  y={5}
                  width={6}
                  height={2}
                />
              </g>
              <g
                id="report-2"
                transform="translate(16.500000, 16.000000) rotate(-180.000000) translate(-16.500000, -16.000000) translate(10.000000, 8.000000)"
                fill="currentColor"
              >
                <rect
                  id="Rectangle-2-Copy-3"
                  transform="translate(6.000000, 0.500000) rotate(-360.000000) translate(-6.000000, -0.500000) "
                  x="2.76101317e-30"
                  y="-2.10942375e-15"
                  width={12}
                  height={1}
                />
                <rect
                  id="Rectangle-2-Copy-5"
                  transform="translate(1.000000, 15.500000) rotate(-360.000000) translate(-1.000000, -15.500000) "
                  x="7.10542736e-15"
                  y={15}
                  width={2}
                  height={1}
                />
                <rect
                  id="Rectangle-2-Copy-3"
                  transform="translate(0.500000, 7.500000) rotate(-90.000000) translate(-0.500000, -7.500000) "
                  x={-7}
                  y={7}
                  width={15}
                  height={1}
                />
                <rect
                  id="Rectangle-2-Copy-3"
                  transform="translate(12.500000, 1.000000) rotate(-90.000000) translate(-12.500000, -1.000000) "
                  x="11.5"
                  y="0.5"
                  width={2}
                  height={1}
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
