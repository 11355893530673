import React from 'react'
import PropTypes from 'prop-types'

import { debounce } from 'lodash'
import ChipSelector from '../ChipSelector'
import './suggestion.scss'

const SEARCH_DEBOUNCE = 200
const SEARCH_LIMIT = 500

const Suggestion = ({
  selectedId = null,
  selectedLabel = '',
  searchSuggestions = [],
  labelKey,
  placeholder = '',
  readOnly,
  changeCallback = () => {},
  onMenuShow = () => {},
  fetchAction,
  isActive,
  additionalSearchParams = {},
  minLength,
  isLoading
}) => {
  const debouncedSearchFunction = debounce(searchText => {
    let searchParams = {
      [labelKey]: searchText,
      limit: SEARCH_LIMIT
    }

    if (isActive !== undefined) {
      searchParams.isActive = isActive
    }

    if (additionalSearchParams) {
      searchParams = { ...searchParams, ...additionalSearchParams }
    }

    fetchAction(searchParams)
  }, SEARCH_DEBOUNCE)

  const value = !selectedId ? null : { id: selectedId, [labelKey]: selectedLabel }

  return (
    <ChipSelector
      labelKey={labelKey}
      searchSuggestions={searchSuggestions}
      selectedValue={value}
      onSelectionChange={changeCallback}
      onSearchChange={debouncedSearchFunction}
      onMenuShow={onMenuShow}
      placeholder={placeholder}
      readOnly={readOnly}
      minLength={minLength}
      isLoading={isLoading}
    />
  )
}

Suggestion.propTypes = {
  selectedId: PropTypes.number,
  selectedLabel: PropTypes.string,
  placeholder: PropTypes.string,
  isActive: PropTypes.bool,
  additionalSearchParams: PropTypes.object,
  searchSuggestions: PropTypes.array,
  fetchAction: PropTypes.func.isRequired,
  labelKey: PropTypes.string.isRequired,
  changeCallback: PropTypes.func,
  onMenuShow: PropTypes.func,
  readOnly: PropTypes.bool,
  minLength: PropTypes.number,
  isLoading: PropTypes.bool
}

export default Suggestion
