import React from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { TableHeaderColumn } from 'react-bootstrap-table'

import {
  createContractsLinkUrl,
  createDeliveriesLinkUrl,
  createFindDeliveryLinkUrl,
  createDeliverablesLinkUrl
} from '../../../utils/delivery-center'

import { deliveryEvents as EVENTS } from '../../../constants/constants'

const DELIVERY_LOGS_FIELDS = [
  'id',
  'event',
  'createdAt',
  'createdByUser',
  'clientName',
  'contractName',
  'deliverableName',
  'deliveryName'
]

const renderCreatedAt = cell => (
  <div>
    <div>{moment(cell).calendar()}</div>
    <div className="date-time" title={moment(cell).format('DD MMM, hh:mm a (Z)')}>
      {moment(cell).format('ddd MMM DD, hh:mm a (Z)')}
    </div>
  </div>
)

const renderUser = cell => (
  <div>
    <div className="user-name">{cell.name || ''}</div>
    <div className="user-email">{cell.email || ''}</div>
  </div>
)

const renderClient = (cell, row) => {
  const client = {
    id: row.clientId,
    name: row.clientName
  }

  return cell ? <Link to={createContractsLinkUrl(client)}>{cell}</Link> : row.clientId ? `ID: ${row.clientId}` : ''
}

const renderContract = (cell, row) => {
  const client = {
    id: row.clientId,
    name: row.clientName
  }
  const contract = {
    id: row.contractId,
    name: row.contractName
  }

  return cell ? (
    <Link to={createDeliverablesLinkUrl(client, contract)}>{cell}</Link>
  ) : row.contractId ? (
    `ID: ${row.contractId}`
  ) : (
    ''
  )
}

const renderDeliverable = (cell, row) => {
  const deliverable = {
    id: row.deliverableId,
    name: row.deliverableName
  }
  return cell ? (
    <Link to={createDeliveriesLinkUrl(deliverable)}>{cell}</Link>
  ) : row.deliverableId ? (
    `ID: ${row.deliverableId}`
  ) : (
    ''
  )
}

const renderDelivery = (cell, row) => {
  const { deliveryId, deliverableId } = row

  return cell ? (
    <Link to={createFindDeliveryLinkUrl(deliveryId, deliverableId)}>{cell}</Link>
  ) : deliveryId ? (
    `ID: ${deliveryId}`
  ) : (
    ''
  )
}

export const buildColumns = () => {
  const getColumn = column => {
    const defaultColumnConfig = {
      label: column,
      field: column,
      hasSort: false,
      dataAlign: 'left',
      editable: false
    }
    let config = {}

    switch (column) {
      case 'id':
        config = {
          label: 'ID',
          width: '50px',
          dataAlign: 'center'
        }
        break

      case 'event':
        config = {
          label: 'Event',
          width: '250px'
        }
        break

      case 'createdAt':
        config = {
          width: '225px',
          label: 'Created At',
          columnClassName: 'createdAt-cell',
          dataFormat: renderCreatedAt
        }
        break

      case 'createdByUser':
        config = {
          label: 'User',
          dataFormat: renderUser
        }
        break

      case 'clientName':
        config = {
          label: 'Client',
          dataFormat: renderClient
        }
        break

      case 'contractName':
        config = {
          label: 'Contract',
          dataFormat: renderContract
        }
        break

      case 'deliverableName':
        config = {
          label: 'Deliverable',
          dataFormat: renderDeliverable
        }
        break

      case 'deliveryName':
        config = {
          label: 'Delivery',
          dataFormat: renderDelivery
        }
        break

      default:
        break
    }

    return {
      ...defaultColumnConfig,
      ...config
    }
  }

  const columns = DELIVERY_LOGS_FIELDS.map(field => getColumn(field))

  const headerColumns = columns.map((col, index) => {
    return (
      <TableHeaderColumn
        ref={col.field}
        width={col.width}
        key={`col-${index}`}
        dataField={col.field}
        dataSort={col.hasSort}
        sortFunc={col.sortFunc}
        editable={col.editable}
        dataAlign={col.dataAlign}
        isKey={col.field === 'id'}
        dataFormat={col.dataFormat}
        columnClassName={col.columnClassName}
        editColumnClassName={col.editColumnClassName}
      >
        {col.label}
      </TableHeaderColumn>
    )
  })
  return headerColumns
}

export const trClassFormat = row => {
  switch (row.event) {
    case EVENTS.CLIENT_CREATED:
    case EVENTS.CONTRACT_CREATED:
    case EVENTS.DELIVERY_CREATED:
    case EVENTS.CLEANUP_JOB_CREATED:
    case EVENTS.DELIVERABLE_CREATED:
    case EVENTS.DELIVERY_COMPLETED:
      return 'success'

    case EVENTS.CLIENT_ACTIVATED:
    case EVENTS.CONTRACT_ACTIVATED:
    case EVENTS.DELIVERABLE_ACTIVATED:
    case EVENTS.AD_HOC_DELIVERY_CREATED:
      return 'info'

    case EVENTS.CLIENT_DEACTIVATED:
    case EVENTS.CONTRACT_DEACTIVATED:
    case EVENTS.DELIVERABLE_DEACTIVATED:
      return 'warning'

    case EVENTS.DELIVERY_CANCELLED:
      return 'danger'

    default:
      if (row.event.endsWith('UPDATED')) {
        return 'info'
      }
      return ''
  }
}
