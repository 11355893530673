import { createSelector } from 'reselect'
import { keyBy } from 'lodash'

const groupsSelector = state => (state.session && state.session.groups) || []
const groupSuggestionSelector = state => (state.session.userEdit && state.session.userEdit.groupSuggestions) || []

export const getGroups = createSelector(groupsSelector, groups => {
  return groups || null
})

export const getGroupsById = createSelector(groupsSelector, groups => keyBy(groups, 'id'))

export const getEligibleGroups = createSelector(groupSuggestionSelector, groups => {
  return groups.reduce(function(acc, currentValue) {
    if (currentValue.feedsEnabled) {
      acc.push(currentValue)
    }
    return acc
  }, [])
})
