import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { isEmpty, cloneDeep } from 'lodash'

import DataTable from './../DataTable'
import { TableHeaderColumn } from 'react-bootstrap-table'
import moment from 'moment'

import { DATA_TABLE, DATE_ONLY_API_FORMAT } from './constants'

/**
 * DataTable with basic filter functionality
 */
export default class DataTableFiltered extends Component {
  constructor(props) {
    super(props)

    this.disableOnFilterChange = false
    this.headersRefs = {}

    this.dataTable = React.createRef()
    this.headers = this.generateTableHeaders(this.props.columns)
  }

  componentDidMount() {
    this.setFiltersInTableHeaders()
    this.setPlaceholderInDateFilters()

    this.fetch()
  }

  componentDidUpdate(prevProps) {
    if (!isEmpty(prevProps.filters) && isEmpty(this.props.filters)) {
      this.clearAllFilters(prevProps.filters)
    }
  }

  // HEADERS && FILTERS
  clearAllFilters(filters) {
    this.disableOnFilterChange = true
    this.cleanFitersInTableHeaders(filters)
    this.disableOnFilterChange = false
    this.fetch()
  }

  cleanFitersInTableHeaders(filters) {
    Object.keys(filters).map(columnId => this.headersRefs[columnId].current.cleanFiltered())
  }

  generateTableHeaders(columns) {
    const TABLE_ID = 'id'
    const headerColumns = columns.map((col, index) => {
      const { field, label, title, ...otherProps } = col
      this.headersRefs[field] = React.createRef()
      return (
        <TableHeaderColumn
          isKey={field === TABLE_ID}
          {...otherProps}
          ref={this.headersRefs[field]}
          dataField={field}
          key={`data-table-col-${index}`}
          tdStyle={{ whiteSpace: 'normal' }}
          headerText={title || label}
        >
          <span className={'header-text' + (col.dataSort ? ' header-with-sort' : '')}>{label}</span>
        </TableHeaderColumn>
      )
    })

    return headerColumns
  }

  setFiltersInTableHeaders() {
    this.disableOnFilterChange = true
    for (const [columnId, filter] of Object.entries(this.props.filters)) {
      if (this.headersRefs[columnId]) {
        this.headersRefs[columnId].current.customFilter?.applyFilter(filter.value) ||
          this.headersRefs[columnId].current.applyFilter(filter.value)
      }
    }
    this.disableOnFilterChange = false
  }

  setPlaceholderInDateFilters() {
    this.props.columns.forEach(column => {
      if (column.filter && column.filter.type === 'DateFilter') {
        try {
          this.headersRefs[column.field].current.dateFilter.inputDate.placeholder = DATA_TABLE.DATE_ONLY_FORMAT
        } catch (e) {
          // Do nothing. This isn't so important
        }
      }
    })
  }
  // /HEADERS && FILTERS

  // FETCH
  fetch = payload => {
    const {
      page = this.props.page,
      pageSize = this.props.pageSize,
      sortBy = this.props.sortBy,
      sortOrder = this.props.sortOrder,
      filters = this.props.filters
    } = payload || {}

    this.props.fetchRowsAction({
      page,
      pageSize,
      sortBy,
      sortOrder,
      filters
    })
  }

  // ***** HANDLERS *******
  onSortChange = (sortBy, sortOrder) => {
    this.fetch({
      sortBy,
      sortOrder,
      page: 1
    })
  }

  getColumnOptionsByName(columnId) {
    return this.props.columns.filter(column => column.field === columnId)[0]
  }

  convertFilterValue(columnId, filterValueOriginal) {
    const result = {}

    const columnOptions = this.getColumnOptionsByName(columnId)

    // Get filter Value
    let filterValue = filterValueOriginal
    // for some DateFilter we need only YYYY-MM-DD info without timezone shifting
    if (columnOptions.filter.type === 'DateFilter' && columnOptions.filter.dateOnly) {
      // this is a light way to skip timezone info because different browsers provide time in inconsistent formats.
      filterValue = cloneDeep(filterValue) // clone because this object is from redux store and it's read only
      filterValue.date = moment.utc(moment(filterValue.date).format(DATE_ONLY_API_FORMAT)).toDate()
    }
    result.value = filterValue

    // optional set filter comparator
    if (columnOptions.filter.comparator) {
      result.comparator = columnOptions.filter.comparator
    }

    return result
  }

  convertFiltersToAPIFormat(tableFilters) {
    const filters = {}
    for (const [columnId, filterValue] of Object.entries(tableFilters)) {
      filters[columnId] = this.convertFilterValue(columnId, filterValue.value)
    }
    return filters
  }

  onFilterChange = filtersObj => {
    if (this.disableOnFilterChange) {
      return false
    }

    this.fetch({
      filters: this.convertFiltersToAPIFormat(filtersObj),
      page: 1
    })
  }

  onPageChange = page => {
    this.fetch({ page })
  }
  // ***** /HANDLERS *******

  render() {
    const {
      data,
      pageSize,
      totalCount,
      page,
      sortBy,
      sortOrder,
      trClassName,
      tableClassName,
      keyBoardNav,
      entityName,
      id,
      columnsConfig
    } = this.props

    const maxPages = Math.ceil(totalCount / pageSize) || 1

    return (
      <DataTable
        id={id}
        data={data}
        columns={this.headers}
        columnsConfig={columnsConfig}
        page={page}
        maxPages={maxPages}
        sortName={sortBy}
        sortOrder={sortOrder}
        tableClassName={tableClassName + ' sb-table-compact'}
        trClassName={trClassName}
        onPageChange={this.onPageChange}
        onSortChange={this.onSortChange}
        onFilterChange={this.onFilterChange}
        keyBoardNav={keyBoardNav}
        ref={this.dataTable}
        totalCount={totalCount}
        entityName={entityName}
      />
    )
  }
}

DataTableFiltered.defaultProps = {
  keyBoardNav: false
}

DataTableFiltered.propTypes = {
  id: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  columnsConfig: PropTypes.object,
  page: PropTypes.number,
  pageSize: PropTypes.number,
  sortBy: PropTypes.string,
  sortOrder: PropTypes.string,
  filters: PropTypes.object,
  fetchRowsAction: PropTypes.func.isRequired,
  totalCount: PropTypes.number.isRequired,
  tableClassName: PropTypes.string,
  trClassName: PropTypes.string,
  keyBoardNav: PropTypes.bool,
  entityName: PropTypes.string
}
