/**
 * Highlights the part of the option that matches the input value.
 * @param {string} optionValue - The value of the option.
 * @param {string} inputValue - The value of the input.
 * @returns {Object} - Object containing `beforeMatch`, `match`, and `afterMatch`.
 */
export const highlightMatch = (optionValue, inputValue) => {
  const lowerOption = optionValue.toLowerCase()
  const lowerInput = inputValue.toLowerCase()

  const matchIndex = lowerOption.indexOf(lowerInput)

  if (matchIndex === -1 || inputValue === '') {
    return { beforeMatch: optionValue, match: '', afterMatch: '' }
  }

  const beforeMatch = optionValue.slice(0, matchIndex)
  const match = optionValue.slice(matchIndex, matchIndex + inputValue.length)
  const afterMatch = optionValue.slice(matchIndex + inputValue.length)

  return { beforeMatch, match, afterMatch }
}
