import { useDispatch } from 'react-redux'
import { clearModals } from 'actions/modal-actions'
import { pick } from 'lodash'

export default function useFormButtons({
  onOkHandler,
  onRejectHandler,
  values,
  sentFieldsForRules,
  sentFieldsForRequests,
  isRequest
}) {
  const dispatch = useDispatch()

  const getSentFields = (values, sentFieldsForRules = [], sentFieldsForRequests = [], isRequest) => {
    let payload = pick(values, sentFieldsForRules)
    if (values.id) {
      payload.id = values.id
    }
    if (payload.synonym) {
      payload.synonym = payload.synonym.trim()
    }
    if (isRequest) {
      payload = {
        ...pick(values, sentFieldsForRequests),
        ...payload
      }
    }
    return payload
  }

  const closeModal = () => {
    dispatch(clearModals())
  }

  const handleCancelButton = closeModal

  const handleRejectButton = () => {
    const { id, review_notes, request_notes } = getSentFields(
      values,
      sentFieldsForRules,
      sentFieldsForRequests,
      isRequest
    )
    onRejectHandler(id, { review_notes, request_notes })
    closeModal()
  }

  const handleOkButton = () => {
    onOkHandler(getSentFields(values, sentFieldsForRules, sentFieldsForRequests, isRequest))
    closeModal()
  }

  return {
    handleOkButton,
    handleRejectButton,
    handleCancelButton
  }
}
