import { useCallback, useMemo, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { debounce, intersection } from 'lodash'

import { useFormValues } from '../../../FormInputComponents/InputField'
import { DEBOUNCE_ONCHANGE_UI_ACTION_TIMEOUT } from '../../constants'

import { MerchantCategoryRegexRulesActions, RuleModalActions } from '../../actions'

/** This hook get rule from API & help update MCRR request in state without mutation
 */
export default function useFormMerchantCategoryRegexFilds(initValues, mode) {
  const dispatch = useDispatch()

  const { values, isFormChanged, handleFieldChange, updateValue, lastChangedFields } = useFormValues(initValues)

  const checkRule = useCallback(
    ({ categoryId, merchantId, includeParam, ruleType }) =>
      dispatch(
        MerchantCategoryRegexRulesActions.fetchRuleByMainFields({
          merchantId,
          categoryId,
          includeParam,
          ruleType
        })
      ),
    [dispatch]
  )

  const checkRuleDebounce = useMemo(
    () =>
      debounce(
        ({ categoryId, merchantId, includeParam, ruleType }) =>
          checkRule({ merchantId, categoryId, includeParam, ruleType }),
        DEBOUNCE_ONCHANGE_UI_ACTION_TIMEOUT
      ),
    [checkRule]
  )

  // Check rule after first time modal opening
  useEffect(() => {
    const { categoryId, merchantId, includeParam, ruleType } = values
    const isAllFieldsFilled = merchantId && categoryId && includeParam && ruleType
    if (isAllFieldsFilled && !mode.isRequestView) {
      checkRule({ merchantId, categoryId, includeParam, ruleType })
    }
    // Not necessary to check dependencies
    // We run it only once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Check rule after fields changes
  const { categoryId, merchantId, includeParam, ruleType } = values
  useEffect(() => {
    const isAllFieldsFilled = categoryId && merchantId && includeParam && ruleType
    const isChangeFilesForCheckRule = intersection(lastChangedFields, ['merchantId', 'categoryId']).length > 0
    const isChangeFilesForDebounceCheckRule = intersection(lastChangedFields, ['includeParam', 'ruleType']).length > 0

    // TODO refactor this
    if (isAllFieldsFilled && isChangeFilesForCheckRule) {
      checkRule({ merchantId, categoryId, includeParam, ruleType })
    } else if (isAllFieldsFilled && isChangeFilesForDebounceCheckRule) {
      dispatch(RuleModalActions.startRuleLoading()) // immediately show Rule loading
      checkRuleDebounce({ merchantId, categoryId, includeParam, ruleType })
    }
  }, [categoryId, merchantId, includeParam, ruleType, checkRule, checkRuleDebounce, lastChangedFields, dispatch])

  const handleMerchantChange = useCallback(
    merchant => {
      const merchantId = merchant?.id
      const merchantName = merchant?.merchantName

      updateValue({ merchantId, merchantName })
    },
    [updateValue]
  )

  const handleCategoryChange = useCallback(
    category => {
      // TODO what about some general function for this?
      const categoryId = category?.id
      const categoryFullPath = category?.fullPath
      updateValue({ categoryId, categoryFullPath })
    },
    [updateValue]
  )

  return { values, isFormChanged, handleFieldChange, handleMerchantChange, handleCategoryChange }
}
