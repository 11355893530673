import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'react-bootstrap'
import CopyToClipboard from 'react-copy-to-clipboard'

const CopyToClipboardModal = props => {
  const { note, text, onCopy } = props

  return (
    <>
      <p>{note}</p>
      <hr />

      <div style={{ overflow: 'scroll', display: 'block', height: '500px' }}>
        <p style={{ whiteSpace: 'pre', textAlign: 'left' }}>{text}</p>
      </div>

      <br />

      <CopyToClipboard text={text} onCopy={onCopy}>
        <Button variant="secondary">Copy to Clipboard</Button>
      </CopyToClipboard>
    </>
  )
}

CopyToClipboardModal.defaultProps = {
  note: '',
  text: '',
  onCopy: null
}

CopyToClipboardModal.propTypes = {
  note: PropTypes.string,
  text: PropTypes.string,
  onCopy: PropTypes.func
}

export default CopyToClipboardModal
