import React from 'react'
import PropTypes from 'prop-types'
import { round } from 'lodash'

const ETLStatsTotals = props => {
  const { taskCount, failedTaskCount, pendingTaskCount, completedTaskCount } = props

  const failureRate = round((failedTaskCount / taskCount) * 100, 2)

  return (
    <div className="etl-stats-totals">
      <div>
        Total <span>{taskCount}</span>
      </div>

      <div>
        Completed <span id="stats-task-completed">{completedTaskCount}</span>
      </div>

      <div>
        Pending <span>{pendingTaskCount}</span>
      </div>

      <div>
        Failed <span id="stats-task-failed">{failedTaskCount}</span>
      </div>

      <div>
        Fail Rate <span>{isNaN(failureRate) ? 0 : failureRate}%</span>
      </div>
    </div>
  )
}

ETLStatsTotals.defaultProps = {
  taskCount: 1,
  failedTaskCount: 1,
  pendingTaskCount: 1,
  completedTaskCount: 1
}

ETLStatsTotals.propTypes = {
  taskCount: PropTypes.number,
  failedTaskCount: PropTypes.number,
  pendingTaskCount: PropTypes.number,
  completedTaskCount: PropTypes.number
}

export default ETLStatsTotals
