import commonRtkApi from 'commonRtkApi.js'
import { sortModes } from 'constants/constants'

const supraTaxonomyAPI = commonRtkApi.injectEndpoints({
  tagTypes: ['supraTaxonomy'],
  endpoints: builder => ({
    getSupraTaxonomyRowById: builder.query({
      query: ({ domainId }) => {
        return { url: `/supra-taxonomy/whitelist-domain/${domainId}`, method: 'GET' }
      },
      providesTags: ['SupraOrchestratorWhitelisted']
    }),
    getSupraTaxonomyRows: builder.query({
      query: ({ paginationModel, sortModel, filterModel }) => {
        const sortModelEntry = sortModel[0]
        let sortBy, sortDesc
        const filters = filterModel.items
        if (sortModelEntry) {
          sortBy = sortModelEntry.field
          sortDesc = sortModelEntry.sort === sortModes.DESCENDING
        }
        const data = {
          ...paginationModel,
          filters,
          sortBy,
          sortDesc
        }
        return { url: `/supra-taxonomy`, method: 'POST', data }
      },
      providesTags: result =>
        result.rows
          ? [
              ...result.rows?.map(({ domain_id }) => ({ type: 'supraTaxonomy', id: domain_id })),
              { type: 'supraTaxonomy', id: 'LIST' }
            ]
          : [{ type: 'supraTaxonomy', domain_id: 'LIST' }]
    }),
    // I held on MUI table filtering style in whitelist request
    getSupraDomainSample: builder.query({
      query: ({ inputValue }) => {
        const data = {
          page: 0,
          pageSize: 100,
          filters: [
            {
              field: 'domain',
              operator: 'contains',
              value: inputValue
            }
          ]
        }
        return { url: `/supra-orchestration/domains-list`, method: 'POST', data }
      },
      providesTags: result =>
        result.rows
          ? [
              ...result.rows?.map(({ domain_id }) => ({ type: 'supraTaxonomy', id: domain_id })),
              { type: 'supraTaxonomy', id: 'LIST' }
            ]
          : [{ type: 'supraTaxonomy', domain_id: 'LIST' }]
    }),
    editSupraTaxonomy: builder.mutation({
      query: ({ domain_id, domain, nonreceipt_whitelisted }) => {
        const data = {
          domain_id,
          domain,
          nonreceipt_whitelisted
        }
        return { url: `/supra-taxonomy/edit`, method: 'PUT', data }
      },
      invalidatesTags: (result, error, arg) => [{ type: 'supraTaxonomy', id: arg.domain_id }]
      //  --- tried to update only one item from the table during the edit item, but it not work now
      // onQueryStarted(arg, { queryFulfilled, dispatch }) {
      //   queryFulfilled.then(({ data: item }) => {
      //     dispatch(
      //       supraTaxonomyAPI.util.updateQueryData('getSupraTaxonomyRows', undefined, draft => {
      //         const index = draft.findIndex(item => item.domain_id === arg.domain_id)
      //         if (index !== -1) {
      //           // Update the item in the cache
      //           draft[index] = { ...draft[index], ...arg }
      //         }
      //       })
      //     )
      //   })
      // }
    })
  })
})

export const {
  useLazyGetSupraTaxonomyRowByIdQuery,
  useGetSupraTaxonomyRowsQuery,
  useGetSupraDomainSampleQuery,
  useEditSupraTaxonomyMutation
} = supraTaxonomyAPI
