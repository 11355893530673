import React from 'react'
import { Controller } from 'react-hook-form'
import { FormControl } from 'react-bootstrap'
import PropTypes from 'prop-types'
import InputField from 'components/FormInputComponents/InputField'

const FilterKeywordsInput = ({ control, errors, info }) => {
  return (
    <InputField
      label="Filter Keywords"
      info={info}
      className="text-start"
      labelSize={4}
      validationMessage={errors.filterKeywords?.message}
    >
      <Controller
        required
        name="filterKeywords"
        control={control}
        render={({ field }) => <FormControl type="textarea" componentClass="textarea" rows={5} {...field} />}
      />
    </InputField>
  )
}

FilterKeywordsInput.propTypes = {
  control: PropTypes.any,
  info: PropTypes.string,
  errors: PropTypes.object
}

export default FilterKeywordsInput
