import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'

import Checkbox from '../CheckBox'
import Input from '../Input'
import SearchIcon from '../Icon/SearchIcon'
import Loader from '../Loader'
import { HotKeys } from 'react-hotkeys'
import Highlighter from 'react-highlight-words'

import { createContractsLinkUrl, createDeliverablesLinkUrl, createDeliveriesLinkUrl } from '../../utils/delivery-center'

import cx from 'classnames'
import { debounce, get } from 'lodash'

const keyMap = {
  UP: ['up'],
  DOWN: ['down'],
  SELECT: ['enter']
}

const DEBOUNCE_MS = 350

class CCDSearch extends Component {
  constructor(props) {
    super(props)
    this.state = {
      searchText: '',
      hideDropdown: true,
      highlightedKey: ''
    }
    this.keyHandlers = {
      UP: this.selectPreviousOption,
      DOWN: this.selectNextOption,
      SELECT: this.handleOptionSelect
    }
  }

  componentDidMount() {
    window.addEventListener('click', this.handleOuterClick, true)
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.handleOuterClick, true)
  }

  selectPreviousOption = () => {
    this.selectOption(-1)
  }

  selectNextOption = () => {
    this.selectOption(1)
  }

  selectOption = (offset = 1) => {
    if (!this.searchWrapper) {
      return // nothing rendered yet
    }
    const { highlightedKey } = this.state
    const listOptions = [...this.searchWrapper.getElementsByTagName('li')]
    if (!listOptions) {
      return // no options to select
    }
    const keys = listOptions.map(el => el.id)
    let nextIndex = 0 // first option by default
    if (highlightedKey) {
      const currentIndex = keys.indexOf(highlightedKey)
      nextIndex = (currentIndex + offset + keys.length) % keys.length // loop back after last option
    }
    const elementToFocus = listOptions[nextIndex]
    if (elementToFocus) {
      elementToFocus.focus() // focus on element
      if (elementToFocus.scrollIntoView) {
        // not supported in dumb browsers
        elementToFocus.scrollIntoView({
          behavior: 'auto',
          block: 'nearest'
        })
      }
      this.setState({ highlightedKey: elementToFocus.id }) // update key in state
    }
  }

  clearSearch = () => {
    this.handleTextChange('')
    this.input.focus()
  }

  handleSearch = debounce(
    (value, options) =>
      this.props.onSearchChange({
        value,
        ...options
      }),
    DEBOUNCE_MS
  )

  handleTextChange = value => {
    this.setState({ searchText: value })
    this.handleSearch(value)
    this.showDropdown()
  }

  showDropdown = () => {
    if (this.state.hideDropdown) {
      this.setState({ hideDropdown: false })
      this.input.focus()
    }
  }

  hideDropdown = () => {
    if (!this.state.hideDropdown) {
      this.setState({ hideDropdown: true })
    }
  }

  handleOuterClick = event => {
    if (!this.state.hideDropdown && this.searchWrapper && !this.searchWrapper.contains(event.target)) {
      this.hideDropdown()
    }
  }

  handleOptionSelect = event => {
    let optionKey
    let option

    // find option to process
    if (get(event, ['currentTarget', 'tagName']) === 'LI') {
      // event from LI, currrentTarget is the option
      optionKey = event.currentTarget.id
      option = event.currentTarget
    } else {
      // event from keypress, process highlighted option
      optionKey = this.state.highlightedKey
      option = document.getElementById(optionKey)
    }

    // process option based on type
    if (option && option.getAttribute('route')) {
      // route attribute found, use it
      this.props.history.push(option.getAttribute('route'))
      this.setState({ searchText: '' })
      this.hideDropdown()
    } else if (optionKey.startsWith('showMore')) {
      // show more option selected
      this.setState({ highlightedKey: optionKey })
      this.showMore(optionKey.split('-')[1])
    }
  }

  showMore = searchType => {
    const { searchResults, onSearchMore } = this.props
    const { searchText } = this.state
    const results = searchResults[`${searchType}s`]
    onSearchMore({
      value: searchText,
      type: searchType,
      offset: results.length
    })
    setTimeout(() => {
      this.selectPreviousOption()
    }, 0)
  }

  createDeliverablesLinkUrl = contract => {
    return createDeliverablesLinkUrl(contract.Client, contract)
  }

  renderResultGroup = (type, items, totalCount) => {
    const { highlightedKey, searchText } = this.state
    const showMoreKey = `showMore-${type}`
    const getLinkFns = {
      client: createContractsLinkUrl,
      contract: this.createDeliverablesLinkUrl,
      deliverable: createDeliveriesLinkUrl
    }
    const getLink = getLinkFns[type]
    return (
      items.length > 0 && (
        <div className="result-group">
          <div className="result-group-name">{type}s</div>
          {items.map(item => {
            const { name, id, isActive } = item
            const key = `${type}-${id}-${name}`
            return (
              <li
                id={key}
                key={key}
                onClick={this.handleOptionSelect}
                className={cx({
                  selected: highlightedKey === key,
                  inactive: !isActive
                })}
              >
                <a href={getLink(item)}>
                  <Highlighter
                    searchWords={[searchText]}
                    textToHighlight={name}
                    highlightClassName="highlight-text"
                    autoEscape
                  />
                </a>
              </li>
            )
          })}
          {items.length < totalCount && (
            <li
              id={showMoreKey}
              className={cx('btn_link', {
                selected: highlightedKey === showMoreKey
              })}
              key={showMoreKey}
              onClick={this.handleOptionSelect}
            >
              Show more
            </li>
          )}
        </div>
      )
    )
  }

  render() {
    const { hideDropdown, searchText } = this.state
    const {
      searchResults: {
        loading,
        clients = [],
        contracts = [],
        deliverables = [],
        clientsCount = 0,
        contractsCount = 0,
        deliverablesCount = 0
      }
    } = this.props
    const noResultsFound = clients.length + contracts.length + deliverables.length === 0
    return (
      <div className={cx('pull-right alignItemsCenter')}>
        <Checkbox
          checked={this.props.showInactive}
          label="Show Inactive"
          valueDidChange={this.props.onToggleShowInactive}
        />
        <HotKeys keyMap={keyMap} handlers={this.keyHandlers}>
          <div
            onClick={this.showDropdown}
            ref={el => {
              window.aa = el
              this.searchWrapper = el
            }}
          >
            <div
              className={cx({
                searchBoxOpen: !hideDropdown,
                searchBoxClosed: hideDropdown
              })}
            >
              <Input
                ref={el => {
                  this.input = el
                }}
                clearButton
                placeholder="Search Clients, Contracts and Deliverables"
                className={cx('search-input', 'input', 'flex1', 'overrideSearchBorder')}
                value={searchText}
                iconName={<SearchIcon width={20} height={20} />}
                didClickClearButton={this.clearSearch}
                textDidChange={this.handleTextChange}
              />
            </div>
            <div className="search-dropdown-popover searchDropDown">
              {loading && <Loader overlap />}
              <ul className="searchDropdownList">
                {noResultsFound && <li>No matches found</li>}
                {this.renderResultGroup('client', clients, clientsCount)}
                {this.renderResultGroup('contract', contracts, contractsCount)}
                {this.renderResultGroup('deliverable', deliverables, deliverablesCount)}
              </ul>
            </div>
          </div>
        </HotKeys>
      </div>
    )
  }
}

CCDSearch.propTypes = {
  searchResults: PropTypes.object,
  onSearchChange: PropTypes.func,
  onSearchMore: PropTypes.func,
  history: PropTypes.object,
  showInactive: PropTypes.bool,
  onToggleShowInactive: PropTypes.func
}

export default withRouter(CCDSearch)
