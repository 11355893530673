import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { fetchBrandSuggestionsAction } from './actions'
import Suggestion from './Suggestion'

class BrandSuggestion extends Component {
  render() {
    const {
      selectedId,
      selectedLabel,
      brandSuggestions,
      fetchBrandSuggestionsAction,
      changeCallback,
      readOnly
    } = this.props
    return (
      <Suggestion
        labelKey={'fullPath'}
        selectedId={selectedId}
        selectedLabel={selectedLabel}
        searchSuggestions={brandSuggestions}
        fetchAction={fetchBrandSuggestionsAction}
        changeCallback={changeCallback}
        placeholder={`Search for a Brand`}
        readOnly={readOnly}
        isActive
      />
    )
  }
}

BrandSuggestion.propTypes = {
  selectedId: PropTypes.number,
  selectedLabel: PropTypes.string,
  changeCallback: PropTypes.func,

  brandSuggestions: PropTypes.array.isRequired,
  fetchBrandSuggestionsAction: PropTypes.func.isRequired,

  readOnly: PropTypes.bool
}

BrandSuggestion.defaultProps = {
  selectedId: null,
  selectedLabel: '',
  brandSuggestions: [],
  changeCallback: () => {},
  readOnly: false
}

const mapStateToProps = ({ suggestions }) => ({
  brandSuggestions: suggestions.brands
})

const mapDispatchToProps = {
  fetchBrandSuggestionsAction
}

export default connect(mapStateToProps, mapDispatchToProps)(BrandSuggestion)
