import React, { Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import { OverlayTrigger, Tooltip, Glyphicon, Button } from 'react-bootstrap'
import PropTypes from 'prop-types'
import { supraQCToolDefaults, boomerBaseURL, TASK_STATUS } from 'constants/constants'
import Loader from '../Loader'
import { onAddToDictModalOpen } from '../../actions/supra-qc-tool-actions'
import { openRegexUtilityModal, openStatsModal } from './slices'
import { refreshSamplingQCTable } from './actions'
import RegexUtilityModal from './Modals/RegexUtilityModal'
import SamplingQCStatsModal from './SamplingQCStatsModal'
import paginationTextHint from '../Tooltips/paginationTextHint'
import { getStatusMeta } from './utils'
import './style.scss'

const DATE_SHORT_FORMAT = 'MMM DD, hh:mm A (UTC Z)'

const { SAMPLING_QC_SIZE_PER_PAGE } = supraQCToolDefaults

const SOURCE_TO_CLASS_MAP = {
  conflict: 'text-danger',
  staging: 'text-primary'
}

class SamplingQC extends Component {
  tooltip = name => <Tooltip id="sampling-sessions-toolbar-tooltip">{name}</Tooltip>

  overlayFormatter = (cell, row, placement = 'right') => {
    return (
      <OverlayTrigger placement={placement} delayShow={100} overlay={this.tooltip(cell)}>
        <span>{cell}</span>
      </OverlayTrigger>
    )
  }

  percentageFormatter = cell => {
    return <span>{cell}%</span>
  }

  openAddToDictModal = row => {
    this.props.onAddToDictModalOpen({
      selectedRow: {
        ...row,
        email_type: row.email_type
      },
      source: 'sampling_qc'
    })
  }

  inDictionaryFormatter = (cell, row) => {
    const className = row.new_email_type || row.modified_label ? 'text-primary' : ''
    return <span className={className}>{cell.toString()}</span>
  }

  firstEntryFormatter = (cell, row) => {
    return <span>{cell[0]?.toString()}</span>
  }

  listOfValuesFormatter = (cell, row) => {
    return <span>{cell?.join(', ')}</span>
  }

  dictionaryStatusFormatter = (cell, row) => {
    const dictionaryRules = [...row.dictionary_ruleids]
    const portalRules = [...row.dictionary_ruleids_portal]
    const bbxSupra = row.bbx_supra
    const bbxSupraPortal = row.bbx_supra_portal
    const tooltipText = `ML: ${dictionaryRules} (${bbxSupra}) , Portal: ${portalRules} (${bbxSupraPortal})`
    return (
      <OverlayTrigger placement="right" delayShow={100} overlay={this.tooltip(tooltipText)}>
        <span className={SOURCE_TO_CLASS_MAP[row.source]}>{row.source}</span>
      </OverlayTrigger>
    )
  }

  definedTypeFormatter = (cell, row) => {
    const tooltipText = `Type: ${row.bbx_supra || 'NULL'}, Defined Type: ${row.bbx_supra_portal || 'NULL'}`
    return (
      <OverlayTrigger delayShow={100} overlay={this.tooltip(tooltipText)}>
        <span className={SOURCE_TO_CLASS_MAP[row.source]}>{cell}</span>
      </OverlayTrigger>
    )
  }

  getBoomerLink = row => {
    const exampleSubject = row.examples[0]
    const url = `${boomerBaseURL}/search?defaultFromAddr=${row.from_addr}&defaultSubj=${exampleSubject}`
    return url
  }

  actionsFormatter = (cell, row) => {
    return (
      <div className="d-flex justify-content-center">
        <OverlayTrigger placement="left" overlay={<Tooltip id="edit-regex-utility">Edit Regex Utility</Tooltip>}>
          <div onClick={() => this.props.openRegexUtilityModal({ selectedRow: row })} className="cursor-pointer">
            <Glyphicon glyph="edit" />
          </div>
        </OverlayTrigger>
      </div>
    )
  }

  msgUUIDFormatted = (cell, row) => {
    const firstMsgUUID = row.examples_msguuid[0]
    return (
      <div>
        <OverlayTrigger placement="right" delayShow={100} overlay={this.tooltip(firstMsgUUID)}>
          <span>{cell}</span>
        </OverlayTrigger>

        <div className="pull-right">
          <OverlayTrigger placement="left" overlay={<Tooltip id="open-boomer-link">View Receipt</Tooltip>}>
            <a
              href={`https://receipt-gateway-web.prod.slicetest.com/receipt/show?receiptIds=${firstMsgUUID}&receiptType=CATEGORIZED_MESSAGE`}
              target="_blank"
              className="action-icon"
              rel="noopener noreferrer"
            >
              <Glyphicon glyph={'camera'} className="action-icon" />
            </a>
          </OverlayTrigger>

          <OverlayTrigger placement="left" overlay={<Tooltip id="open-boomer-link">Open Boomer Link</Tooltip>}>
            <a className="action-icon ml-2" href={this.getBoomerLink(row)} target="_blank" rel="noopener noreferrer">
              <Glyphicon glyph={'search'} className="action-icon" />
            </a>
          </OverlayTrigger>
        </div>
      </div>
    )
  }

  createCustomToolBar = props => {
    const { lastDictionaryCheck } = this.props
    const { color, text } = getStatusMeta(lastDictionaryCheck.status)
    return (
      <div className="text-left result-table-toolbar">
        Sampling QC Data
        <span>
          <OverlayTrigger delayShow={100} placement="top" overlay={this.tooltip('Refresh')}>
            <Button
              bsSize="xsmall"
              bsStyle="default"
              className="action-button refresh-button"
              onClick={() => this.props.refreshSamplingQCTable({})}
            >
              <Glyphicon glyph="refresh" />
            </Button>
          </OverlayTrigger>
        </span>
        <span>
          <Button
            bsSize="xsmall"
            bsStyle="primary"
            className="action-button ml-2"
            onClick={() => this.props.openStatsModal()}
            disabled={this.props.rows.length === 0}
          >
            View Stats
          </Button>
        </span>
        {lastDictionaryCheck?.id && (
          <OverlayTrigger delayShow={100} placement="bottom" overlay={this.getTooltip(lastDictionaryCheck)}>
            <span className="ml-3">
              <strong>
                Last dictionary check:{' '}
                <span className={`text-color-${color}`}>
                  <strong>{text}</strong>
                </span>{' '}
                {lastDictionaryCheck && moment(lastDictionaryCheck.createdAt).format(DATE_SHORT_FORMAT)}
              </strong>
            </span>
          </OverlayTrigger>
        )}
      </div>
    )
  }

  getTooltip(task) {
    const statusToTooltipMap = {
      [TASK_STATUS.SUCCESS]:
        'Sample is synced with latest dictionary rules. "source" and "type" columns have been updated',
      [TASK_STATUS.PENDING]:
        'Updating dictionary with new rules and re-checking sample against all dictionary rules. (Click Refresh button to get status of job.)',
      [TASK_STATUS.PROCESSING]:
        'Updating dictionary with new rules and re-checking sample against all dictionary rules. (Click Refresh button to get status of job.)',
      [TASK_STATUS.FAILED]: 'Last dictionary check failed. The "source" and "type" column might be inaccurate',
      [TASK_STATUS.CANCELLED]: 'Last dictionary check was cancelled. The "source" and "type" column might be inaccurate'
    }
    const tooltipText = statusToTooltipMap[task.status]
    return (
      <Tooltip id="last-dictionary-check-tooltip">
        <p className="text-left">{tooltipText}</p>
      </Tooltip>
    )
  }

  render() {
    let idNumber = 1
    const options = {
      sizePerPage: SAMPLING_QC_SIZE_PER_PAGE,
      paginationShowsTotal: paginationTextHint,
      hideSizePerPage: true,
      toolBar: this.createCustomToolBar
    }

    const defaultFilter = { type: 'TextFilter', delay: 500 }

    return this.props.loading ? (
      <Loader />
    ) : (
      <>
        {this.props.showStatsModal && <SamplingQCStatsModal />}
        <RegexUtilityModal />
        <div key="sampling-qc-table">
          <BootstrapTable
            data={this.props.rows.map(row => ({
              ...row,
              _defined_type: row.bbx_supra_portal || row.bbx_supra,
              idNumber: idNumber++
            }))}
            pagination
            options={options}
            striped
            hover
            condensed
            bordered
            responsive
          >
            <TableHeaderColumn dataField="idNumber" width="60px" isKey>
              ID
            </TableHeaderColumn>

            <TableHeaderColumn dataField="template" filter={defaultFilter} dataSort dataFormat={this.msgUUIDFormatted}>
              Template
            </TableHeaderColumn>

            <TableHeaderColumn dataField="from_addr" filter={defaultFilter} dataSort dataFormat={this.overlayFormatter}>
              From
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="source"
              width="120px"
              filter={defaultFilter}
              dataSort
              dataFormat={this.dictionaryStatusFormatter}
            >
              Source
            </TableHeaderColumn>

            <TableHeaderColumn
              dataField="_defined_type"
              width="100px"
              filter={defaultFilter}
              dataSort
              dataFormat={this.definedTypeFormatter}
            >
              Type
            </TableHeaderColumn>

            <TableHeaderColumn
              dataField="percentage_msg_total_cusum"
              width="130px"
              filter={defaultFilter}
              dataSort
              dataFormat={this.percentageFormatter}
            >
              Quantile
            </TableHeaderColumn>

            <TableHeaderColumn
              dataField="percentage_msg"
              width="100px"
              filter={defaultFilter}
              dataSort
              dataFormat={this.percentageFormatter}
            >
              Traffic
            </TableHeaderColumn>

            <TableHeaderColumn
              dataField="examples"
              filter={defaultFilter}
              dataSort
              dataFormat={this.firstEntryFormatter}
            >
              Examples
            </TableHeaderColumn>

            <TableHeaderColumn dataField="button" width="70px" dataFormat={this.actionsFormatter}>
              Actions
            </TableHeaderColumn>
          </BootstrapTable>
        </div>
      </>
    )
  }
}

SamplingQC.propTypes = {
  rows: PropTypes.array,
  aggregations: PropTypes.object,
  total: PropTypes.number,
  page: PropTypes.number,
  loading: PropTypes.bool,
  onAddToDictModalOpen: PropTypes.func,
  openRegexUtilityModal: PropTypes.func,
  refreshSamplingQCTable: PropTypes.func,
  openStatsModal: PropTypes.func,
  showStatsModal: PropTypes.bool,
  lastDictionaryCheck: PropTypes.object
}

function mapStateToProps({ supraQCToolV2 }) {
  return {
    rows: supraQCToolV2.samplingQCTab.rows || [],
    total: supraQCToolV2.samplingQCTab.total || 0,
    aggregations: supraQCToolV2.samplingQCTab.aggregations,
    loading: supraQCToolV2.samplingQCTab.loading,
    page: supraQCToolV2.samplingQCTab.page || 1,
    showStatsModal: supraQCToolV2.statsModal.show,
    lastDictionaryCheck: supraQCToolV2.samplingQCTab.lastDictionaryCheck || {}
  }
}

export default connect(mapStateToProps, {
  onAddToDictModalOpen,
  openRegexUtilityModal,
  refreshSamplingQCTable,
  openStatsModal
})(SamplingQC)
