import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { fetchCategorySuggestionsAction } from './actions'
import Suggestion from './Suggestion'

class CategorySuggestion extends Component {
  render() {
    const {
      selectedId,
      selectedLabel,
      isLeaf,
      categorySuggestions,
      fetchCategorySuggestionsAction,
      changeCallback,
      readOnly
    } = this.props

    const additionalSearchParams = {}
    if (isLeaf !== undefined) {
      additionalSearchParams.isLeaf = isLeaf
    }

    return (
      <Suggestion
        labelKey={'fullPath'}
        selectedId={selectedId}
        selectedLabel={selectedLabel}
        searchSuggestions={categorySuggestions}
        fetchAction={fetchCategorySuggestionsAction}
        changeCallback={changeCallback}
        placeholder={`Search for a Category`}
        readOnly={readOnly}
        additionalSearchParams={additionalSearchParams}
        isActive
      />
    )
  }
}

CategorySuggestion.propTypes = {
  selectedId: PropTypes.number,
  selectedLabel: PropTypes.string,
  isLeaf: PropTypes.bool,
  changeCallback: PropTypes.func,

  categorySuggestions: PropTypes.array.isRequired,
  fetchCategorySuggestionsAction: PropTypes.func.isRequired,

  readOnly: PropTypes.bool
}

CategorySuggestion.defaultProps = {
  selectedId: null,
  selectedLabel: '',
  isLeaf: undefined,
  categorySuggestions: [],
  changeCallback: () => {},
  readOnly: false
}

const mapStateToProps = ({ suggestions }) => ({
  categorySuggestions: suggestions.categories
})

const mapDispatchToProps = {
  fetchCategorySuggestionsAction
}

export default connect(mapStateToProps, mapDispatchToProps)(CategorySuggestion)
