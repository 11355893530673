import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { ToggleButtonGroup, ToggleButton, Badge, Glyphicon } from 'react-bootstrap'

import './index.scss'

export const CATEGORY_FITLERS = {
  ALL: 'all',
  SELECTED: 'selected',
  INCORRECT: 'incorrect'
}

class CategoryConfigFilters extends Component {
  state = {
    categoryFilter: this.props.defaultValue || CATEGORY_FITLERS.ALL
  }

  onCategoryFilterChange = categoryFilter => {
    this.setState({ categoryFilter })
    this.props.onCategoryFilterChange(categoryFilter)
  }

  render() {
    const { correctCategoriesCount, inCorrectCategoriesCount } = this.props
    const { categoryFilter } = this.state
    return (
      <div className="category-config-filters">
        <Glyphicon glyph="filter" />
        <ToggleButtonGroup
          type="radio"
          name="categoryFilter"
          value={categoryFilter}
          onChange={this.onCategoryFilterChange}
        >
          <ToggleButton value={CATEGORY_FITLERS.ALL}>All Categories</ToggleButton>
          <ToggleButton
            value={CATEGORY_FITLERS.SELECTED}
            disabled={correctCategoriesCount === 0}
            title="This button will show all valid selected Categories"
          >
            Selected Categories <Badge>{correctCategoriesCount}</Badge>
          </ToggleButton>
          <ToggleButton
            value={CATEGORY_FITLERS.INCORRECT}
            disabled={inCorrectCategoriesCount === 0}
            title="This button will show and allow to de-select inactive and parent categories. In-active and parent categories are un-deliverable"
          >
            {inCorrectCategoriesCount > 0 && <Glyphicon glyph="exclamation-sign" />}
            Invalid Categories <Badge>{inCorrectCategoriesCount}</Badge>
          </ToggleButton>
        </ToggleButtonGroup>
      </div>
    )
  }
}

CategoryConfigFilters.propTypes = {
  correctCategoriesCount: PropTypes.number.isRequired,
  inCorrectCategoriesCount: PropTypes.number.isRequired,
  defaultValue: PropTypes.string,

  onCategoryFilterChange: PropTypes.func.isRequired
}

export default CategoryConfigFilters
