import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Button } from 'react-bootstrap'
import Input from 'components/Input'
import { clearModals } from '../../../actions/modal-actions'
import { isCategoryNameValid, isBrandNameValid, trimName } from './helper'
import CheckBox from 'components/CheckBox'
import InfoTooltip from 'components/Tooltips/InfoTooltip'
import { CPG_TOOLTIP_TEXT } from '../constants-taxonomy'
import TaxonomyNameTooltipInfo from './TaxonomyNameTooltipInfo'

class TaxonomyEditModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      name: this.props.rowName,
      isCPG: this.props.isCPG
    }
  }

  handleNameChange = name => {
    const validator = this.props.tableType === 'Category' ? isCategoryNameValid : isBrandNameValid
    if (validator(name)) {
      this.setState({ name })
    }
  }

  handleCheckboxClick = val => {
    this.setState({
      isCPG: val
    })
  }

  handleEditSubmit = clickEvent => {
    clickEvent.preventDefault()
    const trimmedName = trimName(this.state.name)
    this.props.editFunction({
      rowId: this.props.rowId,
      name: trimmedName,
      isCPG: this.state.isCPG
    })
    this.props.clearModals()
  }

  render() {
    const { name, isCPG } = this.state
    const { tableType, clearModals } = this.props
    return (
      <div className="mt-5 taxonomy-edit-modal">
        <div className="d-flex align-items-end">
          <span className="taxonomy-input-label">
            Name
            <TaxonomyNameTooltipInfo type={tableType} />
          </span>
          <Input
            didClickClearButton={() => this.setState({ name: '' })}
            clearButton
            value={name}
            textDidChange={this.handleNameChange}
            className="taxonomy-input-field"
          />
        </div>

        {tableType === 'Category' && (
          <div className="mt-5 taxonomy-parent-input">
            <span className="taxonomy-input-label">
              <span>CPG</span>
              <span className="fs-14-16 pb-1">
                <InfoTooltip text={CPG_TOOLTIP_TEXT} />
              </span>
            </span>
            <CheckBox
              className="checkboxInline taxonomy-input-field"
              valueDidChange={this.handleCheckboxClick}
              checked={isCPG}
            />
          </div>
        )}

        <div className="modal-body-footer">
          <Button onClick={clearModals}>Cancel</Button>
          <Button className="btn_custom" onClick={this.handleEditSubmit} disabled={name === ''}>
            Update
          </Button>
        </div>
      </div>
    )
  }
}

TaxonomyEditModal.propTypes = {
  rowId: PropTypes.number.isRequired,
  rowName: PropTypes.string.isRequired,
  isCPG: PropTypes.bool,
  clearModals: PropTypes.func.isRequired,
  editFunction: PropTypes.func.isRequired,
  tableType: PropTypes.string
}

const mapDispatchToProps = {
  clearModals
}

export default connect(null, mapDispatchToProps)(TaxonomyEditModal)
