import React from 'react'
import PropTypes from 'prop-types'

import { Form, Glyphicon, Alert } from 'react-bootstrap'
import InputField, { useFormValues } from './../../../FormInputComponents/InputField'

import CategorySuggestion from './../../../Suggestion/CategorySuggestion'
import MerchantSuggestion from './../../../Suggestion/MerchantSuggestion'

import useFormMerchantCategoryFilds from './useFormMerchantCategoryFilds'
import useModalUISettings from './../../Common/useModalUISettings'
import useFormButtons from './../../Common/useFormButtons'
import useMode from './../../Common/useMode'

import NoteFields from './../../Common/NoteFields'
import CommonRuleModalButtons from './../../Common/CommonRuleModalButtons'

export default function MerchantCategoryRuleModal({ value, isRequest, onOkHandler, onRejectHandler, isReadOnly }) {
  const { id, merchantId, merchantName, categoryId, categoryFullPath, request_notes, review_notes } = value
  const mode = useMode(isRequest, isReadOnly, id)

  const { values, isFormChanged, handleFieldChange, updateValue } = useFormValues({
    id: id ?? null,
    categoryId: categoryId ?? null,
    categoryFullPath: categoryFullPath ?? null,
    merchantId: merchantId ?? null,
    merchantName: merchantName ?? null,
    request_notes: request_notes ?? null,
    review_notes: review_notes ?? null
  })

  const [handleMerchantChange, handleCategoryChange] = useFormMerchantCategoryFilds(values, updateValue, mode)

  const isAllFieldsFilled = values.categoryId && values.merchantId
  const fieldsNames = 'Merchant and Category'
  const isRuleActiveFunc = rule => rule.isActive && rule.categoryIsActive && rule.merchantIsActive
  const { ruleInfoMessage, ruleInfoStyle, okButtonName, isOkButtonDisabled } = useModalUISettings({
    prevRuleId: values.id,
    isAllFieldsFilled,
    isFormChanged,
    fieldsNames,
    isRuleActiveFunc,
    mode
  })

  const sentFieldsForRules = ['merchantId', 'categoryId']
  const sentFieldsForRequests = ['merchantName', 'categoryFullPath', 'request_notes', 'review_notes']
  const { handleOkButton, handleRejectButton, handleCancelButton } = useFormButtons({
    onOkHandler,
    onRejectHandler,
    values,
    sentFieldsForRules,
    sentFieldsForRequests,
    isRequest
  })

  return (
    <Form className="text-left" horizontal>
      <InputField validationState={values.merchantId === undefined ? 'error' : null} required label={'Merchant'}>
        <MerchantSuggestion
          selectedId={values.merchantId}
          selectedLabel={values.merchantName}
          changeCallback={handleMerchantChange}
          readOnly={mode.isRequestApprove || mode.isRequestView}
        />
      </InputField>
      <InputField validationState={values.categoryId === undefined ? 'error' : null} required label={'Category'}>
        <CategorySuggestion
          selectedId={values.categoryId}
          selectedLabel={values.categoryFullPath}
          changeCallback={handleCategoryChange}
          readOnly={mode.isRequestApprove || mode.isRequestView}
        />
      </InputField>

      {!mode.isRequestView && (
        <Alert bsStyle={ruleInfoStyle}>
          <Glyphicon className="padding-rigt-standard" glyph="info-sign" />
          {ruleInfoMessage}
        </Alert>
      )}

      <NoteFields
        mode={mode}
        request_notes={values.request_notes}
        review_notes={values.review_notes}
        handleFieldChange={handleFieldChange}
      />

      <CommonRuleModalButtons
        handleOkButton={handleOkButton}
        handleRejectButton={handleRejectButton}
        handleCancelButton={handleCancelButton}
        mode={mode}
        okButtonName={okButtonName}
        isOkButtonDisabled={isOkButtonDisabled}
      />
    </Form>
  )
}

MerchantCategoryRuleModal.defaultProps = {
  value: {
    id: null,
    merchantId: undefined,
    merchantName: null,
    categoryId: undefined,
    categoryFullPath: null,
    request_notes: null,
    review_notes: null
  },
  isRequest: false,
  isReadOnly: false
}

MerchantCategoryRuleModal.propTypes = {
  value: PropTypes.shape({
    id: PropTypes.number,
    merchantId: PropTypes.number,
    merchantName: PropTypes.string,
    categoryId: PropTypes.number,
    categoryFullPath: PropTypes.string,
    request_notes: PropTypes.string,
    review_notes: PropTypes.string
  }),

  onOkHandler: PropTypes.func.isRequired,
  onRejectHandler: PropTypes.func,

  isRequest: PropTypes.bool,
  isReadOnly: PropTypes.bool
}
