import { useSelector } from 'react-redux'
import { capitalize } from 'lodash'
import { METRICS_STORE_PATH } from './../metricsReducer'

const getCategoryInfo = category => {
  const name = category.value
  const id = category.id
  return {
    id,
    label: name
      .split(' ')
      .map(w => capitalize(w))
      .join(' ')
  }
}

const useCategoryInfo = () => {
  const { categories } = useSelector(state => state[METRICS_STORE_PATH])

  const hasSelectedCategory = categories.length > 0
  const isMultipleSelection = categories.length > 1

  if (!hasSelectedCategory) {
    return {
      hasSelectedCategory,
      isMultipleSelection
    }
  }

  const SHOROT_LABEL_LENGTH = 200
  const firstSelectedCategory = categories[0]
  const { label, id } = getCategoryInfo(firstSelectedCategory)
  const shortCategoryFullPath = label.length > SHOROT_LABEL_LENGTH ? `...${label.slice(-SHOROT_LABEL_LENGTH)}` : label

  return {
    categoryId: id,
    label,
    shortCategoryFullPath,
    hasSelectedCategory,
    isMultipleSelection
  }
}

export default useCategoryInfo
