import React, { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Modal, Button } from 'react-bootstrap'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import { round } from 'lodash'
import { supraQCToolDefaults } from 'constants/constants'
import { closeStatsModal } from './slices'
import './style.scss'

const { NUMBERS_AFTER_DECIMAL_POINT } = supraQCToolDefaults
const BUCKET_SIZE = 20

const SamplingQCStatsModal = () => {
  const dispatch = useDispatch()
  const handleClose = () => dispatch(closeStatsModal())
  const {
    stats: { createdAt, statistics }
  } = useSelector(state => state.supraQCToolV2.statsModal)

  let {
    defined_templates_cnt = 0,
    defined_templates_pct = 0,
    // eslint-disable-next-line prefer-const
    total_templates_cnt = 0,
    conflicted_templates_cnt = 0,
    staged_templates_cnt = 0
  } = statistics

  defined_templates_cnt = `${defined_templates_cnt} / ${total_templates_cnt}`
  defined_templates_pct = `${round(defined_templates_pct, NUMBERS_AFTER_DECIMAL_POINT)} %`
  conflicted_templates_cnt = `${conflicted_templates_cnt} / ${total_templates_cnt}`
  staged_templates_cnt = `${staged_templates_cnt} / ${statistics?.defined_templates_cnt}`

  const aggregates = [
    {
      defined_templates_cnt,
      defined_templates_pct,
      conflicted_templates_cnt,
      total_templates_cnt,
      staged_templates_cnt
    }
  ]
  let sequence = 1

  const statisticsData = useMemo(() => {
    return statistics?.risk_quantile_chart?.map(row => {
      return {
        ...row,
        sequence: sequence++,
        quantile: `${row.quantile - BUCKET_SIZE} - ${row.quantile}%`,
        defined_templates_pct: `${row.defined_templates_pct.toFixed(2)}%`
      }
    })
  }, [statistics, sequence])

  return (
    <Modal show onHide={handleClose} bsClass="modal">
      <Modal.Header>
        <Modal.Title>Sampling QC Statistics</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {(statistics?.risk_quantile_chart && (
          <div className="d-flex flex-column">
            <div>
              <h4>Sample Date</h4>
              <div>{createdAt.slice(0, 10)}</div>
            </div>

            <div className="mt-3">
              <BootstrapTable
                data={aggregates.map(row => ({ ...row, sequence: sequence++ }))}
                striped
                hover
                condensed
                bordered
                responsive
              >
                <TableHeaderColumn dataField="sequence" hidden isKey>
                  Sequence
                </TableHeaderColumn>

                <TableHeaderColumn key="defined_templates_pct" dataField="defined_templates_pct">
                  % Traffic Defined
                </TableHeaderColumn>
                <TableHeaderColumn key="defined_templates_cnt" dataField="defined_templates_cnt">
                  Templates Defined
                </TableHeaderColumn>
                <TableHeaderColumn key="staged_templates_cnt" dataField="staged_templates_cnt">
                  Staged
                </TableHeaderColumn>
                <TableHeaderColumn key="conflicted_templates_cnt" dataField="conflicted_templates_cnt">
                  Conflicted
                </TableHeaderColumn>
              </BootstrapTable>
            </div>
            <div className="mt-3">
              <BootstrapTable data={statisticsData} striped hover condensed bordered responsive>
                <TableHeaderColumn dataField="sequence" hidden isKey>
                  Sequence
                </TableHeaderColumn>
                <TableHeaderColumn key="quantile" tdStyle={{ whiteSpace: 'normal' }} dataField="quantile" isKey={false}>
                  Quantile
                </TableHeaderColumn>
                <TableHeaderColumn key="templates_cnt" tdStyle={{ whiteSpace: 'normal' }} dataField="templates_cnt">
                  Templates
                </TableHeaderColumn>
                <TableHeaderColumn
                  key="defined_templates_cnt"
                  tdStyle={{ whiteSpace: 'normal' }}
                  dataField="defined_templates_cnt"
                >
                  # Defined
                </TableHeaderColumn>
                <TableHeaderColumn
                  key="defined_templates_pct"
                  tdStyle={{ whiteSpace: 'normal' }}
                  dataField="defined_templates_pct"
                >
                  % Defined
                </TableHeaderColumn>
              </BootstrapTable>
            </div>
          </div>
        )) ||
          'Statistics not available'}
      </Modal.Body>

      <Modal.Footer>
        <Button onClick={handleClose}>Close</Button>
      </Modal.Footer>
    </Modal>
  )
}

export default SamplingQCStatsModal
