/*
 * Example:
 * let props = { parent: 'A', child: 'aa', arrOfChilds: ['aa', 'bb'] }
 */
const duplicateCheck = ({ parent, child, arrOfChilds }) => {
  // check duplicate in arrOfChilds
  // Props => child, arrOfChilds
  const hasDuplicateInGroup = () => {
    const arrMod = arrOfChilds.map(item => item?.trim()?.toLowerCase())
    const sameItems = arrMod.filter(item => item === child?.trim()?.toLowerCase())
    if (sameItems.length > 1) {
      return true
    } else {
      return false
    }
  }

  // check synonym duplicates child with parent
  // Props => parent, child
  const hasDuplicateWithParent = () => child?.trim()?.toLowerCase() === parent?.toLowerCase()

  // check duplicates in arrOfChilds together with parent
  // Props => parent, arrOfChilds
  const hasAnyDuplicate = () => {
    const arrLowerCase = arrOfChilds.map(_child => _child?.trim()?.toLowerCase())
    return arrLowerCase.some(
      (_child, index) => arrLowerCase.indexOf(_child) !== index || _child === parent?.toLowerCase()
    )
  }

  // it returns new functions which supposed to be called
  return {
    hasDuplicateInGroup,
    hasDuplicateWithParent,
    hasAnyDuplicate
  }
}

export default duplicateCheck
