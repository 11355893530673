import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReactHighcharts from 'react-highcharts'
// import ReactHighstock from 'react-highcharts/ReactHighstock'
import highchartsTheme from './highcharts-theme'

const labels = {
  revenue: 'Revenue (Dollars)',
  count: 'Count'
}

class TableChart extends Component {
  static propTypes = {
    data: PropTypes.array,
    nameField: PropTypes.string,
    aggType: PropTypes.string,
    title: PropTypes.string,
    selectedField: PropTypes.string,
    height: PropTypes.number,
    width: PropTypes.number
  }

  static defaultProps = {}

  constructor(props) {
    super(props)
    ReactHighcharts.Highcharts.setOptions(highchartsTheme)
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      this.props.data !== nextProps.data ||
      this.props.selectedField !== nextProps.selectedField ||
      this.props.height !== nextProps.height ||
      this.props.width !== nextProps.width
    )
  }

  buildConfig(props) {
    const series = props.data.map(d => {
      return {
        name: d[`${this.props.nameField}`],
        data: [props.selectedField === 'count' ? d.count : parseFloat(d.revenue)]
      }
    })

    return {
      chart: {
        type: 'column',
        height: this.props.height,
        width: this.props.height ? undefined : this.props.width
      },
      xAxis: {
        categories: [`${this.props.aggType}`],
        title: {
          text: ''
        }
      },
      yAxis: {
        title: {
          text: labels[props.selectedField]
        }
      },
      plotOptions: {
        series: {
          allowPointSelect: true,
          animation: false,
          marker: {
            enabled: true
          }
        }
      },
      title: {
        text: props.title
      },
      credits: {
        enabled: false
      },
      legend: {
        itemStyle: {
          lineHeight: '1',
          fontSize: '10px'
        }
      },
      series
    }
  }

  render() {
    return (
      <div className={`widget-chart-container`}>
        <ReactHighcharts ref={`${this.props.aggType}Chart`} config={this.buildConfig(this.props)} />
      </div>
    )
  }
}

export default TableChart
