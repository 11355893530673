import React from 'react'
import PropTypes from 'prop-types'
import { Glyphicon, OverlayTrigger, Popover } from 'react-bootstrap'

export const CELL_TYPE = {
  BRAND: 'brand',
  CATEGORY: 'category'
}

/**
 * ValidationIcon for CBR row with validation message
 * @param {String} type - type of cell (brand or category)
 * @param {String} reason - reason for validation
 * @returns {JSX.Element}
 */
const ValidationIcon = ({ type, reason }) => {
  const messages = {
    [CELL_TYPE.BRAND]: {
      brand_empty: 'Brand is empty. Please select a Brand.',
      brand_not_exist: `Brand doesn't exist. Please select another Brand.`,
      cbr_not_exist: `Rule for current Brand and Category doesn't exist. Please select another Brand or Category.`
    },
    [CELL_TYPE.CATEGORY]: {
      category_empty: 'Category is empty. Please select a Category.',
      category_not_exist: `Category doesn't exist. Please select another Category.`,
      cbr_not_exist: `Rule for current Category and Brand doesn't exist. Please select another Brand or Category.`
    }
  }

  const message = messages[type]?.[reason] || ''

  if (!message) {
    return null
  }

  return (
    <OverlayTrigger
      placement="top"
      overlay={
        <Popover id="niq-cbr-validation-tooltip">
          <p>{message}</p>
        </Popover>
      }
    >
      <Glyphicon className="ml-2 text-color-red" glyph="alert" />
    </OverlayTrigger>
  )
}

ValidationIcon.propTypes = {
  type: PropTypes.string.isRequired,
  reason: PropTypes.string.isRequired
}

export default ValidationIcon
