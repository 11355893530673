import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  initializeClientForm,
  updateClientForm,
  checkIfClientNameAvailable,
  saveClient
} from '../../../actions/dm-actions'
import { clearModals } from '../../../actions/modal-actions'
import { Button, Form } from 'react-bootstrap'
import TextInputField from '../Common/TextInputField'
import Loader from '../../Loader'
import { debounce, pick } from 'lodash'

class ClientForm extends Component {
  componentDidMount() {
    this.props.initializeClientForm({
      ...pick(this.props.client, ['id', 'name']),
      dirty: false
    })
  }

  getValidationDetails(client = this.props) {
    let isNameValid = false
    if (client.name && client.name.trim()) {
      isNameValid = true
    }
    return {
      isNameValid
    }
  }

  handleNameChange = event => {
    this.updateFormValue('name', event.target.value)
    this.checkIfClientNameAvailable({
      id: this.props.id,
      name: event.target.value
    })
  }

  checkIfClientNameAvailable = debounce(this.props.checkIfClientNameAvailable, 500)

  updateFormValue(key, value) {
    this.props.updateClientForm({
      [key]: value,
      ...this.getValidationDetails({ [key]: value }),
      dirty: true
    })
  }

  disableSaveButton() {
    const { dirty, isNameValid, isNameAvailable } = this.props
    return !dirty || isNameValid === false || isNameAvailable === false
  }

  saveClient = () => {
    const { name, id } = this.props
    this.props.saveClient({ name, id })
  }

  render() {
    const { name = '', dirty, isNameValid, id, isNameAvailable, loading } = this.props
    return (
      <Form>
        {loading && <Loader overlap />}
        <div>
          <TextInputField
            id="client-name"
            label="Client Name"
            help={
              isNameValid === false
                ? 'Client name cannot be empty'
                : isNameAvailable === false
                ? 'Client name already exists'
                : null
            }
            validationState={dirty && (isNameValid === false || isNameAvailable === false) ? 'error' : null}
            required
            type="text"
            value={name}
            onChange={this.handleNameChange}
            autoFocus
          />
        </div>
        <div className="modal-footer sb-form-footer">
          <div className="modal-buttons">
            <Button className="btn-cancel" onClick={this.props.clearModals}>
              Cancel
            </Button>
            <Button disabled={this.disableSaveButton()} className="btn-ok" onClick={this.saveClient}>
              {id && id !== 'new' ? 'Save' : 'Create'}
            </Button>
          </div>
        </div>
      </Form>
    )
  }
}

ClientForm.defaultProps = {
  client: { id: 'new' }
}

ClientForm.propTypes = {
  client: PropTypes.object,
  name: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  dirty: PropTypes.bool,
  isNameValid: PropTypes.bool,
  isNameAvailable: PropTypes.bool,
  loading: PropTypes.bool,
  initializeClientForm: PropTypes.func,
  updateClientForm: PropTypes.func,
  checkIfClientNameAvailable: PropTypes.func,
  clearModals: PropTypes.func,
  saveClient: PropTypes.func
}

function mapStateToProps({ dm: { clientForm } }) {
  return {
    ...clientForm
  }
}

export default connect(mapStateToProps, {
  initializeClientForm,
  updateClientForm,
  checkIfClientNameAvailable,
  clearModals,
  saveClient
})(ClientForm)
