import React from 'react'

const paginationTextHint = (start, to, total) => {
  return (
    <p className="mt-2.5">
      Showing {start} to {to} of {total}
    </p>
  )
}

export default paginationTextHint
