import React, { Component } from 'react'
import PropTypes from 'prop-types'
import constants from '../../../constants/constants'
import { Button } from 'react-bootstrap'
import StockChartComponent from './StockChartComponent'

class SplitByChart extends Component {
  shouldComponentUpdate(nextProps) {
    return (
      this.props.data !== nextProps.data ||
      this.props.chartView !== nextProps.chartView ||
      this.props.drillDownBy !== nextProps.drillDownBy
    )
  }

  onSelectedTimeRangeChanged = (min, max) => {
    this.props.onSelectedTimeRangeChanged({ from: min, to: max })
  }

  refreshQCGraphData = payload => {
    const refreshPayload = {
      ...payload,
      ...{ componentType: 'split' }
    }
    this.props.refreshQCGraphData(refreshPayload)
  }

  render() {
    const { data, splitBy, drillDownBy, chartView, onDrillDownByChanged, onSelectedTimeRangeChanged } = this.props
    const { REVENUE, LAST_REVENUE } = constants.aggregations

    if (!data || !data.revenue) {
      return <div />
    }

    const renderChart = (key, chartKey, data) => {
      return (
        <div key={key} className="splitBy-chart-wrapper">
          <div>
            <Button
              onClick={event => {
                event.preventDefault()
                onDrillDownByChanged({
                  name: key,
                  type: splitBy
                })
                onSelectedTimeRangeChanged({ from: null, to: null })
              }}
              className="pull-right drill-down-button"
            >
              Drilldown &gt;&gt;
            </Button>
          </div>
          <div
            className="splitBy-chart-container"
            style={{
              backgroundColor: !drillDownBy || drillDownBy.name === key ? '#FFFFFF' : '#DDDDDD'
            }}
          >
            <StockChartComponent
              splitBy={splitBy}
              chartView={chartView}
              chartKey={chartKey}
              data={data}
              onSelectedTimeRangeChanged={this.onSelectedTimeRangeChanged}
              refreshQCGraphData={this.refreshQCGraphData}
              loading={!(data.currentSeries && data.lastSeries)}
            />
          </div>
        </div>
      )
    }

    const getSplitByCharts = () => {
      return Object.keys(data.revenue).map(key => {
        const chartData = {
          currentSeries: data[REVENUE] ? data[REVENUE][key] : [],
          lastSeries: data[LAST_REVENUE] ? data[LAST_REVENUE][key] : []
        }
        return renderChart(key, key, chartData)
      })
    }

    if (splitBy === 'none') {
      const chartData = {
        currentSeries: data[REVENUE] ? data[REVENUE][REVENUE] : [],
        lastSeries: data[LAST_REVENUE] ? data[LAST_REVENUE][LAST_REVENUE] : []
      }
      return renderChart('none', REVENUE, chartData)
    } else {
      return <div className="left-charts-container">{getSplitByCharts()}</div>
    }
  }
}

SplitByChart.propTypes = {
  drillDownBy: PropTypes.object,
  splitBy: PropTypes.string,
  chartView: PropTypes.string,
  data: PropTypes.object,
  title: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
  onDrillDownByChanged: PropTypes.func.isRequired,
  onSelectedTimeRangeChanged: PropTypes.func,
  refreshQCGraphData: PropTypes.func
}

export default SplitByChart
