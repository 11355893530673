import { DATA_TABLE } from './../../DataTable/constants'
import { BooleanCell, DateCell } from './../../DataTable'

const COMMON_COLUMNS = {
  id: {
    field: 'id',

    dataAlign: 'center',
    headerAlign: 'center',
    width: '70px',

    label: 'ID',
    dataSort: true,
    filter: {
      type: 'TextFilter',
      delay: DATA_TABLE.FILTER_DELAY
    }
  },
  categoryId: {
    field: 'categoryId',

    dataAlign: 'center',
    headerAlign: 'center',
    width: '100px',

    label: 'Cat. ID',
    title: 'Category ID',
    dataSort: true,
    filter: {
      type: 'TextFilter',
      delay: DATA_TABLE.FILTER_DELAY
    }
  },
  categoryFullPath: {
    field: 'categoryFullPath',

    dataAlign: 'left',
    headerAlign: 'center',
    width: 'auto',

    label: 'Category full path',
    dataSort: true,
    filter: {
      type: 'TextFilter',
      delay: DATA_TABLE.FILTER_DELAY
    }
  },
  brandId: {
    field: 'brandId',

    dataAlign: 'center',
    headerAlign: 'center',
    width: '100px',

    label: 'Brand ID',
    dataSort: true,
    filter: {
      type: 'TextFilter',
      delay: DATA_TABLE.FILTER_DELAY
    }
  },
  brandFullPath: {
    field: 'brandFullPath',

    dataAlign: 'left',
    headerAlign: 'center',
    width: 'auto',

    label: 'Brand full path',
    dataSort: true,
    filter: {
      type: 'TextFilter',
      delay: DATA_TABLE.FILTER_DELAY
    }
  },
  merchantId: {
    field: 'merchantId',

    dataAlign: 'center',
    headerAlign: 'center',
    width: '120px',

    label: 'Merchant ID',
    dataSort: true,
    filter: {
      type: 'TextFilter',
      delay: DATA_TABLE.FILTER_DELAY
    }
  },
  merchantName: {
    field: 'merchantName',

    dataAlign: 'left',
    headerAlign: 'center',
    width: '200px',

    label: 'Merchant',
    dataSort: true,
    filter: {
      type: 'TextFilter',
      delay: DATA_TABLE.FILTER_DELAY
    }
  },
  updatedAt: {
    field: 'updatedAt',

    dataAlign: 'center',
    headerAlign: 'center',
    width: '160px',

    label: 'Updated At',
    dataSort: true,
    dataFormat: (cell, row) => DateCell({ date: cell }),
    filter: {
      type: 'DateFilter',
      dateOnly: true,
      delay: DATA_TABLE.FILTER_DELAY
    }
  },
  updatedByName: {
    field: 'updatedByName',

    dataAlign: 'center',
    headerAlign: 'center',
    width: '170px',

    label: 'Updated By',
    dataSort: true,
    filter: {
      type: 'TextFilter',
      delay: DATA_TABLE.FILTER_DELAY
    }
  },
  isActive: {
    field: 'isActive',

    dataAlign: 'center',
    headerAlign: 'center',
    width: '80px',

    label: 'Active',
    dataSort: true,
    dataFormat: (cell, row) => BooleanCell({ isActive: cell }),
    filter: {
      type: 'SelectFilter',
      options: {
        1: 'True',
        0: 'False'
      }
    }
  },
  categoryIsLeaf: {
    field: 'categoryIsLeaf',

    dataAlign: 'center',
    headerAlign: 'center',
    width: '80px',

    label: 'Is leaf',
    title: 'Is Category a leaf',
    dataSort: true,
    dataFormat: (cell, row) => BooleanCell({ isActive: cell }),
    filter: {
      type: 'SelectFilter',
      options: {
        1: 'True',
        0: 'False'
      }
    }
  },
  includeParam: {
    field: 'includeParam',

    dataAlign: 'center',
    headerAlign: 'center',
    width: '220px',

    label: 'Include Param',
    dataSort: true,
    filter: {
      type: 'TextFilter',
      delay: DATA_TABLE.FILTER_DELAY
    }
  },
  excludeParam: {
    field: 'excludeParam',

    dataAlign: 'center',
    headerAlign: 'center',
    width: '220px',

    label: 'Exclude Param',
    dataSort: true,
    filter: {
      type: 'TextFilter',
      delay: DATA_TABLE.FILTER_DELAY
    }
  },
  ruleType: {
    field: 'ruleType',

    dataAlign: 'center',
    headerAlign: 'center',
    width: '170px',

    label: 'Rule Type',
    dataSort: true,
    filter: {
      type: 'TextFilter',
      delay: DATA_TABLE.FILTER_DELAY
    }
  },
  isExcludeRule: {
    field: 'isExcludeRule',

    dataAlign: 'center',
    headerAlign: 'center',
    width: '90px',

    label: 'Exclude',
    title: 'Is Exclude Rule',
    dataSort: true,
    dataFormat: (cell, row) => BooleanCell({ isActive: cell }),
    filter: {
      type: 'SelectFilter',
      options: {
        1: 'True',
        0: 'False'
      }
    }
  },
  // category-brand-path
  isSameNode: {
    field: 'isSameNode',

    dataAlign: 'center',
    headerAlign: 'center',
    width: '150px',

    label: 'Is Same Node',
    dataSort: true,
    dataFormat: (cell, row) => BooleanCell({ isActive: cell }),
    filter: {
      type: 'SelectFilter',
      options: {
        1: 'True',
        0: 'False'
      }
    }
  },
  rcbBrndCount: {
    field: 'rcbBrndCount',

    dataAlign: 'center',
    headerAlign: 'center',
    width: '150px',

    label: 'Brands count',
    dataSort: true,
    filter: {
      type: 'TextFilter',
      delay: DATA_TABLE.FILTER_DELAY
    }
  },

  actionsColumn: {
    field: 'actionsColumn',
    label: 'Actions',
    dataAlign: 'center',
    headerAlign: 'center',
    width: '70px'
  }
}

export default COMMON_COLUMNS
