export const VALIDATION_BRAND_REGEX = /^[A-Za-z0-9.'&_+\-!()@#,:/\s]+$/i
export const VALIDATION_BRAND_MESSAGE = `Allowed English letters, numbers and symbols: . ' & _ + - ! ( ) @ # , : /`

const VALIDATION_CATEGORY_REGEX = /^[A-Za-z 0-9&\-,.'+]*$/i
export const VALIDATION_CATEGORY_MESSAGE = `Allowed English letters, numbers and symbols: & - , .`

export const isBrandNameValid = name => {
  return VALIDATION_BRAND_REGEX.test(name)
}

export const isCategoryNameValid = name => {
  return VALIDATION_CATEGORY_REGEX.test(name)
}

export const trimName = name => {
  return name.trim().replace(/\s+/g, ' ')
}
