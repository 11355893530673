import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Button, Form, Col, FormGroup, Glyphicon, Alert } from 'react-bootstrap'

import { debounce } from 'lodash'
import InputField, { getElementValue } from './../../FormInputComponents/InputField'
import { DEBOUNCE_ONCHANGE_UI_ACTION_TIMEOUT } from './../../../constants/constants'
import { confirmModal } from './../../../actions/modal-actions'
import { saveFeedModuleQueryAction, removeFeedModuleQueryAction } from './actions'
import QueryHelp from './QueryHelp'

class FeedModuleQueryItem extends Component {
  constructor(props) {
    super(props)
    this.isFormChanged = false
  }

  state = {
    editedQuery: this.props.query,
    errors: {}
  }

  handleFieldChange = typeEvent => {
    const changedField = getElementValue(typeEvent.target)

    this.isFormChanged = true
    this.setState(
      {
        editedQuery: {
          ...this.state.editedQuery,
          ...changedField
        }
      },
      this.validateDebounce
    )
  }

  onRemoveHandler = () => {
    const {
      editedQuery: { id, name }
    } = this.state
    this.removeModal(id, name)
  }

  removeModal = (id, name) => {
    const { confirmModal } = this.props

    confirmModal(
      <div>
        <span>
          {`Are you sure you want to delete Feed Module Query `}
          <strong>
            <em>{name}</em>
          </strong>
          {' ?'}
        </span>
      </div>,
      'Confirm Delete',
      { okButton: `Remove` },
      () => this.props.removeFeedModuleQueryAction({ id })
    )
  }

  onCancelHandler = () => {
    this.isFormChanged = false
    this.setState(
      {
        editedQuery: this.props.query
      },
      this.validate
    )
  }

  onOkHandler = () => {
    const { editedQuery } = this.state

    const errors = this.validate()
    const isValid = !Object.keys(errors).length
    if (isValid) {
      this.props.saveFeedModuleQueryAction(editedQuery)
      this.isFormChanged = false
    }
  }

  validate() {
    const errors = {}

    const { editedQuery } = this.state

    const { queries } = this.props

    // NAME VALIDATION
    // empty validation
    if (!editedQuery.name) {
      errors.name = "Name canno't be empty."
    }

    // check uniq name
    const queriesWithEqualName = queries.filter(q => q.name === editedQuery.name && q.id !== editedQuery.id)
    if (queriesWithEqualName.length > 0) {
      errors.name = `Name should be unique. You should change the name.`
    }

    // SQL VALIDATION
    if (!editedQuery.query) {
      errors.query = "SQL query canno't be empty."
    }

    this.setState({ errors })
    return errors
  }
  validateDebounce = debounce(this.validate, DEBOUNCE_ONCHANGE_UI_ACTION_TIMEOUT)

  render() {
    const { query, isMissed } = this.props
    const { editedQuery, errors } = this.state

    const isNew = !editedQuery.id
    const isValid = !Object.keys(errors).length

    return (
      <Form className="feed-module-query-form" horizontal key={query.name}>
        <InputField
          data-id="name"
          label="Name"
          type="input"
          required
          info="Set this name in the 'queryFile' property of the frequency configuration where you want to use this query. You can use it in one or more frequency configurations."
          value={editedQuery.name || ''}
          validationMessage={errors.name}
          onChange={this.handleFieldChange}
        />
        {isMissed && (
          <Alert bsStyle="warning">
            <Glyphicon glyph="alert" className="feed-module-query-alert-icon" />
            {`There aren't any related "queryFile" properties in Feed Module configuration for this name.`}
          </Alert>
        )}

        <InputField
          data-id="query"
          label="Query"
          type="textarea"
          componentClass="textarea"
          rows={7}
          required
          value={editedQuery.query || ''}
          validationMessage={errors.query}
          onChange={this.handleFieldChange}
          help={<QueryHelp />}
        />
        <FormGroup>
          <Col className="text-center" sm={12}>
            {!isNew && (
              <Button className="btn_custom_secondary" onClick={this.onRemoveHandler}>
                Remove Query
              </Button>
            )}
            <Button className="btn_custom_secondary" onClick={this.onCancelHandler}>
              Discard Changes
            </Button>
            <Button className="btn_custom" onClick={this.onOkHandler} disabled={!(this.isFormChanged && isValid)}>
              {isNew ? 'Create' : 'Save'}
            </Button>
          </Col>
        </FormGroup>
      </Form>
    )
  }
}

FeedModuleQueryItem.defaultProps = {
  query: {},
  queries: [],
  isMissed: false
}

FeedModuleQueryItem.propTypes = {
  query: PropTypes.object.isRequired,
  queries: PropTypes.arrayOf(PropTypes.object),
  isMissed: PropTypes.bool,
  saveFeedModuleQueryAction: PropTypes.func.isRequired,
  removeFeedModuleQueryAction: PropTypes.func.isRequired,
  confirmModal: PropTypes.func.isRequired
}

const mapDispatchToProps = {
  saveFeedModuleQueryAction,
  removeFeedModuleQueryAction,
  confirmModal
}

export default connect(null, mapDispatchToProps)(FeedModuleQueryItem)
