import commonRtkApi from 'commonRtkApi.js'

const brandAPI = commonRtkApi.injectEndpoints({
  endpoints: builder => ({
    brandSuggestions: builder.query({
      query: fullPath => {
        const params = {
          fullPath,
          limit: 5,
          isActive: true
        }

        return { url: `/taxonomy/brands/suggestions`, method: 'GET', params }
      }
    })
  })
})

export const { useBrandSuggestionsQuery } = brandAPI
