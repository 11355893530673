import React, { Component } from 'react'
import PropTypes from 'prop-types'
import MerchantsInput from './../Filter/merchants-input'
import { Field } from 'redux-form'

export default class MerchantsFilter extends Component {
  static propTypes = {
    feed: PropTypes.object
  }

  render() {
    return (
      <div>
        <Field name="merchants" component={MerchantsInput} />
      </div>
    )
  }
}
