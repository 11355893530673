import { useSelector } from 'react-redux'

import { getModalUISettings } from './utils.js'

/**
 * This hook find active rule in the current Rule/Request Modal
 * and get UI settings form the Modal
 */
function useModalUISettings({ prevRuleId, isAllFieldsFilled, fieldsNames, isRuleActiveFunc, mode, isFormChanged }) {
  const { ruleLoading, rule } = useSelector(state => state.rules.modal)

  return getModalUISettings({
    prevRuleId,
    isAllFieldsFilled,
    fieldsNames,
    isRuleActiveFunc,
    mode,
    isFormChanged,
    isLoading: ruleLoading,
    rule
  })
}

export default useModalUISettings
