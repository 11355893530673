import { isEmpty } from 'lodash'
import { TASK_STATUS } from 'constants/constants'

// This function calculate metrics for open page by statistics data value
const metricsSupraSamplingCalc = (currentRows = []) => {
  const defaultObj = {
    count: 0,
    defined_templates_pct: 0,
    dictionary_conflicts: 0,
    defined_templates: 0,
    pct_traffic_100_templates: 0
  }

  const sumStats = currentRows.reduce((acc, el) => {
    if (!el?.statistics) return { ...acc, count: acc.count + 1 }
    const {
      conflicted_templates_cnt,
      pct_traffic_100_templates,
      defined_templates_pct,
      defined_templates_cnt,
      total_templates_cnt
    } = el?.statistics
    return {
      count: acc.count + 1,
      defined_templates_pct: acc.defined_templates_pct + defined_templates_pct,
      dictionary_conflicts: acc.dictionary_conflicts + conflicted_templates_cnt / total_templates_cnt,
      defined_templates: acc.defined_templates + defined_templates_cnt / total_templates_cnt,
      pct_traffic_100_templates: acc.pct_traffic_100_templates + pct_traffic_100_templates
    }
  }, defaultObj)

  return [
    {
      sequence: 1,
      count: sumStats.count,
      defined_templates_pct: `${(sumStats.defined_templates_pct / sumStats.count || 0).toFixed(2)} %`,
      dictionary_conflicts: `${((sumStats.dictionary_conflicts * 100) / sumStats.count || 0).toFixed(2)} %`,
      defined_templates: `${((sumStats.defined_templates * 100) / sumStats.count || 0).toFixed(2)} %`,
      pct_traffic_100_templates: `${(sumStats.pct_traffic_100_templates / sumStats.count || 0).toFixed(2)} %`
    }
  ]
}

const statusOption = {
  PENDING: 'Pending',
  IN_PROGRESS: 'In_Progress',
  ERROR: 'Error',
  ACTIVE: 'Active',
  COMPLETED: 'Completed',
  TIMED_OUT: 'Timed_Out',
  CANCELED: 'Canceled'
}

const lifeCycleStates = {
  PENDING: 'PENDING',
  RUNNING: 'RUNNING',
  TERMINATED: 'TERMINATED',
  INTERNAL_ERROR: 'INTERNAL_ERROR'
}

const getInternalStatus = row => {
  const state = row.state
  if (state.life_cycle_state === 'PENDING') {
    return statusOption.PENDING
  } else if (state.life_cycle_state === 'RUNNING') {
    return statusOption.IN_PROGRESS
  } else if (state.result_state === 'FAILED') {
    return statusOption.ERROR
  } else if (state.life_cycle_state === 'TERMINATED' && state.result_state === 'SUCCESS') {
    // check if the data is loaded to Elasticsearch.
    // If yes then the status should be 'Active'
    // Else the status should be 'Completed'
    if (row.statistics && !isEmpty(row.statistics)) {
      return statusOption.ACTIVE
    }
    return statusOption.COMPLETED
  } else if (state.life_cycle_state === 'TERMINATED' && state.result_state === 'TIMEDOUT') {
    return statusOption.TIMED_OUT
  } else if (state.life_cycle_state === 'TERMINATED' && state.result_state === 'CANCELED') {
    return statusOption.CANCELED
  }
  return `${state.life_cycle_state} / ${state.result_state}`
}

// #formatForInputSelect func works with arr & string formats (['ORDER', 'SHIPPING'], 'WELCOME,PAYMENT')
const formatForInputSelect = types => {
  if (types === '') return []
  let res = types
  if (typeof types === 'string') {
    res = types.split(',')
  }
  return res?.map(type => ({ value: type, label: type })) || []
}

function getStatusMeta(text = 'undefined') {
  let color = ''
  switch (text) {
    case TASK_STATUS.PENDING:
    case TASK_STATUS.PROCESSING:
      color = 'blue'
      break
    case TASK_STATUS.SUCCESS:
      color = 'green'
      break
    case TASK_STATUS.CANCELLED:
      color = 'yellow'
      break
    case TASK_STATUS.FAILED:
      color = 'red'
      break
    default:
      color = 'red'
  }

  return {
    color,
    text
  }
}

export {
  metricsSupraSamplingCalc,
  statusOption,
  getInternalStatus,
  formatForInputSelect,
  lifeCycleStates,
  getStatusMeta
}
