import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import cx from 'classnames'
import './index.scss'

class ProgressPoints extends Component {
  render() {
    const { progressList } = this.props
    const blocks = []
    progressList.forEach(({ tooltip, status }, index) => {
      blocks.push(<Dot key={index} className={status} tooltip={tooltip} />)
      if (index + 1 < progressList.length) {
        blocks.push(<Dash key={index + 0.5} className={status} />)
      }
    })
    return <div className="progress-points">{blocks}</div>
  }
}

ProgressPoints.propTypes = {
  progressList: PropTypes.arrayOf(
    PropTypes.shape({
      status: PropTypes.string,
      tooltip: PropTypes.string
    })
  )
}

export default ProgressPoints

function Dot({ className, tooltip: tooltipText }) {
  const tooltip = <Tooltip id="progress-point">{tooltipText}</Tooltip>
  return (
    <OverlayTrigger placement="top" overlay={tooltip}>
      <div className={cx('dot', className)} />
    </OverlayTrigger>
  )
}

Dot.propTypes = {
  className: PropTypes.string,
  tooltip: PropTypes.string
}

function Dash({ className }) {
  return <div className={cx('dash', className)} />
}

Dash.propTypes = {
  className: PropTypes.string
}
