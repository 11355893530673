import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ChipSelectField from '../../../Common/ChipSelectField'
import { Button, HelpBlock } from 'react-bootstrap'
import Select from 'react-select'

import {
  reportSearch,
  updateReportsForm,
  saveReports,
  resetReportsForm,
  fetchReportTabs
} from '../../../../../actions/delivery-config-actions'

class ReportConfig extends Component {
  reportSearch = searchText => {
    this.props.reportSearch(searchText)
  }

  handleProdReportChange = report => {
    this.props.updateReportsForm({
      prodReport: report,
      dirty: true
    })
  }

  handleQCReportChange = report => {
    const updateObj = {
      qcReport: report,
      dirty: true
    }
    if (!report) {
      updateObj.qcTabId = null
    }
    this.props.updateReportsForm(updateObj)
    if (report && report.id) {
      this.props.fetchReportTabs(report.id)
    }
  }

  handleQCTabChange = ev => {
    this.props.updateReportsForm({
      qcTabId: ev.value,
      dirty: true
    })
  }

  saveHandler = () => {
    const { onComplete } = this.props
    this.saveReportsForm(onComplete)
  }

  saveAndNextHandler = () => {
    const { onComplete, onNext } = this.props
    if (this.hasValidChangesToSave()) {
      const callback = () => {
        onComplete()
        onNext()
      }
      this.saveReportsForm(callback)
    } else {
      onNext()
    }
  }

  saveReportsForm = callback => {
    const { prodReport, qcReport, saveReports, deliverableId, qcTabId } = this.props
    saveReports(
      {
        deliverableId,
        prodReportId: prodReport.id,
        qcReportId: qcReport ? qcReport.id : null,
        qcTabId
      },
      callback
    )
  }

  hasValidChangesToSave = () => {
    const { dirty, prodReport } = this.props
    return dirty && prodReport && prodReport.id
  }

  renderActions = () => {
    const { editable, dirty, resetReportsForm } = this.props
    return (
      <div className="sb-form-footer">
        <div className="form-action-buttons">
          {editable && (
            <React.Fragment>
              <Button className="btn-cancel btn-small" disabled={!dirty} onClick={resetReportsForm}>
                Cancel
              </Button>
              <Button
                disabled={!this.hasValidChangesToSave()}
                className="btn_custom_secondary btn-small"
                onClick={this.saveHandler}
              >
                {'Save'}
              </Button>
            </React.Fragment>
          )}
          <Button className="btn_custom btn-small" onClick={this.saveAndNextHandler}>
            Next
          </Button>
        </div>
      </div>
    )
  }

  render() {
    const { prodReport, qcReport, qcTabId, qcReportTabs, reportSuggestions, dirty, editable } = this.props
    // Converting data for <Select /> input
    const qcReportTabsMod = qcReportTabs.map(tab => ({ label: tab.name, value: tab.id }))
    return (
      <div className="step-form report-config-form sb-form">
        <ChipSelectField
          id="prod-report"
          label="Select a PROD report"
          required
          selectedValue={prodReport}
          onSelectionChange={this.handleProdReportChange}
          labelKey="name"
          onSearchChange={search => {
            this.reportSearch(search)
          }}
          searchSuggestions={reportSuggestions}
          help={dirty && !prodReport ? 'PROD report cannot be empty' : null}
          validationState={dirty && !prodReport ? 'error' : null}
          placeholder="Select Report"
          readOnly={!editable}
        />
        <ChipSelectField
          id="qc-report"
          label="Select a QC report (optional)"
          selectedValue={qcReport}
          onSelectionChange={this.handleQCReportChange}
          labelKey="name"
          onSearchChange={search => {
            this.reportSearch(search)
          }}
          searchSuggestions={reportSuggestions}
          placeholder="Select Report"
          readOnly={!editable}
        />
        {qcReport ? (
          <Select
            placeholder={'Select the QC dashboard tab...'}
            onChange={this.handleQCTabChange}
            value={qcReportTabsMod.find(el => el.value === qcTabId)}
            options={qcReportTabsMod}
            disabled={!editable}
          />
        ) : null}
        <HelpBlock>
          <br />
          * After linking the Report(s), the filters will be refreshed automatically
          <br />* The tab selected for the QC Report will automatically link the previous prod non-excluded view to its
          table reference field
        </HelpBlock>
        {this.renderActions()}
      </div>
    )
  }
}

ReportConfig.propTypes = {
  deliverableId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  prodReport: PropTypes.object,
  qcReport: PropTypes.object,
  qcTabId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), // todo: fix this
  qcReportTabs: PropTypes.array,
  reportSuggestions: PropTypes.array,
  editable: PropTypes.bool,
  dirty: PropTypes.bool,
  onComplete: PropTypes.func,
  onNext: PropTypes.func,
  saveReports: PropTypes.func,
  updateReportsForm: PropTypes.func,
  resetReportsForm: PropTypes.func,
  reportSearch: PropTypes.func,
  fetchReportTabs: PropTypes.func
}

function mapStateToProps({ dm: { selectedDeliverable: { id: deliverableId } = {} }, deliveryConfig: { reportsForm } }) {
  return {
    deliverableId,
    ...reportsForm
  }
}
export default connect(mapStateToProps, {
  reportSearch,
  updateReportsForm,
  resetReportsForm,
  saveReports,
  fetchReportTabs
})(ReportConfig)
