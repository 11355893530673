import React, { Component } from 'react'
import PropTypes from 'prop-types'
import constants from '../../../constants/constants'
import StockChartComponent from './StockChartComponent'
import cx from 'classnames'

const aggregations = constants.aggregations

class DrilldownChart extends Component {
  shouldComponentUpdate(nextProps) {
    return (
      this.props.data !== nextProps.data ||
      this.props.chartView !== nextProps.chartView ||
      this.props.splitByData !== nextProps.splitByData ||
      this.props.drilldownEnabledStatus !== nextProps.drilldownEnabledStatus
    )
  }

  getData(currentKey, lastKey) {
    const data = {
      currentSeries: [],
      lastSeries: []
    }
    if (this.props.data[currentKey]) {
      data.currentSeries =
        this.props.drillDownBy && this.props.drillDownBy.name !== 'none'
          ? this.props.data[currentKey][this.props.drillDownBy.name]
          : this.props.data[currentKey][constants.esFields[currentKey] || currentKey]
    }
    if (lastKey && this.props.data[lastKey]) {
      data.lastSeries =
        this.props.drillDownBy && this.props.drillDownBy.name !== 'none'
          ? this.props.data[lastKey][this.props.drillDownBy.name]
          : this.props.data[lastKey][constants.esFields[lastKey] || lastKey]
    }
    return data
  }

  refreshQCGraphData = payload => {
    const refreshPayload = {
      ...payload,
      ...{ componentType: 'drilldown' }
    }
    this.props.refreshQCGraphData(refreshPayload)
  }

  getLoadingStatus = (current, last) => {
    const { data, drillDownBy } = this.props
    if (!data || !drillDownBy) {
      return false
    }

    // Convert into the format for drillDownBy none queries
    const currentDrillDownName = drillDownBy.name === 'none' ? current : drillDownBy.name
    const lastDrillDownName = drillDownBy.name === 'none' ? last : drillDownBy.name

    const currentData = data[current] ? data[current][currentDrillDownName] : []
    const previousData = data[last] ? data[last][lastDrillDownName] : []

    if (currentData !== null && previousData !== null) {
      return false
    } else if (currentData === null || previousData === null) {
      return true
    } else {
      return false
    }
  }

  onSelectedTimeRangeChanged = (min, max) => {
    this.props.onSelectedTimeRangeChanged({ from: min, to: max })
  }

  render() {
    const { drillDownBy, chartView, splitByData, drilldownEnabledStatus } = this.props

    if (!splitByData || !splitByData.revenue) {
      return <div />
    }

    return (
      <div>
        <div
          className={cx('drilldown-chart-container', {
            'disable-drilldown': !drilldownEnabledStatus[aggregations.REVENUE]
          })}
        >
          <StockChartComponent
            drillDownBy={drillDownBy}
            chartView={chartView}
            chartKey={aggregations.REVENUE}
            data={this.getData(aggregations.REVENUE, aggregations.LAST_REVENUE)}
            onSelectedTimeRangeChanged={this.onSelectedTimeRangeChanged}
            loading={this.getLoadingStatus(aggregations.REVENUE, aggregations.LAST_REVENUE)}
            showStatusButton
            enabled={drilldownEnabledStatus[aggregations.REVENUE]}
            onChangeEnabledStatus={this.props.onChangeDrilldownEnabledStatus}
            refreshQCGraphData={this.refreshQCGraphData}
          />
        </div>
        <div
          className={cx('drilldown-chart-container', {
            'disable-drilldown': !drilldownEnabledStatus[aggregations.ITEM_COUNT]
          })}
        >
          <StockChartComponent
            drillDownBy={drillDownBy}
            chartView={chartView}
            chartKey={aggregations.REVENUE}
            data={this.getData(aggregations.REVENUE, aggregations.LAST_REVENUE)}
            isCountRequired
            onSelectedTimeRangeChanged={this.onSelectedTimeRangeChanged}
            loading={this.getLoadingStatus(aggregations.REVENUE, aggregations.LAST_REVENUE)}
            showStatusButton
            enabled={drilldownEnabledStatus[aggregations.ITEM_COUNT]}
            onChangeEnabledStatus={this.props.onChangeDrilldownEnabledStatus}
            refreshQCGraphData={this.refreshQCGraphData}
          />
        </div>
        <div
          className={cx('drilldown-chart-container', {
            'disable-drilldown': !drilldownEnabledStatus[aggregations.WEIGHT]
          })}
        >
          <StockChartComponent
            drillDownBy={drillDownBy}
            chartView={chartView}
            chartKey={aggregations.WEIGHT}
            data={this.getData(aggregations.WEIGHT, aggregations.LAST_WEIGHT)}
            onSelectedTimeRangeChanged={this.onSelectedTimeRangeChanged}
            loading={this.getLoadingStatus(aggregations.WEIGHT, aggregations.LAST_WEIGHT)}
            showStatusButton
            enabled={drilldownEnabledStatus[aggregations.WEIGHT]}
            onChangeEnabledStatus={this.props.onChangeDrilldownEnabledStatus}
            refreshQCGraphData={this.refreshQCGraphData}
          />
        </div>
        <div
          className={cx('drilldown-chart-container', {
            'disable-drilldown': !drilldownEnabledStatus[aggregations.FACTOR]
          })}
        >
          <StockChartComponent
            drillDownBy={drillDownBy}
            chartView={chartView}
            chartKey={aggregations.FACTOR}
            data={this.getData(aggregations.FACTOR, aggregations.LAST_FACTOR)}
            onSelectedTimeRangeChanged={this.onSelectedTimeRangeChanged}
            loading={this.getLoadingStatus(aggregations.FACTOR, aggregations.LAST_FACTOR)}
            showStatusButton
            enabled={drilldownEnabledStatus[aggregations.FACTOR]}
            onChangeEnabledStatus={this.props.onChangeDrilldownEnabledStatus}
            refreshQCGraphData={this.refreshQCGraphData}
          />
        </div>
        <div
          className={cx('drilldown-chart-container', {
            'disable-drilldown': !drilldownEnabledStatus[aggregations.PRICE]
          })}
        >
          <StockChartComponent
            drillDownBy={drillDownBy}
            chartView={chartView}
            chartKey={aggregations.PRICE}
            data={this.getData(aggregations.PRICE, aggregations.LAST_PRICE)}
            onSelectedTimeRangeChanged={this.onSelectedTimeRangeChanged}
            loading={this.getLoadingStatus(aggregations.PRICE, aggregations.LAST_PRICE)}
            showStatusButton
            enabled={drilldownEnabledStatus[aggregations.PRICE]}
            onChangeEnabledStatus={this.props.onChangeDrilldownEnabledStatus}
            refreshQCGraphData={this.refreshQCGraphData}
          />
        </div>
        <div
          className={cx('drilldown-chart-container', {
            'disable-drilldown': !drilldownEnabledStatus[aggregations.QUANTITY]
          })}
        >
          <StockChartComponent
            drillDownBy={drillDownBy}
            chartView={chartView}
            chartKey={aggregations.QUANTITY}
            data={this.getData(aggregations.QUANTITY, aggregations.LAST_QUANTITY)}
            onSelectedTimeRangeChanged={this.onSelectedTimeRangeChanged}
            loading={this.getLoadingStatus(aggregations.QUANTITY, aggregations.LAST_QUANTITY)}
            showStatusButton
            enabled={drilldownEnabledStatus[aggregations.QUANTITY]}
            onChangeEnabledStatus={this.props.onChangeDrilldownEnabledStatus}
            refreshQCGraphData={this.refreshQCGraphData}
          />
        </div>
        <div
          className={cx('drilldown-chart-container', {
            'disable-drilldown': !drilldownEnabledStatus[aggregations.UNITS]
          })}
        >
          <StockChartComponent
            drillDownBy={drillDownBy}
            chartView={chartView}
            chartKey={aggregations.UNITS}
            data={this.getData(aggregations.UNITS, aggregations.LAST_UNITS)}
            onSelectedTimeRangeChanged={this.onSelectedTimeRangeChanged}
            loading={this.getLoadingStatus(aggregations.UNITS, aggregations.LAST_UNITS)}
            showStatusButton
            enabled={drilldownEnabledStatus[aggregations.UNITS]}
            onChangeEnabledStatus={this.props.onChangeDrilldownEnabledStatus}
            refreshQCGraphData={this.refreshQCGraphData}
          />
        </div>
        <div
          className={cx('drilldown-chart-container', {
            'disable-drilldown': !drilldownEnabledStatus[aggregations.USER_ID]
          })}
        >
          <StockChartComponent
            drillDownBy={drillDownBy}
            chartView={chartView}
            chartKey={aggregations.USER_ID}
            data={this.getData(aggregations.USER_ID, aggregations.LAST_USER_ID)}
            onSelectedTimeRangeChanged={this.onSelectedTimeRangeChanged}
            loading={this.getLoadingStatus(aggregations.USER_ID, aggregations.LAST_USER_ID)}
            showStatusButton
            enabled={drilldownEnabledStatus[aggregations.USER_ID]}
            onChangeEnabledStatus={this.props.onChangeDrilldownEnabledStatus}
            refreshQCGraphData={this.refreshQCGraphData}
          />
        </div>
      </div>
    )
  }
}

DrilldownChart.propTypes = {
  chartView: PropTypes.string,
  drillDownBy: PropTypes.object,
  data: PropTypes.object,
  title: PropTypes.string,
  aggregation: PropTypes.arrayOf(PropTypes.object),
  height: PropTypes.number,
  width: PropTypes.number,
  onSelectedTimeRangeChanged: PropTypes.func,
  splitByData: PropTypes.object,
  drilldownEnabledStatus: PropTypes.object,
  onChangeDrilldownEnabledStatus: PropTypes.func,
  refreshQCGraphData: PropTypes.func
}

DrilldownChart.defaultProps = {
  height: 250
}

export default DrilldownChart
