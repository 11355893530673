import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import { default as constants, permissions as PERMISSIONS } from '../../../constants/constants'
import { connect } from 'react-redux'
import {
  onDescriptionSelectionChanged,
  refreshDescriptionData,
  onChangeQcColumnsVisibility,
  onChangeDescriptionHistoryStatus
} from '../../../actions/qc-actions'
import {
  onBrandEditSelect,
  onCategoryEditSelect,
  onBrandSuggest,
  onCategorySuggest,
  onToggleDescriptionEdit,
  onToggleSearchLockData,
  onBulkStageDictionaryEdits,
  onBulkUnstageDictionaryEdits,
  onSaveQCDictionaryEntries,
  onToggleToolbarOption,
  onTagForEdit,
  refreshQCDescriptionEditStatus,
  toggleQCDescriptionSearchStatus,
  fetchQCDescriptionEditSuggestions
} from '../../../actions/widget-actions'
import 'react-bootstrap-table/dist/react-bootstrap-table.min.css'
import '../../../styles/descriptionTable.scss'
import Loader from '../../Loader'
import ColumnFilter from './../widgets/ColumnFilter'
import DescriptionTableOptions from '../widgets/DescriptionTableOptions'
import DescriptionTitleValue from '../widgets/descriptionTableCells/DescriptionTitleValue'
import LargeNumValue from '../widgets/descriptionTableCells/LargeNumValue'
import EditableDesciptionCell from '../widgets/descriptionTableCells/EditableDesciptionCell'
import InEditValue from '../widgets/descriptionTableCells/InEditValue'
import DescriptionToolbar from '../widgets/description-toolbar'
import { defaultPaginationOptions, qcFields } from '../../../constants/DescriptionTableConfig'
import UserService from '../../../services/user-service'
import ReactInterval from 'react-interval'
import moment from 'moment'
import { isEmpty, findIndex } from 'lodash'

const getExportFileName = () => {
  const date = new Date()
  return (
    'qc_descriptions_' +
    `${date.getFullYear()}_${date.getMonth()}_${date.getDate()}_` +
    `${date.getHours()}_${date.getMinutes()}_${date.getSeconds()}` +
    '.csv'
  )
}

function mapStateToProps(state) {
  return {
    ...state.searchQC[constants.aggregations.DESCRIPTION_WITH_HISTORY],
    from: state.searchQC.from,
    to: state.searchQC.to,
    loading: state.searchQC.descriptionWithHistory.loading,
    totalDescriptionsCount: state.searchEdit?.stats?.queried_description_count?.count || 0,
    columns: state.searchQC.descriptionWithHistory.columns,
    showToolbar: state.searchEdit.description.showToolbar,
    canEdit:
      UserService.isPortalAdmin(state.session.user) ||
      UserService.hasPermission(state.session.user, PERMISSIONS.adminToolsQCToolEdit),
    inEdit: state.searchEdit.description.inEdit,
    edits: state.searchEdit.description.edits,
    stagedEdits: state.searchEdit.description.stagedEdits,
    drillDownBy: state.searchQC.drillDownBy,
    descriptionItemType: state.searchQC.descriptionItemType,
    isDescriptionEnable: state.searchQC.isDescriptionEnable,
    splitByData: state.searchQC.splitByChart.data,
    showQCDescSearch: state.searchQC.showQCDescSearch
  }
}

class QCDescription extends Component {
  createValueEditor = (onUpdate, props) => {
    if (
      (props.attribute === 'brand' && !props.row.is_brand_editable && props.row.in_current && props.row.brand_name) ||
      (props.attribute === 'category' &&
        !props.row.is_category_editable &&
        props.row.in_current &&
        props.row.category_full_path)
    ) {
      return (
        <EditableDesciptionCell
          ref={this.inputRef} // needed by BootstrapTable. Please do not remove this.
          cell={props.attribute === 'brand' ? props.row.brand_name : props.row.category_full_path}
          dictKey={props.row.dictionary_key}
          inDict={props.attribute === 'brand' ? props.row.in_dict_brand : props.row.in_dict_category_fp}
          attribute={props.attribute}
        />
      )
    }
    return <InEditValue onUpdate={onUpdate} {...props} />
  }

  buildColumns() {
    const { descriptionItemType } = this.props
    this.unselectableRows = []
    const calc_RevenueDifference = row => Math.round(parseFloat(row.revenue, 10) - parseFloat(row.last_revenue, 10))
    const getCustomFilter = (filterHandler, { delay, placeholder }) => (
      <ColumnFilter filterHandler={filterHandler} delay={delay} placeholder={placeholder} />
    )
    const getColumn = column => {
      const width = ''
      const dataFormat = cell => {
        return cell
      }
      switch (column) {
        case 'sequence':
          return {
            label: '#',
            field: column,
            width: '30',
            dataAlign: 'center',
            csvFormat: cell => cell,
            dataFormat: cell => cell,
            thStyle: { verticalAlign: 'top' },
            hasSort: true
          }
        case 'count':
          return {
            label: 'Count',
            field: column,
            dataAlign: 'center',
            dataFormat: cell => <LargeNumValue value={parseFloat(cell)} />,
            width: '70',
            thStyle: { verticalAlign: 'top' },
            hasSort: true
          }
        case 'revenue':
          return {
            label: 'Revenue',
            field: column,
            dataAlign: 'center',
            dataFormat: (cell, row) => {
              const revenue = descriptionItemType === 'Deleted' ? row.last_revenue : cell
              return <LargeNumValue value={parseFloat(revenue)} prefix="$" />
            },
            width: '75',
            thStyle: { verticalAlign: 'top' },
            hasSort: true,
            sortFunc: (firstInput, secondInput, order) => {
              const inputValue = descriptionItemType === 'Deleted' ? 'last_revenue' : 'revenue'
              if (order === 'desc') {
                return parseFloat(secondInput[inputValue]) - parseFloat(firstInput[inputValue])
              } else {
                return parseFloat(firstInput[inputValue]) - parseFloat(secondInput[inputValue])
              }
            }
          }
        case 'last_revenue':
          return {
            label: 'Prev. Revenue',
            field: column,
            dataAlign: 'center',
            dataFormat: cell => <LargeNumValue value={parseFloat(cell)} prefix="$" />,
            width: '50',
            thStyle: { verticalAlign: 'top' },
            hasSort: true,
            sortFunc: (firstInput, secondInput, order) => {
              if (order === 'desc') {
                return parseFloat(secondInput.last_revenue) - parseFloat(firstInput.last_revenue)
              } else {
                return parseFloat(firstInput.last_revenue) - parseFloat(secondInput.last_revenue)
              }
            }
          }
        case 'revenue_diff':
          return {
            label: 'Rev. Difference',
            field: column,
            thStyle: { verticalAlign: 'top' },
            dataAlign: 'right',
            csvFormat: (cell, row) => calc_RevenueDifference(row),
            dataFormat: (cell, row) => {
              const diff = calc_RevenueDifference(row)
              // eslint-disable-next-line
              const prefix = `${diff === 0 ? '$' : diff > 0 ? '+$ ' : '-$'}`
              return <LargeNumValue value={Math.abs(diff)} prefix={prefix} />
            },
            width: '80',
            hasSort: true,
            sortFunc: (firstInput, secondInput, order) => {
              const a_val = calc_RevenueDifference(firstInput)
              const b_val = calc_RevenueDifference(secondInput)
              if (order === 'desc') {
                return b_val - a_val
              } else {
                return a_val - b_val
              }
            }
          }
        case 'description':
          return {
            width: '250',
            label: 'Description',
            field: column,
            hasSort: true,
            thStyle: { verticalAlign: 'top' },
            filter: {
              type: 'CustomFilter',
              getElement: getCustomFilter,
              customFilterParameters: {
                placeholder: `${descriptionItemType === 'Deleted' ? 'Last Desc Search' : 'Desc Search'}`
              }
            },
            dataFormat: (cell, row) => (
              <DescriptionTitleValue
                descriptionValue={descriptionItemType === 'Deleted' ? row.last_description : cell}
                merchantName={row.merchant_name ? row.merchant_name : ''}
              />
            )
          }
        case 'merchant_name':
          return {
            width: '120',
            label: 'Merchant',
            field: column,
            thStyle: { verticalAlign: 'top' },
            filter: {
              type: 'CustomFilter',
              getElement: getCustomFilter,
              customFilterParameters: {
                placeholder: 'Merchant Search'
              }
            },
            hasSort: false
          }
        case 'category_full_path':
          return {
            label: 'Curr. Category',
            field: column,
            hasSort: false,
            thStyle: { verticalAlign: 'top' },
            width: '150',
            filter: {
              type: 'CustomFilter',
              getElement: getCustomFilter,
              customFilterParameters: {
                placeholder: 'Category Search'
              }
            },
            dataFormat: (cell, row) => (
              <EditableDesciptionCell
                cell={cell}
                dictKey={row.dictionary_key}
                updatedBy={row.category_added_by}
                inDict={row.in_dict_category_fp}
                attribute={'category'}
              />
            ),
            customEditor: {
              getElement: this.createValueEditor,
              customEditorParameters: { attribute: 'category' }
            },
            editColumnClassName: (cell, row) => {
              return row.is_category_editable && row.in_current && row.category_full_path !== undefined
                ? 'in-edit qc-editable'
                : 'not-editable'
            },
            columnClassName: (cell, row) => {
              return row.is_category_editable && row.in_current && row.category_full_path !== undefined
                ? ''
                : 'not-editable'
            },
            editable: (cell, row) => {
              return row.is_category_editable && row.in_current && row.category_full_path !== undefined
            }
          }
        case 'last_category_full_path':
          return {
            label: 'Prev. Category',
            field: column,
            hasSort: false,
            filter: {
              type: 'CustomFilter',
              getElement: getCustomFilter,
              customFilterParameters: {
                placeholder: 'Last Category Search'
              }
            },
            thStyle: { verticalAlign: 'top' },
            width: descriptionItemType === 'Deleted' ? '100' : '150'
          }
        case 'brand_name':
          return {
            width: '150',
            label: 'Curr. Brand',
            field: column,
            filter: {
              type: 'CustomFilter',
              getElement: getCustomFilter,
              customFilterParameters: {
                placeholder: 'Brand Search'
              }
            },
            thStyle: { verticalAlign: 'top' },
            hasSort: false,
            dataFormat: (cell, row) => {
              if (row && !row.is_brand_editable && !row.is_category_editable) {
                this.unselectableRows.push(row.sequence)
              }
              return (
                <EditableDesciptionCell
                  cell={cell}
                  dictKey={row.dictionary_key}
                  updatedBy={row.brand_added_by}
                  inDict={row.in_dict_brand}
                  attribute={'brand'}
                />
              )
            },
            customEditor: {
              getElement: this.createValueEditor,
              customEditorParameters: { attribute: 'brand' }
            },
            editColumnClassName: (cell, row) => {
              return row.is_brand_editable && row.in_current && row.brand_name !== undefined
                ? 'in-edit qc-editable'
                : 'not-editable'
            },
            columnClassName: (cell, row) => {
              return row.is_brand_editable && row.in_current && row.brand_name !== undefined ? '' : 'not-editable'
            },
            editable: (cell, row) => {
              return row.is_brand_editable && row.in_current && row.brand_name !== undefined
            }
          }
        case 'last_brand_name':
          return {
            width: descriptionItemType === 'Deleted' ? '100' : '150',
            label: 'Prev. Brand',
            field: column,
            thStyle: { verticalAlign: 'top' },
            filter: {
              type: 'CustomFilter',
              getElement: getCustomFilter,
              customFilterParameters: {
                placeholder: 'Last Brand Search'
              }
            },
            hasSort: false
          }
        default: {
          break
        }
      }

      return {
        label: constants.fields[column].label,
        field: column,
        dataFormat,
        width,
        hasSort: true
      }
    }

    const columnFields = qcFields.map(field => field.value)
    const fields = ['sequence', ...columnFields]
    const columns = fields.map(field => getColumn(field))
    const headerColumns = columns.map((col, index) => {
      return (
        <TableHeaderColumn
          dataField={col.field}
          hidden={col.field !== 'sequence' && this.props.columns && this.props.columns.indexOf(col.field) === -1}
          isKey={col.field === 'sequence'}
          key={`col-${index}`}
          thStyle={col.thStyle}
          dataSort={col.hasSort}
          dataAlign={col.dataAlign}
          dataFormat={col.dataFormat}
          sortFunc={col.sortFunc}
          ref={el => this.tableHeaderRefs(el)}
          width={col.width}
          csvFormat={col.csvFormat}
          csvFormatExtraData={col.csvFormatExtraData}
          customEditor={col.customEditor}
          editable={!!col.editable}
          editColumnClassName={col.editColumnClassName}
          columnClassName={col.columnClassName}
          filter={this.props.showQCDescSearch ? col.filter : {}}
        >
          {col.label}
        </TableHeaderColumn>
      )
    })
    return headerColumns
  }

  tableHeaderRefs = el => {
    if (!el) {
      return
    }
    if (!this.columnRefs) {
      this.columnRefs = {}
    }
    this.columnRefs[el.props.dataField] = el
  }

  toggleQCDescriptionSearchStatus = () => {
    for (const ref in this.columnRefs) {
      this.columnRefs[ref].cleanFiltered()
    }
    this.props.toggleQCDescriptionSearchStatus()
  }

  createField(fieldName, sortType, sortOrder, size, siblingAggs) {
    const field = {}
    field.fieldName = fieldName
    field.orderType = sortType
    field.sort = sortOrder
    field.size = size || 10
    field.siblingAggs = siblingAggs
    return field
  }

  handleExportCSV = () => {
    // @todo TODO: Refactor currentCumulativeRevenue
    // create a helper utility that is outside the table to avoid setting global variable
    if (this.qcDescriptionTable) {
      this.qcDescriptionTable.handleExportCSV()
    }
  }

  onSelectRow = (row, isSelected) => {
    this.tagRows([this.convertRow(row)], isSelected)
  }

  tagRows(rows, isSelected) {
    this.props.onTagForEdit(rows, isSelected)
  }

  convertRow(row) {
    return {
      id: [row.merchant_id.toString(), row.norm_description].join(''),
      merchantId: row.merchant_id,
      normDescription: row.norm_description,
      description: row.description,
      brand_id: row.brand_id,
      brand_full_path: row.brand_name,
      category_id: row.category_id,
      category_full_path: row.category_full_path,
      inDictBrand: row.in_dict_brand,
      inStagingDictBrand: row.in_staging_dict_brand,
      inDictCategory: row.in_dict_category_fp,
      inStagingDictCategory: row.in_staging_dict_category_fp,
      isBrandEditable: row.is_brand_editable,
      isCategoryEditable: row.is_category_editable
    }
  }

  onSelectAllRows = isSelected => {
    if (this.qcDescriptionTable && this.qcDescriptionTable.getTableDataIgnorePaging) {
      const data = this.qcDescriptionTable.getTableData().map(this.convertRow)
      this.tagRows(data, isSelected)
    }
  }

  getOptions = () => {
    const paginationOptions = { ...defaultPaginationOptions }
    paginationOptions.hideSizePerPage = !(this.props.data.length > 0)
    paginationOptions.paginationShowsTotal = this.props.data.length > 0
    return {
      defaultSortName: 'sequence', // default sort column name
      defaultSortOrder: 'asc', // default sort order
      noDataText: (
        <div className="no-data-msg" style={{ fontSize: '18px' }}>
          {this.props.loading ? <Loader /> : 'No results'}
        </div>
      ),
      ...paginationOptions
    }
  }

  getSelectedRowKeys = () => {
    return this.props.edits.rows.map(row => {
      let index = findIndex(this.props.data, ['dictionary_key', row.id])
      return ++index
    })
  }

  selectRow = () => {
    return this.props.inEdit
      ? {
          mode: 'checkbox',
          selected: this.getSelectedRowKeys(),
          clickToSelect: false,
          onSelect: this.onSelectRow,
          onSelectAll: this.onSelectAllRows,
          unselectable: this.unselectableRows
        }
      : {
          mode: 'checkbox',
          selected: [],
          hideSelectColumn: true
        }
  }

  onSelectAll = () => this.onSelectAllRows(true, true)

  render() {
    if (isEmpty(this.props.splitByData.revenue) && isEmpty(this.props.splitByData.last_revenue)) {
      return <div />
    }

    if (!this.props.data) return null
    const columns = this.buildColumns()

    let sequence = 1
    return (
      <div style={{ height: '100%' }}>
        <div className={'description-table-actions qc-edit'}>
          <DescriptionToolbar
            data={this.props.data}
            loading={this.props.loading}
            totalDescriptionsCount={this.props.totalDescriptionsCount}
            inEdit={this.props.canEdit ? this.props.inEdit : false}
            edits={this.props.edits}
            stagedEdits={this.props.stagedEdits}
            selectedSize={this.props.selectedSize}
            onBrandSelect={this.props.onBrandEditSelect}
            onCategorySelect={this.props.onCategoryEditSelect}
            onBrandSuggest={this.props.onBrandSuggest}
            onCategorySuggest={this.props.onCategorySuggest}
            onToggleDescriptionEdit={this.props.onToggleDescriptionEdit}
            onToggleSearchLockData={this.props.onToggleSearchLockData}
            onBulkStageDictionaryEdits={this.props.onBulkStageDictionaryEdits}
            onBulkUnstageDictionaryEdits={this.props.onBulkUnstageDictionaryEdits}
            onSaveDictionaryEntries={this.props.onSaveQCDictionaryEntries}
            onExport={this.handleExportCSV}
            onToggleToolbarOption={this.props.onToggleToolbarOption}
            toolbarOptionActive={this.props.showToolbar}
            canEdit={this.props.canEdit}
            tableStatus={this.props.isDescriptionEnable}
            onChangeStatus={this.props.onChangeDescriptionHistoryStatus}
            refreshDescriptionData={this.props.refreshDescriptionData}
            toggleSearchStatus={this.toggleQCDescriptionSearchStatus}
            showSearchIcon={this.props.showQCDescSearch}
            descriptionEditSuggestions={this.props.fetchQCDescriptionEditSuggestions}
            onSelectAll={() => this.onSelectAll()}
          />
        </div>
        <div className={`description-table-container ${this.props.loading ? 'loading' : ''}`}>
          {
            <DescriptionTableOptions
              onDescriptionSelectionChanged={this.props.onDescriptionSelectionChanged}
              selectedSize={this.props.selectedSize}
              sortBy={this.props.sortBy}
              refreshDescriptionData={this.props.refreshDescriptionData}
              from={moment(this.props.from)
                .utc()
                .format(constants.dateFormat.qcTool)}
              to={moment(this.props.to)
                .utc()
                .format(constants.dateFormat.qcTool)}
              columnList={qcFields}
              visibleColumns={this.props.columns}
              handleSelectChange={this.props.onChangeQcColumnsVisibility}
              showToolbar={this.props.showToolbar}
            />
          }
          <BootstrapTable
            className={'react-bootstrap-table'}
            ref={el => {
              this.qcDescriptionTable = el
            }}
            bordered={false}
            data={Array.isArray(this.props.data) ? this.props.data.map(row => ({ ...row, sequence: sequence++ })) : []}
            options={this.getOptions()}
            selectRow={this.selectRow()}
            pagination
            striped
            hover
            condensed
            responsive
            cellEdit={
              this.props.canEdit
                ? {
                    mode: 'click',
                    blurToSave: true
                  }
                : {}
            }
            csvFileName={getExportFileName()}
          >
            {columns}
          </BootstrapTable>
        </div>
        {this.props.data && this.props.data.length > 0 && (
          <ReactInterval timeout={300000} enabled callback={() => this.props.refreshQCDescriptionEditStatus()} />
        )}
      </div>
    )
  }
}

QCDescription.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  totalDescriptionsCount: PropTypes.number,
  onTableSort: PropTypes.func,
  loading: PropTypes.bool,
  selectedSize: PropTypes.number,
  sortBy: PropTypes.string,
  onDescriptionSelectionChanged: PropTypes.func,
  refreshDescriptionData: PropTypes.func,
  from: PropTypes.number,
  to: PropTypes.number,
  columns: PropTypes.array,
  showToolbar: PropTypes.bool,
  onChangeQcColumnsVisibility: PropTypes.func,
  canEdit: PropTypes.bool,
  inEdit: PropTypes.bool,
  edits: PropTypes.object,
  stagedEdits: PropTypes.object,
  onBrandEditSelect: PropTypes.func,
  onCategoryEditSelect: PropTypes.func,
  onBrandSuggest: PropTypes.func,
  onCategorySuggest: PropTypes.func,
  onToggleDescriptionEdit: PropTypes.func,
  onToggleSearchLockData: PropTypes.func,
  onBulkStageDictionaryEdits: PropTypes.func,
  onBulkUnstageDictionaryEdits: PropTypes.func,
  onSaveQCDictionaryEntries: PropTypes.func,
  onToggleToolbarOption: PropTypes.func,
  onTagForEdit: PropTypes.func,
  refreshQCDescriptionEditStatus: PropTypes.func,
  drillDownBy: PropTypes.object,
  descriptionItemType: PropTypes.string,
  isDescriptionEnable: PropTypes.bool,
  onChangeDescriptionHistoryStatus: PropTypes.func,
  splitByData: PropTypes.object,
  toggleQCDescriptionSearchStatus: PropTypes.func,
  showQCDescSearch: PropTypes.bool,
  fetchQCDescriptionEditSuggestions: PropTypes.func
}

export default connect(mapStateToProps, {
  onDescriptionSelectionChanged,
  refreshDescriptionData,
  onChangeQcColumnsVisibility,
  onBrandEditSelect,
  onCategoryEditSelect,
  onBrandSuggest,
  onCategorySuggest,
  onToggleDescriptionEdit,
  onToggleSearchLockData,
  onBulkStageDictionaryEdits,
  onBulkUnstageDictionaryEdits,
  onSaveQCDictionaryEntries,
  onToggleToolbarOption,
  onTagForEdit,
  refreshQCDescriptionEditStatus,
  onChangeDescriptionHistoryStatus,
  toggleQCDescriptionSearchStatus,
  fetchQCDescriptionEditSuggestions
})(QCDescription)
