import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { Button, Alert, Glyphicon } from 'react-bootstrap'
import Loader from '../../Loader'

import { clearModals } from '../../../actions/modal-actions'
import { fetchDeliverablesByCategory } from '../../../actions/taxonomy-actions'
import DeliverableTable from './DeliverableTable'
import './index.scss'

class CategoryDeleteModal extends Component {
  componentDidMount() {
    const { rowId } = this.props
    this.props.fetchDeliverablesByCategory({ categoryId: rowId, includeChildren: true })
  }

  handleDelete = () => {
    const { rowId } = this.props
    this.props.deleteFunction(rowId)
    this.props.clearModals()
  }

  render() {
    const { rowName, isLeaf, loading, deliverables } = this.props

    const deliverablesTotalCount = deliverables.count
    const deliverablesRows = deliverables.rows

    return (
      <div className="category-delete-modal">
        <div>
          <span>
            {`Are you sure you want to delete Category`}
            <strong>
              <em>{rowName}</em>
            </strong>
            ?
          </span>
        </div>
        <div className="category-delete-modal-validation">
          <h4>Verification:</h4>

          {!isLeaf ? (
            <Alert bsStyle="warning">
              <Glyphicon glyph="warning-sign" />
              {`All children of this Category will be also deleted!`}
            </Alert>
          ) : (
            <Alert bsStyle="success">
              <Glyphicon glyph="ok-sign" />
              {`Selected Category has no children.`}
            </Alert>
          )}

          {loading ? (
            <>
              <span> Check Deliverable relations ...</span>
              <Loader loading height="55px" />
            </>
          ) : deliverablesTotalCount > 0 ? (
            <>
              <Alert bsStyle="warning">
                <Glyphicon glyph="warning-sign" />
                {`There are Deliverable Associated (${deliverablesTotalCount}) with this Category or their children. Are you sure you want to Delete?`}
              </Alert>
              <DeliverableTable deliverables={deliverablesRows} totalCount={deliverablesTotalCount} />
            </>
          ) : (
            <Alert bsStyle="success">
              <Glyphicon glyph="ok-sign" />
              {`Selected Category has no related Deliverables`}
            </Alert>
          )}
        </div>
        <div className="category-delete-modal-buttons">
          <Button onClick={this.props.clearModals}>Cancel</Button>
          <Button className="btn_custom" onClick={this.handleDelete} disabled={loading}>
            Delete
          </Button>
        </div>
      </div>
    )
  }
}

CategoryDeleteModal.defaultProps = {
  loading: false,
  deliverables: {
    count: 0,
    rows: []
  }
}

CategoryDeleteModal.propTypes = {
  rowId: PropTypes.number.isRequired,
  rowName: PropTypes.string.isRequired,
  isLeaf: PropTypes.bool.isRequired,

  loading: PropTypes.bool,
  deliverables: PropTypes.object,

  clearModals: PropTypes.func.isRequired,
  deleteFunction: PropTypes.func.isRequired,
  fetchDeliverablesByCategory: PropTypes.func.isRequired
}

const mapStateToProps = ({
  taxonomy: {
    categories: { deliverables }
  }
}) => ({
  loading: deliverables.loading,
  deliverables: deliverables.data
})

const mapDispatchToProps = {
  clearModals,
  fetchDeliverablesByCategory
}

export default connect(mapStateToProps, mapDispatchToProps)(CategoryDeleteModal)
