import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Alert, Glyphicon } from 'react-bootstrap'

import Loader from 'components/Loader'
import { clearModals } from 'actions/modal-actions'

import categoryTestsActions from './../../categoryTestsActions'
import GlobalForm from './GlobalForm'

const CategoryTestEditGlobalModal = () => {
  const dispatch = useDispatch()

  const { loading, error, data } = useSelector(state => state.categoryTests.globalConfiguration)

  const { id } = data

  useEffect(() => {
    dispatch(categoryTestsActions.fetchGlobalConfig())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const closeModal = () => {
    dispatch(categoryTestsActions.clearGlobalConfig())
    dispatch(clearModals())
  }

  return (
    <Form className="text-left" horizontal>
      {loading && <Loader loading overlap />}
      {error && (
        <Alert bsStyle="danger">
          <Glyphicon className="padding-rigt-standard" glyph="info-sign" />
          Error during fetching global configuration: {error}
        </Alert>
      )}

      <GlobalForm key={id ?? 'null'} initValues={data} closeModal={closeModal} isOkDisabled={loading || error} />
    </Form>
  )
}

CategoryTestEditGlobalModal.propTypes = {}

export default CategoryTestEditGlobalModal
