import React from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { DATA_TABLE } from 'components/DataTable/constants'
import { BooleanCell, DateCell } from 'components/DataTable'
import DateRangeTableFilter from 'components/DataTable/DateRangeTableFilter'
import DeployJobActionsCell from './DeliveryJobActionsCell'
import moment from 'moment'

import { renderComment } from 'components/DeliveryManager/Common/table-utils'
import { DELIVERY_JOB_STATUS } from 'constants/deliveryJobsConstants'

const getDateRangeTableFilter = (filterHandler, customFilterParameters) => {
  const MINUS_YEARS = 20
  const rangeStart = moment()
    .startOf('month')
    .subtract(MINUS_YEARS, 'years')
    .toDate()
  const rangeEnd = moment()
    .endOf('month')
    .toDate()

  return (
    <DateRangeTableFilter
      filterHandler={filterHandler}
      rangeStart={rangeStart}
      rangeEnd={rangeEnd}
      availableRangeInfo={`This filter can only go back ${MINUS_YEARS} years; clear filter and sort to find longer historical ranges.`}
      {...customFilterParameters}
    />
  )
}

const buildDeliveryJobsColumns = createAlert => {
  const onCopy = (text, result) => {
    const type = result ? 'info' : 'danger'
    const title = result ? 'Comment copied to clipboard' : 'There was an error trying to copy'
    createAlert(type, '', title)
  }
  return [
    {
      field: 'id',

      dataAlign: 'center',
      headerAlign: 'center',
      width: '70px',

      label: 'ID',
      dataSort: true,
      dataFormat: function getIdCell(cell, row) {
        return (
          <OverlayTrigger
            placement="right"
            overlay={
              <Tooltip id="delivery-job-id-cell-tooltip">
                <div className="text-left">Updated by: {row.updatedBy}</div>
                <div className="text-left">Updated at: {row.updatedAt}</div>
                <div className="text-left">Created by: {row.createdBy}</div>
                <div className="text-left">Created at: {row.createdAt}</div>
              </Tooltip>
            }
          >
            <span>{cell}</span>
          </OverlayTrigger>
        )
      },
      filter: {
        type: 'TextFilter',
        delay: DATA_TABLE.FILTER_DELAY
      }
    },
    {
      field: 'toClient',

      dataAlign: 'center',
      headerAlign: 'center',
      width: '91px',

      label: 'To client',
      dataSort: true,
      dataFormat: (cell, row) => BooleanCell({ isActive: cell }),
      filter: {
        type: 'SelectFilter',
        options: {
          1: 'True',
          0: 'False'
        }
      }
    },
    {
      field: 'status',

      dataAlign: 'center',
      headerAlign: 'center',
      width: '110px',

      label: 'Status',
      dataSort: true,
      dataFormat(cell, row) {
        return (
          <>
            <span className={`category-job-status category-job-status-${row.status}`}>{row.status}</span>{' '}
            {renderComment(row.notes, onCopy)}
          </>
        )
      },
      filter: {
        type: 'SelectFilter',
        options: {
          [DELIVERY_JOB_STATUS.UNSCHEDULED]: 'Unscheduled',
          [DELIVERY_JOB_STATUS.SCHEDULED]: 'Scheduled',
          [DELIVERY_JOB_STATUS.PROCESSING]: 'Processing',
          [DELIVERY_JOB_STATUS.READY]: 'Ready',
          [DELIVERY_JOB_STATUS.PUBLISHED]: 'Published'
        },
        comparator: '='
      }
    },
    {
      field: 'categoryId',

      dataAlign: 'center',
      headerAlign: 'center',
      width: '110px',

      label: 'Category ID',
      dataSort: true,
      filter: {
        type: 'TextFilter',
        delay: DATA_TABLE.FILTER_DELAY
      }
    },
    {
      field: 'categoryFullPath',

      dataAlign: 'left',
      headerAlign: 'center',
      width: 'auto',

      label: 'Category full path',
      dataSort: true,
      filter: {
        type: 'TextFilter',
        delay: DATA_TABLE.FILTER_DELAY
      }
    },
    {
      field: 'deliveryDate',

      dataAlign: 'center',
      headerAlign: 'center',
      width: '230px',
      label: 'Delivery Date',
      dataSort: true,
      dataFormat: (cell, row) => DateCell({ date: cell }),
      filter: {
        // NOTE: default values we set in Reducer
        type: 'CustomFilter',
        getElement: getDateRangeTableFilter,
        customFilterParameters: {
          label: 'Delivery Date range'
        }
      }
    },
    {
      field: 'deliveredDate',

      dataAlign: 'center',
      headerAlign: 'center',
      width: '225px',

      label: 'Delivered Date',
      dataSort: true,
      dataFormat: (cell, row) => DateCell({ date: cell }),
      filter: {
        type: 'CustomFilter',
        getElement: getDateRangeTableFilter,
        customFilterParameters: {
          label: 'Delivered Date range'
        }
      }
    },
    {
      field: 'analystName',

      dataAlign: 'center',
      headerAlign: 'center',
      width: '140px',

      label: 'Analyst',
      dataSort: true,
      dataFormat(cell, row) {
        return (
          <>
            {row.analystName || ' '} {renderComment(row.analystNotes, onCopy)}
          </>
        )
      },
      filter: {
        type: 'TextFilter',
        delay: DATA_TABLE.FILTER_DELAY
      }
    },
    {
      field: 'isActive',

      dataAlign: 'center',
      headerAlign: 'center',
      width: '80px',

      label: 'Active',
      dataSort: true,
      dataFormat: (cell, row) => BooleanCell({ isActive: cell }),
      filter: {
        type: 'SelectFilter',
        options: {
          1: 'True',
          0: 'False'
        }
      }
    },
    {
      field: 'actionsColumn',
      label: 'Actions',
      dataAlign: 'center',
      headerAlign: 'center',
      width: '80px',
      dataFormat: function getActionCell(cell, row) {
        return <DeployJobActionsCell row={row} />
      }
    }
  ]
}

export default buildDeliveryJobsColumns
