import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { DropdownButton, MenuItem, ButtonGroup, Col } from 'react-bootstrap'
import { Typeahead } from 'react-bootstrap-typeahead'
import { priceFormatter, numberFormatter } from '../../../utils/formatters'
import constants from '../../../constants/constants'
import Loader from '../../Loader'
import DateRangeFilter from '../DateRangeFilter'
import moment from 'moment'

class QcToolbar extends Component {
  constructor(props) {
    super(props)
    this.splitByOptions = {
      none: 'Split by None',
      [constants.aggregations.BRAND]: 'Split by Brand',
      [constants.aggregations.CATEGORY]: 'Split by Category',
      [constants.aggregations.MERCHANT]: 'Split by Merchant'
    }
    this.timeIntervalOptions = {
      [constants.timeInterval.QUARTER]: 'Quarterly',
      [constants.timeInterval.MONTH]: 'Monthly',
      [constants.timeInterval.WEEK]: 'Weekly',
      [constants.timeInterval.DAY]: 'Daily'
    }
    this.chartViewOptions = {
      absolute: 'Absolute',
      delta: 'Delta'
    }
  }

  componentDidUpdate() {
    // perform any preparations after update
    if (!this.props.drillDownBy && this.qcDrilldown) this.clearValue()
  }

  clearValue() {
    if (this.qcDrilldown && this.qcDrilldown.getInstance) {
      const instance = this.qcDrilldown.getInstance()
      if (instance) return instance.clear()
    }
  }

  onDateRangeChange = value => {
    this.props.onSelectedTimeRangeChanged({
      from: moment(value[0]).valueOf(),
      to: moment(value[1]).valueOf()
    })
  }

  render() {
    const {
      splitBy,
      drillDownBy,
      drillDownOptions,
      timeInterval,
      chartView,
      from,
      to,
      rangeStart,
      rangeEnd,
      onSplitByChanged,
      onDrillDownByChanged,
      loading,
      onChartViewChanged,
      onTimeIntervalChanged,
      onSelectedTimeRangeChanged
    } = this.props
    const getSplitByOptions = () => {
      return Object.keys(this.splitByOptions).map(key => {
        return (
          <MenuItem eventKey={key} key={key} active={key === splitBy}>
            {this.splitByOptions[key]}
          </MenuItem>
        )
      })
    }
    const getTimeIntervalOptions = () => {
      return Object.keys(this.timeIntervalOptions).map(key => {
        return (
          <MenuItem eventKey={key} key={key} active={key === timeInterval}>
            {this.timeIntervalOptions[key]}
          </MenuItem>
        )
      })
    }
    const getChartViewOptions = () => {
      return Object.keys(this.chartViewOptions).map(key => {
        return (
          <MenuItem eventKey={key} key={key} active={key === chartView}>
            {this.chartViewOptions[key]}
          </MenuItem>
        )
      })
    }
    /* Custom rendering as we have different types */
    const renderMenuItemChildren = option => {
      return (
        <span>
          <span style={{ fontWeight: 'bold', whiteSpace: 'normal' }}>{`${option.name}: `}</span>
          <small>{`(${numberFormatter(option.count)}) (${priceFormatter(option.revenue)})`}</small>
        </span>
      )
    }
    return (
      <div className="qc-toolbar">
        <Col className="pull-left" lg={2} md={2} sm={4}>
          <DropdownButton
            className="splitby-option-button"
            title={this.splitByOptions[splitBy]}
            id="qcSplitBy"
            onSelect={val => {
              if (splitBy !== val) {
                this.clearValue()
                onSplitByChanged(val)
              }
            }}
          >
            {getSplitByOptions()}
          </DropdownButton>
        </Col>
        <Col lg={5} md={4} sm={8}>
          {loading ? (
            <Loader />
          ) : (
            <div
              className="qc-drilldownBy"
              style={{
                display: drillDownOptions[splitBy] ? 'flex' : 'none'
              }}
            >
              <div className="pull-left drilldown-label">
                <h5 className="text-right" style={{ display: 'inline-block' }}>
                  Drill Down By
                </h5>
              </div>
              <Typeahead
                className="qc-drilldown"
                ref={ref => {
                  this.qcDrilldown = ref
                }}
                align="justify"
                placeholder="Select"
                selected={drillDownBy ? [drillDownBy] : []}
                onChange={selectedVals => {
                  const newVal = selectedVals.length ? selectedVals[0] : null
                  if (newVal && drillDownBy !== newVal) {
                    onDrillDownByChanged(newVal)
                    onSelectedTimeRangeChanged({ from: null, to: null })
                  }
                }}
                options={splitBy === 'none' ? [] : drillDownOptions[splitBy] ? drillDownOptions[splitBy].data : []}
                renderMenuItemChildren={renderMenuItemChildren}
                labelKey={'name'}
                filterBy={[]}
                disabled={
                  splitBy === 'none' ||
                  !drillDownOptions[splitBy] ||
                  !drillDownOptions[splitBy].data ||
                  !drillDownOptions[splitBy].data.length
                }
                maxResults={10}
                paginationText="Show More Results"
                paginate
              />
            </div>
          )}
        </Col>
        <Col lg={5} md={6} sm={12}>
          <ButtonGroup className="pull-right">
            <DateRangeFilter
              label={'DateRange'}
              name={'DateRange'}
              handleDateSelection={this.onDateRangeChange}
              rangeStart={rangeStart}
              rangeEnd={rangeEnd}
              className="qc-toolbar-date-range-filter"
              startDate={moment(from).format(constants.dateFormat.qcTool)}
              endDate={moment(to).format(constants.dateFormat.qcTool)}
            />
            <DropdownButton
              title={this.chartViewOptions[chartView]}
              className="qc-option-button"
              id="qcChartView"
              onSelect={val => {
                if (chartView !== val) {
                  onChartViewChanged(val)
                }
              }}
            >
              {getChartViewOptions()}
            </DropdownButton>
            <DropdownButton
              pullRight
              title={this.timeIntervalOptions[timeInterval]}
              className="qc-option-button"
              id="qcTimeInterval"
              onSelect={val => {
                if (timeInterval !== val) {
                  onTimeIntervalChanged(val)
                }
              }}
            >
              {getTimeIntervalOptions()}
            </DropdownButton>
          </ButtonGroup>
        </Col>
      </div>
    )
  }
}

QcToolbar.propTypes = {
  splitBy: PropTypes.string,
  drillDownBy: PropTypes.object,
  drillDownOptions: PropTypes.object,
  timeInterval: PropTypes.string,
  loading: PropTypes.bool,
  chartView: PropTypes.string,
  onSplitByChanged: PropTypes.func.isRequired,
  onDrillDownByChanged: PropTypes.func.isRequired,
  onChartViewChanged: PropTypes.func.isRequired,
  onTimeIntervalChanged: PropTypes.func.isRequired,
  onSelectedTimeRangeChanged: PropTypes.func,
  data: PropTypes.object,
  from: PropTypes.number,
  to: PropTypes.number,
  rangeStart: PropTypes.string,
  rangeEnd: PropTypes.string
}

export default QcToolbar
