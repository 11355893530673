import commonRtkApi from 'commonRtkApi.js'

const brandSynonymRuleAPI = commonRtkApi.injectEndpoints({
  endpoints: builder => ({
    brandSynonymRule: builder.query({
      query: ({ brandId, synonym }) => {
        const params = {
          page: 1,
          sortBy: 'id',
          pageSize: 1,
          sortOrder: 'asc',
          filters: {
            brandId: { value: brandId },
            synonym: {
              value: synonym,
              comparator: 'ilike'
            }
          },
          filterType: 'table'
        }
        return { url: `/rops/rules/brand-synonym`, method: 'GET', params }
      }
    })
  })
})

export const { useBrandSynonymRuleQuery } = brandSynonymRuleAPI
