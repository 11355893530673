import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import moment from 'moment'

import { clearModals } from 'actions/modal-actions'
import { DELIVERY_DATE_FORMAT } from 'constants/deliveryJobsConstants'

import DeliveryJobModalLayout from './DeliveryJobModalLayout'
import deliveryJobsActions from './../deliveryJobsActions'

class DeliveryJobCreateModal extends Component {
  state = {
    categoryId: null,
    categoryFullPath: null,
    deliveryDate: null,
    toClient: false,
    notes: ''
  }

  handleOkButton = () => {
    const { categoryId, deliveryDate, notes, toClient } = this.state
    this.props.saveDeliveryJobAction({
      isActive: true,
      categoryId: categoryId,
      deliveryDate,
      notes,
      toClient
    })
    this.props.clearModals()
  }

  validateDate(dateRaw) {
    const date = moment(dateRaw, DELIVERY_DATE_FORMAT, true)
    let message = ''

    if (!date.isValid()) {
      message = `Delivery date is invalid or has wrong format. Expected format is "${DELIVERY_DATE_FORMAT}"`
    } else {
      const startOfMonth = moment().startOf('month')
      const endOfMonth = moment().endOf('month')
      if (!date || date < startOfMonth || date > endOfMonth) {
        message = 'Delivery day must be in the current month'
      }
    }

    return {
      isValid: !message,
      message
    }
  }

  getUISettings = () => {
    const { categoryId, deliveryDate } = this.state
    const { conflictDeliveryJob, conflictDeliveryJobLoading } = this.props
    const settings = {
      infoMessage: 'Choose a category and delivery date...',
      infoStyle: null, // '', success, info, warning, danger // TODO use constants for this
      okButtonName: 'Create',
      isOkButtonDisabled: false
    }

    if (conflictDeliveryJobLoading) {
      settings.infoMessage = `Loading ...`
      settings.infoStyle = null
      settings.isOkButtonDisabled = true
    } else if (categoryId && deliveryDate) {
      const statusDeliveryDate = this.validateDate(deliveryDate)
      if (!statusDeliveryDate.isValid) {
        settings.infoMessage = statusDeliveryDate.message
        settings.infoStyle = 'danger'
        settings.isOkButtonDisabled = true
      } else if (conflictDeliveryJob) {
        settings.infoMessage = `We already have Delivery Job ID: ${conflictDeliveryJob.id} with the selected Category for the current month`
        settings.infoStyle = 'danger'
        settings.isOkButtonDisabled = true
      } else {
        settings.infoMessage = `We don't have the selected Category for the current month. You can create a new Delivery Job.`
        settings.infoStyle = 'info'
        settings.isOkButtonDisabled = false
      }
    } else {
      settings.isOkButtonDisabled = true
    }

    return settings
  }

  handleFieldChange = newValue => {
    if (newValue.categoryId || newValue.deliveryDate) {
      this.setState(newValue, this.checkDeliveryJob)
    } else {
      this.setState(newValue)
    }
  }

  checkDeliveryJob = () => {
    const { categoryId, deliveryDate } = this.state
    const deliveryDateStatus = this.validateDate(deliveryDate)
    if (categoryId && deliveryDate && deliveryDateStatus.isValid) {
      this.props.fetchDeliveryJobsByCategoryAndDeliveryDate({
        categoryId,
        from: moment(deliveryDate)
          .startOf('month')
          .format(DELIVERY_DATE_FORMAT),
        to: moment(deliveryDate)
          .endOf('month')
          .format(DELIVERY_DATE_FORMAT)
      })
    }
  }

  render() {
    const { categoryId, categoryFullPath, deliveryDate, toClient, notes } = this.state

    const { clearModals } = this.props

    const { infoMessage, infoStyle, okButtonName, isOkButtonDisabled } = this.getUISettings()

    return (
      <DeliveryJobModalLayout
        categoryId={categoryId}
        categoryFullPath={categoryFullPath}
        deliveryDate={deliveryDate}
        toClient={toClient}
        notes={notes}
        handleFieldChange={this.handleFieldChange}
        infoMessage={infoMessage}
        infoStyle={infoStyle}
        okButtonName={okButtonName}
        isOkButtonDisabled={isOkButtonDisabled}
        onCancelHandler={clearModals}
        onOkHandler={this.handleOkButton}
      />
    )
  }
}

DeliveryJobCreateModal.defaultProps = {
  conflictDeliveryJobLoading: false,
  conflictDeliveryJob: null
}

DeliveryJobCreateModal.propTypes = {
  clearModals: PropTypes.func.isRequired,
  fetchDeliveryJobsByCategoryAndDeliveryDate: PropTypes.func.isRequired,
  saveDeliveryJobAction: PropTypes.func.isRequired,

  conflictDeliveryJobLoading: PropTypes.bool,
  conflictDeliveryJob: PropTypes.object
}

const mapStateToProps = ({ deliveryJobs: { modal } }) => ({
  conflictDeliveryJobLoading: modal.loading,
  conflictDeliveryJob: modal.conflictDeliveryJob
})

const mapDispatchToProps = {
  clearModals,
  fetchDeliveryJobsByCategoryAndDeliveryDate: deliveryJobsActions.fetchByCategoryAndDeliveryDate,
  saveDeliveryJobAction: deliveryJobsActions.save
}

export default connect(mapStateToProps, mapDispatchToProps)(DeliveryJobCreateModal)
