import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import DocumentTitle from 'react-document-title'

import Loader from '../../../Loader'
import DataTable from './../../../DataTable'

import { fetchPastDeliveries } from '../../../../actions/delivery-actions'

import { appName } from '../../../../constants/constants'
import buildPastDeliveryColumns from './past-delivery-columns'

import './index.scss'

class PastDeliveriesPage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      page: 1,
      pageSize: 25,
      sortOrder: 'desc',
      sortBy: 'deliveryDate'
    }
  }

  componentDidMount() {
    if (this.props.deliverableId) {
      this.fetchPastDeliveries()
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.deliverableId !== prevProps.deliverableId) {
      this.fetchPastDeliveries()
    }
  }

  fetchPastDeliveries = (payload = {}) => {
    const {
      deliverableId = this.props.deliverableId,
      sortBy = this.state.sortBy,
      sortOrder = this.state.sortOrder,
      page = this.state.page,
      pageSize = this.state.pageSize
    } = payload

    this.props.fetchPastDeliveries({
      deliverableId,
      sortBy,
      sortOrder,
      page,
      pageSize
    })
  }

  onPageChange = page => {
    this.setState({ page })
    this.fetchPastDeliveries({ page })
  }

  render() {
    const { loading, documentTitle, pastDeliveries, totalPastDeliveries } = this.props

    const { page, pageSize } = this.state

    const maxPages = Math.ceil(totalPastDeliveries / pageSize) || 1
    const columns = buildPastDeliveryColumns({
      page,
      pageSize,
      deliveryCount: totalPastDeliveries
    })

    return (
      <DocumentTitle title={documentTitle ? `${appName} | ${documentTitle}` : appName}>
        <React.Fragment>
          {loading && <Loader overlap />}

          <DataTable
            columns={columns}
            maxPages={maxPages}
            data={pastDeliveries}
            page={this.state.page}
            sortName={this.state.sortBy}
            sortOrder={this.state.sortOrder}
            onPageChange={this.onPageChange}
            ref={el => {
              this.dataTable = el
            }}
          />
        </React.Fragment>
      </DocumentTitle>
    )
  }
}

PastDeliveriesPage.defaultProps = {
  documentTitle: 'Delivery Manager - Past Deliveries'
}

PastDeliveriesPage.propTypes = {
  deliverableId: PropTypes.number,
  fetchPastDeliveries: PropTypes.func,
  pastDeliveries: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool,
  documentTitle: PropTypes.string,
  totalPastDeliveries: PropTypes.number
}

const mapStateToProps = ({ delivery: { error, loading, pastDeliveries, pastDeliveryCount } }) => ({
  error,
  loading,
  pastDeliveries,
  totalPastDeliveries: pastDeliveryCount
})

export default connect(mapStateToProps, { fetchPastDeliveries })(PastDeliveriesPage)
