import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { cloneDeep } from 'lodash'
import { Button, Glyphicon } from 'react-bootstrap'
import Select from 'react-select'

import Loader from '../../Loader'
import {
  setSelectedFeedModuleAction,
  resetSelectedFeedModuleAction,
  setEditedFeedModuleAction,
  resetEditedFeedModuleAction,
  fetchFeedModulesAction,
  fetchFeedModulesAndSelectAction
} from './actions'

import FeedModuleForm from './FeedModuleForm'
import FeedModuleQueries from './FeedModuleQueries'

import './feedModule.scss'

const BASE_PATH = '/admin/feeds/modules'

const FeedModules = () => {
  const dispatch = useDispatch()
  const { selectedFeedModule, editedFeedModule, loading, data } = useSelector(state => state.feedModules)
  const feedModules = data.rows || []

  const history = useHistory()
  const { moduleId: selectedFeedModuleValue } = useParams()

  useEffect(() => {
    if (selectedFeedModuleValue) {
      dispatch(fetchFeedModulesAndSelectAction({ selectedFeedModuleValue }))
    } else {
      dispatch(resetSelectedFeedModuleAction())
      dispatch(resetEditedFeedModuleAction())
      dispatch(fetchFeedModulesAction())
    }
  }, [dispatch, selectedFeedModuleValue])

  const onChangeFeedModuleSelector = selectedFeedModule => {
    history.push(`${BASE_PATH}/${selectedFeedModule.value}`)
    dispatch(setSelectedFeedModuleAction(selectedFeedModule))
    if (selectedFeedModule) {
      const editedFeedModule = cloneDeep(feedModules.find(m => m.id === selectedFeedModule.value))
      dispatch(setEditedFeedModuleAction(editedFeedModule))
    } else {
      dispatch(setEditedFeedModuleAction(null))
    }
  }

  const onCreateNewHandler = () => {
    dispatch(setSelectedFeedModuleAction(null))
    dispatch(
      setEditedFeedModuleAction({
        isNew: true
      })
    )
  }

  const onCreateFromTemplateHandler = () => {
    const currentFeedModule = editedFeedModule
    dispatch(setSelectedFeedModuleAction(null))
    dispatch(
      setEditedFeedModuleAction({
        ...cloneDeep(currentFeedModule),
        isNew: true,
        id: currentFeedModule.id + '-new',
        name: currentFeedModule.name + '-new'
      })
    )
  }

  return (
    <div className="container feed-module-page">
      <div className="feed-module-header">
        <div className="h3">Data Feed Modules</div>
        <Button className="btn_custom" onClick={onCreateNewHandler}>
          Create new Feed Module
        </Button>
      </div>
      <div className="feed-module-select-container">
        <Select
          className="feed-module-select"
          placeholder="Select Feed Module"
          onChange={onChangeFeedModuleSelector}
          value={selectedFeedModule}
          options={feedModules.map(module => ({ value: module.id, label: module.name }))}
        />
        <Button
          className="btn btn-default feed-module-select-btn"
          onClick={onCreateFromTemplateHandler}
          disabled={!editedFeedModule || (editedFeedModule && editedFeedModule.isNew)}
          title="Create a new Feed Module using this item as a template"
        >
          <Glyphicon glyph="duplicate" />
        </Button>
      </div>
      {loading && <Loader loading overlap />}
      {editedFeedModule && (
        <>
          <FeedModuleForm feedModule={editedFeedModule} key={editedFeedModule.id} />
          {!editedFeedModule.isNew && (
            <FeedModuleQueries feedModuleId={editedFeedModule.id} key={'queries-for-' + editedFeedModule.id} />
          )}
        </>
      )}
    </div>
  )
}

export default FeedModules
