import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { isEmpty } from 'lodash'

const Filters = ({ filters }) => {
  function getMaxDate(maxOrderDate) {
    return maxOrderDate ? moment(maxOrderDate).format('M/D/YY') : 'present'
  }

  return (
    <div>
      {filters.itemAttributes && !isEmpty(filters.itemAttributes) && (
        <div>
          {`${Object.entries(filters.itemAttributes).filter(([key, value]) => value === true).length} Item Attributes`}
        </div>
      )}
      {filters.merchants && !isEmpty(filters.merchants) && (
        <div>{`${Object.keys(filters.merchants).length} Merchants`}</div>
      )}
      {filters.l1Categories && !isEmpty(filters.l1Categories) && (
        <div>{`${Object.keys(filters.l1Categories).filter(key => filters.l1Categories[key]).length} Categories`}</div>
      )}
      {filters.minOrderDate && (
        <div>{`${moment(filters.minOrderDate).format('M/D/YY')} to ${getMaxDate(filters.maxOrderDate)}`}</div>
      )}
      {filters.sourceId && <div>{`SourceId=${filters.sourceId}`}</div>}
    </div>
  )
}

Filters.propTypes = {
  filters: PropTypes.object.isRequired
}

export default Filters
