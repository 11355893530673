import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import Loader from 'components/Loader'
import { createAlert } from 'actions/app-actions'
import { DataTableFiltered } from 'components/DataTable'

import DeliveryJobsToolbar from './DeliveryJobsToolbar'
import deliveryJobsActions from './deliveryJobsActions'

import buildDeliveryJobsColumns from './columns'

import './deliveryJobs.scss'

class DeliveryJobs extends Component {
  constructor(props) {
    super(props)

    this.columns = buildDeliveryJobsColumns(props.createAlert)
  }
  render() {
    const {
      loading,
      pageSize,
      hasEditAccess,
      totalCount,
      page,
      rows,
      sortBy,
      sortOrder,
      filters,
      fetchDeliveryJobs
    } = this.props

    return (
      <div className="delivery-jobs-page">
        <DeliveryJobsToolbar hasEditAccess={hasEditAccess} refreshHandler={fetchDeliveryJobs} />
        {loading && <Loader loading overlap />}
        <DataTableFiltered
          data={rows}
          columns={this.columns}
          page={page}
          pageSize={pageSize}
          totalCount={totalCount}
          sortBy={sortBy}
          sortOrder={sortOrder}
          filters={filters}
          tableClassName="delivery-jobs-table"
          fetchRowsAction={fetchDeliveryJobs}
          entityName="jobs"
        />
      </div>
    )
  }
}

DeliveryJobs.defaultProps = {
  rows: [],
  totalCount: 0
}

DeliveryJobs.propTypes = {
  page: PropTypes.number,
  pageSize: PropTypes.number,
  sortBy: PropTypes.string,
  sortOrder: PropTypes.string,
  filters: PropTypes.object,
  loading: PropTypes.bool.isRequired,
  hasEditAccess: PropTypes.bool.isRequired,
  totalCount: PropTypes.number.isRequired,
  rows: PropTypes.arrayOf(PropTypes.object).isRequired,
  createAlert: PropTypes.func.isRequired,
  fetchDeliveryJobs: PropTypes.func.isRequired
}

const mapStateToProps = ({ deliveryJobs }) => ({
  page: deliveryJobs.page,
  sortBy: deliveryJobs.sortBy,
  loading: deliveryJobs.loading,
  rows: deliveryJobs.data.rows,
  pageSize: deliveryJobs.pageSize,
  sortOrder: deliveryJobs.sortOrder,
  totalCount: deliveryJobs.data.count,
  filters: deliveryJobs.filters
})

const mapDispatchToProps = {
  createAlert,
  fetchDeliveryJobs: deliveryJobsActions.fetch
}

export default connect(mapStateToProps, mapDispatchToProps)(DeliveryJobs)
