import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import { confirmModal } from '../../actions/modal-actions'

import SingleDateInput from '../SingleDateInput'

class SendIncrementModalComponent extends Component {
  state = {
    selectedDate: moment.utc().startOf('day')
  }

  componentDidMount() {
    this.props.onChange && this.props.onChange(this.state.selectedDate)
  }

  captureSelectedDate = selectedDate => {
    this.setState({ selectedDate })
    this.props.onChange && this.props.onChange(selectedDate)
  }

  render() {
    return (
      <div>
        <p style={{ textAlign: 'left' }}>
          {`Client checkpoint will be reset to the given date. Valid extracts
            older than this date will be scheduled to be sent. Use this option
            when you already produced the replacement extract(s) for invalid ones.`}
        </p>

        <label>
          {'Resend After: '}
          <SingleDateInput
            popupTitle="Resend After"
            selectedDate={this.state.selectedDate.toDate() || undefined}
            onDateChange={this.captureSelectedDate}
            maxDate={moment.utc().toDate()}
          />
        </label>
      </div>
    )
  }
}

SendIncrementModalComponent.propTypes = {
  onSubmit: PropTypes.func,
  onChange: PropTypes.func
}

const sendIncrementModal = (updateEtlCheckpoint, feed) => {
  let inputValue = null
  return confirmModal(
    <SendIncrementModalComponent
      onChange={value => {
        inputValue = value
      }}
    />,
    'Resend Increments',
    { okButton: 'Set Checkpoint' },
    () => {
      // Convert local time to the same in UTC
      const startDayInUTC = moment.utc(inputValue.format('YYYY-MM-DD HH:mm:ss')).startOf('day')

      updateEtlCheckpoint('idf-shipping', `CHECKPOINT-${feed.id}`, startDayInUTC.toISOString())
    }
  )
}

export default sendIncrementModal
