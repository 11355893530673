import React from 'react'
import Box from '@mui/material/Box'
import PropTypes from 'prop-types'
import RestartAltRoundedIcon from '@mui/icons-material/RestartAltRounded'
import { useGetSupraOrchestratorItemQuery } from './tableDAGAPI'

const StatusCell = ({ row }) => {
  const { isLoading, data: orchestratorItem } = useGetSupraOrchestratorItemQuery(row.run_id)

  return (
    <Box sx={{ p: 1 }}>
      {isLoading ? <RestartAltRoundedIcon /> : <span>{orchestratorItem.state.result_state}</span>}
    </Box>
  )
}

StatusCell.propTypes = {
  row: PropTypes.object
}

export default StatusCell
