import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Glyphicon, OverlayTrigger } from 'react-bootstrap'

class EditName extends Component {
  static propTypes = {
    value: PropTypes.string,
    onUpdatedValue: PropTypes.func,
    id: PropTypes.string,
    tooltip: PropTypes.func,
    onChangeMode: PropTypes.func,
    type: PropTypes.string,
    class: PropTypes.string
  }

  render() {
    return (
      <div>
        <OverlayTrigger placement="top" overlay={this.props.tooltip('In Edit')}>
          <div className={this.props.class}>
            <input
              id={this.props.id}
              className={'sq_name_edit'}
              type="text"
              value={this.props.value}
              onChange={this.props.onUpdatedValue}
            />
          </div>
        </OverlayTrigger>
        <OverlayTrigger placement="top" overlay={this.props.tooltip('Cancel Edit')}>
          <span
            className="cancel_edit_mode"
            onClick={e => {
              e.preventDefault()
              this.props.onChangeMode({
                type: this.props.type,
                value: 'selected'
              })
            }}
          >
            <Glyphicon glyph="remove" />
          </span>
        </OverlayTrigger>
      </div>
    )
  }
}

export default EditName
