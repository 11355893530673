import React from 'react'

export default function Cross(props) {
  return (
    <svg
      width="12px"
      height="12px"
      viewBox="0 0 12 12"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>cross</title>
      <defs />
      <g id="Components" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g id="Icons" transform="translate(-99.000000, -203.000000)">
          <g id="cross" transform="translate(90.000000, 193.000000)">
            <g id="Close-x">
              <rect id="spacing" x={0} y={0} width={30} height={30} />
              <rect
                id="Rectangle"
                fill="currentColor"
                transform="translate(15.000000, 16.000000) rotate(-315.000000) translate(-15.000000, -16.000000) "
                x={8}
                y={15}
                width={14}
                height={2}
              />
              <rect
                id="Rectangle"
                fill="currentColor"
                transform="translate(15.000000, 16.000000) rotate(-45.000000) translate(-15.000000, -16.000000) "
                x={8}
                y={15}
                width={14}
                height={2}
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
