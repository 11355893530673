import React, { Component } from 'react'
import PropTypes from 'prop-types'
import FormDatePicker from './../../FormDatePicker'
import MerchantsInput from './../Filter/merchants-input'
import CategoriesInput from './../Filter/categories-input'
import { Field } from 'redux-form'

export default class BaseTransaction extends Component {
  static propTypes = {
    feed: PropTypes.object.isRequired
  }

  render() {
    return (
      <div>
        <Field name="l1Categories" component={CategoriesInput} />
        <Field name="merchants" component={MerchantsInput} />
        <FormDatePicker
          label="Min Order Date"
          fieldName="minOrderDate"
          isRequired={!this.props.feed.isActive}
          fromDate={new Date('1/1/13')}
          tooltip={'The min order date is inclusive.'}
        />
        {!this.props.feed.isActive && (
          <FormDatePicker
            label="Max Order Date"
            isRequired
            fieldName="maxOrderDate"
            fromDate={
              this.props.feed.filters.minOrderDate ? new Date(this.props.feed.filters.minOrderDate) : new Date('1/1/13')
            }
            tooltip={'The max order date is inclusive.'}
          />
        )}
      </div>
    )
  }
}
