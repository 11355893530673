import React from 'react'
import StatusCell from './StatusCell'
import DurationCell from './DurationCell'
// import { ORCHESTRATOR_DATE_FORMAT } from '../orchestratorConstants'

function getSupraSSTableModel({ getActions }) {
  const supraSSTableModel = [
    {
      field: 'session_id',
      headerName: 'Session Id',
      width: 150
    },
    {
      field: 'domain',
      headerName: 'Domain',
      width: 150
    },
    {
      field: 'run_id',
      headerName: 'Run ID',
      width: 150
    },
    {
      field: 'job_id',
      headerName: 'Job ID',
      width: 150
    },
    {
      field: 'state',
      headerName: 'Status',
      valueGetter: value => value.run_id || value.run_id,
      renderCell: params => <StatusCell {...params} />
    },
    {
      field: 'run_duration',
      headerName: 'Work time',
      valueGetter: value => value.run_id || value.run_id,
      renderCell: params => <DurationCell {...params} />
    }
    // {
    //   field: 'start_date',
    //   headerName: 'Date start',
    //   valueGetter: ({ value }) => format(new Date(value), ORCHESTRATOR_DATE_FORMAT),
    //   width: 150
    // },
    // {
    //   field: 'end_date',
    //   headerName: 'Date end',
    //   valueGetter: ({ value }) => format(new Date(value), ORCHESTRATOR_DATE_FORMAT),
    //   width: 150
    // },
    // {
    //   field: 'actions',
    //   type: 'actions',
    //   headerName: 'Actions',
    //   width: 100,
    //   cellClassName: 'actions',
    //   getActions
    // }
  ]
  return supraSSTableModel
}

export default getSupraSSTableModel
