import React from 'react'
import PropTypes from 'prop-types'
import {
  FormGroup,
  FormControl,
  InputGroup,
  ControlLabel,
  HelpBlock,
  OverlayTrigger,
  Tooltip,
  Glyphicon
} from 'react-bootstrap'

export default function TextInputField({
  id,
  label,
  help,
  validationState,
  required,
  actionComponent,
  prefix,
  info,
  ...props
}) {
  const infoTooltip = () => {
    return <Tooltip id="info-tooltip">{info}</Tooltip>
  }
  let InputComp = <FormControl {...props} />
  if (prefix) {
    InputComp = (
      <InputGroup>
        <InputGroup.Addon>{prefix}</InputGroup.Addon>
        {InputComp}
      </InputGroup>
    )
  }
  return (
    <FormGroup controlId={id} validationState={validationState}>
      <ControlLabel className={required ? 'required' : ''}>
        {label}
        {info && info.length > 0 && (
          <div className="field-name-info-icon">
            <OverlayTrigger placement="top" overlay={infoTooltip()}>
              <Glyphicon glyph={'info-sign'} />
            </OverlayTrigger>
          </div>
        )}
      </ControlLabel>
      {InputComp}
      {validationState && <FormControl.Feedback />}
      {help && <HelpBlock>{help}</HelpBlock>}
      {actionComponent && { actionComponent }}
    </FormGroup>
  )
}

TextInputField.defaultProps = {
  actionComponent: null
}

TextInputField.propTypes = {
  id: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  help: PropTypes.string,
  validationState: PropTypes.string,
  required: PropTypes.bool,
  prefix: PropTypes.string,
  actionComponent: PropTypes.node,
  info: PropTypes.string
}
