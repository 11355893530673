import React from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import SortIcon from '../../SortIcon'
import ClientIcon from '../../Icon/FolderClose'
import ContractIcon from '../../Icon/Contract'
import DeliverableIcon from '../../Icon/DeliverableIcon'
import AlertCircle from '../../Icon/AlertCircle'
import MoreActions from '../../Icon/MoreActions'
import { OverlayTrigger, Popover, Tooltip, Glyphicon } from 'react-bootstrap'
import CopyToClipboard from 'react-copy-to-clipboard'
import {
  createContractsLinkUrl,
  createDeliverablesLinkUrl,
  createDeliveriesLinkUrl
} from '../../../utils/delivery-center'
import moment from 'moment'
import { get } from 'lodash'
import cx from 'classnames'

const DATE_FORMAT = 'YYYY/MM/DD'

export const caretRender = (direction, className) => {
  return <SortIcon asc={direction === 'asc'} desc={direction === 'desc'} className={className} />
}

export const renderAction = popoverContent => {
  const popover = (
    <Popover id="action-popover" className="sb-popover unselectable">
      {popoverContent}
    </Popover>
  )
  return (
    <div className="action-wrapper unselectable">
      <OverlayTrigger trigger="click" rootClose placement="bottom" overlay={popover}>
        <MoreActions />
      </OverlayTrigger>
    </div>
  )
}

export const ActionOption = ({ label, onClick }) => (
  <div
    className="action-button"
    onClick={(...args) => {
      onClick(...args)
      // following line is a trick that ensures the overlay
      // popup is closed after clicking the actionOption
      document.body.click()
    }}
  >
    {label}
  </div>
)

ActionOption.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
}

export const buildClientNameRenderer = actionHandlers => {
  const { onEdit, onToggleStatus, onDelete } = actionHandlers
  return function cellRenderer(cell, row) {
    const popoverContent = (
      <React.Fragment>
        <ActionOption label="View / Edit" onClick={() => onEdit(row)} />
        <ActionOption label={row.isActive ? 'Deactivate' : 'Activate'} onClick={() => onToggleStatus(row)} />
        <ActionOption label={'Delete'} onClick={() => onDelete(row)} />
      </React.Fragment>
    )
    return (
      <span>
        <ClientIcon />
        <NavLink className="filter-route-link" to={createContractsLinkUrl(row)}>
          {cell}
        </NavLink>
        {renderAction(popoverContent)}
      </span>
    )
  }
}

export const buildContractNameRenderer = actionHandlers => {
  const { onEdit, onToggleStatus, onDelete } = actionHandlers
  return function cellRenderer(cell, row) {
    const popoverContent = (
      <React.Fragment>
        <ActionOption label="View / Edit" onClick={() => onEdit(row)} />
        <ActionOption label={row.isActive ? 'Deactivate' : 'Activate'} onClick={() => onToggleStatus(row)} />
        <ActionOption label={'Delete'} onClick={() => onDelete(row)} />
      </React.Fragment>
    )
    return (
      <span>
        <ContractIcon />
        <NavLink className="filter-route-link" to={createDeliverablesLinkUrl(row.Client, row)}>
          {cell}
        </NavLink>
        {renderAction(popoverContent)}
      </span>
    )
  }
}

export const buildDeliverableNameRenderer = actionHandlers => {
  const { onEdit, onToggleStatus, onDelete } = actionHandlers
  return function cellRenderer(cell, row) {
    const popoverContent = (
      <React.Fragment>
        <ActionOption label="View / Edit" onClick={() => onEdit(row)} />
        <ActionOption label={row.isActive ? 'Deactivate' : 'Activate'} onClick={() => onToggleStatus(row)} />
        <ActionOption label={'Delete'} onClick={() => onDelete(row)} />
      </React.Fragment>
    )
    return (
      <span>
        <DeliverableIcon />
        <NavLink className="filter-route-link" to={createDeliveriesLinkUrl(row)}>
          {cell}
        </NavLink>
        {renderAction(popoverContent)}
      </span>
    )
  }
}

export const renderDate = cell => <span>{cell ? moment(cell).format(DATE_FORMAT) : '- -'}</span>

const contractExpTooltip = date => (
  <Tooltip id="tt-expiry-warning" className="tt-medium">
    {`A contract will expire soon after it's final delivery on `}
    <strong>{moment(date).format(DATE_FORMAT)}</strong>
  </Tooltip>
)

const getClientContractDelayedContent = delayedDeliveries => {
  if (delayedDeliveries.length === 1) {
    const delayDays = moment()
      .startOf('day')
      .diff(moment.utc(delayedDeliveries[0].nextDelivery).startOf('day'), 'days')
    return (
      <React.Fragment>
        {`Deliverable `}
        <strong>{delayedDeliveries[0].name}</strong>
        {` missed the delivery deadline `}
        {delayDays === 0 && <strong>today!</strong>}
        {delayDays === 1 && <strong>yesterday!</strong>}
        {delayDays > 1 && <strong>{delayDays} days ago!</strong>}
      </React.Fragment>
    )
  } else if (delayedDeliveries.length === 2) {
    return (
      <React.Fragment>
        {`2 Deliverables `}
        <strong>{delayedDeliveries[0].name}</strong>
        {` and `}
        <strong>{delayedDeliveries[1].name}</strong>
        {` missed the delivery deadline!`}
      </React.Fragment>
    )
  } else if (delayedDeliveries.length >= 3) {
    return (
      <React.Fragment>
        <strong>{delayedDeliveries[0].name}</strong>
        {` and `}
        <strong>{delayedDeliveries.length - 1}</strong>
        {` others missed the delivery deadline!`}
      </React.Fragment>
    )
  }
}

const getDeliverableDelayedContent = delayedDeliveries => {
  return (
    <React.Fragment>
      {`This Deliverable missed the delivery deadline `}
      <strong>{moment.utc(delayedDeliveries.nextDelivery).format(DATE_FORMAT)}</strong>
      {'!'}
    </React.Fragment>
  )
}

const delayedDeliveryTooltip = (delayedDeliveries, type) => {
  let content = null
  if (type === 'deliverable') {
    content = getDeliverableDelayedContent(delayedDeliveries)
  } else {
    content = getClientContractDelayedContent(delayedDeliveries)
  }
  return (
    <Tooltip id="delay-delivery-tooltip" className="danger-tooltip tt-medium">
      {content}
    </Tooltip>
  )
}

const getClientDelayedDeliveries = contracts => {
  const delayedDeliveries = []
  contracts.forEach(contract => {
    const deliverables = contract.Deliverables
    if (deliverables && deliverables.length) {
      deliverables.forEach(deliverable => delayedDeliveries.push(deliverable))
    }
  })
  return delayedDeliveries
}

export const renderActiveStatus = (cell, row, type) => {
  const { upcomingContractExpiry: expDate, Contracts = [], Deliverables = [], nextDelivery, isActive } = row
  let delayedDeliveries = []
  let warnTooltip = null
  let className = ''
  if (isActive) {
    switch (type) {
      case 'client':
        delayedDeliveries = getClientDelayedDeliveries(Contracts)
        if (delayedDeliveries.length >= 1) {
          warnTooltip = delayedDeliveryTooltip(delayedDeliveries, type)
          className = 'bold danger'
        }
        break
      case 'contract':
        delayedDeliveries = Deliverables.map(deliverable => deliverable)
        if (delayedDeliveries.length >= 1) {
          warnTooltip = delayedDeliveryTooltip(delayedDeliveries, type)
          className = 'bold danger'
        }
        break
      case 'deliverable':
        if (moment.utc(nextDelivery).startOf('day') < moment.utc().startOf('day')) {
          warnTooltip = delayedDeliveryTooltip(row, type)
          className = 'bold danger'
        }
        break
      default:
    }
  }

  return (
    <div className={cx(className, 'wrap-alert-icon')}>
      <span>{isActive ? 'Active' : 'Inactive'}</span>
      {isActive && warnTooltip && (
        <OverlayTrigger placement="top" overlay={warnTooltip}>
          <span className="alert-icon">
            <AlertCircle />
          </span>
        </OverlayTrigger>
      )}
      {isActive && expDate && (
        <OverlayTrigger placement="top" overlay={contractExpTooltip(expDate)}>
          <span className="alert-icon">
            <AlertCircle />
          </span>
        </OverlayTrigger>
      )}
    </div>
  )
}

export const renderNextDelivery = (cell, row) => {
  const mDate = cell ? moment.utc(cell).startOf('day') : null
  let className = ''
  if (row.isActive && mDate) {
    const delayDays = moment
      .utc()
      .startOf('day')
      .diff(mDate, 'days')
    if (delayDays >= 1) {
      className = 'bold danger'
    }
  }

  return <span className={cx(className, 'wrap-alert-icon')}>{cell ? moment.utc(cell).format(DATE_FORMAT) : '- -'}</span>
}

export const renderContractClient = (cell, row) => {
  return (
    <NavLink className="filter-route-link" to={createContractsLinkUrl(row.Client)}>
      {get(row, ['Client', 'name'], '- -')}
    </NavLink>
  )
}

export const renderDeliverableClient = (cell, row) => {
  return (
    <NavLink className="filter-route-link" to={createDeliverablesLinkUrl(get(row, ['Contract', 'Client']))}>
      {get(row, ['Contract', 'Client', 'name'], '- -')}
    </NavLink>
  )
}

export const renderQuality = cell => {
  if (cell) {
    return <div className={cx('circle', cell.toLowerCase())} />
  }
}

export const renderComment = (infoText, onCopy) => {
  if (infoText) {
    return (
      <OverlayTrigger
        rootClose
        placement="top"
        overlay={
          <Tooltip id="comment-tooltip">
            {'Comment (Click to Copy):\n'}
            <i>{infoText}</i>
          </Tooltip>
        }
      >
        <CopyToClipboard text={infoText} onCopy={onCopy}>
          <Glyphicon glyph="comment" />
        </CopyToClipboard>
      </OverlayTrigger>
    )
  }
}
