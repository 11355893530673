import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { debounce } from 'lodash'

class ColumnFilter extends Component {
  onChange = debounce(searchValue => {
    this.props.filterHandler({
      callback: this.createCallBack(searchValue)
    })
  }, this.props.delay)

  cleanFiltered = () => {
    this.props.filterHandler({
      callback: this.createCallBack()
    })
  }

  /*
   * Normalize the string by removing extra spaces
   * @param {string} str - string to be normalized
   * @return {string} - normalized string
   */
  normalizeString = str => {
    return str.replace(/\s+/g, ' ').trim()
  }

  createCallBack = searchValue => {
    if (!searchValue) return // if no searchValue no filtering needed
    let negate = false
    if (searchValue[0] === '-') {
      // for text starting with '-', negate results anb ignore first character
      searchValue = searchValue.substring(1)
      negate = true
    }
    if (!searchValue) return // if no searchValue no filtering needed
    searchValue = searchValue.toLowerCase()
    searchValue = this.normalizeString(searchValue)

    // return callback to do the data filtering based on searchValue
    return targetValue => {
      let matched = true
      if (targetValue) {
        targetValue = this.normalizeString(targetValue.toLowerCase())
        matched = targetValue.indexOf(searchValue) > -1
      }
      return negate ? !matched : matched
    }
  }

  render() {
    return (
      <input
        type="text"
        className={this.props.className}
        placeholder={this.props.placeholder}
        onChange={event => this.onChange(event.target.value)}
      />
    )
  }
}
ColumnFilter.propTypes = {
  filterHandler: PropTypes.func,
  delay: PropTypes.number,
  placeholder: PropTypes.string,
  className: PropTypes.string
}

ColumnFilter.defaultProps = {
  delay: 1000,
  className: 'filter text-filter form-control' // default classes to match table filter's css
}

export default ColumnFilter
