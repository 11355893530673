import React from 'react'
import { Glyphicon, Alert } from 'react-bootstrap'
import PropTypes from 'prop-types'

import InputField, { useFormValues } from 'components/FormInputComponents/InputField'
import { getModalUISettings } from './../../Common/utils'
import { useCategoryBrandRuleQuery } from './categoryBrandRuleAPI'
import useHandleBrandChange from './useHandleBrandChange'

import BrandSuggestion from 'components/Suggestion/BrandSuggestion'

/**
 * Component for selecting existing Brand (use BrandSuggestion)
 * and possibility to check related "Brand Category Rule" if it exist
 */
const BrandSuggestionWithCheck = ({ brand, categoryId, mode, index, handleBrandsChange, prevRuleId }) => {
  const { values: currentBrand, handleFieldChange, updateValue } = useFormValues(brand)
  const { brandId, brandFullPath, isMatchAnywhere } = currentBrand

  const fieldsNames = 'Category and Brand'
  const isFieldsFilled = categoryId && brandId

  const { data, error, isLoading } = useCategoryBrandRuleQuery(
    { categoryId, brandId },
    { skip: !categoryId || !brandId }
  )

  const newRule = data?.rows[0]
  const isRuleActiveFunc = rule => rule.isActive && rule.categoryIsActive && rule.brandIsActive

  const { ruleInfoMessage, ruleInfoStyle, isFieldsDisabled } = getModalUISettings({
    prevRuleId,
    isAllFieldsFilled: isFieldsFilled,
    fieldsNames,
    isRuleActiveFunc,
    mode,
    rule: newRule,
    errorMessage: error?.message,
    isLoading
  })

  useHandleBrandChange({
    mode,
    currentBrand,
    index,
    newRule,
    isLoading,
    handleBrandsChange,
    prevRuleId
  })

  return (
    <>
      <InputField validationState={brandId === undefined ? 'error' : null} required label={'Brand'}>
        <BrandSuggestion
          selectedId={brandId}
          selectedLabel={brandFullPath}
          changeCallback={el =>
            updateValue({
              brandId: el?.id,
              brandFullPath: el?.fullPath
            })
          }
          readOnly={mode.isRequestApprove || mode.isRequestView || !categoryId}
          mode={mode}
        />
      </InputField>
      {!mode.isRequestView && (
        <Alert bsStyle={ruleInfoStyle}>
          <Glyphicon className="padding-rigt-standard" glyph="info-sign" />
          {ruleInfoMessage}
        </Alert>
      )}
      <InputField
        id="isMatchAnywhere"
        label="Match Anywhere"
        type="CheckBox"
        checked={isMatchAnywhere || false}
        onChange={val => handleFieldChange(val)}
        disabled={isFieldsDisabled || mode.isRequestView || !categoryId}
      />
    </>
  )
}

BrandSuggestionWithCheck.propTypes = {
  brand: PropTypes.object,
  categoryId: PropTypes.number,
  mode: PropTypes.object,
  index: PropTypes.number,
  handleBrandsChange: PropTypes.func,
  prevRuleId: PropTypes.number
}

export default BrandSuggestionWithCheck
