import { strRemoveMilliseconds } from 'utils/formatters'

function getSupraTaxonomyTableModel({ getActions }) {
  const supraTaxonomyTableModel = [
    {
      field: 'domain_id',
      headerName: 'ID',
      width: 100
    },
    {
      field: 'domain',
      headerName: 'Domain',
      width: 160
    },
    {
      field: 'messages',
      headerName: 'Messages',
      type: 'number',
      width: 100
    },
    {
      field: 'nonreceipts',
      headerName: 'Nonreceipts',
      type: 'number',
      width: 100
    },
    {
      field: 'receipts',
      headerName: 'Receipts',
      type: 'number',
      width: 100
    },
    {
      field: 'nonreceipt_whitelisted',
      headerName: 'Is_whitelisted',
      type: 'boolean',
      width: 100
    },
    {
      field: 'nonreceipt_whitelisted_update_time',
      headerName: 'Whitelisted_update',
      valueGetter: value => strRemoveMilliseconds(value),
      width: 120
    },
    {
      field: 'create_time',
      headerName: 'Create_time',
      valueGetter: value => strRemoveMilliseconds(value),
      width: 120
    },
    {
      field: 'update_time',
      headerName: 'Update_time',
      valueGetter: value => strRemoveMilliseconds(value),
      width: 120
    },
    {
      field: 'first_message_date',
      headerName: 'First_message_date',
      valueGetter: value => strRemoveMilliseconds(value),
      width: 120
    },
    {
      field: 'last_message_date',
      headerName: 'Last_message_date',
      valueGetter: value => strRemoveMilliseconds(value),
      width: 120
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions
    }
  ]
  return supraTaxonomyTableModel
}

export default getSupraTaxonomyTableModel
