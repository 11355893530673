import React from 'react'
import PropTypes from 'prop-types'

const getTaskRuntimeHeaders = () => (
  <tr>
    <th>Task Type</th>
    <th>Avg Runtime</th>
    <th>Min</th>
    <th>Max</th>
  </tr>
)

const mapTaskRuntimes = (taskType, runtimeData) => (
  <tr key={taskType}>
    <td>{taskType}</td>
    <td>{runtimeData.average} min</td>
    <td>{runtimeData.min} min</td>
    <td>{runtimeData.max} min</td>
  </tr>
)

const TaskRuntimes = ({ taskRuntimes }) => {
  return (
    <div className="etl-stats-widget">
      <h3>Task Runtimes</h3>

      <table>
        <tbody>
          {getTaskRuntimeHeaders()}
          {Object.keys(taskRuntimes).map(taskType => mapTaskRuntimes(taskType, taskRuntimes[taskType]))}
        </tbody>
      </table>
    </div>
  )
}

TaskRuntimes.propTypes = {
  taskRuntimes: PropTypes.object
}

export default TaskRuntimes
