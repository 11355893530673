import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button, Form, Glyphicon, Alert } from 'react-bootstrap'
import moment from 'moment'

import InputField, { getElementValue } from 'components/FormInputComponents/InputField'
import CategorySuggestion from 'components/Suggestion/CategorySuggestion'
import { DELIVERY_DATE_FORMAT } from 'constants/deliveryJobsConstants'

class DeliveryJobModalLayout extends Component {
  handleCategoryChange = category => {
    const categoryId = category ? category.id : undefined
    const categoryFullPath = category ? category.fullPath : undefined
    this.props.handleFieldChange({ categoryId, categoryFullPath })
  }

  handleFieldChange = typeEvent => {
    this.props.handleFieldChange(getElementValue(typeEvent.target))
  }

  render() {
    const {
      categoryId,
      categoryFullPath,
      deliveryDate,
      toClient,
      notes,
      analystNotes,
      infoStyle,
      infoMessage,
      okButtonName,
      isOkButtonDisabled,
      onCancelHandler,
      onOkHandler
    } = this.props

    return (
      <Form className="text-left" horizontal>
        {analystNotes !== undefined ? (
          <InputField
            id="analystNotes"
            label="Analyst Notes"
            componentClass="textarea"
            value={analystNotes}
            rows="10"
            onChange={this.handleFieldChange}
          />
        ) : (
          <>
            <InputField validationState={categoryId === undefined ? 'error' : null} required label="Category">
              <CategorySuggestion
                selectedId={categoryId}
                selectedLabel={categoryFullPath}
                isLeaf
                changeCallback={this.handleCategoryChange}
              />
            </InputField>
            <InputField
              id="deliveryDate"
              type="date"
              label="Delivery Date"
              required
              placeholder={DELIVERY_DATE_FORMAT}
              min={moment()
                .startOf('month')
                .format(DELIVERY_DATE_FORMAT)}
              max={moment()
                .endOf('month')
                .format(DELIVERY_DATE_FORMAT)}
              value={deliveryDate || ''}
              validationState={moment(deliveryDate || undefined).isValid() ? null : 'error'}
              onChange={this.handleFieldChange}
            />
            <Alert bsStyle={infoStyle}>
              <Glyphicon glyph="info-sign" className="padding-rigt-standard" />
              {infoMessage}
            </Alert>
            <InputField
              id="toClient"
              label="To Client"
              type="CheckBox"
              value={undefined}
              checked={toClient || false}
              onChange={this.handleFieldChange}
            />
            <InputField
              id="notes"
              label="Scheduler Notes"
              componentClass="textarea"
              rows="10"
              value={notes || ''}
              onChange={this.handleFieldChange}
            />
          </>
        )}

        <div className="modal-body-footer">
          <Button onClick={onCancelHandler}>Cancel</Button>
          <Button className="btn_custom" onClick={onOkHandler} disabled={isOkButtonDisabled}>
            {okButtonName}
          </Button>
        </div>
      </Form>
    )
  }
}

DeliveryJobModalLayout.defaultProps = {
  categoryId: undefined,
  categoryLabel: null,
  toClient: false,
  notes: '',
  analystNotes: undefined
}

DeliveryJobModalLayout.propTypes = {
  categoryId: PropTypes.number,
  categoryFullPath: PropTypes.string,
  notes: PropTypes.string,
  toClient: PropTypes.bool,
  deliveryDate: PropTypes.string,
  analystNotes: PropTypes.string,

  infoMessage: PropTypes.string,
  infoStyle: PropTypes.string,

  okButtonName: PropTypes.string,
  isOkButtonDisabled: PropTypes.bool,

  handleFieldChange: PropTypes.func.isRequired,

  onCancelHandler: PropTypes.func.isRequired,
  onOkHandler: PropTypes.func.isRequired
}

export default DeliveryJobModalLayout
