import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import Loader from 'components/Loader'
import { DataTableFiltered, DataTableToolbar, TableToolbarButtons } from 'components/DataTable'

import CategoryGuidelineActions from './actions'
import getColumns from './Table/columns'

import './categoryGuideline.scss'

class CategoryGuideline extends Component {
  render() {
    const {
      loading,
      pageSize,
      totalCount,
      page,
      rows,
      sortBy,
      sortOrder,
      filters,
      fetchCategoryGuidelinesAction,
      downloadCategoryGuidelinesAction,
      clearFiltersAction,
      hasEditAccess
    } = this.props

    return (
      <>
        <DataTableToolbar>
          <TableToolbarButtons
            downloadHandler={downloadCategoryGuidelinesAction}
            refreshHandler={fetchCategoryGuidelinesAction}
            clearFiltersHandler={clearFiltersAction}
          />
        </DataTableToolbar>
        {loading && <Loader loading overlap />}

        <DataTableFiltered
          data={rows}
          columns={getColumns(hasEditAccess)}
          page={page}
          pageSize={pageSize}
          totalCount={totalCount}
          sortBy={sortBy}
          sortOrder={sortOrder}
          filters={filters}
          tableClassName="category-guideline-table"
          fetchRowsAction={fetchCategoryGuidelinesAction}
        />
      </>
    )
  }
}

CategoryGuideline.defaultProps = {
  rows: [],
  totalCount: 0
}

CategoryGuideline.propTypes = {
  page: PropTypes.number,
  pageSize: PropTypes.number,
  sortBy: PropTypes.string,
  sortOrder: PropTypes.string,
  filters: PropTypes.object,
  loading: PropTypes.bool.isRequired,
  hasEditAccess: PropTypes.bool.isRequired,
  totalCount: PropTypes.number.isRequired,
  rows: PropTypes.arrayOf(PropTypes.object).isRequired,
  fetchCategoryGuidelinesAction: PropTypes.func.isRequired,
  downloadCategoryGuidelinesAction: PropTypes.func.isRequired,
  clearFiltersAction: PropTypes.func.isRequired
}

const mapStateToProps = ({ categoryGuideline }) => ({
  page: categoryGuideline.page,
  sortBy: categoryGuideline.sortBy,
  loading: categoryGuideline.loading,
  rows: categoryGuideline.data.rows,
  pageSize: categoryGuideline.pageSize,
  sortOrder: categoryGuideline.sortOrder,
  totalCount: categoryGuideline.data.count,
  filters: categoryGuideline.filters
})

const mapDispatchToProps = {
  fetchCategoryGuidelinesAction: CategoryGuidelineActions.fetch,
  downloadCategoryGuidelinesAction: CategoryGuidelineActions.download,
  clearFiltersAction: CategoryGuidelineActions.clearFilters
}

export default connect(mapStateToProps, mapDispatchToProps)(CategoryGuideline)
