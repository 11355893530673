import React from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { DATA_TABLE } from 'components/DataTable/constants'
import { BooleanCell, DateCell } from 'components/DataTable'
import ActionsCell from './ActionsCell'
import ResultCell from './ResultCell'
import PassCell from './PassCell'
import TrendResultCell from './TrendResultCell'

import './categoryTestResultCell.scss'

const passedFilter = {
  type: 'SelectFilter',
  options: {
    1: 'Pass',
    0: 'Fail',
    empty: 'Empty',
    notEmpty: 'Tested'
  }
}

const categoryTestsColumns = [
  {
    field: 'id',

    dataAlign: 'center',
    headerAlign: 'center',
    width: '70px',

    label: 'Cat ID',
    title: 'Category ID',
    dataSort: true,
    dataFormat: function getIdCell(cell, row) {
      return (
        <OverlayTrigger
          placement="right"
          overlay={
            <Tooltip id="delivery-job-id-cell-tooltip">
              {row.createdAt ? (
                <>
                  <div className="text-left">Created at: {row.createdAt}</div>
                  <div className="text-left">Updated at: {row.updatedAt}</div>
                  <div className="text-left">Updated by: {row.updatedByName}</div>
                </>
              ) : (
                <div>This test doesn&apos;t have local configuration</div>
              )}
            </Tooltip>
          }
        >
          <span>{cell}</span>
        </OverlayTrigger>
      )
    },
    filter: {
      type: 'TextFilter',
      delay: DATA_TABLE.FILTER_DELAY
    }
  },
  {
    field: 'categoryFullPath',

    dataAlign: 'left',
    headerAlign: 'center',
    width: 'auto',

    label: 'Category full path',
    dataSort: true,
    filter: {
      type: 'TextFilter',
      delay: DATA_TABLE.FILTER_DELAY
    }
  },
  {
    field: 'isDeliveryJobActive',

    dataAlign: 'center',
    headerAlign: 'center',
    width: '70px',

    label: 'Job',
    title: 'Has Delivery Job',
    dataSort: true,
    dataFormat: (cell, row) =>
      BooleanCell({
        isActive: cell,
        titleActive: `Related Delivery Job ID:${row.deliveryJobId}, Stauts: ${row?.deliveryJobStatus?.toUpperCase()}`,
        bsStyle: 'circle'
      }),
    filter: {
      type: 'SelectFilter',
      options: {
        1: 'True',
        0: 'False'
      }
    }
  },
  {
    field: 'dateTestIsPass',

    dataAlign: 'center',
    headerAlign: 'center',
    width: '75px',

    label: 'Date',
    title: 'Date Test status',
    dataSort: true,
    dataFormat: function getTestCell(cell, row) {
      return (
        <ResultCell
          name="Date test"
          result={row.dateTestIsPass === null ? null : <BooleanCell isActive={row.dateTestIsPass} />}
          resultMark=""
          showViolation={false}
          threshold={row.dateTestThreshold}
          isPass={row.dateTestIsPass}
          isActive={row.dateTestIsActive}
          error={row.dateTestError}
        />
      )
    },
    filter: passedFilter
  },
  {
    field: 'dictionaryTestResult',

    dataAlign: 'center',
    headerAlign: 'center',
    width: '80px',

    label: 'Dictionary',
    title: 'Result of Dictionary test',
    dataSort: true,
    dataFormat: function getTestCell(cell, row) {
      return (
        <ResultCell
          name="Dictionary test"
          result={cell}
          threshold={row.dictionaryTestThreshold}
          isPass={row.dictionaryTestIsPass}
          isActive={row.dictionaryTestIsActive}
          error={row.dictionaryTestError}
        />
      )
    },
    filter: {
      type: 'NumberFilter',
      delay: DATA_TABLE.FILTER_DELAY
    }
  },
  {
    field: 'includeTestResult',

    dataAlign: 'center',
    headerAlign: 'center',
    width: '80px',

    label: 'Include',
    title: 'Result of Include test',
    dataSort: true,
    dataFormat: function getTestCell(cell, row) {
      return (
        <ResultCell
          name="Include test"
          result={cell}
          threshold={row.includeTestThreshold}
          isPass={row.includeTestIsPass}
          isActive={row.includeTestIsActive}
          query={{
            groupName: row.includeTestGroupQueryName,
            queryName: row.includeTestQueryName
          }}
          error={row.includeTestError}
        />
      )
    },
    filter: {
      type: 'NumberFilter',
      delay: DATA_TABLE.FILTER_DELAY
    }
  },
  {
    field: 'excludeTestResult',

    dataAlign: 'center',
    headerAlign: 'center',
    width: '80px',

    label: 'Exclude',
    title: 'Result of Exclude test',
    dataSort: true,
    dataFormat: function getTestCell(cell, row) {
      return (
        <ResultCell
          name="Exclude test"
          result={cell}
          threshold={row.excludeTestThreshold}
          isPass={row.excludeTestIsPass}
          isActive={row.excludeTestIsActive}
          query={{
            groupName: row.excludeTestGroupQueryName,
            queryName: row.excludeTestQueryName
          }}
          error={row.excludeTestError}
        />
      )
    },
    filter: {
      type: 'NumberFilter',
      delay: DATA_TABLE.FILTER_DELAY
    }
  },
  {
    field: 'misbrandTestResult',

    dataAlign: 'center',
    headerAlign: 'center',
    width: '80px',

    label: 'Misbrand',
    title: 'Result of Misbrand test',
    dataSort: true,
    dataFormat: function getTestCell(cell, row) {
      return (
        <ResultCell
          name="Misbrand test"
          result={cell}
          threshold={row.misbrandTestThreshold}
          isPass={row.misbrandTestIsPass}
          isActive={row.misbrandTestIsActive}
          error={row.misbrandTestError}
          query={{
            groupName: row.misbrandTestGroupQueryName,
            queryName: row.misbrandTestQueryName
          }}
        />
      )
    },
    filter: {
      type: 'NumberFilter',
      delay: DATA_TABLE.FILTER_DELAY
    }
  },
  {
    field: 'coverageTestResult',

    dataAlign: 'center',
    headerAlign: 'center',
    width: '95px',

    label: 'Coverage',
    title: 'Result of Include Coverage test',
    dataSort: true,
    dataFormat: function getTestCell(cell, row) {
      return (
        <ResultCell
          name="Include Coverage test"
          result={cell}
          threshold={row.coverageTestThreshold}
          isPass={row.coverageTestIsPass}
          isActive={row.coverageTestIsActive}
          error={row.coverageTestError}
          query={{
            groupName: row.coverageTestGroupQueryName,
            queryName: row.coverageTestQueryName
          }}
        />
      )
    },
    filter: {
      type: 'NumberFilter',
      delay: DATA_TABLE.FILTER_DELAY
    }
  },
  {
    field: 'newBrandTestResult',

    dataAlign: 'center',
    headerAlign: 'center',
    width: '100px',

    label: 'New Brand',
    title: 'Result of New Brand test',
    dataSort: true,
    dataFormat: function getTestCell(cell, row) {
      return (
        <ResultCell
          name="New Brand test"
          result={cell}
          threshold={row.newBrandTestThreshold}
          isPass={row.newBrandTestIsPass}
          isActive={row.newBrandTestIsActive}
          error={row.newBrandTestError}
          query={{
            groupName: row.newBrandTestGroupQueryName,
            queryName: row.newBrandTestQueryName
          }}
        />
      )
    },
    filter: {
      type: 'NumberFilter',
      delay: DATA_TABLE.FILTER_DELAY
    }
  },
  {
    field: 'newBrandMonthTestResult',

    dataAlign: 'center',
    headerAlign: 'center',
    width: '105px',

    label: '3M New Brand',
    title: 'Result of New Brand 3 Months test',
    dataSort: true,
    dataFormat: function getTestCell(cell, row) {
      return (
        <ResultCell
          name="New Brand 3 Months test"
          result={cell}
          threshold={row.newBrandMonthTestThreshold}
          isPass={row.newBrandMonthTestIsPass}
          isActive={row.newBrandMonthTestIsActive}
          error={row.newBrandMonthTestError}
          query={{
            groupName: row.newBrandTestGroupQueryName,
            queryName: row.newBrandTestQueryName
          }}
        />
      )
    },
    filter: {
      type: 'NumberFilter',
      delay: DATA_TABLE.FILTER_DELAY
    }
  },
  {
    field: 'merchantTrendTestIsPass',

    dataAlign: 'center',
    headerAlign: 'center',
    width: '105px',

    label: 'Merchant Trend',
    title: 'Result of Category Merchant Trend test',
    dataSort: true,
    dataFormat: function getTestCell(cell, row) {
      return (
        <TrendResultCell
          name="Merchant Trend test"
          result={row.merchantTrendTestResult}
          threshold={row.merchantTrendTestThreshold}
          sensitivity={row.merchantTrendTestSensitivity}
          rank={row.merchantTrendTestRank}
          isPass={row.merchantTrendTestIsPass}
          isActive={row.merchantTrendTestIsActive}
          error={row.merchantTrendTestInfo?.error}
        />
      )
    },
    filter: passedFilter
  },
  {
    field: 'brandTrendTestIsPass',

    dataAlign: 'center',
    headerAlign: 'center',
    width: '105px',

    label: 'Brand Trend',
    title: 'Result of Category Brand Trend test',
    dataSort: true,
    dataFormat: function getTestCell(cell, row) {
      return (
        <TrendResultCell
          name="Brand Trend test"
          result={row.brandTrendTestResult}
          threshold={row.brandTrendTestThreshold}
          sensitivity={row.brandTrendTestSensitivity}
          rank={row.brandTrendTestRank}
          isPass={row.brandTrendTestIsPass}
          isActive={row.brandTrendTestIsActive}
          error={row.brandTrendTestInfo?.error}
        />
      )
    },
    filter: passedFilter
  },
  {
    field: 'overallTrendTestIsPass',

    dataAlign: 'center',
    headerAlign: 'center',
    width: '105px',

    label: 'Trend',
    title: 'Result of Category overall Trend test',
    dataSort: true,
    dataFormat: function getTestCell(cell, row) {
      return (
        <TrendResultCell
          name="Overall Trend test"
          result={row.overallTrendTestResult}
          threshold={row.overallTrendTestThreshold}
          sensitivity={row.overallTrendTestSensitivity}
          isPass={row.overallTrendTestIsPass}
          isActive={row.overallTrendTestIsActive}
          error={row.overallTrendTestInfo?.error}
          isOverallTrend
        />
      )
    },
    filter: passedFilter
  },
  {
    field: 'isTestPass',

    dataAlign: 'center',
    headerAlign: 'center',
    width: '80px',

    label: 'Pass',
    title: 'Final result of all tests',
    isAlwaysVisible: true,
    dataSort: true,
    dataFormat: (cell, row) => PassCell({ isTestPass: cell, row }),
    filter: passedFilter
  },
  {
    field: 'testedAt',

    dataAlign: 'center',
    headerAlign: 'center',
    width: '160px',
    label: 'Tested Date',
    dataSort: true,
    dataFormat: (cell, row) => DateCell({ date: cell }),
    filter: {
      type: 'DateFilter',
      delay: DATA_TABLE.FILTER_DELAY
    }
  },
  {
    field: 'analystName',

    dataAlign: 'center',
    headerAlign: 'center',
    width: '140px',

    label: 'Analyst',
    dataSort: true,
    filter: {
      type: 'TextFilter',
      delay: DATA_TABLE.FILTER_DELAY
    }
  },
  {
    field: 'isUseLocalConfig',

    dataAlign: 'center',
    headerAlign: 'center',
    width: '70px',

    label: 'MOD',
    title: 'TRUE means test is modified to use Local Thresholds. (Will be unaffected by any Global changes.)',
    dataSort: true,
    dataFormat: (cell, row) =>
      BooleanCell({ isActive: cell, titleActive: 'Use local test configuration', bsStyle: 'circle' }),
    filter: {
      type: 'SelectFilter',
      options: {
        1: 'True',
        0: 'False'
      }
    }
  },
  {
    field: 'isAutoSubmit',

    dataAlign: 'center',
    headerAlign: 'center',
    width: '80px',

    label: 'Submit',
    title: 'Auto Submit',
    dataSort: true,
    dataFormat: (cell, row) =>
      BooleanCell({
        isActive: cell,
        titleActive: 'Automatically submit related Delivery Job if test was passed',
        bsStyle: 'circle'
      }),
    filter: {
      type: 'SelectFilter',
      options: {
        1: 'True',
        0: 'False'
      }
    }
  },
  {
    field: 'actionsColumn',
    label: 'Actions',
    dataAlign: 'center',
    headerAlign: 'center',
    width: '65px',
    dataFormat: function getActionCell(cell, row) {
      return <ActionsCell row={row} />
    }
  }
]

export default categoryTestsColumns
