import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Glyphicon, Button, Tooltip } from 'react-bootstrap'
import EditValue from '../EditValue'

class staged extends Component {
  static propTypes = {
    stagedValue: PropTypes.object,
    dictKey: PropTypes.string,
    attribute: PropTypes.string,
    removeStagedEdit: PropTypes.func
  }

  getTooltip = name => <Tooltip id={`description-staged-${this.props.attribute}`}>{name}</Tooltip>
  render() {
    const { stagedValue, dictKey, attribute } = this.props
    return (
      <div className={'cell-staged-value'}>
        {stagedValue.name}
        <EditValue getTooltip={() => this.getTooltip('Revert')}>
          <Button
            className="cell-clear-button"
            bsStyle="link"
            onClick={e => {
              e.preventDefault()
              this.props.removeStagedEdit(dictKey, attribute)
            }}
          >
            <Glyphicon glyph="remove" />
          </Button>
        </EditValue>
      </div>
    )
  }
}

export default staged
