/* eslint-disable react/prop-types */
import React from 'react'
import moment from 'moment'

import { TableHeaderColumn } from 'react-bootstrap-table'

const DISPLAY_DATE_FORMAT = 'MM/DD/YYYY'

const DELIVERY_FIELDS = ['id', 'deliveryDate', 'completionDate', 'notes']

const renderDisplayDate = cell => <span>{cell ? moment.utc(cell).format(DISPLAY_DATE_FORMAT) : '- -'}</span>

const renderDeliveryNotes = cell => <span>{cell || '- -'}</span>

const formatDeliveryStatus = (cell, row) => {
  if (cell === null) return <span>- -</span>

  const { status, deliveryDate, completionDate } = row
  const formatDate = date => moment.utc(date).format(DISPLAY_DATE_FORMAT)

  if (status === 'CANCELLED') {
    return <span className="delivery-status-red">{`Cancelled on ${formatDate(completionDate)}`}</span>
  }

  if (moment(completionDate).isAfter(moment(deliveryDate))) {
    return <span className={'delivery-status-red'}>{`Late Delivery on ${formatDate(completionDate)}`}</span>
  }

  return <span>{`Delivered on ${formatDate(completionDate)}`}</span>
}

const formatDeliveryCount = (cell, row, formatExtraData, rowIdx) => {
  const { deliveryCount, page, pageSize } = formatExtraData
  // account for page offset
  const deliveryNumber = deliveryCount - (page - 1) * pageSize - rowIdx
  return <span>{deliveryNumber}</span>
}

const getColumn = field => {
  const defaultColumn = {
    field,
    label: field,
    dataSort: false,
    dataAlign: 'center',
    headerAlign: 'center',
    caretRender: () => {}
  }

  let config = {}

  switch (field) {
    case 'id':
      config = {
        label: '',
        width: '100px',
        dataFormat: formatDeliveryCount
      }
      break

    case 'deliveryDate':
      config = {
        label: 'Deadline',
        dataFormat: renderDisplayDate,
        width: '200px'
      }
      break

    case 'completionDate':
      config = {
        label: 'Date Delivered',
        dataFormat: formatDeliveryStatus,
        width: '300px'
      }
      break

    case 'notes':
      config = {
        label: 'Notes',
        dataAlign: 'left',
        headerAlign: 'left',
        dataFormat: renderDeliveryNotes
      }
      break

    default:
      break
  }

  return {
    ...defaultColumn,
    ...config
  }
}

const buildPastDeliveryColumns = ({ deliveryCount, page, pageSize }) => {
  const columns = DELIVERY_FIELDS.map(field => getColumn(field))

  const headerColumns = columns.map((column, index) => {
    const { field, label, ...otherProps } = column

    return (
      <TableHeaderColumn
        {...otherProps}
        ref={field}
        dataField={field}
        key={`col-${index}`}
        isKey={field === 'id'}
        formatExtraData={{
          page,
          pageSize,
          deliveryCount
        }}
      >
        {label}
      </TableHeaderColumn>
    )
  })

  return headerColumns
}

export default buildPastDeliveryColumns
