import React, { useState } from 'react'
import { Autocomplete, Box, TextField, Typography } from '@mui/material'
import PropTypes from 'prop-types'

import useDebounce from 'utils/debounce/useDebounce'
import { useGetSupraDomainSampleQuery } from '../../SupraTaxonomyPage/supraTaxonomyAPI'

const DomainAutocomplete = ({ onClickHandler }) => {
  const [inputValue, setInputValue] = useState('')

  const inputValueDebounced = useDebounce(inputValue, 400)
  const { data: domainSample, isFetching, error } = useGetSupraDomainSampleQuery({
    inputValue: inputValueDebounced
  })

  const inputSelectList = domainSample?.map(el => ({ ...el, label: el.domain }))

  return (
    <Box>
      <Typography variant="subtitle1" sx={{ py: 1 }}>
        *Domain Select
      </Typography>
      <Autocomplete
        disablePortal
        id="supra-whitelist-domain"
        options={inputSelectList || []}
        sx={{ width: 300 }}
        onChange={(_, value) => onClickHandler(value)}
        onInputChange={(_, value) => setInputValue(value)}
        renderInput={params => <TextField {...params} label="Domain" />}
        isOptionEqualToValue={(options, value) => options.valueOf === value.valueOf}
        loading={isFetching}
        noOptionsText={error ? 'Request failed.' : 'No matches found.'}
      />
    </Box>
  )
}

DomainAutocomplete.propTypes = {
  onClickHandler: PropTypes.func
}

export default DomainAutocomplete
