import React from 'react'
import { Box } from '@mui/material'
import Caption from 'mui/components/Caption/Caption'
import TableDAG from './TableDAG/TableDAG'
import JobProcessBlock from './JobProcessBlock/JobProcessBlock'
import WithErrorBoundaryWrapper from 'components/WithErrorBoundaryWrapper/WithErrorBoundaryWrapper'

const SupraOrchestratorPage = () => {
  return (
    <Box sx={{ mx: 1 }}>
      <Caption title="Job process for selected domain" />
      <JobProcessBlock />
      <Caption title="Orchestration Log (Not working version)" />
      <TableDAG />
    </Box>
  )
}

export default WithErrorBoundaryWrapper(SupraOrchestratorPage, '"Supra Orchestrator" page')
