import React from 'react'
import { Controller } from 'react-hook-form'
import { FormControl } from 'react-bootstrap'
import PropTypes from 'prop-types'
import InputField from 'components/FormInputComponents/InputField'

const MaxTemplatesInput = ({ control, errors, info }) => {
  return (
    <InputField
      label="Max Templates"
      required
      info={info}
      validationMessage={errors.maxTemplates?.message}
      className="text-start"
      labelSize={4}
    >
      <Controller
        name="maxTemplates"
        control={control}
        render={({ field }) => <FormControl {...field} type="number" />}
      />
    </InputField>
  )
}

MaxTemplatesInput.propTypes = {
  control: PropTypes.object.isRequired,
  info: PropTypes.string,
  errors: PropTypes.object
}

export default MaxTemplatesInput
