export default {
  NAME: 'Slice Intelligence',
  appBaseUrls: {
    development: 'http://localhost:4000',
    staging: 'https://staging.portal.goslice.com',
    production: 'https://d2992ppy5fps7s.cloudfront.net'
  },
  serverBaseUrls: {
    development: 'http://localhost:8080',
    staging: 'https://portal-staging-ri-api.slicetest.com',
    production: 'https://portal-production-ri-api.slicetest.com'
  },
  sentry: {
    development: 'https://6cbd54974bdd4e0686b107839ebc8a87@sentry.io/1327877',
    // cicd: 'https://6cbd54974bdd4e0686b107839ebc8a87@sentry.io/1327877',
    staging: 'https://6cbd54974bdd4e0686b107839ebc8a87@sentry.io/1327877',
    production: 'https://6cbd54974bdd4e0686b107839ebc8a87@sentry.io/1327877'
  },
  colors: {
    primaryColor: '#32AEC0',
    secondaryColor: '#EE6B50',
    dayCellColor: '#66E2DA'
  }
}
