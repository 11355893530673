import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { clearModals } from 'actions/modal-actions'
import DeliveryJobModalLayout from './DeliveryJobModalLayout'

import deliveryJobsActions from './../deliveryJobsActions'

class DeliveryJobEditModal extends Component {
  constructor(props) {
    super(props)
    const { deliveryJobId, analystNotes } = this.props

    this.state = {
      id: deliveryJobId,
      analystNotes: analystNotes || '',
      isFormChanged: false
    }
  }

  handleFieldChange = newValue => {
    this.setState({ ...newValue, isFormChanged: true })
  }

  handleOkButton = () => {
    const { id, analystNotes } = this.state

    this.props.saveDeliveryJobAction({
      id: id,
      analystNotes
    })

    this.props.clearModals()
  }

  getUISettings = () => {
    const settings = {
      infoMessage: '',
      infoStyle: null, // '', success, info, warning, danger
      okButtonName: 'Edit',
      isOkButtonDisabled: false
    }

    return settings
  }

  render() {
    const { analystNotes, isFormChanged } = this.state

    const { clearModals } = this.props

    const { infoMessage, infoStyle, okButtonName, isOkButtonDisabled } = this.getUISettings()
    const isOkButtonDisabled2 = isFormChanged ? isOkButtonDisabled : true

    return (
      <DeliveryJobModalLayout
        analystNotes={analystNotes}
        infoMessage={infoMessage}
        infoStyle={infoStyle}
        handleFieldChange={this.handleFieldChange}
        handleCategoryChange={this.handleCategoryChange}
        okButtonName={okButtonName}
        isOkButtonDisabled={isOkButtonDisabled2}
        onCancelHandler={clearModals}
        onOkHandler={this.handleOkButton}
      />
    )
  }
}

DeliveryJobEditModal.defaultProps = {
  deliveryJobId: null,
  categoryId: undefined,
  categoryFullPath: undefined,
  brandId: undefined,
  brandFullPath: undefined,

  ruleLoading: false,
  rule: null
}

DeliveryJobEditModal.propTypes = {
  deliveryJobId: PropTypes.number,
  categoryId: PropTypes.number,
  deliveryDate: PropTypes.string,
  toClient: PropTypes.bool,
  notes: PropTypes.string,
  analystNotes: PropTypes.string,

  clearModals: PropTypes.func.isRequired,
  saveDeliveryJobAction: PropTypes.func.isRequired,

  deliveryJob: PropTypes.object
}

const mapDispatchToProps = {
  clearModals,
  saveDeliveryJobAction: deliveryJobsActions.save
}

export default connect(null, mapDispatchToProps)(DeliveryJobEditModal)
