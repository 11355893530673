import React, { Component } from 'react'
import PropTypes from 'prop-types'
import SplitByChart from './SplitByChart'
import { Button } from 'react-bootstrap'
import { connect } from 'react-redux'
import Loader from '../../Loader'
import {
  onDrillDownByChanged,
  showMoreSplitByCharts,
  onSelectedTimeRangeChanged,
  refreshQCGraphData
} from '../../../actions/qc-actions'
import { isEmpty, keys } from 'lodash'

class SplitByChartWidget extends Component {
  render() {
    const {
      drillDownBy,
      splitBy,
      chartView,
      data,
      title,
      onDrillDownByChanged,
      onSelectedTimeRangeChanged,
      loading,
      drillDownOptions,
      refreshQCGraphData
    } = this.props
    const isDataEmpty = isEmpty(data.revenue) && isEmpty(data.last_revenue)
    if (isDataEmpty) {
      return <div />
    }

    const shouldShowMore = splitBy !== 'none' && drillDownOptions[splitBy].data.length > keys(data.revenue).length

    return (
      <div className="qctool-widget">
        {loading && isDataEmpty ? (
          <Loader />
        ) : (
          <div>
            <SplitByChart
              drillDownBy={drillDownBy}
              splitBy={splitBy}
              chartView={chartView}
              data={data}
              title={title}
              onDrillDownByChanged={onDrillDownByChanged}
              onSelectedTimeRangeChanged={onSelectedTimeRangeChanged}
              refreshQCGraphData={refreshQCGraphData}
            />
            {shouldShowMore && (
              <div style={{ padding: '10px' }}>
                <Button
                  disabled={loading}
                  onClick={value => this.props.showMoreSplitByCharts(value)}
                  className="center-block"
                  bsStyle="warning"
                >
                  Show More
                </Button>
              </div>
            )}
          </div>
        )}
      </div>
    )
  }
}

SplitByChartWidget.propTypes = {
  drillDownBy: PropTypes.object,
  splitBy: PropTypes.string,
  data: PropTypes.object,
  title: PropTypes.string,
  chartView: PropTypes.string,
  loading: PropTypes.bool,
  onDrillDownByChanged: PropTypes.func,
  showMoreSplitByCharts: PropTypes.func,
  onSelectedTimeRangeChanged: PropTypes.func,
  refreshQCGraphData: PropTypes.func,
  drillDownOptions: PropTypes.object
}

function mapStateToProps(state) {
  return {
    drillDownBy: state.searchQC.drillDownBy,
    splitBy: state.searchQC.splitBy,
    chartView: state.searchQC.chartView,
    data: state.searchQC.splitByChart.data,
    loading: state.searchQC.splitByChart.loading,
    title: ``,
    drillDownOptions: state.searchQC.drillDownOptions
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onDrillDownByChanged: value => dispatch(onDrillDownByChanged(value)),
    showMoreSplitByCharts: value => dispatch(showMoreSplitByCharts(value)),
    onSelectedTimeRangeChanged: value => dispatch(onSelectedTimeRangeChanged(value)),
    refreshQCGraphData: payload => dispatch(refreshQCGraphData(payload))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SplitByChartWidget)
