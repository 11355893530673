import { useState, useCallback } from 'react'
import { getElementValue } from './InputField'
import { isEqual, sortBy } from 'lodash'

/**
 * This hook is responsible for storing and changing form value
 * Also return information when and which fields were changed
 */
export default function useFormValues(initValues) {
  const [values, setValue] = useState(initValues)
  const [isFormChanged, setIsFormChanged] = useState(false)

  const [lastChangedFields, setLastChangedFields] = useState([])

  const updateValue = useCallback(newValueArg => {
    let newValue
    if (newValueArg instanceof Function) {
      setValue(values => {
        newValue = newValueArg(values)
        return { ...values, ...newValue }
      })
    } else {
      newValue = newValueArg
      setValue(values => ({ ...values, ...newValue }))
    }

    setIsFormChanged(true)

    setLastChangedFields(prevFields => {
      const newFields = Object.keys(newValue)
      const isEqualFields = isEqual(sortBy(prevFields), sortBy(newFields))
      // deos't replace array if no new values it provides reference equality
      return isEqualFields ? prevFields : newFields
    })

    return newValue
  }, [])

  /**
   * Use hook useCallback for returning the same function all time
   * This is necessary for performance optimization React.memo() (using in InputField component)
   */
  const handleFieldChange = useCallback(typeEvent => updateValue(getElementValue(typeEvent.target)), [updateValue])

  return { values, isFormChanged, handleFieldChange, updateValue, lastChangedFields }
}
