import React from 'react'
import PropTypes from 'prop-types'

const ValueWithMark = ({ children, mark = '', nullValue = 'null' }) => {
  const value = children
  return value === null ? (
    <>{nullValue}</>
  ) : (
    <>
      {value}
      {mark}
    </>
  )
}

ValueWithMark.propTypes = {
  children: PropTypes.node,
  mark: PropTypes.string,
  nullValue: PropTypes.string
}

export default ValueWithMark
