export const STEP = {
  SELECT_DOMAIN: 'Select domain',
  WHITELISTED: 'Whitelisted',
  BRONZE_TO_SILVER: 'Bronze to Silver',
  RECRAWLING: 'Recrawling'
}

export const STATUS = {
  NONE: '-------',
  PENDING: 'Pending...',
  RUNNING: 'Running...',
  DONE: 'Done.',
  SKIPPED: 'Skipped.',
  TERMINATED: 'Terminated.'
}

export const ORCHESTRATOR_DATE_FORMAT = 'yyyy-MM-dd HH:mm:ss'
