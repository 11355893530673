import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import moment from 'moment'

import DateRangeFilter from '../../content/DateRangeFilter'
import { Button } from 'react-bootstrap'

import { updateDateSelectionETLStats } from '../../../actions/etl-stats-actions'

class ETLStatsFilter extends Component {
  constructor(props) {
    super(props)

    this.state = {
      filterSearch: '',
      filterType: 'type'
    }
  }

  componentDidMount() {
    this.fetchTasks()
  }

  componentDidUpdate(prevProps) {
    if (
      (prevProps.dateFilterTo !== this.props.dateFilterTo || prevProps.dateFilterFrom !== this.props.dateFilterFrom) &&
      !this.props.loading
    ) {
      this.fetchTasks()
    }
  }

  fetchTasks = () => {
    const { filterType, filterSearch } = this.state
    const { dateFilterTo, dateFilterFrom } = this.props

    const timeFrameQuery = {
      $lte: dateFilterTo,
      $gte: dateFilterFrom
    }

    let filterQuery = {}

    if (filterSearch !== '') {
      filterQuery = { [filterType]: filterSearch }
    }

    const taskPayload = {
      endedAt: timeFrameQuery,
      ...filterQuery
    }

    this.props.fetchETLStats(taskPayload)
  }

  onFilterChange = event => {
    const filterSearch = (event.target && event.target.value) || ''
    this.setState({ filterSearch })
  }

  onFilterSelect = event => {
    const filterType = (event.target && event.target.value) || ''
    this.setState({ filterType })
  }

  onDateSelection = selection => {
    const [from, to] = selection

    this.props.updateDateSelectionETLStats({
      to: moment(to).toISOString(),
      from: moment(from).toISOString()
    })
  }

  getFilterOptions = () => {
    return this.props.filterOptions.map((filterOption, idx) => (
      <option key={`${filterOption.value}-${idx}`} value={filterOption.value}>
        {filterOption.display}
      </option>
    ))
  }

  getTextFilter = () => (
    <div id="etl-stats-text-filter">
      <select onChange={this.onFilterSelect}>{this.getFilterOptions()}</select>

      <input type="search" value={this.state.filterSearch} onChange={this.onFilterChange} />
    </div>
  )

  render() {
    const { rangeStart, rangeEnd, dateFilterTo, dateFilterFrom } = this.props

    return (
      <div className="etl-stats-filters">
        <div>
          <DateRangeFilter
            label={'Date Selection'}
            rangeEnd={rangeEnd}
            rangeStart={rangeStart}
            endDate={dateFilterTo}
            startDate={dateFilterFrom}
            handleDateSelection={this.onDateSelection}
          />
        </div>

        {this.getTextFilter()}

        <div id="etl-stats-filter-button">
          <Button onClick={this.fetchTasks}>Filter</Button>
        </div>
      </div>
    )
  }
}

ETLStatsFilter.defaultProps = {
  dateFilterFrom: moment()
    .subtract(1, 'day')
    .startOf('day')
    .toISOString(),
  dateFilterTo: moment()
    .endOf('day')
    .toISOString(),
  filterOptions: [
    { value: 'type', display: 'Task Type' },
    { value: 'userId', display: 'User' }
  ]
}

ETLStatsFilter.propTypes = {
  loading: PropTypes.bool,
  rangeEnd: PropTypes.string,
  rangeStart: PropTypes.string,
  fetchETLStats: PropTypes.func,
  filterOptions: PropTypes.array,
  dateFilterTo: PropTypes.string,
  dateFilterFrom: PropTypes.string,
  updateDateSelectionETLStats: PropTypes.func
}

const mapStateToProps = ({ etlStats: { dateFilterSelection, tasksTimeframe } }) => ({
  dateFilterTo: dateFilterSelection.to,
  dateFilterFrom: dateFilterSelection.from,
  rangeStart: tasksTimeframe.start,
  rangeEnd: tasksTimeframe.end
})

export default connect(mapStateToProps, { updateDateSelectionETLStats })(ETLStatsFilter)
