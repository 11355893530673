import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Grid, Row, Col, Well, Radio } from 'react-bootstrap'
import '../../styles/qcTool.scss'
import SplitByChartWidget from './qcToolWidgets/SplitByChartWidget'
import DrilldownChartWidget from './qcToolWidgets/DrilldownChartWidget'
import QcToolbar from './qcToolWidgets/QcToolbar'
import QCDescription from './qcToolWidgets/QCDescription'
import {
  onSplitByChanged,
  onDrillDownByChanged,
  onChartViewChanged,
  onTimeIntervalChanged,
  onSelectedTimeRangeChanged,
  onChangeDescriptionItemType
} from '../../actions/qc-actions'
import ReactHighstock from 'react-highcharts/ReactHighstock'
import { simpleNumberFormatter } from '../../utils/formatters'
import { isEmpty, forIn } from 'lodash'
import constants from '../../constants/constants'

class QCWrapper extends Component {
  static propTypes = {
    splitBy: PropTypes.string,
    drillDownBy: PropTypes.object,
    drillDownOptions: PropTypes.object,
    timeInterval: PropTypes.string,
    chartView: PropTypes.string,
    onSplitByChanged: PropTypes.func.isRequired,
    onDrillDownByChanged: PropTypes.func.isRequired,
    onChartViewChanged: PropTypes.func.isRequired,
    onTimeIntervalChanged: PropTypes.func.isRequired,
    onSelectedTimeRangeChanged: PropTypes.func,
    loading: PropTypes.bool,
    from: PropTypes.number,
    to: PropTypes.number,
    data: PropTypes.object,
    contextFilters: PropTypes.array,
    rangeStart: PropTypes.string,
    rangeEnd: PropTypes.string,
    onChangeDescriptionItemType: PropTypes.func,
    descriptionItemType: PropTypes.string,
    descriptionTypeCounts: PropTypes.object
  }

  componentDidMount() {
    this.setExtremesValues(this.props.from, this.props.to)
  }

  componentDidUpdate(prevProps) {
    if (this.props.from !== prevProps.from || this.props.to !== prevProps.to) {
      this.setExtremesValues(this.props.from, this.props.to)
    }
  }

  setExtremesValues(from, to) {
    if (ReactHighstock.Highcharts.charts) {
      ReactHighstock.Highcharts.charts.forEach(chart => {
        if (chart) {
          if (chart.xAxis[0].setExtremes) {
            chart.xAxis[0].setExtremes(from, to, true, true, {
              trigger: 'syncExtremes'
            })
          }
        }
      })
    }
  }

  getItemTypeOptions = () => {
    const optionType = []
    const { descriptionItemType, onChangeDescriptionItemType, descriptionTypeCounts } = this.props
    // TODO: restyle this
    forIn(constants.itemTypes, (value, key) => {
      const count = descriptionTypeCounts[value]
      optionType.push(
        <Radio
          key={key}
          checked={descriptionItemType === value}
          onChange={() => onChangeDescriptionItemType(value)}
          disabled={!count}
        >
          <label className={'label control-label'}>
            {`${value} : ${count == null ? '' : simpleNumberFormatter(count)}`}
          </label>
        </Radio>
      )
    })
    return optionType
  }

  render() {
    if (!this.props.contextFilters.length) {
      return <span className="qc-default-title">{'No context/filters selected'}</span>
    }

    return (
      <Grid bsClass="container-fluid" className="qc-wrapper">
        <Row className="qc-filters-wrapper">
          <Col xs={12} md={12} mdPush={0} className="qc-filters">
            <QcToolbar
              splitBy={this.props.splitBy}
              drillDownBy={this.props.drillDownBy}
              drillDownOptions={this.props.drillDownOptions}
              onSplitByChanged={this.props.onSplitByChanged}
              onDrillDownByChanged={this.props.onDrillDownByChanged}
              timeInterval={this.props.timeInterval}
              chartView={this.props.chartView}
              onChartViewChanged={this.props.onChartViewChanged}
              onTimeIntervalChanged={this.props.onTimeIntervalChanged}
              onSelectedTimeRangeChanged={this.props.onSelectedTimeRangeChanged}
              loading={this.props.loading}
              data={this.props.data}
              from={this.props.from}
              to={this.props.to}
              rangeStart={this.props.rangeStart}
              rangeEnd={this.props.rangeEnd}
            />
          </Col>
        </Row>
        <Row className="qc-charts-wrapper">
          <Col xs={12} md={6} mdPush={0} className="qc-split-by-chart">
            <Well bsClass="well-qc-split-by-chart">
              <SplitByChartWidget />
            </Well>
          </Col>
          <Col xs={12} md={6} mdPush={0} className="qc-drill-down-chart">
            <Well bsClass="well-qc-split-by-chart">
              <DrilldownChartWidget />
            </Well>
          </Col>
        </Row>
        <hr />
        <Row className="qc-description-counts">
          <Col xs={12} md={12} mdPush={0} className="qc-description-counts">
            {// TODO: create a separate component once design/features are finalized
            !isEmpty(this.props.descriptionTypeCounts) && this.getItemTypeOptions()}
          </Col>
        </Row>
        <Row className="qc-description-wrapper">
          <Col xs={12} md={12} mdPush={0} className="qc-description-table">
            <QCDescription />
          </Col>
        </Row>
      </Grid>
    )
  }
}
function mapStateToProps(state) {
  return {
    contextFilters: state.search.searchFilters.categoryFilterList,
    drillDownBy: state.searchQC.drillDownBy,
    drillDownOptions: state.searchQC.drillDownOptions,
    splitBy: state.searchQC.splitBy,
    timeInterval: state.searchQC.timeInterval,
    chartView: state.searchQC.chartView,
    loading: state.searchQC.drillDownOptions.loading,
    from: state.searchQC.from,
    to: state.searchQC.to,
    rangeStart: state.searchQC.rangeStart,
    rangeEnd: state.searchQC.rangeEnd,
    data: state.searchQC.splitByChart.data,
    descriptionTypeCounts: state.searchQC.descriptionTypeCounts,
    descriptionItemType: state.searchQC.descriptionItemType
  }
}
function mapDispatchToProps(dispatch) {
  return {
    onSplitByChanged: value => dispatch(onSplitByChanged(value)),
    onDrillDownByChanged: value => dispatch(onDrillDownByChanged(value)),
    onChartViewChanged: value => dispatch(onChartViewChanged(value)),
    onTimeIntervalChanged: value => dispatch(onTimeIntervalChanged(value)),
    onSelectedTimeRangeChanged: value => dispatch(onSelectedTimeRangeChanged(value)),
    onChangeDescriptionItemType: value => dispatch(onChangeDescriptionItemType(value))
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(QCWrapper)
