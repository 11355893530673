import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import ChipSelectField from '../../../Common/ChipSelectField'
import TextInputField from '../../../Common/TextInputField'
import DeliverySchedule from './DeliverySchedule'
import { Form, Button } from 'react-bootstrap'
import Loader from '../../../../Loader'

import {
  onClientSearchChange,
  onContractSearchChange,
  checkIfDeliverableNameAvailable
} from '../../../../../actions/dm-actions'
import {
  updateBasicDetailsForm,
  resetBasicDetailsForm,
  saveBasicDetailsForm
} from '../../../../../actions/delivery-config-actions'
import { debounce, pick } from 'lodash'
import './index.scss'

class BasicConfig extends Component {
  getValidationDetails(key, value) {
    const payload = {}
    switch (key) {
      case 'name':
        payload.isNameValid = false
        if (value && value.trim()) payload.isNameValid = true
        break
      case 'Client':
        payload.isClientValid = false
        if (value && value.id) payload.isClientValid = true
        break
      case 'Contract':
        payload.isContractValid = false
        if (value && value.id) payload.isContractValid = true
        break
      default:
    }
    return payload
  }

  updateFormValue = (key, value) => {
    if (!this.props.editable) {
      return
    }
    this.updateDetails({
      [key]: value,
      ...this.getValidationDetails(key, value)
    })
  }

  updateDetails = payload => {
    if (!this.props.editable) {
      return
    }
    this.props.updateBasicDetailsForm({
      ...payload,
      dirty: true
    })
  }

  onClientSearchChange = debounce(this.props.onClientSearchChange, 500)
  handleContractSearchChange = value => {
    const { onContractSearchChange, Client } = this.props
    const { id: clientId } = Client || {}
    onContractSearchChange(value, clientId)
  }

  onContractSearchChange = debounce(this.handleContractSearchChange, 500)

  handleClientChange = value => {
    const { deliverableId, name, Contract } = this.props
    const { id: contractId } = Contract || {}
    this.updateFormValue('Client', value || undefined)
    if (!value && contractId) {
      this.updateFormValue('Contract', undefined)
    }
    if (value) {
      if (value && value.id && name && contractId) {
        this.checkIfDeliverableNameAvailable({
          id: deliverableId,
          name,
          clientId: value.id,
          contractId
        })
      }
    }
  }

  handleContractChange = value => {
    const { deliverableId, name, Client } = this.props
    const { id: clientId } = Client || {}
    this.updateFormValue('Contract', value || undefined)
    if (value && value.id && name && clientId) {
      this.checkIfDeliverableNameAvailable({
        id: deliverableId,
        name,
        contractId: value.id,
        clientId
      })
    }
  }

  handleNameChange = event => {
    const { deliverableId, Client: { id: clientId } = {}, Contract: { id: contractId } = {} } = this.props
    const { value } = event.target
    this.updateFormValue('name', value)
    if (clientId && contractId && value) {
      this.checkIfDeliverableNameAvailable({
        id: deliverableId,
        name: value,
        clientId,
        contractId
      })
    }
  }

  checkIfDeliverableNameAvailable = debounce(this.props.checkIfDeliverableNameAvailable, 500)

  hasValidChangesToSave = () => {
    const {
      name,
      Client,
      Contract,
      isNameValid,
      isNameAvailable,
      isClientValid,
      isContractValid,
      dirty,
      firstDelivery,
      totalDeliveries
    } = this.props
    if (
      // changes done
      dirty === true &&
      // changes are not inValid
      isNameValid !== false &&
      isNameAvailable !== false &&
      isClientValid !== false &&
      isContractValid !== false &&
      // mandatory values are there
      name &&
      Client &&
      Contract &&
      firstDelivery &&
      totalDeliveries
    ) {
      return true
    }
    return false
  }

  saveHandler = () => {
    const { onComplete } = this.props
    this.saveBasicDetailsForDeliverable(onComplete)
  }

  saveAndNextHandler = () => {
    const { onComplete, onNext } = this.props
    if (this.hasValidChangesToSave()) {
      const callback = () => {
        onComplete()
        onNext()
      }
      this.saveBasicDetailsForDeliverable(callback)
    } else {
      onNext()
    }
  }

  saveBasicDetailsForDeliverable = callback => {
    const { saveBasicDetailsForm } = this.props
    const payload = this.buildSavePayload()
    saveBasicDetailsForm(payload, callback)
  }

  buildSavePayload = () => {
    const { deliverableId, Contract, Client } = this.props
    return {
      ...pick(this.props, [
        'name',
        'totalDeliveries',
        'firstDelivery',
        'startMonth',
        'startYear',
        'frequency',
        'deliveryDay',
        'deliveryDayType'
      ]),
      contractId: Contract.id,
      clientId: Client.id,
      id: deliverableId
    }
  }

  render() {
    const {
      editable,
      deliverableId,
      Client = null,
      Contract = null,
      name = '',
      isClientValid,
      isContractValid,
      isNameValid,
      isNameAvailable,
      clientSuggestions = [],
      contractSuggestions = [],
      dirty,
      resetBasicDetailsForm,
      loading
    } = this.props
    const isNew = !deliverableId || deliverableId === 'new'
    const hasValidChangesToSave = this.hasValidChangesToSave()
    return (
      <div className="basic-details-form step-form">
        {loading && <Loader overlap />}

        <Form className="sb-form">
          <TextInputField
            id="deliverable-name"
            label="Deliverable Name"
            help={
              isNameValid === false
                ? 'Deliverable name cannot be empty'
                : isNameAvailable === false
                ? 'Deliverable name already exists'
                : null
            }
            validationState={dirty && (isNameValid === false || isNameAvailable === false) ? 'error' : null}
            required
            type="text"
            value={name}
            onChange={this.handleNameChange}
            autoFocus
            readOnly={!editable}
          />
          <ChipSelectField
            id="client"
            label="Client"
            required
            selectedValue={Client}
            onSelectionChange={this.handleClientChange}
            labelKey="name"
            onSearchChange={search => {
              this.onClientSearchChange(search)
            }}
            searchSuggestions={clientSuggestions}
            help={isClientValid === false ? 'Client cannot be empty' : null}
            validationState={dirty && isClientValid === false ? 'error' : null}
            placeholder="Select Client"
            readOnly={!editable}
          />
          <ChipSelectField
            disabled={!Client}
            id="contract"
            label="Contract"
            required
            selectedValue={Contract}
            onSelectionChange={this.handleContractChange}
            labelKey="name"
            onSearchChange={search => {
              this.onContractSearchChange(search)
            }}
            searchSuggestions={contractSuggestions}
            help={isContractValid === false ? 'Contract cannot be empty' : null}
            validationState={dirty && isContractValid === false ? 'error' : null}
            placeholder="Select Contract"
            readOnly={!editable}
          />
          <DeliverySchedule {...this.props} updateDetails={this.updateDetails} />
          <div className="sb-form-footer">
            <div className="form-action-buttons">
              {editable && (
                <React.Fragment>
                  <Button className="btn-cancel btn-small" disabled={!dirty} onClick={resetBasicDetailsForm}>
                    Cancel
                  </Button>
                  <Button
                    disabled={!hasValidChangesToSave || loading}
                    className="btn_custom_secondary btn-small"
                    onClick={this.saveHandler}
                  >
                    {isNew ? 'Create' : 'Save'}
                  </Button>
                </React.Fragment>
              )}
              <Button
                disabled={(isNew && !hasValidChangesToSave) || loading}
                className="btn_custom btn-small"
                onClick={this.saveAndNextHandler}
              >
                Next
              </Button>
            </div>
          </div>
        </Form>
      </div>
    )
  }
}

BasicConfig.propTypes = {
  editable: PropTypes.bool,
  deliverableId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  Client: PropTypes.object,
  Contract: PropTypes.object,
  name: PropTypes.string,
  firstDelivery: PropTypes.string,
  isClientValid: PropTypes.bool,
  isContractValid: PropTypes.bool,
  isNameValid: PropTypes.bool,
  isNameAvailable: PropTypes.bool,
  totalDeliveries: PropTypes.number,
  clientSuggestions: PropTypes.array,
  contractSuggestions: PropTypes.array,
  dirty: PropTypes.bool,
  updateBasicDetailsForm: PropTypes.func,
  checkIfDeliverableNameAvailable: PropTypes.func,
  onClientSearchChange: PropTypes.func,
  onContractSearchChange: PropTypes.func,
  resetBasicDetailsForm: PropTypes.func,
  saveBasicDetailsForm: PropTypes.func,
  onComplete: PropTypes.func,
  onNext: PropTypes.func,
  currentDeliveryId: PropTypes.number,
  loading: PropTypes.bool
}

function mapStateToProps({
  dm: { selectedDeliverable: { id: deliverableId } = {} },
  deliveryConfig: {
    basicForm,
    clientSuggestions,
    contractSuggestions,
    deliverableData: { currentDeliveryId }
  }
}) {
  return {
    ...basicForm,
    deliverableId,
    clientSuggestions,
    contractSuggestions,
    currentDeliveryId,
    loading: basicForm.loading
  }
}

export default connect(mapStateToProps, {
  updateBasicDetailsForm,
  resetBasicDetailsForm,
  saveBasicDetailsForm,
  onClientSearchChange,
  onContractSearchChange,
  checkIfDeliverableNameAvailable
})(BasicConfig)
