import { createSelector } from 'reselect'

const feedsSelector = state => (state.feeds && state.feeds.data) || []
const feedSelector = state => (state.session && state.session.feed) || {}
const feedCountSelector = state => (state.feeds && state.feeds.count) || 0

export const getFeeds = createSelector(feedsSelector, feeds => feeds)

export const getFeedsCount = createSelector(feedCountSelector, count => count)

export const getFeedForEdit = createSelector(feedSelector, feed => {
  const metadata = feed.metadata ? JSON.parse(feed.metadata) : {}
  const filters = metadata.filters || {}

  return {
    ...feed,
    isActive: !!feed.isActive,
    startDate: feed.startDate ? new Date(feed.startDate) : null,
    endDate: feed.endDate ? new Date(feed.endDate) : null,
    // If a startDate is defined, we don't schedule a firehose
    scheduleFirehose: !feed.startDate,
    filters: {
      l1Categories: filters.l1Categories || {},
      itemAttributes: filters.itemAttributes || {},
      merchants: filters.merchants || [],
      minOrderDate: filters.minOrderDate ? new Date(filters.minOrderDate) : null,
      maxOrderDate: filters.maxOrderDate ? new Date(filters.maxOrderDate) : null,
      sourceId: filters.sourceId
    }
  }
})
