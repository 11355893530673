import React from 'react'
import PropTypes from 'prop-types'
import { Col } from 'react-bootstrap'
import { useFormValues } from 'components/FormInputComponents/InputField'

import QuerySuggestion from 'components/Suggestion/QuerySuggestion'

const getValueForSave = value => (value ? Number(value) : value)

const useQueryField = ({ isUseLocalConfig, setValueForSave, updateValue }) => {
  const register = ({ id, queryId, isActive, isActiveGlobal }) => {
    setValueForSave({ [id]: getValueForSave(queryId) })

    const readOnly = isUseLocalConfig ? !isActive : !isActiveGlobal

    return {
      onChange: value => updateValue({ [id]: value }),
      queryId,
      readOnly
    }
  }

  return [register]
}

const QueryField = ({ groupQueryId, groupQueryName, queryId, queryName, readOnly, onChange }) => {
  const { values, updateValue } = useFormValues({
    groupQueryId,
    groupQueryName,
    queryName
  })

  const groupQueryChangeHandler = newValue => {
    if (newValue) {
      updateValue({ groupQueryId: newValue.id, groupQueryName: newValue.name })
    } else {
      updateValue({
        groupQueryId: null,
        groupQueryName: '',
        queryName: ''
      })

      onChange(null) // update queryId
    }
  }

  const queryChangeHandler = newValue => {
    updateValue({ queryName: newValue?.name ?? '' })
    onChange(newValue?.id ?? null) // update queryId
  }

  return (
    <Col sm={12}>
      <QuerySuggestion
        groupQueryId={values.groupQueryId}
        groupQueryLabel={values.groupQueryName}
        queryId={queryId}
        queryLabel={values.queryName}
        changeGroupQueryCallback={groupQueryChangeHandler}
        changeQueryCallback={queryChangeHandler}
        readOnly={readOnly}
      />
    </Col>
  )
}

QueryField.propTypes = {
  groupQueryId: PropTypes.number,
  groupQueryName: PropTypes.string,
  queryId: PropTypes.number,
  queryName: PropTypes.string,
  readOnly: PropTypes.bool,
  onChange: PropTypes.func.isRequired
}

export default QueryField
export { useQueryField }
