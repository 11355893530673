import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'react-bootstrap'

import InputField from 'components/FormInputComponents/InputField'

const FieldsGroup = ({ label, info, children }) => {
  const childrenArray = React.Children.toArray(children)

  const isActiveComponent = childrenArray[0] || null
  const otherChidren = childrenArray.slice(1, childrenArray.length)

  const chidrenInRow = React.Children.map(otherChidren, child => <Row>{child}</Row>)

  return (
    <InputField label={label} info={info}>
      <Col sm={4}>{isActiveComponent}</Col>
      <Col sm={8}>{chidrenInRow}</Col>
    </InputField>
  )
}

FieldsGroup.propTypes = {
  label: PropTypes.string.isRequired,
  info: PropTypes.node,

  children: PropTypes.node
}

export default FieldsGroup
