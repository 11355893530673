import { DataGridPremium, GridToolbar } from '@mui/x-data-grid-premium'
import React from 'react'
import { useGetSamplingSessionsQuery } from './tableDAGAPI'
import getSupraSSTableModel from './supraSSTableModel'
import { LinearProgress } from '@mui/material'
import { TABLE_MUI_ROWS_OPTION } from 'constants/constants'
import WrapperWithLoader from 'mui/components/WrapperWithLoader/WrapperWithLoader'

const TableDAG = () => {
  const { isLoading, error, data: orchestratorSemplingSessinos } = useGetSamplingSessionsQuery()
  const getActions = () => {}

  const initialState = {
    pagination: { paginationModel: { pageSize: 10 } }
  }

  return (
    <WrapperWithLoader isLoading={isLoading}>
      <>
        {error}
        <DataGridPremium
          loading={isLoading}
          getRowId={row => row?.session_id}
          initialState={initialState}
          rows={orchestratorSemplingSessinos?.rows || []}
          columns={getSupraSSTableModel({ getActions })}
          slots={{
            toolbar: GridToolbar,
            loadingOverlay: LinearProgress
          }}
          disableRowSelectionOnClick
          pagination
          pageSizeOptions={TABLE_MUI_ROWS_OPTION}
        />
      </>
    </WrapperWithLoader>
  )
}

export default TableDAG
