import React from 'react'
import PropTypes from 'prop-types'
import { Form } from 'react-bootstrap'
import { uniqBy } from 'lodash'

import { useFormValues } from 'components/FormInputComponents/InputField'
import CategoryBrandPathSuggestion from 'components/CategoryBrandPathSuggestion'
import BrandSuggestionWithCheck from './BrandSuggestionWithCheck'

import useFormCategoryBrandFilds from './useFormCategoryBrandFilds'
import useFormButtons from './../../Common/useFormButtons'
import useMode from './../../Common/useMode'
import { getSubmitButtonName } from '../../Common/utils'

import NoteFields from './../../Common/NoteFields'
import CommonRuleModalButtons from './../../Common/CommonRuleModalButtons'
import ManageFieldButtons from '../../../QCSupportPage/Requests/BrandRequestModal/ManageFieldButtons'
import './categoryBrandRuleModal.scss'

const emptyBrandObj = {
  brandId: null,
  brandFullPath: null,
  isMatchAnywhere: false
}

function CategoryBrandRuleModal({ value, isRequest, onOkHandler, onRejectHandler, isReadOnly }) {
  const { id, categoryId, categoryFullPath, brands, request_notes, review_notes } = value
  const mode = useMode(isRequest, isReadOnly, id)

  const { values, handleFieldChange, updateValue } = useFormValues({
    id: id ?? null,
    categoryId: categoryId ?? null,
    categoryFullPath: categoryFullPath ?? null,
    brands: brands || [],
    request_notes: request_notes ?? null,
    review_notes: review_notes ?? null
  })

  const { handleCategoryChange, handleBrandsChange } = useFormCategoryBrandFilds(updateValue)

  const sentFieldsForRules = ['categoryId', 'brandId', 'isMatchAnywhere']
  const sentFieldsForRequests = ['categoryFullPath', 'brands', 'request_notes', 'review_notes']

  const { handleOkButton, handleRejectButton, handleCancelButton } = useFormButtons({
    onOkHandler,
    onRejectHandler,
    values: convertValuesForSave(values),
    sentFieldsForRules,
    sentFieldsForRequests,
    isRequest
  })

  const hasEmptyField = values.brands.some(brand => brand.brandId === null || brand.brandId === undefined)
  const isOkButtonDisabled = hasEmptyField || !values.categoryId

  const addBrandFieldHandler = () => updateValue(values => ({ brands: [...values.brands, emptyBrandObj] }))
  const removeBrandFieldHandler = () => updateValue(values => ({ brands: values.brands.slice(0, -1) }))

  return (
    <Form className="text-left" horizontal>
      <div className="category-request-modal-form-body">
        <CategoryBrandPathSuggestion
          categoryId={categoryId}
          categoryFullPath={categoryFullPath}
          handleCategoryChange={handleCategoryChange}
          readOnly={mode.isRequestApprove || mode.isRequestView}
        />
        {values.brands.map((brand, i) => (
          <BrandSuggestionWithCheck
            key={brand.brandId || i}
            brand={brand}
            categoryId={categoryId || values.categoryId}
            mode={mode}
            index={i}
            handleBrandsChange={handleBrandsChange}
            prevRuleId={values.id}
          />
        ))}

        {mode.isRequestCreate && (
          <ManageFieldButtons
            name={'Brand'}
            addHandler={addBrandFieldHandler}
            removeHandler={removeBrandFieldHandler}
            fieldsCount={values.brands.length}
            minFildCount={1}
            hasEmptyField={isOkButtonDisabled}
          />
        )}

        <NoteFields
          mode={mode}
          request_notes={values.request_notes}
          review_notes={values.review_notes}
          handleFieldChange={handleFieldChange}
        />

        <CommonRuleModalButtons
          handleOkButton={handleOkButton}
          handleRejectButton={handleRejectButton}
          handleCancelButton={handleCancelButton}
          mode={mode}
          okButtonName={getSubmitButtonName(mode)}
          isOkButtonDisabled={isOkButtonDisabled}
        />
      </div>
    </Form>
  )
}

CategoryBrandRuleModal.defaultProps = {
  value: {
    id: null,
    categoryId: null,
    categoryFullPath: null,
    brands: [emptyBrandObj],
    request_notes: null,
    review_notes: null
  },
  isRequest: false,
  isReadOnly: false
}

CategoryBrandRuleModal.propTypes = {
  value: PropTypes.shape({
    id: PropTypes.number,
    categoryId: PropTypes.number,
    categoryFullPath: PropTypes.string,
    brands: PropTypes.arrayOf(PropTypes.object),
    isMatchAnywhere: PropTypes.bool,
    request_notes: PropTypes.string,
    review_notes: PropTypes.string
  }),

  onOkHandler: PropTypes.func.isRequired,
  onRejectHandler: PropTypes.func,

  isRequest: PropTypes.bool,
  isReadOnly: PropTypes.bool
}

const convertValuesForSave = values => {
  // remove duplicatesid
  const uniqBrands = uniqBy(values.brands, 'brandId').filter(c => c?.brandId)
  // select special data for FieldsForRules
  const { brandId, brandFullPath, isMatchAnywhere } = uniqBrands[0] || {}
  return {
    ...values,
    brands: uniqBrands,
    brandId,
    brandFullPath,
    isMatchAnywhere
  }
}

export { emptyBrandObj }
export default CategoryBrandRuleModal
