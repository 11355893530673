import React from 'react'
import PropTypes from 'prop-types'
import { Glyphicon } from 'react-bootstrap'

const SKIN = {
  default: {
    // eslint-disable-next-line react/display-name
    active: title => <Glyphicon style={{ color: 'green' }} glyph="ok" title={title} />,
    // eslint-disable-next-line react/display-name
    inActive: title => <Glyphicon style={{ color: 'red' }} glyph="remove" title={title} />,
    empty: null
  },
  circle: {
    // eslint-disable-next-line react/display-name
    active: title => <div title={title} className="bool-cell-circle" />,
    inActive: () => null,
    empty: null
  }
}

const BooleanCell = ({ isActive, isAllowNull = false, titleActive = '', bsStyle = 'default' }) => {
  if (isActive === null && isAllowNull) {
    return SKIN[bsStyle].empty
  } else if (isActive) {
    return SKIN[bsStyle].active(titleActive)
  } else {
    return SKIN[bsStyle].inActive()
  }
}

BooleanCell.propTypes = {
  isActive: PropTypes.bool.isRequired,
  isAllowNull: PropTypes.bool,
  titleActive: PropTypes.string,
  bsStyle: PropTypes.string
}

export default BooleanCell
