import React from 'react'
import PropTypes from 'prop-types'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

import ResultCellValue from './ResultCellValue'

import ResultTooltip from './Tooltip/ResultTooltip'
import TrendViolationInfo from './Tooltip/TrendViolationInfo'
import ErrorSign from './ErrorSign'
import { getMaxFailedViolation, getMaxPassedViolation } from './utils'

const TrendResultCell = ({
  name,
  result,
  threshold,
  sensitivity,
  rank,
  isPass,
  isActive,
  error = null,
  isOverallTrend = false
}) => {
  let resultMark = '%'
  let maxViolation = null

  if (result && isPass) {
    maxViolation = getMaxPassedViolation(result)
  } else if (result && !isPass) {
    maxViolation = getMaxFailedViolation(result)
    if (maxViolation === null) {
      maxViolation = 'no data'
      resultMark = ''
    }
  }

  const itemName = name.split(' ')[0]

  return (
    <div>
      <OverlayTrigger
        placement="top"
        overlay={
          <Tooltip id="category-test-trend-result-tooltip">
            <ResultTooltip name={name} isPass={isPass} isActive={isActive}>
              <TrendViolationInfo
                isActive={isActive}
                result={result}
                threshold={threshold}
                sensitivity={sensitivity}
                rank={rank}
                resultMark={resultMark}
                itemName={itemName}
                isOverallTrend={isOverallTrend}
              />
            </ResultTooltip>
          </Tooltip>
        }
      >
        <span>
          <ResultCellValue mark={resultMark} isActive={isActive} isPass={isPass}>
            {maxViolation}
          </ResultCellValue>
        </span>
      </OverlayTrigger>
      <ErrorSign error={error} />
    </div>
  )
}

TrendResultCell.propTypes = {
  name: PropTypes.string.isRequired,
  result: PropTypes.object,
  threshold: PropTypes.number,
  sensitivity: PropTypes.number,
  rank: PropTypes.number,
  isPass: PropTypes.bool,
  isActive: PropTypes.bool.isRequired,
  error: PropTypes.string,
  isOverallTrend: PropTypes.bool
}

export default TrendResultCell
