import React from 'react'
import { capitalize, upperCase } from 'lodash'

import { DateCell, TextCell } from 'components/DataTable'
import { DATA_TABLE } from 'components/DataTable/constants'
import RequestPayloadCell from './RequestPayloadCell'
import RequestActionsCell from './RequestActionsCell'
import { REQUESTS_STATUS, REQUESTS_TYPE } from './../constants'

import { OverlayTrigger, Tooltip, Glyphicon } from 'react-bootstrap'
import CopyToClipboard from 'react-copy-to-clipboard'

import { createAlert } from 'actions/app-actions'
// TODO refactoring: move these functionality in one special file
// src/components/DeliveryManager/Common/table-utils.js
// src/components/DeliveryJobsPage/columns.js
const onCopy = (text, result) => {
  const type = result ? 'info' : 'danger'
  const title = result ? 'Comment copied to clipboard' : 'There was an error trying to copy'
  createAlert(type, '', title)
}
export const renderComment = (request_notes, review_notes, onCopy) => {
  if (request_notes || review_notes) {
    return (
      <OverlayTrigger
        rootClose
        placement="top"
        overlay={
          <Tooltip id="comment-tooltip">
            {request_notes && <p>Requestor Notes: {request_notes}</p>}
            {review_notes && <p>Reviewer Notes: {review_notes}</p>}
            <p>
              <i>{'Comment (Click to Copy):'}</i>
            </p>
          </Tooltip>
        }
      >
        <CopyToClipboard text={request_notes + '\n' + review_notes} onCopy={onCopy}>
          <Glyphicon glyph="comment" />
        </CopyToClipboard>
      </OverlayTrigger>
    )
  }
}

export default function getColumns(hasEditAccess) {
  return [
    {
      field: 'id',

      dataAlign: 'center',
      headerAlign: 'center',
      width: '75px',

      label: 'ID',
      dataSort: true,
      filter: {
        type: 'TextFilter',
        delay: DATA_TABLE.FILTER_DELAY
      }
    },
    {
      field: 'type',

      dataAlign: 'left',
      headerAlign: 'center',
      width: '100px',

      label: 'Type',
      dataSort: true,
      dataFormat(cell, row) {
        return <span className="text-uppercase">{cell}</span>
      },
      filter: {
        type: 'SelectFilter',
        options: {
          [REQUESTS_TYPE.BRAND]: capitalize(REQUESTS_TYPE.BRAND),
          [REQUESTS_TYPE.CBR]: upperCase(REQUESTS_TYPE.CBR),
          [REQUESTS_TYPE.MCR]: upperCase(REQUESTS_TYPE.MCR),
          [REQUESTS_TYPE.MBR]: upperCase(REQUESTS_TYPE.MBR),
          [REQUESTS_TYPE.BSR]: upperCase(REQUESTS_TYPE.BSR),
          [REQUESTS_TYPE.MCRR]: upperCase(REQUESTS_TYPE.MCRR),
          [REQUESTS_TYPE.MBRR]: upperCase(REQUESTS_TYPE.MBRR),
          [REQUESTS_TYPE.GENERAL]: capitalize(REQUESTS_TYPE.GENERAL),
          [REQUESTS_TYPE.GUIDELINE]: capitalize(REQUESTS_TYPE.GUIDELINE)
        },
        comparator: '='
      }
    },
    {
      field: 'payload',

      dataAlign: 'left',
      headerAlign: 'center',
      // width: '380px',
      width: 'auto',

      label: 'Request',
      dataFormat: (cell, row) =>
        RequestPayloadCell({
          payload: cell,
          type: row.type
        }),
      dataSort: true,
      filter: {
        type: 'TextFilter',
        delay: DATA_TABLE.FILTER_DELAY
      }
    },
    {
      field: 'status',

      dataAlign: 'left',
      headerAlign: 'center',
      width: '110px',

      label: 'Status',
      dataSort: true,
      dataFormat(cell, row) {
        return (
          <>
            <span className={`requests-status requests-status-${row.status}`}>{row.status}</span>
            {renderComment(row.request_notes, row.review_notes, onCopy)}
          </>
        )
      },
      filter: {
        type: 'SelectFilter',
        options: {
          [REQUESTS_STATUS.PENDING]: capitalize(REQUESTS_STATUS.PENDING),
          [REQUESTS_STATUS.APPROVED]: capitalize(REQUESTS_STATUS.APPROVED),
          [REQUESTS_STATUS.REJECTED]: capitalize(REQUESTS_STATUS.REJECTED),
          [REQUESTS_STATUS.FAILED]: capitalize(REQUESTS_STATUS.FAILED),
          [REQUESTS_STATUS.DELETED]: capitalize(REQUESTS_STATUS.DELETED),
          [REQUESTS_STATUS.PROCESSING]: capitalize(REQUESTS_STATUS.PROCESSING)
        },
        comparator: '='
      }
    },
    {
      field: 'result',

      dataAlign: 'left',
      headerAlign: 'center',
      width: 'auto',

      label: 'Result',
      dataSort: true,
      dataFormat: (cell, row) => TextCell({ text: cell, bsClass: 'requsts-result-column sb-table-text-line-6-cell' }),
      filter: {
        type: 'TextFilter',
        delay: DATA_TABLE.FILTER_DELAY
      }
    },
    {
      field: 'createdByName',

      dataAlign: 'center',
      headerAlign: 'center',
      width: '130px',

      label: 'Created By',
      dataSort: true,
      filter: {
        type: 'TextFilter',
        delay: DATA_TABLE.FILTER_DELAY
      }
    },
    {
      field: 'updatedByName',

      dataAlign: 'center',
      headerAlign: 'center',
      width: '130px',

      label: 'Updated By',
      dataSort: true,
      filter: {
        type: 'TextFilter',
        delay: DATA_TABLE.FILTER_DELAY
      }
    },
    {
      field: 'updatedAt',

      dataAlign: 'center',
      headerAlign: 'center',
      width: '160px',

      label: 'Updated At',
      dataSort: true,
      dataFormat: (cell, row) => DateCell({ date: cell }),
      filter: {
        type: 'DateFilter',
        dateOnly: true,
        delay: DATA_TABLE.FILTER_DELAY
      }
    },
    {
      field: 'actionsColumn',
      label: 'Actions',
      dataAlign: 'center',
      headerAlign: 'center',
      width: '70px',
      dataFormat: function getActionCell(cell, row) {
        return <RequestActionsCell row={row} hasEditAccess={hasEditAccess} />
      }
    }
  ]
}
