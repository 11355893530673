import React from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'

/**
 * Tab Navigation component
 * @param {string} path - path of tab
 * @param {string} label - label of tab
 * @param {string} name - name of tab in DocumentTitle. If not set we use "label"
 * @param {ReactElement} component - component with body of this tab. Use it in TabNav component
 */
const TabNavItem = ({ path, name, label }) => {
  return (
    <NavLink key={name || label} to={path} activeClassName="active" role="tab">
      {label}
    </NavLink>
  )
}
TabNavItem.propTypes = {
  path: PropTypes.string.isRequired,
  component: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  label: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.string]).isRequired,
  name: PropTypes.string
}

export default TabNavItem
