import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Button } from 'react-bootstrap'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import { Link } from 'react-router-dom'
import Loader from '../Loader'
import BetaAlert from '../BetaAlert'
import { fetchFeedGroupLocks, deleteFeedGroupLock } from '../../actions/feed-actions'
import { confirmModal } from '../../actions/modal-actions'

const NoData = () => (
  <center>
    <h4>No locks found</h4>
  </center>
)
const BETA_ALERT_MSG = 'PLEASE DO NOT TAKE ANY ACTION WHEN FEEDS ARE PROCESSING! ONLY DELETE LOCKS YOU ARE SURE OF.'
class FeedGroupLocks extends Component {
  constructor(props) {
    super(props)
    this.getColumns = this.getColumns.bind(this)
    this.deleteFeedGroupLock = this.deleteFeedGroupLock.bind(this)
  }

  componentDidMount() {
    this.props.fetchFeedGroupLocks()
  }

  getColumns() {
    const COLUMN_KEYS = ['id', 'type', 'group', 'param', 'value', 'createdAt', 'updatedAt']
    let columns = COLUMN_KEYS.map(key => ({
      key,
      dataFormat: (cell, row) => {
        return key === 'group' ? <Link to={`/admin/groups/${row.group.id}`}>{row.group.name}</Link> : cell
      }
    }))
    columns = [
      ...columns,
      {
        key: 'delete',
        dataFormat: (cell, row) => (
          <Button bsStyle="danger" onClick={() => this.deleteFeedGroupLock(row)}>
            Delete
          </Button>
        )
      }
    ]
    return columns.map(column => ({
      key: column.key,
      label: column.key,
      dataField: column.key,
      ref: column.key,
      dataAlign: 'left',
      editable: false,
      dataFormat: column.dataFormat
    }))
  }

  deleteFeedGroupLock(data) {
    this.props.confirmModal(
      'Are you sure you want to delete this lock? Please ensure that no feeds are currently processing for this group!',
      'Confirm Delete',
      { okButton: 'Delete' },
      () => this.props.deleteFeedGroupLock(data.id)
    )
  }

  render() {
    const { data, loading, error } = this.props
    if (loading) {
      return <Loader />
    }
    const columns = this.getColumns()
    return (
      <div>
        <br />
        {error && error}
        {<BetaAlert enabled message={BETA_ALERT_MSG} onDismissAlert={() => {}} />}
        {data && data.length ? (
          <BootstrapTable
            ref={el => {
              this.table = el
            }}
            data={data}
            striped
            hover
            condensed
            bordered
            responsive
            className="feed-group-locks-table"
            remote
          >
            {columns.map(column => (
              <TableHeaderColumn
                dataAlign={column.dataAlign}
                ref={column.ref}
                key={column.key}
                isKey={column.key === 'id'}
                dataField={column.dataField}
                editable={column.editable}
                dataFormat={column.dataFormat}
                className="feed-group-locks-table-column-title"
              >
                {column.label}
              </TableHeaderColumn>
            ))}
          </BootstrapTable>
        ) : (
          <NoData />
        )}
      </div>
    )
  }
}

FeedGroupLocks.propTypes = {
  error: PropTypes.string,
  loading: PropTypes.bool,
  documentTitle: PropTypes.string,
  fetchFeedGroupLocks: PropTypes.func,
  deleteFeedGroupLock: PropTypes.func,
  confirmModal: PropTypes.func,
  data: PropTypes.arrayOf(PropTypes.object)
}
FeedGroupLocks.defaultProps = {
  data: []
}

const mapStateToProps = ({
  feeds: {
    feedGroupLocks: { data, error, loading }
  }
}) => ({
  data,
  error,
  loading
})

export default connect(mapStateToProps, { fetchFeedGroupLocks, confirmModal, deleteFeedGroupLock })(FeedGroupLocks)
