import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { uniq, map, values } from 'lodash'
import { CopyToClipboard } from 'react-copy-to-clipboard'

class JobMetadata extends Component {
  static propTypes = {
    job: PropTypes.object,
    changeSearch: PropTypes.func
  }

  getEditUser(metadata) {
    return uniq(map(values(metadata.edits), 'user'))
  }

  calculateEditProgress(editParams) {
    return Math.round((Number(editParams.updatedCount || 0) / Number(editParams.totalCount || 1)) * 100)
  }

  renderDictionaryEditProgress(metadata, property) {
    const editParams = metadata[property]
    const users = this.getEditUser(metadata)
    const progress = this.calculateEditProgress(editParams)
    return (
      <div key={property} title={JSON.stringify(metadata[property], null, 2)}>
        {`edited by: ${users.toString()}`}
        <br />
        {`${editParams.status} : ${progress}%`}
      </div>
    )
  }

  renderKeyValue(label, value, title) {
    return (
      <div key={`${this.props.job.id}-${label}`} title={title}>
        {label}: {value}
      </div>
    )
  }

  getQueueIcon = key => {
    switch (key) {
      case 'monster-queue':
        return '🐙'
      case 'shark-queue':
        return '🦈'
      case 'ant-queue':
        return '🐜'
      case 'idf-firehose':
        return '🔥'
      default:
        return '💂'
    }
  }

  render() {
    const metadata = JSON.parse(this.props.job.metadata)
    const { job, changeSearch } = this.props
    const { source, user, ...otherJobDetails } = job

    return (
      <div className="job-metadata-wrapper">
        {user && this.renderKeyValue('User', user.fullname)}
        {source &&
          this.renderKeyValue(
            'source',
            <CopyToClipboard text={source}>
              <span className="click-to-copy">{source}</span>
            </CopyToClipboard>
          )}
        {metadata &&
          Object.keys(metadata).map(property => {
            switch (property) {
              case 'group-name':
                return this.renderKeyValue('group', metadata['group-name'])
              case 'triggerSource':
                return this.renderKeyValue('triggered by', metadata.triggerSource)
              case 'queue':
                const queueKey = metadata.queue
                return this.renderKeyValue(
                  'queue',
                  `${queueKey} ${this.getQueueIcon(queueKey)}`,
                  'Jobs with a specific queue will only be executed by workers enabled on it.'
                )
              case 'retried-from':
                return (
                  <div key={property}>
                    <span className="btn_link" onClick={() => changeSearch(metadata[property])}>
                      Retried from: {metadata[property].slice(-6)}
                    </span>
                  </div>
                )
              case 'progress':
                const startTime = moment(job.startedAt)
                const end = job.endedAt ? moment(job.endedAt) : moment()
                switch (job.type) {
                  case 'description-edit':
                    return this.renderDictionaryEditProgress(metadata, property)
                  case 'domain-metadata':
                    return (
                      <div key={property} title={JSON.stringify(otherJobDetails, null, 2)}>
                        {JSON.stringify(metadata[property], null, 2)}
                      </div>
                    )
                  default:
                    return (
                      <div key={property} title={JSON.stringify(otherJobDetails, null, 2)}>
                        {job.startedAt && metadata[property].count != null
                          ? `${(metadata[property].count / moment.duration(end.diff(startTime)).asSeconds()).toFixed(
                              0
                            )} inserts/s`
                          : ''}
                      </div>
                    )
                }
              case 'id':
                return <div key={property}>id: {metadata[property]} </div>
              case 'edits':
                return null
              default:
                return null
            }
          })}
      </div>
    )
  }
}

export default JobMetadata
