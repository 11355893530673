import React from 'react'

export default function TargetFlag(props) {
  return (
    <svg
      width="14px"
      height="16px"
      viewBox="0 0 14 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      {/* Generator: Sketch 51.2 (57519) - http://www.bohemiancoding.com/sketch */}
      <title>ic-target</title>
      <desc>Created with Sketch.</desc>
      <defs />
      <g id="Components" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g id="Icons" transform="translate(-608.000000, -260.000000)">
          <g id="ic-target" transform="translate(600.000000, 253.000000)">
            <g>
              <rect id="spacing" x={0} y={0} width={30} height={30} />
              <g id="flag" transform="translate(8.000000, 7.000000)">
                <path
                  d="M2.44139111,3.5 L1.56639111,10.5 L11.9025404,10.5 L9.33583441,7.54861231 L12.8947576,3.5 L2.44139111,3.5 Z"
                  id="Rectangle-Copy"
                  stroke="currentColor"
                />
                <polygon id="Rectangle-Copy-2" fill="currentColor" points="2 1 3 1 1 16 -1.42108547e-14 16" />
                <circle id="Oval-2" fill="currentColor" cx="2.5" cy="1.5" r="1.5" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
