/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import { Button, Glyphicon, OverlayTrigger, Tooltip } from 'react-bootstrap'
import PropTypes from 'prop-types'
import moment from 'moment'
import { round } from 'lodash'
import {
  refreshSamplingSessionsTable,
  fetchSampleDataForSession,
  triggerDictionaryChecks,
  fetchErrorDetailsForSamplingSession,
  cancelSamplingSessionJob
} from './actions'
import { setSelectedTab } from '../../actions/supra-qc-tool-actions'
import {
  openGenerateSampleModal,
  openMetricsModal,
  onPageChangeSamplingSession,
  onPageSizeChangeSamplingSession
} from './slices'
import { supraQCToolDefaults, supraQCToolTabs, permissions as PERMISSIONS } from '../../constants/constants'
import UserService from '../../services/user-service'
import Loader from '../Loader'
import GenerateNewSampleModal from './Modals/GenerateNewSampleModal/GenerateNewSampleModal'
import SamplingQCMetricsModal from './Modals/SamplingQCMetricsModal'
import { orchestrate } from 'actions/task-actions'
import { confirmModal } from 'actions/modal-actions'
import InfoTooltip from 'components/Tooltips/InfoTooltip'
import paginationTextHint from 'components/Tooltips/paginationTextHint'
import { statusOption, getInternalStatus, lifeCycleStates } from './utils'
import './style.scss'

const { NUMBERS_AFTER_DECIMAL_POINT } = supraQCToolDefaults
const { SAMPLING_QC } = supraQCToolTabs

class SamplingSessions extends Component {
  componentDidMount() {
    const { total, refreshSamplingSessionsTable } = this.props
    if (total === 0) {
      refreshSamplingSessionsTable()
    }
  }

  handleGetCurrentData = () => this.table?.state?.data

  tooltip = name => <Tooltip id="sampling-sessions-toolbar-tooltip">{name}</Tooltip>

  viewSampleData = row => {
    const sessionId = row.transformedParameters?.session_id
    this.props.fetchSampleDataForSession({ sessionId })
    this.props.setSelectedTab(SAMPLING_QC)
  }

  cancelRunningJob = row => {
    const sessionId = row._session_id
    this.props.cancelSamplingSessionJob(sessionId)
  }

  handleDetailsClick({ event, row }) {
    event.preventDefault()
    this.props.fetchErrorDetailsForSamplingSession({ runId: row.run_id })
  }

  fullNameFormatter = (cell, row) => {
    return <div>{row.createdByUser?.fullname}</div>
  }

  sessionIDFormatter = (cell, row) => {
    return (
      <>
        {`${row._session_id.slice(0, 18)}...`}
        <InfoTooltip
          tooltipBSClass="text-left"
          iconClass="ml-0"
          text={
            <p className="text-left">
              <b>Subject Types:</b> {row?._filter_supra_type?.replace(/,/g, ', ')} <br />
              <b>Date Start:</b> {row._filter_start_date} <br />
              <b>Date End:</b> {row._filter_end_date} <br />
              {row.transformedParameters.filter_keywords && (
                <>
                  <b>Keywords:</b> {row.transformedParameters.filter_keywords}
                </>
              )}
            </p>
          }
        />
      </>
    )
  }

  passThroughFormatter = (cell, row) => {
    return <div>{row.transformedParameters?.passthrough}</div>
  }

  domainFormatter = (cell, row) => {
    return <div>{row.domain}</div>
  }

  dateTimeFormatter = (cell, row) => {
    return <div>{moment(cell).format('YYYY-MM-DD HH:mm:SS')}</div>
  }

  statusFormatter = (cell, row) => {
    return (
      <>
        <span>{`${row._status}`}</span>
        {row.state.result_state === 'FAILED' && (
          <InfoTooltip
            text={row.errorMessage}
            trigger="click"
            onClickHandler={event => this.handleDetailsClick({ event, row })}
          />
        )}
      </>
    )
  }

  // TODO: this will change in future as new sample types are added
  sampleTypeFormatter = () => 'Domain'

  dictionaryCheckFormatter = (cell, row) => {
    return (
      <div>{row.lastDictionaryCheckedAt && moment(row.lastDictionaryCheckedAt).format('YYYY-MM-DD: HH:mm:ss')}</div>
    )
  }

  pctSamplesDefinedFormatter = (cell, row) => {
    return (
      <div>
        {row.statistics?.defined_templates_cnt && row.statistics?.total_templates_cnt
          ? `${round(
              (row.statistics?.defined_templates_cnt * 100) / row.statistics?.total_templates_cnt,
              NUMBERS_AFTER_DECIMAL_POINT
            )} %`
          : '-'}
      </div>
    )
  }

  pctTrafficDefinedFormatter = (cell, row) => {
    return (
      <div>
        {row.statistics?.defined_templates_pct
          ? `${round(row.statistics?.defined_templates_pct, NUMBERS_AFTER_DECIMAL_POINT)} %`
          : '-'}
      </div>
    )
  }

  pct100TemplatesFormatter = (cell, row) => {
    return (
      <div>
        {row.statistics?.pct_traffic_100_templates
          ? `${round(row.statistics?.pct_traffic_100_templates, NUMBERS_AFTER_DECIMAL_POINT)} %`
          : '-'}
      </div>
    )
  }

  pctConflictsFormatter = (cell, row) => {
    return (
      <div>
        {row.statistics?.conflicted_templates_cnt && row.statistics?.total_templates_cnt
          ? `${round(
              (row.statistics?.conflicted_templates_cnt * 100) / row.statistics?.total_templates_cnt,
              NUMBERS_AFTER_DECIMAL_POINT
            )} %`
          : '-'}
      </div>
    )
  }

  buttonFormatter = (cell, row) => {
    const jobCompleted = ![lifeCycleStates.TERMINATED, lifeCycleStates.INTERNAL_ERROR].includes(
      row.state.life_cycle_state
    )
    const { userHasSupraQCEditPermission, userHasSupraQCAdminPermission } = this.props
    return (
      <div className="d-flex justify-content-around">
        {row._status === statusOption.ACTIVE && (
          <OverlayTrigger placement="left" overlay={<Tooltip id="dictionary-check-utility">Dictionary Check</Tooltip>}>
            <div
              onClick={e => {
                e.preventDefault()
                this.openRunDictionaryCheckModal(row.domain)
              }}
              className="cursor-pointer"
            >
              <Glyphicon glyph="list" />
            </div>
          </OverlayTrigger>
        )}

        {row._status === statusOption.ACTIVE && (
          <OverlayTrigger placement="left" overlay={<Tooltip id="view-samling-utility">View Sampling</Tooltip>}>
            <div onClick={() => this.viewSampleData(row)} className="cursor-pointer">
              <Glyphicon glyph="eye-open" />
            </div>
          </OverlayTrigger>
        )}

        {userHasSupraQCEditPermission && (
          <OverlayTrigger
            placement="left"
            overlay={<Tooltip id="pre-filled-utility">Generate sample with pre-filled parameters</Tooltip>}
          >
            <div
              onClick={() => this.props.openGenerateSampleModal({ defaultValue: row.transformedParameters })}
              className="cursor-pointer"
            >
              <Glyphicon glyph="duplicate" />
            </div>
          </OverlayTrigger>
        )}

        {jobCompleted && userHasSupraQCAdminPermission && (
          <OverlayTrigger placement="left" overlay={<Tooltip id="cancel-job">Cancel a running job</Tooltip>}>
            <div onClick={() => this.cancelRunningJob(row)} className="cursor-pointer">
              <Glyphicon glyph="stop" />
            </div>
          </OverlayTrigger>
        )}
      </div>
    )
  }

  openReloadSamplingResultsModal() {
    return this.props.confirmModal(
      <span>{`Are you sure you want to reload sampling results`}</span>,
      'Confirm Orchestration',
      { okButton: 'Yes, Orchestrate' },
      () =>
        this.props.orchestrate('supra-qc-load-sampling-result', {
          forceReload: true
        })
    )
  }

  openRunDictionaryCheckModalAllDomains() {
    const domains = this.props.domains
    return this.props.confirmModal(
      <span>{`Are you sure you want to run dictionary check for domains: ${domains.join(', ')}?`}</span>,
      'Confirm Orchestration',
      { okButton: 'Yes, Orchestrate' },
      () => this.props.triggerDictionaryChecks({ domains })
    )
  }

  openRunDictionaryCheckModal(domain) {
    return this.props.confirmModal(
      <span>{`Are you sure you want to run dictionary check for domain: ${domain}?`}</span>,
      'Confirm Orchestration',
      { okButton: 'Yes, Orchestrate' },
      () => this.props.orchestrate('supra-qc-dictionary-check', { domain })
    )
  }

  createCustomToolBar = props => {
    const { userHasSupraQCEditPermission } = this.props
    return (
      <div className="text-left result-table-toolbar">
        Sampling Sessions
        <span>
          <OverlayTrigger delayShow={1000} placement="top" overlay={this.tooltip('Refresh')}>
            <Button
              bsSize="xsmall"
              bsStyle="default"
              className="action-button refresh-button"
              onClick={this.props.refreshSamplingSessionsTable}
            >
              <Glyphicon glyph="refresh" />
            </Button>
          </OverlayTrigger>

          <OverlayTrigger delayShow={1000} placement="top" overlay={this.tooltip('Generate New Sample')}>
            <Button
              bsSize="xsmall"
              bsStyle="primary"
              className="action-button refresh-button"
              onClick={() => this.props.openGenerateSampleModal({})}
              disabled={!userHasSupraQCEditPermission}
            >
              Generate New Sample
            </Button>
          </OverlayTrigger>

          <OverlayTrigger delayShow={1000} placement="top" overlay={this.tooltip('Reload Sampling Results')}>
            <Button
              bsSize="xsmall"
              bsStyle="warning"
              className="action-button refresh-button"
              onClick={event => {
                event.preventDefault()
                this.openReloadSamplingResultsModal()
              }}
              disabled={!userHasSupraQCEditPermission}
            >
              Reload Sampling Results
            </Button>
          </OverlayTrigger>

          <OverlayTrigger
            delayShow={1000}
            placement="top"
            overlay={this.tooltip('Trigger Dictionary Check for all domains')}
          >
            <Button
              bsSize="xsmall"
              bsStyle="primary"
              className="action-button refresh-button"
              onClick={event => {
                event.preventDefault()
                this.openRunDictionaryCheckModalAllDomains()
              }}
              disabled={!userHasSupraQCEditPermission}
            >
              Trigger Dictionary Checks
            </Button>
          </OverlayTrigger>
          <span>
            <Button
              bsSize="xsmall"
              bsStyle="primary"
              className="action-button ml-2"
              onClick={() => this.props.openMetricsModal()}
            >
              View Metrics
            </Button>
          </span>
        </span>
      </div>
    )
  }

  render() {
    let sequence = 1
    const options = {
      paginationShowsTotal: paginationTextHint,
      toolBar: this.createCustomToolBar,
      page: this.props.page,
      sizePerPage: this.props.pageSize,
      sizePerPageList: [15, 20, 30],
      onPageChange: e => this.props.onPageChangeSamplingSession(e),
      onSizePerPageList: e => this.props.onPageSizeChangeSamplingSession(e)
    }
    const defaultFilter = { type: 'TextFilter' }
    const selectFilter = { type: 'SelectFilter', options: statusOption }
    const dateFilter = { type: 'DateFilter' }

    return this.props.loading ? (
      <Loader />
    ) : (
      <>
        {this.props.showGenerateSampleModal && <GenerateNewSampleModal />}
        <SamplingQCMetricsModal getCurrentData={this.handleGetCurrentData} />
        <div key="sampling-sessions-table">
          <BootstrapTable
            data={this.props.rows.map(row => ({
              ...row,
              sequence: sequence++,
              _session_id: row.transformedParameters?.session_id,
              _fullname: row.createdByUser?.fullname,
              _filter_start_date: row.transformedParameters?.filter_start_date,
              _filter_end_date: row.transformedParameters?.filter_end_date,
              _filter_supra_type: row.transformedParameters?.filter_supra_type,
              _status: getInternalStatus(row)
            }))}
            pagination
            options={options}
            striped
            hover
            condensed
            bordered
            responsive
            ref={t => (this.table = t)}
            className="drop-menu-up"
          >
            <TableHeaderColumn dataField="sequence" hidden isKey>
              Sequence
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="_session_id"
              filter={defaultFilter}
              dataSort
              dataFormat={this.sessionIDFormatter}
              width="150px"
            >
              Session ID
            </TableHeaderColumn>

            <TableHeaderColumn
              dataField="_session_id"
              filter={defaultFilter}
              dataSort
              dataFormat={this.sampleTypeFormatter}
              width="10%"
            >
              Sample Type
            </TableHeaderColumn>

            <TableHeaderColumn
              dataField="domain"
              filter={defaultFilter}
              dataSort
              dataFormat={this.domainFormatter}
              width="13%"
            >
              Domain
            </TableHeaderColumn>

            <TableHeaderColumn
              dataField="_status"
              dataFormat={this.statusFormatter}
              filter={selectFilter}
              dataSort
              width="12%"
            >
              Status
            </TableHeaderColumn>

            <TableHeaderColumn
              dataField="_fullname"
              dataFormat={this.fullNameFormatter}
              filter={defaultFilter}
              dataSort
              width="15%"
            >
              User
            </TableHeaderColumn>

            <TableHeaderColumn
              dataField="createdAt"
              dataFormat={this.dateTimeFormatter}
              filter={dateFilter}
              dataSort
              width="150px"
            >
              Create
            </TableHeaderColumn>

            <TableHeaderColumn
              dataField="defined_templates_pct"
              dataFormat={this.pctSamplesDefinedFormatter}
              filter={defaultFilter}
              dataSort
              width="130px"
            >
              % Samples Defined
            </TableHeaderColumn>

            <TableHeaderColumn
              dataField="defined_templates_pct"
              dataFormat={this.pctTrafficDefinedFormatter}
              filter={defaultFilter}
              dataSort
              width="130px"
            >
              % Traffic Defined
            </TableHeaderColumn>

            <TableHeaderColumn
              dataField="defined_templates_pct"
              dataFormat={this.pct100TemplatesFormatter}
              filter={defaultFilter}
              dataSort
              width="130px"
            >
              100 Templates
            </TableHeaderColumn>

            <TableHeaderColumn
              dataField="defined_templates_pct"
              dataFormat={this.pctConflictsFormatter}
              filter={defaultFilter}
              dataSort
              width="100px"
            >
              % Conflicts
            </TableHeaderColumn>

            <TableHeaderColumn dataField="button" dataFormat={this.buttonFormatter} width="80px">
              Actions
            </TableHeaderColumn>
          </BootstrapTable>
        </div>
      </>
    )
  }
}

SamplingSessions.propTypes = {
  rows: PropTypes.array,
  total: PropTypes.number,
  page: PropTypes.number,
  pageSize: PropTypes.number,
  maxRows: PropTypes.number,
  loading: PropTypes.bool,
  refreshSamplingSessionsTable: PropTypes.func,
  fetchSampleDataForSession: PropTypes.func,
  openGenerateSampleModal: PropTypes.func,
  setSelectedTab: PropTypes.func,
  confirmModal: PropTypes.func,
  orchestrate: PropTypes.func,
  dictionaryChecksMap: PropTypes.object,
  domains: PropTypes.array,
  triggerDictionaryChecks: PropTypes.func,
  fetchErrorDetailsForSamplingSession: PropTypes.func,
  cancelSamplingSessionJob: PropTypes.func,
  userHasSupraQCEditPermission: PropTypes.bool,
  userHasSupraQCAdminPermission: PropTypes.bool,
  userIsPortalAdmin: PropTypes.bool,
  openMetricsModal: PropTypes.func,
  onPageChangeSamplingSession: PropTypes.func,
  onPageSizeChangeSamplingSession: PropTypes.func,
  showGenerateSampleModal: PropTypes.bool
}

function mapStateToProps({ supraQCToolV2, session }) {
  return {
    rows: supraQCToolV2.samplingSessionsTab.rows || [],
    total: supraQCToolV2.samplingSessionsTab.total || 0,
    loading: supraQCToolV2.samplingSessionsTab.loading,
    page: supraQCToolV2.samplingSessionsTab.page || 1,
    pageSize: supraQCToolV2.samplingSessionsTab.pageSize,
    dictionaryChecksMap: supraQCToolV2.samplingSessionsTab.dictionaryChecksMap || {},
    domains: supraQCToolV2.samplingSessionsTab.domains || [],
    userHasSupraQCEditPermission: UserService.hasPermission(session.user, PERMISSIONS.supraQCToolEdit),
    userHasSupraQCAdminPermission: UserService.hasPermission(session.user, PERMISSIONS.supraQCToolAdmin),
    userIsPortalAdmin: UserService.isPortalAdmin(session.user),
    showGenerateSampleModal: supraQCToolV2.generateSampleModal.show
  }
}

export default connect(mapStateToProps, {
  refreshSamplingSessionsTable,
  fetchSampleDataForSession,
  openGenerateSampleModal,
  triggerDictionaryChecks,
  fetchErrorDetailsForSamplingSession,
  cancelSamplingSessionJob,
  setSelectedTab,
  confirmModal,
  orchestrate,
  openMetricsModal,
  onPageChangeSamplingSession,
  onPageSizeChangeSamplingSession
})(SamplingSessions)
