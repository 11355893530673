/*!
 * globals
 */

const localStorage = (() => {
  try {
    return window.localStorage
  } catch (e) {
    return {
      getItem: () => null,
      setItem: () => {}
    }
  }
})()

export default {
  clear() {
    return localStorage.clear()
  },
  get(key, outputJson = true) {
    if (outputJson) {
      try {
        return JSON.parse(localStorage.getItem(key))
      } catch (e) {
        console.error(e)
      }
    } else {
      return localStorage.getItem(key)
    }
  },
  set(key, value, outputJson = true) {
    if (!value) {
      localStorage.removeItem(key)
    } else if (outputJson) {
      try {
        localStorage.setItem(key, JSON.stringify(value))
      } catch (e) {
        console.error(e)
        return false
      }
    } else {
      localStorage.setItem(key, value)
    }
    return true
  }
}
