import React from 'react'
import PropTypes from 'prop-types'
import { Panel, Form, FormGroup, DropdownButton, Checkbox, FormControl, ControlLabel, HelpBlock } from 'react-bootstrap'

function JobOrchestratePanel({
  jobs,
  selectedTask,
  onSelectTaskChanged,
  onToggleOverrideMetadata,
  overrideMetadata,
  metadata,
  metadataValidity,
  handleMetadataChanged,
  onOrchestrate
}) {
  if (!jobs || jobs.length <= 0) {
    return null
  }
  return (
    <Panel id="job-orchestrate" className="job-orchestrate">
      <Panel.Heading>
        <Panel.Title toggle>Orchestrate New Job</Panel.Title>
      </Panel.Heading>
      <Panel.Collapse>
        <Panel.Body>
          <Form horizontal>
            <FormGroup controlId="jobFormType">
              <DropdownButton
                className={'form-control job-orchestrate'}
                bsStyle={'default'}
                title={selectedTask ? selectedTask.label : 'Select Job'}
                key={'etl-task'}
                id={'dropdown-task-select'}
                onSelect={onSelectTaskChanged}
              >
                {jobs}
              </DropdownButton>
              <Checkbox inline selected={overrideMetadata} onClick={onToggleOverrideMetadata}>
                Override Metadata
              </Checkbox>
            </FormGroup>
            {overrideMetadata && (
              <FormGroup controlId="jobFormMetadata" validationState={metadataValidity}>
                <ControlLabel>Metadata</ControlLabel>

                <FormControl
                  componentClass="textarea"
                  placeholder="{...}"
                  value={metadata}
                  onChange={handleMetadataChanged}
                />
                <FormControl.Feedback />
                {metadataValidity !== undefined && <HelpBlock>Metadata should be a valid JSON object</HelpBlock>}
              </FormGroup>
            )}
            <span
              disabled={!(selectedTask && metadataValidity !== 'error')}
              className={'btn_custom'}
              onClick={onOrchestrate}
            >
              Orchestrate
            </span>
          </Form>
        </Panel.Body>
      </Panel.Collapse>
    </Panel>
  )
}

JobOrchestratePanel.propTypes = {
  jobs: PropTypes.array,
  selectedTask: PropTypes.object,
  onSelectTaskChanged: PropTypes.func,
  overrideMetadata: PropTypes.bool,
  onToggleOverrideMetadata: PropTypes.func,
  metadata: PropTypes.string,
  metadataValidity: PropTypes.string,
  handleMetadataChanged: PropTypes.func,
  onOrchestrate: PropTypes.func
}

export default JobOrchestratePanel
