import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'

import { Glyphicon, OverlayTrigger, Tooltip } from 'react-bootstrap'

import { addCustomModal } from 'actions/modal-actions'
import MerchantFieldsTableModal from './MerchantFieldsTableModal'

const MerchantActionColumn = ({ id, name }) => {
  const dispatch = useDispatch()

  const showFieldsModal = () => {
    dispatch(
      addCustomModal({
        id: 'merchant-fields-table-modal',
        title: `Fields of Merchant "${name}" ID:${id}`,
        size: 'large',
        content: <MerchantFieldsTableModal id={id} name={name} />
      })
    )
  }

  const renderActionTooltip = label => <Tooltip id="taxonomy-action">{label}</Tooltip>

  return (
    <div className="sb-table-action-cell">
      <OverlayTrigger placement="left" overlay={renderActionTooltip(`Show Merchants fields`)}>
        <div onClick={showFieldsModal}>
          <Glyphicon glyph="eye-open" />
        </div>
      </OverlayTrigger>
    </div>
  )
}

MerchantActionColumn.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired
}

export default MerchantActionColumn
