import React, { useEffect, useState } from 'react'
import { Button, Stack, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import { STATUS, STEP } from '../../orchestratorConstants'
import { useLazyBronzeToSilverRunQuery, useLazyGetBronzeToSilverStatusQuery } from './bronzeToSilverAPI'

const API_STATUS = {
  PENDING: 'PENDING',
  RUNNING: 'RUNNING',
  SKIPPED: 'SKIPPED',
  TERMINATED: 'TERMINATED',
  SUCCESS: 'SUCCESS'
}

const BronzeToSilverStep = ({ selectedDomain, active, activateNextStep, setSessionId }) => {
  const [triggerRun, resultRunStatus, lastPromiseInfo] = useLazyBronzeToSilverRunQuery()
  const [triggerStatus, resultStatus] = useLazyGetBronzeToSilverStatusQuery()

  const [status, setStatus] = useState(STATUS.NONE)

  useEffect(() => {
    switch (resultStatus?.data?.state?.life_cycle_state) {
      case API_STATUS.PENDING:
        return setStatus(STATUS.PENDING)
      case API_STATUS.RUNNING:
        setSessionId(resultRunStatus?.data?.sessionId)
        return setStatus(`${STATUS.RUNNING} ${resultStatus?.data?.setup_duration / 1000} sec.`)
      case API_STATUS.SKIPPED:
        setSessionId(resultRunStatus?.data?.sessionId)
        activateNextStep(STEP.RECRAWLING)
        return setStatus(STATUS.SKIPPED)
      case API_STATUS.TERMINATED:
        return setStatus(STATUS.TERMINATED)
      case API_STATUS.SUCCESS:
        setSessionId(resultRunStatus?.data?.sessionId)
        activateNextStep(STEP.RECRAWLING)
        return setStatus(STATUS.DONE)
      default:
        return setStatus(STATUS.NONE)
    }
  }, [resultStatus])

  const onRunBronzeToSilver = () => {
    triggerRun(selectedDomain?.domain)
    setStatus(STATUS.PENDING)
  }

  const onGetStatus = () => {
    triggerStatus({ runId: resultRunStatus?.data?.run_id, sessionId: resultRunStatus?.data?.sessionId })
  }

  const handleSkipClick = () => {}

  return (
    <Stack
      direction="row"
      sx={{
        alignItems: 'flex-end',
        my: 1
      }}
    >
      <Typography sx={{ width: 400 }} variant="h5">
        2. Bronze to Silver
      </Typography>
      <Button
        sx={{ ml: 2 }}
        variant="outlined"
        onClick={onRunBronzeToSilver}
        disabled={!active || lastPromiseInfo.lastArg === selectedDomain?.domain}
      >
        Run
      </Button>
      <Button sx={{ ml: 2 }} variant="outlined" onClick={onGetStatus} disabled={!active}>
        Status
      </Button>
      <Button sx={{ ml: 2 }} variant="outlined" onClick={handleSkipClick} disabled>
        Skip
      </Button>
      <Typography sx={{ ml: 4 }} variant="h5">
        {status}
      </Typography>
    </Stack>
  )
}

BronzeToSilverStep.propTypes = {
  selectedDomain: PropTypes.any,
  active: PropTypes.bool,
  activateNextStep: PropTypes.func,
  setSessionId: PropTypes.func
}

export default BronzeToSilverStep
