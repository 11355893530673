import React, { Component } from 'react'
import PropTypes from 'prop-types'

import ProgressPoints from '../../ProgressPoints'
import { NavLink } from 'react-router-dom'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

import { createDeliveriesLinkUrl } from '../../../utils/delivery-center'

// possible status for delivery steps
const SUCCESS = ['DELIVERED', 'COMPLETED', 'APPROVED']
const FAILED = ['FAILED', 'CANCELLED', 'REJECTED']
const PROCESSING = ['PROCESSING']
const SKIPPED = ['SKIPPED']

// allowable progress states
const PROGRESS = {
  SKIPPED: 'skipped',
  PROCESSING: 'processing',
  DONE: 'done',
  ERROR: 'error',
  PENDING: ''
}

// tooltip mapping for each progress points
const TOOLTIPS = {
  DELIVERY_NOT_FOUND: 'No Current delivery found',
  categoryCleanupStatus: {
    COMPLETED: 'Data cleaning completed',
    FAILED: 'Data cleaning failed',
    PENDING: 'Data cleaning is pending',
    SKIPPED: 'Skipped Data Cleaning'
  },
  dataCustomization: {
    COMPLETED: 'Data Customization completed',
    FAILED: 'Data Customization failed',
    PENDING: 'Data Customization is not yet started',
    PROCESSING: 'Data Customization in process...'
  },
  qc: {
    FAILED: 'QC Report failed',
    PENDING: 'QC Report creation pending',
    PROCESSING: 'Building QC Report...',
    SKIPPED: 'Skipped QC Report creation'
  },
  qcApprovalStatus: {
    PENDING: 'QC Report pending for approval',
    APPROVED: 'Approved QC Report',
    REJECTED: 'Rejected QC Report'
  },
  prod: {
    COMPLETED: 'Production Report ready',
    FAILED: 'Production Report creation failed',
    PENDING: 'Production Report creation pending',
    PROCESSING: 'Building Production Report...'
  },
  completionStatus: {
    DELIVERED: 'Successfully Delivered!',
    CANCELLED: 'Cancelled Delivery',
    SCHEDULED: 'Delivery In progress...',
    PROCESSING: 'Delivery In progress...'
  }
}

class DeliveryProgress extends Component {
  /**
   * Util method that helps to derive progress status from a delivery step status
   */
  deriveProgressStatus = stepStatus => {
    const { progress: { completionStatus } = {} } = this.props

    if (FAILED.includes(stepStatus)) {
      return PROGRESS.ERROR
    } else if (PROCESSING.includes(stepStatus)) {
      return PROGRESS.PROCESSING
    } else if (SUCCESS.includes(stepStatus)) {
      return PROGRESS.DONE
    } else if (
      SKIPPED.includes(stepStatus) ||
      /*
        If delivery is completed i.e ['CANCELLED', 'DELIVERED'],
        we default any step progress to PROGRESS.SKIPPED
      */
      ['CANCELLED', 'DELIVERED'].includes(completionStatus)
    ) {
      return PROGRESS.SKIPPED
    }
    return PROGRESS.PENDING
  }

  getDataCleaningProgress = () => {
    const { progress: { categoryCleanupStatus } = {} } = this.props
    return {
      tooltip: TOOLTIPS.categoryCleanupStatus[categoryCleanupStatus],
      status: this.deriveProgressStatus(categoryCleanupStatus)
    }
  }

  getDataCustomizationProgress = () => {
    const { progress: { dataCustomization } = {} } = this.props
    return {
      tooltip: TOOLTIPS.dataCustomization[dataCustomization],
      status: this.deriveProgressStatus(dataCustomization)
    }
  }

  getQCProgress = () => {
    const { progress: { qc, qcApprovalStatus } = {} } = this.props
    let status = null
    let tooltip = null
    /*
      QC progress includes:
      1. qc steps
      2. qcApproval steps
      If qc == COMPLETED,
        we use qcApproval status
      else,
        we simply use qc status
    */
    if (SUCCESS.includes(qc)) {
      // qc completed
      status =
        qcApprovalStatus === 'PENDING'
          ? PROGRESS.PROCESSING // when qcApproval is pending for user input
          : this.deriveProgressStatus(qcApprovalStatus)
      tooltip = TOOLTIPS.qcApprovalStatus[qcApprovalStatus]
    } else {
      // qc not yet completed
      status = this.deriveProgressStatus(qc)
      tooltip = TOOLTIPS.qc[qc]
    }
    return {
      tooltip,
      status
    }
  }

  getProdProgress = () => {
    const { progress: { prod } = {} } = this.props
    return {
      tooltip: TOOLTIPS.prod[prod],
      status: this.deriveProgressStatus(prod)
    }
  }

  getDeliveryCompletionProgress = () => {
    const { progress: { completionStatus } = {} } = this.props
    return {
      tooltip: TOOLTIPS.completionStatus[completionStatus],
      status: this.deriveProgressStatus(completionStatus)
    }
  }

  /**
   * Prepare progress details for each steps
   */
  prepareProgressList = () => {
    return [
      this.getDataCleaningProgress(), // step 1
      this.getDataCustomizationProgress(), // step 2
      this.getQCProgress(), // step 3
      this.getProdProgress(), // step 4
      this.getDeliveryCompletionProgress() // step 5
    ]
  }

  /**
   * render a message when no current delivery is found
   */
  renderNoDeliveryMsg = () => {
    return (
      <OverlayTrigger placement="top" overlay={<Tooltip id="no-delivery">{TOOLTIPS.DELIVERY_NOT_FOUND}</Tooltip>}>
        <div>{'- -'}</div>
      </OverlayTrigger>
    )
  }

  render() {
    const { deliverableId, deliverableName } = this.props
    const { progress } = this.props
    let content = null
    if (!progress) {
      content = this.renderNoDeliveryMsg()
    } else {
      content = <ProgressPoints progressList={this.prepareProgressList()} />
    }
    return (
      <NavLink
        className="filter-route-link"
        to={createDeliveriesLinkUrl(
          {
            id: deliverableId,
            name: deliverableName
          },
          'current'
        )}
      >
        {content}
      </NavLink>
    )
  }
}

DeliveryProgress.propTypes = {
  deliverableId: PropTypes.number,
  deliverableName: PropTypes.string,
  progress: PropTypes.object
}

export default DeliveryProgress
