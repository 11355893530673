import React from 'react'
import { TableHeaderColumn } from 'react-bootstrap-table'
import {
  caretRender,
  buildClientNameRenderer,
  renderNextDelivery,
  renderDate,
  renderActiveStatus
} from '../Common/table-utils'

const buildClientColumns = ({ onEdit, onToggleStatus, onDelete }) => {
  const getColumn = column => {
    const defaultColumnConfig = {
      label: column,
      field: column,
      dataSort: true,
      dataAlign: 'center',
      headerAlign: 'center',
      width: '100px',
      caretRender
    }

    let config = {}
    switch (column) {
      case 'id':
        config = {
          label: '#',
          hidden: true
        }
        break
      case 'name':
        config = {
          label: 'Client',
          className: 'freeze-left client-cell',
          columnClassName: 'freeze-left client-cell',
          dataFormat: buildClientNameRenderer({
            onEdit,
            onToggleStatus,
            onDelete
          }),
          dataAlign: 'left',
          width: '250px'
        }
        break
      case 'contractCount':
        config = {
          label: 'Contracts'
        }
        break
      case 'deliverableCount':
        config = {
          label: 'Deliverables',
          width: '110px'
        }
        break
      case 'isActive':
        config = {
          label: 'Status',
          dataFormat: (cell, row) => renderActiveStatus(cell, row, 'client')
        }
        break
      case 'nextDelivery':
        config = {
          label: 'Next Delivery',
          width: '130px',
          dataFormat: renderNextDelivery,
          dataSort: false
        }
        break
      case 'lastDeliveredDate':
        config = {
          label: 'Last Delivered',
          width: '110px',
          dataFormat: renderDate,
          dataSort: false
        }
        break
      case 'updatedAt':
        config = {
          label: 'Update Date',
          width: '120px',
          dataFormat: renderDate
        }
        break
      case 'createdAt':
        config = {
          label: 'Create Date',
          width: '120px',
          dataFormat: renderDate
        }
        break
      case 'createdByUser.fullname':
        config = {
          label: 'Created By',
          width: '120px',
          dataFormat: (cell, row) => {
            return row.createdByUser ? row.createdByUser.fullname : '- -'
          }
        }
        break
      default:
        break
    }
    return {
      ...defaultColumnConfig,
      ...config
    }
  }
  const fields = [
    'id',
    'name',
    'contractCount',
    'deliverableCount',
    'isActive',
    'nextDelivery',
    'lastDeliveredDate',
    'createdAt',
    'createdByUser.fullname'
  ]
  const columns = fields.map(field => getColumn(field))
  const headerColumns = columns.map((col, index) => {
    const { field, label, ...otherProps } = col
    return (
      <TableHeaderColumn dataField={field} ref={field} isKey={field === 'id'} key={`col-${index}`} {...otherProps}>
        {label}
      </TableHeaderColumn>
    )
  })
  return headerColumns
}

export default buildClientColumns
