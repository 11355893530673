import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Tooltip } from 'react-bootstrap'
import EditValue from '../EditValue'

class Unchanged extends Component {
  static propTypes = {
    cell: PropTypes.string,
    inDict: PropTypes.bool,
    updatedBy: PropTypes.string
  }

  getTooltip = () => {
    const { cell, updatedBy } = this.props
    return (
      <Tooltip id={`description-${this.props.cell}`}>
        {cell}
        {updatedBy && (
          <span className="edited-by-user label-capitalize">
            <br />
            <i>{`Updated By: ${updatedBy}`}</i>
          </span>
        )}
      </Tooltip>
    )
  }

  render() {
    return (
      <EditValue getTooltip={() => this.getTooltip()}>
        <span className={this.props.inDict ? 'in-dict' : 'not-in-dict'}>{this.props.cell}</span>
      </EditValue>
    )
  }
}

export default Unchanged
