import React from 'react'

import Metric, { useMetric } from './Metric/Metric'
import AreaChart from './Charts/AreaChart'

import { METRIC_GRAPTH } from './constants'

const IncludeWidget = () => {
  const { data, loading, subTitle, queryType, queryId, warningMessage, refreshGraph } = useMetric(METRIC_GRAPTH.INCLUDE)

  return (
    <Metric loading={loading} warningMessage={warningMessage}>
      <Metric.Header title="Revenue To Be Included" disabled={!queryId} refreshGraph={refreshGraph} />
      <Metric.Query key={queryId} queryType={queryType} />
      <AreaChart data={data} subTitle={subTitle} yAxisTitle="Revenue Outside Category (%)" threshold={5} />
    </Metric>
  )
}

export default React.memo(IncludeWidget)
