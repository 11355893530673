import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Link } from 'react-router-dom'
import cx from 'classnames'
import DocumentTitle from 'react-document-title'

// components
import Filters from './Filters'
import GroupLogo from '../GroupLogo'
import Unauthorized from '../Unauthorized'
import RVTable from '../Table/ReactVirtualizedTable'

// constants
import { appName, products } from '../../constants/constants'
import threeWayCheckboxConstants from '../ThreeWayCheckbox/constants'

// css
import './index.scss'
import 'react-virtualized/styles.css'

export default class FeedListView extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedFeeds: {},
      checkedStatus: threeWayCheckboxConstants.STATUS.NONE,
      feedsWithMetadataOpen: []
    }

    this.searchEntities = {
      groups: { display: 'Groups' },
      feeds: { display: 'Feeds' }
    }
  }

  componentDidMount() {
    this.props.fetchFeeds(0, 10)
  }

  toggleSwitch = id => {
    const feed = this.props.feeds[id - 1]
    feed.isActive = !feed.isActive
    this.props.saveFeed(feed)
  }

  toggleFeedRawMetadata(feed) {
    let ids = this.state.feedsWithMetadataOpen
    const index = ids.findIndex(e => e === feed.id)
    if (index !== -1) {
      ids = ids.filter(i => i !== feed.id)
    } else {
      ids.push(feed.id)
    }
    this.setState({ feedsWithMetadataOpen: ids })
  }

  renderFilters = metadata => {
    try {
      if (metadata) {
        const parsedMetadata = JSON.parse(metadata)
        return <div>{parsedMetadata.filters ? <Filters filters={parsedMetadata.filters} /> : <div />}</div>
      } else {
        return <div />
      }
    } catch (error) {
      return <div>Invalid metadata</div>
    }
  }

  getMaxDeliveryDate(date) {
    return date ? ` to ${moment(date).format('L')}` : ''
  }

  getStartDateFormated(feed) {
    if (!feed.startDate) {
      return 'starting after first firehose delivery'
    }
    return `from ${moment(feed.startDate).format('L')}`
  }

  isUnauthorized = key => {
    return !this.props.userProducts || !this.props.userProducts[key] || !this.props.userProducts[key].enabled
  }

  onGroupClicked = group => {
    this.props.onSearchEntityChanged('groups')
    this.props.updateFilter(group)
    this.props.fetchFeeds(0, null, group)
  }

  getColumns() {
    return [
      {
        key: 'id',
        label: 'Reference',
        width: 200,
        cellRenderer: feed => (
          <div className={'table-cell'}>
            {feed.moduleId}-{feed.frequency}-{feed.id}
          </div>
        )
      },
      {
        key: 'metadata',
        label: 'Filters',
        width: 200,
        cellRenderer: feed => <div className={'table-cell'}>{this.renderFilters(feed.metadata)}</div>
      },
      {
        key: 'startDate',
        label: 'Delivery Schedule',
        width: 200,
        cellRenderer: feed => (
          <div className={'table-cell'}>
            {feed.isActive === false
              ? `One time delivery on ${feed.startDate ? moment(feed.startDate).format('L') : 'N/A'}`
              : `${feed.frequency} delivery ${this.getStartDateFormated(feed)} ${this.getMaxDeliveryDate(
                  feed.endDate
                )}`}
          </div>
        )
      },
      {
        key: 'isActive',
        label: 'Status',
        width: 100,
        cellRenderer: feed => (
          <div className={`table-cell ${feed.isActive !== true ? '' : 'feed-status-active'}`}>
            {feed.isActive !== true ? 'Manual' : 'Active'}
          </div>
        )
      },
      {
        key: 'id',
        label: 'Group Name',
        width: 100,
        cellRenderer: feed => {
          let groupName = feed.groupName
          // Search results return in diff structure
          if (feed.Group) {
            groupName = feed.Group.name
          }
          return (
            <div className={'table-cell'}>
              {groupName ? (
                <div>
                  <GroupLogo height={15} width={15} />
                  &nbsp;
                  <span>
                    <span className="btn_link" onClick={() => this.onGroupClicked(groupName)}>
                      {groupName}
                    </span>
                    &nbsp;
                  </span>
                </div>
              ) : (
                'No Group'
              )}
            </div>
          )
        }
      },
      {
        key: 'lastDeliveryDate',
        label: 'Last Delivered',
        width: 150,
        cellRenderer: feed => (
          <div
            title={feed.lastDeliveryDate}
            className={cx({
              'table-cell': true,
              today: feed.lastDeliveryDate !== null && moment(feed.lastDeliveryDate).isSame(moment(new Date()), 'd')
            })}
          >
            {feed.lastDeliveryDate !== null && `${moment(feed.lastDeliveryDate).format('L')}`}
            {feed.lastDeliveryDate === null && 'N/A'}
          </div>
        )
      },
      {
        key: 'id',
        label: 'Actions',
        width: 100,
        cellRenderer: feed => (
          <div>
            <Link className="btn btn-success btn-xs" to={`/admin/feeds/edit/${feed.id}`}>
              Edit
            </Link>{' '}
            <Link
              className="btn btn-default btn-xs"
              to={`/admin/jobs/?search=${feed.moduleId}-${feed.frequency}-${feed.id}`}
            >
              See Jobs
            </Link>
          </div>
        )
      }
    ]
  }

  renderOptions(options) {
    return options.map(opt => (
      <option key={opt.key} value={opt.key}>
        {opt.value}
      </option>
    ))
  }

  render() {
    if (this.isUnauthorized(products.adminToolsFeeds)) {
      return <Unauthorized />
    }

    return (
      <DocumentTitle title={this.props.documentTitle ? `${appName} | ${this.props.documentTitle}` : appName}>
        <div style={{ marginTop: '0px' }} className="FeedList container">
          <div className="textRight" style={{ paddingBottom: '20px' }}>
            <Link className="btn_custom" to="/admin/feeds/edit/new">
              Add Feed
            </Link>
          </div>

          <RVTable
            enableSearch
            minimumBatchSize={15}
            data={this.props.feeds}
            query={this.props.query}
            search={this.props.search}
            columns={this.getColumns()}
            fetchData={this.props.fetchFeeds}
            searchEntities={this.searchEntities}
            updateFilter={this.props.updateFilter}
            remoteRowCount={this.props.feeds.length}
            selectedSearchEntity={this.props.searchEntity}
            onSearchEntityChanged={this.props.onSearchEntityChanged}
          />
        </div>
      </DocumentTitle>
    )
  }
}

FeedListView.propTypes = {
  feeds: PropTypes.array,
  switchToggleFeed: PropTypes.func.isRequired,
  search: PropTypes.string,
  updateFilter: PropTypes.func.isRequired,
  saveFeed: PropTypes.func.isRequired,
  groups: PropTypes.object,
  remoteRowCount: PropTypes.number,
  fetchFeeds: PropTypes.func.isRequired,
  userProducts: PropTypes.object,
  searchEntity: PropTypes.string,
  searchEntities: PropTypes.arrayOf(PropTypes.object),
  onSearchEntityChanged: PropTypes.func,
  query: PropTypes.object,
  documentTitle: PropTypes.string
}

FeedListView.defaultProps = {
  documentTitle: 'Data Feeds'
}
