import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { OverlayTrigger, Tooltip, Glyphicon } from 'react-bootstrap'
import { Overlay } from 'react-overlays'
import { DateRangePicker } from 'react-date-range'
import cx from 'classnames'
import moment from 'moment'
import { isSameDay, format, subYears, addYears } from 'date-fns'
import ArrowUpIcon from 'components/Icon/ArrowUp'
import ArrowDownIcon from 'components/Icon/ArrowDown'
import { rangeToString } from 'utils/date-range-util'
import config from 'config'

import predefinedMonthsRanges from './ranges'

import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import './index.scss'

const { primaryColor } = config.colors
const SELECTION_KEY = 'selection'
const MIN_DATE_RANGE = subYears(new Date(), 20)
const MAX_DATE_RANGE = addYears(new Date(), 2)

/** Any value convert to js Date obj
 * @date - moment|string|Date obj
 * @defaultValue
 * return Date object
 */
const getDateObj = (date, defaultDate = null) => {
  return date ? moment(date).toDate() : defaultDate
}

class DateRangeFilterComponent extends Component {
  constructor(props) {
    super(props)

    // In any case convert to Date object
    const startDate = getDateObj(props.startDate)
    const endDate = getDateObj(props.endDate)

    this.state = {
      isOpen: false,
      startDate,
      endDate,
      startDateDisplayed: startDate,
      endDateDisplayed: endDate
    }

    this.dateRangeFilterWrapperRef = React.createRef()
    this.popRef = React.createRef()
  }

  _toggleOpenPanel = () => {
    this.setState({ isOpen: !this.state.isOpen })
  }

  _closePanel = () => {
    this.setState({ isOpen: false })
  }

  _getLabel = (startDate, endDate) => {
    return rangeToString([startDate, endDate], false, 'MMM-DD-YYYY')
  }

  handleDateSelect = ranges => {
    const { startDate, endDate } = ranges[SELECTION_KEY]
    const isSecondClickOnTheSameDay =
      isSameDay(startDate, this.state.startDate) && isSameDay(endDate, this.state.endDate)

    this.setState({ startDate, endDate }) // set start/end date -> update the date range popup

    if (isSecondClickOnTheSameDay || !isSameDay(startDate, endDate)) {
      // select date range
      this._closePanel()
      this.setState({ startDateDisplayed: startDate, endDateDisplayed: endDate })
      this.props.handleDateSelection([startDate, endDate])
    }
  }

  render() {
    const { label, className, dateDisplayFormat, availableRangeInfo, customDateRanges } = this.props
    const { isOpen, startDate, endDate, startDateDisplayed, endDateDisplayed } = this.state

    const rangeStart = getDateObj(this.props.rangeStart, MIN_DATE_RANGE)
    const rangeEnd = getDateObj(this.props.rangeEnd, MAX_DATE_RANGE)

    // This is a place for enhancement predefined date ranges
    this.predefinedRanges = predefinedMonthsRanges(rangeStart, rangeEnd)

    const getTooltip = label => (
      <Tooltip id={label}>
        {label}:<br />
        {this._getLabel(startDateDisplayed, endDateDisplayed)}
      </Tooltip>
    )

    const getAvailableInfo = availableRangeInfo => (
      <div className="date-range-filter-popup-available">
        Allowable range: {format(rangeStart, dateDisplayFormat)}-{format(rangeEnd, dateDisplayFormat)}
        {availableRangeInfo && (
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip id="date-range-filter-available-tooltip">{availableRangeInfo}</Tooltip>}
          >
            <Glyphicon className="date-range-filter-popup-available-info" glyph="info-sign" />
          </OverlayTrigger>
        )}
      </div>
    )

    return (
      <>
        <div ref={this.dateRangeFilterWrapperRef} className={cx('date-range-filter', className)}>
          <OverlayTrigger rootClose placement="top" overlay={getTooltip(label)}>
            <div
              className="squareBoxWithArrow date-range-filter-body"
              style={{ borderColor: isOpen ? primaryColor : '' }}
              onClick={this._toggleOpenPanel}
            >
              <span className={cx('filterSelector')}>
                {startDateDisplayed && endDateDisplayed ? (
                  this._getLabel(startDateDisplayed, endDateDisplayed)
                ) : (
                  <span className="date-range-filter-placeholder">Enter date range</span>
                )}
              </span>
              <span className="spanRightArrowIcon">
                {isOpen ? (
                  <ArrowUpIcon id="arrowIconUp" fill="#4C4C4C" width={32} height={32} />
                ) : (
                  <ArrowDownIcon id="arrowIconDown" className="defaultArrowDown" width={32} height={32} />
                )}
              </span>
            </div>
          </OverlayTrigger>
        </div>
        <Overlay
          show={isOpen}
          rootClose
          onHide={this._closePanel}
          target={this.dateRangeFilterWrapperRef}
          placement="bottom"
        >
          {({ props, arrowProps, placement }) => {
            return (
              <div className={cx('date-range-filter-popup', 'Z2-shadow')} {...props}>
                <DateRangePicker
                  ranges={[
                    {
                      startDate,
                      endDate,
                      key: SELECTION_KEY
                    }
                  ]}
                  onChange={this.handleDateSelect}
                  inputRanges={[]}
                  color={primaryColor}
                  rangeColors={[primaryColor]}
                  staticRanges={customDateRanges?.length > 0 ? customDateRanges : this.predefinedRanges}
                  moveRangeOnFirstSelection={false}
                  editableDateInputs
                  dateDisplayFormat={dateDisplayFormat}
                  minDate={rangeStart}
                  maxDate={rangeEnd}
                  footerContent={getAvailableInfo(availableRangeInfo)}
                />
              </div>
            )
          }}
        </Overlay>
      </>
    )
  }
}

DateRangeFilterComponent.propTypes = {
  startDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  endDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  rangeStart: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  rangeEnd: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  handleDateSelection: PropTypes.func.isRequired,
  availableRangeInfo: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
  customDateRanges: PropTypes.arrayOf(PropTypes.object),
  dateDisplayFormat: PropTypes.string // Date format base on "date-fns" lib
}

DateRangeFilterComponent.defaultProps = {
  dateRange: {},
  label: 'Date Range',
  dateDisplayFormat: 'MM/dd/yyyy',
  availableRangeInfo: ''
}

/**
 * This wrapper is used for updating DateRange component when new start/end dates are set
 */
const DateRangeFilter = props => {
  // Build key base on stard/end Dates.
  const key = getDateObj(props.startDate) + '-' + getDateObj(props.endDate)

  // Rebuild dateRangeFilter base on key
  return <DateRangeFilterComponent {...props} key={key} />
}

DateRangeFilter.propTypes = {
  startDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  endDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)])
}

export default DateRangeFilter
