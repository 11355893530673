import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { cloneDeep, isObject, isEmpty } from 'lodash'
import { Button, Glyphicon } from 'react-bootstrap'
import cx from 'classnames'

import Property from './Property'
import NewProperty from './NewProperty'
import RawJson from './RawJson'

class JsonEditField extends Component {
  state = {
    isOpenRaw: false
  }

  onPropertyChange = changedProp => {
    const { onChange, value } = this.props

    const result = {
      ...value,
      ...changedProp
    }
    onChange(result)
  }

  onRemoveHandler = key => {
    const { onChange, value } = this.props
    const copyValue = cloneDeep(value)
    delete copyValue[key]
    onChange(copyValue)
  }

  onRawJsonChanged = newValue => {
    const { onChange } = this.props
    onChange(newValue)
  }

  toggleOpenRaw = () => {
    this.setState({ isOpenRaw: !this.state.isOpenRaw })
  }

  render() {
    const { value, level, maxLevel, requiredKeys } = this.props
    const { isOpenRaw } = this.state

    return (
      <div className={cx('json-edit-field', { 'json-edit-sub-field': level > 0 })}>
        {value &&
          Object.keys(value).map(key =>
            Property({
              key,
              value: value[key],
              level,
              maxLevel,
              requiredKeys,
              onValueChange: this.onPropertyChange,
              onRemoveHandler: this.onRemoveHandler
            })
          )}

        <NewProperty level={level} onSave={this.onPropertyChange} isShowAddNew={isObject(value) && isEmpty(value)} />

        {level === 0 && (
          <>
            <Button className="btn-link json-edit-field-toggle-raw" onClick={this.toggleOpenRaw}>
              {isOpenRaw ? (
                <>
                  <span>Hide raw JSON</span>
                  <Glyphicon title="Hide raw JSON" glyph={'menu-up'} />
                </>
              ) : (
                <>
                  <span>Show raw JSON</span>
                  <Glyphicon title="Show raw JSON" glyph={'menu-down'} />
                </>
              )}
            </Button>
            {isOpenRaw && <RawJson value={value} onChange={this.onRawJsonChanged} />}
          </>
        )}
      </div>
    )
  }
}

JsonEditField.defaultProps = {
  level: 0,
  maxLevel: 1,
  requiredKeys: []
}

JsonEditField.propTypes = {
  value: PropTypes.oneOfType([PropTypes.object]),
  onChange: PropTypes.func.isRequired,
  level: PropTypes.number,
  maxLevel: PropTypes.number,
  requiredKeys: PropTypes.arrayOf(PropTypes.string)
}

export default JsonEditField
