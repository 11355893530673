import { connect } from 'react-redux'
import { orchestrate, loadTasks, updateTaskAutoRefresh, updateTaskSearchFilter } from '../../actions/task-actions'
import { confirmModal } from '../../actions/modal-actions'
import UserService from '../../services/user-service'
import QueryString from 'query-string'

import JobListView from './index.view'
import WithErrorBoundaryWrapper from 'components/WithErrorBoundaryWrapper/WithErrorBoundaryWrapper'

function mapStateToProps(state, props) {
  return {
    jobs: state.tasks.data,
    loading: state.tasks.loading,
    jobsCount: state.tasks.count,
    autoRefresh: state.tasks.autoRefresh,
    selectedJobs: state.jobListText.selectedJobs,
    query: QueryString.parse(props.location.search),
    searchField: state.tasks.searchField,
    searchValue: state.tasks.searchValue,
    userProducts: UserService.getProducts(state.session.user),
    isPortalAdmin: state.session.user ? UserService.isPortalAdmin(state.session.user) : false
  }
}

function mapDispatchToProps(dispatch) {
  return {
    updateTaskSearchFilter: (field, value) => dispatch(updateTaskSearchFilter(field, value)),
    orchestrate: (jobType, metadata) => dispatch(orchestrate(jobType, metadata)),
    confirmModal: (content, title, okButton, callback) => dispatch(confirmModal(content, title, okButton, callback)),
    loadTasks: payload => dispatch(loadTasks(payload)),
    updateTaskAutoRefresh: value => dispatch(updateTaskAutoRefresh(value))
  }
}

export default WithErrorBoundaryWrapper(
  connect(mapStateToProps, mapDispatchToProps)(JobListView),
  '"Admin Job Monitoring" page'
)
