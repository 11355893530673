import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { HelpBlock } from 'react-bootstrap'
import NumberInputField from '../../../Common/NumberInputField'
import SingleDateInput from '../../../../SingleDateInput'
import { MonthSelector, DayTypeSelector, YearSelector } from '../../../Common/DateTimeSelectors'
import DeliverableIcon from '../../../../Icon/DeliverableIcon'
import ArrowIcon from '../../../../Icon/ArrowUp'
import cx from 'classnames'
import moment from 'moment'

const DATE_FORMAT = 'MM/DD/YYYY'

class DeliverySchedule extends Component {
  renderBottomBox = () => {
    const {
      startMonth,
      startYear,
      frequency = '',
      deliveryDay = '',
      deliveryDayType,
      updateDetails,
      editable
    } = this.props
    return (
      <React.Fragment>
        <div className="bottom-connector" />
        <div className="bottom-box">
          The rest of the deliveries begin on
          <MonthSelector
            placeholder="Month"
            selected={startMonth}
            onChange={value => {
              updateDetails({
                startMonth: value
              })
            }}
            readOnly={!editable}
          />
          <YearSelector
            placeholder="Year"
            selected={startYear}
            onChange={value =>
              updateDetails({
                startYear: value
              })
            }
            readOnly={!editable}
          />
          every
          <NumberInputField
            min={1}
            max={12}
            value={frequency}
            onChange={value =>
              updateDetails({
                frequency: value
              })
            }
            readOnly={!editable}
          />
          <React.Fragment>{frequency < 2 ? 'month' : 'months'}</React.Fragment> on
          <NumberInputField
            min={1}
            max={31}
            value={deliveryDay}
            onChange={value =>
              updateDetails({
                deliveryDay: value
              })
            }
            ordinalSuffix
            readOnly={!editable}
          />
          <DayTypeSelector
            placeholder="Select"
            selected={deliveryDayType}
            onChange={value =>
              updateDetails({
                deliveryDayType: value
              })
            }
            readOnly={!editable}
          />
          {deliveryDayType === 'MONTH_DAY' && (
            <HelpBlock>* It will extend to next working day if it falls on a legal holiday</HelpBlock>
          )}
        </div>
      </React.Fragment>
    )
  }

  renderIconbox = () => {
    const { totalDeliveries } = this.props
    return (
      <React.Fragment>
        <div className="top-box" />
        <div className="icon-box">
          <DeliverableIcon />
          {totalDeliveries > 1 && <DeliverableIcon />}
          {totalDeliveries > 2 && <DeliverableIcon />}
          {totalDeliveries > 2 && <DeliverableIcon />}
          <DeliverableIcon />
        </div>
      </React.Fragment>
    )
  }

  renderArrow = () => (
    <div className="arrow-right">
      <ArrowIcon fill="currentColor" />
    </div>
  )

  calcSecondDeliveryDate = () => {
    const { firstDelivery, frequency, startMonth, startYear } = this.props
    let secondDelivery = null
    if (startMonth && startYear) {
      secondDelivery = moment()
        .month(startMonth - 1)
        .year(startYear)
        .endOf('month')
    } else if (frequency) {
      secondDelivery = moment(firstDelivery).add(frequency, 'months')
    }
    return secondDelivery
  }

  calcLastDeliveryDate = () => {
    const { totalDeliveries, firstDelivery, frequency } = this.props
    if (!firstDelivery || !totalDeliveries || !frequency) {
      return
    }
    if (totalDeliveries === 1) {
      return moment(firstDelivery)
    }
    const secondDelivery = this.calcSecondDeliveryDate()
    return moment(secondDelivery).add(
      frequency * (totalDeliveries - 2), // since 1st two deliveries are done
      'months'
    )
  }

  render() {
    const { totalDeliveries = '', firstDelivery, updateDetails, editable, currentDeliveryId } = this.props
    return (
      <div className="delivery-schedule">
        <NumberInputField
          className="total-deliveries"
          value={totalDeliveries}
          onChange={value =>
            updateDetails({
              totalDeliveries: value || 0
            })
          }
          postLabel="Total Deliveries"
          max={999}
          readOnly={!editable}
        />
        <div className="ds-dt-wrapper">
          {this.renderIconbox()}
          <SingleDateInput
            name="First Delivery"
            className={cx('first-date-input', { uneditable: currentDeliveryId })}
            popupClassName="sb-form-popup"
            popupTitle="Select Date for First Delivery"
            minDate={moment()}
            selectLabel="DD MMM, YYYY"
            selectedDate={firstDelivery ? moment(firstDelivery, DATE_FORMAT) : undefined}
            format="DD MMM, YYYY"
            onDateChange={value =>
              updateDetails({
                firstDelivery: value && value.format(DATE_FORMAT)
              })
            }
            readOnly={!editable || !!currentDeliveryId}
            tooltip={
              currentDeliveryId
                ? 'First delivery date cannot be edited once a delivery is already created for the Deliverable. However, you can still edit deadline for the actual Delivery.'
                : undefined
            }
          />
          <SingleDateInput
            name="Last Delivery"
            selectLabel="- -"
            className="last-date-input"
            selectedDate={this.calcLastDeliveryDate()}
            format="MMM, YYYY"
            disabled
          />
          {this.renderArrow()}
          {totalDeliveries > 1 && this.renderBottomBox()}
        </div>
      </div>
    )
  }
}

DeliverySchedule.propTypes = {
  totalDeliveries: PropTypes.number,
  firstDelivery: PropTypes.string,
  startMonth: PropTypes.number,
  startYear: PropTypes.number,
  frequency: PropTypes.number,
  deliveryDay: PropTypes.number,
  deliveryDayType: PropTypes.string,
  updateDetails: PropTypes.func,
  editable: PropTypes.bool,
  currentDeliveryId: PropTypes.number
}

export default DeliverySchedule
