import React from 'react'
import PropTypes from 'prop-types'
import cuid from 'cuid'
import {
  FormGroup,
  FormControl,
  HelpBlock,
  Col,
  ControlLabel,
  OverlayTrigger,
  Tooltip,
  Glyphicon
} from 'react-bootstrap'

// import->export related modules
import getElementValue from './utils'
import useFormValues from './useFormValues'

export { getElementValue, useFormValues }

const getInfoTooltip = (text, id) => {
  return (
    <OverlayTrigger rootClose overlay={<Tooltip id={id}>{text}</Tooltip>} delayShow={300}>
      <Glyphicon glyph="info-sign" style={{ marginLeft: '5px' }} />
    </OverlayTrigger>
  )
}

const InputField = React.memo(function InputFieldComponent({
  label,
  validationState,
  validationMessage,
  required = false,
  children,
  info,
  help,
  className,
  labelSize = 3,
  fieldSize,
  ...props
}) {
  // autmaticaly set validation state error
  if (validationState === undefined && validationMessage) {
    validationState = 'error'
  }
  const BOOTSTRAP_COLUMNS_COUNT = 12
  const computedFieldSize = fieldSize || BOOTSTRAP_COLUMNS_COUNT - labelSize
  return (
    <FormGroup className={className} validationState={validationState}>
      <Col sm={labelSize}>
        <ControlLabel className={`${required ? 'required' : ''} pt-2`}>
          {label}
          {info && getInfoTooltip(info, props.id || cuid())}
        </ControlLabel>
      </Col>
      <Col sm={computedFieldSize}>
        {children !== undefined ? children : <FormControl {...props} />}
        {validationMessage && <HelpBlock>{validationMessage}</HelpBlock>}
        {help || null}
      </Col>
    </FormGroup>
  )
})

InputField.propTypes = {
  label: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element), PropTypes.string]),
  labelSize: PropTypes.number,
  fieldSize: PropTypes.number,
  validationState: PropTypes.string,
  validationMessage: PropTypes.string,
  className: PropTypes.string,
  required: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element), PropTypes.node]),
  help: PropTypes.element,
  info: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  id: PropTypes.string
}

export default InputField
