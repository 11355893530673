/*!
 * deps
 */

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import Input from '../Input'
import Box from '../Box'
import CheckBox from '../CheckBox'
import FilterListModal from '../Modals/FilterListModal'
import SearchIcon from '../Icon/SearchIcon'
import './index.scss'
import { confirmModal } from '../../actions/modal-actions'
import { connect } from 'react-redux'
import { clone } from 'lodash'
/*!
 * globals
 */

const noop = () => {}

/**
 * CheckBoxMultiSelect component
 */

class CheckBoxMultiSelect extends Component {
  static propTypes = {
    canSelectTop: PropTypes.bool,
    selectedNames: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    options: PropTypes.object,
    keys: PropTypes.array.isRequired,
    top10: PropTypes.object,
    data: PropTypes.object,
    valueDidChange: PropTypes.func,
    className: PropTypes.string,
    columnCount: PropTypes.number,
    canBeHidden: PropTypes.bool,
    isImportEnable: PropTypes.bool,
    error: PropTypes.string,
    isHiddenByDefault: PropTypes.bool,
    entityNames: PropTypes.object,
    importTitle: PropTypes.string,
    confirmModal: PropTypes.func
  }

  static defaultProps = {
    valueDidChange: noop,
    columnCount: 2,
    items: {},
    data: {},
    label: '',
    canBeHidden: false,
    isHiddenByDefault: false,
    isImportEnable: false,
    entityNames: { singular: '', plural: '' }
  }

  constructor(args) {
    super(args)
    this.state = {
      isHidden: this.props.isHiddenByDefault,
      searchValue: ''
    }
  }

  _textDidChange = searchValue => {
    this.setState({ searchValue })
  }

  _valueDidChange = key => checked => {
    this.props.valueDidChange({ ...this.props.data, [key]: checked })
  }

  _selectAll = () => {
    this.props.valueDidChange(
      this.props.keys.reduce((acc, key) => {
        acc[key] = true
        return acc
      }, {})
    )
  }

  _selectTop10 = () => {
    this.props.valueDidChange(this.props.top10)
  }

  _clear = () => {
    this.props.valueDidChange({})
  }

  toggleIsHidden = () => {
    this.setState({ isHidden: !this.state.isHidden })
  }

  onImportFilter = filtersArray => {
    const unknownIds = []
    const filtersFormatted = filtersArray.reduce((obj, filter) => {
      if (this.props.options[filter]) {
        obj[filter] = true
      } else {
        unknownIds.push(filter)
      }
      return obj
    }, {})

    this.props.valueDidChange({ ...this.props.data, ...filtersFormatted })
  }

  openImportModal = () => {
    this.filtersDataRef = null
    this.props.confirmModal(
      <FilterListModal
        onSubmit={values => {
          this.filtersDataRef = values
        }}
      />,
      this.props.importTitle || 'Import',
      { okButton: 'Import' },
      () => {
        this.onImportFilter(clone(this.filtersDataRef))
        this.filtersDataRef = null
      }
    )
  }

  render() {
    const {
      columnCount,
      className,
      canBeHidden,
      selectedNames,
      label,
      placeholder,
      canSelectTop,
      keys,
      options,
      isImportEnable,
      entityNames
    } = this.props

    const selectedValues = this.props.data

    const { searchValue, isHidden } = this.state

    const columnWidth = `${100 / columnCount}%`

    const length = Object.keys(selectedValues).filter(k => selectedValues[k]).length
    let toggleMessage = ''
    if (!length) {
      toggleMessage = 'no restrictions applied'
    } else if (length === 1) {
      toggleMessage = `1 ${entityNames.singular} selected`
    } else {
      toggleMessage = `${length} ${entityNames.plural} selected`
    }

    return (
      <div className={cx(className, 'container CheckBoxMultiSelect')}>
        {canBeHidden && (
          <div>
            <span className="visibilityStyle btn_link" onClick={this.toggleIsHidden}>
              {isHidden ? `Show (${toggleMessage})` : `Hide (${toggleMessage})`}
            </span>
          </div>
        )}
        {!isHidden && isImportEnable && (
          <span className="top-spacing-sm inline-block btn_link" onClick={this.openImportModal}>
            Import {entityNames.singular} list
          </span>
        )}
        {!isHidden && (
          <div className="typeContainer">
            <div className={cx('boxContainer', { withSelectedNames: selectedNames })}>
              <Box className="flex alignItemsCenter">
                <>
                  {label && <label className={cx('multiLabel', 'bold')}>{label}</label>}
                  <Input
                    clearButton
                    placeholder={placeholder}
                    className={cx('input', 'flex1', 'productInput')}
                    value={searchValue}
                    iconName={<SearchIcon width={24} height={24} />}
                    didClickClearButton={() => this._textDidChange('')}
                    textDidChange={this._textDidChange}
                  />
                  <Box className={'actions flex directionColumn'}>
                    <>
                      <span className="medium" onClick={this._selectAll}>
                        Select all
                      </span>
                      {canSelectTop ? (
                        <span className="medium" onClick={this._selectTop10}>
                          Select top 10
                        </span>
                      ) : (
                        ''
                      )}
                      <span className="medium" onClick={this._clear}>
                        Clear
                      </span>
                    </>
                  </Box>
                </>
              </Box>
              <div className="flex">
                {this.props.error ? (
                  <span className="warning bg-warning">
                    {this.props.error} <span onClick={() => this.setState({ errorMessage: null })}>X</span>
                  </span>
                ) : (
                  ''
                )}
              </div>
              <Box className={'items wrap'}>
                <>
                  {keys
                    .filter(key => options[key].name.toLowerCase().startsWith(searchValue.toLowerCase()))
                    .map(key => (
                      <CheckBox
                        key={key}
                        style={{ width: columnWidth }}
                        className={'item'}
                        valueDidChange={this._valueDidChange(key)}
                        checked={selectedValues[key]}
                        label={options[key].name}
                      />
                    ))}
                </>
              </Box>
            </div>
            {selectedNames && (
              <div className="selectedNames">
                <label>
                  {selectedNames} ({Object.keys(selectedValues).length})
                </label>
                <div className="namesContainer">
                  {Object.keys(selectedValues).map((key, index) => (
                    <div key={index}>{options[key] ? options[key].name : ''}</div>
                  ))}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    )
  }
}

export default connect(
  () => {
    return {}
  },
  { confirmModal }
)(CheckBoxMultiSelect)
