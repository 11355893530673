import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import DocumentTitle from 'react-document-title'

import { Grid, Row, Col } from 'react-bootstrap'
import { default as QueryBuilderWrapper } from './QueryBuilderWrapper'
import { default as Content } from './content/Content'
import { loadDefaultQuery, toggleTreeVisibility, hideTree } from './actions/niq-search-actions'
import '../../styles/treeView.scss'
import UserService from '../../services/user-service'
import { products, appName, permissions } from 'constants/constants'
import { NIQ_SEARCH_STORE_PATH } from './reducers/niq-reducers'
import Unauthorized from '../Unauthorized'
import WithErrorBoundaryWrapper from 'components/WithErrorBoundaryWrapper/WithErrorBoundaryWrapper'

class Search extends Component {
  static propTypes = {
    isTreeVisible: PropTypes.bool,
    loadDefaultQuery: PropTypes.func,
    hasUserNIQProduct: PropTypes.bool,
    hasUserViewPermissions: PropTypes.bool,
    hideTree: PropTypes.func,
    toggleTreeVisibility: PropTypes.func
  }

  componentDidMount() {
    // Load initial query
    this.props.loadDefaultQuery()
  }

  render() {
    const { hasUserNIQProduct, hasUserViewPermissions, documentTitle } = this.props
    if (!hasUserNIQProduct || !hasUserViewPermissions) {
      return <Unauthorized />
    }
    return (
      <DocumentTitle title={documentTitle ? `${appName} | ${documentTitle}` : appName}>
        <Grid bsClass="search-root">
          <Row className="show-grid">
            <Col xs={12} md={12}>
              <QueryBuilderWrapper
                isTreeVisible={this.props.isTreeVisible}
                toggleTreeVisibility={this.props.toggleTreeVisibility}
              />
              <div
                onClick={this.props.hideTree}
                className={`overlay
                  ${this.props.isTreeVisible ? 'query-tree-overlay-visible' : 'query-tree-overlay-hidden'}`}
              />
              <Content />
            </Col>
          </Row>
        </Grid>
      </DocumentTitle>
    )
  }
}

Search.propTypes = {
  isTreeVisible: PropTypes.bool,
  loadDefaultQuery: PropTypes.func,
  hasUserNIQProduct: PropTypes.bool,
  hasUserViewPermissions: PropTypes.bool,
  hideTree: PropTypes.func,
  toggleTreeVisibility: PropTypes.func,
  documentTitle: PropTypes.string
}

Search.defaultProps = {
  documentTitle: 'QC Tool'
}

function mapStateToProps(state) {
  return {
    isTreeVisible: state[NIQ_SEARCH_STORE_PATH].isTreeVisible,
    hasUserNIQProduct: UserService.isProductAuthorized(UserService.getProducts(state.session.user), products.NIQqcTool),
    hasUserViewPermissions: UserService.hasPermission(state.session.user, permissions.NIQqcToolView)
  }
}

export default WithErrorBoundaryWrapper(
  connect(mapStateToProps, { loadDefaultQuery, toggleTreeVisibility, hideTree })(Search),
  '"NIQ QC Tool" page'
)
