import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button, Glyphicon, OverlayTrigger, Tooltip, Tabs, Tab } from 'react-bootstrap'
import { connect } from 'react-redux'

import { default as WidgetWrapper } from './widgets/WidgetWrapper'
import { onSearchFilterChanged } from '../actions/niq-widget-actions'
import constants, { searchToolTabs } from '../constants/niq-constants'
import moment from 'moment'
import './resizable-widget.scss'
import './search-tab.scss'
import { joinRoom, leaveRoom, DICT_EDIT_ROOM } from '../../../socket'

import { toggleQCMode, toggleLayoutLock, setSelectedTab } from '../actions/niq-search-actions'
import { NIQ_SEARCH_STORE_PATH } from '../reducers/niq-reducers'

const { EDIT_MODE } = searchToolTabs

class Content extends Component {
  componentDidMount() {
    joinRoom(DICT_EDIT_ROOM)
  }

  componentWillUnmount() {
    leaveRoom(DICT_EDIT_ROOM)
  }

  handleDateSelection = range => {
    this.props.onSearchFilterChanged('range', range)
  }

  render() {
    const tooltip = name => <Tooltip id="edit-layout-tooltip">{name}</Tooltip>
    return (
      <div id="search-qc-wrapper">
        <Tabs
          onSelect={this.props.setSelectedTab}
          id="search-tabs"
          className="tab-label portal-tabs"
          activeKey={this.props.isActiveTab}
        >
          <Tab eventKey={EDIT_MODE} title={EDIT_MODE} unmountOnExit>
            <OverlayTrigger
              placement="left"
              delayShow={1000}
              overlay={tooltip(this.props.isLayoutLocked ? 'Edit Layout' : 'Lock Layout')}
            >
              <>
                <Button
                  bsStyle="default"
                  active={!this.props.isLayoutLocked}
                  className="pull-right edit-nlayout"
                  onClick={event => {
                    event.preventDefault()
                    this.props.toggleLayoutLock()
                  }}
                >
                  <Glyphicon glyph="edit" />
                </Button>
              </>
            </OverlayTrigger>
            <div style={{ position: 'relative', zIndex: 5 }}>
              <WidgetWrapper
                isLayoutLocked={this.props.isLayoutLocked}
                handleDateSelection={this.handleDateSelection}
                rangeStart={this.props.filters.min_range}
                rangeEnd={this.props.filters.max_range}
                from={this.props.filters.from}
                to={this.props.filters.to}
                lastLoadDate={this.props.lastLoadDate}
                dataIndex={this.props.dataIndex}
              />
            </div>
          </Tab>
        </Tabs>
      </div>
    )
  }
}

Content.propTypes = {
  isLayoutLocked: PropTypes.bool,
  toggleQCMode: PropTypes.func.isRequired,
  setSelectedTab: PropTypes.func.isRequired,
  filters: PropTypes.object,
  onSearchFilterChanged: PropTypes.func.isRequired,
  toggleLayoutLock: PropTypes.func.isRequired,
  isActiveTab: PropTypes.string,
  lastLoadDate: PropTypes.object,
  dataIndex: PropTypes.string
}

function mapStateToProps(state) {
  const niqSearchState = state[NIQ_SEARCH_STORE_PATH]
  return {
    isTreeVisible: niqSearchState.isTreeVisible,
    isLayoutLocked: niqSearchState.isLayoutLocked,
    isActiveTab: niqSearchState.isActiveTab,
    filters: {
      ...niqSearchState.searchFilters,
      min_range: niqSearchState.searchFilters.min_range
        ? niqSearchState.searchFilters.min_range
        : moment('1', 'X')
            .utc()
            .format(constants.dateFormat.qcTool),
      max_range: niqSearchState.searchFilters.max_range,
      from: niqSearchState.searchFilters.from,
      to: niqSearchState.searchFilters.to
    },
    lastLoadDate: niqSearchState.lastLoadDate,
    dataIndex: niqSearchState.dataIndex
  }
}

export default connect(mapStateToProps, {
  toggleQCMode,
  onSearchFilterChanged,
  toggleLayoutLock,
  setSelectedTab
})(Content)
