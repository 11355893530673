import React, { Component } from 'react'
import { connect } from 'react-redux'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import PropTypes from 'prop-types'

const SHOW_RESULTS_TABLE = false

class AddToDictResultsTable extends Component {
  renderShowsTotal = (start, to) => {
    return (
      <p>
        Showing {this.props.rows.length} of {this.props.total}
      </p>
    )
  }

  overlayFormatter = (cell, row, placement = 'right') => {
    return (
      <OverlayTrigger placement={placement} delayShow={500} overlay={this.tooltip(cell)}>
        <span>{cell}</span>
      </OverlayTrigger>
    )
  }

  tooltip = name => <Tooltip id="add-to-dictionary-tooltip">{name}</Tooltip>

  render() {
    let sequence = 1
    const options = {
      page: 1,
      sizePerPage: 10,
      paginationShowsTotal: this.renderShowsTotal,
      hideSizePerPage: true
    }
    return (
      <>
        {this.props.existingRules.length ? (
          <>
            <h4 style={{ marginTop: 20 }}>Existing rules matching the regex</h4>
            <BootstrapTable
              data={this.props.existingRules.map(row => ({ ...row, sequence: sequence++ }))}
              striped
              condensed
              bordered
            >
              <TableHeaderColumn dataField="sequence" hidden isKey>
                Sequence
              </TableHeaderColumn>

              <TableHeaderColumn dataFormat={this.overlayFormatter} dataField="id">
                ID
              </TableHeaderColumn>

              <TableHeaderColumn
                dataFormat={this.overlayFormatter}
                tdStyle={{ whiteSpace: 'normal' }}
                dataField="subject"
              >
                Subject
              </TableHeaderColumn>
              <TableHeaderColumn
                tdStyle={{ whiteSpace: 'normal' }}
                dataFormat={this.overlayFormatter}
                dataField="from_addr"
              >
                From
              </TableHeaderColumn>
              <TableHeaderColumn dataField="domain">Domain</TableHeaderColumn>
              <TableHeaderColumn dataField="type">Type</TableHeaderColumn>
            </BootstrapTable>
            <hr />
          </>
        ) : (
          ''
        )}
        {SHOW_RESULTS_TABLE ? (
          <>
            <h4 style={{ marginTop: 20 }}>Rows matching the regex</h4>
            <BootstrapTable
              data={this.props.rows.map(row => ({ ...row, sequence: sequence++ }))}
              striped
              condensed
              bordered
              pagination
              options={options}
            >
              <TableHeaderColumn dataField="sequence" hidden isKey>
                Sequence
              </TableHeaderColumn>

              <TableHeaderColumn
                dataFormat={this.overlayFormatter}
                tdStyle={{ whiteSpace: 'normal' }}
                dataField="subject"
              >
                Subject
              </TableHeaderColumn>
              <TableHeaderColumn
                tdStyle={{ whiteSpace: 'normal' }}
                dataFormat={this.overlayFormatter}
                dataField="from_addr"
              >
                From
              </TableHeaderColumn>
              <TableHeaderColumn dataField="domain">Domain</TableHeaderColumn>
              <TableHeaderColumn dataField="type">Type</TableHeaderColumn>
            </BootstrapTable>
          </>
        ) : (
          ''
        )}
      </>
    )
  }
}

AddToDictResultsTable.propTypes = {
  rows: PropTypes.array,
  total: PropTypes.number,
  existingRules: PropTypes.array
}

function mapStateToProps(state) {
  return {
    rows: state.supraQCTool.addToDictResult.rows || [],
    total: state.supraQCTool.addToDictResult.total || 0,
    existingRules: state.supraQCTool.addToDictResult.existingRules || []
  }
}

export default connect(mapStateToProps, {})(AddToDictResultsTable)
