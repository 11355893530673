import React from 'react'
import PropTypes from 'prop-types'

import Toggle from 'components/Toggle'

import GlobalInactiveWarning from './GlobalInactiveWarning'

const getProperties = ({ isActiveLocal, isActiveGlobal, isUseLocalConfig }) => {
  const isActive = isUseLocalConfig ? isActiveLocal : isActiveGlobal
  const disabled = !isActiveGlobal || !isUseLocalConfig
  const isShowGlobalWarning = isUseLocalConfig && !isActiveGlobal

  return { isActive, disabled, isShowGlobalWarning }
}

const getValueForSave = (value, isUseLocalConfig) => {
  const valueForSave = isUseLocalConfig ? value : null
  return valueForSave
}

const useIsActiveField = ({ isUseLocalConfig, setValueForSave, updateValue }) => {
  const register = ({ id, isActive, isActiveGlobal }) => {
    setValueForSave({ [id]: getValueForSave(isActive, isUseLocalConfig) })

    const props = getProperties({ isActiveLocal: isActive, isActiveGlobal, isUseLocalConfig })

    return {
      onChange: value => updateValue({ [id]: value }),
      ...props
    }
  }

  return [register]
}

const IsActiveField = ({ isActive, onChange, disabled, isShowGlobalWarning }) => {
  return (
    <>
      <Toggle id="isActive" isActive={isActive} onChange={onChange} disabled={disabled} className="inline-block" />
      <GlobalInactiveWarning show={isShowGlobalWarning} />
    </>
  )
}

IsActiveField.propTypes = {
  isActive: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,

  disabled: PropTypes.bool.isRequired,
  isShowGlobalWarning: PropTypes.bool.isRequired
}

export { useIsActiveField }
export default IsActiveField
