import React from 'react'

export default function OpenEye(props) {
  return (
    <svg
      width="22px"
      height="12px"
      viewBox="0 0 22 12"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <defs />
      <g id="Components" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g id="Icons" transform="translate(-424.000000, -232.000000)">
          <g id="open-eye" transform="translate(420.000000, 223.000000)">
            <g>
              <rect id="spacing" x={0} y={0} width={30} height={30} />
              <g id="eye" transform="translate(4.000000, 9.000000)" fill="currentColor">
                <path
                  d="M21.6438901,5.75771429 C21.6318022,5.74296703 21.6170549,5.73136264 21.6027912,5.71879121 C21.5798242,5.69364835 21.5505714,5.66004396 21.509956,5.61386813 C18.4224615,2.08685714 14.7107473,0.117978022 11.0582637,0.0701098901 C11.0108791,0.0696263736 10.963978,0.0691428571 10.9170769,0.0691428571 C7.2218022,0.0691428571 3.58962637,1.97274725 0.119428571,5.72894505 C0.0396483516,5.80824176 -0.00265934066,5.91896703 0.00241758242,6.03501099 C-0.000483516484,6.06813187 -0.000725274725,6.10149451 0.00483516484,6.13534066 C0.0188571429,6.22092308 0.0585054945,6.29659341 0.11556044,6.35461538 C3.58720879,10.1137143 7.22035165,12.0187692 10.9173187,12.0187692 C10.963978,12.0187692 11.0113626,12.0182857 11.0585055,12.0178022 C14.7107473,11.9699341 18.4227033,10.0010549 21.5101978,6.4738022 C21.5525055,6.42569231 21.582967,6.39087912 21.6059341,6.36549451 C21.6187473,6.35389011 21.6318022,6.34373626 21.6429231,6.33068132 C21.6704835,6.30263736 21.6944176,6.27024176 21.713033,6.23421978 C21.743978,6.17474725 21.7587253,6.10898901 21.7584835,6.04395604 C21.758967,5.97868132 21.743978,5.91316484 21.713033,5.85345055 C21.6944176,5.81791209 21.6712088,5.78575824 21.6438901,5.75771429 Z M11.0473846,11.2033187 C7.62650549,11.2463516 4.22617582,9.50931868 0.944791209,6.04395604 C4.18483516,2.62210989 7.53753846,0.883626374 10.918044,0.883626374 C10.9610769,0.883626374 11.0043516,0.88410989 11.0473846,0.884593407 C14.4286154,0.928835165 17.8864835,2.7616044 20.8003956,6.04395604 C17.8864835,9.32630769 14.4286154,11.1590769 11.0473846,11.2033187 Z"
                  id="Shape"
                  fillRule="nonzero"
                />
                <path
                  d="M11,10 C8.790861,10 7,8.209139 7,6 C7,3.790861 8.790861,2 11,2 C13.209139,2 15,3.790861 15,6 C15,8.209139 13.209139,10 11,10 Z M9.5,6 C10.3284271,6 11,5.32842712 11,4.5 C11,3.67157288 10.3284271,3 9.5,3 C8.67157288,3 8,3.67157288 8,4.5 C8,5.32842712 8.67157288,6 9.5,6 Z"
                  id="Combined-Shape"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
