import React from 'react'
import PropTypes from 'prop-types'
import { Glyphicon } from 'react-bootstrap'

const ErrorSign = ({ error }) =>
  error ? <Glyphicon title={error} className="ml-1 text-color-red" glyph="alert" /> : null

ErrorSign.propTypes = {
  error: PropTypes.string
}

export default ErrorSign
