import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button, Glyphicon, Tooltip, OverlayTrigger } from 'react-bootstrap'

class ExportContainer extends Component {
  static propTypes = {
    onExport: PropTypes.func
  }

  render() {
    const tooltip = <Tooltip id="export-tooltip">Export Data (To CSV)</Tooltip>
    return (
      <OverlayTrigger delayShow={1000} placement="top" overlay={tooltip}>
        <Button
          bsSize="xsmall"
          bsStyle="default"
          className="action-button"
          onClick={e => {
            e.preventDefault()
            this.props.onExport()
          }}
        >
          <Glyphicon glyph="export" />
        </Button>
      </OverlayTrigger>
    )
  }
}

export default ExportContainer
