import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { cloneDeep } from 'lodash'

import Loader from '../../Loader'
import { DataTableFiltered } from './../../DataTable'

import CommonRulesToolbar from './../Common/CommonRulesToolbar'

import { CategoryBrandPathRulesActions } from './../actions'

import COMMON_COLUMNS from './../Common/commonColumns'
import { STORE_PATH } from './../constants'

class RulesCategoryBrandPath extends Component {
  constructor(props) {
    super(props)

    this.columns = this.getColumns(cloneDeep(COMMON_COLUMNS))
  }

  getColumns(commonColumns) {
    const columns = [
      {
        ...commonColumns.categoryId,
        field: 'id'
      },
      commonColumns.categoryFullPath,
      {
        ...commonColumns.id,
        field: 'rcbCategoryId',
        width: '150px',
        label: 'CBR Category Id'
      },
      {
        ...commonColumns.categoryFullPath,
        field: 'rcbCategoryFullPath',
        label: 'CBR Category Full Path'
      },
      commonColumns.isSameNode,
      commonColumns.rcbBrndCount
    ]

    return columns
  }

  render() {
    const {
      loading,
      pageSize,
      hasEditAccess,
      totalCount,
      page,
      rows,
      sortBy,
      sortOrder,
      filters,
      fetchRulesAction,
      downloadRulesAction,
      clearFilters
    } = this.props

    return (
      <React.Fragment>
        <CommonRulesToolbar
          hasEditAccess={hasEditAccess}
          toolbarType={'Category Brand Path'}
          downloadRulesAction={downloadRulesAction}
          refreshHandler={fetchRulesAction}
          clearFilters={clearFilters}
        />

        {loading && <Loader loading overlap />}

        <DataTableFiltered
          data={rows}
          columns={this.columns}
          page={page}
          pageSize={pageSize}
          totalCount={totalCount}
          sortBy={sortBy}
          sortOrder={sortOrder}
          filters={filters}
          tableClassName="rules-table"
          fetchRowsAction={fetchRulesAction}
        />
      </React.Fragment>
    )
  }
}

RulesCategoryBrandPath.defaultProps = {
  rows: [],
  totalCount: 0
}

RulesCategoryBrandPath.propTypes = {
  page: PropTypes.number,
  pageSize: PropTypes.number,
  sortBy: PropTypes.string,
  sortOrder: PropTypes.string,
  filters: PropTypes.object,
  loading: PropTypes.bool.isRequired,
  hasEditAccess: PropTypes.bool.isRequired,
  totalCount: PropTypes.number.isRequired,
  rows: PropTypes.arrayOf(PropTypes.object).isRequired,
  fetchRulesAction: PropTypes.func.isRequired,
  downloadRulesAction: PropTypes.func.isRequired,
  clearFilters: PropTypes.func.isRequired
}

const mapStateToProps = ({ rules: { [STORE_PATH.categoryBrandPath]: categoryBrandPath } }) => ({
  page: categoryBrandPath.page,
  sortBy: categoryBrandPath.sortBy,
  loading: categoryBrandPath.loading,
  rows: categoryBrandPath.data.rows,
  pageSize: categoryBrandPath.pageSize,
  sortOrder: categoryBrandPath.sortOrder,
  totalCount: categoryBrandPath.data.count,
  filters: categoryBrandPath.filters
})

const mapDispatchToProps = {
  fetchRulesAction: CategoryBrandPathRulesActions.fetch,
  downloadRulesAction: CategoryBrandPathRulesActions.download,
  clearFilters: CategoryBrandPathRulesActions.clearFilters
}

export default connect(mapStateToProps, mapDispatchToProps)(RulesCategoryBrandPath)
