import React, { Component } from 'react'
import PropTypes from 'prop-types'
import MerchantFilter from './merchants-filter-template'
import MinMaxFilter from './min-max-date-filter-template'

export default class MerchantsDate extends Component {
  static propTypes = {
    feed: PropTypes.object
  }

  render() {
    return (
      <div>
        <MerchantFilter feed={this.props.feed} />
        <MinMaxFilter feed={this.props.feed} fromDate={new Date('1/1/13')} />
      </div>
    )
  }
}
