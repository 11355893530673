import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Button, Form, Col, FormGroup, FormControl, Glyphicon, Alert } from 'react-bootstrap'
import { debounce } from 'lodash'
import moment from 'moment'

import { DEBOUNCE_ONCHANGE_UI_ACTION_TIMEOUT } from './../../../constants/constants'
import InputField, { getElementValue } from './../../FormInputComponents/InputField'
import Toggle from './../../Toggle'

import { saveFeedModuleAction, removeFeedModuleAction } from './actions'
import { confirmModal } from './../../../actions/modal-actions'
import { getAdditionalUIFilters, getMissedQueryFilesAlerts } from './selectors'
import { convertConfigsStrToJSON, convertConfigsJsonToStr, generateIdBaseOnName } from './utils'
import ConfigHelp from './ConfigHelp'
import JsonEditField from './../../JsonEditField/JsonEditField'

const DATA_FEED_SOURCE = {
  redshiftRaku: 'redshiftRaku',
  snowflake: 'snowflake'
}

class FeedModuleForm extends Component {
  constructor(props) {
    super(props)
    const editedFeedModule = convertConfigsStrToJSON(this.props.feedModule)
    this.state = {
      editedFeedModule,
      errors: {},
      ...this.getConfigsToggles(editedFeedModule)
    }

    this.name = this.props.feedModule.name
    this.isFormChanged = false
  }

  getConfigsToggles(editedFeedModule) {
    return {
      showFirehoseConfig: !!editedFeedModule.firehoseConfig,
      showMonthlyConfig: !!editedFeedModule.monthlyConfig,
      showWeeklyConfig: !!editedFeedModule.weeklyConfig,
      showDailyConfig: !!editedFeedModule.dailyConfig
    }
  }

  validate() {
    const errors = {}

    const { editedFeedModule } = this.state
    const { feedModules } = this.props

    // ID VALIDATION
    if (!editedFeedModule.id) {
      errors.id = 'ID can’t be empty.'
    }

    if (editedFeedModule.isNew) {
      const feedModuleWithEqualId = feedModules.filter(m => m.id === editedFeedModule.id)
      if (feedModuleWithEqualId.length > 0) {
        errors.id = `ID should be unique. Please change the name.`
      }
    }

    // NAME VALIDATION
    // empty validation
    if (!editedFeedModule.name) {
      errors.name = 'Name can’t be empty.'
    }

    // check uniq name
    let feedModuleWithEqualName = []
    if (editedFeedModule.isNew) {
      feedModuleWithEqualName = feedModules.filter(m => m.name === editedFeedModule.name)
    } else {
      feedModuleWithEqualName = feedModules.filter(
        m => m.name === editedFeedModule.name && m.id !== editedFeedModule.id
      )
    }
    if (feedModuleWithEqualName.length > 0) {
      errors.name = `Name should be unique. Please change the name.`
    }

    // CONFIGS VALIDATION
    const validateJSONConfig = configNameField => {
      const config = editedFeedModule[configNameField]
      if (!config) return

      if (!config.queryFile) {
        errors[configNameField] = 'Config should have "queryFile" field. Please add it.'
      }

      if (config.queryFile && typeof config.queryFile !== 'string') {
        errors[configNameField] = 'Property "queryFile" should be a string.'
      }

      if (config.extractTypeId && typeof config.extractTypeId !== 'number') {
        errors[configNameField] = 'Property "extractTypeId" should be a number.'
      }
    }

    validateJSONConfig('firehoseConfig')
    validateJSONConfig('monthlyConfig')
    validateJSONConfig('weeklyConfig')
    validateJSONConfig('dailyConfig')

    this.setState({ errors })
    return errors
  }

  validateDebounce = debounce(this.validate, DEBOUNCE_ONCHANGE_UI_ACTION_TIMEOUT)

  handleFieldChange = typeEvent => {
    let changedFields = getElementValue(typeEvent.target)
    changedFields = this.updateIdIfNeed(changedFields)
    this.setField(changedFields)
  }

  setField = changedFields => {
    this.isFormChanged = true
    this.setState(
      {
        editedFeedModule: {
          ...this.state.editedFeedModule,
          ...changedFields
        }
      },
      this.validateDebounce
    )
  }

  updateIdIfNeed(changedFields) {
    const { editedFeedModule } = this.state
    const name = changedFields.name
    if (editedFeedModule.isNew && name !== undefined) {
      const idField = {
        id: generateIdBaseOnName(name)
      }

      return {
        ...changedFields,
        ...idField
      }
    }

    return changedFields
  }

  onCancelHandler = () => {
    this.isFormChanged = false
    const editedFeedModule = convertConfigsStrToJSON(this.props.feedModule)
    this.setState(
      {
        editedFeedModule,
        ...this.getConfigsToggles(editedFeedModule)
      },
      this.validate
    )
  }

  onRemoveHandler = () => {
    const { editedFeedModule } = this.state
    const { id } = editedFeedModule
    this.removeModal(id)
  }

  removeModal = id => {
    const { confirmModal } = this.props

    confirmModal(
      <div>
        <span>
          {`Are you sure you want to delete Feed Module `}
          <strong>
            <em>{`ID: ${id}`}</em>
          </strong>
          {' ?'}
          <br />
          <i>{'Note: all related SQL queries will be deleted as well!'}</i>
        </span>
      </div>,
      'Confirm Delete',
      { okButton: `Remove` },
      () => this.props.removeFeedModuleAction({ id })
    )
  }

  onOkHandler = () => {
    const { editedFeedModule } = this.state

    const errors = this.validate()
    const isValid = !Object.keys(errors).length
    if (isValid) {
      this.name = editedFeedModule.name
      this.isFormChanged = false
      this.props.saveFeedModuleAction(convertConfigsJsonToStr(editedFeedModule))
    }
  }

  renderAdditionalUIFilterOptions() {
    const options = this.props.additionalUIFilters
      .filter(Boolean)
      .sort()
      .map(filter => {
        return (
          <option key={filter} value={filter}>
            {filter}
          </option>
        )
      })

    options.unshift(<option key={'none'} value={''} />)

    return options
  }

  renderAlertIncorrectQueryFile(queryFile) {
    return (
      <Alert bsStyle="warning">
        <Glyphicon glyph="alert" className="feed-module-query-alert-icon" />
        {`There is no any related SQL query for "queryFile": `}
        <strong>{queryFile}</strong>
      </Alert>
    )
  }

  renderConfigHelpPanel = fieldName => {
    return (
      <div className="text-right">
        <div className="inline-block">
          <Button className="btn-link" onClick={() => this.generateExtractBaseConfig(fieldName)}>
            Generate ExtractID based config
          </Button>
        </div>
        <div className="inline-block">
          <Button className="btn-link" onClick={() => this.generateDirectQueryConfig(fieldName)}>
            Generate direct Query config
          </Button>
        </div>
        <div className="inline-block">
          <ConfigHelp />
        </div>
      </div>
    )
  }

  renderToggleConfig = (configPoperty, showConfigProperty) => {
    return (
      <Toggle
        isActive={this.state[showConfigProperty]}
        key={this.state[showConfigProperty]}
        onChange={value => {
          if (value) {
            this.setState({ [showConfigProperty]: true })
          } else {
            this.setState({ [showConfigProperty]: false })
            this.setField({ [configPoperty]: null })
          }
        }}
      />
    )
  }

  generateExtractBaseConfig = fieldName => {
    this.setField({
      [fieldName]: {
        extractTypeId: 1,
        tableName: '[TABLE_NAME]',
        queryFile: '[SQL_NAME]',
        legacyPrefix: '[PREFIX]',
        feedPrefix: '[PREFIX]'
      }
    })
  }

  generateDirectQueryConfig = fieldName => {
    this.setField({
      [fieldName]: {
        tableName: '',
        queryFile: '[SQL_NAME]',
        // postQuery: '[YOU_POST_QUERY]', // deprecated in IN-1516
        legacyPrefix: '[PREFIX]',
        feedPrefix: '[PREFIX]',
        schedule: {
          frequency: 'weekly',
          hourMark: 13
        }
      }
    })
  }

  render() {
    const { editedFeedModule, errors } = this.state
    const { missedQueryFiles } = this.props

    const isValid = !Object.keys(errors).length
    return (
      <div className="feed-module-form">
        {editedFeedModule && (
          <div>
            <div className="h4 feed-module-help-title">
              <strong>
                {editedFeedModule.isNew ? (
                  <span>{`You create a new Feed Module`}</span>
                ) : (
                  <span>{`You edit Feed Module "${this.name}"`}</span>
                )}
              </strong>
              {!editedFeedModule.isNew && (
                <span className="feed-module-help-title-editor-label">
                  {editedFeedModule.createdAt && (
                    <>
                      <strong>Created at</strong>
                      <span>{moment(editedFeedModule.createdAt).format('lll')}</span>{' '}
                    </>
                  )}
                  {(editedFeedModule.lastUpdatedByUser || editedFeedModule.updatedAt) && (
                    <>
                      <strong>Last edited </strong>
                      {editedFeedModule.lastUpdatedByUser && (
                        <>
                          <strong>by</strong>
                          <span title={editedFeedModule.lastUpdatedByUser.fullname.email}>
                            {editedFeedModule.lastUpdatedByUser.fullname}
                          </span>
                        </>
                      )}
                      {editedFeedModule.updatedAt && (
                        <>
                          <strong>at</strong>
                          <span>{moment(editedFeedModule.updatedAt).format('lll')}</span>
                        </>
                      )}
                    </>
                  )}
                </span>
              )}
            </div>

            <Form className="" horizontal>
              <InputField
                id="id"
                label="Id"
                type="input"
                info="ID of Feed Module. It should be unique. It's auto generated from the Name"
                required
                value={editedFeedModule.id || ''}
                validationMessage={errors.id}
                onChange={this.handleFieldChange}
                disabled
              />

              <InputField
                id="name"
                label="Name"
                type="input"
                info=" This is the user friendly name displayed in the feeds dropdown in the portal and sent in the headers of e-mails when the feed summary is sent out at the end of a batch"
                required
                validationMessage={errors.name}
                value={editedFeedModule.name || ''}
                onChange={this.handleFieldChange}
              />

              <InputField label="Source" info="Data source for this Feed Module" required>
                <FormControl
                  id="source"
                  componentClass="select"
                  placeholder="select"
                  onChange={this.handleFieldChange}
                  value={editedFeedModule.source}
                >
                  {Object.keys(DATA_FEED_SOURCE).map(source => (
                    <option value={source} key={source}>
                      {DATA_FEED_SOURCE[source]}
                    </option>
                  ))}
                </FormControl>
              </InputField>

              <InputField
                id="firehoseConfig"
                label="Firehose"
                info="Fill this configuration if you need Firehose sending ability."
                validationMessage={errors.firehoseConfig}
                rows={7}
                help={this.state.showFirehoseConfig ? this.renderConfigHelpPanel('firehoseConfig') : null}
              >
                <>
                  {this.renderToggleConfig('firehoseConfig', 'showFirehoseConfig')}
                  {this.state.showFirehoseConfig && (
                    <JsonEditField
                      value={editedFeedModule.firehoseConfig}
                      onChange={newValue => this.setField({ firehoseConfig: newValue })}
                      requiredKeys={['queryFile']}
                    />
                  )}
                </>
              </InputField>
              {missedQueryFiles.firehoseConfig && this.renderAlertIncorrectQueryFile(missedQueryFiles.firehoseConfig)}

              <InputField
                id="monthlyConfig"
                label="Monthly Frequency"
                info="Fill this configuration if you need Monthly frequency for this Feed Module."
                validationMessage={errors.monthlyConfig}
                rows={7}
                help={this.state.showMonthlyConfig ? this.renderConfigHelpPanel('monthlyConfig') : null}
              >
                <>
                  {this.renderToggleConfig('monthlyConfig', 'showMonthlyConfig')}
                  {this.state.showMonthlyConfig && (
                    <JsonEditField
                      value={editedFeedModule.monthlyConfig}
                      onChange={newValue => this.setField({ monthlyConfig: newValue })}
                      requiredKeys={['queryFile']}
                    />
                  )}
                </>
              </InputField>
              {missedQueryFiles.monthlyConfig && this.renderAlertIncorrectQueryFile(missedQueryFiles.monthlyConfig)}

              <InputField
                id="weeklyConfig"
                label="Weekly Frequency"
                info="Fill this configuration if you need Weekly frequency for this Feed Module."
                validationMessage={errors.weeklyConfig}
                rows={7}
                help={this.state.showWeeklyConfig ? this.renderConfigHelpPanel('weeklyConfig') : null}
              >
                <>
                  {this.renderToggleConfig('weeklyConfig', 'showWeeklyConfig')}
                  {this.state.showWeeklyConfig && (
                    <JsonEditField
                      value={editedFeedModule.weeklyConfig}
                      onChange={newValue => this.setField({ weeklyConfig: newValue })}
                      requiredKeys={['queryFile']}
                    />
                  )}
                </>
              </InputField>
              {missedQueryFiles.weeklyConfig && this.renderAlertIncorrectQueryFile(missedQueryFiles.weeklyConfig)}

              <InputField
                id="dailyConfig"
                label="Daily Frequency"
                info="Fill this configuration if you need Dayily frequency for this Feed Module."
                validationMessage={errors.dailyConfig}
                rows={7}
                help={this.state.showDailyConfig ? this.renderConfigHelpPanel('dailyConfig') : null}
              >
                <>
                  {this.renderToggleConfig('dailyConfig', 'showDailyConfig')}
                  {this.state.showDailyConfig && (
                    <JsonEditField
                      value={editedFeedModule.dailyConfig}
                      onChange={newValue => this.setField({ dailyConfig: newValue })}
                      requiredKeys={['queryFile']}
                    />
                  )}
                </>
              </InputField>
              {missedQueryFiles.dailyConfig && this.renderAlertIncorrectQueryFile(missedQueryFiles.dailyConfig)}

              <InputField
                label="Additional UI Filter"
                info="Some feeds require merchant/category filters along with min and max order dates."
              >
                <FormControl
                  id="additionalUIFilter"
                  componentClass="select"
                  placeholder="select"
                  onChange={this.handleFieldChange}
                  value={editedFeedModule.additionalUIFilter || ''}
                >
                  {this.renderAdditionalUIFilterOptions()}
                </FormControl>
              </InputField>

              <FormGroup>
                <Col className="text-center" sm={12}>
                  {!editedFeedModule.isNew && (
                    <Button className="btn_custom_secondary" onClick={this.onRemoveHandler}>
                      Remove Module
                    </Button>
                  )}
                  <Button className="btn_custom_secondary" onClick={this.onCancelHandler}>
                    Discard Changes
                  </Button>
                  <Button className="btn_custom" onClick={this.onOkHandler} disabled={!(this.isFormChanged && isValid)}>
                    {editedFeedModule.isNew ? `Create` : `Save`}
                  </Button>
                </Col>
              </FormGroup>
            </Form>
          </div>
        )}
      </div>
    )
  }
}

FeedModuleForm.defaultProps = {
  feedModule: {},
  feedModules: []
}

FeedModuleForm.propTypes = {
  feedModule: PropTypes.object,
  feedModules: PropTypes.arrayOf(PropTypes.object).isRequired,
  missedQueryFiles: PropTypes.object.isRequired,
  additionalUIFilters: PropTypes.arrayOf(PropTypes.string).isRequired,
  saveFeedModuleAction: PropTypes.func.isRequired,
  removeFeedModuleAction: PropTypes.func.isRequired,
  confirmModal: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  feedModules: state.feedModules.data.rows,
  additionalUIFilters: getAdditionalUIFilters(state),
  missedQueryFiles: getMissedQueryFilesAlerts(state)
})

const mapDispatchToProps = {
  saveFeedModuleAction,
  removeFeedModuleAction,
  confirmModal
}

export default connect(mapStateToProps, mapDispatchToProps)(FeedModuleForm)
