import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useForm, Controller } from 'react-hook-form'
import joi from 'joi'
import { joiResolver } from '@hookform/resolvers/joi'
import { Button, Modal, Checkbox } from 'react-bootstrap'
import { closeEditDictionaryEntryModal } from './slices'
import InputField from 'components/FormInputComponents/InputField'
import Loader from 'components/Loader'
import { useEditEntryMutation } from './cmeDictionaryAPI'
import CategorySuggestion from 'components/Suggestion/CategorySuggestion'
import BrandSuggestion from 'components/Suggestion/BrandSuggestion'

const CMEDictionaryEditEntryModal = () => {
  const dispatch = useDispatch()
  const { show, selectedRow } = useSelector(state => state.cmeDictionary.editDictionaryEntryModal)

  const [editEntry, { isError, error, isLoading: isUpdating }] = useEditEntryMutation()

  const onCloseWindow = () => {
    dispatch(closeEditDictionaryEntryModal())
  }

  const validationSchema = joi.object({
    category: joi
      .object({
        category_id: joi
          .number()
          .integer()
          .min(0),
        category_full_path: joi.string().allow(''),
        category_added_by: joi.string().allow(''),
        category_add_time: joi.string().allow('')
      })
      .label('Category'),

    brand: joi
      .object({
        brand_id: joi
          .number()
          .integer()
          .min(0),
        brand_full_path: joi.string().allow(''),
        brand_added_by: joi.string().allow(''),
        brand_add_time: joi.string().allow('')
      })
      .label('Brand'),

    train: joi
      .boolean()
      .default(false)
      .label('Train')
  })

  const closeModal = () => {
    dispatch(closeEditDictionaryEntryModal())
  }

  const {
    control,
    handleSubmit,
    formState: { isDirty, errors, isValid },
    reset
  } = useForm({
    mode: 'all',
    defaultValues: {
      category: {
        category_id: selectedRow.category_id || 0,
        category_full_path: selectedRow.category_full_path || '',
        category_added_by: selectedRow.category_added_by || '',
        category_add_time: selectedRow.category_add_time || ''
      },

      brand: {
        brand_id: selectedRow.brand_id || 0,
        brand_full_path: selectedRow.brand_full_path || '',
        brand_added_by: selectedRow.brand_added_by || '',
        brand_add_time: selectedRow.brand_add_time || ''
      },

      train: Boolean(selectedRow.train)
    },
    resolver: joiResolver(validationSchema)
  })

  const onSubmit = async data => {
    const { category, brand, train } = data
    const category_id_changed = Number(category.category_id) !== Number(selectedRow.category_id)
    const brand_id_changed = Number(brand.brand_id) !== Number(selectedRow.brand_id)
    const result = await editEntry({
      category_id: category.category_id,
      brand_id: brand.brand_id,
      category_add_time: selectedRow.category_add_time,
      category_added_by: selectedRow.category_added_by,
      category_id_changed,
      brand_id_changed,
      brand_add_time: selectedRow.brand_add_time,
      brand_added_by: selectedRow.brand_added_by,
      train,
      description: selectedRow.description,
      merchant_id: selectedRow.merchant_id
    }).unwrap()
    if (result.success) {
      dispatch(closeEditDictionaryEntryModal())
    }
  }

  return (
    <Modal show={show} onHide={onCloseWindow} bsSize="lg">
      <Modal.Header>
        <Modal.Title>Edit Dictionary Entry</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form className="mt-3 d-flex flex-column" onSubmit={handleSubmit(onSubmit)}>
          <InputField
            className="text-start"
            label="Category"
            info="Select category"
            labelSize={3}
            validationMessage={errors.category?.message}
          >
            <Controller
              name="category"
              control={control}
              render={({ field: { onChange, value } }) => (
                <CategorySuggestion
                  selectedId={value.category_id}
                  selectedLabel={value.category_full_path}
                  isLeaf
                  changeCallback={category =>
                    onChange({ category_id: category?.id || 0, category_full_path: category?.fullPath || '' })
                  }
                />
              )}
            />
          </InputField>

          <InputField
            className="text-start"
            label="Brand"
            info="Select brand"
            labelSize={3}
            validationMessage={errors.brand?.message}
          >
            <Controller
              name="brand"
              control={control}
              render={({ field: { onChange, value } }) => (
                <BrandSuggestion
                  selectedId={value.brand_id}
                  selectedLabel={value.brand_full_path}
                  isLeaf
                  changeCallback={brand =>
                    onChange({ brand_id: brand?.id || 0, brand_full_path: brand?.fullPath || '' })
                  }
                />
              )}
            />
          </InputField>

          <InputField
            label="Train"
            info="whether this rule should be used in training?"
            className="text-start"
            labelSize={3}
            fieldSize={1}
            validationMessage={errors.train?.message}
          >
            <Controller
              name="train"
              control={control}
              render={({ field }) => <Checkbox {...field} className="text-center" checked={field.value} />}
            />
          </InputField>

          <div className="mt-4 d-flex justify-content-center">
            {isUpdating ? (
              <Loader height={40} />
            ) : (
              <>
                <Button onClick={closeModal}>Close</Button>

                <Button className="ml-2" onClick={() => reset()} disabled={!isDirty}>
                  Reset
                </Button>

                <Button className="ml-2" type="submit" bsStyle="primary" disabled={!isDirty || (isDirty && !isValid)}>
                  Update
                </Button>
              </>
            )}
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        {isError && (
          <div className="text-danger">
            <h4>{error.data}</h4>
          </div>
        )}
      </Modal.Footer>
    </Modal>
  )
}

export default CMEDictionaryEditEntryModal
