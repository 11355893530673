import commonRtkApi from 'commonRtkApi.js'

const supraOrchestratorAPI = commonRtkApi.injectEndpoints({
  tagTypes: ['supraOrchestrator'],
  endpoints: builder => ({
    // getSupraOrchestratorLogs: builder.query({
    //   query: () => {
    //     const data = {}
    //     return { url: `/supra-orchestration/logs`, method: 'POST', data }
    //   },
    //   providesTags: ['SupraOrchestratorLogs']
    // }),
    getSamplingSessions: builder.query({
      query: () => {
        return { url: `/supra-qc/sampling-sessions`, method: 'GET' }
      },
      providesTags: ['SupraOrchestratorSS']
    }),
    getSupraOrchestratorItem: builder.query({
      query: run_id => {
        return { url: `/supra-orchestrator/recrawling/${run_id}`, method: 'GET' }
      },
      providesTags: ['SupraOrchestratorItem']
    })
  })
})

export const {
  // useGetSupraOrchestratorLogsQuery,
  useGetSamplingSessionsQuery,
  useGetSupraOrchestratorItemQuery
} = supraOrchestratorAPI
