import React from 'react'
import PropTypes from 'prop-types'

import { Button, Form, Col, FormGroup, ControlLabel } from 'react-bootstrap'

import { getElementValue } from 'components/FormInputComponents/InputField'
import Accordion from 'components/Accordion'

const CategoryGuidelineModalLayout = ({
  categoryId,
  categoryFullPath,
  include,
  exclude,
  notes,
  onCancelHandler,
  onOkHandler,
  handleFieldChange,
  readOnly
}) => {
  const handleChange = typeEvent => {
    handleFieldChange(getElementValue(typeEvent.target))
  }

  const accordionItems = [
    {
      id: 'include',
      value: include,
      header: 'Include',
      placeholder: 'Enter includes.'
    },
    {
      id: 'exclude',
      value: exclude,
      header: 'Exclude',
      placeholder: 'Enter excludes.'
    },
    {
      id: 'notes',
      value: notes,
      header: 'Notes',
      placeholder: 'Enter notes.'
    }
  ]

  return (
    <Form horizontal>
      <FormGroup>
        <Col sm={12} className="category-guideline-modal-label">
          <ControlLabel title="Category ID">Category ID: {categoryId}</ControlLabel>
        </Col>
      </FormGroup>
      <FormGroup>
        <Col sm={12} className="category-guideline-modal-label">
          <ControlLabel title="Category Full Path">{categoryFullPath}</ControlLabel>
        </Col>
      </FormGroup>
      <Accordion>
        {accordionItems.map(({ id, header, value, placeholder }) => (
          <Accordion.Item key={id} header={header}>
            {readOnly ? (
              <div className="category-guideline-view-field">{value}</div>
            ) : (
              <textarea
                id={id}
                className="category-guideline-edit-field"
                onChange={handleChange}
                placeholder={placeholder}
                value={value || ''}
              />
            )}
          </Accordion.Item>
        ))}
      </Accordion>

      <div className="modal-body-footer">
        {readOnly ? (
          <Button onClick={onCancelHandler}>Close</Button>
        ) : (
          <>
            <Button onClick={onCancelHandler}>Cancel</Button>
            <Button className="btn_custom" onClick={onOkHandler}>
              Save
            </Button>
          </>
        )}
      </div>
    </Form>
  )
}

CategoryGuidelineModalLayout.defaultProps = {
  readOnly: false
}

CategoryGuidelineModalLayout.propTypes = {
  categoryId: PropTypes.number.isRequired,
  categoryFullPath: PropTypes.string.isRequired,
  include: PropTypes.string,
  exclude: PropTypes.string,
  notes: PropTypes.string,

  readOnly: PropTypes.bool,

  handleFieldChange: PropTypes.func.isRequired,

  onCancelHandler: PropTypes.func.isRequired,
  onOkHandler: PropTypes.func.isRequired
}

export default CategoryGuidelineModalLayout
