import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { PropsRoute } from '../../../utils/route-util'
import DeliveryConfigPage from './ConfigPage'
import CurrentDeliveryPage from './CurrentDeliveryPage'
import PastDeliveriesPage from './PastDeliveriesPage'

import { initializeNew, initializeExisting } from '../../../actions/delivery-config-actions'
import { fetchCurrentDelivery } from '../../../actions/delivery-actions'
import { isEmpty } from 'lodash'

class DeliveryPages extends Component {
  componentDidUpdate(prevProps) {
    const { deliverableId } = this.props
    const { deliverableId: prevDeliverableId } = prevProps
    // data/form initializations
    if (
      prevDeliverableId !== deliverableId && // deliverableId has upadted in URL
      prevDeliverableId !== 'new' // previous deliverableId was not new (i.e we haven't just saved a new deliverable config)
    ) {
      if (deliverableId === 'new') {
        this.initStateForNew()
      } else {
        this.initStateForExisting(deliverableId)
      }
    }
  }

  /**
   * Initialize view for new deliverable
   */
  initStateForNew = () => {
    const { selectedClient, selectedContract, initializeNew } = this.props
    initializeNew({
      preSelectedClient: isEmpty(selectedClient) ? undefined : selectedClient,
      preSelectedContract: isEmpty(selectedContract) ? undefined : selectedContract
    })
  }

  /**
   * Initialize view for existing deliverable
   */
  initStateForExisting = deliverableId => {
    const { initializeExisting, fetchCurrentDelivery } = this.props
    initializeExisting({
      id: deliverableId
    })
    fetchCurrentDelivery({ deliverableId })
  }

  render() {
    const { deliverableConfigured, deliverableId } = this.props
    return (
      <React.Fragment>
        <PropsRoute
          path="/admin/dm/delivery-config"
          component={DeliveryConfigPage}
          deliverableId={deliverableId}
          deliverableConfigured={deliverableConfigured}
        />
        <PropsRoute
          path="/admin/dm/delivery-current"
          component={CurrentDeliveryPage}
          deliverableId={deliverableId}
          deliverableConfigured={deliverableConfigured}
        />
        <PropsRoute
          path="/admin/dm/delivery-past"
          component={PastDeliveriesPage}
          deliverableId={deliverableId}
          deliverableConfigured={deliverableConfigured}
        />
      </React.Fragment>
    )
  }
}

DeliveryPages.propTypes = {
  deliverableId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  selectedClient: PropTypes.object,
  selectedContract: PropTypes.object,
  selectedDeliverable: PropTypes.object,
  /**
   * null: Status of certain steps unknown
   * false: There is atleast 1 pending step
   * true: All steps are completed
   */
  deliverableConfigured: PropTypes.bool,
  initializeNew: PropTypes.func,
  initializeExisting: PropTypes.func,
  fetchCurrentDelivery: PropTypes.func
}

function mapStateToProps() {
  return {}
}
export default connect(mapStateToProps, {
  initializeNew,
  initializeExisting,
  fetchCurrentDelivery
})(DeliveryPages)
