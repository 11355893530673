import { useEffect, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { createNextState } from '@reduxjs/toolkit'
import { RuleModalActions } from '../../actions'
import { emptyBrandObj } from './CategoryBrandRuleModal'

/** This hook help update CBR request in state without mutation
 */
export default function useFormCategoryBrandFilds(updateValue) {
  const dispatch = useDispatch()
  // clear modal
  useEffect(() => {
    return function cleanup() {
      dispatch(RuleModalActions.clear())
    }
  }, [dispatch])

  const handleCategoryChange = useCallback(
    category => {
      const categoryId = category?.id
      const categoryFullPath = category?.fullPath
      updateValue({
        categoryId,
        categoryFullPath,
        // drop brands to default value, they can have the previous values
        brands: [emptyBrandObj]
      })
    },
    [updateValue]
  )

  const handleBrandsChange = useCallback(
    (brand, index) => {
      updateValue(values => {
        return {
          brands: createNextState(values.brands, draftState => {
            draftState[index] = brand
          })
        }
      })
    },
    [updateValue]
  )

  return {
    handleCategoryChange,
    handleBrandsChange
  }
}
