import React from 'react'
import PropTypes from 'prop-types'
import { Autocomplete, TextField, CircularProgress, Box, Typography } from '@mui/material'

import { highlightMatch } from './utils'

/**
 * Autocomplete component using Material UI
 * It's controlled component
 * @see https://mui.com/components/autocomplete/
 */
const AutocompleteMUI = ({
  value = '',
  suggestions = [],
  isOpen = false,
  isLoading = false,
  onInputChange = () => {},
  onChange = () => {},
  onCancel = () => {},
  placeholder = ''
}) => {
  return (
    <Autocomplete
      sx={{
        width: '100%',
        '& .MuiAutocomplete-popupIndicator': {
          display: 'none'
        },
        '& .MuiInputBase-root.MuiInputBase-sizeSmall': {
          pl: '0px',
          paddingRight: '20px !important'
        }
      }}
      size="small"
      slotProps={{
        popper: {
          placement: 'bottom-start',
          style: { width: 'fit-content', maxWidth: '400px' },
          sx: {
            boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.2)'
          }
        }
      }}
      open={isOpen}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      getOptionLabel={option => {
        return option.value
      }}
      options={suggestions}
      loading={isLoading}
      value={{ value }}
      onInputChange={(event, newInputValue, reason) => {
        if (reason === 'input') {
          // call the API to get suggestions only for user types
          onInputChange(newInputValue)
        } else if (reason === 'clear') {
          onCancel(event)
        }
      }}
      onChange={(_event, newValue, reason) => {
        if (reason === 'selectOption') {
          onChange(newValue)
        }
      }}
      renderInput={params => (
        <TextField
          autoFocus
          placeholder={placeholder}
          {...params}
          slotProps={{
            input: {
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {isLoading ? (
                    <Box
                      sx={{
                        position: 'absolute',
                        top: '8px',
                        right: '25px'
                      }}
                    >
                      <CircularProgress color="inherit" size={18} />
                    </Box>
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              )
            }
          }}
        />
      )}
      renderOption={(props, option, { inputValue }) => {
        const { beforeMatch, match, afterMatch } = highlightMatch(option.value, inputValue)

        return (
          <li {...props}>
            <Typography variant="body1" sx={{ fontSize: 14 }} component="span">
              {beforeMatch}
              <span style={{ fontWeight: 'bold', color: '#000' }}>{match}</span>
              {afterMatch}
            </Typography>
          </li>
        )
      }}
    />
  )
}

AutocompleteMUI.propTypes = {
  /**
   * Value of the input
   */
  value: PropTypes.string,

  /**
   * List of suggestions
   */
  suggestions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      value: PropTypes.string
    })
  ),

  /**
   * Whether the dropdown is open
   */
  isOpen: PropTypes.bool,

  /**
   * Whether the dropdown is loading
   */
  isLoading: PropTypes.bool,

  /**
   * Callback when the input changes
   */
  onInputChange: PropTypes.func,

  /**
   * Callback when a suggestion is selected
   */
  onChange: PropTypes.func,

  /**
   * Callback when the input is cleared
   */
  onCancel: PropTypes.func,

  /**
   * Placeholder for the input
   */
  placeholder: PropTypes.string
}

export default AutocompleteMUI
