import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/DeleteOutlined'
import { DataGridPremium, GridActionsCellItem } from '@mui/x-data-grid-premium'
import { GridToolbar } from '@mui/x-data-grid-pro'
import { LinearProgress } from '@mui/material'

import { useGetEntriesQuery, useEditEntryMutation } from './cmeDictionaryAPI'
import { openEditDictionaryEntryModal } from './slices'
import CMEDictionaryEditEntryModal from './CMEDictionaryEditEntryModal'
import getCMEDictionaryColumns from './Table/columns'
import { sortModes, TABLE_MUI_ROWS_CURRENT, TABLE_MUI_ROWS_OPTION } from '../../constants/constants'

import './cmeDictionary.scss'
import WrapperWithLoader from 'mui/components/WrapperWithLoader/WrapperWithLoader'
import useMUITableState from 'mui/hooks/useMUITableState'

const CMEDictionary = () => {
  const dispatch = useDispatch()

  const { show: showEditModal } = useSelector(state => state.cmeDictionary.editDictionaryEntryModal)

  const {
    paginationModel,
    setPaginationModel,
    sortModel,
    setSortModel,
    filterModel,
    setFilterModel
  } = useMUITableState()

  const { isLoading, isFetching, data: pageInfo, isError, error, refetch } = useGetEntriesQuery({
    paginationModel,
    sortModel,
    filterModel
  })

  const [editEntry, { isLoading: isUpdating }] = useEditEntryMutation()

  const rows = pageInfo?.rows

  const handleEditClick = id => () => {
    const selectedRow = rows.find(row => row.id === id)
    dispatch(openEditDictionaryEntryModal({ selectedRow: { ...selectedRow } }))
  }

  const handleDeleteClick = row => () => {
    const {
      category_id,
      brand_id,
      category_added_by,
      category_add_time,
      brand_added_by,
      brand_add_time,
      upc,
      train,
      merchant_id,
      description
    } = row
    editEntry({
      category_id,
      brand_id,
      upc,
      train,
      description,
      merchant_id,
      category_added_by,
      category_add_time,
      brand_added_by,
      brand_add_time,
      is_deleted: true
    })
    refetch()
  }

  const getActions = ({ id, row }) => {
    return [
      <GridActionsCellItem
        key="edit-icon"
        icon={<EditIcon />}
        label="Edit"
        className="textPrimary"
        onClick={handleEditClick(id)}
        color="inherit"
      />,
      <GridActionsCellItem
        key="delete-icon"
        icon={<DeleteIcon />}
        label="Delete"
        onClick={handleDeleteClick(row)}
        color="inherit"
      />
    ]
  }

  const columns = getCMEDictionaryColumns({ getActions })

  return (
    <WrapperWithLoader isLoading={isLoading}>
      <>
        {showEditModal && <CMEDictionaryEditEntryModal />}
        {!isError ? (
          <DataGridPremium
            loading={isLoading || isFetching || isUpdating}
            paginationMode="server"
            sortingMode="server"
            filterMode="server"
            initialState={{
              pagination: {
                paginationModel: { pageSize: TABLE_MUI_ROWS_CURRENT, page: 0 }
              },
              sorting: {
                sortModel: [{ field: 'rating', sort: sortModes.DESCENDING }]
              }
            }}
            editMode="row"
            onSortModelChange={setSortModel}
            keepNonExistentRowsSelected
            rows={rows}
            slots={{
              toolbar: GridToolbar,
              loadingOverlay: LinearProgress
            }}
            pageSizeOptions={[paginationModel.pageSize, ...TABLE_MUI_ROWS_OPTION]}
            onPaginationModelChange={setPaginationModel}
            pagination
            columns={columns}
            rowCount={pageInfo?.total || 0}
            onFilterModelChange={setFilterModel}
          />
        ) : (
          <div>
            <h1 className="text-center text-danger">{error.data}</h1>
          </div>
        )}
      </>
    </WrapperWithLoader>
  )
}

export default CMEDictionary
