import React from 'react'
import PropTypes from 'prop-types'
import { ControlLabel, FormControl, FormGroup, HelpBlock, Col, InputGroup } from 'react-bootstrap'

import { thresholdValidation } from './validation'

const getProperties = ({ valueLocal, valueGlobal, isUseLocalConfig, isActive }) => {
  const value = isUseLocalConfig ? valueLocal : valueGlobal
  const disabled = !isActive || !isUseLocalConfig

  return { value, disabled }
}

const getValueForSave = (value, isUseLocalConfig) => (isUseLocalConfig ? value : null)

const useThresholdField = ({ isUseLocalConfig, setValueForSave, setError, handleFieldChange }) => {
  const register = ({ id, value, valueGlobal, isActive, validation }) => {
    setValueForSave({ [id]: getValueForSave(value, isUseLocalConfig) })

    const actualValidation = validation || thresholdValidation

    const error = actualValidation(value)
    const onChange = e => {
      const value = handleFieldChange(e)[id]

      const error = actualValidation(value)
      setError({ [id]: error })
    }

    const props = getProperties({ valueLocal: value, valueGlobal, isUseLocalConfig, isActive })

    return {
      onChange,
      id,
      error,
      ...props
    }
  }

  return [register]
}

const ThresholdField = ({ id, disabled, value, onChange, error = null, label = 'Threshold', addon = '%' }) => {
  const validationState = error === null ? null : 'error'

  return (
    <FormGroup validationState={validationState}>
      <Col componentClass={ControlLabel} sm={2}>
        {label}:
      </Col>
      <Col sm={4} style={{ width: '37%' }}>
        <InputGroup>
          <FormControl id={id} type="number" value={value} disabled={disabled} onChange={onChange} />
          {addon && <InputGroup.Addon>{addon}</InputGroup.Addon>}
        </InputGroup>
      </Col>
      {error && <HelpBlock className="category-tests-modal-form-error">{error}</HelpBlock>}
    </FormGroup>
  )
}

ThresholdField.propTypes = {
  label: PropTypes.string,
  addon: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  disabled: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.string
}

export default ThresholdField
export { useThresholdField }
