import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Button, Form, Col, FormGroup, Radio, FormControl } from 'react-bootstrap'

import MerchantSuggestion from './../Suggestion/MerchantSuggestion'
import InputField, { getElementValue } from './../FormInputComponents/InputField'
import cmeInspectorActions from './cmeInspectorActions'

import './cmeInspector.scss'

const isStaging = process.env.REACT_APP_DEPLOY_ENV.toLowerCase() === 'staging'
const MAX_DESCRIPTIONS = 200

class CMEInspectorForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      search: {
        descriptions: null,
        parsedCategories: null,
        merchantId: null,
        merchantName: '',
        env: isStaging ? 'staging' : 'production'
      },
      errors: {}
    }
    this.isFormChanged = false
  }

  validate() {
    const errors = {}
    const { search } = this.state

    if (search.descriptions?.length <= 0) {
      errors.descriptions = 'Description can’t be empty.'
    }

    if (search.descriptions?.length > MAX_DESCRIPTIONS) {
      errors.descriptions = `You can enter maximum ${MAX_DESCRIPTIONS} lines`
    }

    this.setState({ errors })
    return errors
  }

  setField = changedFields => {
    this.isFormChanged = true
    this.setState(
      {
        search: {
          ...this.state.search,
          ...changedFields
        }
      },
      this.validate
    )
  }

  handleFieldChange = typeEvent => {
    const changedFields = getElementValue(typeEvent.target)
    this.setField(changedFields)
  }

  handleDescriptionsChange = typeEvent => {
    const changedFields = getElementValue(typeEvent.target)
    this.setField({ descriptions: this.convertToArray(changedFields.descriptions) })
  }

  handleMerchantChange = merchant => {
    const merchantId = merchant ? merchant.id : undefined
    const merchantName = merchant ? merchant.merchantName : undefined
    this.setField({ merchantId, merchantName })
  }

  handleMerchantIdChange = typeEvent => {
    const changedFields = getElementValue(typeEvent.target)
    const merchantIdStr = changedFields.merchantId
    if (merchantIdStr) {
      let merchantId = +merchantIdStr
      if (isNaN(merchantId)) {
        merchantId = null
      }
      changedFields.merchantId = merchantId
    }
    this.setField({ ...changedFields, merchantName: `[Merchant ID:${merchantIdStr}]` })
  }

  convertToArray(str) {
    const NEW_LINE_EXPRESSION = /\r\n|\n\r|\n|\r/g
    return str.split(NEW_LINE_EXPRESSION)
  }

  handleInspectButton = () => {
    const { descriptions, parsedCategories, merchantId, env } = this.state.search
    const parsedCategoriesAsArray = parsedCategories ? this.convertToArray(parsedCategories) : []
    if (parsedCategoriesAsArray.length > MAX_DESCRIPTIONS) {
      parsedCategoriesAsArray.length = MAX_DESCRIPTIONS
    }

    this.props.fetchCMEInspect({
      descriptions,
      parsedCategories: parsedCategoriesAsArray,
      merchantId,
      env
    })
  }

  render() {
    const { search, errors } = this.state

    const isValid = !Object.keys(errors).length && search.descriptions

    return (
      <Form horizontal>
        <div>
          <InputField
            id="descriptions"
            className="cme-inspector-inline-form-field"
            label="Description"
            type="textarea"
            componentClass="textarea"
            rows={5}
            info="Product descriptions. Multiple descriptions should be divided by new line. Max value is 200 lines."
            validationMessage={errors.descriptions}
            help={<span>Descriptions count: {search.descriptions?.length || 0}</span>}
            required
            value={search.descriptions?.join('\n') || ''}
            onChange={this.handleDescriptionsChange}
          />
          <InputField
            id="parsedCategories"
            className="cme-inspector-inline-form-field"
            label="Parsed Category"
            type="textarea"
            componentClass="textarea"
            rows={5}
            info="Parsed Categories. Multiple categories should be divided by new line. Description and Parsed Category are corresponded one-to-one by each newline."
            value={search.parsedCategories || ''}
            onChange={this.handleFieldChange}
          />
        </div>

        <div>
          <InputField
            className="cme-inspector-inline-form-field"
            validationState={search.merchantId === undefined ? 'error' : null}
            info="Merchant from which descriptions originate. You can search by name or Merchant ID."
            label={'Merchant'}
          >
            <>
              <MerchantSuggestion
                key={search.merchantId}
                selectedId={search.merchantId}
                selectedLabel={search.merchantName}
                changeCallback={this.handleMerchantChange}
                additionalSearchParams={{ isClientQualified: true }}
              />

              <div className="cmi-inspector-merchant-label" />
              <FormControl
                type="input"
                id="merchantId"
                placeholder="Merchant ID"
                value={search.merchantId || ''}
                onChange={this.handleMerchantIdChange}
              />
            </>
          </InputField>

          <InputField
            id="env"
            className="cme-inspector-inline-form-field"
            label="Environment"
            type="radio"
            value={search.env || 'production'}
            onChange={this.handleFieldChange}
            info="Production is the real-time system currently used to taxonomize items.
Staging is the development system used to test new models that may taxonomize items."
          >
            <>
              <Radio
                data-id="env"
                value="production"
                name="radioGroupEnv"
                bsClass="cme-inspector-radio"
                checked={search.env === 'production'}
                onChange={this.handleFieldChange}
                disabled={isStaging}
              >
                Production Model
              </Radio>
              <Radio
                data-id="env"
                name="radioGroupEnv"
                value="staging"
                bsClass="cme-inspector-radio"
                checked={search.env === 'staging'}
                onChange={this.handleFieldChange}
                disabled={isStaging}
              >
                Staging Model
              </Radio>
            </>
          </InputField>
        </div>

        <FormGroup bsClass="cme-inspector-group-buttons">
          <Col className="text-center" sm={12}>
            <Button
              className="btn_custom"
              onClick={this.handleInspectButton}
              disabled={!(this.isFormChanged && isValid)}
            >
              Inspect
            </Button>
          </Col>
        </FormGroup>
      </Form>
    )
  }
}

CMEInspectorForm.propTypes = {
  fetchCMEInspect: PropTypes.func.isRequired
}

const mapDispatchToProps = {
  fetchCMEInspect: cmeInspectorActions.fetch
}

export default connect(null, mapDispatchToProps)(CMEInspectorForm)
