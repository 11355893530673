import React, { Component } from 'react'
import PropTypes from 'prop-types'
import OverTimeToolbar from './OverTimeToolbar'
import OverTimeHighChart from './OverTimeHighChart'
import { connect } from 'react-redux'
import { isEmpty } from 'lodash'
import Loader from '../../Loader'
import { onTimeAggregationChanged, onTimeDimensionChanged, onTableSort } from '../../../actions/widget-actions'

class OverTimeWidget extends Component {
  static propTypes = {
    data: PropTypes.object,
    title: PropTypes.string,
    selectedInterval: PropTypes.string,
    selectedFilter: PropTypes.string,
    onTimeAggregationChanged: PropTypes.func,
    onTimeDimensionChanged: PropTypes.func,
    aggregation: PropTypes.arrayOf(PropTypes.object),
    chartTitle: PropTypes.string,
    selectedField: PropTypes.string,
    selectedView: PropTypes.string,
    type: PropTypes.string,
    aggType: PropTypes.string,
    loading: PropTypes.bool,
    height: PropTypes.number,
    width: PropTypes.number,
    from: PropTypes.string,
    to: PropTypes.string,
    rangeStart: PropTypes.string,
    rangeEnd: PropTypes.string,
    handleDateSelection: PropTypes.func
  }

  render() {
    return (
      <div className="qc-tool-chart-tab-widget">
        <OverTimeToolbar
          selectedField={this.props.selectedField}
          selectedFilter={this.props.selectedFilter}
          selectedInterval={this.props.selectedInterval}
          onTimeDimensionChanged={this.props.onTimeDimensionChanged}
          onTimeAggregationChanged={this.props.onTimeAggregationChanged}
          aggType={this.props.aggType}
          aggregation={this.props.aggregation}
          rangeStart={this.props.rangeStart}
          rangeEnd={this.props.rangeEnd}
          from={this.props.from}
          to={this.props.to}
          handleDateSelection={this.props.handleDateSelection}
        />
        {this.props.loading && <Loader overlap />}
        {isEmpty(this.props.data) ? (
          <div className="no-data-msg">
            No data found. Hit <strong>Apply</strong> button if not clicked
          </div>
        ) : (
          <OverTimeHighChart
            data={this.props.data}
            title={this.props.title}
            selectedField={this.props.selectedField}
            selectedFilter={this.props.selectedFilter}
            aggregation={this.props.aggregation}
            height={this.props.height ? this.props.height - 30 : undefined}
            width={this.props.width ? this.props.width - 20 : undefined}
          />
        )}
      </div>
    )
  }
}

function mapStateToProps(state, props) {
  return {
    data: state.searchEdit[props.aggType] ? state.searchEdit[props.aggType].data : {},
    loading: state.searchEdit[props.aggType].loading,
    title: ``,
    selectedField: state.searchEdit[props.aggType].selectedField,
    selectedInterval: state.searchEdit[props.aggType].selectedInterval,
    selectedFilter: state.searchEdit[props.aggType].selectedFilter,
    aggregation: state.searchEdit[props.aggType].aggregation
  }
}

export default connect(mapStateToProps, {
  onTimeAggregationChanged,
  onTimeDimensionChanged,
  onTableSort
})(OverTimeWidget)
