import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NIQ_SEARCH_EDIT_STORE_PATH } from '../../../../../reducers/niq-reducers'
import { stageDictEdit, descriptionSuggestClean } from '../../../../../actions/niq-widget-actions'

/**
 * Hook to get and set staged values for a dictionary editable fields(attributes)
 *
 * @param {DESCRIPTION_EDITABLE_ATTRIBUTE} attribute - attribute to edits
 * @param {string} dictionaryKey - The dictionary key to edit
 *
 * @returns {Object} - The returned object from the hook.
 * @returns {Array} returns.suggestions - Array of suggestions related to the attribute
 * @returns {Object} returns.stageValues - The current staged values for the given dictionary key
 * @returns {boolean} returns.isLoading - Flag to indicate if the suggestions are loading
 * @returns {function(string, string, string): void} returns.updateStageValue - Function to update the staged value
 */
export const useEditValue = (attribute, dictionaryKey) => {
  const dispatch = useDispatch()

  useEffect(() => {
    return () => {
      // cleanup suggestions on unmount
      dispatch(descriptionSuggestClean())
    }
  }, [dispatch])

  const suggestions = useSelector(
    state => state[NIQ_SEARCH_EDIT_STORE_PATH].description.edits[`${attribute}Suggestions`]
  )

  const isLoading = useSelector(state => state[NIQ_SEARCH_EDIT_STORE_PATH].description.edits.loading)

  const stagedEdits = useSelector(state => state[NIQ_SEARCH_EDIT_STORE_PATH].description.stagedEdits)

  /**
   * Update the staged value
   * @param {DESCRIPTION_EDITABLE_ATTRIBUTE} attribute - The attribute to update
   * @param {string} id - The id of the value
   * @param {string} value - The value to update
   */
  const updateStageValue = useCallback(
    (attribute, id, value) => {
      dispatch(stageDictEdit(dictionaryKey, attribute, id, value))
    },
    [dispatch, dictionaryKey]
  )

  const stageValues = stagedEdits[dictionaryKey]

  return {
    suggestions,
    stageValues,
    isLoading,
    updateStageValue
  }
}
