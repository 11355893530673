import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Glyphicon, Alert } from 'react-bootstrap'
import { isEmpty } from 'lodash'

import Loader from 'components/Loader'
import EditReviewTable from './EditReviewTable'
import { buildTableData } from './utils'
import useCheckCBR from './useCheckCBR'

/**
 * Build the modal for reviewing edits
 * @param {Array} allRows - all rows
 * @param {Object} stagedEdits - the edits that have been staged
 */
const EditReviewModal = ({ allRows, stagedEdits }) => {
  const { tableData, noOfChanges } = useMemo(() => buildTableData(allRows, stagedEdits), [allRows, stagedEdits])

  const { incorrectPairs, isLoading, error } = useCheckCBR(tableData)

  return (
    <>
      {isLoading && <Loader overlap />}

      <EditReviewTable tableData={tableData} noOfChanges={noOfChanges} incorrectPairs={incorrectPairs} />

      {!isLoading && !error && isEmpty(incorrectPairs) && (
        <Alert bsStyle="success" className="mt-2">
          <Glyphicon className="padding-right-standard mr-2" glyph="ok" />
          All pairs Brand-Category are correct.
        </Alert>
      )}

      {!isLoading && !error && !isEmpty(incorrectPairs) && (
        <Alert bsStyle="warning" className="mt-2">
          <Glyphicon className="padding-right-standard mr-2" glyph="alert" />
          {Object.keys(incorrectPairs).length} incorrect pairs Brand-Category found. Please fix them before saving.
        </Alert>
      )}

      {error && !isLoading && (
        <Alert bsStyle="danger" className="mt-2">
          <Glyphicon className="padding-rigt-standard mr-2" glyph="alert" />
          Check CBR API Error: {error?.data}
        </Alert>
      )}
    </>
  )
}

EditReviewModal.propTypes = {
  allRows: PropTypes.arrayOf(PropTypes.object).isRequired,
  stagedEdits: PropTypes.object.isRequired
}

export default EditReviewModal
