import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { DropdownButton, MenuItem } from 'react-bootstrap'
import { find } from 'lodash'

const fields = [
  {
    key: 'count',
    value: 'Count'
  },
  {
    key: 'revenue',
    value: 'Revenue'
  }
]

class TableChartToolbar extends Component {
  static propTypes = {
    selectedField: PropTypes.string,
    onFieldChanged: PropTypes.func,
    onDimensionChanged: PropTypes.func,
    aggType: PropTypes.string
  }

  onFieldChanged(value) {
    this.props.onDimensionChanged(value, this.props.aggType)
  }

  render() {
    const renderDropdowns = () => {
      return fields.map(opt => {
        return (
          <MenuItem bsSize="xsmall" key={opt.key} eventKey={opt.key} active={opt.key === this.props.selectedField}>
            {opt.value}
          </MenuItem>
        )
      })
    }

    return (
      <div className="pull-right table-chart-toolbar">
        <DropdownButton
          pullRight
          title={find(fields, 'key', this.props.selectedField).value}
          id={`chart-view-${this.props.aggType}`}
          bsSize="xsmall"
          onSelect={this.onFieldChanged.bind(this)}
        >
          {renderDropdowns()}
        </DropdownButton>
      </div>
    )
  }
}

export default TableChartToolbar
