import { createSelector } from 'reselect'

const l1CategoriesSelector = state => (state.l1Categories && state.l1Categories.data) || []

export const getL1CategoriesId = createSelector(l1CategoriesSelector, categories => {
  return categories.reduce((ids, category) => {
    ids.push(category.id)
    return ids
  }, [])
})

export const getL1CategoriesIndexedById = createSelector(l1CategoriesSelector, categories => {
  return categories.reduce((categoriesIndexedById, category) => {
    categoriesIndexedById[category.id] = category
    return categoriesIndexedById
  }, {})
})
