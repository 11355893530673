import commonRtkApi from 'commonRtkApi.js'

const NIQRulesAPI = commonRtkApi.injectEndpoints({
  endpoints: builder => ({
    checkCBR: builder.mutation({
      query: categoryBrandsPairs => {
        const data = { categoryBrandsPairs }
        return { url: `/niq/taxonomy/check/cbr`, method: 'POST', data }
      }
    })
  })
})

export const { useCheckCBRMutation } = NIQRulesAPI
