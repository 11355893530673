import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import moment from 'moment'

import deliveryJobsActions from './deliveryJobsActions'
import { Glyphicon, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { confirmModal, addCustomModal } from 'actions/modal-actions'
import DeliveryJobEditModal from './Modals/DeliveryJobEditModal'
import { PORTAL_SYSTEM_USER_ID } from 'constants/constants'
import { DELIVERY_JOB_STATUS } from 'constants/deliveryJobsConstants'

class DeployJobActionsCell extends Component {
  deleteFunction = id => {
    this.props.saveDeliveryJobAction({ id, isActive: false })
  }

  activateFunction = id => {
    this.props.saveDeliveryJobAction({ id, isActive: true })
  }

  editModal = () => {
    const { addCustomModal, row } = this.props
    addCustomModal({
      modalType: 'successModal',
      id: 'create-new-delivery-job-modal',
      title: 'Edit Delivery Job',
      size: 'large',
      content: <DeliveryJobEditModal analystNotes={row.analystNotes || ''} deliveryJobId={row.id} />
    })
  }

  deleteModal = id => {
    const { confirmModal } = this.props

    confirmModal(
      <div>
        <span>
          {`Are you sure you want to delete Delivery Job `}
          <strong>
            <em>{`ID: ${id}`}</em>
          </strong>
          ?
        </span>
      </div>,
      'Confirm Delete',
      { okButton: `Remove` },
      () => this.deleteFunction(id)
    )
  }

  activateModal = id => {
    const { confirmModal } = this.props

    confirmModal(
      <div>
        <span>
          {`Are you sure you want to re-activate Delivery Job `}
          <strong>
            <em>{`ID: ${id}`}</em>
          </strong>
          ?
        </span>
      </div>,
      'Confirm Re-Activate',
      { okButton: `Re-Activate job` },
      () => this.activateFunction(id)
    )
  }

  isLessThanCurrentMonth(date) {
    const startOfMonth = moment().startOf('month')
    return moment(date) < startOfMonth
  }

  getActivationButton() {
    const { id, isActive, status, createdBy, deliveryDate } = this.props.row

    let activationGlyph = ''
    let activationTooltip = ''
    let activationMethod = () => {}

    if (isActive) {
      const statusesForDelete = [
        DELIVERY_JOB_STATUS.UNSCHEDULED,
        DELIVERY_JOB_STATUS.SCHEDULED,
        DELIVERY_JOB_STATUS.PROCESSING
      ]
      const isCreatedByPortalSystem = createdBy === PORTAL_SYSTEM_USER_ID
      if (
        statusesForDelete.includes(status) &&
        (!isCreatedByPortalSystem || this.isLessThanCurrentMonth(deliveryDate))
      ) {
        // show delete
        activationGlyph = 'trash'
        activationTooltip = 'Delete Job'
        activationMethod = this.deleteModal.bind(this, id)
      } else {
        // don't show delete
        return null
      }
    } else {
      // show activate button
      activationGlyph = 'repeat'
      activationTooltip = 'Re-Activate Job'
      activationMethod = this.activateModal.bind(this, id)
    }

    return (
      <OverlayTrigger
        placement="left"
        overlay={<Tooltip id="delivery-job-activation-tooltip">{activationTooltip}</Tooltip>}
      >
        <div onClick={activationMethod}>
          <Glyphicon glyph={activationGlyph} />
        </div>
      </OverlayTrigger>
    )
  }

  pick(id, analystId) {
    this.props.saveDeliveryJobAction({ id, analystId })
  }

  unPick(id, analystId) {
    this.props.saveDeliveryJobAction({ id, analystId: null })
  }

  getPickButton(status) {
    const { id } = this.props.row
    const { currentUserId } = this.props
    let tooltip = null
    let glyph = null
    let pickMethod = () => {}

    if (status === DELIVERY_JOB_STATUS.SCHEDULED) {
      tooltip = 'Pick'
      glyph = 'hand-up'
      pickMethod = this.pick.bind(this, id, currentUserId)
    } else if (status === DELIVERY_JOB_STATUS.PROCESSING) {
      tooltip = 'Unpick'
      glyph = 'hand-down'
      pickMethod = this.unPick.bind(this, id, currentUserId)
    } else {
      return null
    }
    return (
      <OverlayTrigger placement="left" overlay={<Tooltip id="delivery-job-pick-tooltip">{tooltip}</Tooltip>}>
        <div onClick={pickMethod}>
          <Glyphicon glyph={glyph} />
        </div>
      </OverlayTrigger>
    )
  }

  submit(id) {
    this.props.saveDeliveryJobAction({ id, deliveredDate: new Date() })
  }

  unSubmit(id) {
    this.props.saveDeliveryJobAction({ id, deliveredDate: null })
  }

  getSubmitButton(status) {
    const { id } = this.props.row
    let tooltip = null
    let glyph = null
    let method = () => {}

    if (status === DELIVERY_JOB_STATUS.PROCESSING) {
      tooltip = 'Submit'
      glyph = 'ok-circle'
      method = this.submit.bind(this, id)
    } else if (status === DELIVERY_JOB_STATUS.READY) {
      tooltip = 'Unsubmit'
      glyph = 'remove-circle'
      method = this.unSubmit.bind(this, id)
    } else {
      return null
    }
    return (
      <OverlayTrigger placement="left" overlay={<Tooltip id="delivery-job-submit-tooltip">{tooltip}</Tooltip>}>
        <div onClick={method}>
          <Glyphicon glyph={glyph} />
        </div>
      </OverlayTrigger>
    )
  }

  render() {
    const { status, isActive } = this.props.row

    return (
      <div className="sb-table-action-cell">
        {isActive &&
          (status === DELIVERY_JOB_STATUS.PROCESSING ||
            status === DELIVERY_JOB_STATUS.READY ||
            status === DELIVERY_JOB_STATUS.PUBLISHED) && (
            <OverlayTrigger placement="left" overlay={<Tooltip id="delivery-job-edit-tooltip">Edit job</Tooltip>}>
              <div onClick={this.editModal} title={`Edit Job`}>
                <Glyphicon glyph="edit" />
              </div>
            </OverlayTrigger>
          )}
        {this.getActivationButton()}
        {isActive && this.getPickButton(status)}
        {isActive && this.getSubmitButton(status)}
      </div>
    )
  }
}

DeployJobActionsCell.propTypes = {
  saveDeliveryJobAction: PropTypes.func.isRequired,
  row: PropTypes.object.isRequired,

  currentUserId: PropTypes.number,

  addCustomModal: PropTypes.func.isRequired,
  confirmModal: PropTypes.func.isRequired
}

function mapStateToProps({ session: { user } = {} }) {
  return {
    currentUserId: user ? user.id : null
  }
}
const mapDispatchToProps = {
  saveDeliveryJobAction: deliveryJobsActions.save,
  addCustomModal,
  confirmModal
}

export default connect(mapStateToProps, mapDispatchToProps)(DeployJobActionsCell)
