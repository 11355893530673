import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import { Table, Button } from 'react-bootstrap'
import Loader from 'components/Loader'
import { clearModals } from 'actions/modal-actions'

import { fetchMerchantFields, MERCHANT_FIELDS_STORE_PATH } from './merchantSlice'

const MerchantFieldsTableModal = ({ id, name }) => {
  const dispatch = useDispatch()

  const { data, loading } = useSelector(state => state[MERCHANT_FIELDS_STORE_PATH])

  useEffect(() => {
    dispatch(fetchMerchantFields({ id }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const closeModal = () => {
    dispatch(clearModals())
  }

  return (
    <>
      <div className="merchant-fields-modal">
        {loading && <Loader overlap />}

        <Table responsive striped hover condensed>
          <thead>
            <tr>
              <th className="text-left">Merchant Name</th>
              <th className="text-center">Field</th>
              <th className="text-center">Orders count</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, i) => (
              <tr key={i}>
                <td className="text-left">{name}</td>
                <td className="text-left text-break">{item.field}</td>
                <td className="text-center">{item.orders}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      <div className="merchant-fields-modal-total">{data.length ? `Total count: ${data.length}` : ''}</div>
      <div className="modal-body-footer">
        <Button onClick={closeModal}>Close</Button>
      </div>
    </>
  )
}

MerchantFieldsTableModal.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired
}

export default MerchantFieldsTableModal
