import React from 'react'
import PropTypes from 'prop-types'

import { Form, FormControl, Glyphicon, Alert } from 'react-bootstrap'
import InputField from './../../../FormInputComponents/InputField'

import BrandSuggestion from './../../../Suggestion/BrandSuggestion'
import MerchantSuggestion from './../../../Suggestion/MerchantSuggestion'

import useFormMerchantBrandRegexFilds from './useFormMerchantBrandRegexFilds'
import useModalUISettings from './../../Common/useModalUISettings'
import useFormButtons from './../../Common/useFormButtons'
import useMode from './../../Common/useMode'

import NoteFields from './../../Common/NoteFields'
import CommonRuleModalButtons from './../../Common/CommonRuleModalButtons'

export default function MerchantBrandRegexRuleModal({ value, isRequest, onOkHandler, onRejectHandler, isReadOnly }) {
  const {
    id,
    merchantId,
    merchantName,
    brandId,
    brandFullPath,
    includeParam,
    excludeParam,
    ruleType,
    isExcludeRule,
    request_notes,
    review_notes
  } = value

  const mode = useMode(isRequest, isReadOnly, id)

  const initValues = {
    id: id ?? null,
    merchantId: merchantId ?? null,
    merchantName: merchantName ?? null,
    brandId: brandId ?? null,
    brandFullPath: brandFullPath ?? null,

    includeParam: includeParam ?? null,
    excludeParam: excludeParam ?? null,
    ruleType: ruleType ?? 'DESCRIPTION',
    isExcludeRule: isExcludeRule ?? false,

    request_notes: request_notes ?? null,
    review_notes: review_notes ?? null
  }

  const {
    values,
    isFormChanged,
    handleFieldChange,
    handleMerchantChange,
    handleBrandChange
  } = useFormMerchantBrandRegexFilds(initValues, mode)

  const isAllFieldsFilled = values.merchantId && values.brandId && values.includeParam && values.ruleType
  const fieldsNames = 'Merchant, Brand and other parameters'
  const isRuleActiveFunc = rule => rule.isActive && rule.brandIsActive && rule.merchantIsActive
  const { ruleInfoMessage, ruleInfoStyle, okButtonName, isOkButtonDisabled, isFieldsDisabled } = useModalUISettings({
    prevRuleId: values.id,
    isAllFieldsFilled,
    isFormChanged,
    fieldsNames,
    isRuleActiveFunc,
    mode
  })

  const sentFieldsForRules = ['merchantId', 'brandId', 'includeParam', 'excludeParam', 'ruleType', 'isExcludeRule']
  const sentFieldsForRequests = ['merchantName', 'brandFullPath', 'request_notes', 'review_notes']
  const { handleOkButton, handleRejectButton, handleCancelButton } = useFormButtons({
    onOkHandler,
    onRejectHandler,
    values,
    sentFieldsForRules,
    sentFieldsForRequests,
    isRequest
  })

  return (
    <Form className="text-left" horizontal>
      <InputField validationState={values.merchantId === undefined ? 'error' : null} required label={'Merchant'}>
        <MerchantSuggestion
          selectedId={values.merchantId}
          selectedLabel={values.merchantName}
          changeCallback={handleMerchantChange}
          readOnly={mode.isRequestApprove || mode.isRequestView}
        />
      </InputField>

      <InputField validationState={values.brandId === undefined ? 'error' : null} required label={'Brand'}>
        <BrandSuggestion
          selectedId={values.brandId}
          selectedLabel={values.brandFullPath}
          changeCallback={handleBrandChange}
          readOnly={mode.isRequestApprove || mode.isRequestView}
        />
      </InputField>

      <InputField
        id="includeParam"
        label="Include param"
        type="input"
        value={values.includeParam || ''}
        onChange={handleFieldChange}
        validationState={values.includeParam === '' && values.excludeParam === '' ? 'error' : null}
        disabled={mode.isRequestApprove || mode.isRequestView}
        required
      />
      <InputField
        id="excludeParam"
        label="Exclude param"
        type="input"
        value={values.excludeParam || ''}
        validationState={values.includeParam === '' && values.excludeParam === '' ? 'error' : null}
        onChange={handleFieldChange}
        disabled={mode.isRequestApprove || mode.isRequestView || isFieldsDisabled}
      />
      <InputField label="Rule Type" required>
        <FormControl
          id="ruleType"
          componentClass="select"
          placeholder="select"
          defaultValue={values.ruleType}
          onChange={handleFieldChange}
          disabled
        >
          <option value="DESCRIPTION">DESCRIPTION</option>
        </FormControl>
      </InputField>

      <InputField
        id="isExcludeRule"
        label="Is Exclude Rule"
        type="CheckBox"
        checked={values.isExcludeRule || false}
        onChange={handleFieldChange}
        disabled={mode.isRequestApprove || mode.isRequestView || isFieldsDisabled}
      />

      {!mode.isRequestView && (
        <Alert bsStyle={ruleInfoStyle}>
          <Glyphicon className="padding-rigt-standard" glyph="info-sign" />
          {ruleInfoMessage}
        </Alert>
      )}

      <NoteFields
        mode={mode}
        request_notes={values.request_notes}
        review_notes={values.review_notes}
        handleFieldChange={handleFieldChange}
      />

      <CommonRuleModalButtons
        handleOkButton={handleOkButton}
        handleRejectButton={handleRejectButton}
        handleCancelButton={handleCancelButton}
        mode={mode}
        okButtonName={okButtonName}
        isOkButtonDisabled={isOkButtonDisabled}
      />
    </Form>
  )
}

MerchantBrandRegexRuleModal.defaultProps = {
  value: {
    id: null,
    merchantId: undefined,
    merchantName: null,
    brandId: undefined,
    brandFullPath: null,

    includeParam: null,
    excludeParam: null,
    ruleType: null,
    isExcludeRule: null,

    request_notes: null,
    review_notes: null
  },
  isRequest: false,
  isReadOnly: false
}

MerchantBrandRegexRuleModal.propTypes = {
  value: PropTypes.shape({
    id: PropTypes.number,
    merchantId: PropTypes.number,
    merchantName: PropTypes.string,
    brandId: PropTypes.number,
    brandFullPath: PropTypes.string,

    includeParam: PropTypes.string,
    excludeParam: PropTypes.string,
    ruleType: PropTypes.string,
    isExcludeRule: PropTypes.bool,

    request_notes: PropTypes.string,
    review_notes: PropTypes.string
  }),

  onOkHandler: PropTypes.func.isRequired,
  onRejectHandler: PropTypes.func,

  isRequest: PropTypes.bool,
  isReadOnly: PropTypes.bool
}
