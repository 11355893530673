import React, { useState } from 'react'

import DomainAutocomplete from './DomainAutocomplete'
import WhitelistedStep from './WhitelistedStep/WhitelistedStep'
import BronzeToSilverStep from './BronzeToSilverStep/BronzeToSilverStep'
import RecrawlingStep from './RecrawlingStep/RecrawlingStep'
import { STEP } from '../orchestratorConstants'

const JobProcessBlock = () => {
  const [currentStep, setCurrentStep] = useState(STEP.SELECT_DOMAIN)
  const [sessionId, setSessionId] = useState('')
  const [selectedDomain, setSelectedDomain] = useState({})

  const onSelectDomain = domain => {
    setSelectedDomain(domain)
    if (domain === null) {
      setCurrentStep(STEP.SELECT_DOMAIN)
    } else {
      setCurrentStep(STEP.WHITELISTED)
    }
  }

  return (
    <>
      <DomainAutocomplete onClickHandler={onSelectDomain} />
      <WhitelistedStep
        selectedDomain={selectedDomain}
        active={currentStep === STEP.WHITELISTED}
        activateNextStep={setCurrentStep}
      />
      <BronzeToSilverStep
        selectedDomain={selectedDomain}
        active={currentStep === STEP.BRONZE_TO_SILVER}
        setSessionId={setSessionId}
        activateNextStep={setCurrentStep}
      />
      <RecrawlingStep selectedDomain={selectedDomain} sessionId={sessionId} active={currentStep === STEP.RECRAWLING} />
    </>
  )
}

export default JobProcessBlock
