import React, { Component } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import { Table } from 'react-bootstrap'

const isOdd = num => num % 2

const isFirstDescriptionInGroup = (rows, i) =>
  i === 0 || rows[i - 1].descriptionIndex !== rows[i].descriptionIndex || false

const getSourceName = source => {
  const mapping = {
    // source : {ui info}
    dictionary: {
      name: 'Dictionary',
      description: 'Category and Brand given by Dictionary.'
    },
    fastText: {
      name: 'Machine Learning',
      description: 'Category given from Machine Learning Algorithm only.'
    },
    ruleCategorize: {
      name: 'Rules Category',
      description: 'Category given by rules.'
    },
    'dictionary+ruleBrandize': {
      name: 'Dictionary Rule Brand',
      description: 'Brand given by rules using the Dictionary’s Category.'
    },
    'fastText+ruleBrandize': {
      name: 'Machine Learning Rule Brand',
      description: 'Brand given by rules using the ML Algorithm’s Category.'
    },
    'ruleCategorize+ruleBrandize': {
      name: 'Rules Full',
      description: 'Brand given by rules using the Rule’s Category.'
    }
  }

  const defaultInfo = {
    name: source,
    description: '[unknown source]'
  }

  return mapping[source] || defaultInfo
}

const getRuleName = ruleType => {
  const mapping = {
    // rule type from API : {ui info}
    'Description-Category': {
      name: 'MCRR Description',
      description: 'Merchant Category Regex Rule - Description'
    },
    'Category 1-1': {
      name: 'MCR',
      description: 'Merchant Category  Rule'
    },
    'Parsed-Category': {
      name: 'MCRR Parsed Category',
      description: 'Merchant Category Regex Rule - Parsed Category'
    },
    'Description-Brand': {
      name: 'MBRR Description',
      description: 'Merchant Brand Regex Rule - Description'
    },
    'Brand 1-1': {
      name: 'MBR',
      description: 'Merchant Brand Rule'
    },
    'Category-Brand': {
      name: 'CBR',
      description: 'Category Brand Rule'
    }
  }

  const defaultInfo = {
    name: ruleType,
    description: '[unknown rule type]'
  }

  return mapping[ruleType] || defaultInfo
}

export default class CMEResultTable extends Component {
  render() {
    const { rows } = this.props
    return (
      <Table responsive bordered condensed className="cme-inspector-table">
        <thead>
          <tr>
            <th className="text-center">Source</th>
            <th className="text-left">Merchant</th>
            <th className="text-center">Description</th>
            <th className="text-left">Parsed Category</th>
            <th className="text-left">Rule</th>
            <th className="text-left">Category</th>
            <th className="text-left">Brand</th>
            <th className="text-left">CME Statistic</th>
          </tr>
        </thead>
        <tbody>
          {rows.map((row, i) => (
            <tr
              key={row.source + '-' + i}
              className={isOdd(row.descriptionIndex) ? 'cme-inspector-row-1' : 'cme-inspector-row-2'}
            >
              <td className="text-left" title={getSourceName(row.source).description}>
                {getSourceName(row.source).name}
              </td>
              <td className="text-left">
                {row.merchant?.id && `${row.merchant.name || '[unknown]'} (${row.merchant.id})`}
              </td>
              <td
                className={cx('text-left', {
                  'cme-inspector-first-description-cell': isFirstDescriptionInGroup(rows, i)
                })}
              >
                {row.description}
              </td>
              <td className="text-left">{row.parsedCategory}</td>
              <td className="text-left" title={row.rule?.id && getRuleName(row.rule.name).description}>
                {row.rule?.id && `${getRuleName(row.rule.name).name || '[unknown]'} (${row.rule.id}) `}
              </td>
              <td className="text-left">
                {row.category?.id && `${row.category.name || '[unknown]'} (${row.category.id})`}
              </td>
              <td className="text-left">{row.brand?.id && `${row.brand.name || '[unknown]'} (${row.brand.id})`}</td>
              <td
                className="text-left"
                title={
                  row.cmeStatistic &&
                  'Similarity is how similar a description is to the category. Confidence is how confident the ML algorithm is about the category.'
                }
              >
                {row.cmeStatistic && (
                  <>
                    <div>Similarity: {row.cmeStatistic.similarity}</div>
                    <div>Confidence: {row.cmeStatistic.confidence}</div>
                  </>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    )
  }
}

CMEResultTable.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.object)
}
