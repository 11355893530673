import React from 'react'
import PropTypes from 'prop-types'

import { Button } from 'react-bootstrap'

export default function CommonRuleModalButtons({
  handleOkButton,
  handleRejectButton,
  handleCancelButton,
  mode,
  okButtonName,
  isOkButtonDisabled,
  isRejectButtonDisabled
}) {
  return (
    <div className="modal-body-footer">
      <Button onClick={handleCancelButton}>Cancel</Button>
      {!mode.isRequestView && (
        <>
          {mode.isRequestApprove && (
            <Button onClick={handleRejectButton} disabled={isRejectButtonDisabled}>
              Reject
            </Button>
          )}
          <Button className="btn_custom" onClick={handleOkButton} disabled={isOkButtonDisabled}>
            {okButtonName}
          </Button>
        </>
      )}
    </div>
  )
}

CommonRuleModalButtons.defaultProps = {
  isRejectButtonDisabled: false
}

CommonRuleModalButtons.propTypes = {
  handleOkButton: PropTypes.func.isRequired,
  handleRejectButton: PropTypes.func.isRequired,
  handleCancelButton: PropTypes.func.isRequired,

  mode: PropTypes.object.isRequired,

  okButtonName: PropTypes.string.isRequired,
  isOkButtonDisabled: PropTypes.bool.isRequired,
  isRejectButtonDisabled: PropTypes.bool
}
