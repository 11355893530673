import commonRtkApi from 'commonRtkApi.js'

const recrawlingStepAPI = commonRtkApi.injectEndpoints({
  endpoints: builder => ({
    runRecrawling: builder.query({
      query: ({ domain, sessionId }) => {
        const data = { domain, sessionId }
        return {
          url: `/supra-orchestration/recrawling-run`,
          method: 'POST',
          data
        }
      }
    }),
    getRecrawlingRunStatus: builder.query({
      query: ({ dagRunId, sessionId }) => {
        const data = { dagRunId, sessionId }
        return {
          url: `/supra-orchestration/recrawling-run-status`,
          method: 'POST',
          data
        }
      }
    })
  })
})

export const { useLazyRunRecrawlingQuery, useLazyGetRecrawlingRunStatusQuery } = recrawlingStepAPI
