import React from 'react'
import { upperFirst } from 'lodash'

import { TableHeaderColumn } from 'react-bootstrap-table'

import ActionColumn from './ActionColumn'
import { DATA_TABLE } from 'components/DataTable/constants'
import { BooleanCell } from 'components/DataTable'

const getLevelTitle = (columnName, columnValue, row) => {
  // get level ("l1_id" -> "l1__name") column name
  const lN_name = columnName.replace('id', 'name')
  return row[lN_name] ? row[lN_name] : null
}

const buildTaxonomyColumns = ({ tableType, editFunction, hasEditAccess, deleteFunction }) => {
  const tableId = `id`
  const tableName = 'name'
  const isCPG = 'is_cpg'

  const getColumn = column => {
    const defaultColumnConfig = {
      label: column,
      field: column,
      dataSort: false,
      dataAlign: 'center',
      headerAlign: 'center',
      width: '100px'
    }

    let config = {}
    switch (column) {
      case tableId:
        config = {
          label: 'ID',
          width: '75px',
          dataSort: true,
          filter: {
            type: 'TextFilter',
            delay: DATA_TABLE.FILTER_DELAY
          }
        }
        break

      case tableName:
        config = {
          label: 'Name',
          dataAlign: 'left',
          dataSort: true,
          width: 'auto',
          filter: {
            type: 'TextFilter',
            delay: DATA_TABLE.FILTER_DELAY
          }
        }
        break

      case 'full_path':
        config = {
          label: 'Full Path',
          dataAlign: 'left',
          dataSort: true,
          width: 'auto',
          filter: {
            type: 'TextFilter',
            delay: DATA_TABLE.FILTER_DELAY
          }
        }
        break

      case 'is_active':
        config = {
          label: 'Active',
          width: '80px',
          dataSort: true,
          dataFormat: (cell, row) => BooleanCell({ isActive: cell }),
          filter: {
            type: 'SelectFilter',
            options: {
              1: 'True',
              0: 'False'
            }
          }
        }
        break

      case isCPG:
        config = {
          label: 'CPG',
          width: '80px',
          dataSort: true,
          dataFormat: (cell, row) => BooleanCell({ isActive: cell }),
          filter: {
            type: 'SelectFilter',
            options: {
              1: 'True',
              0: 'False'
            }
          }
        }
        break

      case 'is_leaf':
        config = {
          label: 'Is Leaf',
          width: '80px',
          dataSort: true,
          dataFormat: (cell, row) => BooleanCell({ isActive: cell }),
          filter: {
            type: 'SelectFilter',
            options: {
              1: 'True',
              0: 'False'
            }
          }
        }
        break

      case 'l1_id':
      case 'l2_id':
      case 'l3_id':
      case 'l4_id':
      case 'l5_id':
      case 'l6_id':
        config = {
          width: '75px',
          dataSort: true,
          columnTitle: (cell, row) => getLevelTitle(column, cell, row),
          filter: {
            type: 'TextFilter',
            delay: DATA_TABLE.FILTER_DELAY
          }
        }
        break

      case 'action_column':
        config = {
          label: 'Actions',
          // eslint-disable-next-line react/display-name
          dataFormat: (cell, row) => {
            return (
              <ActionColumn
                rowId={row[tableId]}
                isLeaf={row.is_leaf}
                isActive={row.is_active}
                rowName={row[tableName]}
                isCPG={row[isCPG]}
                editFunction={editFunction}
                deleteFunction={deleteFunction}
                tableType={upperFirst(tableType)}
              />
            )
          }
        }
        break

      default:
        break
    }

    const colConfig = {
      ...defaultColumnConfig,
      ...config
    }

    return colConfig
  }

  const fields = {
    brand: [
      tableId,
      'full_path',
      tableName,
      'l1_id',
      'l2_id',
      'l3_id',
      'l4_id',
      'l5_id',
      'l6_id',
      'is_active',
      'is_leaf',
      'action_column'
    ],
    category: [
      tableId,
      'full_path',
      tableName,
      'l1_id',
      'l2_id',
      'l3_id',
      'l4_id',
      'l5_id',
      'l6_id',
      'is_active',
      isCPG,
      'is_leaf',
      'action_column'
    ]
  }

  // remove action column if permission to view only
  if (hasEditAccess === false) {
    fields[tableType].pop()
  }

  const columns = fields[tableType].map(field => getColumn(field))
  const headerColumns = columns.map((col, index) => {
    const { field, label, ...otherProps } = col
    return (
      <TableHeaderColumn
        {...otherProps}
        ref={field}
        dataField={field}
        isKey={field === tableId}
        key={`${tableType}-col-${index}`}
      >
        {label}
      </TableHeaderColumn>
    )
  })
  return headerColumns
}

export default buildTaxonomyColumns
