import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { inRange } from 'lodash'

import { Button } from 'react-bootstrap'

import { clearModals } from '../../../actions/modal-actions'

import './index.scss'

class TextareaModal extends Component {
  constructor(props) {
    super(props)

    this.state = { inputValue: '' }
  }

  onChange = event => {
    const inputValue = (event.target && event.target.value) || ''
    this.setState({ inputValue })
  }

  handleSubmit = () => {
    const { onSubmit, clearModals } = this.props
    const { inputValue } = this.state

    if (onSubmit && typeof onSubmit === 'function') {
      onSubmit(inputValue)
    }

    clearModals()
  }

  isCommentValid = () => {
    const { maxLength, minLength } = this.props
    const { inputValue } = this.state

    if (minLength > 0 && maxLength) {
      return inRange(inputValue.length, minLength, maxLength)
    }

    // Not worried about input value length
    return true
  }

  render() {
    const { subtitle, rowCount, minLength, submitText, cancelText, columnCount, placeholder, clearModals } = this.props

    const validComment = this.isCommentValid()

    return (
      <React.Fragment>
        <h4 className={'TextareaModal__header'}>
          {subtitle && minLength && minLength > 0 ? `${subtitle} (Minimum ${minLength} characters)` : subtitle}
        </h4>

        <textarea
          required
          autoFocus
          rows={rowCount}
          cols={columnCount}
          onChange={this.onChange}
          placeholder={placeholder}
          className="TextareaModal__textarea"
        />

        <div className="modal-footer">
          <div className="modal-buttons">
            <Button className="btn-cancel" onClick={clearModals}>
              {cancelText}
            </Button>

            <Button className="btn-ok" disabled={!validComment} onClick={this.handleSubmit}>
              {submitText}
            </Button>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

TextareaModal.defaultProps = {
  rowCount: 8,
  columnCount: 50,
  minLength: 10,
  maxLength: 500,
  subtitle: '',
  submitText: 'Submit',
  cancelText: 'Cancel',
  placeholder: ''
}

TextareaModal.propTypes = {
  subtitle: PropTypes.string,
  placeholder: PropTypes.string,
  rowCount: PropTypes.number,
  columnCount: PropTypes.number,
  minLength: PropTypes.number,
  maxLength: PropTypes.number,
  clearModals: PropTypes.func,
  onSubmit: PropTypes.func,
  submitText: PropTypes.string,
  cancelText: PropTypes.string
}

export default connect(null, { clearModals })(TextareaModal)
