import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { debounce } from 'lodash'
import { Button, Alert, Glyphicon } from 'react-bootstrap'
import ChipSelector from '../../ChipSelector'
import DeliverableTable from './../Categories/DeliverableTable'
import { clearModals } from '../../../actions/modal-actions'
import { fetchDeliverablesByCategory } from '../../../actions/taxonomy-actions'
import { isCategoryNameValid, isBrandNameValid, trimName } from './helper'
import CheckBox from 'components/CheckBox'
import InfoTooltip from 'components/Tooltips/InfoTooltip'
import { CPG_TOOLTIP_TEXT } from '../constants-taxonomy'
import TaxonomyNameTooltipInfo from './TaxonomyNameTooltipInfo'

const SPLIT_CHARACTER = '\n'

class TaxonomyCreateModal extends Component {
  constructor(props) {
    super(props)

    this.state = {
      parentId: null,
      nameList: '',
      selectedParent: null,
      isCPG: false
    }
  }

  handleParentChange = selectedParent => {
    const parentId = selectedParent ? selectedParent.id : null
    this.setState({
      selectedParent,
      parentId
    })

    if (parentId && selectedParent.is_leaf === true) {
      this.props.fetchDeliverablesByCategory({ categoryId: parentId, includeChildren: false })
    }
  }

  handleNameUpdate = typeEvent => {
    const nameList = (typeEvent.target && typeEvent.target.value) || ''
    this.setState({ nameList })
  }

  sanitizeInput = e => {
    const validator = this.props.toolbarType === 'Category' ? isCategoryNameValid : isBrandNameValid
    if (!validator(e.key)) {
      e.preventDefault()
    }
  }

  handleCreate = () => {
    const namesSplit = this.state.nameList.split(SPLIT_CHARACTER)
    const nameList = []

    namesSplit.forEach(name => {
      const trimmedName = trimName(name)

      if (trimmedName && trimmedName !== '') {
        nameList.push(trimmedName)
      }
    })

    this.props.createFunction({
      nameList,
      ancestorId: this.state.parentId,
      isCPG: this.state.isCPG
    })

    this.props.clearModals()
  }

  debouncedSearchFunction = debounce(searchText => {
    this.props.fetchSuggestions(searchText)
  }, 200)

  onCPGCheckboxClick = val => {
    this.setState({
      isCPG: val
    })
  }

  renderInputs = () => {
    const { toolbarType, brandSuggestions, categorySuggestions, deliverablesLoading, deliverables } = this.props
    const { selectedParent } = this.state

    const parentSuggestions = toolbarType === 'Brand' ? brandSuggestions : categorySuggestions

    const deliverablesTotalCount = deliverables.count
    const deliverablesRows = deliverables.rows

    return (
      <>
        <div className="taxonomy-name-input">
          <span className="taxonomy-input-label">
            {`${toolbarType} names`}
            <TaxonomyNameTooltipInfo type={toolbarType} />
          </span>
          <textarea
            style={{ resize: 'none' }}
            rows={5}
            cols={40}
            onChange={this.handleNameUpdate}
            onKeyDown={this.sanitizeInput}
            placeholder="Enter names separated by new line."
          />
        </div>

        {toolbarType === 'Category' && (
          <>
            <div className="taxonomy-parent-input">
              <span className="taxonomy-input-label">{`Parent ${toolbarType}`}</span>

              <div>
                <ChipSelector
                  id="parentId"
                  labelKey="full_path"
                  className="parent-suggestion-input"
                  searchSuggestions={parentSuggestions}
                  selectedValue={this.state.selectedParent}
                  onSelectionChange={this.handleParentChange}
                  placeholder={`Search for a Parent ${toolbarType}`}
                  onSearchChange={search => {
                    this.debouncedSearchFunction(search)
                  }}
                />
              </div>
            </div>
            <div className="mt-5 taxonomy-parent-input">
              <span className="taxonomy-input-label">
                <span>CPG</span>
                <span className="fs-14-16 pb-1">
                  <InfoTooltip text={CPG_TOOLTIP_TEXT} />
                </span>
              </span>
              <div className="parent-suggestion-input">
                <CheckBox
                  className="checkboxInline"
                  valueDidChange={this.onCPGCheckboxClick}
                  checked={this.state.isCPG}
                />
              </div>
            </div>
            <div className="taxonomy-parent-input-validation">
              {selectedParent &&
                selectedParent.is_leaf === true &&
                (deliverablesLoading ? (
                  <span>Check related Deliverables ...</span>
                ) : deliverablesTotalCount > 0 ? (
                  <>
                    <Alert bsStyle="warning">
                      <Glyphicon glyph="warning-sign" />
                      {`There are Deliverables (${deliverablesTotalCount}) associated with this Category. Are you sure you want to make it a parent node?`}
                    </Alert>
                    <DeliverableTable
                      deliverables={deliverablesRows}
                      totalCount={deliverablesTotalCount}
                      showCategory={false}
                    />
                  </>
                ) : (
                  <Alert bsStyle="success">
                    <Glyphicon glyph="ok-sign" />
                    {`Selected Category has no related Deliverables`}
                  </Alert>
                ))}
            </div>
          </>
        )}
      </>
    )
  }

  render() {
    const { nameList } = this.state
    const { deliverablesLoading } = this.props
    const createButtonDisabled = nameList === '' || deliverablesLoading

    return (
      <>
        {this.renderInputs()}
        <div className="modal-body-footer">
          <Button onClick={this.props.clearModals}>Cancel</Button>
          <Button className="btn_custom" onClick={this.handleCreate} disabled={createButtonDisabled}>
            Create
          </Button>
        </div>
      </>
    )
  }
}

TaxonomyCreateModal.defaultProps = {
  brandSuggestions: [],
  categorySuggestions: []
}

TaxonomyCreateModal.propTypes = {
  toolbarType: PropTypes.string.isRequired,
  createFunction: PropTypes.func.isRequired,
  fetchSuggestions: PropTypes.func.isRequired,
  brandSuggestions: PropTypes.array.isRequired,
  categorySuggestions: PropTypes.array.isRequired,

  deliverablesLoading: PropTypes.bool,
  deliverables: PropTypes.object,

  clearModals: PropTypes.func.isRequired,
  fetchDeliverablesByCategory: PropTypes.func.isRequired
}

const mapStateToProps = ({ taxonomy: { brands, categories } }) => ({
  brandSuggestions: brands.suggestions,
  categorySuggestions: categories.suggestions,
  deliverablesLoading: categories.deliverables.loading,
  deliverables: categories.deliverables.data
})

const mapDispatchToProps = {
  clearModals,
  fetchDeliverablesByCategory
}

export default connect(mapStateToProps, mapDispatchToProps)(TaxonomyCreateModal)
