import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import FeedsList from '../FeedListPage'
import Unauthorized from '../Unauthorized'
import FeedDeliveriesPage from './FeedDeliveriesPage'
import FeedGroupLocks from './FeedGroupLocks'
import ClientFeedInfo from './ClientFeedInfo'
import GlobalFeedConfig from './GlobalFeedConfig'
import FeedModules from './FeedModules'
import DataFeedTabs from './DataFeedTabs'
import FeedDeliveriesSummary from './FeedDeliveriesSummary'
import WithErrorBoundaryWrapper from 'components/WithErrorBoundaryWrapper/WithErrorBoundaryWrapper'

import { fetchFeedDeliveries } from '../../actions/feed-actions'

import UserService from '../../services/user-service'
import './index.scss'

class DataFeeds extends Component {
  componentDidMount() {
    if (this.props.isPortalAdmin) {
      this.fetchFeedDeliveries()
    }
  }

  componentDidUpdate() {
    const { feedDeliveries, loading } = this.props

    if (feedDeliveries.length === 0 && !loading) {
      this.fetchFeedDeliveries()
    }
  }

  fetchFeedDeliveries = () => {
    this.props.fetchFeedDeliveries({
      page: 1,
      count: 25
    })
  }

  render() {
    const { location = {}, feedDeliveries, isPortalAdmin, fetchFeedDeliveries } = this.props

    const locationComponentMap = {
      '/admin/feeds/deliveries': (
        <FeedDeliveriesPage feedDeliveries={feedDeliveries} fetchFeedDeliveries={fetchFeedDeliveries} />
      ),
      '/admin/feeds/deliveries-summary': <FeedDeliveriesSummary />,

      '/admin/feeds': <FeedsList location={location} />,
      '/admin/feeds/modules': <FeedModules location={location} />,
      '/admin/feeds/locks': <FeedGroupLocks />,
      '/admin/feeds/client-info': <ClientFeedInfo />,
      '/admin/feeds/global-config': <GlobalFeedConfig />
    }

    function getComponentForPath(path) {
      if (path.startsWith('/admin/feeds/modules')) {
        return <FeedModules location={location} />
      }
      return locationComponentMap[path] || locationComponentMap['/admin/feeds']
    }

    if (!isPortalAdmin) return <Unauthorized />

    return (
      <>
        {DataFeedTabs(this.props)}
        {getComponentForPath(location.pathname)}
      </>
    )
  }
}

DataFeeds.propTypes = {
  loading: PropTypes.bool,
  location: PropTypes.object,
  isPortalAdmin: PropTypes.bool,
  fetchFeedDeliveries: PropTypes.func,
  fetchFeedDeliveriesSummary: PropTypes.func,
  deliveredTodayCount: PropTypes.number,
  feedDeliveries: PropTypes.arrayOf(PropTypes.object)
}

const mapStateToProps = ({
  session: { user },
  feeds: {
    feedDeliveries: { data, loading, error, deliveredTodayCount }
  }
}) => ({
  error,
  loading,
  deliveredTodayCount,
  feedDeliveries: data,
  isPortalAdmin: user ? UserService.isPortalAdmin(user) : false
})

export default WithErrorBoundaryWrapper(
  connect(mapStateToProps, { fetchFeedDeliveries })(DataFeeds),
  '"Admin Data Feeds" page'
)
