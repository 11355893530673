import { useState } from 'react'
import { thresholdValidation } from './../validation'

const useGlobalThresholdField = ({ values, handleFieldChange }) => {
  const [errors, setErrors] = useState({})

  const register = ({ id, validation }) => {
    const actualValidation = validation || thresholdValidation

    const onChange = e => {
      const value = handleFieldChange(e)[id]

      const error = actualValidation(value)
      setErrors(state => ({ ...state, [id]: error }))
    }

    // get isActive key for current field
    const [name] = id.split('Test')
    const isActiveKey = name + 'TestIsActive'

    return {
      id,
      onChange,
      value: values[id],
      error: errors[id],
      disabled: !values[isActiveKey]
    }
  }

  return [register, errors]
}

export default useGlobalThresholdField
