import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { Button } from 'react-bootstrap'
import { some } from 'lodash'

import { useFormValues } from 'components/FormInputComponents/InputField'
import categoryTestsActions from './../../categoryTestsActions'
import { CONFIG_TOOLTIP, RESET_WARNING } from './../constants'

import DateTestRange from './DateTestRange'

import Toggle from 'components/Toggle'
import FieldsGroup from './../FieldsGroup'
import ThresholdField from './../ThresholdField'
import useGlobalThresholdField from './useGlobalThresholdField'
import { dateValidation, rankValidation } from './../validation'

const GlobalForm = ({ initValues, closeModal, isOkDisabled }) => {
  const dispatch = useDispatch()

  const { values, isFormChanged, handleFieldChange, updateValue } = useFormValues(initValues)

  const handleOkButton = () => {
    dispatch(categoryTestsActions.saveGlobalConfig(values))
    closeModal()
  }

  const handleRejectButton = () => {
    closeModal()
  }

  // TODO refactor this. The main idea set information about fields in one place
  // And generate there reset info, fields info, save convert etc. ? move in hook?
  const isConfigChanged = (prevIsAcative, newIsAcative, prevTreshold, newTreshold) => {
    const isTestActivation = newIsAcative && !prevIsAcative

    const isTresholdChanged = newIsAcative && Number(prevTreshold) !== Number(newTreshold)

    if (isTestActivation || isTresholdChanged) {
      return true
    }

    return false
  }
  const getIsShowResetWarning = ({ prevValues, newValues }) => {
    const changes = [
      isConfigChanged(
        prevValues.dateTestIsActive,
        newValues.dateTestIsActive,
        prevValues.dateTestThreshold,
        newValues.dateTestThreshold
      ),
      isConfigChanged(
        prevValues.dictionaryTestIsActive,
        newValues.dictionaryTestIsActive,
        prevValues.dictionaryTestThreshold,
        newValues.dictionaryTestThreshold
      ),
      isConfigChanged(
        prevValues.includeTestIsActive,
        newValues.includeTestIsActive,
        prevValues.includeTestThreshold,
        newValues.includeTestThreshold
      ),
      isConfigChanged(
        prevValues.excludeTestIsActive,
        newValues.excludeTestIsActive,
        prevValues.excludeTestThreshold,
        newValues.excludeTestThreshold
      ),
      isConfigChanged(
        prevValues.misbrandTestIsActive,
        newValues.misbrandTestIsActive,
        prevValues.misbrandTestThreshold,
        newValues.misbrandTestThreshold
      ),
      isConfigChanged(
        prevValues.misbrandTestIsActive,
        newValues.misbrandTestIsActive,
        prevValues.misbrandTestThreshold,
        newValues.misbrandTestThreshold
      ),
      isConfigChanged(
        prevValues.coverageTestIsActive,
        newValues.coverageTestIsActive,
        prevValues.coverageTestThreshold,
        newValues.coverageTestThreshold
      ),
      isConfigChanged(
        prevValues.newBrandTestIsActive,
        newValues.newBrandTestIsActive,
        prevValues.newBrandTestThreshold,
        newValues.newBrandTestThreshold
      ),
      isConfigChanged(
        prevValues.newBrandMonthTestIsActive,
        newValues.newBrandMonthTestIsActive,
        prevValues.newBrandMonthTestThreshold,
        newValues.newBrandMonthTestThreshold
      ),
      // merchant trend
      isConfigChanged(
        prevValues.merchantTrendTestIsActive,
        newValues.merchantTrendTestIsActive,
        prevValues.merchantTrendTestThreshold,
        newValues.merchantTrendTestThreshold
      ),
      isConfigChanged(
        prevValues.merchantTrendTestIsActive,
        newValues.merchantTrendTestIsActive,
        prevValues.merchantTrendTestSensitivity,
        newValues.merchantTrendTestSensitivity
      ),
      isConfigChanged(
        prevValues.merchantTrendTestIsActive,
        newValues.merchantTrendTestIsActive,
        prevValues.merchantTrendTestRank,
        newValues.merchantTrendTestRank
      ),
      // brand trend
      isConfigChanged(
        prevValues.brandTrendTestIsActive,
        newValues.brandTrendTestIsActive,
        prevValues.brandTrendTestThreshold,
        newValues.brandTrendTestThreshold
      ),
      isConfigChanged(
        prevValues.brandTrendTestIsActive,
        newValues.brandTrendTestIsActive,
        prevValues.brandTrendTestSensitivity,
        newValues.brandTrendTestSensitivity
      ),
      isConfigChanged(
        prevValues.brandTrendTestIsActive,
        newValues.brandTrendTestIsActive,
        prevValues.brandTrendTestRank,
        newValues.brandTrendTestRank
      ),

      // overall trend
      isConfigChanged(
        prevValues.overallTrendTestIsActive,
        newValues.overallTrendTestIsActive,
        prevValues.overallTrendTestThreshold,
        newValues.overallTrendTestThreshold
      ),
      isConfigChanged(
        prevValues.overallTrendTestIsActive,
        newValues.overallTrendTestIsActive,
        prevValues.overallTrendTestSensitivity,
        newValues.overallTrendTestSensitivity
      )
    ]

    return some(changes, Boolean)
  }
  const showResetWarning = getIsShowResetWarning({ prevValues: initValues, newValues: values })

  const [registerThresholdField, errors] = useGlobalThresholdField({
    values,
    handleFieldChange
  })

  const isError = Object.values(errors).some(v => Boolean(v))

  return (
    <>
      <div className="category-tests-modal-form-body">
        <FieldsGroup label="Date Test" info={CONFIG_TOOLTIP.DATE}>
          <Toggle
            id="dateTestIsActive"
            isActive={values.dateTestIsActive || false}
            onChange={newValue => updateValue({ dateTestIsActive: newValue })}
          />
          <ThresholdField
            {...registerThresholdField({
              id: 'dateTestThreshold',
              validation: dateValidation
            })}
            label="Period"
            addon="Months"
          />
          <DateTestRange monthRange={values.dateTestThreshold} />
        </FieldsGroup>

        <FieldsGroup label="Dictionary Test" info={CONFIG_TOOLTIP.DICTIONARY}>
          <Toggle
            id="dictionaryTestIsActive"
            isActive={values.dictionaryTestIsActive || false}
            onChange={newValue => updateValue({ dictionaryTestIsActive: newValue })}
          />
          <ThresholdField
            {...registerThresholdField({
              id: 'dictionaryTestThreshold'
            })}
          />
        </FieldsGroup>

        <FieldsGroup label="Include Test" info={CONFIG_TOOLTIP.INCLUDE}>
          <Toggle
            id="includeTestIsActive"
            isActive={values.includeTestIsActive || false}
            onChange={newValue => updateValue({ includeTestIsActive: newValue })}
          />
          <ThresholdField
            {...registerThresholdField({
              id: 'includeTestThreshold'
            })}
          />
        </FieldsGroup>

        <FieldsGroup label="Exclude Test" info={CONFIG_TOOLTIP.EXCLUDE}>
          <Toggle
            id="excludeTestIsActive"
            isActive={values.excludeTestIsActive || false}
            onChange={newValue => updateValue({ excludeTestIsActive: newValue })}
          />
          <ThresholdField
            {...registerThresholdField({
              id: 'excludeTestThreshold'
            })}
          />
        </FieldsGroup>

        <FieldsGroup label="Misbrand Test" info={CONFIG_TOOLTIP.MISBRAND}>
          <Toggle
            id="misbrandTestIsActive"
            isActive={values.misbrandTestIsActive || false}
            onChange={newValue => updateValue({ misbrandTestIsActive: newValue })}
          />
          <ThresholdField
            {...registerThresholdField({
              id: 'misbrandTestThreshold'
            })}
          />
        </FieldsGroup>

        <FieldsGroup label="Coverage Test" info={CONFIG_TOOLTIP.COVERAGE}>
          <Toggle
            id="coverageTestIsActive"
            isActive={values.coverageTestIsActive || false}
            onChange={newValue => updateValue({ coverageTestIsActive: newValue })}
          />
          <ThresholdField
            {...registerThresholdField({
              id: 'coverageTestThreshold'
            })}
          />
        </FieldsGroup>

        <FieldsGroup label="New Brand Test Test" info={CONFIG_TOOLTIP.NEW_BRAND}>
          <Toggle
            id="newBrandTestIsActive"
            isActive={values.newBrandTestIsActive || false}
            onChange={newValue => updateValue({ newBrandTestIsActive: newValue })}
          />
          <ThresholdField
            {...registerThresholdField({
              id: 'newBrandTestThreshold'
            })}
          />
        </FieldsGroup>

        <FieldsGroup label="New Brand 3 months Test" info={CONFIG_TOOLTIP.NEW_BRAND_3_MONTHS}>
          <Toggle
            id="newBrandMonthTestIsActive"
            isActive={values.newBrandMonthTestIsActive || false}
            onChange={newValue => updateValue({ newBrandMonthTestIsActive: newValue })}
          />
          <ThresholdField
            {...registerThresholdField({
              id: 'newBrandMonthTestThreshold'
            })}
          />
        </FieldsGroup>

        <FieldsGroup label="Merchant Trend Test" info={CONFIG_TOOLTIP.MERCHANT_TREND}>
          <Toggle
            id="merchantTrendTestIsActive"
            isActive={values.merchantTrendTestIsActive || false}
            onChange={newValue => updateValue({ merchantTrendTestIsActive: newValue })}
          />
          <ThresholdField
            {...registerThresholdField({
              id: 'merchantTrendTestThreshold'
            })}
          />
          <ThresholdField
            {...registerThresholdField({
              id: 'merchantTrendTestSensitivity'
            })}
            label="Sensitivity"
          />
          <ThresholdField
            {...registerThresholdField({
              id: 'merchantTrendTestRank',
              validation: rankValidation
            })}
            label="Rank"
            addon={null}
          />
        </FieldsGroup>

        <FieldsGroup label="Brand Trend Test" info={CONFIG_TOOLTIP.BRAND_TREND}>
          <Toggle
            id="brandTrendTestIsActive"
            isActive={values.brandTrendTestIsActive || false}
            onChange={newValue => updateValue({ brandTrendTestIsActive: newValue })}
          />
          <ThresholdField
            {...registerThresholdField({
              id: 'brandTrendTestThreshold'
            })}
          />
          <ThresholdField
            {...registerThresholdField({
              id: 'brandTrendTestSensitivity'
            })}
            label="Sensitivity"
          />
          <ThresholdField
            {...registerThresholdField({
              id: 'brandTrendTestRank',
              validation: rankValidation
            })}
            label="Rank"
            addon={null}
          />
        </FieldsGroup>

        <FieldsGroup label="Overall Trend Test" info={CONFIG_TOOLTIP.OVERALL_TREND}>
          <Toggle
            id="overallTrendTestIsActive"
            isActive={values.overallTrendTestIsActive || false}
            onChange={newValue => updateValue({ overallTrendTestIsActive: newValue })}
          />
          <ThresholdField
            {...registerThresholdField({
              id: 'overallTrendTestThreshold'
            })}
          />
          <ThresholdField
            {...registerThresholdField({
              id: 'overallTrendTestSensitivity'
            })}
            label="Sensitivity"
          />
        </FieldsGroup>

        <FieldsGroup label="Is Autosubmit" info={CONFIG_TOOLTIP.AUTOSUBMIT}>
          <Toggle
            id="isAutoSubmit"
            isActive={values.isAutoSubmit}
            onChange={newValue => updateValue({ isAutoSubmit: newValue })}
          />
        </FieldsGroup>
      </div>

      <div className="category-tests-modal-reset-container">{showResetWarning && RESET_WARNING.GLOBAL}</div>
      <div className="modal-body-footer">
        <Button onClick={handleRejectButton}>Cancel</Button>
        <Button className="btn_custom" disabled={isOkDisabled || !isFormChanged || isError} onClick={handleOkButton}>
          Save
        </Button>
      </div>
    </>
  )
}

GlobalForm.propTypes = {
  initValues: PropTypes.object,
  closeModal: PropTypes.func,
  isOkDisabled: PropTypes.bool
}

export default GlobalForm
