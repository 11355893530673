import React from 'react'

export default function FolderClose(props) {
  return (
    <svg
      className="folder-close-icon"
      width="15px"
      height="13px"
      viewBox="0 0 15 13"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <defs />
      <g id="Components" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g id="Icons" transform="translate(-548.000000, -262.000000)">
          <g id="ic-folder-close" transform="translate(540.000000, 253.000000)">
            <g>
              <rect id="spacing" x={0} y={0} width={30} height={30} />
              <g id="icon" transform="translate(8.000000, 9.000000)" fill="currentColor">
                <rect
                  id="Rectangle"
                  transform="translate(0.500000, 6.500000) rotate(-270.000000) translate(-0.500000, -6.500000) "
                  x={-6}
                  y={6}
                  width={13}
                  height={1}
                />
                <rect
                  id="Rectangle-Copy-3"
                  transform="translate(14.500000, 8.000000) rotate(-270.000000) translate(-14.500000, -8.000000) "
                  x="9.5"
                  y="7.5"
                  width={10}
                  height={1}
                />
                <rect id="Rectangle-Copy" x={0} y={12} width={15} height={1} />
                <rect id="Rectangle-Copy-4" x={0} y={3} width={15} height={1} />
                <path
                  d="M1,4 L1,12 L14,12 L14,4 L1,4 Z M0,3 L15,3 L15,13 L0,13 L0,3 Z"
                  id="Rectangle-3"
                  fillRule="nonzero"
                />
                <polygon
                  id="Path-5"
                  fillRule="nonzero"
                  points="0 1 0 0 6.99484539 0 10.2260467 3.00984938 9.54444746 3.7415751 6.60125003 1"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
