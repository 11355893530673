import React, { useMemo, useState } from 'react'
import { Modal, Button, FormControl, Checkbox } from 'react-bootstrap'
import { countBy } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import joi from 'joi'
import { joiResolver } from '@hookform/resolvers/joi'

import { useForm, Controller } from 'react-hook-form'
import { validEmailTypes, permissions as PERMISSIONS, supraQCToolDefaults } from 'constants/constants'
import UserService from 'services/user-service'
import { convertTemplateToRegex } from 'components/SupraQCToolPage/Modals/useTemplateRegex'
import { closeAddEntriesToDictModal, addRuleToDictionary } from 'components/SupraQCToolPage/slices'
import Loader from 'components/Loader'
import InputField from 'components/FormInputComponents/InputField'

import 'components/SupraQCToolPage/style.scss'

const UNALLOWABLE_TEMPLATE_REGEX = supraQCToolDefaults.UNALLOWABLE_TEMPLATE_REGEX

function isValidSampleSubject({ sampleSubject, regex }) {
  if (!sampleSubject) {
    return { result: true }
  }

  const regExp = new RegExp(regex, 'i')

  if (!regExp.test(sampleSubject)) {
    return { result: false, error: 'Sample subject does not match the generated regex' }
  }

  return { result: true }
}

function isValidTemplate(value) {
  if (!value) {
    return { result: true }
  }

  if (value.trim().length < 10) {
    return { result: false, error: 'Template cannot have less than 10 characters' }
  }

  const numberOfWildCards = countBy(value)['_']
  if (numberOfWildCards > 5) {
    return { result: false, error: 'Template cannot have more than 5 wildcards' }
  }

  const hasTwoConsecutiveWildcards = value.match(/_[\s]*_/)
  if (hasTwoConsecutiveWildcards) {
    return { result: false, error: 'Template cannot have two consecutive wildcards ' }
  }

  return { result: true }
}

const AddEntriesToDictModal = () => {
  const dispatch = useDispatch()
  const { show, showAddToDictionaryLoader } = useSelector(state => state.supraQCToolV2.addEntriesToDictionaryModal)
  const session = useSelector(state => state.session)

  const [globalRegex, setGlobalRegex] = useState('')
  const [fromAddressState, setFromAddressState] = useState('')

  const userHasSupraQCEditPermission = useMemo(() => {
    return UserService.hasPermission(session.user, PERMISSIONS.supraQCToolEdit)
  }, [session])

  const validationSchema = joi.object({
    emailType: joi
      .string()
      .trim()
      .required(),

    useInTraining: joi.boolean(),

    template: joi
      .string()
      .trim()
      .required()
      .custom((value, helper) => {
        const validationResult = isValidTemplate(value)
        if (!validationResult.result) {
          return helper.message(validationResult.error)
        }
        return value
      }),

    sampleSubject: joi
      .string()
      .trim()
      .required()
      .custom((sampleSubject, helpers) => {
        const validationResult = isValidSampleSubject({ sampleSubject, regex: globalRegex })
        if (!validationResult.result) {
          return helpers.message(validationResult.error)
        }
        return sampleSubject
      }),

    regex: joi
      .string()
      .trim()
      .required(),

    fromAddr: joi
      .string()
      .trim()
      .email({ tlds: { allow: false } })
      .required(),
    domain: joi
      .string()
      .trim()
      .required()
      .custom((domain, helpers) => {
        const domainRegex = new RegExp(`\\b${domain}\\b`)
        if (!domainRegex.test(fromAddressState)) {
          return helpers.message(`From Address: ${fromAddressState} does not include domain name: ${domain}`)
        }
        return domain
      })
  })

  const {
    control,
    handleSubmit,
    formState: { isDirty, errors, isValid },
    reset,
    setValue
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      fromAddr: '',
      domain: '',
      template: '',
      emailType: 'ORDER',
      regex: '',
      useInTraining: false,
      sampleSubject: ''
    },
    resolver: joiResolver(validationSchema)
  })

  const closeModal = () => {
    dispatch(closeAddEntriesToDictModal())
  }

  const onSubmit = data => {
    const { regex: subject, template, useInTraining, emailType, fromAddr, domain, sampleSubject } = data
    dispatch(
      addRuleToDictionary({
        subject,
        template,
        useInTraining,
        emailType,
        fromAddr,
        domain,
        sampleSubject,
        originalTemplate: template
      })
    )
  }

  const handleFromAddressChange = event => {
    const fromAddr = event.target.value
    setValue('fromAddr', fromAddr, {
      shouldValidate: true
    })
    setFromAddressState(fromAddr)
  }

  const handleTemplateStringChange = event => {
    const template = event.target.value.replace(UNALLOWABLE_TEMPLATE_REGEX, '')
    setValue('template', template, {
      shouldValidate: true
    })
    const regex = convertTemplateToRegex(template)
    setValue('regex', regex, {
      shouldValidate: true
    })
    setGlobalRegex(regex)
  }

  const handleUseInTrainingChange = event => {
    const value = event.target.checked
    setValue('useInTraining', value, {
      shouldValidate: true
    })
  }

  return (
    <Modal backdrop="static" bsSize="large" show={show} onHide={closeModal}>
      <Modal.Header>
        <Modal.Title>Add to Dictionary</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <form className="mt-3 d-flex flex-column" onSubmit={handleSubmit(onSubmit)}>
          <InputField
            label="Type"
            required
            info="Select email type from the dropdown."
            className="mt-3 text-start"
            labelSize={3}
          >
            <Controller
              name="emailType"
              control={control}
              render={({ field }) => (
                <FormControl {...field} componentClass="select">
                  {validEmailTypes.map(type => (
                    <option key={type} value={type}>
                      {type}
                    </option>
                  ))}
                </FormControl>
              )}
            />
          </InputField>

          <InputField
            label="Template"
            required
            info="Enter the template string to generate regex. Allowed: English letters, numbers and special characters"
            className="text-start"
            labelSize={3}
            validationMessage={errors.template?.message}
          >
            <Controller
              required
              name="template"
              control={control}
              render={({ field }) => <FormControl {...field} onChange={handleTemplateStringChange} />}
            />
          </InputField>

          <InputField
            label="Regex"
            required
            info="generated regex will appear here"
            className="text-start"
            labelSize={3}
            validationMessage={errors.regex?.message}
          >
            <Controller
              required
              name="regex"
              control={control}
              render={({ field }) => <FormControl {...field} readOnly />}
            />
          </InputField>

          <InputField
            label="Sample Subject"
            required
            info="Test subject is mandatory to verify that the User created rule will actually match the intended target Subject Line. (This is to avoid User creating rules which do not match anything.)"
            className="text-start"
            labelSize={3}
            validationMessage={errors.sampleSubject?.message}
          >
            <Controller
              required
              name="sampleSubject"
              control={control}
              render={({ field }) => <FormControl {...field} />}
            />
          </InputField>

          <InputField
            label="Use In Training"
            required
            info="whether this rule should be used in training?"
            className="text-start"
            labelSize={3}
            fieldSize={1}
            validationMessage={errors.useInTraining?.message}
          >
            <Controller
              required
              name="useInTraining"
              control={control}
              render={({ field }) => (
                <Checkbox className="text-center" onChange={handleUseInTrainingChange} checked={field.value} />
              )}
            />
          </InputField>

          <InputField
            label="From Address"
            required
            info="Enter from address"
            className="text-start"
            labelSize={3}
            validationMessage={errors.fromAddr?.message}
          >
            <Controller
              required
              name="fromAddr"
              control={control}
              render={({ field }) => <FormControl {...field} onChange={handleFromAddressChange} />}
            />
          </InputField>

          <InputField
            label="Domain"
            required
            info="Select mapped URL domain (merchant)."
            className="text-start"
            labelSize={3}
            validationMessage={errors.domain?.message}
          >
            <Controller required name="domain" control={control} render={({ field }) => <FormControl {...field} />} />
          </InputField>

          <div className="mt-4 d-flex justify-content-center">
            {showAddToDictionaryLoader ? (
              <Loader height={40} />
            ) : (
              <>
                <Button onClick={closeModal}>Close</Button>

                <Button className="ml-2" onClick={() => reset()} disabled={!isDirty}>
                  Reset
                </Button>

                {userHasSupraQCEditPermission && (
                  <Button className="ml-2" type="submit" bsStyle="primary" disabled={!isDirty || (isDirty && !isValid)}>
                    Add to Dictionary
                  </Button>
                )}
              </>
            )}
          </div>
        </form>
      </Modal.Body>

      <Modal.Footer>
        <div>
          <ul className="text-danger">
            {Object.values(errors).map(errorObj => {
              return (
                <li className="text-left" key={errorObj.ref.name}>
                  {errorObj.message}
                </li>
              )
            })}
          </ul>
        </div>
      </Modal.Footer>
    </Modal>
  )
}

export default AddEntriesToDictModal
