/* eslint-disable eqeqeq */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import queryString from 'query-string'
import ReactInterval from 'react-interval'
import moment from 'moment'

import { Form, FormGroup } from 'react-bootstrap'
import Loader from '../../../Loader'
import DeliverySteps from './DeliverySteps'
import TextareaModal from '../../../Modals/TextareaModal'

import { createDeliveriesLinkUrl } from '../../../../utils/delivery-center'

import { confirmModal, addCustomModal } from '../../../../actions/modal-actions'
import {
  cancelDelivery,
  createNewDelivery,
  refreshDeliveryStatus,
  fetchCategoriesCleanupStatus,
  fetchCurrentDelivery
} from '../../../../actions/delivery-actions'

import './index.scss'

const DATE_FORMAT = 'MM/DD/YYYY'

class CurrentDeliveryPage extends Component {
  componentDidMount() {
    if (!this.props.currentDeliveryId) {
      this.props.fetchCurrentDelivery()
    }
  }

  componentDidUpdate(prevProps) {
    const { deliverableId, history, query } = this.props
    if (prevProps.deliverableId !== deliverableId && deliverableId === 'new') {
      history.replace(createDeliveriesLinkUrl())
    }

    if (query.locateDelivery) {
      this.redirectToDeliveryLocation()
    }
  }

  cancelDelivery = () => {
    const { addCustomModal, cancelDelivery, currentDeliveryId } = this.props

    const onSubmit = inputValue => {
      cancelDelivery({
        deliveryId: currentDeliveryId,
        notes: inputValue
      })
    }

    addCustomModal({
      modalType: 'errorModal',
      id: 'cancel-delivery-modal',
      className: 'textarea-modal',
      title: 'Cancel Current Delivery',
      content: (
        <TextareaModal
          onSubmit={onSubmit}
          subtitle={'Please enter reason for cancellation below.'}
          submitText={'Cancel Delivery'}
          cancelText={'Back'}
        />
      )
    })
  }

  createNewDelivery = (deadlineDate, title, content) => {
    const { deliverableId, confirmModal, createNewDelivery } = this.props
    const callback = () =>
      createNewDelivery({
        deliverableId,
        deadlineDate
      })
    confirmModal(content, title, { okButton: 'Yes' }, callback)
  }

  refreshDelivery = fullRefresh => {
    const {
      currentDeliveryId,
      refreshDeliveryStatus,
      categoryIds,
      currentDelivery,
      fetchCategoriesCleanupStatus
    } = this.props
    refreshDeliveryStatus(currentDeliveryId)
    if (fullRefresh) {
      fetchCategoriesCleanupStatus({
        categoryIds,
        deliveryDate: currentDelivery.deliveryDate
      })
    }
  }

  renderRedilverButton = () => {
    const {
      currentDelivery: { deliveryDate },
      loading
    } = this.props
    if (!deliveryDate) {
      return null
    }
    const date = moment.utc(deliveryDate).format(DATE_FORMAT)
    const title = 'Confirm Re-delivery'
    const message = (
      <span>
        {'Are you sure you want to restart current delivery scheduled for '}
        <strong>{date}</strong>
        {' ?'}
      </span>
    )
    return (
      <Form className="redeliver-form">
        <FormGroup className="page-message">{`To redeliver previous delivery click below `}</FormGroup>
        <FormGroup>
          <button
            type="button"
            className={'btn_custom_secondary btn_medium'}
            onClick={() => {
              this.createNewDelivery(date, title, message)
            }}
            disabled={loading}
          >
            Redeliver
          </button>
        </FormGroup>
      </Form>
    )
  }

  renderNextDeliveryButton = () => {
    const { nextDelivery, loading } = this.props
    if (!nextDelivery) {
      return 'No next delivery date found for the deliverable.'
    }
    const nextDeliveryDate = moment.utc(nextDelivery).format(DATE_FORMAT)
    const nextStartDate = moment
      .utc(nextDelivery)
      .startOf('month')
      .format(DATE_FORMAT)
    const title = 'Confirm Start Delivery'
    const message = (
      <span>
        {'Are you sure you want to manually start a new delivery scheduled for '}
        <strong>{nextDeliveryDate}</strong>
        {' ?'}
      </span>
    )
    return (
      <Form className="redeliver-form">
        <FormGroup className="page-message">
          {`The next delivery is scheduled for `}
          <strong>{nextDeliveryDate}</strong>
          {`. It should automatically start on `}
          <strong>{nextStartDate}</strong>
          {'. You can click below to start it right now.'}
        </FormGroup>
        <FormGroup>
          <button
            type="button"
            className={'btn_custom btn_medium'}
            onClick={() => {
              this.createNewDelivery(nextDeliveryDate, title, message)
            }}
            disabled={loading}
          >
            Start Next Delivery
          </button>
        </FormGroup>
      </Form>
    )
  }

  renderProcessingDeliveryActions = () => {
    const { loading } = this.props
    return (
      <Form className="redeliver-form">
        <FormGroup>
          <button
            type="button"
            className={'btn_custom_bg_red btn_custom btn_medium'}
            onClick={this.cancelDelivery}
            disabled={loading}
          >
            Cancel Delivery
          </button>
          <button
            type="button"
            className={'btn_custom btn_custom btn_medium'}
            onClick={() => this.refreshDelivery(true)}
            disabled={loading}
          >
            Refresh
          </button>
        </FormGroup>
      </Form>
    )
  }

  renderDelivery() {
    const { deliveryCompleted } = this.props
    return (
      <React.Fragment>
        <DeliverySteps isDeliveryComplete={deliveryCompleted} />
        {deliveryCompleted && this.renderRedilverButton()}
        {deliveryCompleted && this.renderNextDeliveryButton()}
        {!deliveryCompleted && this.renderProcessingDeliveryActions()}
        {this.shouldAutoRefreshDeliveryStatus() && (
          <ReactInterval timeout={2000} enabled callback={() => this.refreshDelivery(false)} />
        )}
      </React.Fragment>
    )
  }

  shouldAutoRefreshDeliveryStatus = () => {
    const {
      currentDelivery: { qcStatus, categoryCleanupStatus, dataCustomization = {}, qc = {}, prod = {} } = {},
      deliveryCompleted
    } = this.props
    if (
      !deliveryCompleted &&
      ((categoryCleanupStatus === 'SKIPPED' && dataCustomization.status === 'PENDING') ||
        dataCustomization.status === 'PROCESSING' ||
        qc.status === 'PROCESSING' ||
        (!qcStatus && ['SKIPPED', 'COMPLETED'].includes(categoryCleanupStatus)) ||
        (qcStatus === 'APPROVED' && prod.status === 'PENDING') ||
        prod.status === 'PROCESSING')
    ) {
      return true
    }
    return false
  }

  getNextDeliveryDates() {
    const { nextDelivery } = this.props
    const dates = {}
    try {
      dates.deliveryDate = moment(nextDelivery)
    } catch (err) {
      console.error(err)
    }
    return dates
  }

  renderConfigPendingMsg() {
    const { deliverableName, deliverableId } = this.props
    return (
      <div className="page-message">
        Please complete all
        <Link to={createDeliveriesLinkUrl({ deliverableId, deliverableName }, 'config')}>{' configuration '}</Link>
        steps for the Delivery to proceed properly.
      </div>
    )
  }

  redirectToDeliveryLocation = () => {
    const {
      loading,
      deliverableId,
      deliverableName,
      currentDelivery,
      history: { replace },
      query: { locateDelivery, deliveryId }
    } = this.props

    if (!loading && deliverableId && locateDelivery) {
      const deliverable = {
        id: deliverableId,
        name: deliverableName
      }

      if (
        currentDelivery &&
        // ID oneOf number/string
        (currentDelivery.id != deliveryId || currentDelivery.status === 'CANCELLED')
      ) {
        // navigate to past deliveries
        replace(createDeliveriesLinkUrl(deliverable, 'past'))
      } else {
        // trim query string
        replace(createDeliveriesLinkUrl(deliverable, 'current'))
      }
    }
  }

  render() {
    const { deliverableConfigured, loading, currentDelivery } = this.props

    return (
      <div className="current-delivery-page">
        {loading && <Loader overlap />}
        {deliverableConfigured === false && this.renderConfigPendingMsg()}
        {currentDelivery && this.renderDelivery()}
        {deliverableConfigured && !currentDelivery && !loading && this.renderNextDeliveryButton()}
      </div>
    )
  }
}

CurrentDeliveryPage.propTypes = {
  deliverableId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  deliverableConfigured: PropTypes.bool,
  deliveryCompleted: PropTypes.bool,
  deliverableName: PropTypes.string,
  nextDelivery: PropTypes.string,
  currentDeliveryId: PropTypes.number,
  categoryIds: PropTypes.array,
  loading: PropTypes.bool,
  currentDelivery: PropTypes.object,
  fetchCurrentDelivery: PropTypes.func,
  history: PropTypes.object.isRequired,
  confirmModal: PropTypes.func,
  createNewDelivery: PropTypes.func,
  refreshDeliveryStatus: PropTypes.func,
  fetchCategoriesCleanupStatus: PropTypes.func,
  addCustomModal: PropTypes.func,
  cancelDelivery: PropTypes.func,
  query: PropTypes.object
}

function mapStateToProps({ delivery: { loading, currentDelivery }, deliveryConfig: { deliverableData } }, ownProps) {
  const { name: deliverableName, nextDelivery, categories = [] } = deliverableData || {}
  const { status, id: currentDeliveryId } = currentDelivery || {}
  return {
    deliverableName,
    currentDeliveryId,
    nextDelivery,
    loading,
    currentDelivery,
    deliveryCompleted: ['COMPLETED', 'DELIVERED', 'CANCELLED'].includes(status),
    categoryIds: categories.map(({ deliveryCategoryId }) => deliveryCategoryId),
    query: ownProps.location && ownProps.location.search ? queryString.parse(ownProps.location.search) : {}
  }
}

export default connect(mapStateToProps, {
  confirmModal,
  addCustomModal,
  cancelDelivery,
  createNewDelivery,
  refreshDeliveryStatus,
  fetchCategoriesCleanupStatus,
  fetchCurrentDelivery
})(CurrentDeliveryPage)
