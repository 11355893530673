import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Grid, Row, Col } from 'react-bootstrap'
import { BootstrapTable } from 'react-bootstrap-table'
import PaginationControls from './../PaginationControls'

import ColumnsConfigButton from './ColumnsConfig/ColumnsConfigButton'

export default class DataTable extends Component {
  getOptions = () => {
    return {
      sortName: this.props.sortName,
      sortOrder: this.props.sortOrder,
      onSortChange: this.props.onSortChange,
      onFilterChange: this.props.onFilterChange
    }
  }

  render() {
    const {
      page = 1,
      data,
      maxPages,
      trClassName,
      columns,
      onPageChange,
      tableClassName,
      keyBoardNav,
      totalCount,
      entityName,
      id,
      columnsConfig
    } = this.props

    return (
      <div>
        <BootstrapTable
          remote
          hover
          data={data}
          bordered={false}
          trClassName={trClassName}
          keyBoardNav={keyBoardNav}
          className={tableClassName}
          options={this.getOptions()}
          tableContainerClass="sb-table"
          ref={el => {
            this.table = el
          }}
        >
          {columns}
        </BootstrapTable>
        <Grid>
          <Row>
            <Col sm={6}>
              <PaginationControls
                entityName={entityName}
                totalCount={totalCount}
                page={page}
                maxPages={maxPages}
                onPageChange={onPageChange}
              />
            </Col>
            {columnsConfig && (
              <Col sm={6} style={{ paddingTop: '9px' }}>
                <ColumnsConfigButton columns={columnsConfig} tableId={id} />
              </Col>
            )}
          </Row>
        </Grid>
      </div>
    )
  }
}

DataTable.defaultProps = {
  keyBoardNav: true
}

DataTable.propTypes = {
  id: PropTypes.string,
  tableClassName: PropTypes.string,
  page: PropTypes.number,
  maxPages: PropTypes.number,
  sortName: PropTypes.string,
  sortOrder: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.object),
  onSortChange: PropTypes.func,
  onFilterChange: PropTypes.func,
  onPageChange: PropTypes.func,
  trClassName: PropTypes.func,
  keyBoardNav: PropTypes.bool.isRequired,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  columnsConfig: PropTypes.object,
  totalCount: PropTypes.number,
  entityName: PropTypes.string
}
