import React from 'react'

export default function useCategoryBrandPathMessage(categoryId, ruleCBP, ruleCBPLoading, error) {
  let message = null
  let style = null

  const existRuleCBPSameNode = ruleCBP && ruleCBP.isSameNode
  const existRuleCBPNoSameNode = ruleCBP && !ruleCBP.isSameNode
  const isNoRuleCBP = ruleCBP === null

  if (categoryId) {
    if (existRuleCBPSameNode) {
      message = null
    } else if (existRuleCBPNoSameNode) {
      message = 'No Rules exist on this Category path; new rules on this path will override inherited rules.'
      style = 'danger'
    } else if (isNoRuleCBP) {
      message = 'No Rules exist for this category.'
      style = 'warning'
    }
  }

  let categoryBrandPathInfo = <i>choose a category ...</i>
  if (error) {
    categoryBrandPathInfo = <b className="text-color-red">Server Error: {error?.data?.error}</b>
  } else if (ruleCBPLoading) {
    categoryBrandPathInfo = <i>loading ...</i>
  } else if (categoryId && !ruleCBPLoading && ruleCBP) {
    categoryBrandPathInfo = (
      <span>
        <b>{ruleCBP.rcbCategoryFullPath}</b> (ID:{ruleCBP.rcbCategoryId}) has {ruleCBP.rcbBrndCount} Brand rules.
      </span>
    )
  }

  return {
    categoryBrandPathMessage: message,
    categoryBrandPathStyle: style,
    categoryBrandPathInfo
  }
}
