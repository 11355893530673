import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { cloneDeep } from 'lodash'

import Loader from '../../Loader'
import { DataTableFiltered } from './../../DataTable'

import CommonRulesToolbar from './../Common/CommonRulesToolbar'

import MerchantBrandActionsCell from './MerchantBrandActionsCell'
import MerchantBrandRuleModal from './Modals/MerchantBrandRuleModal'

import { MerchantBrandRulesActions } from './../actions'

import COMMON_COLUMNS from './../Common/commonColumns'
import { STORE_PATH } from './../constants'

class RulesMerchantBrand extends Component {
  constructor(props) {
    super(props)

    this.columns = this.getColumns(cloneDeep(COMMON_COLUMNS))
  }

  getColumns(commonColumns) {
    const columns = [
      commonColumns.id,
      commonColumns.merchantId,
      commonColumns.merchantName,
      commonColumns.brandId,
      commonColumns.brandFullPath,
      commonColumns.updatedAt,
      commonColumns.updatedByName,
      commonColumns.isActive,
      {
        ...commonColumns.actionsColumn,
        dataFormat: (cell, row) => <MerchantBrandActionsCell row={row} />
      }
    ]

    return columns
  }

  render() {
    const {
      loading,
      pageSize,
      hasEditAccess,
      totalCount,
      page,
      rows,
      sortBy,
      sortOrder,
      filters,
      fetchRulesAction,
      downloadRulesAction,
      clearFilters,
      saveRuleAction
    } = this.props

    return (
      <React.Fragment>
        <CommonRulesToolbar
          hasEditAccess={hasEditAccess}
          toolbarType={'Merchant Brand'}
          createRuleModalContet={<MerchantBrandRuleModal onOkHandler={saveRuleAction} />}
          downloadRulesAction={downloadRulesAction}
          refreshHandler={fetchRulesAction}
          clearFilters={clearFilters}
        />

        {loading && <Loader loading overlap />}

        <DataTableFiltered
          data={rows}
          columns={this.columns}
          page={page}
          pageSize={pageSize}
          totalCount={totalCount}
          sortBy={sortBy}
          sortOrder={sortOrder}
          filters={filters}
          tableClassName="rules-table"
          fetchRowsAction={fetchRulesAction}
        />
      </React.Fragment>
    )
  }
}

RulesMerchantBrand.defaultProps = {
  rows: [],
  totalCount: 0
}

RulesMerchantBrand.propTypes = {
  page: PropTypes.number,
  pageSize: PropTypes.number,
  sortBy: PropTypes.string,
  sortOrder: PropTypes.string,
  filters: PropTypes.object,
  loading: PropTypes.bool.isRequired,
  hasEditAccess: PropTypes.bool.isRequired,
  totalCount: PropTypes.number.isRequired,
  rows: PropTypes.arrayOf(PropTypes.object).isRequired,
  fetchRulesAction: PropTypes.func.isRequired,
  clearFilters: PropTypes.func.isRequired,
  saveRuleAction: PropTypes.func.isRequired,
  downloadRulesAction: PropTypes.func.isRequired
}

const mapStateToProps = ({ rules: { [STORE_PATH.merchantBrand]: merchantBrand } }) => ({
  page: merchantBrand.page,
  sortBy: merchantBrand.sortBy,
  loading: merchantBrand.loading,
  rows: merchantBrand.data.rows,
  pageSize: merchantBrand.pageSize,
  sortOrder: merchantBrand.sortOrder,
  totalCount: merchantBrand.data.count,
  filters: merchantBrand.filters
})

const mapDispatchToProps = {
  fetchRulesAction: MerchantBrandRulesActions.fetch,
  downloadRulesAction: MerchantBrandRulesActions.download,
  clearFilters: MerchantBrandRulesActions.clearFilters,
  saveRuleAction: MerchantBrandRulesActions.save
}

export default connect(mapStateToProps, mapDispatchToProps)(RulesMerchantBrand)
