import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import QueryBuilderTree from './QueryBuilderTree'
import {
  updateIncExcQueryTree,
  emptyIncExcQueryTree,
  createIncExcQueryTree,
  removeIncExcQueryTree,
  revertIncExcQueryTree,
  fetchIncExcTypeChildren,
  createIncExcMultipleRules
} from '../../../../../actions/delivery-config-actions'
import Loader from '../../../../Loader'
import { getQuerySQL } from '../../../../../actions/search-actions'
import { createAlert } from '../../../../../actions/app-actions'
import { addCustomModal } from '../../../../../actions/modal-actions'
import CopyToClipboardModal from '../../../../../components/Modals/CopyToClipboardModal'

class IncExcQuery extends Component {
  componentDidUpdate(prevProps) {
    if (prevProps.querySQLLoading === true && this.props.querySQLLoading === false && this.props.querySQL) {
      this.openCopyModal(this.props.querySQL)
    }
  }

  openCopyModal = sql => {
    this.props.addCustomModal({
      modalType: 'successModal',
      id: 'copy-to-clipboard-modal',
      className: 'textarea-modal',
      title: 'Query SQL',
      content: (
        <CopyToClipboardModal
          note="Note: Rules with contains, not contains, contains (stem) and not contains (stem) have been evaluated as if they are wildcard rules."
          text={sql}
          onCopy={this.onCopy}
        />
      )
    })
  }

  onCopy = (text, result) => {
    const type = result ? 'info' : 'danger'
    const title = result ? 'SQL copied to clipboard' : 'There was an error trying to copy the SQL'
    this.props.createAlert(type, '', title)
  }

  showQuerySQLModal = async query => {
    await this.props.getQuerySQL(query)
  }

  render() {
    const {
      queryTree,
      updateIncExcQueryTree,
      emptyIncExcQueryTree,
      createIncExcQueryTree,
      removeIncExcQueryTree,
      revertIncExcQueryTree,
      fetchIncExcTypeChildren,
      createIncExcMultipleRules,
      dirty,
      editable,
      loading
    } = this.props
    return (
      <React.Fragment>
        {loading && <Loader overlap />}
        {editable && (
          <div className="inc-exc-qt">
            {!queryTree && (
              <React.Fragment>
                <button type="button" className={'btn_custom btn_medium pull-right'} onClick={createIncExcQueryTree}>
                  Create Query
                </button>
              </React.Fragment>
            )}
            {queryTree && (
              <button
                type="button"
                className={'btn_custom_bg_red btn_custom btn_medium pull-right'}
                onClick={removeIncExcQueryTree}
              >
                Remove Query
              </button>
            )}
            {dirty && (
              <button
                type="button"
                className={'btn_custom_secondary btn_medium pull-right'}
                disabled={!dirty}
                onClick={revertIncExcQueryTree}
              >
                Revert Changes
              </button>
            )}
          </div>
        )}
        {queryTree && (
          <QueryBuilderTree
            createMultipleRules={createIncExcMultipleRules}
            fetchTypeChildren={fetchIncExcTypeChildren}
            handleQueryTreeChanged={updateIncExcQueryTree}
            clearQueryTree={emptyIncExcQueryTree}
            queryTree={queryTree}
            editable={editable}
            showQuerySQLModal={() => this.showQuerySQLModal(queryTree)}
          />
        )}
      </React.Fragment>
    )
  }
}

IncExcQuery.propTypes = {
  queryTree: PropTypes.object,
  updateIncExcQueryTree: PropTypes.func,
  emptyIncExcQueryTree: PropTypes.func,
  createIncExcQueryTree: PropTypes.func,
  removeIncExcQueryTree: PropTypes.func,
  dirty: PropTypes.bool,
  revertIncExcQueryTree: PropTypes.func,
  action: PropTypes.string,
  fetchIncExcTypeChildren: PropTypes.func,
  createIncExcMultipleRules: PropTypes.func,
  editable: PropTypes.bool,
  loading: PropTypes.bool,
  getQuerySQL: PropTypes.func,
  createAlert: PropTypes.func,
  querySQL: PropTypes.string,
  addCustomModal: PropTypes.func,
  querySQLLoading: PropTypes.bool
}

function mapStateToProps({ search: { querySQL, querySQLLoading }, deliveryConfig: { incExcForm } }) {
  return {
    querySQL,
    querySQLLoading,
    queryTree: incExcForm.queryTree,
    dirty: incExcForm.dirty,
    action: incExcForm.action,
    loading: incExcForm.loading
  }
}

export default connect(mapStateToProps, {
  updateIncExcQueryTree,
  emptyIncExcQueryTree,
  createIncExcQueryTree,
  removeIncExcQueryTree,
  revertIncExcQueryTree,
  fetchIncExcTypeChildren,
  createIncExcMultipleRules,
  getQuerySQL,
  createAlert,
  addCustomModal
})(IncExcQuery)
