import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import Loader from '../../Loader'
import { fetchFeedModuleQueriesAction, setOpenNewFeedModuleQueryAction } from './actions'
import { Button } from 'react-bootstrap'

import { getMissedSqlNames } from './selectors'
import FeedModuleQueryItem from './FeedModuleQueryItem'

class FeedModuleQueries extends Component {
  componentDidMount() {
    this.fetchFeedModuleQueriesAction(this.props.feedModuleId)
  }

  fetchFeedModuleQueriesAction = feedModuleId => {
    this.props.fetchFeedModuleQueriesAction({ feedModuleId })
  }

  onAddQuery = () => {
    this.props.setOpenNewFeedModuleQueryAction(true)
  }

  render() {
    const { loading, queries, feedModuleId, isOpenAddNewQuery, missedSqlNames } = this.props

    const allQueries = [...queries]

    if (isOpenAddNewQuery) {
      const newQuery = { feedModuleId }
      allQueries.push(newQuery)
    }

    return (
      <div>
        <hr />
        {loading && <Loader loading overlap />}

        <div className="h3">SQL Queries</div>

        {allQueries.map((query, index) => {
          return (
            <FeedModuleQueryItem
              query={query}
              key={query.name || `key+${index}`}
              isMissed={missedSqlNames.includes(query.name)}
              queries={allQueries}
            />
          )
        })}

        <div className="text-center">
          <Button className="btn_custom" onClick={this.onAddQuery}>
            Add new Query
          </Button>
        </div>
      </div>
    )
  }
}

FeedModuleQueries.defaultProps = {
  queries: [],
  missedSqlNames: [],
  isOpenAddNewQuery: false
}

FeedModuleQueries.propTypes = {
  loading: PropTypes.bool.isRequired,
  queries: PropTypes.arrayOf(PropTypes.object),
  missedSqlNames: PropTypes.arrayOf(PropTypes.string),
  isOpenAddNewQuery: PropTypes.bool.isRequired,
  fetchFeedModuleQueriesAction: PropTypes.func.isRequired,
  setOpenNewFeedModuleQueryAction: PropTypes.func.isRequired,
  feedModuleId: PropTypes.string.isRequired
}

const mapStateToProps = state => ({
  loading: state.feedModules.queryLoading,
  queries: state.feedModules.queries,
  isOpenAddNewQuery: state.feedModules.isOpenAddNewQuery,
  missedSqlNames: getMissedSqlNames(state)
})

const mapDispatchToProps = {
  fetchFeedModuleQueriesAction,
  setOpenNewFeedModuleQueryAction
}

export default connect(mapStateToProps, mapDispatchToProps)(FeedModuleQueries)
