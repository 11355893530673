import { useLocation } from 'react-router-dom'

/**
 * This hook returns the base path without possible latest path parts
 * For example path: '/cme/delivery-jobs/tasks' and tab path is "tasks"
 * so basePath='/cme/delivery-jobs/'
 * @param {string[]} ignoredParts - array with possible last path parts that we should skip
 * @return {string} base pathname without ignoredParts in the end
 */
const useBasePath = (ignoredParts = []) => {
  const location = useLocation()
  const basePath = getBasePath(location.pathname, ignoredParts)
  return basePath
}

/**
 * Get Base path
 * @param {string} path - location path
 * @param {string[]} ignoredParts - array with last path parts
 * @returns {string} base path
 */
const getBasePath = (path, ignoredParts = []) => {
  const pathArray = path.split('/')

  const lastPathPart = pathArray.slice(-1)[0]
  const isIgnoredPart = ignoredParts.includes(lastPathPart)

  if (isIgnoredPart) {
    pathArray.pop() // remove the last element
  }
  const basePath = pathArray.join('/')

  return basePath + '/'
}

export { getBasePath }

export default useBasePath
