import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { Form, Button } from 'react-bootstrap'

import { clearModals } from 'actions/modal-actions'
import { useFormValues } from 'components/FormInputComponents/InputField'

import { CONFIG_TOOLTIP } from './../constants'
import categoryTestsActions from './../../categoryTestsActions'

import FieldsGroup from './../FieldsGroup'
import IsActiveField, { useIsActiveField } from './IsActiveField'
import ThresholdField, { useThresholdField } from './../ThresholdField'
import QueryField, { useQueryField } from './../QueryField'

import ResetWarning from './ResetWarning'
import ConfigurationSelector from './ConfigurationSelector'

import { rankValidation } from './../validation'

const CategoryTestEditModal = ({ value: valuesInit }) => {
  const dispatch = useDispatch()

  const {
    id: categoryId,

    isAutoSubmit,

    dateTestIsActive,

    dictionaryTestIsActive,
    dictionaryTestThreshold,

    includeTestIsActive,
    includeTestThreshold,
    includeTestQueryId = null,

    excludeTestIsActive,
    excludeTestThreshold,
    excludeTestQueryId = null,

    misbrandTestIsActive,
    misbrandTestThreshold,
    misbrandTestQueryId = null,

    coverageTestIsActive,
    coverageTestThreshold,

    newBrandTestIsActive,
    newBrandTestThreshold,
    newBrandTestQueryId = null,

    newBrandMonthTestIsActive,
    newBrandMonthTestThreshold,

    merchantTrendTestIsActive,
    merchantTrendTestThreshold,
    merchantTrendTestSensitivity,
    merchantTrendTestRank,

    brandTrendTestIsActive,
    brandTrendTestThreshold,
    brandTrendTestSensitivity,
    brandTrendTestRank,

    overallTrendTestIsActive,
    overallTrendTestThreshold,
    overallTrendTestSensitivity,

    isUseLocalConfig = false
  } = valuesInit

  const { values, isFormChanged, handleFieldChange, updateValue } = useFormValues({
    isAutoSubmit,

    dateTestIsActive,

    dictionaryTestIsActive,
    dictionaryTestThreshold,

    includeTestIsActive,
    includeTestThreshold,
    includeTestQueryId,

    excludeTestIsActive,
    excludeTestThreshold,
    excludeTestQueryId,

    misbrandTestIsActive,
    misbrandTestThreshold,
    misbrandTestQueryId,

    coverageTestIsActive,
    coverageTestThreshold,

    newBrandTestIsActive,
    newBrandTestThreshold,
    newBrandTestQueryId,

    newBrandMonthTestIsActive,
    newBrandMonthTestThreshold,

    merchantTrendTestIsActive,
    merchantTrendTestThreshold,
    merchantTrendTestSensitivity,
    merchantTrendTestRank,

    brandTrendTestIsActive,
    brandTrendTestThreshold,
    brandTrendTestSensitivity,
    brandTrendTestRank,

    overallTrendTestIsActive,
    overallTrendTestThreshold,
    overallTrendTestSensitivity,

    isUseLocalConfig
  })

  let valuesForSave = {}
  const setValueForSave = payload => (valuesForSave = { ...valuesForSave, ...payload })

  const [errors, setErrors] = useState({})
  const setError = payload => setErrors(state => ({ ...state, ...payload }))

  const [registerIsActiveField] = useIsActiveField({
    isUseLocalConfig: values.isUseLocalConfig,
    setValueForSave,
    updateValue
  })

  const [registerThresholdField] = useThresholdField({
    isUseLocalConfig: values.isUseLocalConfig,
    setValueForSave,
    setError,
    handleFieldChange
  })

  const [registerQueryField] = useQueryField({
    isUseLocalConfig: values.isUseLocalConfig,
    setValueForSave,
    updateValue
  })

  const isError = Object.values(errors).some(e => e !== null)

  /** Modal actions **/

  const closeModal = () => dispatch(clearModals())

  const handleOkButton = () => {
    const payload = {
      categoryId,
      ...values,
      ...valuesForSave
    }

    dispatch(categoryTestsActions.save(payload))
    closeModal()
  }

  const handleRejectButton = () => closeModal()
  /** ***************** */

  return (
    <Form className="text-left" horizontal>
      <div className="category-tests-modal-form-body">
        <ConfigurationSelector isUseLocalConfig={values.isUseLocalConfig} onChange={updateValue} />

        <FieldsGroup label="Date Test" info={CONFIG_TOOLTIP.DATE}>
          <IsActiveField
            {...registerIsActiveField({
              id: 'dateTestIsActive',
              isActive: values.dateTestIsActive,
              isActiveGlobal: valuesInit.dateTestIsActiveGlobal
            })}
          />
        </FieldsGroup>

        <FieldsGroup label="Dictionary Test" info={CONFIG_TOOLTIP.DICTIONARY}>
          <IsActiveField
            {...registerIsActiveField({
              id: 'dictionaryTestIsActive',
              isActive: values.dictionaryTestIsActive,
              isActiveGlobal: valuesInit.dictionaryTestIsActiveGlobal
            })}
          />
          <ThresholdField
            {...registerThresholdField({
              id: 'dictionaryTestThreshold',
              value: values.dictionaryTestThreshold,
              valueGlobal: valuesInit.dictionaryTestThresholdGlobal,
              isActive: values.dictionaryTestIsActive
            })}
          />
        </FieldsGroup>

        <FieldsGroup label="Include Test" info={CONFIG_TOOLTIP.INCLUDE}>
          <IsActiveField
            {...registerIsActiveField({
              id: 'includeTestIsActive',
              isActive: values.includeTestIsActive,
              isActiveGlobal: valuesInit.includeTestIsActiveGlobal
            })}
          />
          <ThresholdField
            {...registerThresholdField({
              id: 'includeTestThreshold',
              value: values.includeTestThreshold,
              valueGlobal: valuesInit.includeTestThresholdGlobal,
              isActive: values.includeTestIsActive
            })}
          />
          <QueryField
            groupQueryId={valuesInit.includeTestGroupQueryId}
            groupQueryName={valuesInit.includeTestGroupQueryName}
            queryName={valuesInit.includeTestQueryName}
            {...registerQueryField({
              id: 'includeTestQueryId',
              onChange: updateValue,
              queryId: values.includeTestQueryId,
              isActive: values.includeTestIsActive,
              isActiveGlobal: valuesInit.includeTestIsActiveGlobal
            })}
          />
        </FieldsGroup>

        <FieldsGroup label="Exclude Test" info={CONFIG_TOOLTIP.EXCLUDE}>
          <IsActiveField
            {...registerIsActiveField({
              id: 'excludeTestIsActive',
              isActive: values.excludeTestIsActive,
              isActiveGlobal: valuesInit.excludeTestIsActiveGlobal
            })}
          />
          <ThresholdField
            {...registerThresholdField({
              id: 'excludeTestThreshold',
              value: values.excludeTestThreshold,
              valueGlobal: valuesInit.excludeTestThresholdGlobal,
              isActive: values.excludeTestIsActive
            })}
          />
          <QueryField
            groupQueryId={valuesInit.excludeTestGroupQueryId}
            groupQueryName={valuesInit.excludeTestGroupQueryName}
            queryName={valuesInit.excludeTestQueryName}
            {...registerQueryField({
              id: 'excludeTestQueryId',
              onChange: updateValue,
              queryId: values.excludeTestQueryId,
              isActive: values.excludeTestIsActive,
              isActiveGlobal: valuesInit.excludeTestIsActiveGlobal
            })}
          />
        </FieldsGroup>

        <FieldsGroup label="Misbrand Test" info={CONFIG_TOOLTIP.MISBRAND}>
          <IsActiveField
            {...registerIsActiveField({
              id: 'misbrandTestIsActive',
              isActive: values.misbrandTestIsActive,
              isActiveGlobal: valuesInit.misbrandTestIsActiveGlobal
            })}
          />
          <ThresholdField
            {...registerThresholdField({
              id: 'misbrandTestThreshold',
              value: values.misbrandTestThreshold,
              valueGlobal: valuesInit.misbrandTestThresholdGlobal,
              isActive: values.misbrandTestIsActive
            })}
          />
          <QueryField
            groupQueryId={valuesInit.misbrandTestGroupQueryId}
            groupQueryName={valuesInit.misbrandTestGroupQueryName}
            queryName={valuesInit.misbrandTestQueryName}
            {...registerQueryField({
              id: 'misbrandTestQueryId',
              onChange: updateValue,
              queryId: values.misbrandTestQueryId,
              isActive: values.misbrandTestIsActive,
              isActiveGlobal: valuesInit.misbrandTestIsActiveGlobal
            })}
          />
        </FieldsGroup>

        <FieldsGroup label="Coverage Test" info={CONFIG_TOOLTIP.COVERAGE}>
          <IsActiveField
            {...registerIsActiveField({
              id: 'coverageTestIsActive',
              isActive: values.coverageTestIsActive,
              isActiveGlobal: valuesInit.coverageTestIsActiveGlobal
            })}
          />
          <ThresholdField
            {...registerThresholdField({
              id: 'coverageTestThreshold',
              value: values.coverageTestThreshold,
              valueGlobal: valuesInit.coverageTestThresholdGlobal,
              isActive: values.coverageTestIsActive
            })}
          />
          <span>
            <i>Query options inherits from Include test</i>
          </span>
        </FieldsGroup>

        <FieldsGroup label="New Brand Test" info={CONFIG_TOOLTIP.NEW_BRAND}>
          <IsActiveField
            {...registerIsActiveField({
              id: 'newBrandTestIsActive',
              isActive: values.newBrandTestIsActive,
              isActiveGlobal: valuesInit.newBrandTestIsActiveGlobal
            })}
          />
          <ThresholdField
            {...registerThresholdField({
              id: 'newBrandTestThreshold',
              value: values.newBrandTestThreshold,
              valueGlobal: valuesInit.newBrandTestThresholdGlobal,
              isActive: values.newBrandTestIsActive
            })}
          />
          <QueryField
            groupQueryId={valuesInit.newBrandTestGroupQueryId}
            groupQueryName={valuesInit.newBrandTestGroupQueryName}
            queryName={valuesInit.newBrandTestQueryName}
            {...registerQueryField({
              id: 'newBrandTestQueryId',
              onChange: updateValue,
              queryId: values.newBrandTestQueryId,
              isActive: values.newBrandTestIsActive,
              isActiveGlobal: valuesInit.newBrandTestIsActiveGlobal
            })}
          />
        </FieldsGroup>

        <FieldsGroup label="New Brand 3 months Test" info={CONFIG_TOOLTIP.NEW_BRAND_3_MONTHS}>
          <IsActiveField
            {...registerIsActiveField({
              id: 'newBrandMonthTestIsActive',
              isActive: values.newBrandMonthTestIsActive,
              isActiveGlobal: valuesInit.newBrandMonthTestIsActiveGlobal
            })}
          />
          <ThresholdField
            {...registerThresholdField({
              id: 'newBrandMonthTestThreshold',
              value: values.newBrandMonthTestThreshold,
              valueGlobal: valuesInit.newBrandMonthTestThresholdGlobal,
              isActive: values.newBrandMonthTestIsActive
            })}
          />
          <span>
            <i>Query options inherits from New Brand Test</i>
          </span>
        </FieldsGroup>

        <FieldsGroup label="Merchant Trend Test" info={CONFIG_TOOLTIP.MERCHANT_TREND}>
          <IsActiveField
            {...registerIsActiveField({
              id: 'merchantTrendTestIsActive',
              isActive: values.merchantTrendTestIsActive,
              isActiveGlobal: valuesInit.merchantTrendTestIsActiveGlobal
            })}
          />
          <ThresholdField
            {...registerThresholdField({
              id: 'merchantTrendTestThreshold',
              value: values.merchantTrendTestThreshold,
              valueGlobal: valuesInit.merchantTrendTestThresholdGlobal,
              isActive: values.merchantTrendTestIsActive
            })}
          />
          <ThresholdField
            label="Sensitivity"
            {...registerThresholdField({
              id: 'merchantTrendTestSensitivity',
              value: values.merchantTrendTestSensitivity,
              valueGlobal: valuesInit.merchantTrendTestSensitivityGlobal,
              isActive: values.merchantTrendTestIsActive
            })}
          />
          <ThresholdField
            label="Rank"
            addon={null}
            {...registerThresholdField({
              id: 'merchantTrendTestRank',
              value: values.merchantTrendTestRank,
              valueGlobal: valuesInit.merchantTrendTestRankGlobal,
              isActive: values.merchantTrendTestIsActive,
              validation: rankValidation
            })}
          />
        </FieldsGroup>

        <FieldsGroup label="Brand Trend Test" info={CONFIG_TOOLTIP.BRAND_TREND}>
          <IsActiveField
            {...registerIsActiveField({
              id: 'brandTrendTestIsActive',
              isActive: values.brandTrendTestIsActive,
              isActiveGlobal: valuesInit.brandTrendTestIsActiveGlobal
            })}
          />
          <ThresholdField
            {...registerThresholdField({
              id: 'brandTrendTestThreshold',
              value: values.brandTrendTestThreshold,
              valueGlobal: valuesInit.brandTrendTestThresholdGlobal,
              isActive: values.brandTrendTestIsActive
            })}
          />
          <ThresholdField
            label="Sensitivity"
            {...registerThresholdField({
              id: 'brandTrendTestSensitivity',
              value: values.brandTrendTestSensitivity,
              valueGlobal: valuesInit.brandTrendTestSensitivityGlobal,
              isActive: values.brandTrendTestIsActive
            })}
          />
          <ThresholdField
            label="Rank"
            addon={null}
            {...registerThresholdField({
              id: 'brandTrendTestRank',
              value: values.brandTrendTestRank,
              valueGlobal: valuesInit.brandTrendTestRankGlobal,
              isActive: values.brandTrendTestIsActive,
              validation: rankValidation
            })}
          />
        </FieldsGroup>

        <FieldsGroup label="Overall Trend Test" info={CONFIG_TOOLTIP.OVERALL_TREND}>
          <IsActiveField
            {...registerIsActiveField({
              id: 'overallTrendTestIsActive',
              isActive: values.overallTrendTestIsActive,
              isActiveGlobal: valuesInit.overallTrendTestIsActiveGlobal
            })}
          />
          <ThresholdField
            {...registerThresholdField({
              id: 'overallTrendTestThreshold',
              value: values.overallTrendTestThreshold,
              valueGlobal: valuesInit.overallTrendTestThresholdGlobal,
              isActive: values.overallTrendTestIsActive
            })}
          />
          <ThresholdField
            label="Sensitivity"
            {...registerThresholdField({
              id: 'overallTrendTestSensitivity',
              value: values.overallTrendTestSensitivity,
              valueGlobal: valuesInit.overallTrendTestSensitivityGlobal,
              isActive: values.overallTrendTestIsActive
            })}
          />
        </FieldsGroup>

        <FieldsGroup label="Is Autosubmit" info={CONFIG_TOOLTIP.AUTOSUBMIT}>
          <IsActiveField
            {...registerIsActiveField({
              id: 'isAutoSubmit',
              isActive: values.isAutoSubmit,
              isActiveGlobal: valuesInit.isAutoSubmitGlobal
            })}
          />
        </FieldsGroup>
      </div>
      <ResetWarning
        valuesPrev={valuesInit}
        valuesCurrent={values}
        testNames={[
          'date',
          'dictionary',
          'include',
          'exclude',
          'misbrand',
          'coverage',
          'newBrand',
          'newBrandMonth',
          'merchantTrend',
          'brandTrend',
          'overallTrend'
        ]}
      />
      <div className="modal-body-footer">
        <Button onClick={handleRejectButton}>Cancel</Button>
        <Button className="btn_custom" disabled={!isFormChanged || isError} onClick={handleOkButton}>
          Save
        </Button>
      </div>
    </Form>
  )
}

CategoryTestEditModal.propTypes = {
  value: PropTypes.object.isRequired
}

export default CategoryTestEditModal
