import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { DropdownButton, MenuItem } from 'react-bootstrap'
import cx from 'classnames'
import moment from 'moment'
import { times, find } from 'lodash'

export class MonthSelector extends Component {
  static propTypes = {
    id: PropTypes.string,
    selected: PropTypes.number,
    className: PropTypes.string,
    placeholder: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    readOnly: PropTypes.bool
  }

  static months = moment.monthsShort()
  render() {
    const { selected, className, placeholder, onChange, id, readOnly } = this.props
    let title = placeholder
    if (selected) {
      title = MonthSelector.months[(selected - 1) % 12]
    }
    return (
      <DropdownButton
        id={id || 'month-selector'}
        className={cx('month-selector', className, {
          'read-only': readOnly
        })}
        title={title}
        onSelect={onChange}
        disabled={readOnly}
      >
        {MonthSelector.months.map((monthName, index) => {
          const monthNum = index + 1
          return (
            <MenuItem eventKey={monthNum} active={selected === monthNum} key={monthNum}>
              {monthName}
            </MenuItem>
          )
        })}
      </DropdownButton>
    )
  }
}

export class YearSelector extends Component {
  static propTypes = {
    id: PropTypes.string,
    selected: PropTypes.number,
    className: PropTypes.string,
    min: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
    placeholder: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    readOnly: PropTypes.bool
  }

  static defaultProps = {
    min: 2017,
    max: 2030
  }

  render() {
    const { min, selected, className, placeholder, onChange, id, readOnly } = this.props
    let { max } = this.props
    if (max <= min) {
      max = min + 1
    }
    let title = placeholder
    if (selected) {
      title = `${selected}`
    }
    return (
      <DropdownButton
        id={id || 'year-selector'}
        className={cx('year-selector', className, {
          'read-only': readOnly
        })}
        title={title}
        onSelect={onChange}
        disabled={readOnly}
      >
        {times(max - min, number => {
          const year = min + number
          return (
            <MenuItem eventKey={year} active={selected === year} key={year}>
              {year}
            </MenuItem>
          )
        })}
      </DropdownButton>
    )
  }
}

export class DayTypeSelector extends Component {
  static dayTypes = [
    {
      key: 'MONTH_DAY',
      label: 'day of the month'
    },
    {
      key: 'WORKING_MONTH_DAY',
      label: 'working day of the month'
    }
  ]

  static propTypes = {
    id: PropTypes.string,
    selected: PropTypes.string,
    className: PropTypes.string,
    placeholder: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    readOnly: PropTypes.bool
  }

  render() {
    const { selected, className, placeholder, onChange, id, readOnly } = this.props
    let title = placeholder
    if (selected) {
      const selectedOption = find(DayTypeSelector.dayTypes, { key: selected })
      if (selectedOption) {
        title = selectedOption.label
      }
    }
    return (
      <DropdownButton
        id={id || 'day-type-selector'}
        className={cx('day-type-selector', className, {
          'read-only': readOnly
        })}
        title={title}
        onSelect={onChange}
        disabled={readOnly}
      >
        {DayTypeSelector.dayTypes.map(({ key, label }) => (
          <MenuItem eventKey={key} active={selected === key} key={key}>
            {label}
          </MenuItem>
        ))}
      </DropdownButton>
    )
  }
}
