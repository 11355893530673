import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Route, Switch, Redirect } from 'react-router-dom'
import { PropsRoute } from '../../utils/route-util'

import BreadCrumbs from './BreadCrumbs'
import Tabs from './Tabs'
import ClientsPage from './ClientsPage'
import ContractsPage from './ContractsPage'
import DeliverablesPage from './DeliverablesPage'
import DeliveryPages from './DeliveryPages'
import CCDSearch from './CCDSearch'
import WithErrorBoundaryWrapper from 'components/WithErrorBoundaryWrapper/WithErrorBoundaryWrapper'

import { extractCCDPathParams, getSelectedPage, isDeliveryPage } from '../../utils/delivery-center'

import { toString } from 'lodash'

import {
  fetchCCDCounts,
  updateSelectedClient,
  updateSelectedContract,
  updateSelectedDeliverable,
  removeSelectedDeliverable,
  initializeNewDeliverable,
  onCCDSearchChange,
  onCCDSearchMore,
  toggleShowInactive,
  fetchClients,
  fetchContracts,
  fetchDeliverables
} from '../../actions/dm-actions'
import UserService from '../../services/user-service'

import { products as PRODUCTS } from '../../constants/constants'

import './index.scss'
import './sb-form.scss'

class DeliveryManager extends Component {
  componentDidMount() {
    if (!this.props.hasDeliveryManagerAccess) {
      return this.props.history.replace('/')
    }
    this.props.fetchCCDCounts({ showInactive: this.props.showInactive })
    this.updateSelectedCCD()
  }

  componentDidUpdate() {
    this.updateSelectedCCD()
  }

  updateSelectedCCD = (props = this.props) => {
    const { client, contract, deliverable } = extractCCDPathParams(props.location)
    if (props.isDeliveryPage) {
      this.validateDeliverableStr(deliverable, props)
    } else {
      this.validateClientContractStr(client, contract, props)
    }
  }

  validateDeliverableStr(urlDeliverable = {}, props) {
    const { selectedDeliverable: stateDeliverable = {} } = props
    // // if nothing is selected in state or url, do an early return
    if (!urlDeliverable.id && stateDeliverable.id !== 'new') {
      this.props.initializeNewDeliverable()
    }
    // if selected deliverable in state and url mismatches, update state deliverable details
    if (urlDeliverable.id && toString(urlDeliverable.id) !== toString(stateDeliverable.id)) {
      this.props.updateSelectedDeliverable({ id: urlDeliverable.id })
    }
  }

  validateClientContractStr(urlClient = {}, urlContract = {}, props) {
    const {
      selectedClient: stateClient = {},
      selectedContract: stateContract = {},
      selectedDeliverable: stateDeliverable = {}
    } = props
    // if nothing is selected in state or url, do an early return
    if (!urlClient.id && !stateClient.id && !stateDeliverable.id) {
      return
    }
    // if selected contract in state and url mismatches, update state contract details
    if (toString(urlContract.id) !== toString(stateContract.id)) {
      this.props.updateSelectedContract({ id: urlContract.id })
      return // if contract mismatches, no need to check client
    }
    // if selected client in state and url mismatches, update state client details
    if (toString(urlClient.id) !== toString(stateClient.id)) {
      this.props.updateSelectedClient({ id: urlClient.id })
    }

    // if selected deliverable in state remove it & update CCD counts
    if (stateDeliverable.id) {
      this.props.removeSelectedDeliverable()
      this.props.fetchCCDCounts({ showInactive: this.props.showInactive })
    }
  }

  onToggleShowInactive = () => {
    this.props.toggleShowInactive()
    const path = this.props.location.pathname
    const pathParts = path.split('/')
    const tab = pathParts[pathParts.length - 1]
    const { showInactive } = this.props
    const tabActions = {
      clients: this.props.fetchClients,
      contracts: this.props.fetchContracts,
      deliverables: this.props.fetchDeliverables
    }
    this.props.fetchCCDCounts({ showInactive: !showInactive })
    if (tab !== 'category-jobs') {
      const { sortBy, sortOrder, pageSize } = this.props[tab]
      tabActions[tab]({
        sortBy,
        sortOrder,
        page: 1,
        pageSize,
        showInactive: !showInactive // because this is using old state. fix this.
      })
    }
  }

  render() {
    const {
      selectedClient = {},
      selectedContract = {},
      selectedDeliverable = {},
      deliverableConfigured,
      searchResults,
      onCCDSearchChange,
      onCCDSearchMore
    } = this.props
    const { id: clientId } = selectedClient
    const { id: deliverableId } = selectedDeliverable
    return (
      <React.Fragment>
        <div className="dm-header">
          <CCDSearch
            searchResults={searchResults}
            onSearchChange={onCCDSearchChange}
            onSearchMore={onCCDSearchMore}
            showInactive={this.props.showInactive}
            onToggleShowInactive={this.onToggleShowInactive}
          />
          {clientId || deliverableId ? (
            <BreadCrumbs
              selectedClient={selectedClient}
              selectedContract={selectedContract}
              selectedDeliverable={selectedDeliverable}
            />
          ) : (
            <div className="page-title">Delivery Manager</div>
          )}
        </div>
        <div id="dm-tabs-wrapper">
          <div className="tab-label portal-tabs">
            <ul role="tablist" className="nav nav-tabs">
              <Tabs {...this.props} />
            </ul>
          </div>
          <div className="tab-content">
            <Switch>
              <Route path="/admin/dm/clients" component={ClientsPage} />
              <Route path="/admin/dm/contracts" component={ContractsPage} />
              <Route path="/admin/dm/deliverables" component={DeliverablesPage} />
              <PropsRoute
                path="/admin/dm/delivery*"
                component={DeliveryPages}
                selectedClient={selectedClient}
                selectedContract={selectedContract}
                selectedDeliverable={selectedDeliverable}
                deliverableId={deliverableId}
                deliverableConfigured={deliverableConfigured}
              />
              <Redirect exact from="/admin/dm" to="/admin/dm/clients" />
            </Switch>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

DeliveryManager.propTypes = {
  location: PropTypes.object,
  selectedPage: PropTypes.string,
  isDeliveryPage: PropTypes.bool,
  deliverableConfigured: PropTypes.bool,
  totalClientCount: PropTypes.number,
  totalContractCount: PropTypes.number,
  totalDeliverableCount: PropTypes.number,
  selectedClient: PropTypes.object,
  selectedContract: PropTypes.object,
  selectedDeliverable: PropTypes.object,
  searchText: PropTypes.string,
  searchResults: PropTypes.object,
  user: PropTypes.object,
  history: PropTypes.object,
  fetchCCDCounts: PropTypes.func,
  updateSelectedClient: PropTypes.func,
  updateSelectedContract: PropTypes.func,
  updateSelectedDeliverable: PropTypes.func,
  removeSelectedDeliverable: PropTypes.func,
  initializeNewDeliverable: PropTypes.func,
  onCCDSearchChange: PropTypes.func,
  onCCDSearchMore: PropTypes.func,
  hasDeliveryManagerAccess: PropTypes.bool,
  showInactive: PropTypes.bool,
  clients: PropTypes.object,
  contracts: PropTypes.object,
  deliverables: PropTypes.object,
  toggleShowInactive: PropTypes.func,
  fetchClients: PropTypes.func,
  fetchContracts: PropTypes.func,
  fetchDeliverables: PropTypes.func
}

function mapStateToProps(
  { dm, deliveryConfig: { stepsCompleted }, delivery: { pastDeliveryCount }, session: { user } },
  ownProps
) {
  const selectedPage = getSelectedPage(ownProps.location)
  const deliveryPage = isDeliveryPage(selectedPage)
  const deliverableConfigured = deliveryPage ? checkDeliverableConfigured(stepsCompleted) : null
  const isPortalAdmin = user ? UserService.isPortalAdmin(user) : false
  const hasProductAccess =
    user && user.products ? UserService.isProductAuthorized(user.products, PRODUCTS.adminToolsDeliveryManager) : false

  return {
    user,
    selectedPage,
    isDeliveryPage: deliveryPage,
    totalClientCount: dm.totalClientCount,
    totalContractCount: dm.totalContractCount,
    totalDeliverableCount: dm.totalDeliverableCount,
    pastDeliveryCount,
    selectedClient: dm.selectedClient || {},
    selectedContract: dm.selectedContract || {},
    selectedDeliverable: dm.selectedDeliverable || {},
    searchResults: dm.searchResults,
    deliverableConfigured,
    hasDeliveryManagerAccess: isPortalAdmin || hasProductAccess,
    showInactive: dm.showInactive,
    clients: dm.clients,
    contracts: dm.contracts,
    deliverables: dm.deliverables
  }
}
export default WithErrorBoundaryWrapper(
  connect(mapStateToProps, {
    fetchCCDCounts,
    updateSelectedClient,
    updateSelectedContract,
    updateSelectedDeliverable,
    removeSelectedDeliverable,
    initializeNewDeliverable,
    onCCDSearchChange,
    onCCDSearchMore,
    toggleShowInactive,
    fetchClients,
    fetchContracts,
    fetchDeliverables
  })(DeliveryManager),
  '"Admin Delivery Manager" page'
)

/**
 * Check if all steps are completed.
 * Returns null if state of any step is unknown; returns boolean otherwise.
 */
function checkDeliverableConfigured(stepCompletedMap) {
  const values = Object.values(stepCompletedMap)
  if (values.indexOf(null) > -1) {
    return null
  }
  return Object.values(stepCompletedMap).filter(item => !item).length === 0
}
