import React, { Component } from 'react'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'
import PropTypes from 'prop-types'
import { fetchGlobalFeedConfig } from '../../actions/feed-actions'
import { fetchPartners, excludePartner, includePartner } from '../../actions/partner-actions'
import {
  fetchMailboxProviders,
  excludeMailboxProvider,
  includeMailboxProvider
} from '../../actions/mailbox-provider-actions'
import {
  excludePartnerMailboxProvider,
  includePartnerMailboxProvider
} from '../../actions/partner-mailbox-provider-actions'
import FormSelect from '../FormSelect'
import { OverlayTrigger, Tooltip, Table, Button, Glyphicon } from 'react-bootstrap'

class GlobalFeedConfig extends Component {
  componentDidMount() {
    this.fetchFormData()
    this.props.fetchGlobalFeedConfig()
  }

  fetchFormData = () => {
    this.props.fetchPartners()
    this.props.fetchMailboxProviders()
  }

  render() {
    return (
      <>
        <div className="config-dropdown-container">
          <div className="config-dropdown">
            <FormSelect
              label="Partner"
              fieldName="partnerId"
              placeholder="Select Partner"
              options={this.props.partners.data.map(partner => ({ value: partner.id, label: partner.name }))}
            />
          </div>
          <div className="config-dropdown">
            <FormSelect
              label="Mailbox Provider"
              fieldName="mailboxProviderId"
              placeholder="Select Mailbox Provider"
              options={this.props.mailboxProviders.data.map(mailboxProvider => ({
                value: mailboxProvider.id,
                label: `${mailboxProvider.display_name} - ${mailboxProvider.name}`
              }))}
            />
          </div>
        </div>
        <div className="exclusion-buttons-container">
          <OverlayTrigger
            placement="bottom"
            overlay={
              <Tooltip id="schedule-firehose-tooltip">
                Excludes selected Partner from all feeds where the filter is applicable
              </Tooltip>
            }
          >
            <button
              className={'btn_custom_secondary'}
              type="button"
              onClick={event => this.props.excludePartner(this.props.partnerIdToExclude)}
              disabled={false}
            >
              Exclude Partner
            </button>
          </OverlayTrigger>
          <OverlayTrigger
            placement="bottom"
            overlay={
              <Tooltip id="schedule-firehose-tooltip">
                Excludes selected Mailbox Provider from all feeds where the filter is applicable
              </Tooltip>
            }
          >
            <button
              className={'btn_custom_secondary'}
              type="button"
              onClick={event => this.props.excludeMailboxProvider(this.props.mailboxProviderIdToExclude)}
              disabled={false}
            >
              Exclude Mailbox Provider
            </button>
          </OverlayTrigger>
          <OverlayTrigger
            placement="bottom"
            overlay={
              <Tooltip id="schedule-firehose-tooltip">
                Excludes selected Partner + Mailbox Provider Combination from all feeds where the filter is applicable
              </Tooltip>
            }
          >
            <button
              className={'btn_custom_secondary'}
              type="button"
              onClick={event =>
                this.props.excludePartnerMailboxProvider(
                  this.props.partnerIdToExclude,
                  this.props.mailboxProviderIdToExclude
                )
              }
              disabled={false}
            >
              Exclude Partner + Mailbox Provider Combination
            </button>
          </OverlayTrigger>
        </div>
        <div className="exclusion-table-container">
          <Table className="exclusion-table" striped bordered hover>
            <thead>
              <tr>
                <th>Partner Name (Partner ID)</th>
                <th>Include</th>
              </tr>
            </thead>
            <tbody>
              {this.props.partnerExcludes.map(item => (
                <tr key={item.partnerId}>
                  <td>
                    {item.partnerName} ({item.partnerId})
                  </td>
                  <td>
                    <Button onClick={event => this.props.includePartner(item.partnerId)}>
                      <Glyphicon glyph="remove" />
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Table className="exclusion-table" striped bordered hover>
            <thead>
              <tr>
                <th>Mailbox Provider Name (Mailbox Provider ID)</th>
                <th>Include</th>
              </tr>
            </thead>
            <tbody>
              {this.props.mailboxProviderExcludes.map(item => (
                <tr key={item.mailboxProviderId}>
                  <td>
                    {item.mailboxProviderDisplayName} - {item.mailboxProviderName} ({item.mailboxProviderId})
                  </td>
                  <td>
                    <Button onClick={event => this.props.includeMailboxProvider(item.mailboxProviderId)}>
                      <Glyphicon glyph="remove" />
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Table className="exclusion-table" striped bordered hover>
            <thead>
              <tr>
                <th>Partner Name (Partner ID) - Mailbox Provider Name (Mailbox Provider ID)</th>
                <th>Include</th>
              </tr>
            </thead>
            <tbody>
              {this.props.partnerMailboxProviderExcludes.map(item => (
                <tr key={`${item.partnerId}-${item.mailboxProviderId}`}>
                  <td>
                    {item.partnerName} ({item.partnerId}) - {item.mailboxProviderDisplayName} ({item.mailboxProviderId})
                  </td>
                  <td>
                    <Button
                      onClick={event =>
                        this.props.includePartnerMailboxProvider(item.partnerId, item.mailboxProviderId)
                      }
                    >
                      <Glyphicon glyph="remove" />
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </>
    )
  }
}

const form = reduxForm({
  form: 'global-feed-config-form'
})(GlobalFeedConfig)

GlobalFeedConfig.propTypes = {
  partners: PropTypes.object,
  mailboxProviders: PropTypes.object,
  partnerExcludes: PropTypes.array,
  mailboxProviderExcludes: PropTypes.array,
  partnerMailboxProviderExcludes: PropTypes.array,
  fetchGlobalFeedConfig: PropTypes.func,
  fetchPartners: PropTypes.func,
  fetchMailboxProviders: PropTypes.func,
  excludePartner: PropTypes.func,
  excludeMailboxProvider: PropTypes.func,
  excludePartnerMailboxProvider: PropTypes.func,
  includePartner: PropTypes.func,
  includeMailboxProvider: PropTypes.func,
  includePartnerMailboxProvider: PropTypes.func,
  partnerIdToExclude: PropTypes.number,
  mailboxProviderIdToExclude: PropTypes.number
}

GlobalFeedConfig.defaultProps = {
  partners: {},
  mailboxProviders: {},
  partnerExcludes: [],
  mailboxProviderExcludes: [],
  partnerMailboxProviderExcludes: []
}

const mapStateToProps = state => ({
  partners: state.partners,
  mailboxProviders: state.mailboxProviders,
  partnerExcludes:
    state.feeds &&
    state.feeds.globalFeedConfig &&
    state.feeds.globalFeedConfig.data &&
    state.feeds.globalFeedConfig.data.partnerExcludes,
  mailboxProviderExcludes:
    state.feeds &&
    state.feeds.globalFeedConfig &&
    state.feeds.globalFeedConfig.data &&
    state.feeds.globalFeedConfig.data.mailboxProviderExcludes,
  partnerMailboxProviderExcludes:
    state.feeds &&
    state.feeds.globalFeedConfig &&
    state.feeds.globalFeedConfig.data &&
    state.feeds.globalFeedConfig.data.partnerMailboxProviderExcludes,
  partnerIdToExclude:
    state.form['global-feed-config-form'] &&
    state.form['global-feed-config-form'].values &&
    state.form['global-feed-config-form'].values.partnerId,
  mailboxProviderIdToExclude:
    state.form['global-feed-config-form'] &&
    state.form['global-feed-config-form'].values &&
    state.form['global-feed-config-form'].values.mailboxProviderId
})

const mapDispatchToProps = {
  fetchGlobalFeedConfig,
  fetchPartners,
  fetchMailboxProviders,
  excludePartner,
  excludeMailboxProvider,
  excludePartnerMailboxProvider,
  includePartner,
  includeMailboxProvider,
  includePartnerMailboxProvider
}

export default connect(mapStateToProps, mapDispatchToProps)(form)
