import React from 'react'

export default function AlertCircle(props) {
  return (
    <svg
      width="20px"
      height="20px"
      viewBox="5 5 20 20"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      {/* Generator: Sketch 51.1 (57501) - http://www.bohemiancoding.com/sketch */}
      <desc>Created with Sketch.</desc>
      <defs />
      <g id="ic-alert-circle" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g>
          <path
            d="M15,23 C10.581722,23 7,19.418278 7,15 C7,10.581722 10.581722,7 15,7 C19.418278,7 23,10.581722 23,15 C23,19.418278 19.418278,23 15,23 Z M14.5,20 L15.5,20 L15.5,18 L14.5,18 L14.5,20 Z M14.486491,17 L15.5955064,17 L16.3832946,11 L13.4934159,11 L14.486491,17 Z"
            id="Combined-Shape"
            fill="currentColor"
          />
        </g>
      </g>
    </svg>
  )
}
