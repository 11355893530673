import React from 'react'
import { Controller } from 'react-hook-form'
import { FormControl } from 'react-bootstrap'
import PropTypes from 'prop-types'
import InputField from 'components/FormInputComponents/InputField'
import { validSampleTypes } from 'constants/constants.js'

const SampleInput = ({ control, info }) => {
  return (
    <InputField label="Sample" required info={info} className="mt-3 text-start" labelSize={4}>
      <Controller
        name="sample"
        control={control}
        render={({ field }) => (
          <FormControl {...field} componentClass="select">
            {validSampleTypes.map(type => (
              <option key={type} value={type}>
                {type}
              </option>
            ))}
          </FormControl>
        )}
      />
    </InputField>
  )
}

SampleInput.propTypes = {
  control: PropTypes.any,
  info: PropTypes.string
}

export default SampleInput
