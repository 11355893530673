import React from 'react'
import { Alert, Glyphicon } from 'react-bootstrap'

export const CONFIG_TOOLTIP = {
  DATE: (
    <span>
      Checks if the min-max order dates for category is within the inclusive range of user input; ie{' '}
      <p className="category-test-tooltip-code">
        (current month - user threshold) &gt;= order&nbsp;date &gt;= (current month - 1)
      </p>
    </span>
  ),
  DICTIONARY: (
    <span>
      Checks if the maximum monthly percent of Non-Dictionary is less than or equal to User-Inputted threshold; ie{' '}
      <p className="category-test-tooltip-code"> max(non-dictionary) &gt;= user threshold</p>
    </span>
  ),
  INCLUDE:
    'Checks that the maximum monthly percentage of revenue relative to category (currently not within category) is less than or equal to User-Inputted threshold.',
  EXCLUDE:
    'Checks that the maximum monthly percent of Revenue to be Excluded less than or equal to  User-Inputted threshold.',
  MISBRAND: (
    <span>
      Checks that the maximum Total percent of Mis-Brand less than or equal to User-Inputted threshold. Mis-Branding is:
      <p className="category-test-tooltip-code">Brand != [brand name] AND Description contains ”[brand name]”</p>
    </span>
  ),
  COVERAGE:
    'Checks that the maximum monthly percent of Revenue Not Covered by Include query less than or equal to User-Inputted threshold',
  NEW_BRAND:
    'Checks that the maximum words percent of revenue in Brand="Other" less than or equal to User-Inputted threshold',
  NEW_BRAND_3_MONTHS:
    'Checks that the maximum words percent of revenue in Brand="Other" less than or equal to User-Inputted threshold in the last 3 months',
  MERCHANT_TREND: (
    <>
      <p>
        Merchant trend check is to test if a merchant’s current-previous trend difference is too large within a
        category.
      </p>
      <p>
        <strong>Threshold</strong> is the max absolute % violation allowable for the current-previous difference.
      </p>
      <p>
        <strong>Sensitivity</strong> is to limit false alerts, and is defined as the % max previous revenue month.
      </p>
      <p>
        <strong>Rank</strong> sets the number of top revenue merchants to check within a category.
      </p>
    </>
  ),
  BRAND_TREND: (
    <>
      <p>Brand trend check is to test if a brand’s current-previous trend difference is too large within a category.</p>
      <p>
        <strong>Threshold</strong> is the max absolute % violation allowable for the current-previous difference.
      </p>
      <p>
        <strong>Sensitivity</strong> is to limit false alerts, and is defined as the % max previous revenue month.
      </p>
      <p>
        <strong>Rank</strong> sets the number of top revenue brands to check within a category.
      </p>
    </>
  ),
  OVERALL_TREND: (
    <>
      <p>Overall trend check is to test if a current-previous trend difference is too large within a category.</p>
      <p>
        <strong>Threshold</strong> is the max absolute % violation allowable for the current-previous difference.
      </p>
      <p>
        <strong>Sensitivity</strong> is to limit false alerts, and is defined as the % max previous revenue month.
      </p>
    </>
  ),
  AUTOSUBMIT: 'Should we automatically update status of related Delivery Job if test is passed.'
}

export const RESET_WARNING = {
  GLOBAL: (
    <Alert bsStyle="warning">
      <Glyphicon className="padding-rigt-standard" glyph="warning-sign" />
      Note: Saving these changes, you will reset statuses in all Category Tests that use global configuration.
    </Alert>
  ),
  LOCAL: (
    <Alert bsStyle="warning">
      <Glyphicon className="padding-rigt-standard" glyph="warning-sign" />
      Note: Saving these changes, you will reset the result status of the changed test.
    </Alert>
  )
}
