const rankValidation = value => {
  let error = null
  if (isNaN(value) || value === '') {
    error = 'Rank should be a number'
  }

  if (value < 1 || value > 30) {
    error = 'Rank should be between 1 and 30'
  }
  return error
}

const thresholdValidation = value => {
  let error = null

  if (isNaN(value) || value === '') {
    error = 'Value should be a number'
  }

  if (value < 0 || value > 100) {
    error = 'Value should be between 0 and 100 %'
  }

  return error
}

const dateValidation = value => {
  let error = null

  if (isNaN(value) || value === '') {
    error = 'Month should be a number'
  }

  if (value < 1 || value > 36) {
    error = 'Value should be between 1 and 36 months'
  }

  return error
}

export { rankValidation, thresholdValidation, dateValidation }
