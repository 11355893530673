import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import Loader from 'components/Loader'
import { DataTableFiltered } from 'components/DataTable'

import RequestsActions from './actions'
import RequestsToolbar from './Table/RequestsToolbar'
import getColumns from './Table/columns'

import './requests.scss'

class Requests extends Component {
  render() {
    const {
      loading,
      pageSize,
      totalCount,
      page,
      rows,
      sortBy,
      sortOrder,
      filters,
      fetchRequestsAction,
      hasEditAccess
    } = this.props

    return (
      <>
        <RequestsToolbar refreshHandler={fetchRequestsAction} />
        {loading && <Loader loading overlap />}
        <DataTableFiltered
          data={rows}
          columns={getColumns(hasEditAccess)}
          page={page}
          pageSize={pageSize}
          totalCount={totalCount}
          sortBy={sortBy}
          sortOrder={sortOrder}
          filters={filters}
          tableClassName="requests-table"
          fetchRowsAction={fetchRequestsAction}
        />
      </>
    )
  }
}

Requests.defaultProps = {
  rows: [],
  totalCount: 0
}

Requests.propTypes = {
  page: PropTypes.number,
  pageSize: PropTypes.number,
  sortBy: PropTypes.string,
  sortOrder: PropTypes.string,
  filters: PropTypes.object,
  loading: PropTypes.bool.isRequired,
  hasEditAccess: PropTypes.bool.isRequired,
  totalCount: PropTypes.number.isRequired,
  rows: PropTypes.arrayOf(PropTypes.object).isRequired,
  fetchRequestsAction: PropTypes.func.isRequired
}

const mapStateToProps = ({ requests }) => ({
  page: requests.page,
  sortBy: requests.sortBy,
  loading: requests.loading,
  rows: requests.data.rows,
  pageSize: requests.pageSize,
  sortOrder: requests.sortOrder,
  totalCount: requests.data.count,
  filters: requests.filters
})

const mapDispatchToProps = {
  fetchRequestsAction: RequestsActions.fetch
}

export default connect(mapStateToProps, mapDispatchToProps)(Requests)
