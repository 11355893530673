import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'

import { Button, Glyphicon } from 'react-bootstrap'

import { DataTableToolbar, TableToolbarButtons } from 'components/DataTable'

import { addCustomModal } from 'actions/modal-actions'
import deliveryJobsActions from './deliveryJobsActions'
import DeliveryJobCreateModal from './Modals/DeliveryJobCreateModal'
import PublishDeliveryJobs from './PublishDeliveryJobs'

const DeliveryJobsToolbar = ({ refreshHandler, hasEditAccess }) => {
  const dispatch = useDispatch()

  const openCreateModal = () => {
    dispatch(
      addCustomModal({
        modalType: 'successModal',
        id: 'create-delivery-jobs-modal',
        title: `Create new Delivery Job`,
        size: 'large',
        content: <DeliveryJobCreateModal />
      })
    )
  }

  const handleDownload = () => {
    dispatch(deliveryJobsActions.download())
  }

  const clearFilters = () => {
    dispatch(deliveryJobsActions.clearFilters())
  }

  return (
    <DataTableToolbar>
      {hasEditAccess && (
        <Button bsStyle="info" onClick={openCreateModal}>
          <Glyphicon glyph="plus" />
          {`Create Delivery Jobs`}
        </Button>
      )}

      <TableToolbarButtons
        downloadHandler={handleDownload}
        refreshHandler={refreshHandler}
        clearFiltersHandler={clearFilters}
      />

      <PublishDeliveryJobs hasEditAccess={hasEditAccess} />
    </DataTableToolbar>
  )
}

DeliveryJobsToolbar.propTypes = {
  hasEditAccess: PropTypes.bool.isRequired,
  refreshHandler: PropTypes.func.isRequired
}

export default DeliveryJobsToolbar
