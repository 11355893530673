import { createSelector } from 'reselect'

const itemAttributesSelector = state => (state.itemAttributes && state.itemAttributes.data) || []

export const getItemAttributesId = createSelector(itemAttributesSelector, attributes => {
  return attributes.reduce((ids, attribute) => {
    ids.push(attribute.attribute_id)
    return ids
  }, [])
})

export const getItemAttributesIndexedById = createSelector(itemAttributesSelector, attributes => {
  const attr = attributes.reduce((attributesIndexedById, attribute) => {
    attributesIndexedById[attribute.attribute_id] = attribute
    return attributesIndexedById
  }, {})

  // Rename attribute_name to name
  Object.keys(attr).forEach(key => {
    Object.defineProperty(attr[key], 'name', Object.getOwnPropertyDescriptor(attr[key], 'attribute_name'))
    delete attr[key].attribute_name
  })

  return attr
})
