import React from 'react'
import PropTypes from 'prop-types'
import { Form } from 'react-bootstrap'

import InputField, { useFormValues } from 'components/FormInputComponents/InputField'
import BrandSuggestion from 'components/Suggestion/BrandSuggestion'

import useFormBrandSynonymFilds from './useFormBrandSynonymFilds'
import useFormButtons from 'components/Rules/Common/useFormButtons'
import useMode from 'components/Rules/Common/useMode'
import { getSubmitButtonName } from 'components/Rules/Common/utils'

import NoteFields from 'components/Rules/Common/NoteFields'
import CommonRuleModalButtons from 'components/Rules/Common/CommonRuleModalButtons'
import ManageFieldButtons from 'components/QCSupportPage/Requests/BrandRequestModal/ManageFieldButtons'
import SynonymWithCheck from './SynonymWithCheck'
import duplicateCheck from 'utils/duplicateCheck'
import './brandSynonymRuleModal.scss'

// synonym obj need for correct work "updateValue" function in "useFormValues" hook, it didn't work with string
const emptySynonymObj = {
  synonym: ''
}
export default function BrandSynonymRuleModal({ value, isRequest, onOkHandler, onRejectHandler, isReadOnly }) {
  const { id, synonyms, brandId, brandFullPath, request_notes, review_notes } = value
  const mode = useMode(isRequest, isReadOnly, id)

  const initValues = {
    id: id ?? null,
    brandId: brandId ?? null,
    brandFullPath: brandFullPath ?? null,
    synonyms: synonyms ?? [],
    request_notes: request_notes ?? null,
    review_notes: review_notes ?? null
  }

  const { values, handleFieldChange, updateValue } = useFormValues(initValues)

  const { handleBrandChange, handleSynonymChange } = useFormBrandSynonymFilds(updateValue)

  const hasEmptyField = values.synonyms.some(syn => !syn.synonym)
  const { hasAnyDuplicate } = duplicateCheck({
    parent: values.brandFullPath,
    arrOfChilds: values.synonyms.map(el => el.synonym)
  })

  const isOkButtonDisabled = hasEmptyField || !values.brandId || hasAnyDuplicate()

  const sentFieldsForRules = ['synonym', 'brandId']
  const sentFieldsForRequests = ['synonyms', 'brandFullPath', 'request_notes', 'review_notes']
  const { handleOkButton, handleRejectButton, handleCancelButton } = useFormButtons({
    onOkHandler,
    onRejectHandler,
    values: convertValuesForSave(values),
    sentFieldsForRules,
    sentFieldsForRequests,
    isRequest
  })

  const addSynonymFieldHandler = () => updateValue(values => ({ synonyms: [...values.synonyms, emptySynonymObj] }))
  const removeSynonymFieldHandler = () => updateValue(values => ({ synonyms: values.synonyms.slice(0, -1) }))

  return (
    <Form className="text-left" horizontal>
      <div className="brand-synonyms-request-modal-form-body">
        <InputField validationState={values.brandId === undefined ? 'error' : null} required label={'Brand'}>
          <BrandSuggestion
            selectedId={values.brandId}
            selectedLabel={values.brandFullPath}
            changeCallback={handleBrandChange}
            readOnly={mode.isRequestApprove || mode.isRequestView}
          />
        </InputField>

        {values.synonyms.map((synonym, i, arr) => (
          // We can use 'i' as the key, because the user is able to delete only the last symbol
          <SynonymWithCheck
            key={i}
            index={i}
            synonym={synonym}
            synonyms={arr}
            brandId={brandId || values.brandId}
            brandFullPath={brandFullPath || values.brandFullPath}
            mode={mode}
            handleSynonymChange={handleSynonymChange}
            prevRuleId={values.id}
          />
        ))}

        {mode.isRequestCreate && (
          <ManageFieldButtons
            name={'Synonym'}
            addHandler={addSynonymFieldHandler}
            removeHandler={removeSynonymFieldHandler}
            fieldsCount={values.synonyms.length}
            minFildCount={1}
            hasEmptyField={isOkButtonDisabled}
          />
        )}

        <NoteFields
          mode={mode}
          request_notes={values.request_notes}
          review_notes={values.review_notes}
          handleFieldChange={handleFieldChange}
        />

        <CommonRuleModalButtons
          handleOkButton={handleOkButton}
          handleRejectButton={handleRejectButton}
          handleCancelButton={handleCancelButton}
          mode={mode}
          okButtonName={getSubmitButtonName(mode)}
          isOkButtonDisabled={isOkButtonDisabled}
        />
      </div>
    </Form>
  )
}

BrandSynonymRuleModal.defaultProps = {
  value: {
    id: null,
    synonyms: [emptySynonymObj],
    brandId: null,
    brandFullPath: null,
    request_notes: null,
    review_notes: null
  },
  isRequest: false,
  isReadOnly: false
}

BrandSynonymRuleModal.propTypes = {
  value: PropTypes.shape({
    id: PropTypes.number,
    synonyms: PropTypes.array,
    brandId: PropTypes.number,
    brandFullPath: PropTypes.string,
    request_notes: PropTypes.string,
    review_notes: PropTypes.string
  }),

  onOkHandler: PropTypes.func.isRequired,
  onRejectHandler: PropTypes.func,

  isRequest: PropTypes.bool,
  isReadOnly: PropTypes.bool
}

const convertValuesForSave = values => {
  // select data item for "RuleCreate" && "RuleEdit", they work with one synonym
  const { synonym } = values.synonyms[0] || {}
  return {
    ...values,
    synonym
  }
}

export { emptySynonymObj }
