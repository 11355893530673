import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ChartBars from 'components/Icon/ChartBars'
import Table from 'components/Icon/Table'
import { default as Export } from '../../../export/ExportContainer'
import { OverlayTrigger, Tooltip, Button, Glyphicon } from 'react-bootstrap'

class TableToolbar extends Component {
  tooltip = title => (
    <Tooltip id="description-table-toolbar-tooltip">{title || `${this.props.aggType} options`}</Tooltip>
  )

  renderRefreshButton = () => {
    return (
      <OverlayTrigger delayShow={1000} placement="top" overlay={this.tooltip('Refresh')}>
        <Button
          bsSize="xsmall"
          bsStyle="default"
          className="action-button"
          onClick={() => this.props.updateWidgetData(this.props.aggType)}
        >
          <Glyphicon glyph="refresh" />
        </Button>
      </OverlayTrigger>
    )
  }

  renderChartIcon = () => {
    return (
      <span>
        <ChartBars
          key={'chart'}
          size="28"
          className={'widget-toollbar-icon-active'}
          onClick={err => {
            err.preventDefault()
            this.props.onViewChanged('chart', this.props.aggType)
          }}
        />
      </span>
    )
  }

  renderTableIcon = () => {
    return (
      <span>
        <Table
          key={'table'}
          size="28"
          className={'widget-toollbar-icon-active'}
          onClick={err => {
            err.preventDefault()
            this.props.onViewChanged('table', this.props.aggType)
          }}
        />
      </span>
    )
  }

  renderWidgetOptionButton = () => {
    return (
      <span>
        <OverlayTrigger delayShow={1000} placement="left" overlay={this.tooltip()}>
          <Button
            bsSize="xsmall"
            bsStyle="default"
            active={this.props.showToolbar}
            onClick={err => {
              err.preventDefault()
              this.props.onWidgetsOptionsChanged(this.props.aggType, 'showToolbar', !this.props.showToolbar)
            }}
          >
            <Glyphicon glyph="wrench" />
          </Button>
        </OverlayTrigger>
      </span>
    )
  }

  render() {
    return (
      <div className="pull-right" style={{ width: '100px' }}>
        {this.renderRefreshButton()}
        {this.props.selectedView === 'table' ? this.renderChartIcon() : this.renderTableIcon()}
        <Export onExport={this.props.onExport} />
        {this.renderWidgetOptionButton()}
      </div>
    )
  }
}

TableToolbar.propTypes = {
  selectedView: PropTypes.string,
  onViewChanged: PropTypes.func,
  aggType: PropTypes.string,
  onWidgetsOptionsChanged: PropTypes.func,
  showToolbar: PropTypes.bool,
  updateWidgetData: PropTypes.func,
  onExport: PropTypes.func
}

export default TableToolbar
