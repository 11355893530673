import React from 'react'
import PropTypes from 'prop-types'
import { FormGroup, Col } from 'react-bootstrap'
import moment from 'moment'

const DateTestRange = ({ monthRange }) => {
  const DATE_FORMAT = 'MMM DD YYYY'
  let rangeString = '----'
  if (monthRange) {
    const start = moment()
      .add(-1 * monthRange, 'month')
      .startOf('month')
      .format(DATE_FORMAT)
    const end = moment()
      .add(-1, 'month')
      .endOf('month')
      .format(DATE_FORMAT)

    rangeString = `${start}-${end}`
  }
  return (
    <FormGroup>
      <Col sm={2}>Range:</Col>
      <Col sm={10}>{rangeString}</Col>
    </FormGroup>
  )
}

DateTestRange.propTypes = {
  monthRange: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
}

export default DateTestRange
