import React from 'react'

const help = `How Rules Work:

Rules are meant to taxonomize items
Rules can be organized into 2 purposes; Categorization and Branding.

Merchant Category Rules
Merchant Category Regex Rules

Merchant Brand Rules
Merchant Brand Regex Rules
Category Brand Rules
Category Brand Rules Path

Brand Synonyms


Category Rules:
    Merchant Category Rules
        These rules will Categorize all Items from this merchant to a single Category

    Merchant Category Regex Rules
        These rules will categorize all matched receipts from Merchant to a specific Category

        Mandatory Fields:
            Merchant: Requires a Merchant
            Category: Requires a Category
            Inclusion: Requires a RegEx pattern to find specific strings within a receipt item description
            (RegEx is automatically made to be case-insensitive flag)

        Rule Types
            Parsed Category
                Match logic: Is an exact string match on a categorization given directly from the merchant on a receipt Item

            Description
                Match logic: Is a Regular Expression (RegEx) matched against the description from a rececipt item

                        Example:
                            Inclusion Param: (^|\\s)Shampoo.*
                            Matches all of the following:
                                Head & Shoulders Shampoo
                                Shampoo - 32oz
                                The Best Shampoo&Conditioner - 16oz
                            Will Not match:
                                XYZShampoo

                Optional:
                    Exclusion: Excludes specific strings matched by the Inclusion RegEx
                        Example:
                            Inclusion (^|\\s)Shampoo.*
                            Exclusion (^|\\s)Shampoo&Conditioner.*

                            Matches all of the following:
                                Head & Shoulders Shampoo
                                Shampoo - 32oz
                            Will Not match:
                                The Best Shampoo&Conditioner - 16oz
                                XYZShampoo

                    Is Exclude: Is an anti-match, where the item will not be categorized to a specific category if the pattern matches.
                    More simply, a matched description will NOT be categorized into the specified cagtegory.

                        Example:
                            Merchant: Amazon
                            Category: Conditioner

                            Inclusion (^|\\s)Shampoo&Conditioner.*
                            Is Exclude: True

                            Matches all of the following:
                                The Best Shampoo&Conditioner - 16oz

                            Will Not match:
                                Head & Shoulders Shampoo
                                Shampoo - 32oz
                                XYZShampoo

                            Any description that has "The Best Shampoo&Conditioner - 16oz" will NOT be categorized into Shampoo.

Brand Rules
    Merchant Brand Rules
        These rules will Brand all Items from this merchant to a single Brand

    Merchant Brand Regex Rules
        These rules will brand all matched items from Merchant to a specific Brand

        Rule Types
        * Only 1 rule type
            Description
                Match logic: Is a Regular Expression match against the description from a rececipt





Global Precedence Order

Current:

    The following is the Current Precedence Order, which shows how items are taxonomized.
    Rules are top-priority in the precedence chain.

    Categorization:
        Merchant Categories Regex Rules (Parsed Category)
            > Merchant Category Rules
            > Merchant Category Regex Rules (Description)
            > Dictionary
            > CME ML Algorithm
            > Default: Other (Category ID: 1)

    Branding:
        Merchant Brand Regex Rules (Description)
            > Dictionary (uses Brand Synonyms)
            > Category Brand Rules (uses Brand Synonyms)
            > Default: Other (Brand ID: 1)


Legacy:

    The following is the Legacy Order, which shows existing but deprecated functionality.
    Deprecated means that functionality exists within system, but is no longer used or available for modification.

    Categorization:
        Merchant Categories Regex Rules (Parsed Category)
            > Merchant Category Rules
            > Deprecated: Merchant Category Regex Rules (Merchant)
            > Merchant Category Regex Rules (Description)
            > Dictionary
            > CME ML Algorithm
            > Default: Other (Category ID: 1)

    Branding:
        Deprecated: Merchant Brand Regex Rules (Merchant)
            > Merchant Brand Regex Rules (Description)
            > Deprecated: Brand Whitelist
            > Dictionary (uses Brand Synonyms)
            > Category Brand Rules (uses Brand Synonyms)
            > Default: Other (Brand ID: 1)
`
const HelpRulesTab = () => {
  return (
    <div>
      <pre>{help}</pre>
    </div>
  )
}

export default HelpRulesTab
