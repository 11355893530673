import React from 'react'
import PropTypes from 'prop-types'

import Loader from 'components/Loader'

import MetricHeader from './MetricHeader'
import MetricQuery from './MetricQuery'
import useMetric from './useMetric'

const Metric = ({ loading, warningMessage, children }) => {
  const getChildByDisplayName = (children, displayName) =>
    React.Children.toArray(children).find(child => child.type.displayName === displayName) || null

  const header = getChildByDisplayName(children, 'MetricHeader')
  const query = getChildByDisplayName(children, 'MetricQuery')
  const graph = getChildByDisplayName(children, 'MetricChart')

  return (
    <div className="qc-metrics-widget">
      {header}
      {query}
      <div className="qc-metrics-graph-container">
        {loading ? (
          <Loader overlap />
        ) : warningMessage ? (
          <div className="qc-metrics-no-message">{warningMessage}</div>
        ) : (
          graph
        )}
      </div>
    </div>
  )
}

Metric.propTypes = {
  children: PropTypes.node.isRequired,
  loading: PropTypes.bool,
  warningMessage: PropTypes.string
}

Metric.Header = MetricHeader
Metric.Query = MetricQuery

export { useMetric }
export default Metric
