import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Glyphicon, OverlayTrigger, Popover } from 'react-bootstrap'

import Loader from '../Loader'
import CMEInspectorForm from './CMEInspectorForm'
import CMEResultTable from './CMEResultTable'

import './cmeInspector.scss'

class CMEInspector extends Component {
  helpContainer = (
    <Popover id="cme-inspector-page-help">
      <p>Inspector is used to observe and audit results from all CME APIs</p>
    </Popover>
  )

  helpResultContainer = (
    <Popover id="cme-inspector-page-result-help">
      <div>Source shows from where Category/Brand originated (hover on values to get tooltip definitions).</div>
      <div>Rule shows specific rule type and id that was triggered (hover on values to get tooltip definitions).</div>
      <div>Category/Brand are given by the Source.</div>
      <div>
        CME statistics are additional metrics given by Machine Learning Algorithm (hover on values to get tooltip
        definitions).
      </div>
    </Popover>
  )

  renderResultErrors(resultErrors) {
    return (
      <div className="text-color-red cme-inspector-result-errors">
        <div className="h4">Warning:</div>
        <ul>
          {resultErrors.map((error, i) => (
            <li key={`error-${error.source}`}>
              <b>{error.source}</b> API not shown in results due to error: <i>&quot;{error.error}&quot;</i>
            </li>
          ))}
        </ul>
      </div>
    )
  }

  render() {
    const { loading, rows, errors } = this.props

    return (
      <div className="cme-inspector">
        {loading && <Loader loading overlap />}
        <div className="h3">
          <span>CME Inspector</span>
          <OverlayTrigger placement="bottom" overlay={this.helpContainer}>
            <Glyphicon glyph="info-sign" className="cme-inspector-general-help" />
          </OverlayTrigger>
        </div>
        <div>
          <CMEInspectorForm />

          {(rows?.length > 0 || errors.length > 0) && (
            <div className="cme-inspector-result">
              <div className="cme-inspector-result-header">
                <span className="h3">Results: {rows.length} rows</span>
                <OverlayTrigger placement="bottom" overlay={this.helpResultContainer}>
                  <Glyphicon glyph="info-sign" className="cme-inspector-general-help" />
                </OverlayTrigger>
              </div>
              {errors && errors.length > 0 && this.renderResultErrors(errors)}
              <CMEResultTable rows={rows} />
            </div>
          )}
        </div>
      </div>
    )
  }
}

CMEInspector.propTypes = {
  loading: PropTypes.bool.isRequired,
  rows: PropTypes.arrayOf(PropTypes.object),
  errors: PropTypes.arrayOf(PropTypes.object)
}

const mapStateToProps = ({ cmeInspector }) => ({
  loading: cmeInspector.loading,
  rows: cmeInspector.rows,
  errors: cmeInspector.errors
})

export default connect(mapStateToProps, null)(CMEInspector)
