import React from 'react'
import useDebounce from 'utils/debounce/useDebounce'
import joi from 'joi'
import { useBrandSynonymRuleQuery } from './brandSynonymRuleAPI'

const VALIDATION_SYNONYM_REGEX = /^[A-Za-z0-9.'&_+\-!()@#,:/\s]+$/i
const VALIDATION_SYNONYM_MESSAGE = `Allowed English letters, numbers and symbols: . ' & _ + - ! ( ) @ # , : /`

const validateSynonym = value => {
  const schema = joi
    .string()
    .required()
    .ruleset.regex(VALIDATION_SYNONYM_REGEX)
    .rule({ message: VALIDATION_SYNONYM_MESSAGE })
    .label('Synonym')

  const { error, value: validatedValue } = schema.validate(value)

  return { validationMessage: error?.message, validatedValue }
}

/*
 * This hook validate BSR for every synonym (grammarly & server validation)
 */
const useSynonymValidation = ({ synonym, brandId }) => {
  //  used debounce to check the rule after finishing typing
  const synonymDebounced = useDebounce(synonym?.trim())

  const { data, error, isLoading } = useBrandSynonymRuleQuery(
    {
      brandId,
      synonym: synonymDebounced
    },
    {
      skip: !brandId || synonymDebounced === ''
    }
  )

  const rule = data?.rows[0]
  const isRuleActiveFunc = rule => rule.isActive && rule.brandIsActive

  const { validationMessage, validatedValue: newSynonym } = validateSynonym(synonymDebounced)

  let isSynonymValid = false
  if ((!validationMessage && !error && !rule) || newSynonym === '') {
    isSynonymValid = true
  }

  const synonymHelpInfo = <span className="text-color-red">{validationMessage}</span>

  return {
    synonymHelpInfo,
    isSynonymValid,
    error,
    rule,
    isRuleActiveFunc,
    isLoading
  }
}

export default useSynonymValidation
