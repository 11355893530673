export const REQUESTS_STATUS = {
  PENDING: 'pending',
  PROCESSING: 'processing',
  REJECTED: 'rejected',
  APPROVED: 'approved',
  FAILED: 'failed',
  DELETED: 'deleted'
}

export const REQUESTS_TYPE = {
  BRAND: 'brand',
  CBR: 'cbr',
  MCR: 'mcr',
  MBR: 'mbr',
  BSR: 'bsr',
  MCRR: 'mcrr',
  MBRR: 'mbrr',
  GENERAL: 'general',
  GUIDELINE: 'guideline'
}

export const REQUESTS_ACTIONS = {
  DELETE: 'delete',
  APPROVE: 'approve',
  REJECT: 'reject'
}
