import React, { Component } from 'react'
import PropTypes from 'prop-types'
import DocumentTitle from 'react-document-title'
import moment from 'moment'
import { debounce, groupBy, values, upperCase } from 'lodash'

// components
import { InfiniteLoader, Table, Column, AutoSizer } from 'react-virtualized'
import {
  MenuItem,
  Modal,
  Tabs,
  Tab,
  Alert,
  Button,
  ButtonGroup,
  Glyphicon,
  OverlayTrigger,
  Tooltip,
  Row,
  Col,
  FormControl
} from 'react-bootstrap'
import { CopyToClipboard } from 'react-copy-to-clipboard'

import Unauthorized from 'components/Unauthorized'
import SearchIcon from 'components/Icon/SearchIcon'
import Box from 'components/Box'
import Input from 'components/Input'
import Loader from 'components/Loader'
import { appName, products } from 'constants/constants'

import EtlStats from '../ETLStats'

import JobOrchestratePanel from './job-orchestrate-panel'
import {
  jobIdCellFactory,
  jobTypeCellFactory,
  durationCell,
  metadataCellFactory,
  outputCell,
  jobStatusCellFactory,
  interactionsCell,
  getDayFormat
} from './column-helper'
import EtlStates from './etl-states'

// utils
import { JOB_GROUPS, JOB_LIST } from './job-list'
import { joinRoom, leaveRoom, ETL_JOBS_ROOM } from '../../socket'

import './index.scss'

const ETL_JOBS_TAB = 'ETL JOBS'
const ETL_STATES_TAB = 'ETL STATES'
const ETL_STATS_TAB = 'ETL STATS'

export default class JobListView extends Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: false,
      metadataModel: {
        open: false,
        content: null,
        header: null
      },
      overrideMetadata: false,
      metadata: undefined,
      displayAlert: true
    }
  }

  componentDidMount() {
    const { query = {}, autoRefresh } = this.props
    this.updateSearchWithDebounce(query.searchValue || '', query.searchField)
    if (autoRefresh && this.hasPermissionForAutoRefresh()) {
      joinRoom(ETL_JOBS_ROOM)
    }
  }

  componentWillUnmount() {
    if (this.props.autoRefresh) {
      leaveRoom(ETL_JOBS_ROOM)
    }
  }

  orchestrate = () => {
    const { selectedTask, metadataValidity, overrideMetadata, metadata } = this.state
    if (selectedTask && metadataValidity !== 'error') {
      let jsonMetadata = {}
      if (overrideMetadata && metadata) {
        jsonMetadata = JSON.parse(metadata)
      }
      this.props.confirmModal(
        <span>
          Are you sure you want to orchestrate a
          <strong>
            <em>{` ${selectedTask.label} `}</em>
          </strong>
          task?
        </span>,
        'Confirm Orchestration',
        { okButton: 'Yes, Orchestrate' },
        () => this.props.orchestrate(selectedTask.key, jsonMetadata)
      )
    }
  }

  isRowLoaded = ({ index }) => {
    this.setState({
      loading: false
    })
    return !!this.props.jobs[index]
  }

  loadMoreRows = ({ startIndex, stopIndex }) => {
    if (this.state && !this.state.loading) {
      this.setState({
        loading: true
      })
      this.props.loadTasks({
        offset: startIndex,
        count: stopIndex - startIndex + 1,
        searchValue: this.props.searchValue,
        searchField: this.props.searchField
      })
    }
  }

  debounceSearch = debounce((searchValue = this.props.searchValue, searchField = this.props.searchField) => {
    this.props.loadTasks({
      offset: 0,
      count: this.props.searchBatchSize,
      searchValue,
      searchField
    })
    window.scrollTo(0, 0)
  }, 300)

  updateSearchWithDebounce = (searchValue, searchFieldArg) => {
    let { searchField, updateTaskSearchFilter } = this.props
    searchField = searchFieldArg || searchField
    updateTaskSearchFilter(searchValue, searchField)
    this.debounceSearch(searchValue, searchField)
  }

  isUnauthorized = key => {
    return !this.props.userProducts || !this.props.userProducts[key] || !this.props.userProducts[key].enabled
  }

  getAllowedJobMenuOptions = () => {
    const menuItems = []
    if (!this.props.isPortalAdmin) {
      return []
    }
    const groupedTasks = groupBy(JOB_LIST, job => job.group || 'Others')
    values(JOB_GROUPS).forEach(group => {
      const groupTasks = groupedTasks[group]
      if (groupTasks && groupTasks.length) {
        menuItems.push(
          <MenuItem key={menuItems.length} header>
            {group}
          </MenuItem>
        )
        groupTasks.forEach(job => {
          menuItems.push(
            <MenuItem
              key={job.key}
              eventKey={job}
              active={job.key === this.state.selectedTask}
              title={`${job.tooltip ? `(${job.key}) - ${job.tooltip}` : job.key}`}
            >
              {job.label || job.key}
            </MenuItem>
          )
        })
        menuItems.push(<MenuItem divider key={menuItems.length} />)
      }
    })
    return menuItems
  }

  onSelectTaskChanged = key => {
    this.setState({ selectedTask: key })
  }

  handleMetadataChanged = event => {
    const value = event.target.value
    let valid = true
    if (value) {
      try {
        JSON.parse(value)
      } catch (err) {
        valid = false
      }
    }
    this.setState({
      metadata: value,
      metadataValidity: value ? (valid ? 'success' : 'error') : undefined
    })
  }

  closeMetadata = () => {
    this.setState({ metadataModel: {} })
  }

  openMetadataModel = rowData => {
    const { metadata, type, createdAt, source, user } = rowData
    const header = (
      <div>
        <span>Metadata</span>
        {' - '}
        <span>{upperCase(type)}</span>{' '}
        <small>{createdAt ? moment(createdAt).format(getDayFormat(createdAt)) : '-'}</small>
      </div>
    )
    const content = (
      <div>
        {user && (
          <div>
            {'Created by: '}
            {user.fullname} <small>({user.email})</small>
          </div>
        )}
        {source && (
          <div>
            {'Source: '}
            <CopyToClipboard text={source}>
              <span className="click-to-copy">{source}</span>
            </CopyToClipboard>
          </div>
        )}
        <pre className="metadata-viewer">{JSON.stringify(JSON.parse(metadata || {}), null, 2)}</pre>
      </div>
    )
    this.setState({
      metadataModel: {
        open: true,
        content,
        header
      }
    })
  }

  renderMetadataModel = () => {
    return (
      <Modal
        bsSize="large"
        show={this.state.metadataModel.open}
        onHide={this.closeMetadata}
        className="job-modal job-metadata"
      >
        <Modal.Header closeButton>
          <Modal.Title>{this.state.metadataModel.header}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{this.state.metadataModel.content}</Modal.Body>
      </Modal>
    )
  }

  hasPermissionForAutoRefresh = () => {
    return false // this.props.isPortalAdmin
  }

  toggleAutoRefresh = () => {
    const { autoRefresh, updateTaskAutoRefresh } = this.props
    const newValue = !autoRefresh
    updateTaskAutoRefresh(newValue)
    if (newValue && this.hasPermissionForAutoRefresh()) {
      joinRoom(ETL_JOBS_ROOM)
    } else {
      leaveRoom(ETL_JOBS_ROOM)
    }
  }

  updateSearchEntity = event => {
    const { searchValue, updateTaskSearchFilter } = this.props
    updateTaskSearchFilter(searchValue, event.target.value)
    this.debounceSearch(searchValue, event.target.value)
  }

  searchInField = field => value => {
    this.props.updateTaskSearchFilter(value, field)
    this.debounceSearch(value)
  }

  renderSearchOptions() {
    const searchEntities = {
      type: { display: 'Job Type' },
      status: { display: 'Job Status' },
      id: { display: 'Id' },
      batchId: { display: 'Batch' },
      source: { display: 'Source' },
      metadata: { display: 'Metadata' }
    }
    // In case you need to allow certain search fields only for admins
    // Please uncomment following code and move it there
    // if (this.props.isPortalAdmin) {
    //   searchEntities = {
    //     ...searchEntities,
    //     userId: { display: 'User Id' },
    //     all: { display: 'All' }
    //   }
    // }
    return Object.keys(searchEntities).map(searchOption => {
      const display = searchEntities[searchOption].display

      return (
        <option key={searchOption} value={searchOption}>
          {display}
        </option>
      )
    })
  }

  render() {
    const { autoRefresh, searchField, documentTitle, isPortalAdmin } = this.props

    if (this.isUnauthorized(products.adminToolsJobMonitoring)) {
      return <Unauthorized />
    }
    const jobs = this.getAllowedJobMenuOptions()
    return (
      <DocumentTitle title={documentTitle ? `${appName} | ${documentTitle}` : appName}>
        <Tabs
          onSelect={key => this.setState({ activeTab: key })}
          id="jobs-page-tabs"
          className="portal-tabs container"
          activeKey={this.state.activeTab}
        >
          <Tab eventKey={ETL_JOBS_TAB} title={ETL_JOBS_TAB}>
            <div>
              <JobOrchestratePanel
                jobs={jobs}
                selectedTask={this.state.selectedTask}
                onSelectTaskChanged={this.onSelectTaskChanged}
                overrideMetadata={this.state.overrideMetadata}
                onToggleOverrideMetadata={() => {
                  this.setState({
                    overrideMetadata: !this.state.overrideMetadata
                  })
                }}
                metadata={this.state.metadata}
                metadataValidity={this.state.metadataValidity}
                handleMetadataChanged={this.handleMetadataChanged}
                onOrchestrate={this.orchestrate}
              />
              <div className="refresh-actions">
                {
                  <div className="auto-refresh-warning">
                    {this.hasPermissionForAutoRefresh() && this.state.displayAlert && (
                      <Alert
                        bsStyle="warning"
                        onDismiss={() => {
                          this.setState({ displayAlert: false })
                        }}
                      >
                        By default the jobs will no longer update in real time. You can use&nbsp;&nbsp;
                        <Glyphicon glyph="refresh" />
                        &nbsp;&nbsp;button to refresh jobs manually.
                        <br />
                        In case, you still need to see real time updates, you can enable it by clicking on{' '}
                        <Glyphicon glyph="play" /> button.
                      </Alert>
                    )}
                  </div>
                }
                <ButtonGroup className="pull-right">
                  {this.hasPermissionForAutoRefresh() && (
                    <OverlayTrigger
                      placement="left"
                      overlay={
                        <Tooltip id="autoRefresh">
                          {autoRefresh ? 'Pause real time updates' : 'Enable real time updates'}
                        </Tooltip>
                      }
                    >
                      <Button onClick={this.toggleAutoRefresh}>
                        <Glyphicon glyph={autoRefresh ? 'pause' : 'play'} />
                      </Button>
                    </OverlayTrigger>
                  )}
                  <OverlayTrigger placement="left" overlay={<Tooltip id="refresh">{'Refresh Jobs'}</Tooltip>}>
                    <Button
                      onClick={() => {
                        this.debounceSearch(this.props.searchValue)
                      }}
                    >
                      <Glyphicon glyph="refresh" />
                    </Button>
                  </OverlayTrigger>
                </ButtonGroup>
              </div>
              <Box className="flex alignItemsCenter">
                <div style={{ width: '100%' }}>
                  <Row>
                    {
                      <Col md={2}>
                        <FormControl
                          componentClass="select"
                          placeholder="Search Field"
                          onChange={this.updateSearchEntity}
                          value={searchField}
                        >
                          {this.renderSearchOptions()}
                        </FormControl>
                      </Col>
                    }

                    <Col md={10}>
                      <Input
                        clearButton
                        placeholder={`Search`}
                        className="CheckBoxMultiSelect input flex flex1"
                        value={this.props.searchValue}
                        iconName={<SearchIcon width={24} height={24} />}
                        didClickClearButton={() => this.updateSearchWithDebounce('')}
                        textDidChange={this.updateSearchWithDebounce}
                      />
                    </Col>
                  </Row>
                </div>
              </Box>
              <div className="JobList top-spacing-sm" style={{ height: 700 }}>
                <InfiniteLoader
                  isRowLoaded={this.isRowLoaded}
                  loadMoreRows={this.loadMoreRows}
                  rowCount={this.props.jobsCount}
                  minimumBatchSize={this.props.minimumBatchSize}
                >
                  {({ onRowsRendered }) => (
                    <AutoSizer>
                      {({ width }) => (
                        <div>
                          <Table
                            width={width}
                            height={700}
                            headerHeight={20}
                            rowHeight={80}
                            onRowsRendered={onRowsRendered}
                            rowCount={this.props.jobs.length}
                            rowGetter={({ index }) => this.props.jobs[index]}
                          >
                            <Column
                              label="Id"
                              width={100}
                              flexGrow={1}
                              dataKey={'id'}
                              className="job-cell job-cell-id"
                              cellRenderer={jobIdCellFactory(this.searchInField('batchId'))}
                            />
                            <Column
                              label="Type"
                              width={200}
                              flexGrow={1}
                              dataKey={'type'}
                              className="job-cell job-cell-type"
                              cellRenderer={jobTypeCellFactory(this.searchInField('type'))}
                            />
                            <Column
                              label="Started - Ended"
                              width={150}
                              flexGrow={1}
                              dataKey={'startedAt'}
                              className="job-cell job-cell-time"
                              cellRenderer={durationCell}
                            />
                            <Column
                              label="Metadata"
                              width={200}
                              flexGrow={2}
                              dataKey={'metadata'}
                              className="job-cell job-cell-metadata"
                              cellRenderer={metadataCellFactory(this.searchInField('metadata'), this.openMetadataModel)}
                            />
                            <Column
                              label="Output"
                              width={150}
                              flexGrow={1}
                              dataKey={'output'}
                              className="job-cell job-cell-output"
                              cellRenderer={outputCell}
                            />
                            <Column
                              label="Status"
                              width={100}
                              flexGrow={0}
                              dataKey={'output'}
                              className="job-cell job-cell-output"
                              cellRenderer={jobStatusCellFactory(this.searchInField('status'))}
                            />
                            <Column
                              label="Actions"
                              width={100}
                              flexGrow={1}
                              dataKey={'id'}
                              className="job-cell job-cell-actions"
                              cellRenderer={interactionsCell}
                            />
                          </Table>
                        </div>
                      )}
                    </AutoSizer>
                  )}
                </InfiniteLoader>
                {this.props.loading && <Loader loading overlap />}
              </div>
              {this.renderMetadataModel()}
            </div>
          </Tab>

          {isPortalAdmin && (
            <Tab eventKey={ETL_STATES_TAB} title={ETL_STATES_TAB}>
              <EtlStates />
            </Tab>
          )}
          {isPortalAdmin && (
            <Tab eventKey={ETL_STATS_TAB} title={ETL_STATS_TAB}>
              <EtlStats />
            </Tab>
          )}
        </Tabs>
      </DocumentTitle>
    )
  }
}

JobListView.propTypes = {
  selectedJobs: PropTypes.object,
  jobs: PropTypes.array,
  loading: PropTypes.bool,
  jobsCount: PropTypes.number,
  autoRefresh: PropTypes.bool,
  searchField: PropTypes.string,
  searchValue: PropTypes.string,
  query: PropTypes.object,
  loadTasks: PropTypes.func,
  userProducts: PropTypes.object,
  isPortalAdmin: PropTypes.bool,
  minimumBatchSize: PropTypes.number,
  searchBatchSize: PropTypes.number,
  updateTaskSearchFilter: PropTypes.func,
  orchestrate: PropTypes.func,
  updateTaskAutoRefresh: PropTypes.func,
  confirmModal: PropTypes.func,
  documentTitle: PropTypes.string
}

JobListView.defaultProps = {
  minimumBatchSize: 20,
  searchBatchSize: 30,
  documentTitle: 'Job Monitoring'
}
