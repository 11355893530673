import commonRtkApi from 'commonRtkApi.js'

const bronzeToSilverStepAPI = commonRtkApi.injectEndpoints({
  endpoints: builder => ({
    bronzeToSilverRun: builder.query({
      query: domain => {
        const data = { domain }
        return {
          url: `/supra-orchestration/bronze-to-silver-run`,
          method: 'POST',
          data
        }
      }
    }),
    getBronzeToSilverStatus: builder.query({
      query: ({ runId, sessionId }) => {
        const data = { runId, sessionId }
        return {
          url: `/supra-orchestration/bronze-to-silver-status`,
          method: 'POST',
          data
        }
      }
    })
  })
})

export const { useLazyBronzeToSilverRunQuery, useLazyGetBronzeToSilverStatusQuery } = bronzeToSilverStepAPI
