import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

/**
 * This is the cell for showing long text.
 * By default, we crop the text after 4 lines
 * add title and ellipsis if text is too long
 */
const TextCell = ({ text = '', bsClass }) => {
  return (
    <div className={cx('sb-table-text-cell', bsClass)} title={text}>
      {text}
    </div>
  )
}

TextCell.propTypes = {
  text: PropTypes.string,
  bsClass: PropTypes.string
}

export default TextCell
