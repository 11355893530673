import React from 'react'
import PropTypes from 'prop-types'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

import { BooleanCell } from 'components/DataTable'

const CategoryGuidelineBooleanCell = ({ isActive, isCategoryActive, isLeaf }) => {
  let reason = 'Category Guideline is active because related category is active and is leaf'

  if (!isLeaf) {
    reason = "Category Guideline is disable because related category isn't a leaf"
  }

  if (!isCategoryActive) {
    reason = "Category Guideline is disable because related category isn't active"
  }

  if (!isCategoryActive && !isLeaf) {
    reason = "Category Guideline is disable because related category isn't active and isn't a leaf"
  }

  return (
    <div>
      <OverlayTrigger placement="top" overlay={<Tooltip id="category-guideline-bool-cell-tooltip">{reason}</Tooltip>}>
        <div style={{ width: '17px', height: '17px', display: 'inline-block' }}>
          <BooleanCell isActive={isActive} />
        </div>
      </OverlayTrigger>
    </div>
  )
}

CategoryGuidelineBooleanCell.propTypes = {
  isActive: PropTypes.bool.isRequired,
  isCategoryActive: PropTypes.bool.isRequired,
  isLeaf: PropTypes.bool.isRequired
}

export default CategoryGuidelineBooleanCell
