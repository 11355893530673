import React from 'react'
import PropTypes from 'prop-types'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

import { REQUESTS_TYPE } from './../constants'

export default function RequestPayloadCell({ payload, type }) {
  let cell
  switch (type) {
    case REQUESTS_TYPE.BRAND: {
      const { brand, categories, synonyms } = payload
      cell = (
        <>
          <div>New Brand: {brand}</div>
          {categories &&
            categories.map((category, i) => (
              <div key={category.id}>
                Category #{i + 1} ID:{category.id} {category.fullPath}
              </div>
            ))}
          {synonyms &&
            synonyms.map((synonym, i) => (
              <div key={synonym}>
                Synonym #{i + 1}: {synonym}
              </div>
            ))}
        </>
      )
      break
    }
    case REQUESTS_TYPE.CBR: {
      const { categoryId, categoryFullPath, brandId, brandFullPath, isMatchAnywhere } = payload
      cell = (
        <>
          <div>
            Category ID:{categoryId} {categoryFullPath}
          </div>
          <div>
            Brand ID:{brandId} {brandFullPath}
          </div>
          {isMatchAnywhere && <div>Match Anywhere: true</div>}
        </>
      )
      break
    }
    case REQUESTS_TYPE.MBR: {
      const { merchantId, merchantName, brandId, brandFullPath } = payload
      cell = (
        <span>
          <div>
            Merchant ID:{merchantId} {merchantName}
          </div>
          <div>
            Brand ID:{brandId} {brandFullPath}
          </div>
        </span>
      )
      break
    }
    case REQUESTS_TYPE.MCR: {
      const { merchantId, merchantName, categoryId, categoryFullPath } = payload
      cell = (
        <>
          <div>
            Merchant ID:{merchantId} {merchantName}
          </div>
          <div>
            Category ID:{categoryId} {categoryFullPath}
          </div>
        </>
      )
      break
    }
    case REQUESTS_TYPE.BSR: {
      const { brandId, brandFullPath, synonym, synonyms } = payload
      const actualSynonyms = synonyms || [{ synonym }]
      cell = (
        <>
          <div>
            Brand ID:{brandId} {brandFullPath}
          </div>
          {actualSynonyms.map(({ synonym }) => (
            <div key={synonym}>Synonym: {synonym}</div>
          ))}
        </>
      )
      break
    }
    case REQUESTS_TYPE.MCRR: {
      const {
        merchantId,
        merchantName,
        categoryId,
        categoryFullPath,
        includeParam,
        excludeParam,
        ruleType,
        isExcludeRule
      } = payload
      cell = (
        <>
          <div>
            Merchant ID:{merchantId} {merchantName}
          </div>
          <div>
            Category ID:{categoryId} {categoryFullPath}
          </div>
          <div>Include param: {includeParam}</div>
          {excludeParam && <div>Exclude param: {excludeParam}</div>}
          <div>Rule type: {ruleType}</div>
          {isExcludeRule && <div>Is Exclude Rule: true</div>}
        </>
      )
      break
    }
    case REQUESTS_TYPE.MBRR: {
      const {
        merchantId,
        merchantName,
        brandId,
        brandFullPath,
        includeParam,
        excludeParam,
        ruleType,
        isExcludeRule
      } = payload
      cell = (
        <>
          <div>
            Merchant ID:{merchantId} {merchantName}
          </div>
          <div>
            Brand ID:{brandId} {brandFullPath}
          </div>
          <div>Include param: {includeParam}</div>
          {excludeParam && <div>Exclude param: {excludeParam}</div>}
          <div>Rule type: {ruleType}</div>
          {isExcludeRule && <div>Is Exclude Rule: true</div>}
        </>
      )
      break
    }
    case REQUESTS_TYPE.GUIDELINE:
    case REQUESTS_TYPE.GENERAL: {
      const { request_notes, review_notes } = payload
      cell = (
        <>
          <div>
            <ins>Requestor notes:</ins> {request_notes}
          </div>
          {review_notes && (
            <div>
              <br />
              <ins>Reviewer notes:</ins> {review_notes}
            </div>
          )}
        </>
      )
      break
    }
    default:
      cell = <span>[UNKNOWN TYPE: {type}]</span>
      break
  }

  return (
    <OverlayTrigger placement="right" overlay={<Tooltip id="request-payload-cell-tooltip">{cell}</Tooltip>}>
      <div className="sb-table-text-line-6-cell">{cell}</div>
    </OverlayTrigger>
  )
}

RequestPayloadCell.propTypes = {
  payload: PropTypes.object,
  type: PropTypes.string.isRequired,
  request_notes: PropTypes.string,
  review_notes: PropTypes.string
}
