import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Button } from 'react-bootstrap'

import MetricsWarning from './MetricsWarning'

import DictionaryWidget from './DictionaryWidget'
import IncludeWidget from './IncludeWidget'
import ExcludeWidget from './ExcludeWidget'
import MisbrandWidget from './MisbrandWidget'
import IncludeCoverageWidget from './IncludeCoverageWidget'

import './metrics.scss'

import { METRIC_GRAPTH } from './constants'
import { METRICS_STORE_PATH } from './metricsReducer'
import metricsActions from './metricsActions'
import useCategoryInfo from './Metric/useCategoryInfo'

const widgets = [
  {
    name: 'Dictionary',
    id: METRIC_GRAPTH.DICTIONARY,
    component: DictionaryWidget
  },
  {
    name: 'Include',
    id: METRIC_GRAPTH.INCLUDE,
    component: IncludeWidget
  },
  {
    name: 'Exclude',
    id: METRIC_GRAPTH.EXCLUDE,
    component: ExcludeWidget
  },
  {
    name: 'Misbrand',
    id: METRIC_GRAPTH.MISBRAND,
    component: MisbrandWidget
  },
  {
    name: 'Include Coverage',
    id: METRIC_GRAPTH.INCLUDE_COVERAGE,
    component: IncludeCoverageWidget
  }
]

const Metrics = () => {
  const dispatch = useDispatch()
  const { widgetsVisibility } = useSelector(state => state[METRICS_STORE_PATH])

  const { hasSelectedCategory, isMultipleSelection } = useCategoryInfo()

  const isActive = hasSelectedCategory && !isMultipleSelection

  const onClickToggleWidget = event => {
    const { target } = event
    target.blur() // prevent focus button

    const widget = target.dataset.widget
    dispatch(metricsActions.toggleWidget({ widget, show: !widgetsVisibility[widget] }))
  }

  const noActvieWidgets = Object.values(widgetsVisibility).filter(Boolean).length === 0

  return (
    <div className="qc-tool-chart-tab-widget qc-metrics">
      <div className="qc-metrics-menu">
        <span className="h5 qc-metrics-menu-label">Metrics:</span>
        <div className="inline-block" onClick={onClickToggleWidget}>
          {widgets.map(widget => (
            <Button
              key={widget.id}
              className="ml-3"
              data-widget={widget.id}
              active={widgetsVisibility[widget.id]}
              disabled={!isActive}
            >
              {widget.name}
            </Button>
          ))}
        </div>
      </div>

      {widgets.map(widget =>
        isActive && widgetsVisibility[widget.id]
          ? React.createElement(widget.component, {
              key: widget.id
            })
          : null
      )}

      {noActvieWidgets && <div className="qc-metrics-no-message">No Metrics selected.</div>}

      <MetricsWarning hasSelectedCategory={hasSelectedCategory} isMultipleSelection={isMultipleSelection} />
    </div>
  )
}

export default Metrics
