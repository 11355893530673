import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import moment from 'moment'
import { delay } from 'lodash'

import { Button, Glyphicon, Tooltip, OverlayTrigger } from 'react-bootstrap'
import { Link } from 'react-router-dom'

import { confirmModal } from 'actions/modal-actions'
import { orchestrate } from 'actions/task-actions'
import deliveryJobsActions from './deliveryJobsActions'
import { TASK_STATUS } from 'constants/constants'
import { JOB_TYPES } from 'components/JobListPage/job-list'

const DATE_SHORT_FORMAT = 'MMM DD'
const DATE_FORMAT = 'MM-DD-YYYY hh:mm:ss A'

class PublishDeliveryJobs extends Component {
  componentDidMount() {
    this.refreshTasksStatus()
  }

  refreshTasksStatus = () => {
    this.props.fetchDeliveryJobsTasks()
  }

  openPublishModal = () => {
    const { confirmModal } = this.props

    confirmModal(
      <div>
        <p>Publish process includes running ETL process in Snowflake. </p>
        <p>
          Then update Delivery Jobs from status <em>Ready</em> to <em>Published</em>
        </p>
        <p>
          <strong>
            <em>Are you sure you want to run Publish process ?</em>
          </strong>
        </p>
      </div>,
      'Confirm Publish',
      { okButton: `Start Publish` },
      () => this.startPublish()
    )
  }

  startPublish = () => {
    this.props.setLoadingDeliveryJobsTasks(true)
    this.props.orchestrate(JOB_TYPES.PUBLISH_DELIVERY)
    delay(() => this.refreshTasksStatus(), 2500)
  }

  getStatusText(text = 'undefined') {
    let color = ''
    switch (text) {
      case TASK_STATUS.PENDING:
      case TASK_STATUS.PROCESSING:
        color = 'blue'
        break
      case TASK_STATUS.SUCCESS:
        color = 'green'
        break
      case TASK_STATUS.CANCELLED:
        color = 'yellow'
        break
      case TASK_STATUS.FAILED:
        color = 'red'
        break
      default:
        color = 'red'
    }

    return (
      <span className={`text-color-${color}`}>
        <strong>{text}</strong>
      </span>
    )
  }

  getTooltip(task) {
    return (
      <Tooltip id="delivery-jobs-publish-task-tooltip">
        <p>Click to refresh task information</p>
        {task && (
          <>
            <p>Last task info:</p>
            <ul>
              <li>Id: {task.id}</li>
              <li>Status: {task.status}</li>
              <li>UserId: {task.userId}</li>
              <li>Create: {moment(task.createdAt).format(DATE_FORMAT)}</li>
              <li>Start: {task.startedAt && moment(task.startedAt).format(DATE_FORMAT)}</li>
              <li>End: {task.endedAt && moment(task.endedAt).format(DATE_FORMAT)}</li>
            </ul>
          </>
        )}
      </Tooltip>
    )
  }

  render() {
    const { hasEditAccess, task, loading } = this.props
    const status = task && task.status

    return (
      <div className="rightFormButton delivery-jobs-publish">
        {hasEditAccess && (
          <Button
            bsStyle="default"
            className="right"
            onClick={this.openPublishModal}
            disabled={status === TASK_STATUS.PENDING || status === TASK_STATUS.PROCESSING || loading}
          >
            <Glyphicon glyph="cloud-upload" />
            Publish
          </Button>
        )}

        <div className="delivery-jobs-publish-status">
          {loading ? (
            <span>Loading ...</span>
          ) : (
            <OverlayTrigger delayShow={100} placement="bottom" overlay={this.getTooltip(task)}>
              <span
                title="Click to refresh task information"
                className="delivery-jobs-publish-text"
                onClick={this.refreshTasksStatus}
              >
                Last run: {this.getStatusText(status)} {task && moment(task.createdAt).format(DATE_SHORT_FORMAT)}
                <Glyphicon glyph="refresh" />
              </span>
            </OverlayTrigger>
          )}
        </div>

        <Link to={`/admin/jobs/?searchField=type&&searchValue=${JOB_TYPES.PUBLISH_DELIVERY}`}>See all tasks</Link>
      </div>
    )
  }
}

PublishDeliveryJobs.propTypes = {
  hasEditAccess: PropTypes.bool.isRequired,

  loading: PropTypes.bool,
  task: PropTypes.object,

  confirmModal: PropTypes.func,
  orchestrate: PropTypes.func,
  fetchDeliveryJobsTasks: PropTypes.func,
  setLoadingDeliveryJobsTasks: PropTypes.func
}

function mapStateToProps({ deliveryJobs: { etlTask } }) {
  return {
    loading: etlTask.loading,
    task: etlTask.task
  }
}

const mapDispatchToProps = {
  confirmModal,
  orchestrate,
  fetchDeliveryJobsTasks: deliveryJobsActions.fetchDeliveryJobsTasks,
  setLoadingDeliveryJobsTasks: deliveryJobsActions.setLoadingDeliveryJobsTasks
}

export default connect(mapStateToProps, mapDispatchToProps)(PublishDeliveryJobs)
