import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Glyphicon } from 'react-bootstrap'

import RVTable from '../../../../Table/ReactVirtualizedTable'
import TextInputField from '../../../Common/TextInputField'
import Loader from '../../../../Loader'

import {
  fetchCategories,
  sortAssociatedCategories,
  onChangeSearchValue,
  updateCategorySelection,
  updateCategoryLabel
} from '../../../../../actions/delivery-config-actions'
import { caretRender, renderQuality } from '../../../Common/table-utils'
import CategoryConfigFilters, { CATEGORY_FITLERS } from './CategoryConfigFilters'
import { createAlert } from '../../../../../actions/app-actions'

import './index.scss'

const HEADER_HEIGHT = 42
const ROW_HEIGHT = 30
const MAX_TABLE_HEIGHT = 480
const MIN_BATCH_SIZE = 25
const LIMIT = 20

class CategoryConfig extends Component {
  componentDidMount() {
    this.fetchCategoriesInit()
    this.categoryFilter = CATEGORY_FITLERS.ALL
  }

  getTableData = () => {
    const { editable, data, associatedCategories, selectedCategoryIds } = this.props
    const tableData = editable ? data : this._getCorrectAssociatedCategories(associatedCategories)
    return tableData.map(category => {
      const { category_id } = category
      return {
        ...category,
        key: category_id,
        value: !editable || selectedCategoryIds.includes(category_id)
      }
    })
  }

  onCategorySortChange = (sortBy, sortOrder) => {
    const { editable, sortAssociatedCategories } = this.props
    if (editable) {
      this.fetchCategoriesInit({
        sortBy,
        sortOrder: sortOrder.toLowerCase()
      })
    } else {
      sortAssociatedCategories({
        sortBy,
        sortOrder: sortOrder.toLowerCase()
      })
    }
  }

  onCategoryFilterChange = newCategoryFilter => {
    this.categoryFilter = newCategoryFilter
    this.fetchCategoriesInit()
  }

  _getCorrectAssociatedCategories(categories = []) {
    return categories.filter(c => c.is_active && c.is_leaf)
  }
  _getCorrectAssociatedCategoriesIds() {
    const ids = this._getCorrectAssociatedCategories(this.props.associatedCategories).map(c => c.category_id)
    return ids
  }
  _getInCorrectAssociatedCategoriesIds() {
    const ids = this.props.associatedCategories.filter(c => !c.is_active || !c.is_leaf).map(c => c.category_id)
    return ids
  }

  fetchData = (offset, limit, searchValue) => {
    this.fetchCategories({ offset, limit, searchValue })
  }

  fetchCategoriesInit = payload => this.fetchCategories({ offset: 0, limit: LIMIT, ...payload })

  fetchCategories = payload => {
    const {
      sortBy = this.props.sortBy,
      sortOrder = this.props.sortOrder,
      offset = this.props.offset,
      limit = this.props.limit,
      searchField = 'full_path',
      searchValue = this.props.searchTerm,
      editable = this.props.editable
    } = payload || {}

    const params = {
      sortBy,
      sortOrder,
      offset,
      limit,
      searchField,
      searchValue,
      editable
    }

    if (this.categoryFilter === CATEGORY_FITLERS.SELECTED) {
      params.searchCategoryIds = this._getCorrectAssociatedCategoriesIds()
    }
    if (this.categoryFilter === CATEGORY_FITLERS.INCORRECT) {
      params.searchCategoryIds = this._getInCorrectAssociatedCategoriesIds()
    }

    this.props.fetchCategories(params)
  }

  calcTableHeight = noOfRows => {
    const actualHeight = HEADER_HEIGHT + noOfRows * ROW_HEIGHT
    return Math.min(actualHeight, MAX_TABLE_HEIGHT)
  }

  renderColumnHeader = headerData => {
    const { dataKey, sortBy, label, sortDirection } = headerData
    const direction = dataKey === sortBy ? sortDirection.toLowerCase() : null
    return (
      <span>
        {label} {caretRender(direction)}
      </span>
    )
  }

  getColumns = () => {
    const columns = []
    if (this.props.editable) {
      columns.push({
        key: 'value',
        label: '',
        type: 'checkbox',
        width: 20,
        flexGrow: 0,
        flexShrink: 0
      })
    }
    columns.push(
      {
        key: 'category_id',
        label: 'ID',
        width: 40,
        disableSort: false,
        headerRenderer: headerData => this.renderColumnHeader(headerData),
        flexGrow: 0,
        flexShrink: 0
      },
      {
        key: 'full_path',
        label: 'CATEGORY',
        width: 600,
        className: 'category-full-path-cell',
        disableSort: false,
        headerRenderer: headerData => this.renderColumnHeader(headerData)
      },
      {
        key: 'quality',
        label: 'QUALITY',
        disableSort: false,
        width: 80,
        headerStyle: { textAlign: 'center' },
        cellRenderer: category => renderQuality(category.quality),
        headerRenderer: headerData => this.renderColumnHeader(headerData),
        flexGrow: 0,
        flexShrink: 0
      }
    )
    if (this.props.editable && this.categoryFilter === CATEGORY_FITLERS.INCORRECT) {
      columns.push({
        key: 'is_active',
        label: 'Status',
        cellRenderer: category => {
          return (
            <div className="text-center incorrect-category-status">
              {!category.is_active && <Glyphicon glyph="remove" className="" title="Category is deleted" />}
              {!category.is_leaf && <Glyphicon glyph="link" className="" title="Category is parent" />}
            </div>
          )
        },
        width: 40
      })
    }
    return columns
  }

  handleLabelChange = event => {
    const value = event.target.value
    const { updateCategoryLabel } = this.props
    updateCategoryLabel({
      updatedCategoryLabel: value,
      isLabelValid: value && !!value.trim(),
      dirty: true
    })
  }

  updateCategorySelection = (categoryId, value) => {
    const inCorrectCategories = this._getInCorrectAssociatedCategoriesIds()
    const tryToEnableIncorrectCategory = inCorrectCategories.includes(categoryId) && value === true
    if (tryToEnableIncorrectCategory) {
      this.props.createAlert(
        'warning',
        'In-active and parent categories are un-deliverable',
        `You can't select not-deliverable Category`
      )
    } else {
      this.props.updateCategorySelection(categoryId)
    }
  }

  render() {
    const data = this.getTableData()
    const {
      loading,
      count,
      editable,
      onChangeSearchValue,
      searchTerm,
      sortBy,
      sortOrder,
      updatedCategoryLabel,
      isLabelValid,
      dirty
    } = this.props
    return (
      <div className="category-config">
        {loading && <Loader overlap />}
        <TextInputField
          id="cat-label-name"
          label="Category Label"
          help={isLabelValid === false ? 'Category Label cannot be empty' : null}
          validationState={dirty && isLabelValid === false ? 'error' : null}
          required
          type="text"
          value={updatedCategoryLabel || ''}
          onChange={this.handleLabelChange}
          autoFocus
          readOnly={!editable}
          info={'This label will be shown to clients in the final deliverable'}
        />

        {editable && (
          <CategoryConfigFilters
            defaultValue={this.categoryFilter}
            onCategoryFilterChange={this.onCategoryFilterChange}
            correctCategoriesCount={this._getCorrectAssociatedCategoriesIds().length}
            inCorrectCategoriesCount={this._getInCorrectAssociatedCategoriesIds().length}
          />
        )}
        <RVTable
          columns={this.getColumns()}
          data={data}
          onCheckedValueChange={this.updateCategorySelection}
          fetchData={this.fetchData}
          enableSearch={editable}
          remoteRowCount={editable ? count : data.length}
          search={searchTerm}
          minimumBatchSize={MIN_BATCH_SIZE}
          updateFilter={onChangeSearchValue}
          searchPlaceHolder="Search category name"
          sortBy={sortBy}
          sortDirection={sortOrder}
          sort={this.onCategorySortChange}
          rowHeight={ROW_HEIGHT}
          headerHeight={HEADER_HEIGHT}
          height={this.calcTableHeight(data.length)}
          fetchCount={MIN_BATCH_SIZE}
        />
        {data.length === 0 && <div className="no-category-data-msg">{'NO DATA FOUND'}</div>}
      </div>
    )
  }
}

CategoryConfig.propTypes = {
  deliverableId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  data: PropTypes.array,
  sortBy: PropTypes.string,
  sortOrder: PropTypes.string,
  fetchCategories: PropTypes.func,
  offset: PropTypes.number,
  limit: PropTypes.number,
  searchTerm: PropTypes.string,
  count: PropTypes.number,
  onChangeSearchValue: PropTypes.func,
  selectedCategoryIds: PropTypes.array,
  updateCategorySelection: PropTypes.func,
  loading: PropTypes.bool,
  editable: PropTypes.bool,
  associatedCategories: PropTypes.array,
  dirty: PropTypes.bool,
  sortAssociatedCategories: PropTypes.func,
  isLabelValid: PropTypes.bool,
  updateCategoryLabel: PropTypes.func,
  updatedCategoryLabel: PropTypes.string,
  createAlert: PropTypes.func.isRequired
}

function mapStateToProps({
  dm: { selectedDeliverable: { id: deliverableId } = {} },
  deliveryConfig: { categoryForm, associatedCategories }
}) {
  return {
    deliverableId,
    sortBy: categoryForm.sortBy,
    sortOrder: categoryForm.sortOrder,
    offset: categoryForm.offset,
    limit: categoryForm.limit,
    data: categoryForm.data,
    searchTerm: categoryForm.searchTerm,
    count: categoryForm.filteredCount,
    selectedCategoryIds: categoryForm.selectedCategoryIds,
    loading: categoryForm.loading,
    associatedCategories,
    dirty: categoryForm.dirty,
    updatedCategoryLabel: categoryForm.updatedCategoryLabel,
    isLabelValid: categoryForm.isLabelValid
  }
}

export default connect(mapStateToProps, {
  fetchCategories,
  sortAssociatedCategories,
  onChangeSearchValue,
  updateCategorySelection,
  updateCategoryLabel,
  createAlert
})(CategoryConfig)
