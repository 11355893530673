import moment from 'moment'

function getCMEDictionaryColumns({ getActions }) {
  const cmeDictionaryColumns = [
    {
      field: 'id',
      headerName: 'ID',
      type: 'string',
      headerClassName: 'cme-dictionary-table-header',
      sortable: false,
      filterable: false
    },
    {
      field: 'merchant_id',
      headerName: 'Merchant ID',
      type: 'number',
      headerClassName: 'cme-dictionary-table-header'
    },
    {
      field: 'norm_description',
      headerName: 'Norm Desc',
      headerClassName: 'cme-dictionary-table-header'
    },
    {
      field: 'description',
      headerName: 'Description',
      headerClassName: 'cme-dictionary-table-header'
    },
    {
      field: 'norm_desc_hash',
      headerName: 'Norm Desc Hash',
      type: 'string',
      headerClassName: 'cme-dictionary-table-header'
    },
    {
      field: 'norm_desc_hash_int',
      headerName: 'Norm Desc Hash Int',
      type: 'number',
      headerClassName: 'cme-dictionary-table-header',
      valueGetter: value => {
        return value?.toString()
      }
    },
    {
      field: 'category_id',
      headerName: 'Category ID',
      type: 'number',
      headerClassName: 'cme-dictionary-table-header',
      valueGetter: value => {
        return value?.toString()
      }
    },
    {
      field: 'category_full_path',
      headerName: 'Category Full Path',
      type: 'string',
      headerClassName: 'cme-dictionary-table-header'
    },
    {
      field: 'category_added_by',
      headerName: 'Category Added By',
      headerClassName: 'cme-dictionary-table-header'
    },
    {
      field: 'category_add_time',
      headerName: 'Category Add Time',
      headerClassName: 'cme-dictionary-table-header',
      valueGetter: value => {
        if (!value) return ''
        return moment(value).format('MMM DD, YYYY HH:mm (Z)')
      }
    },
    {
      field: 'brand_id',
      headerName: 'Brand ID',
      type: 'number',
      headerClassName: 'cme-dictionary-table-header',
      valueGetter: value => {
        return value?.toString()
      }
    },
    {
      field: 'brand_full_path',
      headerName: 'Brand Full Path',
      type: 'string',
      headerClassName: 'cme-dictionary-table-header'
    },
    {
      field: 'brand_added_by',
      headerName: 'Brand Added By',
      headerClassName: 'cme-dictionary-table-header'
    },
    {
      field: 'brand_add_time',
      headerName: 'Brand Add Time',
      headerClassName: 'cme-dictionary-table-header',
      valueGetter: value => {
        if (!value) return ''
        return moment(value).format('MMM DD, YYYY HH:mm (Z)')
      }
    },
    {
      field: 'is_deleted',
      headerName: 'Deleted',
      type: 'boolean',
      headerClassName: 'cme-dictionary-table-header'
    },
    {
      field: 'staging',
      headerName: 'Staging',
      type: 'boolean',
      headerClassName: 'cme-dictionary-table-header',
      sortable: false
    },
    {
      field: 'train',
      headerName: 'Train',
      type: 'boolean',
      headerClassName: 'cme-dictionary-table-header'
    },
    {
      field: 'delete_time',
      headerName: 'Delete Time',
      headerClassName: 'cme-dictionary-table-header',
      valueGetter: value => {
        if (!value) return ''
        return moment(value).format('MMM DD, YYYY HH:mm (Z)')
      }
    },
    {
      field: 'last_modified_time',
      headerName: 'Last Modified At',
      headerClassName: 'cme-dictionary-table-header',
      valueGetter: value => {
        if (!value) return ''
        return moment(value).format('MMM DD, YYYY HH:mm (Z)')
      }
    },
    {
      field: 'sf_create_time',
      headerName: 'SF Create Time',
      headerClassName: 'cme-dictionary-table-header',
      valueGetter: value => {
        if (!value) return ''
        return moment(value).format('MMM DD, YYYY HH:mm (Z)')
      }
    },
    {
      field: 'sf_update_time',
      headerName: 'SF Update Time',
      headerClassName: 'cme-dictionary-table-header',
      valueGetter: value => {
        if (!value) return ''
        return moment(value).format('MMM DD, YYYY HH:mm (Z)')
      }
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions
    }
  ]
  return cmeDictionaryColumns
}

export default getCMEDictionaryColumns
