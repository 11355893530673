import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import SingleDateInput from '../../../SingleDateInput'
import TextInputField from '../../Common/TextInputField'
import { Form, Button, FormGroup } from 'react-bootstrap'
import ArrowIcon from '../../../Icon/ArrowUp'
import Loader from '../../../Loader'

import moment from 'moment'

import { updateEditDeadlineForm, updateDeliveryDeadline } from '../../../../actions/delivery-actions'
import { clearModals } from '../../../../actions/modal-actions'

const DATE_FORMAT = 'MM/DD/YYYY'
const NOW = moment().startOf('day')

class NewDeliveryForm extends Component {
  componentDidMount() {
    const { promptDate } = this.props
    this.handleDeadlineDateChange(promptDate)
  }

  handleDeadlineDateChange = newDateValue => {
    const { updateEditDeadlineForm } = this.props
    let newDate = null
    if (newDateValue) {
      newDate = moment.utc(newDateValue)
    }
    if (newDate && !newDate.isBefore(NOW)) {
      updateEditDeadlineForm({
        deadlineDate: newDate.format(DATE_FORMAT),
        isDateValid: true
      })
    } else {
      updateEditDeadlineForm({
        deadlineDate: newDate,
        isDateValid: false
      })
    }
  }

  updateDeliveryDeadline = () => {
    const { deadlineDate, deliveryId, updateDeliveryDeadline, clearModals } = this.props
    updateDeliveryDeadline(
      {
        deliveryId,
        deadlineDate
      },
      clearModals
    )
  }

  disableUpdateButton() {
    const { deadlineDate, isDateValid, promptDate } = this.props
    return !deadlineDate || !isDateValid || deadlineDate === promptDate
  }

  renderArrow = () => (
    <div className="arrow-right">
      <ArrowIcon fill="currentColor" />
    </div>
  )

  render() {
    const { deadlineDate, readOnly, deliverableName, clearModals, loading, error } = this.props
    return (
      <Form className="sb-form">
        {loading && <Loader overlap />}
        {error && <div>{error}</div>}
        <TextInputField
          id="deliverable-name"
          label="Deliverable"
          required
          type="text"
          readOnly
          value={deliverableName}
        />
        <FormGroup className="date-input-group">
          <SingleDateInput
            name="Delivery Start"
            className="deadline-start-input"
            popupClassName="sb-form-popup"
            selectLabel="DD MMM, YYYY"
            selectedDate={moment()}
            format="DD MMM, YYYY"
            disabled
          />
          {this.renderArrow()}
          <SingleDateInput
            name="Delivery Deadline"
            className="deadline-date-input"
            popupClassName="sb-form-popup deadline-date-select"
            popupTitle="Select Date for Delivery Deadline"
            selectLabel="DD MMM, YYYY"
            selectedDate={deadlineDate ? moment(deadlineDate) : undefined}
            format="DD MMM, YYYY"
            onDateChange={this.handleDeadlineDateChange}
            readOnly={readOnly}
            autoFocus
            minDate={moment()}
          />
        </FormGroup>
        <div className="modal-footer sb-form-footer">
          <div className="modal-buttons">
            <Button className="btn-cancel" onClick={clearModals}>
              Cancel
            </Button>
            <Button disabled={this.disableUpdateButton()} className="btn-ok" onClick={this.updateDeliveryDeadline}>
              {'Update'}
            </Button>
          </div>
        </div>
      </Form>
    )
  }
}

NewDeliveryForm.propTypes = {
  promptDate: PropTypes.string,
  deadlineDate: PropTypes.string,
  loading: PropTypes.bool,
  error: PropTypes.string,
  isDateValid: PropTypes.bool,
  readOnly: PropTypes.bool,
  deliveryId: PropTypes.number.isRequired,
  deliverableName: PropTypes.string.isRequired,
  updateEditDeadlineForm: PropTypes.func,
  clearModals: PropTypes.func,
  updateDeliveryDeadline: PropTypes.func
}

function mapStateToProps({ delivery: { editDeadlineForm } }) {
  return {
    ...editDeadlineForm
  }
}

export default connect(mapStateToProps, {
  clearModals,
  updateEditDeadlineForm,
  updateDeliveryDeadline
})(NewDeliveryForm)
