import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchRegexMatchesAcrossSample } from 'components/SupraQCToolPage/actions'
import PropTypes from 'prop-types'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import { Popover, OverlayTrigger, Glyphicon } from 'react-bootstrap'

import Loader from 'components/Loader'

const helpContainer = (
  <Popover id="regex-utility-matches-across-sample-help">
    <p>
      This tab shows the examples from other templates (excluding the current template) from the current session that
      match the generated regex. However, for computing the percentage, for every template, we only consider at the
      most, 5 examples. Also, the list below shows only 10 examples at the most. The percentage shown also takes into
      consideration the amount of traffic associated with each template, denoted by the percentage_msg_total_cusum field
    </p>
  </Popover>
)

const RegexUtilityMatchesAcrossSample = ({ regex, template }) => {
  const dispatch = useDispatch()

  const { loading, numberOfMatchingTemplates, sessionId, rows, templateId, totalNumberOfTemplates } = useSelector(
    state => {
      return {
        ...state.supraQCToolV2.regexUtilityModal.matchesAcrossSample,
        sessionId: state.supraQCToolV2.samplingQCTab.sessionId,
        templateId: state.supraQCToolV2.regexUtilityModal.selectedRow.templateId
      }
    }
  )

  useEffect(() => {
    dispatch(fetchRegexMatchesAcrossSample({ regex, template, sessionId, templateId }))
  }, [regex, dispatch, sessionId, templateId, template])

  let sequence = 1

  return loading ? (
    <Loader />
  ) : (
    <>
      <h5 className="text-left">
        Templates: {numberOfMatchingTemplates}, Total Templates: {totalNumberOfTemplates}
        <OverlayTrigger overlay={helpContainer} trigger="click" placement="right">
          <Glyphicon glyph="info-sign" className="ml-2" />
        </OverlayTrigger>
        <div />
      </h5>
      <BootstrapTable
        data={rows.map(example => ({ ...example, sequence: sequence++ }))}
        striped
        hover
        condensed
        bordered
        responsive
      >
        <TableHeaderColumn dataField="sequence" hidden isKey>
          Sequence
        </TableHeaderColumn>
        <TableHeaderColumn dataField="example" dataSort>
          Subject
        </TableHeaderColumn>
        <TableHeaderColumn dataField="fromAddr" dataSort>
          FromAddr
        </TableHeaderColumn>
        <TableHeaderColumn dataField="template" dataSort>
          Template
        </TableHeaderColumn>
        <TableHeaderColumn tdStyle={{ whiteSpace: 'normal' }} dataField="messageUUID" dataSort>
          Message UUID
        </TableHeaderColumn>
      </BootstrapTable>
    </>
  )
}

RegexUtilityMatchesAcrossSample.propTypes = {
  regex: PropTypes.string.isRequired,
  template: PropTypes.string.isRequired
}

export default RegexUtilityMatchesAcrossSample
