import React from 'react'
import PropTypes from 'prop-types'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import moment from 'moment'

import { DATA_TABLE } from './../constants'

const DateCell = ({ date = '', format = DATA_TABLE.DATE_ONLY_FORMAT }) => {
  const dateText = date ? moment.utc(date).format(format) : ''
  return (
    <OverlayTrigger placement="top" overlay={<Tooltip id="date-cell-tooltip">{date}</Tooltip>}>
      <span>{dateText}</span>
    </OverlayTrigger>
  )
}

DateCell.propTypes = {
  date: PropTypes.string,
  format: PropTypes.string
}

export default DateCell
