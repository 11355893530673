import React from 'react'
import { Glyphicon, OverlayTrigger, Tooltip } from 'react-bootstrap'
import cx from 'classnames'

const EncryptionWarning = () => {
  return (
    <OverlayTrigger
      placement="top"
      overlay={
        <Tooltip id="">
          The selected group has a value in &quot;s3Bucket&quot;, it will use <i>idf-shipping-s3</i> for delivery. For
          this specific delivery method, disabling the encryption option is not supported
        </Tooltip>
      }
    >
      <Glyphicon glyph="warning-sign" className={cx('FeedEditPage__delivery_icon_warn')} />
    </OverlayTrigger>
  )
}

export default EncryptionWarning
