import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import DocumentTitle from 'react-document-title'

import { Tab, Tabs } from 'react-bootstrap'

import Brands from './Brands'
import Categories from './Categories'
import Merchants from './Merchants'
import Unauthorized from '../Unauthorized'
import WithErrorBoundaryWrapper from 'components/WithErrorBoundaryWrapper/WithErrorBoundaryWrapper'

import UserService from '../../services/user-service'

import { appName, permissions } from '../../constants/constants'

import './index.scss'

const BRANDS = 'Brands'
const CATEGORIES = 'Categories'
const MERCHANTS = 'Merchants'

class Taxonomy extends Component {
  constructor(props) {
    super(props)

    this.state = {
      selectedTab: BRANDS
    }
  }

  handleTabSelect = tabKey => this.setState({ selectedTab: tabKey })

  render() {
    const { selectedTab } = this.state
    const { hasTaxonomyAccess, hasEditAccess } = this.props

    if (!hasTaxonomyAccess) return <Unauthorized />

    return (
      <DocumentTitle title={selectedTab ? `${appName} | ${selectedTab}` : appName}>
        <div className="taxonomy-page">
          <Tabs id="taxonomy-tabs" className="portal-tabs" activeKey={selectedTab} onSelect={this.handleTabSelect}>
            <Tab title={BRANDS} eventKey={BRANDS}>
              <Brands hasEditAccess={hasEditAccess} />
            </Tab>

            <Tab mountOnEnter title={CATEGORIES} eventKey={CATEGORIES}>
              <Categories hasEditAccess={hasEditAccess} />
            </Tab>

            <Tab mountOnEnter title={MERCHANTS} eventKey={MERCHANTS}>
              <Merchants />
            </Tab>
          </Tabs>
        </div>
      </DocumentTitle>
    )
  }
}

Taxonomy.propTypes = {
  hasEditAccess: PropTypes.bool.isRequired,
  hasTaxonomyAccess: PropTypes.bool.isRequired
}

const mapStateToProps = ({ session: { user } }) => ({
  hasTaxonomyAccess: user && user.permissions ? UserService.hasPermission(user, permissions.ropsTaxonomyView) : false,
  hasEditAccess: user && user.permissions ? UserService.hasPermission(user, permissions.ropsTaxonomyEdit) : false
})

export default WithErrorBoundaryWrapper(connect(mapStateToProps, null)(Taxonomy), '"CME Taxonomy" page')
