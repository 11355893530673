import React, { Component } from 'react'
import PropTypes from 'prop-types'

class SelectedName extends Component {
  static propTypes = {
    onChangeMode: PropTypes.func,
    name: PropTypes.string,
    class: PropTypes.string,
    type: PropTypes.string
  }

  render() {
    return (
      <div
        className={this.props.class}
        onDoubleClick={e => {
          e.preventDefault()
          this.props.onChangeMode({ type: this.props.type, value: 'edit' })
        }}
        title={this.props.name}
      >
        {this.props.name}
      </div>
    )
  }
}

export default SelectedName
