import React from 'react'
import PropTypes from 'prop-types'
import { Typography, Stack, Alert } from '@mui/material'
import InfoIcon from '@mui/icons-material/Info'
import Tooltip from '@mui/material/Tooltip'

const InputWithWideLabel = ({ label, info, validationMessage, isHide, children }) => {
  return (
    <>
      <Stack
        direction="row"
        sx={{
          justifyContent: 'space-between',
          alignItems: 'center',
          ml: 2,
          maxWidth: '350px',
          display: isHide ? 'none' : ''
        }}
      >
        <Stack direction="row">
          <Typography variant="h6" className="pr-1">
            {label}
          </Typography>
          {info && (
            <Tooltip title={<Typography variant="subtitle1">{info}</Typography>} arrow>
              <InfoIcon fontSize="medium" />
            </Tooltip>
          )}
        </Stack>
        {children}
      </Stack>
      {validationMessage && <Alert severity="warning">{validationMessage}</Alert>}
    </>
  )
}

InputWithWideLabel.propTypes = {
  label: PropTypes.string,
  info: PropTypes.string,
  validationMessage: PropTypes.string,
  isHide: PropTypes.bool,
  children: PropTypes.element
}

export default InputWithWideLabel
