import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { cloneDeep } from 'lodash'

import Loader from '../../Loader'
import { DataTableFiltered } from './../../DataTable'

import CommonRulesToolbar from './../Common/CommonRulesToolbar'

import MerchantBrandRegexActionsCell from './MerchantBrandRegexActionsCell'
import MerchantBrandRegexRuleModal from './Modals/MerchantBrandRegexRuleModal'

import { MerchantBrandRegexRulesActions } from './../actions'

import COMMON_COLUMNS from './../Common/commonColumns'
import { STORE_PATH } from './../constants'

class RulesMerchantBrandRegex extends Component {
  constructor(props) {
    super(props)

    this.columns = this.getColumns(cloneDeep(COMMON_COLUMNS))
  }

  getColumns(commonColumns) {
    const columns = [
      commonColumns.id,
      commonColumns.merchantId,
      commonColumns.merchantName,
      commonColumns.brandId,
      commonColumns.brandFullPath,
      commonColumns.includeParam,
      commonColumns.excludeParam,
      commonColumns.ruleType,
      commonColumns.isExcludeRule,
      commonColumns.updatedAt,
      commonColumns.updatedByName,
      commonColumns.isActive,
      {
        ...commonColumns.actionsColumn,
        dataFormat: (cell, row) => <MerchantBrandRegexActionsCell row={row} />
      }
    ]

    return columns
  }

  render() {
    const {
      loading,
      pageSize,
      hasEditAccess,
      totalCount,
      page,
      rows,
      sortBy,
      sortOrder,
      filters,
      fetchRulesAction,
      downloadRulesAction,
      clearFilters,
      saveRuleAction
    } = this.props

    return (
      <React.Fragment>
        <CommonRulesToolbar
          hasEditAccess={hasEditAccess}
          toolbarType={'Merchant Brand Regex'}
          createRuleModalContet={<MerchantBrandRegexRuleModal onOkHandler={saveRuleAction} />}
          downloadRulesAction={downloadRulesAction}
          refreshHandler={fetchRulesAction}
          clearFilters={clearFilters}
        />

        {loading && <Loader loading overlap />}

        <DataTableFiltered
          data={rows}
          columns={this.columns}
          page={page}
          pageSize={pageSize}
          totalCount={totalCount}
          sortBy={sortBy}
          sortOrder={sortOrder}
          filters={filters}
          tableClassName="rules-table"
          fetchRowsAction={fetchRulesAction}
        />
      </React.Fragment>
    )
  }
}

RulesMerchantBrandRegex.defaultProps = {
  rows: [],
  totalCount: 0
}

RulesMerchantBrandRegex.propTypes = {
  page: PropTypes.number,
  pageSize: PropTypes.number,
  sortBy: PropTypes.string,
  sortOrder: PropTypes.string,
  filters: PropTypes.object,
  loading: PropTypes.bool.isRequired,
  hasEditAccess: PropTypes.bool.isRequired,
  totalCount: PropTypes.number.isRequired,
  rows: PropTypes.arrayOf(PropTypes.object).isRequired,
  fetchRulesAction: PropTypes.func.isRequired,
  downloadRulesAction: PropTypes.func.isRequired,
  clearFilters: PropTypes.func.isRequired,
  saveRuleAction: PropTypes.func.isRequired
}

const mapStateToProps = ({ rules: { [STORE_PATH.merchantBrandRegex]: merchantBrandRegex } }) => ({
  page: merchantBrandRegex.page,
  sortBy: merchantBrandRegex.sortBy,
  loading: merchantBrandRegex.loading,
  rows: merchantBrandRegex.data.rows,
  pageSize: merchantBrandRegex.pageSize,
  sortOrder: merchantBrandRegex.sortOrder,
  totalCount: merchantBrandRegex.data.count,
  filters: merchantBrandRegex.filters
})

const mapDispatchToProps = {
  fetchRulesAction: MerchantBrandRegexRulesActions.fetch,
  downloadRulesAction: MerchantBrandRegexRulesActions.download,
  clearFilters: MerchantBrandRegexRulesActions.clearFilters,
  saveRuleAction: MerchantBrandRegexRulesActions.save
}

export default connect(mapStateToProps, mapDispatchToProps)(RulesMerchantBrandRegex)
