import { useEffect } from 'react'

/**
 * We should decide when we need to run "handleCategoryChange" and what parameters need to be set in this callback
 */
const useHandleCategoryChange = ({ values, ruleCBP, index, isFormChanged, handleCategoryChange }) => {
  // get values of component base on isUseCategory value
  let actualCategoryId = null
  let actualCategoryFullPath = null
  if (values.isUseCategory) {
    actualCategoryId = values.categoryId
    actualCategoryFullPath = values.categoryFullPath
  } else {
    actualCategoryId = ruleCBP?.rcbCategoryId
    actualCategoryFullPath = ruleCBP?.rcbCategoryFullPath
  }

  let skipHandler = false

  if (!isFormChanged) {
    skipHandler = true
  }

  // changed category selection => run handleCategoryChange()
  useEffect(() => {
    if (skipHandler) {
      return
    }
    handleCategoryChange({ id: actualCategoryId, fullPath: actualCategoryFullPath, index })
  }, [actualCategoryId, actualCategoryFullPath, handleCategoryChange, index, skipHandler])
}

export default useHandleCategoryChange
