/**
 * Builds the rows with changed brands, categories or both
 * Base on all rows and their staged edits
 * @param {Object[]} allRows - Array of all rows
 * @param {Object} stagedEdits - Object containing edits staged by dictionary keys.
 * @returns {Object} Contains the array with changed rows and the number of changes.
 */
export const buildTableData = (allRows, stagedEdits) => {
  let sequence = 1
  let noOfChanges = 0

  // Construct table allRows by iterating over the keys in stagedEdits
  const tableData = Object.keys(stagedEdits)
    .map(dictionaryKey => {
      const editAttributes = stagedEdits[dictionaryKey]
      const dataItem = allRows.find(item => item.dictionary_key === dictionaryKey)

      // Skip if no corresponding allRows item or no edits
      if (!dataItem || !editAttributes) return null

      // Create the table row directly mapping required fields
      const tableRow = {
        id: sequence++,
        description: dataItem.description,
        merchant: dataItem.merchant_name,
        indictBrand: dataItem.in_dict_brand,
        indictCategory: dataItem.in_dict_category_fp,
        brand: dataItem.brand_name,
        category: dataItem.category_full_path
      }

      // Append new values for attributes that have changes
      Object.entries(editAttributes).forEach(([attribute, { name }]) => {
        tableRow[`new${attribute}`] = name
        noOfChanges++
      })

      return tableRow
    })
    .filter(row => row !== null) // Filter out any null entries

  return { tableData, noOfChanges }
}

/**
 * Groups an array of objects by brand and category.
 * @param {Object[]} categoryBrandPairs - Array of objects containing 'id', 'brand', and 'category' fields.
 * @returns {Object[]} Array of objects containing 'brand', 'category', and 'ids' fields.
 */
export const groupByBrandAndCategory = categoryBrandPairs => {
  const grouped = categoryBrandPairs.reduce((acc, item) => {
    const { brand, category } = item
    const key = `${brand}-${category}`

    // Initialize if not already set
    if (!acc[key]) {
      acc[key] = {
        ids: [],
        brand,
        category
      }
    }

    // Push current item's ID to the 'ids' array of the grouped object
    acc[key].ids.push(item.id)

    return acc
  }, {})

  // Convert the map values back to an array
  return Object.values(grouped)
}

/**
 * Convert the incorrect array from server to flat object for visualization
 * @param {Object[]} incorrectPairs - Array of objects containing 'ids' and 'reason' fields.
 * @example
 *    incorrectPairs: [
 *         {
 *             ids: [1, 2],
 *             reason: 'Brand not found'
 *         },
 * ]
 * @returns {Object.<string, string>} - Object with 'id' as key and 'reason' as value.
 * @example
 * {
 *    1: 'Brand not found',
 *    2: 'Brand not found',
 * }
 */
export const getIncorrectPairsAsObject = incorrectPairs => {
  if (!incorrectPairs) return {}

  const result = incorrectPairs.reduce((acc, item) => {
    item.ids.forEach(id => {
      acc[id] = item.reason
    })
    return acc
  }, {})

  return result
}
