import React from 'react'
import { TableHeaderColumn } from 'react-bootstrap-table'
import {
  caretRender,
  buildDeliverableNameRenderer,
  renderNextDelivery,
  renderDate,
  renderActiveStatus,
  renderDeliverableClient
} from '../Common/table-utils'
import DeliveryProgress from './delivery-progress'

const buildDeliverableColumns = ({ onEdit, onToggleStatus, getDeliveryProgress, onDelete }) => {
  const renderProgress = (cell, { id, name, currentDeliveryId }) => {
    const progress = getDeliveryProgress(currentDeliveryId)
    return <DeliveryProgress deliverableId={id} deliverableName={name} progress={progress} />
  }

  const getColumn = column => {
    const defaultColumnConfig = {
      label: column,
      field: column,
      dataSort: true,
      dataAlign: 'center',
      headerAlign: 'center',
      width: '100px',
      caretRender
    }

    let config = {}
    switch (column) {
      case 'id':
        config = {
          label: '#',
          hidden: true
        }
        break
      case 'name':
        config = {
          label: 'Deliverable',
          className: 'freeze-left deliverable-cell',
          columnClassName: 'freeze-left deliverable-cell',
          dataFormat: buildDeliverableNameRenderer({
            onEdit,
            onToggleStatus,
            onDelete
          }),
          dataAlign: 'left',
          width: '350px'
        }
        break
      case 'Contract.Client.name':
        config = {
          label: 'Client',
          width: '130px',
          dataFormat: renderDeliverableClient
        }
        break
      case 'isActive':
        config = {
          label: 'Status',
          dataFormat: (cell, row) => renderActiveStatus(cell, row, 'deliverable')
        }
        break
      case 'nextDelivery':
        config = {
          label: 'Next Delivery',
          width: '130px',
          dataFormat: renderNextDelivery
        }
        break
      case 'lastDeliveredDate':
        config = {
          label: 'Last Delivered',
          width: '130px',
          dataFormat: renderDate
        }
        break
      case 'progress':
        config = {
          label: 'Delivery Progress',
          width: '250px',
          dataFormat: renderProgress,
          dataSort: false
        }
        break
      default:
        break
    }
    return {
      ...defaultColumnConfig,
      ...config
    }
  }
  const fields = ['id', 'name', 'Contract.Client.name', 'isActive', 'progress', 'nextDelivery', 'lastDeliveredDate']
  const columns = fields.map(field => getColumn(field))
  const headerColumns = columns.map((col, index) => {
    const { field, label, ...otherProps } = col
    return (
      <TableHeaderColumn dataField={field} ref={field} isKey={field === 'id'} key={`col-${index}`} {...otherProps}>
        {label}
      </TableHeaderColumn>
    )
  })
  return headerColumns
}

export default buildDeliverableColumns
