import React from 'react'
import { Controller } from 'react-hook-form'
import { FormControl, InputGroup } from 'react-bootstrap'
import PropTypes from 'prop-types'
import InputField from 'components/FormInputComponents/InputField'

const MaxQuantileInput = ({ control, errors, info }) => {
  return (
    <InputField
      label="Max Quantile"
      required
      info={info}
      validationMessage={errors.maxQuantile?.message}
      className="text-start"
      labelSize={4}
    >
      <Controller
        name="maxQuantile"
        control={control}
        render={({ field }) => (
          <InputGroup>
            <FormControl {...field} type="number" required step="0.1" />
            <InputGroup.Addon> % </InputGroup.Addon>
          </InputGroup>
        )}
      />
    </InputField>
  )
}

MaxQuantileInput.propTypes = {
  control: PropTypes.any,
  errors: PropTypes.object,
  info: PropTypes.string
}

export default MaxQuantileInput
