import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import moment from 'moment'
import { isEmpty, find, toString } from 'lodash'
import { Button, ButtonGroup, ButtonToolbar } from 'react-bootstrap'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import SearchIcon from '../Icon/SearchIcon'
import Input from '../Input'
import { loadETLStates, updateEtlCheckpoint, updateETLparam } from '../../actions/task-actions'
import paginationTextHint from 'components/Tooltips/paginationTextHint'
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css'

class EtlStates extends Component {
  constructor(args) {
    super(args)
    this.state = {
      data: [],
      dataEdits: {}
    }
  }

  componentDidMount() {
    this.props.loadETLStates()
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.data !== prevProps.data) {
      this.props.data.forEach(row => {
        row.value = toString(row.value)
        row.originalValue = row.value
      })
      if (!isEmpty(prevState.dataEdits)) {
        this.discardChanges()
      }
    }
  }

  afterValueUpdate = (id, value) => {
    const { dataEdits } = this.state
    const row = find(this.props.data, { id })
    if (row.originalValue !== value) {
      this.setState({
        dataEdits: {
          ...dataEdits,
          [id]: value
        }
      })
    }
  }

  discardChanges = () => {
    this.props.data.forEach(row => {
      row.value = row.originalValue
    })
    this.setState({ dataEdits: {} })
  }

  saveChanges = () => {
    Object.keys(this.state.dataEdits).forEach(id => {
      this.props.updateEtlCheckpoint({
        id,
        value: this.state.dataEdits[id]
      })
    })
    this.discardChanges()
  }

  buildColumns = () => {
    const checkpointValidator = (value, row) => {
      const response = {
        isValid: true,
        notification: { type: 'success', msg: '', title: '' }
      }

      let valid = true
      if (this.isValidCheckPoint(row, value)) {
        try {
          const date = moment(value)
          valid = date.isValid()
        } catch (errr) {
          valid = false
        }
      }
      if (!valid) {
        response.isValid = false
        response.notification = {
          type: 'error',
          msg: 'Seriously, who gave you admin access?\nPress ESC to cancel editing.',
          title: 'INVALID DATE TIME VALUE\n'
        }
      }
      return response
    }
    const getColumn = column => {
      const defaultColumnConfig = {
        label: column,
        field: column,
        hasSort: true,
        dataAlign: 'left',
        editable: false
      }
      let config = {}
      switch (column) {
        case 'id':
          config = {
            width: '30px',
            dataAlign: 'center',
            sortFunc: (firstInput, secondInput, order) => {
              return order === 'desc'
                ? parseInt(secondInput.id, 10) - parseInt(firstInput.id, 10)
                : parseInt(firstInput.id, 10) - parseInt(secondInput.id, 10)
            }
          }
          break
        case 'value':
          config = {
            editable: {
              validator: checkpointValidator
            },
            columnClassName: 'value-cell',
            dataFormat: (cell, row) => {
              if (this.isValidCheckPoint(row, cell)) {
                return (
                  <div>
                    <div>{moment.utc(cell).format()}</div>
                    <div className="local-date-time">{moment(cell).format('DD MMM,YY  hh:mm a Z')}</div>
                  </div>
                )
              } else {
                return <div>{cell}</div>
              }
            }
          }
          break
        case 'updatedAt':
        case 'createdAt':
          config = {
            dataFormat: cell => {
              return (
                <div>
                  <div title={cell}>{moment(cell).calendar()}</div>
                </div>
              )
            }
          }
          break
        default:
          break
      }
      return {
        ...defaultColumnConfig,
        ...config
      }
    }
    const fields = ['id', 'type', 'param', 'value', 'createdAt', 'updatedAt']
    const columns = fields.map(field => getColumn(field))
    const headerColumns = columns.map((col, index) => {
      return (
        <TableHeaderColumn
          dataField={col.field}
          isKey={col.field === 'id'}
          key={`col-${index}`}
          dataSort={col.hasSort}
          dataAlign={col.dataAlign}
          dataFormat={col.dataFormat}
          sortFunc={col.sortFunc}
          editColumnClassName={col.editColumnClassName}
          columnClassName={col.columnClassName}
          editable={col.editable}
          ref={col.field}
          width={col.width}
        >
          {col.label}
        </TableHeaderColumn>
      )
    })
    return headerColumns
  }

  onPageChange = pageNumber => {
    this.props.updateETLparam({ pageNumber })
  }

  onSizePerPageChange = sizePerPage => {
    this.props.updateETLparam({
      sizePerPage,
      pageNumber: 1
    })
  }

  onSortChange = (sortBy, sortOrder) => {
    this.props.updateETLparam({ sortBy, sortOrder })
  }

  onSearchChange = search => {
    this.props.updateETLparam({
      search,
      pageNumber: 1
    })
  }

  getOptions = () => {
    return {
      sizePerPage: this.props.sizePerPage,
      onPageChange: this.onPageChange,
      sizePerPageList: [10, 20, 50, 100],
      page: this.props.pageNumber,
      onSizePerPageList: this.onSizePerPageChange,
      sortName: this.props.sortBy,
      sortOrder: this.props.sortOrder,
      onSortChange: this.onSortChange,
      onSearchChange: this.onSearchChange,
      paginationShowsTotal: paginationTextHint
    }
  }

  isValidCheckPoint = (row, value) => {
    return row && row.param && row.param.startsWith('CHECKPOINT') && value && value !== '0'
  }

  cellEditOptions = {
    mode: 'dbclick',
    blurToSave: true,
    afterSaveCell: (row, cellName, cellValue) => {
      if (cellName === 'value') {
        if (this.isValidCheckPoint(row, cellValue)) {
          cellValue = moment.utc(cellValue).format()
        }
        row.value = cellValue
        this.afterValueUpdate(row.id, cellValue)
      }
    }
  }

  trClassFormat = row => {
    return this.state.dataEdits[row.id] ? 'edited' : ''
  }

  render() {
    return (
      <div>
        <Input
          clearButton
          placeholder="Search ETL states"
          className="CheckBoxMultiSelect flex1"
          value={this.props.search}
          iconName={<SearchIcon width={24} height={24} />}
          didClickClearButton={() => this.onSearchChange('')}
          textDidChange={this.onSearchChange}
        />
        <ButtonToolbar
          style={{
            visibility: Object.keys(this.state.dataEdits).length ? 'visible' : 'hidden'
          }}
        >
          {
            <ButtonGroup className="pull-right">
              <Button bsStyle="danger" onClick={this.saveChanges}>
                Save {Object.keys(this.state.dataEdits).length} changes
              </Button>
              <Button bsStyle="warning" onClick={this.discardChanges}>
                {' '}
                Discard{' '}
              </Button>
            </ButtonGroup>
          }
        </ButtonToolbar>
        <BootstrapTable
          ref={el => {
            this.table = el
          }}
          data={this.props.data}
          pagination
          options={this.getOptions()}
          trClassName={this.trClassFormat}
          cellEdit={this.cellEditOptions}
          striped
          hover
          condensed
          bordered
          responsive
          className="etl-states-table drop-menu-up"
          remote
          fetchInfo={{ dataTotalSize: this.props.count }}
        >
          {this.buildColumns()}
        </BootstrapTable>
      </div>
    )
  }
}

EtlStates.propTypes = {
  data: PropTypes.array,
  loadETLStates: PropTypes.func,
  updateEtlCheckpoint: PropTypes.func,
  count: PropTypes.number,
  pageNumber: PropTypes.number,
  sizePerPage: PropTypes.number,
  sortBy: PropTypes.string,
  sortOrder: PropTypes.string,
  search: PropTypes.string,
  updateETLparam: PropTypes.func
}

function mapStateToProps(state) {
  return {
    data: state.etlStates.data,
    count: state.etlStates.count,
    pageNumber: state.etlStates.pageNumber,
    sizePerPage: state.etlStates.sizePerPage,
    sortBy: state.etlStates.sortBy,
    sortOrder: state.etlStates.sortOrder,
    search: state.etlStates.search
  }
}

export default connect(mapStateToProps, {
  loadETLStates,
  updateEtlCheckpoint,
  updateETLparam
})(EtlStates)
