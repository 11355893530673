import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import Loader from '../../Loader'
import DataTable from './../../DataTable'
import TaxonomyToolbar from '../Common/TaxonomyToolbar'

import { fetchMerchants, downloadMerchants, updateMerchantsTable } from '../../../actions/taxonomy-actions'

import buildMerchantColumns from './columns'

export class Merchants extends Component {
  componentDidMount() {
    this.fetchMerchants()
  }

  fetchMerchants = payload => {
    const {
      sortBy = this.props.sortBy,
      sortOrder = this.props.sortOrder,
      page = this.props.page,
      pageSize = this.props.pageSize,
      filters = this.props.filters
    } = payload || {}

    this.props.updateMerchantsTable({
      page,
      pageSize,
      sortBy,
      sortOrder,
      filters
    })

    this.props.fetchMerchants({
      page,
      pageSize,
      sortBy,
      sortOrder,
      filters
    })
  }

  onSortChange = (sortBy, sortOrder) => {
    this.fetchMerchants({
      sortBy,
      sortOrder,
      page: 1
    })
  }

  onFilterChange = filtersObj => {
    const filters = {}
    for (const [columnName, filterValue] of Object.entries(filtersObj)) {
      filters[columnName] = { value: filterValue.value }
    }

    this.fetchMerchants({
      filters,
      page: 1
    })
  }

  onPageChange = page => {
    this.fetchMerchants({ page })
  }

  render() {
    const { loading, pageSize, totalMerchantCount, downloadMerchants } = this.props

    const maxPages = Math.ceil(totalMerchantCount / pageSize) || 1

    const columns = buildMerchantColumns({ tableType: 'merchant' })

    return (
      <React.Fragment>
        <TaxonomyToolbar toolbarType="Merchant" hasEditAccess={false} downloadFunction={downloadMerchants} />

        {loading && <Loader loading overlap />}

        <DataTable
          columns={columns}
          keyBoardNav={false}
          maxPages={maxPages}
          page={this.props.page}
          data={this.props.merchants}
          sortName={this.props.sortBy}
          trClassName={this.trClassName}
          onPageChange={this.onPageChange}
          sortOrder={this.props.sortOrder}
          totalCount={totalMerchantCount}
          onSortChange={this.onSortChange}
          onFilterChange={this.onFilterChange}
          tableClassName="merchants-table sb-table-compact"
          ref={el => {
            this.dataTable = el
          }}
        />
      </React.Fragment>
    )
  }
}

Merchants.defaultProps = {
  merchants: [],
  totalMerchantCount: 0
}

Merchants.propTypes = {
  page: PropTypes.number,
  sortBy: PropTypes.string,
  pageSize: PropTypes.number,
  sortOrder: PropTypes.string,
  filters: PropTypes.object,
  loading: PropTypes.bool.isRequired,
  fetchMerchants: PropTypes.func.isRequired,
  downloadMerchants: PropTypes.func.isRequired,
  totalMerchantCount: PropTypes.number.isRequired,
  updateMerchantsTable: PropTypes.func.isRequired,
  merchants: PropTypes.arrayOf(PropTypes.object).isRequired
}

const mapStateToProps = ({ taxonomy: { merchants } }) => ({
  page: merchants.page,
  sortBy: merchants.sortBy,
  loading: merchants.loading,
  pageSize: merchants.pageSize,
  sortOrder: merchants.sortOrder,
  merchants: merchants.data.rows,
  totalMerchantCount: merchants.data.count,
  filters: merchants.filters
})

const mapDispatchToProps = {
  fetchMerchants,
  downloadMerchants,
  updateMerchantsTable
}

export default connect(mapStateToProps, mapDispatchToProps)(Merchants)
