import React, { Component } from 'react'
import PropTypes from 'prop-types'
import FormSelect from '../../FormSelect'
import FormDatePicker from './../../FormDatePicker'
import CategoriesInput from './../Filter/categories-input'
import { Field } from 'redux-form'

export default class AmazonScalingFactor extends Component {
  static propTypes = {
    feed: PropTypes.object.isRequired
  }

  render() {
    const { feed } = this.props

    return (
      <div>
        <Field name="l1Categories" component={CategoriesInput} />
        <FormDatePicker
          label="Min Order Date"
          fieldName="minOrderDate"
          isRequired={!feed.isActive}
          tooltip={'The min order date is inclusive.'}
          fromDate={new Date('1/1/13')}
        />
        {!feed.isActive && (
          <FormDatePicker
            label="Max Order Date"
            isRequired
            fieldName="maxOrderDate"
            tooltip={'The max order date is inclusive.'}
            fromDate={feed.filters.minOrderDate ? new Date(feed.filters.minOrderDate) : new Date('1/1/13')}
          />
        )}
        <FormSelect
          label="Source ID"
          fieldName="sourceId"
          placeholder="Select source"
          options={[
            { value: '1', label: ' 1' },
            { value: '2', label: ' 2' }
          ]}
        />
      </div>
    )
  }
}
