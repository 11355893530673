/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */

import React from 'react'

import moment from 'moment'
import TimeAgo from 'react-timeago'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { Tooltip, OverlayTrigger, Label } from 'react-bootstrap'

import JobListMetadata from './job-metadata'
import JobInteractions from './job-interactions'
import { prettifyNumberValues } from './utils'

const SHRUG = '¯\\_(ツ)_/¯'

export const getDayFormat = date => {
  return moment(date).isSame(new Date(), 'day') ? 'LT' : 'DD MMM'
}

export const renderDate = (cellValue, tooltipPlacement = 'right') => {
  return (
    <OverlayTrigger
      placement={tooltipPlacement}
      overlay={
        <Tooltip id={`job-id-tooltip-date`}>{cellValue ? moment(cellValue).format('YYYY/MM/DD LTS') : SHRUG}</Tooltip>
      }
    >
      <span>{cellValue ? moment(cellValue).format(getDayFormat(cellValue)) : '―'}</span>
    </OverlayTrigger>
  )
}

export const renderDuration = (startedAt, endedAt) => {
  if (!startedAt || !endedAt) return null
  const duration = moment.utc(moment(endedAt) - moment(startedAt))
  let durationString = ''
  if (duration.hours()) {
    durationString += `${duration.hours()} hrs `
  }
  if (duration.minutes()) {
    durationString += `${duration.minutes()} mins `
  }
  if (!duration.hours()) {
    durationString += `${duration.seconds()} s `
  }
  return (
    <div className="job-duration">
      {'duration: '}
      {durationString || '0s'}
    </div>
  )
}

export const jobIdCellFactory = updateSearchWithDebounce => ({ rowData }) => {
  return (
    <div>
      id:{' '}
      <OverlayTrigger
        placement="right"
        delayShow={1000}
        overlay={<Tooltip id={`job-id-tooltip-id}`}>{rowData.id}</Tooltip>}
      >
        <CopyToClipboard text={rowData.id}>
          <span className="job-id click-to-copy">{rowData.id.slice(-6)}</span>
        </CopyToClipboard>
      </OverlayTrigger>
      <div className="filter-batch-link">
        <span className="btn_link" onClick={() => updateSearchWithDebounce(rowData.batchId)}>
          {rowData.batchId && (
            <CopyToClipboard text={rowData.batchId}>
              <span className="click-to-copy">batch</span>
            </CopyToClipboard>
          )}
        </span>
      </div>
    </div>
  )
}

export const jobTypeCellFactory = updateSearchWithDebounce => ({ rowData }) => {
  return (
    <div>
      <span className="btn_link" onClick={() => updateSearchWithDebounce(rowData.type)}>
        {rowData.type}
      </span>
      <div className="job-created-date">
        {'Created: '}
        {renderDate(rowData.createdAt)}
      </div>
      <div className="job-updated-time-ago">
        Updated{' '}
        <TimeAgo
          date={rowData.updatedAt}
          minPeriod={30}
          title={moment(rowData.updatedAt).format(getDayFormat(rowData.updatedAt))}
        />
      </div>
    </div>
  )
}

export const durationCell = ({ rowData }) => {
  return (
    <div className="job-start-end">
      {renderDate(rowData.startedAt, 'left')}
      {` - `}
      {renderDate(rowData.endedAt)}
      {renderDuration(rowData.startedAt, rowData.endedAt)}
    </div>
  )
}

export const metadataCellFactory = (updateSearchWithDebounce, openMetadataModel) => ({ rowData }) => {
  return (
    <div>
      <JobListMetadata changeSearch={updateSearchWithDebounce} job={rowData} />
      {rowData.metadata && (
        <OverlayTrigger
          delayShow={500}
          placement="top"
          overlay={<Tooltip id={`job-metadata-tooltip`}>Click to view Metadata</Tooltip>}
        >
          <div className="metadataPeek" onClick={() => openMetadataModel(rowData)}>
            <i className="glyphicon glyphicon-eye-open" />
          </div>
        </OverlayTrigger>
      )}
    </div>
  )
}

export const outputCell = ({ rowData }) => {
  let output = rowData.output

  if (output) {
    try {
      // try to get the output as JSON
      const outputJSON = JSON.parse(rowData.output)
      // prettify the number values
      output = prettifyNumberValues(outputJSON)
      // convert it back to string
      output = JSON.stringify(output)
    } catch (e) {
      // do nothing, It's not a JSON
    }
  }

  return (
    <OverlayTrigger
      placement="bottom"
      overlay={<Tooltip id={`job-status-tooltip-output`}>{rowData.output || SHRUG}</Tooltip>}
    >
      <span>{output || '―'}</span>
    </OverlayTrigger>
  )
}

const CLASS_FOR_STATUS = {
  retried: 'info',
  progress: 'info',
  paused: 'info',
  cancelled: 'info',
  failed: 'warning',
  success: 'success',
  processing: 'primary'
}

export const jobStatusCellFactory = updateSearchWithDebounce => ({ rowData }) => {
  return (
    <OverlayTrigger
      placement="bottom"
      overlay={
        <Tooltip id={`job-status-tooltip-${rowData.id}`}>{rowData.output ? rowData.output : 'No Output'}</Tooltip>
      }
    >
      <Label onClick={() => updateSearchWithDebounce(rowData.status)} bsStyle={CLASS_FOR_STATUS[rowData.status]}>
        {rowData.status}
      </Label>
    </OverlayTrigger>
  )
}

export const interactionsCell = ({ rowData }) => {
  return <JobInteractions job={rowData} />
}
