import React from 'react'
import PropTypes from 'prop-types'
import { Glyphicon } from 'react-bootstrap'

const AccordionItem = ({ handleClick, position, children, header, height, isCollapsed, isExpanded }) => {
  const handleAccordionItemClick = () => {
    handleClick(position)
  }

  let icon
  let title = `${header}. `
  if (isExpanded) {
    icon = 'resize-small'
    title += 'Click to collapse'
  } else {
    icon = 'resize-full'
    title += 'Click to expand'
  }

  const headerLabel = isCollapsed ? header.charAt(0).toUpperCase() : header

  return (
    <div className={`portal-accordion-item`} style={{ height: `${height}px` }}>
      <div className="portal-accordion-item-header" title={title} onClick={handleAccordionItemClick}>
        <Glyphicon glyph={icon} />
        <span>{headerLabel}</span>
      </div>
      <div className="portal-accordion-item-body">{children}</div>
    </div>
  )
}

AccordionItem.propTypes = {
  header: PropTypes.string,
  height: PropTypes.number,
  position: PropTypes.number,
  handleClick: PropTypes.func,
  children: PropTypes.node,
  isCollapsed: PropTypes.bool,
  isExpanded: PropTypes.bool
}

AccordionItem.defaultProps = {
  isCollapsed: false,
  isExpanded: false
}

export default AccordionItem
