import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Tooltip } from 'react-bootstrap'
import EditValue from '../EditValue'

class Processing extends Component {
  static propTypes = {
    trackedValue: PropTypes.object,
    attribute: PropTypes.string,
    cell: PropTypes.string,
    inDict: PropTypes.bool
  }

  getTooltip = () => {
    const { trackedValue } = this.props
    const title = trackedValue && trackedValue.status ? `${trackedValue.status}` : ''
    return (
      <Tooltip id={`description-${this.props.attribute}-${this.props.cell}`}>
        Status: {title}
        {
          <span className="edited-by-user label-capitalize">
            <br />
            <i>{`Updated By: ${trackedValue.user}`}</i>
          </span>
        }
      </Tooltip>
    )
  }

  render() {
    return (
      <EditValue getTooltip={() => this.getTooltip()} className={'editable-desc-value'}>
        <span>
          <span className={this.props.inDict ? 'in-dict' : 'not-in-dict'}>{this.props.trackedValue.oldName}</span>
          <span className={'tracked'}>{` (${this.props.trackedValue.newName})`}</span>
        </span>
      </EditValue>
    )
  }
}

export default Processing
