import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import Refresh from '../../../../Icon/Refresh'
import CloseEye from '../../../../Icon/CloseEye'
import OpenEye from '../../../../Icon/OpenEye'

import cx from 'classnames'
import './index.scss'

class DeliverySubStep extends Component {
  onRetryClicked = () => {
    const { onRetry, step, substep, onToggleDetails } = this.props
    if (typeof onRetry === 'function') {
      onToggleDetails(false, substep)
      onRetry(step, substep)
    }
  }

  onToggleDetails = () => {
    const { onToggleDetails, showDetails, substep } = this.props
    if (typeof onToggleDetails === 'function') {
      onToggleDetails(!showDetails, substep)
    }
  }

  renderActions = () => {
    const { status, details, showDetails, hideRetry } = this.props
    const tooltip = name => <Tooltip id="icon-tooltip">{name}</Tooltip>
    return (
      <span className="step-actions">
        {details && (
          <OverlayTrigger rootClose placement="top" overlay={tooltip(showDetails ? 'Hide details' : 'Show details')}>
            <span className="step-action" onClick={this.onToggleDetails}>
              {showDetails ? <CloseEye /> : <OpenEye />}
            </span>
          </OverlayTrigger>
        )}
        {status === 'FAILED' && !hideRetry && (
          <OverlayTrigger placement="top" rootClose overlay={tooltip('Retry from here')}>
            <span className="step-action" onClick={this.onRetryClicked}>
              {<Refresh />}
            </span>
          </OverlayTrigger>
        )}
      </span>
    )
  }

  render() {
    const { label, className, status, details, showDetails } = this.props
    return (
      <div className={cx('delivery-sub-step-wrapper', className)}>
        <div
          className={cx('sub-step', {
            completed: status === 'COMPLETED',
            failed: status === 'FAILED',
            processing: status === 'PROCESSING',
            pending: status === 'PENDING'
          })}
        >
          <span className="label">{label}</span>
          {this.renderActions()}
        </div>
        {showDetails && (
          <div className="details-box">{typeof details === 'string' ? details : JSON.stringify(details)}</div>
        )}
      </div>
    )
  }
}

DeliverySubStep.defaultProps = {
  status: 'PENDING',
  label: ''
}

DeliverySubStep.propTypes = {
  step: PropTypes.string.isRequired,
  substep: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  status: PropTypes.oneOf(['COMPLETED', 'PROCESSING', 'FAILED', 'PENDING']),
  showDetails: PropTypes.bool,
  details: PropTypes.any,
  className: PropTypes.string,
  onRetry: PropTypes.func,
  hideRetry: PropTypes.bool,
  onToggleDetails: PropTypes.func
}

export default DeliverySubStep
