import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { StepPanelGroup, StepPanel } from '../../Common/StepPanel'
import { currentDeliverySteps as steps } from '../../../../utils/delivery-center'
import TargetFlag from '../../../Icon/TargetFlag'
import DataCleanupPanel from './DataCleanupPanel'
import DataCustomizationPanel from './DataCustomizationPanel'
import QCPanel from './QCPanel'
import PRODPanel from './ProdPanel'
import EditDeadlineForm from './EditDeadlineForm'
import UserService from '../../../../services/user-service'
import { permissions as PERMISSIONS } from '../../../../constants/constants'
import { DELIVERY_JOB_STATUS } from '../../../../constants/deliveryJobsConstants'

import {
  updateActiveStep,
  startDataCustomization,
  onToggleDetails,
  retryDeliveryStep,
  proceedToDataCustomization,
  approveQC,
  fetchCurrentDelivery
} from '../../../../actions/delivery-actions'
import { addFormModal } from '../../../../actions/modal-actions'
import moment from 'moment'
import cx from 'classnames'

const { DATA_CLEANUP, DATA_CUSTOMIZATION, QC, PROD } = steps
const DATE_TIME_FORMAT = 'MM/DD/YYYY hh:mm A'
class DeliverySteps extends Component {
  handleOnToggle = (stepKey, value) => {
    this.props.updateActiveStep(value ? stepKey : null)
  }

  /**
   * Create onToggle handler for STEP
   */
  onToggle = STEP => value => {
    this.handleOnToggle(STEP, value)
  }

  editDeadline = () => {
    const { deliveryDate, addFormModal, deliveryId, deliverableName } = this.props
    addFormModal({
      id: 'edit-deadline-modal',
      className: 'edit-deadline-modal',
      title: 'Edit Delivery Deadline',
      content: <EditDeadlineForm deliveryId={deliveryId} deliverableName={deliverableName} promptDate={deliveryDate} />
    })
  }

  handleDeliveryStepRetry = (step, substep) => {
    const { deliveryId, retryDeliveryStep } = this.props
    retryDeliveryStep(step, substep, deliveryId)
  }

  handleStartDataCustomization = rerun => {
    const { deliveryId, startDataCustomization } = this.props
    const callback = () => this.panelGroup.updatePanelExpandState(DATA_CUSTOMIZATION, true)
    startDataCustomization(deliveryId, rerun, callback)
  }

  proceedToDataCustomization = skipDataCleanup => {
    const { deliveryId, proceedToDataCustomization } = this.props
    const callback = () => this.panelGroup.updatePanelExpandState(DATA_CUSTOMIZATION, true)
    proceedToDataCustomization(deliveryId, skipDataCleanup, callback)
  }

  handleQcApproval = (approve, notes = '') => {
    const { deliveryId, approveQC } = this.props

    const callback = () => this.panelGroup.updatePanelExpandState(approve ? PROD : DATA_CUSTOMIZATION, true)

    approveQC({ deliveryId, approve, notes, callback })
  }

  dataCleanupPanel() {
    const { totalCategories = 0, readyCategories = 0, categoryCleanupStatus, isDeliveryComplete } = this.props
    const skipped = categoryCleanupStatus === 'SKIPPED'
    const isComplete = totalCategories > 0 && readyCategories === totalCategories
    const title = (
      <span>
        {'Data Cleaning: '}
        <strong>{`${readyCategories} of ${totalCategories}`}</strong>
        {' categories published'}
        {skipped && ' (skipped)'}
      </span>
    )
    return (
      <StepPanel
        stepKey={DATA_CLEANUP}
        step={skipped && !isComplete ? <span>- -</span> : 1}
        title={title}
        isComplete={skipped || isComplete}
        editable={!isDeliveryComplete}
        onToggle={this.onToggle(DATA_CLEANUP)}
      >
        <DataCleanupPanel
          categoryCleanupStatus={categoryCleanupStatus}
          proceedToDataCustomization={this.proceedToDataCustomization}
          editable={!isDeliveryComplete}
        />
      </StepPanel>
    )
  }

  dataCustomizationPanel() {
    const {
      dataCustomization: { status, updatedAt, tasks },
      isDeliveryComplete,
      qcStatus,
      substepDetails,
      onToggleDetails
    } = this.props
    let titleText = 'Data Customization'
    if (status === 'PROCESSING') {
      titleText += ': Scripts are running'
    } else if (status === 'COMPLETED') {
      titleText += ': Scripts completed successfully'
    } else if (status === 'FAILED') {
      titleText += ': Scripts failed'
    }
    return (
      <StepPanel
        stepKey={DATA_CUSTOMIZATION}
        step={2}
        title={titleText}
        headerSuffix={<LastUpdated date={updatedAt} />}
        isComplete={status === 'COMPLETED'}
        editable={!isDeliveryComplete}
        onToggle={this.onToggle(DATA_CUSTOMIZATION)}
        failed={status === 'FAILED'}
        className={cx({ active: status === 'PROCESSING' })}
      >
        <DataCustomizationPanel
          qcRejected={qcStatus === 'REJECTED'}
          tasks={tasks}
          substepDetails={substepDetails}
          onToggleDetails={onToggleDetails}
          retryDeliveryStep={this.handleDeliveryStepRetry}
          startDataCustomization={this.handleStartDataCustomization}
          editable={!isDeliveryComplete}
        />
      </StepPanel>
    )
  }

  qcPanel() {
    const QcStatusSubstepStatusMap = {
      APPROVED: 'COMPLETED',
      REJECTED: 'FAILED',
      PENDING: 'PENDING',
      SKIPPED: 'SKIPPED'
    }
    const {
      qc: { status, updatedAt, tasks },
      qcStatus,
      isDeliveryComplete,
      substepDetails,
      qcReportId,
      canReviewQc,
      notes
    } = this.props
    if (qcStatus === 'SKIPPED') {
      tasks.updateQcReport.status = 'SKIPPED'
      tasks.refreshQcFilters.status = 'SKIPPED'
    }
    tasks.waitingForApproval = {
      status: QcStatusSubstepStatusMap[qcStatus],
      updatedAt: '',
      output: ''
    }

    let titleText = 'QC'
    if (status === 'PROCESSING') {
      titleText += ': Scripts are running'
    } else if (status === 'FAILED') {
      titleText += ': Scripts failed'
    } else if (qcStatus === 'SKIPPED') {
      titleText += ': (skipped)'
    } else if (qcStatus === 'APPROVED') {
      titleText += ': Approved'
    } else if (qcStatus === 'PENDING') {
      titleText += ': Pending Approval'
    } else if (qcStatus === 'REJECTED') {
      titleText += ': Rejected'
    } else if (status === 'COMPLETED') {
      titleText += ': Scripts completed successfully'
    }

    return (
      <StepPanel
        stepKey={QC}
        step={3}
        title={titleText}
        headerSuffix={<LastUpdated date={updatedAt} />}
        isComplete={(qcStatus === 'APPROVED' && status === 'COMPLETED') || qcStatus === 'SKIPPED'}
        editable={!isDeliveryComplete}
        onToggle={this.onToggle(QC)}
        failed={status === 'FAILED' || qcStatus === 'REJECTED'}
        className={cx({
          active: status === 'PROCESSING' || qcStatus === 'PENDING'
        })}
      >
        <QCPanel
          notes={notes}
          qcApprovalStatus={qcStatus}
          tasks={tasks}
          substepDetails={substepDetails}
          qcReportId={qcReportId}
          onToggleDetails={this.props.onToggleDetails}
          retryDeliveryStep={this.handleDeliveryStepRetry}
          approveQC={this.handleQcApproval}
          editable={!isDeliveryComplete}
          canReviewQc={canReviewQc}
        />
      </StepPanel>
    )
  }

  prodPanel() {
    const {
      prod: { status, updatedAt, tasks },
      isDeliveryComplete,
      substepDetails,
      prodReportId
    } = this.props
    let titleText = 'Production'
    if (status === 'PROCESSING') {
      titleText += ': Scripts are running'
    } else if (status === 'FAILED') {
      titleText += ': Scripts failed'
    } else if (status === 'COMPLETED') {
      titleText += ': Scripts completed successfully'
    }
    return (
      <StepPanel
        stepKey={PROD}
        step={4}
        title={titleText}
        headerSuffix={<LastUpdated date={updatedAt} />}
        isComplete={status === 'COMPLETED'}
        editable={!isDeliveryComplete}
        onToggle={this.onToggle(PROD)}
        failed={status === 'FAILED'}
        className={cx({ active: status === 'PROCESSING' })}
      >
        <PRODPanel
          tasks={tasks}
          substepDetails={substepDetails}
          prodReportId={prodReportId}
          onToggleDetails={this.props.onToggleDetails}
          retryDeliveryStep={this.handleDeliveryStepRetry}
          editable={!isDeliveryComplete}
        />
      </StepPanel>
    )
  }

  summaryPanel() {
    const { deliveryDate, deliveryStatus, completionDate, notes } = this.props
    const DATE = 'MM/DD/YYYY'
    const deliveredDate = completionDate ? moment.utc(completionDate) : moment()
    const delayDays = deliveredDate.diff(moment.utc(deliveryDate), 'days')
    const formattedDate = moment.utc(deliveryDate).format(DATE)
    let titleTxt = 'Loading...'
    if (deliveryStatus === 'COMPLETED' || deliveryStatus === 'DELIVERED') {
      if (delayDays === 0) {
        titleTxt = (
          <span>
            <strong>On Time</strong>
            {` - Deadline ${formattedDate}`}
          </span>
        )
      } else if (delayDays > 0) {
        titleTxt = (
          <span>
            <strong>Delayed delivery</strong>
            {` ${delayDays} day${delayDays > 1 ? 's' : ''} after ${formattedDate}`}
          </span>
        )
      } else {
        titleTxt = (
          <span>
            <strong>Delivered</strong>
            {` ${Math.abs(delayDays)} day${Math.abs(delayDays) > 1 ? 's' : ''} before ${formattedDate}`}
          </span>
        )
      }
    } else if (deliveryStatus === 'CANCELLED') {
      titleTxt = (
        <span>
          <strong>Cancelled</strong>
          {` on ${moment.utc(deliveredDate).format(DATE)}`}
        </span>
      )
    } else if (deliveryDate) {
      titleTxt = (
        <span>
          Deadline {formattedDate}
          <span onClick={this.editDeadline} className="edit-deadline-btn">
            Edit
          </span>
        </span>
      )
    }
    const isComplete = ['COMPLETED', 'DELIVERED', 'CANCELLED'].includes(deliveryStatus)
    const cancelled = deliveryStatus === 'CANCELLED'
    const renderNotes = <div className="beta-alert">{notes}</div>
    return (
      <StepPanel
        className={cx('delivery-summary-panel', {
          delayed: delayDays > 0,
          cancelled
        })}
        step={<TargetFlag />}
        stepKey={'summary'}
        isComplete={isComplete}
        title={titleTxt}
        disabled={!cancelled}
        expanded={cancelled}
        headerSuffix={<LastUpdated date={completionDate} />}
      >
        {cancelled && renderNotes}
      </StepPanel>
    )
  }

  render() {
    const { activeStep } = this.props
    return (
      <StepPanelGroup
        ref={el => {
          this.panelGroup = el
        }}
        id="current-delivery"
        accordian
        defaultExpanded={{ [activeStep]: true }}
      >
        {this.dataCleanupPanel()}
        {this.dataCustomizationPanel()}
        {this.qcPanel()}
        {this.prodPanel()}
        {this.summaryPanel()}
      </StepPanelGroup>
    )
  }
}

DeliverySteps.propTypes = {
  deliveryId: PropTypes.number,
  deliverableName: PropTypes.string,
  totalCategories: PropTypes.number,
  readyCategories: PropTypes.number,
  categoryCleanupStatus: PropTypes.string,
  dataCustomization: PropTypes.object,
  qc: PropTypes.object,
  qcStatus: PropTypes.string,
  qcReportId: PropTypes.number,
  prodReportId: PropTypes.number,
  prod: PropTypes.object,
  activeStep: PropTypes.string,
  deliveryDate: PropTypes.string,
  deliveryStatus: PropTypes.string,
  isDeliveryComplete: PropTypes.bool,
  substepDetails: PropTypes.object,
  updateActiveStep: PropTypes.func,
  startDataCustomization: PropTypes.func,
  addFormModal: PropTypes.func,
  deliverableId: PropTypes.number,
  onToggleDetails: PropTypes.func,
  retryDeliveryStep: PropTypes.func,
  proceedToDataCustomization: PropTypes.func,
  approveQC: PropTypes.func,
  fetchCurrentDelivery: PropTypes.func,
  completionDate: PropTypes.string,
  canReviewQc: PropTypes.bool,
  notes: PropTypes.string
}

function mapStateToProps({
  deliveryConfig: { deliverableData },
  session: { user },
  delivery: {
    activeStep,
    cleanupJobs = {},
    substepDetails,
    currentDelivery: {
      id: deliveryId,
      status: deliveryStatus,
      deliveryDate,
      dataCustomization = {},
      qc = {},
      prod = {},
      qcStatus,
      categoryCleanupStatus,
      completionDate,
      notes
    }
  }
}) {
  const { id: deliverableId, name: deliverableName, qcReportId, prodReportId, categories = [] } = deliverableData || {}
  const readyCategories = Object.values(cleanupJobs).filter(job => job && job.status === DELIVERY_JOB_STATUS.PUBLISHED)
    .length
  const canReviewQc = UserService.hasPermission(user, PERMISSIONS.sandboxDeliveryQcReview)

  return {
    activeStep,
    deliveryDate,
    deliverableId,
    deliveryStatus,
    deliverableName,
    deliveryId,
    dataCustomization,
    qc,
    prod,
    qcReportId,
    prodReportId,
    substepDetails,
    totalCategories: categories.length,
    readyCategories,
    qcStatus,
    categoryCleanupStatus,
    completionDate,
    canReviewQc,
    notes
  }
}

export default connect(mapStateToProps, {
  updateActiveStep,
  startDataCustomization,
  addFormModal,
  onToggleDetails,
  retryDeliveryStep,
  proceedToDataCustomization,
  approveQC,
  fetchCurrentDelivery
})(DeliverySteps)

function LastUpdated({ date }) {
  return date ? <span className="last-updated">{moment(date).format(DATE_TIME_FORMAT)}</span> : null
}

LastUpdated.propTypes = {
  date: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
}
