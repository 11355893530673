import React from 'react'
import PropTypes from 'prop-types'
import { Glyphicon } from 'react-bootstrap'

import BooleandHighlightedText from './BooleandHighlightedText'
import ValueWithMark from './ValueWithMark'

const ResultCellValue = ({ isPass, isActive, children, mark = '' }) => {
  const value = children
  return isActive ? (
    <BooleandHighlightedText isPass={isPass} useHighlight={!isPass}>
      <ValueWithMark mark={mark} nullValue="—">
        {value}
      </ValueWithMark>
    </BooleandHighlightedText>
  ) : (
    <Glyphicon glyph="ban-circle" />
  )
}

ResultCellValue.propTypes = {
  children: PropTypes.node,
  mark: PropTypes.string,
  isPass: PropTypes.bool,
  isActive: PropTypes.bool
}

export default ResultCellValue
