import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { cloneDeep } from 'lodash'

import Loader from '../../Loader'
import { DataTableFiltered } from './../../DataTable'
import { DATA_TABLE } from './../../DataTable/constants'

import BrandSynonymRuleModal from './Modals/BrandSynonymRuleModal'
import CommonRulesToolbar from './../Common/CommonRulesToolbar'
import BrandSynonymActionsCell from './BrandSynonymActionsCell'

import { BrandSynonymRulesActions } from './../actions'

import COMMON_COLUMNS from './../Common/commonColumns'
import { STORE_PATH } from './../constants'

class RulesBrandSynonym extends Component {
  constructor(props) {
    super(props)

    this.columns = this.getColumns(cloneDeep(COMMON_COLUMNS))
  }

  getColumns(commonColumns) {
    const columns = [
      commonColumns.id,
      commonColumns.brandId,
      commonColumns.brandFullPath,
      {
        field: 'synonym',

        dataAlign: 'center',
        headerAlign: 'center',
        width: '350px',

        label: 'Synonym',
        dataSort: true,
        filter: {
          type: 'TextFilter',
          delay: DATA_TABLE.FILTER_DELAY
        }
      },
      commonColumns.updatedAt,
      commonColumns.updatedByName,
      commonColumns.isActive,
      {
        ...commonColumns.actionsColumn,
        dataFormat: (cell, row) => <BrandSynonymActionsCell row={row} />
      }
    ]

    return columns
  }

  render() {
    const {
      loading,
      pageSize,
      hasEditAccess,
      totalCount,
      page,
      rows,
      sortBy,
      sortOrder,
      filters,
      fetchRulesAction,
      downloadRulesAction,
      clearFilters,
      saveRuleAction
    } = this.props

    return (
      <React.Fragment>
        <CommonRulesToolbar
          hasEditAccess={hasEditAccess}
          toolbarType={'Brand Synonym'}
          createRuleModalContet={<BrandSynonymRuleModal onOkHandler={saveRuleAction} />}
          downloadRulesAction={downloadRulesAction}
          refreshHandler={fetchRulesAction}
          clearFilters={clearFilters}
        />

        {loading && <Loader loading overlap />}

        <DataTableFiltered
          data={rows}
          columns={this.columns}
          page={page}
          pageSize={pageSize}
          totalCount={totalCount}
          sortBy={sortBy}
          sortOrder={sortOrder}
          filters={filters}
          tableClassName="rules-table"
          fetchRowsAction={fetchRulesAction}
        />
      </React.Fragment>
    )
  }
}

RulesBrandSynonym.defaultProps = {
  rows: [],
  totalCount: 0
}

RulesBrandSynonym.propTypes = {
  page: PropTypes.number,
  pageSize: PropTypes.number,
  sortBy: PropTypes.string,
  sortOrder: PropTypes.string,
  filters: PropTypes.object,
  loading: PropTypes.bool.isRequired,
  hasEditAccess: PropTypes.bool.isRequired,
  totalCount: PropTypes.number.isRequired,
  rows: PropTypes.arrayOf(PropTypes.object).isRequired,
  fetchRulesAction: PropTypes.func.isRequired,
  downloadRulesAction: PropTypes.func.isRequired,
  clearFilters: PropTypes.func.isRequired,
  saveRuleAction: PropTypes.func.isRequired
}

const mapStateToProps = ({ rules: { [STORE_PATH.brandSynonym]: brandSynonym } }) => ({
  page: brandSynonym.page,
  sortBy: brandSynonym.sortBy,
  loading: brandSynonym.loading,
  rows: brandSynonym.data.rows,
  pageSize: brandSynonym.pageSize,
  sortOrder: brandSynonym.sortOrder,
  totalCount: brandSynonym.data.count,
  filters: brandSynonym.filters
})

const mapDispatchToProps = {
  fetchRulesAction: BrandSynonymRulesActions.fetch,
  downloadRulesAction: BrandSynonymRulesActions.download,
  clearFilters: BrandSynonymRulesActions.clearFilters,
  saveRuleAction: BrandSynonymRulesActions.save
}

export default connect(mapStateToProps, mapDispatchToProps)(RulesBrandSynonym)
